import React, { useState } from "react";
import { Select, MenuItem, FormControl } from "@mui/material";
import { styled } from "@mui/system";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import curve from "../../../assets/curve.png";
import straight from "../../../assets/straight.png";
import bezier from "../../../assets/bezier.png";
function EdgeTypeDropdown({ formData, onChange }) {
  const [selectedOption, setSelectedOption] = useState(formData.type);

  const handleSelect = (event) => {
    const option = event.target.value;
    setSelectedOption(option);
    onChange(option);
  };

  const edgeTypes = [
    {
      type: "bezier",
      icon: <img src={bezier} alt="bezier" width={30} />,
    },
    { type: "step", icon: <TrendingUpIcon /> },
    { type: "smoothstep", icon: <img src={curve} alt="curve" width={20} /> },
    {
      type: "straight",
      icon: <img src={straight} alt="straight" width={20} />,
    },
    { type: "floating", icon: <AutoModeIcon /> },
  ];

  return (
    // <FormControl style={{width:"80%", marginLeft:"-5px"}}>
      <FormControl className="style-rht-markr-start" 
      onClick={(e) => e.stopPropagation()}
      >
        <div className="marker-line-text">
        <div className="marker-text-container">
      <div className="rht-info">Edge Type</div>
      </div>
      <div className="dropdown-container">
      <Select
      className="rht-fld-select rht-fld-select-flow rht-select-field-flow"
        value={selectedOption}
        onChange={handleSelect}
        onClick={(e) => e.stopPropagation()}
      >
        {edgeTypes.map((option, index) => (
          <MenuItem key={index} value={option.type} style={{ height: "2rem" }}>
            {option.icon}
          </MenuItem>
        ))}
      </Select>
      </div>
      </div>
    </FormControl>
  );
}

export default EdgeTypeDropdown;
