import { useCallback } from "react";

export const useDragOver = () => {
  return useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);
};

export const getPropertyValue = (propertyName, properties) => {
  if (
    properties &&
    typeof properties === "object" &&
    !Array.isArray(properties)
  ) {
    if (properties.propertyName === propertyName) {
      return properties.diagPropValue;
    }
    return null;
  }

  // Handle case where properties is not valid
  if (!Array.isArray(properties)) {
    console.error("Invalid properties format:", properties);
    return null;
  }

  // Find the property when it's an array
  const property = properties.find(
    (prop) => prop.propertyName === propertyName
  );

  // Handle special CSS conversion
  if (
    (propertyName === "Node_Default_CSS" && property) ||
    (propertyName === "Body_Default_CSS" && property)
  ) {
    return convertCSSStringToObject(property.diagPropValue);
  }

  return property ? property.diagPropValue : null;
};

const convertCSSStringToObject = (cssString) => {
  const cssProperties = cssString.split(";").filter(Boolean); // Split the string by semicolons and filter out empty strings
  const cssObject = {};
  cssProperties.forEach((property) => {
    const [key, value] = property.split(":").map((str) => str.trim()); // Split each property by colon and trim whitespaces
    const formattedKey = key.includes("-") ? convertToCamelCase(key) : key;
    cssObject[formattedKey] = value; // Convert the key to camelCase and set it in the object
  });
  return cssObject;
};
// Function to convert camelCase to dash-separated strings
const convertToCamelCase = (str) => {
  return str.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
};

// const getShortenedUuid = () => {
//   return uuidv4().split("-")[0]; // Take only the first part of the UUID
// };

// const getNodeId = () => getShortenedUuid();

export const useDrop = (
  reactFlowWrapper,
  rfInstance,
  nodes,
  setNodes,
  region
) => {
  //make sure my id is not the same as any of the dbids
  // const usedIds = new Set(
  //   nodes?.length > 0 ? nodes?.data[0]?.Dbid?.map((data) => data?.Dbid) : []
  // );
  const usedIds = new Set(
    Array.isArray(nodes?.data) && Array.isArray(nodes.data[0]?.tasktypeid)
      ? nodes.data[0].tasktypeid.map((data) => data?.tasktypeid)
      : []
  );
  return useCallback(
    (event) => {
      event.preventDefault();
      const getNodeId = () => {
        let newId;
        do {
          newId = Math.floor(10000 + Math.random() * 90000);
        } while (usedIds.has(newId));
        usedIds.add(newId);
        return newId.toString();
      };

      // const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      let type = event.dataTransfer.getData("application/reactflow");
      let customData = event.dataTransfer.getData("customData");
      let tempData = event.dataTransfer.getData("data");

      let properties = null;

      if (customData !== null && customData !== "undefined") {
        properties = JSON.parse(customData);
      }
      let data = null;
      if (tempData !== null && tempData !== "undefined") {
        data = JSON.parse(tempData);
      }
      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }

      const position = rfInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      // const position = rfInstance.screenToFlowPosition({
      //   x: event.clientX - reactFlowBounds.left,
      //   y: event.clientY - reactFlowBounds.top,
      // });

      const newNodeData = [
        data.find((obj) => obj.execname === type),
        properties,
        getNodeId(),
      ];
      // const nodeHeight = newNodeData[0]?.node_Height
      //   ? newNodeData[0].node_Height + "px"
      //   : "75px";
      // const nodeWidth = newNodeData[0]?.node_Width
      //   ? newNodeData[0].node_Width + "px"
      //   : "240px";
      // type = type.split(" ")[0].toLowerCase();

      const newNode = {
        id: newNodeData[2],
        type: "dynamicNode",
        position,
        data: newNodeData,
        // style: {
        //   width: nodeWidth,
        //   height: nodeHeight,
        // },
        // draggable: isDraggable,
      };

      setNodes((nds) => nds.concat(newNode));
      // Push to setDiagramDetails if region is 'ai ui'
      // if (region.toLowerCase() === "ai ui") {
      //   const diagramDetail = {
      //     TExecName: newNodeData[0]?.name || "",
      //     Explain: "",
      //     TaskTypeID: newNodeData[0]?.tasktypeid || "",
      //     isActive: 1,
      //     Seq: 0,
      //     Bid: 12,
      //     Dbid: newNodeData[2],
      //     id: newNodeData[2],
      //     ...(newNodeData[0]?.params && { Params: newNodeData[0].params }), // Conditionally add Params
      //   };
      // }
    },
    [rfInstance, setNodes]
  );
};
