import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { Theme } from "../../IncludeFile/Theme";
import themeProvider from "../../../ThemeProvider";
import { ThemeProvider } from "@mui/styles";
import NavBar from "../../navbarComponents/NavBar";
import SaveAsConfirmation from "./SaveAsConfirmation";
import CustomPopUpForm from "../../../layout/CustomPopUpForm";
import { GetToolPropertiesAll } from "../../../apiCalls/DiagramsAPI";
import { ToastContainer, toast } from "react-toastify";
import {
  UpdateDiagTools,
  UpdateToolProperties,
  postResource,
  createRequestBody,
  loadLoginData,
} from "../../../apiCalls/DiagramsAPI";

import Preview from "./Preview";

import { Typography, Box, Button } from "@mui/material";
import ExpandableView from "../../../layout/ExpandableView";
import TabsComponent from "../TabsComponent";
import NodeDropDowns from "./NodeDropDowns";
import CaptionDropDowns from "./CaptionDropDowns";
import BodyDropDowns from "./BodyDropDowns";
import LinkDropDowns from "./LinkDropDowns";
import { TiExport } from "react-icons/ti";
import { GetToolProperties } from "../../../apiCalls/DiagramsAPI";
import PreviewNode from "./PreviewNode";
import DynamicNode from "../../../nodes/DynamicNode";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { useAppContext } from "../RegionContext";
import LoadingSpinner from "../../LoadingSpinner";

function NodeProperties() {
  const location = useLocation();
  const navigate = useNavigate();
  const selectedNodes = location.state?.prop;
  const tools = location.state?.tools;

  const [popup, setPopup] = useState(false);
  const [infoPopup, setInfoPopup] = useState(false);
  const [tabValue, setTabValue] = useState("1");
  const tabs = [{ label: "Style", value: "1" }];
  const [selectedItem, setSelectedItem] = useState();
  const [selectedItemID, setSelectedItemID] = useState();
  const [selectedItemPropertyGroup, setSelectedItemPropertyGroup] = useState();
  const [editedProperties, setEditedProperties] = useState();
  const [newProperties, setNewProperties] = useState();
  const [originalProperties, setOriginalProperties] = useState();
  const [leftSidebarTree, setLeftSidebarTree] = useState();
  const [leftCollapse, setLeftCollapse] = useState(false);
  const [rightCollapse, setRightCollapse] = useState(true);
  const [fetched, setFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  // State to store temporary properties
  const [tempNodes, setTempNodes] = useState({});

  let SidesList = null;
  const authData = loadLoginData();
  const accessKey = authData?.accessKey || "";

  //get info from RegionContext
  const { getRegionInfo } = useAppContext();
  const [regionInfo, setRegionInfo] = useState(null);

  const [data, setData] = useState(null);
  const fetchRegions = async () => {
    try {
      const storedRegion = localStorage.getItem("region");
      const info = await getRegionInfo(storedRegion);
      setRegionInfo(info);
      // Call fetchData only if info is valid and fetched is false
      if (info !== null && info !== undefined) {
        const toolJSON = JSON.parse(info.ToolsJson);
        setData(toolJSON);
        setNewProperties(toolJSON);
        return true;
      }
    } catch (error) {
      console.error("Error fetching regions:", error);
      return false;
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await fetchRegions();
      setFetched(result);
    };

    if (!newProperties) {
      fetchData();
    }
  }, [newProperties]);

  async function getToolProperties(ToolID) {
    setIsLoading(true);
    try {
      // let response = await GetToolProperties(diagToolID);
      const response = await postResource(
        "GetQuickInfo",
        createRequestBody({
          accessKey: accessKey,
          guid: `${window.APIProvider.recordPath.DiagToolsProperties}`,
          param1: ToolID.toString(),
        })
      );
      if (response[0].tasktypeid !== 0) {
        return response;
      } else {
        let matchingTool = tools.find((tool) => tool.tasktypeid === ToolID);
        response[0].tasktypeid = parseInt(ToolID, 10);
        response[0].name = matchingTool.name;
        return response;
      }
    } catch (error) {
      console.error("Fetch Error ", error);
      toast.error("Error saving data: " + error.message);
      throw error;
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      // Map selectedNodes to getToolProperties calls and store the promises
      const propertyPromises = selectedNodes.map(async (toolID) => {
        return await getToolProperties(toolID); // Return the promise
      });

      try {
        SidesList = await Promise.all(propertyPromises);
        setEditedProperties(SidesList);
        setOriginalProperties(SidesList);

        const updatedTree = SidesList.map((item) => ({
          id: item[0].tasktypeid,
          name:
            tools.find(
              (tool) =>
                tool.tasktypeid.toString() === item[0].tasktypeid.toString()
            )?.title ||
            tools.find(
              (tool) =>
                tool.tasktypeid.toString() === item[0].tasktypeid.toString()
            )?.execname ||
            "Tool",

          children: [
            {
              id: `${item[0].tasktypeid} Caption`,
              name: "Caption",
              children: [],
            },
            {
              id: `${item[0].tasktypeid} Node`,
              name: "Node",
              children: [],
            },
            {
              id: `${item[0].tasktypeid} Body`,
              name: "Body",
              children: [],
            },
            {
              id: `${item[0].tasktypeid} Link`,
              name: "Link",
              children: [],
            },
          ],
        }));
        setLeftSidebarTree(updatedTree);
      } catch (error) {
        // Handle errors if any
        console.error("Error fetching properties:", error);
      }
    };

    // Call the fetchData function
    fetchData();

    // Empty dependency array to run the effect only once
  }, []);

  useEffect(() => {
    if (selectedItem) {
      const str = String(selectedItem.id);
      const [firstPart, secondPart] = str.split(/\s(.+)/);
      setSelectedItemID(parseInt(firstPart, 10));
      setSelectedItemPropertyGroup(secondPart);
    }
  }, [selectedItem, editedProperties]);

  //save as functionality leave it if save as is needed later
  // async function saveasData(newTool) {
  //   try {
  //     const response = await UpdateDiagTools(newTool);
  //     // toast.success("Data Saved!");
  //   } catch (error) {
  //     console.error("Fetch Error ", error);
  //     toast.error("Error saving data: " + error.message);
  //   }
  // }

  // const handleSaveAs = () => {
  //   setPopup(!popup);
  // };

  // const handleSave = async (toolInfo) => {
  //   console.log("SidesList", editedProperties);
  //   // Find the tool in SidesList with the same ID as selectedItemID
  //   const originalTool = editedProperties.find(
  //     (tool) => tool[0]?.diagToolID === selectedItemID
  //   );

  //   if (originalTool) {
  //     // Create a deep copy of the original tool
  //     const duplicatedTool = JSON.parse(JSON.stringify(originalTool));
  //     // Generate a new unique ID by adding 1 to the maximum existing ID number
  //     let maxID = 0;
  //     editedProperties.forEach((side) => {
  //       side.forEach((obj) => {
  //         if (obj.diagToolID > maxID) {
  //           maxID = obj.diagToolID;
  //         }
  //       });
  //     });

  //     duplicatedTool.forEach((obj) => {
  //       // Change the `diagToolID` to `maxID + 1`
  //       obj.diagToolID = maxID + 1;

  //       // Change the `name` property to `toolInfo.toolName` if `toolInfo.toolName` exists, otherwise to an empty string
  //       obj.name = toolInfo.toolName ? toolInfo.toolName : "";
  //     });
  //     saveProperties(duplicatedTool);
  //     console.log("SidesList", duplicatedTool);
  //     // Optionally, modify any other properties as needed
  //     // Add the duplicated tool to editedProperties
  //     setEditedProperties((prev) => [...prev, duplicatedTool]);
  //     console.log("SidesList", editedProperties);
  //     // Update leftSidebarTree
  //     const updatedLeftSidebarTree = [
  //       ...leftSidebarTree,
  //       {
  //         id: duplicatedTool[0].diagToolID,
  //         name: duplicatedTool[0].name,
  //         children: [
  //           {
  //             id: `${duplicatedTool[0].diagToolID} Caption`,
  //             name: "Caption",
  //             children: [],
  //           },
  //           {
  //             id: `${duplicatedTool[0].diagToolID} Node`,
  //             name: "Node",
  //             children: [],
  //           },
  //           {
  //             id: `${duplicatedTool[0].diagToolID} Body`,
  //             name: "Body",
  //             children: [],
  //           },
  //           {
  //             id: `${duplicatedTool[0].diagToolID} Link`,
  //             name: "Link",
  //             children: [],
  //           },
  //         ],
  //       },
  //     ];

  //     setLeftSidebarTree(updatedLeftSidebarTree);
  //     await saveasData({
  //       diagToolID: (maxID + 1).toString(),
  //       name: toolInfo.toolName ? toolInfo.toolName : "",
  //       title: toolInfo.toolTitle ? toolInfo.toolTitle : "",
  //       explain: toolInfo.toolExplain ? toolInfo.toolExplain : "",
  //       notes: toolInfo.toolNotes ? toolInfo.toolNotes : "",
  //       caption: toolInfo.toolCaption ? toolInfo.toolCaption : "",
  //       description: toolInfo.toolDescription ? toolInfo.toolDescription : "",
  //     });
  //     handleSaveAs();
  //   } else {
  //     console.error("Original tool not found!");
  //   }
  // };
  async function UpdateToolProperties111(toolProperties) {
    try {
      const requestBody = await postResource(
        "PostQuickInfo",
        createRequestBody({
          accessKey: accessKey,
          guid: `${window.APIProvider.recordPath.UpsertDiagToolsProperties}`,
          param1: "",
          param2: "",
          param3: "",
          param4: "",
          recordSet: "",
          body: JSON.stringify(toolProperties),
        })
      );
      // toast.success("Data Saved!");
    } catch (error) {
      console.error("Error in UpdateToolProperties:", error);
      toast.error("Error saving data: " + error.message);
      return null;
    }
  }

  async function saveProperties(prop) {
    try {
      const filteredProperties = prop
        .flatMap((propertyGroup) => propertyGroup)
        .filter((property) => property.diagPropValue !== null);
      const response = await UpdateToolProperties111(filteredProperties);
      console.log("response", response);

      // navigate("/Landing");
    } catch (error) {
      console.error("Fetch Error ", error);
    }
  }

  //Nav bar components
  const handleInfoPopup = () => {
    setInfoPopup(!infoPopup);
  };
  const leftButton = {
    icon: <InfoIcon />,
    text: "Info", // color: "primary", // "primary" or "secondary"
    handleClick: () => {
      // Handle click for the left button
      handleInfoPopup();
    },
  };

  const rightButtons = [
    {
      icon: Theme.Save,
      text: "Save",
      color: "secondary",
      tooltip:"Save all properties",
      handleClick: () => {
        saveProperties(editedProperties);
      },
    },
    {
      icon: Theme.Restore,
      text: "Restore",
      color: "secondary",
      tooltip:"Restore all properties",
      handleClick: () => {
        setEditedProperties(originalProperties);
      },
    },
    {
      icon: Theme.GoBack,
      text: "Back",
      color: "secondary",
      tooltip:"Go back to previous page",
      handleClick: () => {
        navigate(-1);
      },
    },
  ];
  // const newNodeData = [tools[0], editedProperties[0], 1];
  // console.log("SidesList", editedProperties);
  // console.log("newNodeData",newNodeData)
  const [showLeftIcon, setShowLeftIcon] = useState(true);

  const handleIconClick = () => {
    setShowLeftIcon(!showLeftIcon);
    setLeftCollapse(!leftCollapse);
  };
  // const handleListItemClick = () => {

  // }

  const initializeProperties = (tool) => {
    const { tasktypeid, category } = tool;

    // Check if tool already has customstyle
    const existingNode = newProperties.Nodes.find(
      (node) => node.id === tasktypeid
    );

    if (existingNode?.customstyle) {
      return JSON.parse(JSON.stringify(existingNode.customstyle));
    }

    // Otherwise, use category properties or <all> fallback
    const categoryProperties =
      newProperties[category] || newProperties["<all>"];
    return JSON.parse(JSON.stringify(categoryProperties));
  };

  const handlePropertyChange = (tool, section, key, value) => {
    setTempNodes((prev) => {
      // Check if tool already exists in tempNodes
      if (!prev[tool.tasktypeid]) {
        // Initialize the tool's properties
        const initialProperties = initializeProperties(tool);

        return {
          ...prev,
          [tool.tasktypeid]: {
            ...initialProperties,
            [section]: {
              ...initialProperties[section], // Ensure section is initialized
              [key]: value, // Apply the change
            },
          },
        };
      }

      // If tool already exists, update the specific section and key
      return {
        ...prev,
        [tool.tasktypeid]: {
          ...prev[tool.tasktypeid],
          [section]: {
            ...prev[tool.tasktypeid][section], // Keep other keys in the section intact
            [key]: value, // Apply the change
          },
        },
      };
    });

    console.log(
      `Tool ${tool.tasktypeid} section '${section}' property '${key}' updated to:`,
      value
    );
  };

  // Function to fetch tool's properties or initialize customstyle
  // const getToolProperties = (toolId, category) => {
  //   const toolNode = tools.find((tool) => tool.tasktypeid === toolId);

  //   if (toolNode && toolNode.customstyle) {
  //     // If customstyle exists, return it
  //     return { ...toolNode.customstyle };
  //   } else {
  //     // Get category or fallback properties
  //     const categoryProperties = properties[category] || properties["<all>"];
  //     // Return a deep copy of category properties as the new customstyle
  //     return JSON.parse(JSON.stringify(categoryProperties));
  //   }
  // };

  if (!newProperties || Object.keys(newProperties).length === 0) {
    return <div>Loading...</div>;
  }

  const selectedTool = tools.find(
    (tool) => tool?.tasktypeid?.toString() === selectedItemID?.toString()
  );
  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <ThemeProvider theme={themeProvider}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <NavBar
        isSearchBar={false}
        rightButtons={rightButtons}
        title={
          leftSidebarTree && leftSidebarTree.length > 0
            ? `[${localStorage.getItem("region")}] [${selectedTool?.execname}] Properties`
            : "Loading..."
        }
        leftButton={leftButton}
        icon={true}
        handleMenushwDskButtonClick={() => setRightCollapse(!rightCollapse)}
      />
      {/* {popup && (
        <SaveAsConfirmation
          open={popup}
          handleClose={handleSaveAs}
          handleSave={handleSave}
        />
      )} */}
      {infoPopup && (
        <CustomPopUpForm
          open={infoPopup}
          onClose={handleInfoPopup}
          title="Information"
        >
          <Typography>
            This page lets you edit the properties of tools in the diagram.
            Caption: Update the top part of the tool, including the icon, name,
            and category. Node: Edit the overall properties of the tool. Body:
            Change the bottom section of the tool, which contains the
            description. Link (Optional): Add a link at the bottom of the tool
            with specific properties.
          </Typography>
          <></>
        </CustomPopUpForm>
      )}
      <ExpandableView
        propertyData={data}
        tools={tools}
        tempNodes={tempNodes}
        leftSidebarTree={leftSidebarTree}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        // handleSaveAs={handleSaveAs}
        leftCollapse={leftCollapse}
        setLeftCollapse={setLeftCollapse}
        rightCollapse={rightCollapse}
        setRightCollapse={setRightCollapse}
        showLeftIcon={showLeftIcon}
        setShowLeftIcon={setShowLeftIcon}
      >
        {selectedItemID ? (
          // <PreviewNode
          //   properties={data}
          //   tools={tools}
          //   editedProperties={editedProperties}
          //   selectedItemID={selectedItemID}
          // />
          <>
            <Preview
              propertyData={data}
              tools={tools}
              selectedItemID={selectedItemID}
              tempNodes={tempNodes}
            />
          </>
        ) : (
          <>{/* <DynamicNode data={newNodeData} selected={true} /> */}</>
        )}
        <Box padding="0.5rem">
          <div className="inputfildtet" style={{ marginBottom: "0.5rem" }}>
            <div className="tools-group">
              <div>
                {leftSidebarTree &&
                  leftSidebarTree.length > 0 &&
                  selectedTool?.name}
                <span className="sltitmnem">
                  {
                    editedProperties
                      ?.flatMap((propertyGroup) => propertyGroup)
                      .find(
                        (property) => property.tasktypeid === selectedItemID
                      )?.execname
                  }
                </span>
                <span className="sltitmnem">{selectedItemPropertyGroup}</span>
              </div>
              <div className="top-left-icon-panel" onClick={handleIconClick}>
                {showLeftIcon ? <FaAngleRight /> : <FaAngleLeft />}
              </div>
            </div>
          </div>
          <TabsComponent
            tabValue={tabValue}
            setTabValue={setTabValue}
            tabs={tabs}
          >
            <div key="1">
              {selectedItemPropertyGroup === "Caption" && (
                <CaptionDropDowns
                  selectedItemID={selectedItemID}
                  newProperties={newProperties}
                  handlePropertyChange={handlePropertyChange}
                  tempNodes={tempNodes}
                  selectedTool={selectedTool}
                />
              )}
              {selectedItemPropertyGroup === "Node" && (
                <NodeDropDowns
                  selectedItemID={selectedItemID}
                  newProperties={newProperties}
                  handlePropertyChange={handlePropertyChange}
                  tempNodes={tempNodes}
                  selectedTool={selectedTool}
                />
              )}
              {selectedItemPropertyGroup === "Body" && (
                <BodyDropDowns
                  selectedItemID={selectedItemID}
                  newProperties={newProperties}
                  handlePropertyChange={handlePropertyChange}
                  tempNodes={tempNodes}
                  selectedTool={selectedTool}
                />
              )}
              {selectedItemPropertyGroup === "Link" && (
                <LinkDropDowns
                  selectedItemID={selectedItemID}
                  newProperties={newProperties}
                  handlePropertyChange={handlePropertyChange}
                  tempNodes={tempNodes}
                  selectedTool={selectedTool}
                />
              )}
            </div>
          </TabsComponent>
        </Box>
      </ExpandableView>
    </ThemeProvider>
  );
}

export default NodeProperties;
