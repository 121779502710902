import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { CircularProgress, Grid } from "@mui/material";
import SelectorData from "../../PopUpForm/SelectorData";
import { SplitScreen } from "../../PopUpForm/SplitScreen";
import { useLocation, useNavigate } from "react-router-dom";
import ThemeContentData from "./ThemeContentData";
import ThemeSelectorData from "./ThemeSelectorData";
import { getCurrentDateTime } from "../../apiCalls/DiagramsAPI";
import { ReadArray } from "../Api/ApiCalls";
import ErrorBanner from "../../Error/ErrorBanner";

const ThemeCards = ({ searchQuery, authData ,ReadArrayAllData , defaultStylefromloc, error , setError}) => {
  const userName = authData?.userName || "";
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const [defaultStyle, setDefaultStyle] = useState([]);

  async function GetQuickInfo() {
    try {
      setApiData(ReadArrayAllData);
      selected
        .flat()
        .filter(
          (item) => !ReadArrayAllData.some((apiItem) => apiItem.ArID === item.ArID)
        );

      setDefaultStyle(defaultStylefromloc);
    } catch (error) {
      console.error("Error during request:", error);
      setError("Error during request");
      localStorage.setItem("error", "Error during request");
      localStorage.setItem("navigate", "/Open");
    }
  }



  useEffect(() => {
    if (!authData || !authData.accessKey) {
      navigate("/");
      sessionStorage.clear();
      return;
    }

    const fetchData = async () => {
      setLoading(true);
      try {
        const data = await GetQuickInfo();
      } catch (error) {
        console.error("Error:", error);
        setError("An error occurred in fetching data");
        localStorage.setItem("error", "An error occurred in fetching data");
        localStorage.setItem("navigate", "/Open");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [authData]);


  const itemsToDisplay = selected
    .flat()
    .filter(
      (section) =>
        section.ArrayName &&
        section.ArrayName.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) =>
      a.IsTemplate !== b.IsTemplate ? (a.IsTemplate ? 1 : -1) : a.ArID - b.ArID
    );

  const handleDelete = (arIDToDelete) => {
    const updatedSelected = selected.filter(
      (item) => item.ArID !== arIDToDelete
    );
    setSelected(updatedSelected);

    const updatedApiData = apiData.filter((item) => item.ArID !== arIDToDelete);
    setApiData(updatedApiData);
  };


  console.log("itemsToDisplay",itemsToDisplay);


  return (
    <>
 
      <SplitScreen leftWeight={1} rightWeight={3}>
        <ThemeSelectorData
          apiData={apiData}
          selected={selected}
          setSelected={setSelected}
        />

        {loading ? (
          <CircularProgress
            size={48}
            color="success"
            style={{
              position: "relative",
              minHeight: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        ) : (
          <Grid sx={{ flexGrow: 1 }} container padding={1}>
            {itemsToDisplay.map((item, index) => (
              <ThemeContentData
                key={index}
                size={325}
                textSize={17}
                ArrayName={item.ArrayName}
                Description={item.Description}
                Category={item.Category}
                DataSourceArID={item.DataSourceArID}
                ArID={item.ArID}
                handleDelete={handleDelete}
                isTemplate={item.IsTemplate}
                authData={authData}
                defaultStyle={defaultStyle}
                error = {error}
                setError = {setError}
              />
            ))}
          </Grid>
        )}
      </SplitScreen>

      <ToastContainer position="bottom-right" autoClose={3000} />
      {error && <ErrorBanner />}
    </>
  );
};

export default ThemeCards;
