import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import {
  postResource,
  createRequestBody,
  getAccessKey,
} from "../../apiCalls/DiagramsAPI";

// Default regions data as fallback
const DEFAULT_REGIONS = [
  {
    Region: "Ai UI",
    isGeneric: 0,
    API1: "Diagram List Project Page - Region AI UI",
  },
  {
    Region: "Generic",
    isGeneric: 1,
    API1: "Diagram List Project Page",
  },
  {
    Region: "Table Trigger",
    isGeneric: 0,
    API1: null,
  },
];

// Create context with initial shape
const AppContext = createContext({
  regions: [],
  isLoading: false,
  error: null,
  getRegionInfo: () => Promise.resolve(null),
  getRegions: () => Promise.resolve([]),
  loginData: null,
});

export function AppProvider({ children, loginData }) {
  const [regions, setRegions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Get access key from login data or fallback
  let accessKey = React.useMemo(() => {
    try {
      // console.log("loginData ", loginData);
      // return loginData ? JSON.parse(loginData) : getAccessKey();
      return loginData ? loginData : getAccessKey();
    } catch (err) {
      console.error("Failed to parse login data:", err);
      return getAccessKey();
    }
  }, [loginData]);

  // Fetch regions data from API
  const fetchRegions = useCallback(async () => {
    if (!accessKey) {
      accessKey = getAccessKey();
    }

    setIsLoading(true);
    try {
      const accessKey = getAccessKey();
      const response = await postResource(
        "GetQuickInfo",
        createRequestBody({
          accessKey,
          guid: window.APIProvider?.recordPath?.GetRegions,
        })
      );
      // Use default regions if API returns error
      const regionData =
        response?.[0]?.Status === "ERROR" ? DEFAULT_REGIONS : response;
      setRegions(regionData);
      setError(null);
      return regionData;
    } catch (err) {
      setError(err.message);
      setRegions(DEFAULT_REGIONS);
      return null;
    } finally {
      setIsLoading(false);
    }
  }, [accessKey]);

  // Initial fetch on mount and when accessKey changes
  // useEffect(() => {
  //   if (!regions.length && accessKey) {
  //     fetchRegions();
  //   }
  // }, [fetchRegions, regions.length, accessKey]);

  useEffect(() => {
    if (Array.isArray(regions) && regions.length === 0 && accessKey) {
      fetchRegions();
    }
  }, [fetchRegions, regions, accessKey]);

  // Get info for a specific region
  const getRegionInfo = useCallback(
    async (regionName) => {
      if (!regionName) return "HI";

      // Fetch regions if not already loaded
      if (regions === undefined || regions?.length <= 0) {
        const ret = await fetchRegions();
        if (ret) {
          return (
            ret.find(
              (region) =>
                region?.Region?.toLowerCase() === regionName?.toLowerCase()
            ) || "1"
          );
        }
      } else {
        console.log("regions ", regions);
        return (
          regions.find(
            (region) =>
              region?.Region?.toLowerCase() === regionName?.toLowerCase()
          ) || "2"
        );
      }
    },
    [regions, fetchRegions]
  );

  // Get all region names
  const getRegions = useCallback(async () => {
    if (!regions.length) {
      const ret = await fetchRegions();
      return ret.map((region) => region.Region);
    } else {
      return regions.map((region) => region.Region);
    }
  }, [regions, fetchRegions]);

  // Memoize context value to prevent unnecessary rerenders
  const contextValue = React.useMemo(
    () => ({
      regions,
      isLoading,
      error,
      getRegionInfo,
      getRegions,
      loginData,
    }),
    [regions, isLoading, error, getRegionInfo, getRegions, loginData]
  );

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
}

// Custom hook for consuming the context
export function useAppContext() {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error("useAppContext must be used within an AppProvider");
  }

  return context;
}
