import React, { useEffect, useState } from "react";
import {
  Typography,
  InputLabel,
  Button,
  Select,
  MenuItem,
  TextField,
  FormControlLabel,
  FormControl,
  Checkbox,
} from "@mui/material";
import CustomPopUpForm from "../../layout/CustomPopUpForm";
import { Theme } from "../IncludeFile/Theme";

function Overview({
  open,
  overviewTitle,
  handlePopup,
  item,
  handleBack,
  handleNext,
  handleSave,
  data,
  overviewDirtyFlag,
  setOverviewDirtyFlag,
  nextButtonTitle,
}) {
  // Initialize state
  const [isEditable, setIsEditable] = useState(!item);
  const [isSaved, setIsSaved] = useState(false);
  const [nextClickedNoName, setNextClickedNoName] = useState(false);

  // Set initial form data
  const [formData, setFormData] = useState({
    diagTempID: item?.diagTempID ?? 0,
    Bid: item?.Bid ?? 0,
    name: item?.name ?? "",
    title: item?.title ?? "",
    explanation: item?.explanation ?? "",
    notes: item?.notes ?? "",
    showMinMap: item?.showMinMap ?? 1,
    layout: item?.layout ?? 1,
    nodes: item?.nodes ?? null,
    edges: item?.edges ?? null,
    viewPort: item?.viewPort ?? null,
    createdBy: item?.createdBy ?? null,
    createdDate: item?.createdDate ?? null,
    modifiedBy: item?.modifiedBy ?? null,
    modifiedDate: item?.modifiedDate ?? null,
  });

  // drop down options for template
  const options = data
    ?.filter((item) => item?.Bid === 0)
    .map((item) => ({
      value: item?.name,
      diagTempID: item?.diagTempID,
    }));

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? (checked ? 1 : 0) : value;

    // Update dirty flag if applicable
    setOverviewDirtyFlag?.(true);

    // Handle template name changes
    if (name === "Name") {
      const selectedOption = options.find((item) => item.value === value);
      setFormData({
        ...formData,
        [name]: newValue,
        DiagTempID: selectedOption?.diagTempID ?? formData.diagTempID,
      });
    } else {
      setFormData({
        ...formData,
        [name]: newValue,
      });
    }
  };

  // Handle save operation
  const handleSaveClick = async () => {
    setIsSaved(true);
    setOverviewDirtyFlag?.(false);
    if (formData.name) setIsEditable(false);
    await handleSave(formData);
  };

  // Handle cancel operation
  const handleCancelClick = () => {
    setIsEditable(false);
    setIsSaved(false);
    setFormData({
      ...formData,
      diagTempID: item?.diagTempID ?? 0,
      Bid: item?.Bid ?? 0,
      name: item?.name ?? "",
      title: item?.title ?? "",
      explanation: item?.explanation ?? "",
      notes: item?.notes ?? "",
      showMinMap: item?.showMinMap ?? 1,
      layout: item?.layout ?? 1,
    });
  };

  // Handle next button click
  const handleNextClick = async () => {
    if (overviewDirtyFlag) await handleSaveClick();

    if (formData.name) {
      setNextClickedNoName(false);
      console.log("false called");
      handleNext(formData.title ?? formData.name);
    } else {
      setNextClickedNoName(true);
      console.log("true called");
    }
  };

  // Action buttons for edit and save/cancel modes
  const iconButtons = isEditable
    ? [
        {
          icon: Theme.Restore,
          color: "primary",
          handleClick: handleCancelClick,
          tooltip: "Cancel",
        },
        {
          icon: Theme.Save,
          color: "primary",
          handleClick: handleSaveClick,
          tooltip: "Save",
        },
      ]
    : [
        {
          icon: Theme.Edit,
          color: "primary",
          handleClick: () => setIsEditable(true),
          tooltip: "Click To Edit",
        },
      ];

  return (
    <CustomPopUpForm
      open={open}
      onClose={handlePopup}
      title={overviewTitle ?? "Overview"}
      iconButtons={iconButtons}
    >
      <>
        {!isEditable ? (
          // Display form data in read-only mode
          <div className="card-info">
            <div className="card-info-inside">
              <Typography className="card-info-inside-text">
                {`Template Name:`}
              </Typography>
              <Typography style={{ color: "#000" }}>{formData.name}</Typography>
            </div>
            {["title", "explanation", "notes"].map((field) => (
              <div className="card-info-inside" key={field}>
                <Typography className="card-info-inside-text">
                  {`${field}:`}
                </Typography>
                <Typography style={{ color: "#000" }}>
                  {formData[field]}
                </Typography>
              </div>
            ))}
            {/* checkboxes */}
            <FormControlLabel
              className="text-formate"
              control={
                <Checkbox
                  checked={formData.showMinMap === 1}
                  onChange={handleInputChange}
                  name="showMinMap"
                  disabled
                />
              }
              label="Show Mini Map"
            />
            <FormControlLabel
              className="text-formate"
              control={
                <Checkbox
                  checked={formData.layout === 1}
                  onChange={handleInputChange}
                  name="layout"
                  disabled
                />
              }
              label="Show Layout Control"
            />
          </div>
        ) : (
          // Editable form for input fields
          <div className="card-info">
            <div className="card-info-inside">
              <Typography className="name-of-contol">Template Name:</Typography>
              {data ? (
                <FormControl fullWidth sx={{ width: "100%" }}>
                  <Select
                    className="cont-selct-name"
                    sx={{ width: "100%" }}
                    value={formData.name}
                    name="name"
                    onChange={handleInputChange}
                    size="small"
                  >
                    {options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <TextField
                  required
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  fullWidth
                />
              )}
              {(isSaved || nextClickedNoName) && !formData.name && (
                <Typography style={{ color: "red" }}>
                  Name is required
                </Typography>
              )}
            </div>

            <div className="card-info-inside">
              <Typography className="name-of-contol">{`Title:`}</Typography>
              <TextField
                name="title"
                value={formData.title}
                onChange={handleInputChange}
                fullWidth
              />
            </div>
            {/* Other editable fields */}
            {["explanation", "notes"].map((field) => (
              <div className="card-info-inside" key={field}>
                <Typography className="name-of-contol">{`${field}:`}</Typography>
                <TextField
                  name={field}
                  value={formData[field]}
                  onChange={handleInputChange}
                  fullWidth
                  multiline
                  InputProps={{
                    style: { padding: "7px" },
                  }}
                />
              </div>
            ))}

            {/* Checkboxes */}
            <FormControlLabel
              className="text-formate"
              control={
                <Checkbox
                  checked={formData.showMinMap === 1}
                  onChange={handleInputChange}
                  name="showMinMap"
                />
              }
              label="Show Mini Map"
            />
            <FormControlLabel
              className="text-formate"
              control={
                <Checkbox
                  checked={formData.layout === 1}
                  onChange={handleInputChange}
                  name="layout"
                />
              }
              label="Show Layout Control"
            />
            {/* {["showMinMap", "layout"].map((field) => (
              <FormControlLabel
                className="text-formate"
                key={field}
                control={
                  <Checkbox
                    checked={formData[field] === 1}
                    onChange={handleInputChange}
                    name={field}
                  />
                }
                label={field}
              />
            ))} */}
          </div>
        )}
      </>
      <>
        {/* Action buttons */}
        <Button
          fullWidth
          onClick={handleBack}
          sx={{ textTransform: "none", fontFamily: "Arial" }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          fullWidth
          onClick={handleNextClick}
          sx={{ textTransform: "none", fontFamily: "Arial" }}
        >
          {nextButtonTitle ? nextButtonTitle : "Next"}
        </Button>
      </>
    </CustomPopUpForm>
  );
}

export default Overview;
