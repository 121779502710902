import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  Typography,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { ThemeProvider, Tooltip } from "@mui/material";
import { themeProvider } from "../../ThemeProvider";
import { Theme } from "../IncludeFile/Theme";
import { MdOutlineEdit } from "react-icons/md";
import {
  createRequestBody,
  loadLoginData,
  postResource,
} from "../../apiCalls/DiagramsAPI";
import { getFormattedLocalDateTime } from "../../functions/ModifiedDate";
import CustomPopUpForm from "../../layout/CustomPopUpForm";
function NavBar({
  searchQuery,
  handleSearch,
  isSearchBar,
  leftButton,
  rightButtons,
  iconButtons,
  editButton,
  title,
  explanation,
  toggleMenu,
  isMenuOpenleft,
  dropdownOptions,
  selectedDropdownOption,
  handleDropdownChange,
  icon,
  isMenuOpenright,
  handleMenushwDskButtonClick,
  formData,
  handleEditToolsProperties,
  DiagTempID,
  selectedNodes,
}) {
  const [isEditingExplanation, setIsEditingExplanation] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);

  const [currentFormData, setCurrentFormData] = useState(
    formData || { title: "", explanation: "" }
  );

  const handleFieldSave = (field) => {
    if (field === "title") setIsEditingTitle(false);
    if (field === "explanation") setIsEditingExplanation(false);
  };

  const handleFieldChange = (field, value) => {
    setCurrentFormData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const authData = loadLoginData();
  const accessKey = authData?.accessKey || "";

  const handleSave = async (data) => {
    try {
      console.log("data Before", data);
      const userName = authData?.userName || "Unknown User";
      const todayDate = getFormattedLocalDateTime();
      console.log("todayDate ", todayDate);
      if (!data.createdBy) {
        console.log("data.createdBy ", data.createdBy);
        data.createdBy = userName;
      }
      if (!data.createdDate) {
        console.log("data.createdDate ", data.createdDate);
        data.createdDate = todayDate;
      }
      console.log("dataAfter", data);
      // const templatePostResponse = await postResource(
      //   "PostQuickInfo",
      //   createRequestBody({
      //     accessKey: accessKey,
      //     guid: `${window.APIProvider.recordPath.UpsertQinfoDiagTemplateHeader}`,
      //     body: JSON.stringify(data),
      //   })
      // );
      console.log("data ", JSON.stringify(data));
      data.description = data.explanation;
      const projectPostResponse = await postResource(
        "PostQuickInfo",
        createRequestBody({
          accessKey: accessKey,
          guid: `${window.APIProvider.recordPath.UpsertQinfoDiagHeader}`,
          body: JSON.stringify(data),
        })
      );
      console.log("data ", JSON.stringify(data));
      if (
        // templatePostResponse.Status === "ERROR"
        // &&
        projectPostResponse.Status === "ERROR"
      ) {
      }
    } catch (error) {
      console.error("Fetch Error ", error);
    }

    handlePopupClose();
  };

  const renderLeftButton = () =>
    leftButton ? (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Tooltip arrow title={leftButton.tooltip}>
          <Button
            variant="contained"
            color={leftButton.color || "primary"}
            style={{ height: "33px" }}
            onClick={leftButton.handleClick}
          >
            {leftButton.icon}
            <h6 className="addcontrollbutton">{leftButton.text}</h6>
          </Button>
        </Tooltip>
        {renderIconButtons()}
      </div>
    ) : (
      <div></div>
    );

  const renderIconButtons = () =>
    iconButtons
      ? iconButtons.map((iconButton, index) => (
          <Button
            key={index}
            variant="contained"
            onClick={iconButton.handleClick}
            style={{
              marginLeft: "5px",
              height: "33px",
            }}
          >
            <Tooltip arrow title={iconButton.tooltip}>
              {iconButton.icon}
            </Tooltip>
          </Button>
        ))
      : null;

  const renderRightButtons = () =>
    rightButtons &&
    rightButtons.map((button, index) => (
      <Button
        key={index}
        color={button.color || "secondary"}
        variant="contained"
        className="addandsavebutton"
        onClick={button.handleClick}
        style={{ height: "33px", marginLeft: "10px", fontSize: "1.4rem" }}
      >
        <Tooltip
          arrow
          title={
            <span className="tooltipstyletopnav">
              {button.tooltip ? button.tooltip : button.text}
            </span>
          }
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {button.icon}
            <h6 className="addcontrollbutton">{button.text}</h6>
          </div>
        </Tooltip>
      </Button>
    ));

  const renderEditButtons = () =>
    editButton &&
    editButton.map((button, index) => (
      <div>
        <Button
          key={index}
          color={button.color || "secondary"}
          // color={button.color || "secondary"}
          variant="contained"
          onClick={button.handleClick}
          disabled={button.disabled || ""}
          style={{ height: "33px", marginLeft: "10px", fontSize: "1.4rem" }}
          // style={{ height: "33px", marginLeft: "10px", fontSize: "1.4rem" }}
          // sx={{
          //   backgroundColor: button.disabled ? "grey !important" : undefined,
          //   ...button.sx,
          // }}
        >
          <Tooltip
            arrow
            title={
              <span className="tooltipstyletopnav">
                {button.tooltip ? button.tooltip : button.text}
              </span>
            }
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              {button.icon}
              <h6 className="addcontrollbutton">{button.text}</h6>
            </div>
          </Tooltip>
        </Button>
      </div>
    ));

  const inputRef = useRef(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const handlePopupOpen = () => {
    setIsPopupOpen(true);
  };
  const handlePopupClose = () => {
    setIsPopupOpen(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <ThemeProvider theme={themeProvider}>
      <div className="containerbtnsecction" style={{ paddingRight: "2px" }}>
        <div className="logo">
          <img src="https://agiline.com/wp-content/uploads/Agiline-Logo-2018.png"></img>
        </div>
        {isSearchBar && (
          <div className="menu-srch-form-wrap-frist">
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "30ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <div className="inputfield-wrap">
                <input
                  className="inputfield"
                  id="outlined-multiline-flexible"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => handleSearch(e)}
                  onKeyDown={handleKeyDown}
                  ref={inputRef}
                />
                <div className="inputfield-srch-icon">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      inputRef.current.focus();
                    }}
                  >
                    {Theme.Search}
                  </span>
                </div>
              </div>
            </Box>
          </div>
        )}

        {icon && (
          <Tooltip
            id="tooltipstyletoppanel"
            arrow
            title={
              <span className="tooltipstyletoppanel">Show/Hide left panel</span>
            }
          >
            <div
              onClick={handleMenushwDskButtonClick}
              className="left-sidebar-flow"
            >
              {Theme.Menu}
            </div>
          </Tooltip>
        )}

        {leftButton && (
          <div
            className="bordersec"
            style={{ marginLeft: !isSearchBar ? "0.5rem" : 0 }}
          />
        )}

        {isMenuOpenleft && (
          <div className="slect-wrap-slide">
            <Tooltip
              id="tooltipstyletoppanel"
              arrow
              title={
                <span className="tooltipstyletoppanel">
                  Show/Hide left panel
                </span>
              }
            >
              <span onClick={toggleMenu} className="left-panel-icon">
                {Theme.MenuOpenIcon}
              </span>
            </Tooltip>
          </div>
        )}

        <div
          className="addsavebtn"
          style={{
            height: "100%",
            width: "100%",
            alignItems: "center",
          }}
        >
          {renderLeftButton()}
          {title && (
            <Typography
              style={{
                color: themeProvider.palette.primary.main,
                fontWeight: "bold",
                fontSize: "25px",
                display: "flex",
                justifyContent: "center",
                whiteSpace: "nowrap",
              }}
            >
              {title}
            </Typography>
          )}
          {icon && (
            <>
              {/* <div className="title-container">
  <Typography className="custom-typogrpy">{title}</Typography>
 
  <div onClick={handlePopupOpen} className="edit-popup-drc-ttl">
    {Theme.Edit}
  </div>
</div> */}

              {/* {/ <div className="nav-title-dscr"> /} */}
              {/* <div onClick={handlePopupOpen} className="edit-popup-drc-ttl">
                     {Theme.Edit}
                   </div> */}
              <CustomPopUpForm
                iconButtons={iconButtons}
                open={isPopupOpen}
                onClose={handlePopupClose}
                title="Edit Information"
              >
                <div className="title-container">
                  <div
                    className="input-container-title"
                    style={{ width: "100%" }}
                  >
                    <div
                      style={{
                        fontWeight: "600",
                        paddingLeft: "5px",
                        color: themeProvider.palette.primary.main,
                      }}
                    >
                      Title
                    </div>
                    <div style={{ width: "100%" }}>
                      <TextField
                        size="small"
                        value={currentFormData.title}
                        onChange={(e) =>
                          handleFieldChange("title", e.target.value)
                        }
                        onBlur={() => handleFieldSave("title")}
                        autoFocus
                        className="dynamic-input-text"
                      />
                    </div>
                  </div>
                </div>
                <div className="explanation-container">
                  <div className="input-container-title">
                    <div
                      style={{
                        fontWeight: "600",
                        paddingLeft: "5px",
                        color: themeProvider.palette.primary.main,
                      }}
                    >
                      explanation
                    </div>
                    <div style={{ width: "94.3%" }}>
                      <TextField
                        size="small"
                        value={currentFormData.explanation}
                        onChange={(e) =>
                          handleFieldChange("explanation", e.target.value)
                        }
                        onBlur={() => handleFieldSave("explanation")}
                        autoFocus
                        className="dynamic-input-text"
                      />
                    </div>
                  </div>

                  <div className="btn-edit-popup">
                    <Button
                      sx={{
                        textTransform: "none",
                        fontFamily: "Arial",
                        border: `1px solid ${themeProvider.palette.primary.main}`,
                        "&:hover": {
                          boxShadow:
                            "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",
                        },
                      }}
                      smallwidth
                      onClick={handlePopupClose}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      smallwidth
                      sx={{ textTransform: "none", fontFamily: "Arial" }}
                      onClick={() => {
                        handleSave(currentFormData);
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </CustomPopUpForm>
              <div className="title-container">
                <Typography
                  style={{
                    color: themeProvider.palette.primary.main,
                    fontWeight: "bold",
                    fontSize: "25px",
                    display: "flex",
                    justifyContent: "center",
                    whiteSpace: "nowrap",
                  }}
                >
                  {currentFormData.title}
                </Typography>
                <div onClick={handlePopupOpen} className="edit-popup-drc-ttl">
                  {Theme.Edit}
                </div>
              </div>
              {/* {/ </div> /} */}
              {/* {/ <br></br> /} */}
            </>
            // </Tooltip>
          )}
          {/* {icon && (
                 <div className="nav-title-dscr">
<div onClick={handlePopupOpen} className="edit-popup-drc-ttl">
                     {Theme.Edit}
                   </div>
                 <CustomPopUpForm
                   iconButtons={iconButtons}
                   open={isPopupOpen}
                   onClose={handlePopupClose}
                   title="Edit Information"
                 >
                   <div className="title-container">
                     <div className="input-container-title">
                       <div className="name-of-contol">Title</div>
                       <div style={{ width: "100%" }}>
                         <TextField
                           size="small"
                           value={currentFormData.title}
                           onChange={(e) =>
                             handleFieldChange("title", e.target.value)
                           }
                           onBlur={() => handleFieldSave("title")}
                           autoFocus
                           className="dynamic-input-text"
                         />
                       </div>
                     </div>
                   </div>
                   <div className="explanation-container">
                     <div className="input-container-title">
                       <div className="name-of-contol">explanation</div>
                       <div style={{ width: "94.3%" }}>
                         <TextField
                           size="small"
                           value={currentFormData.explanation}
                           onChange={(e) =>
                             handleFieldChange("explanation", e.target.value)
                           }
                           onBlur={() => handleFieldSave("explanation")}
                           autoFocus
                           className="dynamic-input-text"
                         />
                       </div>
                     </div>
     
                     <div className="btn-edit-popup">
                       <Button
                         sx={{ textTransform: "none", fontFamily: "Arial" }}
                         smallwidth
                         onClick={handlePopupClose}
                         color="primary"
                       >
                         Cancel
                       </Button>
                       <Button
                         variant="contained"
                         smallwidth
                         sx={{ textTransform: "none", fontFamily: "Arial" }}
                         onClick={() => {
                           handleSave(currentFormData);
                         }}
                       >
                         Save
                       </Button>
                     </div>
                   </div>
                 </CustomPopUpForm>
                 <Typography className="custom-typogrpy">
                   {currentFormData.title}
                 </Typography>
               </div>
               )} */}

          {/* <div
            className="explanation-container"
            style={{ position: "relative", marginTop: "10px" }}
            onMouseOver={handleExplanationEdit}
            onMouseOut={() =>
              !isEditingExplanation && setIsEditingExplanation(false)
            }
          >
            {!isEditingExplanation ? (
              <>
                <Typography className="custom-typogrpy">
                  {currentFormData.explanation}
                </Typography>
                <Tooltip title="Edit Explanation">
                  <span
                    onClick={handleExplanationEdit}
                    style={{
                      position: "absolute",
                      right: "-20px",
                      top: "0",
                      cursor: "pointer",
                      color: "gray",
                    }}
                  >
                  </span>
                </Tooltip>
              </>
            ) : (
              <div style={{ display: "flex", alignItems: "center" }}>
                <TextField
                  size="small"
                  value={currentFormData.explanation} // Use current state
                  onChange={handleExplanationChange}
                  onBlur={handleExplanationSave}
                  autoFocus
                  style={{ width: "300px" }}
                />
              </div>
            )}
          </div> */}
          {/* <div className="nav-title-dscr">
            <div onClick={handlePopupOpen}>Edit icon</div> */}
          {/* <div
              className="title-container"
              onMouseOver={() => handleFieldEdit("title")}
              onMouseOut={() => !isEditingTitle && setIsEditingTitle(false)}
            >
              {!isEditingTitle ? (
                <>
                  <Typography className="custom-typogrpy">
                    {currentFormData.title}
                  </Typography>
                  <Tooltip title="Edit Title">
                    <span
                      onClick={() => handleFieldEdit("title")}
                      className="edit-icon-drc"
                    ></span>
                  </Tooltip>
                </>
              ) : (
                <div className="input-container-text">
                  <TextField
                    size="small"
                    value={currentFormData.title}
                    onChange={(e) => handleFieldChange("title", e.target.value)}
                    onBlur={() => handleFieldSave("title")}
                    autoFocus
                    className="dynamic-input-text"
                  />
                </div>
              )}
            </div>
            <div
              className="explanation-container"
              onFocus={() => handleFieldEdit("explanation")}
              onBlur={() => {
                if (!isEditingExplanation) setIsEditingExplanation(false);
              }}
              tabIndex={0} // Ensure the div is focusable
            >
              {!isEditingExplanation ? (
                <Typography className="cst-typo-decript" onClick={() => handleFieldEdit("explanation")}>
                  {currentFormData.explanation}
                </Typography>
              ) : (
                <div className="input-container-text">
                  <TextField
                    size="small"
                    value={currentFormData.explanation}
                    onChange={(e) =>
                      handleFieldChange("explanation", e.target.value)
                    }
                    onBlur={() => handleFieldSave("explanation")}
                    autoFocus
                    className="dynamic-input-text"
                  />
                </div>
              )} */}

          <div
            // className="save-export-btn"
            // className="save-export-btn"
            style={{ display: "flex", alignItems: "center", padding: "10px" }}
          >
            {dropdownOptions &&
              selectedDropdownOption !== null &&
              selectedDropdownOption !== undefined &&
              handleDropdownChange && (
                <Select
                  value={selectedDropdownOption}
                  onChange={(event) => handleDropdownChange(event.target.value)}
                  style={{ marginRight: "10px", height: "33px" }}
                  variant="outlined"
                  size="small"
                >
                  {dropdownOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              )}
            {renderEditButtons()}
            {renderRightButtons()}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default NavBar;
