import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { AppBar, Button } from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import LeftNavBar from "./LeftNavBar";
import themeProvider from "../../ThemeProvider";
import { useNavigate } from "react-router-dom";
import { Theme } from "../IncludeFile/Theme";
import { toast } from "react-toastify";

const drawerWidth = "200px";

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  backgroundColor: themeProvider.palette.landingBg.navBar,
  height: "55px",
  alignContent: "center",
  alignItems: "center",
  borderBottom: "1px solid #ccc",
}));

function LandingNavBar({ title, authData }) {
  const [open, setOpen] = React.useState(true);

  const navigate = useNavigate();

  const handleToggleDrawer = () => {
    setOpen(!open);
  };

  const handleLogOut = async () => {
    try {
      const response = await fetch(
        "https://aicloud.agiline.com:7355/EndSession",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            accessKey: authData.accessKey,
          }),
        }
      );

      if (response.ok) {
        const region = localStorage.getItem("region");
        const contentType = response.headers.get("Content-Type");

        if (contentType && contentType.includes("application/json")) {
          sessionStorage.clear();
          localStorage.clear();
          localStorage.setItem("region", region);
          navigate("/");
        } else {
          const textData = await response.text();
          sessionStorage.clear();
          localStorage.clear();
          localStorage.setItem("region", region);
          console.log("Logout successfully:", textData);
          toast.info("Logout successfully");
          navigate("/");
        }
      } else {
        console.error(
          "Failed to validate OTP:",
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.error("Error during OTP validation:", error);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <>
        <AppBarStyled position="fixed" open={open} elevation={0}>
          <div className="logo">
            <img src="https://agiline.com/wp-content/uploads/Agiline-Logo-2018.png"></img>
          </div>
          <IconButton
            // color="black"
            style={{ color: "black" }}
            aria-label="open drawer"
            onClick={handleToggleDrawer}
            edge="start"
            sx={{ mr: 1, ml: 1 }}
          >
            <MenuIcon />
          </IconButton>

          {title && (
            <Typography
              style={{
                color: themeProvider.palette.primary.main,
                fontWeight: "bold",
                fontSize: "25px",
                display: "flex",
                justifyContent: "center",
                whiteSpace: "nowrap",
              }}
            >
              {title}
            </Typography>
          )}
          <Button
            variant="contained"
            color="secondary"
            onClick={handleLogOut}
            style={{ position: "absolute", right: "10px" }}
          >
            {Theme.Logout}
            <span className="log-out-page">Logout</span>
          </Button>
        </AppBarStyled>
        {open && (
          <LeftNavBar
            open={open}
            drawerWidth={drawerWidth}
            authData={authData}
          />
        )}
      </>
    </Box>
  );
}

export default LandingNavBar;
