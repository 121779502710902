import React, { useState, useEffect } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  Box,
  Button,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "../../assets/icofontcharts/icofont.min.css";
import { ToastContainer, toast } from "react-toastify";
import ErrorBanner from "../../Error/ErrorBanner";

const ThemeContentData = React.memo(
  ({
    size,
    textSize,
    ArrayName,
    Description,
    DataSourceArID,
    ArID,
    handleDelete,
    Category,
    isTemplate,
    authData,
    defaultStyle,
    error,
    setError,
  }) => {
    const Navigate = useNavigate();
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
      useState(false);

    const openDeleteConfirmation = () => {
      setIsDeleteConfirmationOpen(true);
    };

    const closeDeleteConfirmation = () => {
      setIsDeleteConfirmationOpen(false);
    };

    const onDeleteConfirmation = () => {
      closeDeleteConfirmation();
      onDelete();
      toast.success("Record is deleted successfully");
    };

    const handleSelect = () => {
      const stateObject = {
        ArrayName,
        Description,
        Category,
        DataSourceArID,
        defaultStyle,
      };
      Navigate(`/Themes/${ArID}`, { state: stateObject });
    };

    let APIProvider = window.APIProvider;

    const navigate = useNavigate();

    const onDelete = async () => {
      if (!authData || !authData.accessKey) {
        navigate("/");
        sessionStorage.clear();
        return;
      }
      try {
        const requestBodyDelete = {
          accessKey: authData.accessKey,
          guid: APIProvider.GUID.DeleteArrayByarIDGUID,
          param1: String(ArID),
          param2: "",
          param3: "",
          param4: "",
          recordSet: "",
          body: "",
        };
        const response = await fetch(
          `${APIProvider.baseURL}${APIProvider.MethodName.GetQuickInfo}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBodyDelete),
          }
        );

        if (response.ok) {
          handleDelete(ArID);
        } else {
          console.error("Failed to delete item.");
          toast.error("Failed to delete item.");
        }
      } catch (error) {
        console.error("Error deleting item:", error);
        setError("An error occurred in Deleting Theme");
        localStorage.setItem("error", "An error occurred in Deleting Theme");
        localStorage.setItem("navigate", "/Open");
      }
    };

    return (
      <>
        <div className="card-form card-tostfy" style={{ display: "flex" }}>
          <ToastContainer position="bottom-right" autoClose={3000} />
          <>
           
              <Card
                sx={{
                  width: `${size}px`,
                  height: "auto",
                  marginBottom: "1rem",
                  marginRight: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  transition: "box-shadow 0.3s",
                  "&:hover": {
                    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.5)",
                  },
                }}
              >
                <CardContent>
                  <Box>
                    <div className="tmplt-icon-tst">
                      <div className="edit-title">
                        <div className="title-edit-txt-cntr">{ArrayName}</div>

                      <div className="iconset-delet">
                        <div className="Edit-del-icn">
                          <span
                            className="icon"
                            onClick={openDeleteConfirmation}
                          >
                            <HighlightOffIcon />
                          </span>

                          <div className="tooltiptext">Delete</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      fontSize: "12.75px",
                      textAlign: "center",
                      paddingTop: 3,
                    }}
                  >
                    {Description}
                  </div>

                    <div
                      style={{
                        fontSize: textSize * 0.75,
                        textAlign: "center",
                        paddingTop: 3,
                      }}
                    >
                      {Category}
                    </div>
                  </Box>
                </CardContent>
                <CardActions
                  sx={{
                    marginTop: "auto",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <>
                    <Button
                      sx={{
                        fontSize: "1rem",
                        width: "50%",
                        height: "85%",
                        borderRadius: "30px",
                      }}
                      variant="outlined"
                      onClick={handleSelect}
                    >
                      Edit
                    </Button>
                  </>
                </CardActions>
              </Card>
            

            <div>
              <Dialog
                open={isDeleteConfirmationOpen}
                onClose={closeDeleteConfirmation}
                maxWidth="md"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <div className="dashbord-delete-popup">
                  <div className="dashbord-delete-text">
                    <DialogTitle>
                      <div>
                        <HighlightOffIcon
                          style={{
                            fontSize: "3em",
                            color: "red",
                          }}
                        />
                        <IconButton
                          edge="end"
                          color="inherit"
                          onClick={closeDeleteConfirmation}
                          aria-label="close"
                          sx={{
                            position: "absolute",
                            right: "35px",
                            top: 20,
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </DialogTitle>
                    <DialogContent style={{ paddingBottom: "0px" }}>
                      <div className="card-dlt-icon-txt">
                        <h1>Confirmation</h1>
                        <p>
                          Do you really want to delete this record?
                          <br />
                          This process cannot be undone.
                        </p>
                      </div>
                    </DialogContent>
                    <DialogActions
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        paddingBottom: "20px",
                      }}
                    >
                      <Button
                        onClick={closeDeleteConfirmation}
                        sx={{
                          backgroundColor: "#008e05",
                          border: "1px solid #008e05",
                          color: "#fff",
                          margin: "0 5px",
                          padding: "3px 15px",
                          transition: "background-color 0.3s, color 0.3s",
                          textTransform: "capitalize",
                          "&:hover": {
                            backgroundColor: "#008e05",
                            color: "#ffffff",
                            boxShadow:
                              "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.1)",
                          },
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={onDeleteConfirmation}
                        sx={{
                          backgroundColor: "#ff0000",
                          margin: "0 5px",
                          color: "#ffffff",
                          padding: "3px 15px",
                          border: "1px solid #ff0000",
                          transition: "background-color 0.3s, border 0.3s",
                          textTransform: "capitalize",
                          "&:hover": {
                            backgroundColor: "#ff0000",
                            boxShadow:
                              "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.1)",
                          },
                        }}
                      >
                        Delete
                      </Button>
                    </DialogActions>
                  </div>
                </div>
              </Dialog>
            </div>
          </>
        </div>
        {error && <ErrorBanner />}
      </>
    );
  }
);

export default ThemeContentData;
