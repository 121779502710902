import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useLayoutEffect,
  useMemo,
} from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import DialogComponent from "./DialogComponent";
import {
  ReadArray,
  ReadArrayAll,
  WriteArray,
  WriteArray1,
  WriteArrayTheme,
} from "../Api/ApiCalls";
import TreeItem from "./TreeItem";
import "./RecursiveTreeView.css";
import Controls from "../IncludeFile/Controls";
import { ImFilePicture } from "react-icons/im";
import {
  buttonStylesFlat,
  buttonStylesGradient,
  colorSchemes,
  fontFamilies,
  Theme,
  themes,
} from "../IncludeFile/Theme";
import ReactDOMServer from "react-dom/server";
import CustomHtmlTag from "./CustomHtmlTag";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams, useLocation } from "react-router";
import Tooltip from "@mui/material/Tooltip";
import GeneralSection from "./GeneralSection";
import FormTopSection from "./FormTopSection";

import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  CircularProgress,
  Drawer,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  AddressJSON,
  DateJSON,
  DropDownJSON,
  EmailJSON,
  FullNameJSON,
  GenderCheckBoxJSON,
  GenderJSON,
  HeadingJSON,
  LongTextJSON,
  MultipleChoiceJSON,
  PhoneJSON,
  RatingJSON,
  ShortTextJSON,
  SingleChoiceJSON,
  StarJSON,
} from "../IncludeFile/AddJSON";
import AdvanceSection from "./AdvanceSection";
import OptionSection from "./OptionSection";
import CustomPopUpForm from "../../layout/CustomPopUpForm";
import { loadLoginData } from "../../apiCalls/DiagramsAPI";
import debounce from "lodash.debounce";
import ErrorBanner from "../../Error/ErrorBanner";

const RecursiveTreeView = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [treeData, setTreeData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [isBoxVisible, setIsBoxVisible] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedItemID, setSelectedItemID] = useState(null);
  const [popupPosition, setPopupPosition] = useState({ x: 0, y: 0 });
  const [renderedControls, setRenderedControls] = useState([]);
  const [selectedControl, setSelectedControl] = useState(null);
  const [contextMenuStyles, setContextMenuStyles] = useState("");
  const [customStyles, setCustomStyles] = useState({});
  const [editedName, setEditedName] = useState(" ");
  const [selectedItem, setSelectedItem] = useState(null);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [isOpen, setOpen] = React.useState(false);
  const [editedData, setEditedData] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [htmlContent, setHtmlContent] = useState("");
  const numberOfRadioInputs = 1;
  const numberOfCheckbox = 1;
  const [inputValue, setInputValue] = useState("");
  const [inputValueRadio, setInputValueRadio] = useState("");
  const [checkboxes, setCheckboxes] = useState([]);
  const [showmenu, setShowmenu] = useState(false);
  const [showtab, setShowtab] = useState(false);
  const [showtabdsk, setShowdsktab] = useState(false);
  const [showmenudsk, setShowdskmenu] = useState(false);
  const isChecked = false;
  const isSwitched = false;
  const checkboxPosition = 1;
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [tabSelect, settabSelect] = React.useState("Text");
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [selectedOptioncheck, setSelectedOptioncheck] = useState("false");
  const [selectedPositioncheck, setSelectedPositioncheck] = useState("right");
  const selectedOptionmedia = 1;
  const [selectedOptionSwitchcheck, setSelectedOptionSwitchcheck] =
    useState("false");
  const [fieldNames, setFieldNames] = useState([]);
  const [fieldNamestable, setFieldNamestable] = useState([]);
  const [displayClicked, setDisplayClicked] = useState(false);
  const [IconsClicked, setIconClicked] = useState(false);
  const [DataClicked, setDataClicked] = useState(false);
  const [ContainerIcon, setContainerIcon] = useState(false);
  const [InputClicked, setInputClicked] = useState(false);
  const [controlsVisible, setControlsVisible] = useState(true);
  const headerCount = 4;
  const cellCount = 4;
  const [tableHeader, setTableHeader] = useState("TableHeader");
  const [defaultStyle, setDefaultStyle] = useState("");
  const inputType = "Date";
  const inputText = "";
  const imageText = "";
  const [isDragging, setIsDragging] = useState(false);
  const [insertionPoint, setInsertionPoint] = useState(null);
  const [isRequiredFullName, setIsRequiredFullName] = useState("");
  const [isRequiredAddress, setIsRequiredAddress] = useState("");
  const [isRequiredEmail, setIsRequiredEmail] = useState("");
  const [isRequiredPhoneNumber, setIsRequiredPhoneNumber] = useState("");
  const fileName = null;
  const [editedDataFirstNameTextbox, setEditedDataFirstNameTextbox] =
    useState("");
  const [editedDataLastNameTextbox, setEditedDataLastNameTextbox] =
    useState("");
  const [editedDataPrefixTextbox, setEditedDataPrefixTextbox] = useState("");
  const [editedDataMiddleTextbox, setEditedDataMiddleTextbox] = useState("");
  const [CheckboxCheckedMiddleName, setCheckboxCheckedMiddleName] =
    useState(false);
  const [bgColor, setBgColor] = useState("#ffffff");
  const Navigate = useNavigate();
  const [tabValue, setTabValue] = useState("1");
  const inputRef = useRef(null);
  const selectedImage = null;
  const [tableProp, settableProp] = useState("");
  const [typeofcontrol, setTypeOFControl] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [CheckboxCheckedStreet1, setCheckboxCheckedStreet1] = useState("");
  const [CheckboxCheckedStreet2, setCheckboxCheckedStreet2] = useState("");
  const [CheckboxCheckedCity, setCheckboxCheckedCity] = useState("");
  const [CheckboxCheckedState, setCheckboxCheckedState] = useState("");
  const [CheckboxCheckedPostal, setCheckboxCheckedPostal] = useState("");
  const [CheckboxCheckedCountry, setCheckboxCheckedCountry] = useState("");
  const [CheckboxCheckedPrefix, setCheckboxCheckedPrefix] = useState(false);

  const [inputColor, setInputColor] = useState("#ffffff"); // Default color
  const [initialInputColorSet, setInitialInputColorSet] = useState(false);
  const [fontColor, setFontColor] = useState("#000000");

  const [FontSize, setFontSize] = useState(12);
  const [isFontSizeInput, setIsFontSizeInput] = useState(false);
  const [isFontFamilyInput, setIsFontFamilyInput] = useState(false);
  const [isFontColorInput, setIsFontColorInput] = useState(false);
  const [initialFontSizeSet, setInitialFontSizeSet] = useState(false);
  const [initialColorSet, setInitialColorSet] = useState(false);
  const [fontFamily, setFontFamily] = useState("Arial");
  const [showTheme, setShowTheme] = useState(true);
  const [themeArray, setThemeArray] = useState([]);
  const [themehtmlouput, setThemehtmlouput] = useState([]);
  const { arId } = useParams();
  const [editedDataStreet1Textbox, setEditedDataStreet1Textbox] = useState("");
  const [editedDataCityTextbox, setEditedDataCityTextbox] = useState("");
  const [editedDataStateTextbox, setEditedDataStateTextbox] = useState("");
  const [editedDataPostalTextbox, setEditedDataPostalTextbox] = useState("");

  const [currentTable, setCurrentTable] = useState("");

  const navigate = useNavigate();

  const rightsidetabs = [
    { label: "General", value: "1" },

    ...(selectedItem?.type !== "Root" ? [{ label: "Style", value: "2" }] : []),

    ...([
      "HeaderContainer",
      "Container",
      "FooterContainer",
      "Label",
      "Date",
      "RadioContainer",
      "Image",
      "Iframe",
      "Divider",
      "Header",
      "SubHeader",
      "List",
      "Table",
      "TableLabel",
      "Row",
      "Button",
      "TextBox",
      "TextArea",
      "Checkbox",
      "Dropdown",
      "Radio",
      "Switch",
      // "FullName",
      "Email",
      "Heading",
      "LongText",
      "PhoneNumber",
      "ShortText",
    ].includes(selectedItem?.controltype) ||
    selectedItem?.styleType === "Icon" ||
    selectedItem?.type === "Root"
      ? []
      : [{ label: "Options", value: "3" }]),
    ...([
      "HeaderContainer",
      "Container",
      "FooterContainer",
      "DateBlock",
      "Label",
      "Date",
      "RadioContainer",
      "Image",
      "Iframe",
      "Divider",
      "Header",
      "SubHeader",
      "List",
      "Table",
      "TableLabel",
      "Row",
      "Button",
      "TextBox",
      "TextArea",
      "Checkbox",
      "DropDown",
      "Radio",
      "Switch",
      "DropDown",
      "RatingBlock",
    ].includes(selectedItem?.controltype) ||
    selectedItem?.styleType === "Icon" ||
    selectedItem?.type === "Root"
      ? []
      : [{ label: "Advance", value: "4" }]),
  ];

  const authData = loadLoginData();

  const accessKey = authData?.accessKey || "";
  const userName = authData?.userName || "";

  const selectedTab = rightsidetabs.find((tab) => tab.value === tabValue);
  const rightsideStyletab = selectedTab ? selectedTab.value : null;

  const templateArid =
    treeData?.length > 0 && treeData[0]?.Source[0]?.templateArid;

  const findDatablockChildControlIds = (controls) => {
    const ids = [];
    try {
      for (const control of controls) {
        if (control.tableProp === "ChildBlock") {
          ids.push(control.id);
        }
        if (control.control && control.control.length > 0) {
          ids.push(...findDatablockChildControlIds(control.control));
        }
      }
    } catch (error) {
      console.error(
        "An error occurred while finding datablock child control IDs:",
        error
      );
      setError("An error occurred in findDatablockChildControlIds");
      localStorage.setItem(
        "error",
        "An error occurred in findDatablockChildControlIds"
      );
      localStorage.setItem("navigate", "/Open");
    }
    return ids;
  };

  const datablockChildControlIds =
    treeData && treeData[0] && treeData[0].Source
      ? findDatablockChildControlIds(treeData[0].Source)
      : null;

  const findChildControlsByOption = (controls, optionValue) => {
    const result = [];
    try {
      for (const control of controls) {
        if (control.option === optionValue) {
          result.push(control);
        }
        if (control.control && control.control.length > 0) {
          result.push(
            ...findChildControlsByOption(control.control, optionValue)
          );
        }
      }
    } catch (error) {
      console.error(
        "An error occurred while finding optionValue control values:",
        error
      );
      setError("An error occurred in findChildControlsByOption");
      localStorage.setItem(
        "error",
        "An error occurred in findChildControlsByOption"
      );
      localStorage.setItem("navigate", "/Open");
    }
    return result;
  };

  useEffect(() => {
    try {
      if (selectedItem) {
        setTypeOFControl(selectedItem.type);
      }
    } catch (error) {
      console.error("An error occurred in setTypeOFControl useEffect:", error);
      setError("An error occurred in setTypeOFControl useEffect");
      localStorage.setItem(
        "error",
        "An error occurred in setTypeOFControl useEffect"
      );
      localStorage.setItem("navigate", "/Open");
    }
  }, [selectedItem]);

  useEffect(() => {
    try {
      setSelectedControlType(typeofcontrol);
    } catch (error) {
      console.error(
        "An error occurred in setSelectedControlType useEffect:",
        error
      );
      setError("An error occurred in setSelectedControlType useEffect");
      localStorage.setItem(
        "error",
        "An error occurred in setSelectedControlType useEffect"
      );
      localStorage.setItem("navigate", "/Open");
    }
  }, [typeofcontrol]);

  useEffect(() => {
    try {
      if (
        controlsVisible &&
        datablockChildControlIds &&
        !datablockChildControlIds.includes(selectedItemID)
      ) {
        setIconsforFocus(selectedItemID);
      }
    } catch (error) {
      console.error("An error occurred in controlsVisible useEffect:", error);
      setError("An error occurred in controlsVisible useEffect");
      localStorage.setItem(
        "error",
        "An error occurred in controlsVisible useEffect"
      );
      localStorage.setItem("navigate", "/Open");
    }
  }, [customStyles, controlsVisible, selectedItemID, datablockChildControlIds]);

  const [selectedControlType, setSelectedControlType] = useState(typeofcontrol);

  const [readArrayStyles, setReadArrayStyles] = useState([]);

  const handleControlTypeChange = (event) => {
    try {
      const selectedValue = event.target.value;
      setSelectedControlType(selectedValue);

      const selectedItem = Controls.flatMap((category) => category.items).find(
        (item) => item.controltype === selectedValue
      );

      setDefaultStyle(readArrayStyles);

      const defaultStyles = Array.isArray(defaultStyle)
        ? defaultStyle.find(
            (style) => style.styleType === selectedItem.styleType
          )
        : null;

      const defaultArray = defaultStyles.style;

      const mergedStyles = {
        ...defaultArray,
      };

      setCustomStyles(mergedStyles);

      setEditedName(selectedValue);

      setSelectedItem((prevSelectedItem) => ({
        ...prevSelectedItem,
        controltype: selectedValue,
        type: selectedValue,
        styleType: selectedValue,

        htmlTag: selectedItem.htmlTag,
        styles: JSON.stringify({ ...mergedStyles }, null, 2),
        name: selectedValue,
        controlName: selectedValue,
        controlChange: selectedValue,
      }));

      const updatedTreeDataControlType = updateTreeDataControlType(
        treeData,
        selectedItemID,
        selectedValue,
        selectedItem.htmlTag,
        JSON.stringify({ ...mergedStyles }, null, 2),
        selectedControlType,
        selectedItem
      );
      setTreeData(updatedTreeDataControlType);
    } catch (error) {
      console.error("An error occurred in handleControlTypeChange:", error);
      setError("An error occurred in handleControlTypeChange");
      localStorage.setItem(
        "error",
        "An error occurred in handleControlTypeChange"
      );
      localStorage.setItem("navigate", "/Open");
    }
  };

  const updateTreeDataControlType = (
    treeData,
    selectedItemID,
    selectedValue,
    htmlTag,
    styles,
    name,
    selectedControlType
  ) => {
    try {
      return treeData.map((item) => {
        const updatedSourceControlType = item.Source.map((source) => ({
          ...source,
          control: updateControlsControlType(
            source.control,
            selectedItemID,
            selectedValue,
            htmlTag,
            styles,
            name,
            selectedControlType
          ),
        }));
        return { ...item, Source: updatedSourceControlType };
      });
    } catch (error) {
      console.error("An error occurred in updateTreeDataControlType:", error);
      setError("An error occurred in updateTreeDataControlType");
      localStorage.setItem(
        "error",
        "An error occurred in updateTreeDataControlType"
      );
      localStorage.setItem("navigate", "/Open");
    }
  };

  const updateControlsControlType = (
    controls,
    selectedItemID,
    selectedValue,
    htmlTag,
    styles,
    name,
    selectedControlType,
    selectedItem
  ) => {
    try {
      return controls.map((c) => {
        if (c.id === selectedItemID) {
          let dataValue = selectedValue;
          if (selectedValue === "Checkbox") {
            dataValue = "data";
          }
          return {
            ...c,
            controltype: selectedValue,
            type: selectedValue,
            styleType: selectedValue,

            htmlTag: htmlTag,
            styles: styles,
            name: selectedValue,
            controlName: selectedValue,
            controlChange: selectedValue,
          };
        }

        if (c.control && c.control.length > 0) {
          return {
            ...c,
            control: updateControlsControlType(
              c.control,
              selectedItemID,
              selectedValue,
              htmlTag,
              styles,
              name,
              selectedControlType,
              selectedItem
            ),
          };
        }
        return c;
      });
    } catch (error) {
      console.error("An error occurred in updateControlsControlType", error);
      setError("An error occurred in updateControlsControlType");
      localStorage.setItem(
        "error",
        "An error occurred in updateControlsControlType"
      );
      localStorage.setItem("navigate", "/Open");
    }
  };

  const updateControlsvalue = (
    controls,
    controlId,
    newValue,
    selectedOption,
    controlType
  ) => {
    try {
      return controls.map((c) => {
        // If the current control matches the controlId, update it
        if (c.id === controlId) {
          const updatedControl = {
            ...c,
            check:
              controlType === "Switch" ||
              controlType === "checkbox" ||
              controlType === "radio"
                ? newValue
                : c.check,
            switchcheck:
              controlType === "Switch" ||
              controlType === "checkbox" ||
              controlType === "radio"
                ? newValue
                : c.switchcheck,
            switchoption:
              controlType === "Switch" ||
              controlType === "checkbox" ||
              controlType === "radio"
                ? selectedOption
                : c.switchoption,
            option:
              controlType === "Switch" || controlType === "checkbox"
                ? selectedOption
                : c.option,
            media:
              controlType === "radio" ||
              controlType === "select" ||
              controlType === "Switch"
                ? selectedOption
                : c.media,
          };

          return updatedControl;
        }

        // If the control has children, recursively update them
        if (c.control && c.control.length > 0) {
          return {
            ...c,
            control: updateControlsvalue(
              c.control,
              controlId,
              newValue,
              selectedOption,
              controlType
            ),
          };
        }

        return c;
      });
    } catch (error) {
      console.error("An error occurred in updateControlsvalue", error);
      setError("An error occurred in updateControlsvalue");
      localStorage.setItem("error", "An error occurred in updateControlsvalue");
      localStorage.setItem("navigate", "/Open");
    }
  };

  const updateTreeDatavalue = (
    treeData,
    controlId,
    newValue,
    selectedOption,
    controlType
  ) => {
    console.log("controlType", controlType);
    try {
      return treeData.map((item) => {
        const updatedSource = item.Source.map((source) => ({
          ...source,
          control: updateControlsvalue(
            source.control,
            controlId,
            newValue,
            selectedOption,
            controlType
          ),
        }));
        console.log("updatedSource", updatedSource);
        return { ...item, Source: updatedSource };
      });
    } catch (error) {
      console.error("An error occurred in updateTreeDatavalue", error);
      setError("An error occurred in updateTreeDatavalue");
      localStorage.setItem("error", "An error occurred in updateTreeDatavalue");
      localStorage.setItem("navigate", "/Open");
    }
  };

  const handleSwitchChange = (
    newValue,
    switchId,
    selectedOptionSwitchcheck
  ) => {
    try {
      const updatedTreeData = updateTreeDatavalue(
        treeData,
        switchId,
        newValue,
        selectedOptionSwitchcheck,
        "Switch"
      );
      setTreeData(updatedTreeData);
      setSelectedOptionSwitchcheck(newValue ? "true" : "false");
    } catch (error) {
      console.error("An error occurred in Switch Change", error);
      setError("An error occurred in Switch Change");
      localStorage.setItem("error", "An error occurred in Switch Change");
      localStorage.setItem("navigate", "/Open");
    }
  };

  const handleSelectChange = (e, optionId) => {
    try {
      const selectedValue = e.target.value;
      const updatedTreeData = updateTreeDatavalue(
        treeData,
        optionId,
        selectedValue,
        selectedValue,
        "select"
      );
      setTreeData(updatedTreeData);
    } catch (error) {
      console.error("An error occurred in handleSelectChange", error);
      setError("An error occurred in Select Change");
      localStorage.setItem("error", "An error occurred in Select Change");
      localStorage.setItem("navigate", "/Open");
    }
  };

  const handleCheckboxPositionChange = (newValue, checkboxId) => {
    try {
      const updatedTreeData = updateTreeDataPosition(
        treeData,
        checkboxId,
        newValue,
        newValue ? "right" : "left"
      );
      setTreeData(updatedTreeData);
      setSelectedPositioncheck(newValue ? "right" : "left");
    } catch (error) {
      console.error("An error occurred in handleCheckboxPositionChange", error);
      setError("An error occurred in handleCheckboxPositionChange");
      localStorage.setItem(
        "error",
        "An error occurred in handleCheckboxPositionChange"
      );
      localStorage.setItem("navigate", "/Open");
    }
  };

  const updateTreeDataPosition = (
    treeData,
    checkboxId,
    newValue,
    selectedPositioncheck
  ) => {
    try {
      return treeData.map((item) => {
        const updatedSource = item.Source.map((source) => ({
          ...source,
          control: updateControlsPosition(
            source.control,
            checkboxId,
            newValue,
            selectedPositioncheck
          ),
        }));
        return { ...item, Source: updatedSource };
      });
    } catch (error) {
      console.error("An error occurred in updateTreeDataPosition", error);
      setError("An error occurred in updateTreeDataPosition");
      localStorage.setItem(
        "error",
        "An error occurred in updateTreeDataPosition"
      );
      localStorage.setItem("navigate", "/Open");
    }
  };

  const updateControlsPosition = (
    controls,
    checkboxId,
    newValue,
    selectedPositioncheck
  ) => {
    try {
      return controls.map((c) => {
        if (c.id === checkboxId) {
          return {
            ...c,
            media: newValue,
            checkposition: selectedPositioncheck,
          };
        }

        if (c.control && c.control.length > 0) {
          return {
            ...c,
            control: updateControlsPosition(
              c.control,
              checkboxId,
              newValue,
              selectedPositioncheck
            ),
          };
        }
        return c;
      });
    } catch (error) {
      console.error("An error occurred in updateControlsPosition", error);
      setError("An error occurred in updateControlsPosition");
      localStorage.setItem(
        "error",
        "An error occurred in updateControlsPosition"
      );
      localStorage.setItem("navigate", "/Open");
    }
  };

  const handleImageClick = () => {
    inputRef.current.click();
  };

  const handleImageChange = async (event, imageID) => {
    try {
      const file = event.target.files[0];
      if (!file) {
        return;
      }
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const img = document.createElement("img");

      img.onload = () => {
        const maxWidth = 200;
        const maxHeight = 200;

        let width = img.width;
        let height = img.height;

        if (width > maxWidth || height > maxHeight) {
          const aspectRatio = width / height;

          if (width > height) {
            width = maxWidth;
            height = width / aspectRatio;
          } else {
            height = maxHeight;
            width = height * aspectRatio;
          }
        }
        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const reader = new FileReader();

            reader.onload = () => {
              const imageUrl = reader.result;

              const updateTreeData = updateTreeDataImage(
                treeData,
                imageID,
                imageUrl
              );
              setTreeData(updateTreeData);
            };

            reader.readAsDataURL(blob);
          },
          "image/jpeg",
          0.1
        );
      };

      img.src = URL.createObjectURL(file);
    } catch (error) {
      console.error("An error occurred in Image Change", error);
      setError("An error occurred in Image Change");
      localStorage.setItem("error", "An error occurred in Image Change");
      localStorage.setItem("navigate", "/Open");
    }
  };

  const updateTreeDataImage = (treeData, imageID, imageUrl) => {
    try {
      return treeData.map((item) => {
        const updatedSource = item.Source.map((source) => ({
          ...source,
          control: updateControlsImage(source.control, imageID, imageUrl),
        }));
        return { ...item, Source: updatedSource };
      });
    } catch (error) {
      console.error("An error occurred in updateTreeDataImage", error);
      setError("An error occurred in updateTreeDataImage");
      localStorage.setItem("error", "An error occurred in updateTreeDataImage");
      localStorage.setItem("navigate", "/Open");
    }
  };

  const updateControlsImage = (controls, imageID, imageUrl) => {
    try {
      return controls.map((c) => {
        if (c.id === imageID) {
          return {
            ...c,
            media: imageUrl,
          };
        }

        if (c.control && c.control.length > 0) {
          return {
            ...c,
            control: updateControlsImage(c.control, imageID, imageUrl),
          };
        }
        return c;
      });
    } catch (error) {
      console.error("An error occurred in updateControlsImage", error);
      setError("An error occurred in updateControlsImage");
      localStorage.setItem("error", "An error occurred in updateControlsImage");
      localStorage.setItem("navigate", "/Open");
    }
  };

  const fixedIframeUrl = "https://aicloud.agiline.com";

  const [url, setURl] = useState(fixedIframeUrl);

  const handleIFrameClick = () => {
    try {
      const iframeUrl = inputRef.current.value;
      const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

      if (urlRegex.test(iframeUrl)) {
        const updateTreeData = updateTreeDataIFrame(
          treeData,
          selectedItem.id,
          iframeUrl
        );
        setTreeData(updateTreeData);
      } else {
        toast.error("Please enter a valid URL");
      }
    } catch (error) {
      console.error("An error occurred in IFrame Click", error);
      setError("An error occurred in IFrame Click");
      localStorage.setItem("error", "An error occurred in IFrame Click");
      localStorage.setItem("navigate", "/Open");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleIFrameClick();
    }
  };

  const handleIFrameChange = async (event, iframeID) => {
    try {
      const file = event.target.files[0];

      const reader = new FileReader();

      reader.onload = () => {
        const iframeUrl = reader.result;

        const updateTreeData = updateTreeDataIFrame(
          treeData,
          iframeID,
          iframeUrl
        );
        setTreeData(updateTreeData);
      };

      reader.readAsDataURL(file);
    } catch (error) {
      console.error("An error occurred in IFrame Change", error);
      setError("An error occurred in IFrame Change");
      localStorage.setItem("error", "An error occurred in IFrame Change");
      localStorage.setItem("navigate", "/Open");
    }
  };

  const updateTreeDataIFrame = (treeData, iframeID, iframeUrl) => {
    try {
      const updatedTreeData = treeData.map((item) => {
        const updatedSource = item.Source.map((source) => ({
          ...source,
          control: updateControlsIFrame(source.control, iframeID, iframeUrl),
        }));
        return { ...item, Source: updatedSource };
      });
      return updatedTreeData;
    } catch (error) {
      console.error("An error occurred in updateTreeDataIFrame", error);
      setError("An error occurred in updateTreeDataIFrame");
      localStorage.setItem(
        "error",
        "An error occurred in updateTreeDataIFrame"
      );
      localStorage.setItem("navigate", "/Open");
    }
  };

  const updateControlsIFrame = (controls, iframeID, iframeUrl) => {
    try {
      return controls.map((c) => {
        if (c.id === iframeID) {
          return {
            ...c,
            data: iframeUrl,
          };
        }

        if (c.control && c.control.length > 0) {
          return {
            ...c,
            control: updateControlsIFrame(c.control, iframeID, iframeUrl),
          };
        }
        return c;
      });
    } catch (error) {
      console.error("An error occurred in updateControlsIFrame", error);
      setError("An error occurred in updateControlsIFrame");
      localStorage.setItem(
        "error",
        "An error occurred in updateControlsIFrame"
      );
      localStorage.setItem("navigate", "/Open");
    }
  };

  const [selectedValue, setSelectedValue] = useState("");

  const RadioPosition = 1;

  const handleRadioPositionChange = (position, uniqueId) => {
    try {
      const newPosition = parseInt(position);
      const updateTreeData = updateTreeDataRadioPosition(
        treeData,
        uniqueId,
        newPosition
      );
      setTreeData(updateTreeData);
    } catch (error) {
      console.error("An error occurred in handleRadioPositionChange", error);
      setError("An error occurred in handleRadioPositionChange");
      localStorage.setItem(
        "error",
        "An error occurred in handleRadioPositionChange"
      );
      localStorage.setItem("navigate", "/Open");
    }
  };

  const updateTreeDataRadioPosition = (treeData, uniqueId, newPosition) => {
    try {
      return treeData.map((item) => {
        const updatedSource = item.Source.map((source) => ({
          ...source,
          control: updateControlsRadioPosition(
            source.control,
            uniqueId,
            newPosition
          ),
        }));
        return { ...item, Source: updatedSource };
      });
    } catch (error) {
      console.error("An error occurred in updateTreeDataRadioPosition", error);
      setError("An error occurred in updateTreeDataRadioPosition");
      localStorage.setItem(
        "error",
        "An error occurred in updateTreeDataRadioPosition"
      );
      localStorage.setItem("navigate", "/Open");
    }
  };

  const updateControlsRadioPosition = (controls, uniqueId, newPosition) => {
    try {
      return controls.map((c) => {
        if (c.id === uniqueId) {
          return {
            ...c,
            media: newPosition,
          };
        }

        if (c.control && c.control.length > 0) {
          return {
            ...c,
            control: updateControlsPosition(c.control, uniqueId, newPosition),
          };
        }
        return c;
      });
    } catch (error) {
      console.error("An error occurred in updateControlsRadioPosition", error);
      setError("An error occurred in updateControlsRadioPosition");
      localStorage.setItem(
        "error",
        "An error occurred in updateControlsRadioPosition"
      );
      localStorage.setItem("navigate", "/Open");
    }
  };

  const handleRadioChangeData = (e, selectedItem, uniqueKey) => {
    try {
      setSelectedValue(selectedItem);

      setTreeData((prevTreeData) => {
        const updatedTreeData = prevTreeData.map((item) => ({
          ...item,
          Source: item.Source.map((source) => ({
            ...source,
            control: updateControlCheckData(
              source.control,
              selectedItem,
              uniqueKey
            ),
          })),
        }));

        return updatedTreeData;
      });
    } catch (error) {
      console.error("An error occurred in handleRadioChangeData", error);
      setError("An error occurred in handleRadioChangeData");
      localStorage.setItem(
        "error",
        "An error occurred in handleRadioChangeData"
      );
      localStorage.setItem("navigate", "/Open");
    }
  };

  const updateControlCheckData = (controls, selectedItem, uniqueKey) => {
    try {
      return controls.map((c) => {
        if (c.uniqueKey && c.uniqueKey.includes(selectedItem)) {
          const updatedCheckData = {
            ...c.data,
            [selectedItem]: !c.data[selectedItem],
          };

          return {
            ...c,
            data: updatedCheckData,
          };
        }

        if (c.control && c.control.length > 0) {
          return {
            ...c,
            control: updateControlCheckData(c.control, selectedItem, uniqueKey),
          };
        }
        return c;
      });
    } catch (error) {
      console.error("An error occurred in updateControlCheckData", error);
      setError("An error occurred in updateControlCheckData");
      localStorage.setItem(
        "error",
        "An error occurred in updateControlCheckData"
      );
      localStorage.setItem("navigate", "/Open");
    }
  };

  const handleCheckboxChange = (newValue, checkboxId, selectedOptioncheck) => {
    try {
      const updatedTreeData = updateTreeDatavalue(
        treeData,
        checkboxId,
        newValue,
        selectedOptioncheck,
        "checkbox"
      );
      setTreeData(updatedTreeData);
      setSelectedOptioncheck(newValue ? "true" : "false");
    } catch (error) {
      console.error("An error occurred in handleCheckboxChange", error);
      setError("An error occurred in handleCheckboxChange");
      localStorage.setItem(
        "error",
        "An error occurred in handleCheckboxChange"
      );
      localStorage.setItem("navigate", "/Open");
    }
  };

  const handleRadioChange = (newValue, radioId, selectedOptioncheck) => {
    const updatedTreeData = updateTreeDataRadio1(
      treeData,
      radioId,
      newValue,
      selectedOptioncheck
    );
    setSelectedOptioncheck(newValue ? "true" : "false");
    setTreeData(updatedTreeData);
  };

  const updateTreeDataRadio1 = (
    treeData,
    radioId,
    newValue,
    selectedOptioncheck
  ) => {
    return treeData.map((item) => {
      const updatedSource = item.Source.map((source) => ({
        ...source,
        control: updateControlsRadio1(
          source.control,
          radioId,
          newValue,
          selectedOptioncheck
        ),
      }));
      return { ...item, Source: updatedSource };
    });
  };

  const updateControlsRadio1 = (
    controls,
    radioId,
    newValue,
    selectedOptioncheck
  ) => {
    let parentid = null;

    controls.forEach((c) => {
      if (c.id === radioId) {
        parentid = c.parentid;
      }
    });

    return controls.map((c) => {
      if (c.id === radioId) {
        return {
          ...c,
          check: newValue,
          option: selectedOptioncheck,
        };
      }
      if (c.parentid === parentid && c.controltype === "Radio") {
        return {
          ...c,
          check: false,
          option: selectedOptioncheck,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlsRadio1(
            c.control,
            radioId,
            newValue,
            selectedOptioncheck
          ),
        };
      }

      return c;
    });
  };

  const handleChange = (event) => {
    try {
      const newTab = event.target.value;
      settabSelect(newTab);
      const updatedTreeData = updateTreeDatanewTab(
        treeData,
        selectedItemID,
        newTab
      );

      setTreeData(updatedTreeData);
    } catch (error) {
      console.error("An error occurred in handleChange", error);
      setError("An error occurred in handleChange", error);
    }
  };

  const updateTreeDatanewTab = (treeData, selectedItemID, newTab) => {
    return treeData.map((item) => {
      const updatedSourcenewTab = item.Source.map((source) => ({
        ...source,
        control: updateControlsnewTab(source.control, selectedItemID, newTab),
      }));

      return { ...item, Source: updatedSourcenewTab };
    });
  };

  const updateControlsnewTab = (controls, selectedItemID, newTab) => {
    return controls.map((c) => {
      if (c.id === selectedItemID) {
        return {
          ...c,
          tab: newTab,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlsnewTab(c.control, selectedItemID, newTab),
        };
      }
      return c;
    });
  };

  const [Radios, setRadios] = useState([]);

  const handleInputChangeRadio = (e) => {
    try {
      let value = parseFloat(e.target.value);
      value = Math.min(Math.max(value, 1), 100);
      setInputValueRadio(value);
    } catch (error) {
      console.error("An error occurred in handleInputChangeRadio", error);
      setError("An error occurred in handleInputChangeRadio", error);
    }
  };

  const handleAddControlRadio = () => {
    try {
      const numberToAdd = parseFloat(inputValueRadio);

      const newRadio = Array.from({ length: numberToAdd }, (_, index) => ({
        key: Radios.length + index + 1,
        value: Radios.length + index + 1,
      }));

      setRadios((prevRadios) => [...prevRadios, ...newRadio]);
      setInputValueRadio("");
      handleAddControl("Radio", {}, newRadio);
    } catch (error) {
      console.error("An error occurred in handleAddControlRadio", error);
      setError("An error occurred in handleAddControlRadio", error);
    }
  };

  const handleInputChange = (e) => {
    try {
      let value = parseFloat(e.target.value);
      value = Math.min(Math.max(value, 1), 100);
      setInputValue(value);
    } catch (error) {
      console.error("An error occurred in handleInputChange", error);
      setError("An error occurred in handleInputChange", error);
    }
  };

  const handleAddControlCheckbox = (
    checkValue,
    selectedCheckboxId,
    checkboxPosition
  ) => {
    try {
      let numberToAdd = parseFloat(inputValue);

      const newCheckboxes = Array.from({ length: numberToAdd }, (_, index) => ({
        key: checkboxes.length + index + 1,
        value: checkboxes.length + index + 1,
      }));

      setCheckboxes((prevCheckboxes) => [...prevCheckboxes, ...newCheckboxes]);
      setInputValue("");
      handleAddControl(
        "Checkbox",
        { check: checkValue },
        newCheckboxes,
        selectedCheckboxId
      );
    } catch (error) {
      console.error("An error occurred in handleAddControlCheckbox", error);
      setError("An error occurred in handleAddControlCheckbox", error);
    }
  };

  const handleEditChange = (event) => {
    try {
      let newValue = event.target.value;

      if (newValue === "xx") {
        newValue = "";
      }

      if (/^"(.*)"$/g.test(newValue)) {
        newValue = newValue.replace(/^"(.*)"$/, "'$1'");
      }

      setEditedData(newValue);
      setSelectedOption(newValue);
      const updatedTreeData = updateTreeDatanewValue(
        treeData,
        selectedItemID,
        newValue
      );
      setTreeData(updatedTreeData);
    } catch (error) {
      console.error("An error occurred in handleEditChange", error);
      setError("An error occurred in handleEditChange", error);
    }
  };

  const updateTreeDatanewValue = (treeData, selectedItemID, newValue) => {
    return treeData.map((item) => {
      const updatedSourcenewValue = item.Source.map((source) => ({
        ...source,
        control: updateControlsnewValue(
          source.control,
          selectedItemID,
          newValue
        ),
      }));
      return { ...item, Source: updatedSourcenewValue };
    });
  };

  const updateControlsnewValue = (
    controls,
    selectedItemID,
    newValue,
    selectedItemType
  ) => {
    return controls.map((c) => {
      if (c.id === selectedItemID) {
        if (selectedItem.type === "Date") {
          return {
            ...c,
            inputText: newValue,
          };
        } else if (
          selectedItem.type === "Image" ||
          selectedItem.type === "Switch" ||
          selectedItem.type === "Iframe"
        ) {
          return {
            ...c,
            imageText: newValue,
          };
        } else if (selectedItem.type === "Checkbox") {
          return {
            ...c,
            data: newValue,
          };
        } else {
          return {
            ...c,
            data: newValue,
          };
        }
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlsnewValue(
            c.control,
            selectedItemID,
            newValue,
            selectedItemType
          ),
        };
      }
      return c;
    });
  };

  const handleEditChangeforTable = (event) => {
    try {
      let newValue = event.target.value;

      if (/^"(.*)"$/g.test(newValue)) {
        newValue = newValue.replace(/^"(.*)"$/, "'$1'");
      }

      setEditedData(newValue);
      setSelectedOption(newValue);
      const updatedTreeDataforTable = updateTreeDatanewValueforTable(
        treeData,
        selectedItemID,
        newValue
      );
      setTreeData(updatedTreeDataforTable);
    } catch (error) {
      console.error("An error occurred in handleEditChangeforTable", error);
      setError("An error occurred in handleEditChangeforTable", error);
    }
  };

  const updateTreeDatanewValueforTable = (
    treeData,
    selectedItemID,
    newValue
  ) => {
    return treeData.map((item) => {
      const updatedSourcenewValueforTable = item.Source.map((source) => ({
        ...source,
        control: updateControlsnewValueforTable(
          source.control,
          selectedItemID,
          newValue
        ),
      }));
      return { ...item, Source: updatedSourcenewValueforTable };
    });
  };

  function checkOptionMatch(control, option, newValue) {
    if (control.tableHeader === option) {
      control.data = newValue;
    }

    if (control.control && Array.isArray(control.control)) {
      control.control.forEach((childControl) =>
        checkOptionMatch(childControl, option, newValue)
      );
    }
  }

  const updateControlsnewValueforTable = (
    controls,
    selectedItemID,
    newValue,
    selectedItemType
  ) => {
    return controls.map((c) => {
      if (c.id === selectedItemID) {
        let updatedValue = newValue;
        if (c.htmlTag === "td") {
          updatedValue = `{${newValue}}`;
          setEditedData(updatedValue);
          setSelectedOption(updatedValue);
          treeData.forEach((root) => {
            root.Source.forEach((source) => {
              source.control.forEach((control) => {
                checkOptionMatch(control, c.option, newValue);
              });
            });
          });
        }
        if (selectedItemType === "Date") {
          return {
            ...c,
            inputText: newValue,
          };
        } else if (
          selectedItemType === "Image" ||
          selectedItemType === "Switch" ||
          selectedItemType === "Iframe"
        ) {
          return {
            ...c,
            imageText: newValue,
          };
        } else if (selectedItemType === "Checkbox") {
          return {
            ...c,
            data: newValue,
          };
        } else {
          return {
            ...c,
            data: updatedValue,
          };
        }
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlsnewValueforTable(
            c.control,
            selectedItemID,
            newValue,
            selectedItemType
          ),
        };
      }
      return c;
    });
  };

  const handlePopupClick = () => {
    try {
      setOpen(true);
      setPopupVisible(true);
    } catch (error) {
      console.error("An error occurred in handlePopupClick", error);
      setError("An error occurred in handlePopupClick", error);
    }
  };

  const handleAgree = () => {
    try {
      let isValid = true;
      let errorMessage = null;
      let regex = null;

      switch (selectedItem.type) {
        case "TextBox":
          switch (tabSelect) {
            case "Phone Number":
              regex = /^(\+\d{1,3}[- ]?)?\d{10}$/;
              errorMessage = "Invalid phone number";
              break;
            case "Email":
              regex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
              errorMessage = "Invalid email address";
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }

      if (regex) {
        isValid = regex.test(editedData);
      }

      if (isValid) {
        const updatedTreeData = updateTreeData(
          treeData,
          selectedItemID,
          editedData
        );

        setTreeData(updatedTreeData);
        handleClose();
      } else {
        toast.error(errorMessage);
      }
    } catch (error) {
      console.error("An error occurred in handleAgree", error);
      setError("An error occurred in handleAgree", error);
    }
  };

  const updateTreeData = (
    treeData,
    selectedItemID,
    editedData,
    selectedOption
  ) => {
    return treeData.map((item) => {
      const updatedSource = item.Source.map((source) => ({
        ...source,
        control: updateControls(
          source.control,
          selectedItemID,
          editedData,
          selectedOption
        ),
      }));
      return { ...item, Source: updatedSource };
    });
  };

  const updateControls = (
    controls,
    selectedItemID,
    editedData,
    selectedOption
  ) => {
    return controls.map((c) => {
      if (c.id === selectedItemID) {
        return {
          ...c,
          data: editedData || selectedOption,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControls(
            c.control,
            selectedItemID,
            editedData,
            selectedOption
          ),
        };
      }
      return c;
    });
  };

  const handleEditChangeName1 = (e) => {
    try {
      let newValue = e.target.value;

      if (/^"(.*)"$/g.test(newValue)) {
        newValue = newValue.replace(/^"(.*)"$/, "'$1'");
      }

      setEditedName(newValue);

      if (selectedItem) {
        setSelectedItem((prevSelectedItem) => ({
          ...prevSelectedItem,
          name: newValue,
          tableHeader: newValue,
        }));
      }
    } catch (error) {
      console.error("An error occurred in handleEditChangeName1", error);
      setError("An error occurred in handleEditChangeName1", error);
    }
  };

  const collectControlNames = (controls, names) => {
    try {
      controls.forEach((control) => {
        names.push(control.name);
        if (control.control && control.control.length > 0) {
          collectControlNames(control.control, names);
        }
      });
    } catch (error) {
      console.error("An error occurred in collectControlNames", error);
      setError("An error occurred in collectControlNames", error);
    }
  };

  const controlNames = [];

  if (treeData?.length > 0) {
    treeData.forEach((item) => {
      if (item.Source && item.Source.length > 0) {
        item.Source.forEach((source) => {
          if (source.control && source.control.length > 0) {
            collectControlNames(source.control, controlNames);
          }
        });
      }
    });
  }

  const handleRenameConfirm = () => {
    try {
      if (!selectedItem) {
        return;
      }

      const updatedTreeName = updateTreeName(
        treeData,
        selectedItemID,
        editedName
      );
      setTreeData(updatedTreeName);
      setIsEditing(!isEditing);

      setSelectedItem((prevSelectedItem) => ({
        ...prevSelectedItem,
        name: editedName,
      }));
    } catch (error) {
      console.error("An error occurred in handleRenameConfirm", error);
      setError("An error occurred in handleRenameConfirm", error);
    }
  };

  const updateTreeName = (treeData, selectedItemID, editedName) => {
    return treeData.map((item) => {
      return {
        ...item,
        Source: item.Source.map((sourceItem) => {
          return updateControlsName(sourceItem, selectedItemID, editedName);
        }),
      };
    });
  };

  const updateControlsName = (controls, selectedItemID, editedName) => {
    if (controls.id === selectedItemID) {
      return {
        ...controls,
        name: editedName,
      };
    }

    if (controls.control) {
      return {
        ...controls,
        control: controls.control.map((controlItem) => {
          return updateControlsName(controlItem, selectedItemID, editedName);
        }),
      };
    }

    return controls;
  };

  const handleClose = () => {
    try {
      // setShowPopup1(false);
      setOpen(false);
      setShowPopup(false);
      const prevFocusedElement = document.querySelector(".focusingClass");
      const settingWrapper = document.querySelector(".setting-icon-wrapper");
      const DeleteWrapper = document.querySelector(".delete-icon-wrapper");
      if (prevFocusedElement) {
        prevFocusedElement.classList.remove("focusingClass");
      }
      if (settingWrapper) {
        settingWrapper.remove();
      } else {
      }
      if (DeleteWrapper) {
        DeleteWrapper.remove();
      } else {
      }
    } catch (error) {
      console.error("An error occurred in handleClose", error);
      setError("An error occurred in handleClose", error);
    }
  };

  const handleSearch = (event) => {
    try {
      const value = event.target.value;
      setSearchQuery(value);
      setContainerIcon(true);
      setIconClicked(true);
      setDataClicked(true);
      setInputClicked(true);
      setDisplayClicked(true);
      if (window.innerWidth > 991) {
        setShowdskmenu(false);
        setShowmenu(false);
      }
      if (window.innerWidth < 992) {
        setShowmenu(true);
        setShowdskmenu(false);
      }
    } catch (error) {
      console.error("An error occurred in handleSearch", error);
      setError("An error occurred in handleSearch", error);
    }
  };

  const filterTreeData = (data, query) => {
    try {
      if (!query) {
        return data;
      }

      return data
        .map((item) => {
          const filteredChildren = item.control
            ? filterTreeData(item.control, query)
            : [];

          if (
            item.name.toLowerCase().includes(query.toLowerCase()) ||
            filteredChildren.length > 0
          ) {
            return {
              ...item,
              control: filteredChildren,
            };
          }

          return null;
        })
        .filter(Boolean);
    } catch (error) {
      console.error("An error occurred in filterTreeData", error);
      setError("An error occurred in filterTreeData", error);
    }
  };

  const findNodeById = (node, id) => {
    try {
      if (node.id === id) {
        return node;
      }
      if (node.control && node.control.length > 0) {
        for (const childNode of node.control) {
          const foundNode = findNodeById(childNode, id);
          if (foundNode) {
            return foundNode;
          }
        }
      }
      return null;
    } catch (error) {
      console.error("An error occurred in findNodeById", error);
      setError("An error occurred in findNodeById", error);
    }
  };

  const handleAddItem = () => {
    try {
      setTreeData([...treeData]);
    } catch (error) {
      console.error("An error occurred in handleAddItem", error);
      setError("An error occurred in handleAddItem", error);
    }
  };

  const [error, setError] = useState(null);

  const handleControls = () => {
    try {
      if (selectedNode.title === "Datablocks") {
        const prevIcons = document.querySelectorAll(".icon-container");
        prevIcons.forEach((icon) => icon.remove());

        // Remove the 'focusingClass' from any previously focused element
        const prevFocusedElement = document.querySelector(".focusingClass");
        if (prevFocusedElement) {
          prevFocusedElement.classList.remove("focusingClass");
        }

        // Get the first item from treeData
        const firstItem = treeData[0]?.Source[0];
        const firstItemElement = document.getElementById(firstItem?.id);

        // Add 'focusingClass' to the new first item element
        if (firstItemElement) {
          firstItemElement.classList.add("focusingClass");
        }

        // Update states with the new first item
        setSelectedNode(firstItem);
        setSelectedItem(firstItem);
        setSelectedControl(firstItem);
        setEditedName(firstItem?.name);
        setSelectedItemID(firstItem?.id);
      }

      // Toggle visibility and open dialog
      setIsBoxVisible(!isBoxVisible);
      setOpenDialog(true);
      setShowdskmenu(false);
    } catch (error) {
      console.error("An error occurred in adding Controls", error);
      setError("An error occurred in adding Controls", error);
      localStorage.setItem("error", "An error occurred in adding Controls");
      localStorage.setItem("navigate", "/Open");
    }
  };

  const paintIconClick = () => {
    try {
      const prevFocusedElement = document.querySelector(".focusingClass");
      if (prevFocusedElement) {
        prevFocusedElement.classList.remove("focusingClass");
        const prevId = prevFocusedElement.id;
        const prevIconContainer = document.getElementById(
          `iconContainer-${prevId}`
        );
        if (prevIconContainer) {
          prevIconContainer.remove();
        }
      }

      const settingWrapper = document.querySelector(".setting-icon-wrapper");
      const DeleteWrapper = document.querySelector(".delete-icon-wrapper");

      if (settingWrapper) {
        settingWrapper.remove();
      }
      if (DeleteWrapper) {
        DeleteWrapper.remove();
      }

      const prevIcons = document.querySelectorAll(".icon-container");

      prevIcons.forEach((icon) => {
        icon.remove();
      });
      setShowdsktab(false);
      setOpenpaint(true);
      setShowmenu(true);
      setShowdskmenu(true);
    } catch (error) {
      console.error("An error occurred in paintIconClick", error);
      setError("An error occurred in paintIconClick", error);
    }
  };

  const handleMenuButtonClick = () => {
    try {
      // setShowmenu(!showmenu);
      setShowdskmenu(false);
      setOpenpaint(false);
    } catch (error) {
      console.error("An error occurred in handleMenuButtonClick", error);
      setError("An error occurred in handleMenuButtonClick", error);
    }
  };

  const handleCloseLeftMenu = () => {
    try {
      setShowmenu(!showmenu);
      // setShowdskmenu(false);
      setOpenpaint(false);
    } catch (error) {
      console.error("An error occurred in handleCloseLeftMenu", error);
      setError("An error occurred in handleCloseLeftMenu", error);
    }
  };

  const onClickforFocus = (e) => {
    try {
      // If the clicked element is in datablockChildControlIds, focus on its parent with title "Datablocks"
      if (!datablockChildControlIds.includes(e.target.id)) {
        e.preventDefault();

        const prevFocusedElement = document.querySelector(".focusingClass");
        const element = document.getElementById(e.target.id);

        if (element && element.hasAttribute("styleid")) {
          const styleId = element.getAttribute("styleid");

          // Remove 'focusingClass' and icons from previously focused element
          if (prevFocusedElement) {
            prevFocusedElement.classList.remove("focusingClass");

            const prevId = prevFocusedElement.id;
            const prevIconContainer = document.getElementById(
              `iconContainer-${prevId}`
            );
            if (prevIconContainer) {
              prevIconContainer.remove(); // Remove previous icons
            }
          }

          // Add 'focusingClass' to the clicked element
          element.classList.add("focusingClass");

          // Show icons only if the element has a valid styleid
          if (
            styleId &&
            controlsVisible &&
            !datablockChildControlIds.includes(e.target.id)
          ) {
            setIconsforFocus(e.target.id);
          }

          if (!datablockChildControlIds.includes(e.target.id)) {
            handleSelectItem(e.target.id);
            setSelectedNode(e.target.id);
          }

          const navElement = document.getElementById("nav_" + e.target.id);
          if (navElement) {
            navElement.click();
          }

          setCurrentTable(styleId);

          // If it's a double-click and the tab is not shown, show the tab
          if (e.detail === 2 && !showtab) {
            handleTabshwButtonClick();
          }
        }
      } else {
        // If the clicked element is in datablockChildControlIds, focus on the parent with title "Datablocks"
        const element = document.getElementById(e.target.id);
        let parentDatablocksElement = null;

        // Find the parent with title "Datablocks"
        if (element) {
          let parent = element.parentElement;
          while (parent) {
            if (parent.getAttribute("tableProp") === "MainBlock") {
              parentDatablocksElement = parent;
              break;
            }
            parent = parent.parentElement;
          }
        }
        if (parentDatablocksElement) {
          const prevFocusedElement = document.querySelector(".focusingClass");

          // Remove 'focusingClass' and icons from previously focused element
          if (prevFocusedElement) {
            prevFocusedElement.classList.remove("focusingClass");

            const prevId = prevFocusedElement.id;
            const prevIconContainer = document.getElementById(
              `iconContainer-${prevId}`
            );
            if (prevIconContainer) {
              prevIconContainer.remove(); // Remove previous icons
            }
          }

          // Add 'focusingClass' to the parent
          parentDatablocksElement.classList.add("focusingClass");

          // Show icons on the parent element
          const parentId = parentDatablocksElement.id;
          if (parentDatablocksElement.hasAttribute("styleid")) {
            const styleId = parentDatablocksElement.getAttribute("styleid");
            if (styleId && controlsVisible) {
              setIconsforFocus(parentId);
            }
          }

          handleSelectItem(parentDatablocksElement.id);
          setSelectedNode(parentDatablocksElement.id);

          const navElement = document.getElementById(
            "nav_" + parentDatablocksElement.id
          );
          if (navElement) {
            navElement.click();
          }

          setCurrentTable(parentDatablocksElement.getAttribute("styleid"));
        }
      }
    } catch (error) {
      console.error("An error occurred in onClickforFocus", error);
      setError("An error occurred in onClickforFocus", error);
    }
  };

  const handleTabshwButtonClick = (e) => {
    try {
      setShowtab((prevShowtab) => !prevShowtab);
      setShowdsktab(true);
      setOpenpaint(true);
    } catch (error) {
      console.error("An error occurred in handleTabshwButtonClick", error);
      setError("An error occurred in handleTabshwButtonClick", error);
    }
  };

  const handleMenushwDskButtonClick = () => {
    try {
      setShowdskmenu((prevShowdskmenu) => !prevShowdskmenu);
      setShowmenu(false);
    } catch (error) {
      console.error("An error occurred in handleMenushwDskButtonClick", error);
      setError("An error occurred in handleMenushwDskButtonClick", error);
    }
  };
  const handleTabshwDskButtonClick = () => {
    try {
      setShowdsktab(true);
      setShowtab(true);
    } catch (error) {
      console.error("An error occurred in handleTabshwDskButtonClick", error);
      setError("An error occurred in handleTabshwDskButtonClick", error);
    }
  };

  const calculateNextStyleId = () => {
    try {
      const deletedItemId = localStorage.getItem("deletedItemId");

      const deletedItemNumber = deletedItemId
        ? parseInt(deletedItemId.replace(/[a-zA-Z]/g, ""))
        : -1;

      let maxStyleId = 0;

      const findMaxStyleId = (node) => {
        const styleIdRegex = /([a-zA-Z]+)(\d*)/;
        if (typeof node.styleid === "string") {
          const matches = node.styleid.match(styleIdRegex);
          if (matches && matches[1] === "agrfb") {
            const currentId = parseInt(matches[2]);
            if (!isNaN(currentId) && currentId > maxStyleId) {
              maxStyleId = currentId;
            }
          }
        }

        if (node.control && node.control.length > 0) {
          for (const childNode of node.control) {
            findMaxStyleId(childNode);
          }
        }
      };

      treeData[0].Source[0].control.forEach((rootNode) => {
        findMaxStyleId(rootNode);
      });

      const nextStyleId =
        deletedItemNumber > maxStyleId ? deletedItemNumber + 1 : maxStyleId + 1;

      const styleIdPrefix = showTheme ? "agrfb" : "agrfbTheme";

      return `${styleIdPrefix}${nextStyleId}`;
    } catch (error) {
      console.error("An error occurred in calculateNextStyleId", error);
      setError("An error occurred in calculateNextStyleId", error);
    }
  };

  const findMaxNumber = (node, controlType) => {
    try {
      let maxNumber = 0;
      if (node.control) {
        for (const child of node.control) {
          if (child.type === controlType) {
            const number = parseInt(child.name.replace(controlType, ""));
            if (!isNaN(number) && number > maxNumber) {
              maxNumber = number;
            }
          }
          const childMaxNumber = findMaxNumber(child, controlType);
          if (childMaxNumber > maxNumber) {
            maxNumber = childMaxNumber;
          }
        }
      }
      return maxNumber;
    } catch (error) {
      console.error("An error occurred in findMaxNumber", error);
      setError("An error occurred in findMaxNumber", error);
    }
  };

  const findMaxHeaderAndCellNumbers = (treeData) => {
    try {
      let maxHeaderNumber = 0;
      let maxCellNumber = 0;

      const traverse = (node) => {
        if (node.control) {
          for (const child of node.control) {
            if (child.htmlTag === "th") {
              const number = parseInt(child.tableHeader.replace("Header", ""));
              if (!isNaN(number) && number > maxHeaderNumber) {
                maxHeaderNumber = number;
              }
            } else if (child.controltype === "Row") {
              for (const cell of child.control) {
                if (cell.htmlTag === "td") {
                  const number = parseInt(
                    cell.tableHeader.replace("label", "")
                  );
                  if (!isNaN(number) && number > maxCellNumber) {
                    maxCellNumber = number;
                  }
                }
              }
            }
            traverse(child);
          }
        }
      };

      traverse(treeData[0].Source[0]);
      return { maxHeaderNumber, maxCellNumber };
    } catch (error) {
      console.error("An error occurred in findMaxHeaderAndCellNumbers", error);
      setError("An error occurred in findMaxHeaderAndCellNumbers", error);
    }
  };

  const addNewColumn = () => {
    try {
      const defaultlabel = defaultStyle.find(
        (style) => style.styleType === "TableLabel"
      );

      const defaultHeader = defaultStyle.find(
        (style) => style.styleType === "TableHeader"
      );

      const { maxHeaderNumber, maxCellNumber } =
        findMaxHeaderAndCellNumbers(treeData);

      const findTableNode = (node) => {
        if (node.type === "Table") {
          return node;
        } else if (node.control && Array.isArray(node.control)) {
          for (let i = 0; i < node.control.length; i++) {
            const result = findTableNode(node.control[i]);
            if (result) {
              return result;
            }
          }
        }
        return null;
      };

      const tableNode = findTableNode(selectedNode);

      if (!tableNode) {
        return;
      }

      const theadNode = tableNode.control.find(
        (node) => node.controltype === "Header"
      );

      if (!theadNode) {
        return;
      }

      const newHeaderIndex = maxHeaderNumber + 1;
      const newlabelindex = maxCellNumber + 1;

      const styleid = calculateNextStyleId();

      const controlToAddfortablelabel = Controls.flatMap(
        (category) => category.items
      ).find((item) => item.styleType === "Label");

      const newHeader = {
        id: `${tableNode.id}.thead.${newHeaderIndex}`,
        name: `Header${newHeaderIndex}`,
        controlName: `Header${newHeaderIndex}`,
        tableHeader: `Header${newHeaderIndex}`,
        data: `Header${newHeaderIndex}`,
        type: "Table",
        parentid: `${tableNode.id}.thead`,
        htmlTag: "th",
        controltype: "Header",
        styles: JSON.stringify({ ...defaultHeader.style }, null, 2),
        styleid: `${styleid}.thead.${newHeaderIndex}`,
        styleType: "TableHeader",
        mediaURl: "table",
        check: false,
        switchcheck: false,
        title: "Display",
        value: "",
        media: "",
        option: false,
        switchoption: false,
        position: 1,
        checkposition: "left",
        tab: "Text",
        inputType: "Date",
        inputText: "",
        imageText: "",
        sourceData: "",
        tableProp: "tableProp",
        controlChange: `Header${newHeaderIndex}`,
      };

      theadNode.control.push(newHeader);

      tableNode.control
        .filter((node) => node.controltype === "Row")
        .forEach((row) => {
          const newCellID = `${row.id}.cell${newHeaderIndex}`;
          const newCell = {
            id: newCellID,
            name: `label${newlabelindex}`,
            controlName: `label${newlabelindex}`,
            tableHeader: `label${newlabelindex}`,
            data: `label${newlabelindex}`,
            type: "Table",
            parentid: `${row.id}`,
            htmlTag: "td",
            controltype: controlToAddfortablelabel.controltype,
            styles: JSON.stringify({ ...defaultlabel.style }, null, 2),
            styleid: `${styleid}.row_cell${newlabelindex}`,
            styleType: "TableLabel",
            mediaURl: controlToAddfortablelabel.mediaURl,
            check: false,
            switchcheck: false,
            title: "Display",
            value: "",
            media: "",
            option: `Header${newlabelindex}`,
            switchoption: false,
            position: 1,
            checkposition: "left",
            tab: "Text",
            inputType: "Date",
            inputText: "",
            imageText: "",
            sourceData: `Header`,
            tableProp: "tableProp",
            controlChange: `label${newlabelindex}`,
          };

          row.control.push(newCell);
        });

      setTreeData([...treeData]);
    } catch (error) {
      console.error("An error occurred in addNewColumn", error);
      setError("An error occurred in addNewColumn", error);
    }
  };

  const findControlById = (id, controls) => {
    try {
      for (let control of controls) {
        if (control.id === id) {
          return control;
        }
        if (control.children) {
          const found = findControlById(id, control.children);
          if (found) return found;
        }
      }
      return null;
    } catch (error) {
      console.error("An error occurred in findControlById", error);
      setError("An error occurred in findControlById", error);
    }
  };

  const handleMouseOver = (e, control) => {
    try {
      if (!isDragging) return;
      e.stopPropagation();

      const targetElement = e.target;
      const parentId = targetElement.getAttribute("parentid");

      const element = document.getElementById(control.id);

      const focusedNode = document.querySelector(".focusingClass");

      if (element) {
        if (focusedNode && focusedNode !== element) {
          focusedNode.classList.remove("focusingClass");
        }
        element.classList.add("focusingClass");
      }

      setInsertionPoint(control.id); // Set insertion point

      setSelectedItemID(control.id);
      setSelectedControl(control);
      setSelectedNode(control);
      setSelectedOptioncheck(control.check ? "true" : "false");
      setSelectedOptionSwitchcheck(control.switchcheck ? "true" : "false");
      setSelectedPositioncheck(
        control.checkposition || (control.media ? "left" : "right")
      );
      setEditedName(control.name || editedName);
      setEditedData(control.data || "");
      setSelectedOption(control.imageText || control.inputText || control.data);
      settabSelect(control.tab || "");
      setTableHeader(control.tableHeader ? "TableHeader" : "");
      setSelectedControlType(
        control.controlChange || control.selectedControlType
      );
      setIsOptionSelected(!isOptionSelected);
      settableProp(control.tableProp);
    } catch (error) {
      console.error("An error occurred in findControlById", error);
      setError("An error occurred in findControlById", error);
    }
  };

  const handleMouseOut = (e) => {
    try {
      if (!isDragging) return;
      e.stopPropagation();
      const focusedNode = document.querySelector(".focusingClass");

      if (focusedNode) {
        focusedNode.classList.remove("focusingClass");
      }

      setSelectedItemID(null);
      setSelectedControl(null);
      setSelectedNode(null);
    } catch (error) {
      console.error("An error occurred in handleMouseOut", error);
      setError("An error occurred in handleMouseOut", error);
    }
  };

  const handleAddControl = (
    controlType,
    updatedStyles,
    newCheckboxes,
    generateNewKeys
  ) => {
    try {
      const defaultlabel = defaultStyle.find(
        (style) => style.styleType === "TableLabel"
      );

      const defaultHeader = defaultStyle.find(
        (style) => style.styleType === "TableHeader"
      );

      if (
        !treeData[0]?.Source[0]?.control ||
        !Array.isArray(treeData[0].Source[0].control)
      ) {
        console.error("Invalid treeData structure");
        toast.error("Invalid treeData structure");
        setError("Invalid treeData structure");
        return;
      }

      if (selectedNode && selectedNode.styleType === "RadioContainer") {
        return;
      }

      const maxNumber = findMaxNumber(treeData[0].Source[0], controlType);
      const { maxHeaderNumber, maxCellNumber } =
        findMaxHeaderAndCellNumbers(treeData);
      const newName = `${controlType}${maxNumber + 1}`;
      const controlToAdd = Controls.flatMap((category) => category.items).find(
        (item) => item.controltype === controlType
      );

      const controlToAddfortablelabel = Controls.flatMap(
        (category) => category.items
      ).find((item) => item.styleType === "Label");

      if (controlToAdd) {
        setRenderedControls((prevControls) => [...prevControls, controlToAdd]);
      } else {
      }

      if (!controlToAdd) {
        console.log(`Control type ${controlType} not found in Controls data.`);
        return;
      }

      if (!selectedNode) {
        console.error("Control type is not selected.");
        toast.error("Control type is not selected");
        return;
      }

      if (selectedNode.styleType === "RadioContainer") {
        return;
      }

      const footerContainerIndex = treeData[0].Source[0].control.findIndex(
        (item) => item.type === "FooterContainer"
      );

      const headerContainerIndex = treeData[0].Source[0].control.findIndex(
        (item) => item.type === "HeaderContainer"
      );

      const getNextIDS = (treeData, controlType) => {
        let maxID = 0;

        treeData[0].Source[0].control.forEach((item) => {
          if (item.id && typeof item.id === "string") {
            const parts = item.id.split(".");
            const lastPart = parseInt(parts[parts.length - 1]);
            if (!isNaN(lastPart) && lastPart > maxID) {
              maxID = lastPart;
            }
          }
        });

        if (controlType === "HeaderContainer") {
          return 0;
        }
        return maxID + 1;
      };

      const customHtmlTag = controlToAdd.htmlTag;
      const styleid = calculateNextStyleId();

      setDefaultStyle(readArrayStyles);

      const defaultStyles = Array.isArray(defaultStyle)
        ? defaultStyle.find(
            (style) => style.styleType === controlToAdd.styleType
          )
        : null;

      if (
        !defaultStyles ||
        !defaultStyles.style ||
        defaultStyles.style.length === 0
      ) {
        console.log(
          `Default styles for control type ${controlType} not found or empty.`
        );
        setError(
          `Default styles for control type ${controlType} not found or empty.`
        );
        return;
      }

      const defaultRadioConatiner = defaultStyle.find(
        (style) => style.styleType === "RadioContainer"
      );

      const defaultCheckbox = defaultStyle.find(
        (style) => style.styleType === "Checkbox"
      );

      const defaultRadio = defaultStyle.find(
        (style) => style.styleType === "Radio"
      );

      const defaultArray = defaultStyles.style;

      const mergedStyles = {
        ...defaultArray,
        ...updatedStyles,
      };

      if (!selectedNode) {
        console.error("Control type is not selected.");
        toast.error("Please select a control type.");
        return;
      }

      const getValueForControlType = (type) => {
        switch (type) {
          case "Radio":
            return numberOfRadioInputs;
          default:
            return "";
        }
      };

      const getInputTextForControlType = (type) => {
        switch (type) {
          case "Date":
            return "";
          default:
            return "";
        }
      };

      let insertionIndex = -1;
      if (insertionPoint) {
        insertionIndex = treeData[0].Source[0].control.findIndex(
          (item) => item.id === insertionPoint
        );
      }

      if (insertionIndex === -1) {
        console.error("Insertion point not found.");
        insertionIndex = treeData[0].Source[0].control.length;
      }

      if (controlType === "HeaderContainer") {
        if (headerContainerIndex !== -1) {
          toast.info("HeaderContainer already exists.");
          return;
        }

        const newHeaderContainer = {
          id: "0.0H",
          name: newName,
          controlName: newName,
          tableHeader: "",
          data: controlToAdd.description,
          type: controlType,
          parentid: "0",
          styleid: styleid,
          htmlTag: customHtmlTag,
          styles: JSON.stringify({ ...mergedStyles }, null, 2),
          controltype: controlType,
          styleType: controlToAdd.styleType,
          mediaURl: controlToAdd.mediaURl,
          check: isChecked,
          switchcheck: isSwitched,
          title: Controls.find((category) =>
            category.items.some((item) => item.controltype === controlType)
          )?.title,
          value: getValueForControlType(controlType, controlToAdd.description),
          media: "",
          uniqueKey: generateNewKeys,
          option: selectedOptioncheck,
          position: selectedOptionmedia,
          switchoption: selectedOptionSwitchcheck,
          checkposition: selectedPositioncheck,
          tab: tabSelect,
          inputType: inputType,
          inputText: getInputTextForControlType(
            controlType,
            controlToAdd.description
          ),
          imageText: imageText,
          sourceData: "sourceData",
          controlChange: controlType,
          tableProp: "null",
          url: url,
          control: [],
        };

        if (
          treeData[0].Source[0].control.length > 0 &&
          treeData[0].Source[0].control[0].type !== "HeaderContainer"
        ) {
          treeData[0].Source[0].control.unshift(newHeaderContainer);
        } else {
          treeData[0].Source[0].control.push(newHeaderContainer);
        }

        setOpenDialog(false);
        setTreeData([...treeData]);
        return;
      }

      const getDataForControlType = (type, description) => {
        switch (type) {
          case "List":
            return description.join(",");
          case "Dropdown":
            return description.join(",");
          case "Date":
            return new Date().toISOString().slice(0, 10);
          case "Iframe":
            return fixedIframeUrl;
          default:
            return description;
        }
      };

      const getSourceDataForControlType = (type, description) => {
        switch (type) {
          case "TextBox":
            return controlToAdd.controltype;
          default:
            return controlToAdd.controltype;
        }
      };

      const getMediaForControlType = (type) => {
        switch (type) {
          case "Image":
            return selectedImage;
          case "Radio":
            return RadioPosition;
          default:
            return "";
        }
      };

      if (controlType === "FooterContainer") {
        if (footerContainerIndex !== -1) {
          toast.info("FooterContainer already exists.");
          return;
        }
        const newID = getNextIDS(treeData);
        const newNode = {
          id: `0.${newID}`,
          name: newName,
          controlName: newName,
          tableHeader: "",
          data: controlToAdd.description,
          type: controlType,
          htmlTag: customHtmlTag,
          controltype: controlType,
          styles: JSON.stringify({ ...mergedStyles }, null, 2),
          parentid: "0",
          styleid: styleid,
          styleType: controlToAdd.styleType,
          mediaURl: controlToAdd.mediaURl,
          check: isChecked,
          switchcheck: isSwitched,
          title: Controls.find((category) =>
            category.items.some((item) => item.controltype === controlType)
          )?.title,
          value: getValueForControlType(controlType, controlToAdd.description),
          media: getMediaForControlType(controlType, controlToAdd.description),
          uniqueKey: generateNewKeys,
          option: selectedOptioncheck,
          switchoption: selectedOptionSwitchcheck,
          position: selectedOptionmedia,
          checkposition: selectedPositioncheck,
          tab: tabSelect,
          inputType: inputType,
          inputText: getInputTextForControlType(
            controlType,
            controlToAdd.description
          ),
          imageText: imageText,
          sourceData: getSourceDataForControlType(
            controlType,
            controlToAdd.description
          ),
          controlChange: controlType,
          tableProp: "null",
          url: url,
          control: [],
        };

        treeData[0].Source[0].control.push(newNode);

        setOpenDialog(false);
        setTreeData([...treeData]);
        return;
      } else {
        const parentID = selectedNode.id;
        let maxChildIndex = 0;

        if (selectedNode.control && Array.isArray(selectedNode.control)) {
          selectedNode.control.forEach((child) => {
            if (child.id && typeof child.id === "string") {
              const childIndex = parseInt(child.id.split(".").pop(), 10);
              if (!isNaN(childIndex) && childIndex > maxChildIndex) {
                maxChildIndex = childIndex;
              }
            }
          });
        }

        const childID = `${parentID}.${maxChildIndex + 1}`;

        let newNode = {
          id: childID,
          name: newName,
          controlName: newName,
          tableHeader: "",
          data: getDataForControlType(controlType, controlToAdd.description),
          type: controlType,
          parentid: parentID,
          htmlTag: customHtmlTag,
          controltype: controlType,
          styles: JSON.stringify({ ...mergedStyles }, null, 2),
          styleid: styleid,
          styleType: controlToAdd.styleType,
          mediaURl: controlToAdd.mediaURl,
          check: isChecked,
          switchcheck: isSwitched,
          title: Controls.find((category) =>
            category.items.some((item) => item.controltype === controlType)
          )?.title,
          value: getValueForControlType(controlType, controlToAdd.description),
          media: getMediaForControlType(controlType, controlToAdd.description),
          uniqueKey: generateNewKeys,
          option: selectedOptioncheck,
          switchoption: selectedOptionSwitchcheck,
          position: selectedOptionmedia,
          checkposition: selectedPositioncheck,
          tab: tabSelect,
          inputType: inputType,
          inputText: getInputTextForControlType(
            controlType,
            controlToAdd.description
          ),
          imageText: imageText,
          sourceData: getSourceDataForControlType(
            controlType,
            controlToAdd.description
          ),
          controlChange: controlType,
          tableProp: "null",
          url: url,
          control: [],
        };

        const getParentNode = (root, node) => {
          if (!node || node.parentid === "0") {
            return root;
          }

          const findNode = (currentNode) => {
            if (currentNode.id === node.parentid) {
              return currentNode;
            }

            if (currentNode.control) {
              for (const childNode of currentNode.control) {
                const foundNode = findNode(childNode);
                if (foundNode) {
                  return foundNode;
                }
              }
            }
            return null;
          };

          return findNode(root);
        };

        if (newCheckboxes) {
          newCheckboxes.forEach((checkbox, index) => {
            const styleid = calculateNextStyleId();
            const parentNode = getParentNode(
              treeData[0].Source[0],
              selectedNode
            );
            const childID = `${parentNode.id}.${parentNode.control.length + 1}`;

            const maxNumber = findMaxNumber(treeData[0].Source[0], controlType);
            const newName = `${controlType}${maxNumber + 1}`;

            if (!parentNode || !parentNode.id) {
              return;
            }

            let newNode = {
              id: childID,
              name: newName,
              controlName: newName,
              tableHeader: "",
              data: controlToAdd.description,
              type: controlType,
              parentid: parentNode.id,
              styleid: styleid,
              htmlTag: customHtmlTag,
              controltype: controlType,
              check: isChecked,
              switchcheck: isSwitched,
              styles: JSON.stringify({ ...mergedStyles }, null, 2),
              styleType: controlToAdd.styleType,
              mediaURl: controlToAdd.mediaURl,
              title: Controls.find((category) =>
                category.items.some((item) => item.controltype === controlType)
              )?.title,
              value: getValueForControlType(
                controlType,
                controlToAdd.description
              ),
              media: getMediaForControlType(
                controlType,
                controlToAdd.description
              ),
              uniqueKey: generateNewKeys,
              option: selectedOptioncheck,
              switchoption: selectedOptionSwitchcheck,
              position: selectedOptionmedia,
              checkposition: selectedPositioncheck,
              tab: tabSelect,
              inputType: inputType,
              inputText: getInputTextForControlType(
                controlType,
                controlToAdd.description
              ),
              imageText: imageText,
              sourceData: getSourceDataForControlType(
                controlType,
                controlToAdd.description
              ),
              controlChange: controlType,
              tableProp: "null",
              url: url,
              control: [],
            };

            parentNode.control.push(newNode);
          });

          if (footerContainerIndex !== -1) {
            const [removed] = treeData[0].Source[0].control.splice(
              footerContainerIndex,
              1
            );
            treeData[0].Source[0].control.push(removed);
          }

          setTreeData([...treeData]);
          setOpenDialog(false);
          return;
        }

        if (controlType === "Radio") {
          const parentID = selectedNode.id;

          if (selectedNode.controltype === "RadioContainer") {
            toast.error("Cannot add a Radio inside another Radio.");
            return;
          }

          if (selectedNode.controltype === "Radio") {
            toast.error("Cannot add a Radio inside another Radio.");
            return;
          }

          const childIDLayout =
            parentID + "." + (selectedNode.control.length + 1);
          const childIDContainer = childIDLayout + ".1";

          const newNameLayout = `${controlType}${maxNumber + 1}`;
          const newNameContainer = `${controlType}${maxNumber + 2}`;

          if (selectedNode.controltype === "RadioContainer") {
            toast.error("Cannot add a Radio inside another Radio.");
            return;
          }

          if (selectedNode.controltype === "Button") {
            toast.error("Cannot add a Radio inside Button.");
            return;
          }

          const newContainerLayout = {
            id: childIDLayout,
            name: "RadioContainer",
            controlName: newNameLayout,
            tableHeader: "",
            data: "",
            type: "Container",
            parentid: parentID,
            htmlTag: "div",
            controltype: "Container",
            styles: JSON.stringify({ ...defaultRadioConatiner.style }, null, 2),
            styleid: `agrf${maxNumber + 1}`,
            styleType: "RadioContainer",
            mediaURl: controlToAdd.mediaURl,
            title: "Containers",
            tab: tabSelect,
            inputType: inputType,
            inputText: getInputTextForControlType(
              controlType,
              controlToAdd.description
            ),
            imageText: imageText,
            sourceData: getSourceDataForControlType(
              controlType,
              controlToAdd.description
            ),
            controlChange: controlType,
            tableProp: "null",
            url: url,
            control: [
              {
                id: childIDContainer,
                name: newNameContainer,
                controlName: newNameContainer,
                tableHeader: "",
                data: controlToAdd.description,
                type: "Radio",
                parentid: childIDLayout,
                htmlTag: customHtmlTag,
                controltype: controlType,
                styles: JSON.stringify({ ...defaultRadio.style }, null, 2),
                styleid: calculateNextStyleId(),
                styleType: controlToAdd.styleType,
                mediaURl: controlToAdd.mediaURl,
                check: isChecked,
                switchcheck: isSwitched,
                title: "Input",
                value: getValueForControlType(
                  controlType,
                  controlToAdd.description
                ),
                media: getMediaForControlType(
                  controlType,
                  controlToAdd.description
                ),
                uniqueKey: generateNewKeys,
                option: selectedOptioncheck,
                switchoption: selectedOptionSwitchcheck,
                position: selectedOptionmedia,
                checkposition: selectedPositioncheck,
                tab: tabSelect,
                inputType: inputType,
                inputText: getInputTextForControlType(
                  controlType,
                  controlToAdd.description
                ),
                imageText: imageText,
                sourceData: getSourceDataForControlType(
                  controlType,
                  controlToAdd.description
                ),
                controlChange: controlType,
                tableProp: "null",
                url: url,
                control: [],
              },
            ],
          };

          selectedNode.control.push(newContainerLayout);

          const itemToInsert = selectedNode.control.pop();
          selectedNode.control.splice(insertionIndex, 0, itemToInsert);

          setOpenDialog(false);
          setTreeData([...treeData]);
          return;
        }

        if (controlType === "Checkbox") {
          const parentID = selectedNode.id;
          const childIDLayout =
            parentID + "." + (selectedNode.control.length + 1);
          const childIDContainer = childIDLayout + ".1";

          const newNameLayout = `${controlType}${maxNumber + 1}`;
          const newNameContainer = `${controlType}${maxNumber + 2}`;

          if (selectedNode.controltype === "CheckboxContainer") {
            toast.error("Cannot add a Radio inside another Radio.");
            return;
          }

          if (selectedNode.controltype === "Checkbox") {
            toast.error("Cannot add a Checkbox inside another Checkbox.");
            return;
          }

          if (selectedNode.controltype === "Button") {
            toast.error("Cannot add a Checkbox inside Button.");
            return;
          }

          const newContainerLayout = {
            id: childIDLayout,
            name: "CheckboxContainer",
            controlName: newNameLayout,
            tableHeader: "",
            data: "",
            type: "Container",
            parentid: parentID,
            htmlTag: "div",
            controltype: "Container",
            styles: JSON.stringify({ ...defaultRadioConatiner.style }, null, 2),
            styleid: `agr${maxNumber + 1}`,
            styleType: "RadioContainer",
            mediaURl: controlToAdd.mediaURl,
            title: "Containers",
            tab: tabSelect,
            inputType: inputType,
            inputText: getInputTextForControlType(
              controlType,
              controlToAdd.description
            ),
            imageText: imageText,
            sourceData: getSourceDataForControlType(
              controlType,
              controlToAdd.description
            ),
            controlChange: controlType,
            tableProp: "null",
            url: url,
            control: [
              {
                id: childIDContainer,
                name: newNameContainer,
                controlName: newNameContainer,
                tableHeader: "",
                data: controlToAdd.description,
                type: "Checkbox",
                parentid: childIDLayout,
                htmlTag: customHtmlTag,
                controltype: controlType,
                styles: JSON.stringify({ ...defaultCheckbox.style }, null, 2),
                styleid: calculateNextStyleId(),
                styleType: controlToAdd.styleType,
                mediaURl: controlToAdd.mediaURl,
                check: isChecked,
                switchcheck: isSwitched,
                title: Controls.find((category) =>
                  category.items.some(
                    (item) => item.controltype === controlType
                  )
                )?.title,
                value: getValueForControlType(
                  controlType,
                  controlToAdd.description
                ),
                media: getMediaForControlType(
                  controlType,
                  controlToAdd.description
                ),
                uniqueKey: generateNewKeys,
                option: selectedOptioncheck,
                switchoption: selectedOptionSwitchcheck,
                position: selectedOptionmedia,
                checkposition: selectedPositioncheck,
                tab: tabSelect,
                inputType: inputType,
                inputText: getInputTextForControlType(
                  controlType,
                  controlToAdd.description
                ),
                imageText: imageText,
                sourceData: getSourceDataForControlType(
                  controlType,
                  controlToAdd.description
                ),
                controlChange: controlType,
                tableProp: "null",
                url: url,
                control: [],
              },
            ],
          };

          selectedNode.control.push(newContainerLayout);

          const itemToInsert = selectedNode.control.pop();
          selectedNode.control.splice(insertionIndex, 0, itemToInsert);

          setOpenDialog(false);
          setTreeData([...treeData]);
          return;
        }

        const defaultTableHead = defaultStyle.find(
          (style) => style.styleType === "TableHead"
        );

        let globalCellCounter = 1;

        if (
          selectedNode.title === "Display" ||
          selectedNode.title === "Input" ||
          selectedNode.title === "Datablocks"
        ) {
          toast.error("Cannot add a control inside another control.");
          return;
        }

        if (controlType === "Table") {
          const parentID = selectedNode.id;
          const childTableIDLayout =
            parentID + "." + (selectedNode.control.length + 1);
          const tableID = getNextIDS(treeData, controlType);
          const styleidTable = calculateNextStyleId();

          if (selectedNode.controltype === "Table" && controlType === "Table") {
            toast.error("Cannot add a table inside another control.");
            return;
          }

          const rowCount = 1;

          const tableNode = {
            id: `${childTableIDLayout}`,
            name: `Table${tableID}`,
            controlName: `Table${tableID}`,
            tableHeader: "tableHead",
            data: controlToAdd.description,
            type: "Table",
            parentid: parentID,
            htmlTag: "table",
            controltype: controlType,
            styleid: styleidTable,
            styles: JSON.stringify({ ...mergedStyles }, null, 2),
            styleType: controlToAdd.styleType,
            mediaURl: controlToAdd.mediaURl,
            check: isChecked,
            switchcheck: isSwitched,
            title: Controls.find((category) =>
              category.items.some((item) => item.controltype === controlType)
            )?.title,
            value: getValueForControlType(
              controlType,
              controlToAdd.description
            ),
            media: getMediaForControlType(
              controlType,
              controlToAdd.description
            ),
            uniqueKey: generateNewKeys,
            option: selectedOptioncheck,
            switchoption: selectedOptionSwitchcheck,
            position: selectedOptionmedia,
            checkposition: selectedPositioncheck,
            tab: tabSelect,
            inputType: "Header",
            inputText: getInputTextForControlType(
              controlType,
              controlToAdd.description
            ),
            imageText: imageText,
            sourceData: getSourceDataForControlType(
              controlType,
              controlToAdd.description
            ),
            controlChange: controlType,
            tableProp: "null",
            url: url,
            control: [],
          };
          selectedNode.control.push(tableNode);
          const itemToInsert = selectedNode.control.pop();
          selectedNode.control.splice(insertionIndex, 0, itemToInsert);

          const theadNode = {
            id: `${childTableIDLayout}.${tableID}`,
            name: `Table${tableID}_head`,
            controlName: `Table${tableID}_head`,
            tableHeader: "",
            data: "",
            type: "Table",
            parentid: `${parentID}.${tableID}`,
            htmlTag: "thead",
            controltype: "Header",
            styleid: `${styleidTable}_head`,
            styles: JSON.stringify({ ...defaultTableHead.style }, null, 2),
            styleType: controlToAdd.styleType,
            mediaURl: controlToAdd.mediaURl,
            check: isChecked,
            switchcheck: isSwitched,
            title: Controls.find((category) =>
              category.items.some((item) => item.controltype === controlType)
            )?.title,
            value: getValueForControlType(
              controlType,
              controlToAdd.description
            ),
            media: getMediaForControlType(
              controlType,
              controlToAdd.description
            ),
            uniqueKey: generateNewKeys,
            option: selectedOptioncheck,
            switchoption: selectedOptionSwitchcheck,
            position: selectedOptionmedia,
            checkposition: selectedPositioncheck,
            tab: tabSelect,
            inputType: "Header",
            inputText: getInputTextForControlType(
              controlType,
              controlToAdd.description
            ),
            imageText: imageText,
            sourceData: getSourceDataForControlType(
              controlType,
              controlToAdd.description
            ),
            controlChange: `Table${tableID}_head`,
            tableProp: "null",
            url: url,
            control: [],
          };

          tableNode.control.push(theadNode);

          for (let i = 1; i <= headerCount; i++) {
            const styleidHeader = `${styleidTable}_header_${i}`;
            theadNode.control.push({
              id: `${childTableIDLayout}.${tableID}.${i}`,
              name: `Header${maxHeaderNumber + i}`,
              controlName: `Header${maxHeaderNumber + i}`,
              tableHeader: `Header${maxHeaderNumber + i}`,
              data: `Header${maxHeaderNumber + i}`,
              type: "Table",
              parentid: `${parentID}.${tableID}.thead`,
              htmlTag: "th",
              controltype: "Header",
              styleid: styleidHeader,
              styleType: "TableHeader",
              styles: JSON.stringify({ ...defaultHeader.style }, null, 2),
              mediaURl: controlToAdd.mediaURl,
              check: isChecked,
              switchcheck: isSwitched,
              title: Controls.find((category) =>
                category.items.some((item) => item.controltype === controlType)
              )?.title,
              value: getValueForControlType(
                controlType,
                controlToAdd.description
              ),
              media: getMediaForControlType(
                controlType,
                controlToAdd.description
              ),
              uniqueKey: generateNewKeys,
              option: selectedOptioncheck,
              switchoption: selectedOptionSwitchcheck,
              position: selectedOptionmedia,
              checkposition: selectedPositioncheck,
              tab: tabSelect,
              inputType: inputType,
              inputText: getInputTextForControlType(
                controlType,
                controlToAdd.description
              ),
              imageText: imageText,
              controlChange: `Header${i}`,
              sourceData: getSourceDataForControlType(
                controlType,
                controlToAdd.description
              ),
              tableProp: "tableProp",
              url: url,
            });
          }

          for (let i = 1; i <= rowCount; i++) {
            const styleidRow = `${styleidTable}_row_${i}`;
            const row = {
              id: `${childTableIDLayout}.row${i}`,

              name: `Row${i}`,
              controlName: `Row${i}`,
              data: "",
              tableHeader: "",
              type: "Table",
              parentid: `${parentID}.${tableID}`,
              htmlTag: "tr",
              controltype: "Row",
              styleid: styleidRow,
              styles: JSON.stringify({ ...defaultTableHead.style }, null, 2),
              styleType: controlToAdd.styleType,
              mediaURl: controlToAdd.mediaURl,
              check: isChecked,
              switchcheck: isSwitched,
              title: Controls.find((category) =>
                category.items.some((item) => item.controltype === controlType)
              )?.title,
              value: getValueForControlType(
                controlType,
                controlToAdd.description
              ),
              media: getMediaForControlType(
                controlType,
                controlToAdd.description
              ),
              uniqueKey: generateNewKeys,
              option: selectedOptioncheck,
              switchoption: selectedOptionSwitchcheck,
              position: selectedOptionmedia,
              checkposition: selectedPositioncheck,
              tab: tabSelect,
              inputType: "Header",
              inputText: getInputTextForControlType(
                controlType,
                controlToAdd.description
              ),
              imageText: imageText,
              sourceData: getSourceDataForControlType(
                controlType,
                controlToAdd.description
              ),
              controlChange: "Row",
              tableProp: "null",
              url: url,
              control: [],
            };

            for (let j = 1; j <= cellCount; j++) {
              const styleidCell = `${styleidTable}_row${i}_cell${j}`;
              row.control.push({
                id: `${childTableIDLayout}.${tableID}.row${i}_cell${j}`,
                name: `label${maxCellNumber + j}`,
                controlName: `label${maxCellNumber + j}`,
                tableHeader: `label${maxCellNumber + j}`,
                data: `label${maxCellNumber + j}`,
                type: "Table",
                parentid: `${childTableIDLayout}.row${i}`,
                htmlTag: "td",
                controltype: controlToAddfortablelabel.controltype,
                styleid: styleidCell,
                styleType: "TableLabel",
                styles: JSON.stringify({ ...defaultlabel.style }, null, 2),
                mediaURl: controlToAddfortablelabel.mediaURl,
                check: isChecked,
                switchcheck: isSwitched,
                title: Controls.find((category) =>
                  category.items.some(
                    (item) => item.controltype === controlType
                  )
                )?.title,
                value: getValueForControlType(
                  controlType,
                  controlToAdd.description
                ),
                media: getMediaForControlType(
                  controlType,
                  controlToAdd.description
                ),
                uniqueKey: generateNewKeys,
                option: `Header${maxCellNumber + j}`,
                switchoption: selectedOptionSwitchcheck,
                position: selectedOptionmedia,
                checkposition: selectedPositioncheck,
                tab: tabSelect,
                inputType: inputType,
                inputText: getInputTextForControlType(
                  controlType,
                  controlToAdd.description
                ),
                imageText: imageText,
                controlChange: `Header${maxCellNumber + j}`,
                sourceData: getSourceDataForControlType(
                  controlType,
                  controlToAdd.description
                ),
                tableProp: "tableProp",
                url: url,
              });

              globalCellCounter++;
            }

            tableNode.control.push(row);
          }

          if (footerContainerIndex !== -1) {
            const [removed] = treeData[0].Source[0].control.splice(
              footerContainerIndex,
              1
            );
            treeData[0].Source[0].control.push(removed);
          }
          setOpenDialog(false);
          setTreeData([...treeData]);
          return;
        }

        const getNextIDSforDatablocks = (parentNode) => {
          let maxID = 0;

          parentNode.control.forEach((item) => {
            if (item.id && typeof item.id === "string") {
              const parts = item.id.split(".");
              const lastPart = parseInt(parts[parts.length - 1]);
              if (!isNaN(lastPart) && lastPart > maxID) {
                maxID = lastPart;
              }
            }
          });

          return maxID + 1;
        };

        const applyStylesRecursively = (
          control,
          defaultStyle,
          parentID = ""
        ) => {
          const defaultSty = defaultStyle.find(
            (style) => style.styleType === control.styleType
          );

          const newID = parentID
            ? `${parentID}.${control.id.split(".").pop()}`
            : control.id;

          let updatedStyles = { ...defaultSty?.style };

          let treeAlgnment;

          if (treeData[0]?.Source?.length > 0) {
            const rootElement = treeData[0].Source[0];

            try {
              const styles = JSON.parse(rootElement.styles);
              treeAlgnment = styles.alignMent;
            } catch (error) {
              console.error("Failed to parse styles:", error);
              setError(error.message);
            }
          } else {
            console.warn("Source array is empty or undefined.");
          }

          if (
            control.media === "CityState" ||
            control.media === "PostalCountry" ||
            control.media === "FirstLastName" ||
            control.media === "RatingInput"
          ) {
            updatedStyles.flexDirection = "row";
          }

          if (
            control.media === "Address" ||
            control.media === "FullName" ||
            control.media === "DropDown" ||
            control.media === "DropDownCont" ||
            control.media === "LongText" ||
            control.media === "ShortText" ||
            control.media === "EmailContainer" ||
            control.media === "PhoneContainer" ||
            control.media === "SingleChoiceContainer" ||
            control.media === "DateContainer" ||
            control.media === "MultipleChoiceMaincontainer" ||
            control.media === "SingleChoiceMainContainer" ||
            control.media === "Rating"
          ) {
            updatedStyles.flexDirection =
              treeAlgnment === "right" || treeAlgnment === "left"
                ? "row"
                : "column";
          }

          if (
            control.checkposition === "AddressHeader" ||
            control.checkposition === "ShortTextLabel" ||
            control.checkposition === "FullName" ||
            control.checkposition === "LongTextLabel" ||
            control.checkposition === "checkboxlabel" ||
            control.checkposition === "DropDownLabel" ||
            control.checkposition === "EmailLabel" ||
            control.checkposition === "Heading" ||
            control.checkposition === "PhoneNumberLabel" ||
            control.checkposition === "SingleChoiceLabel" ||
            control.checkposition === "MultipleChoiceLabel" ||
            control.checkposition === "DateLabel" ||
            control.checkposition === "RatingLabel" ||
            control.checkposition === "GeneralLabel" ||
            control.checkposition === "ImageLabel" ||
            control.checkposition === "SwitchLabel" ||
            control.checkposition === "IframeLabel"
          ) {
            updatedStyles.textAlign =
              treeAlgnment === "right" ? "right" : "left";
          }
          if (
            control.controltype === "Label" ||
            control.controltype === "TextBox" ||
            control.controltype === "Date" ||
            control.controltype === "Dropdown" ||
            control.controltype === "Radio" ||
            control.controltype === "Checkbox" ||
            control.controltype === "TextArea"
          ) {
            updatedStyles.fontSize = FontSize;
            updatedStyles.fontFamily = fontFamily;
          }

          if (control.htmlTag === "input" || control.htmlTag === "select") {
            updatedStyles.backgroundColor = inputColor;
          }

          if (control.media === "HeadingContainer") {
            updatedStyles.flexDirection = "row";
          }

          if (
            control.checkposition === "Heading" ||
            control.checkposition === "SubHeader"
          ) {
            updatedStyles.width = "-webkit-fill-available";
          }

          if (
            control.media === "AddressHeaderContriner" ||
            control.media === "ShortTextLabel" ||
            control.media === "LongTextLabel" ||
            control.checkposition === "MultipleChoiceLabel" ||
            control.media === "SingleChoiceLabel" ||
            control.media === "DropDownLabel" ||
            control.media === "FullNameHeader" ||
            control.media === "PhoneLabel" ||
            control.media === "EamilLabel" ||
            control.media === "DateLabel" ||
            control.media === "RatingConatiner" ||
            control.checkposition === "PrefixLabel" ||
            control.checkposition === "MiddleNameLabel" ||
            control.media === "RatingConatiner" ||
            control.media === "MultipleChoiceLabel" ||
            control.media === "SingleChoiceLabel"
          ) {
            updatedStyles.maxWidth = "30%";
          }

          if (control.media === "ImageContainer") {
            updatedStyles.maxWidth = "auto";
            updatedStyles.width = "auto";
          }

          if (
            control.media === "Address" ||
            control.media === "FirstLastName" ||
            control.media === "ContainerStreet1" ||
            control.media === "ContainerStreet2" ||
            control.media === "CityState" ||
            control.media === "ContainerSec" ||
            control.media === "ContainerThird" ||
            control.media === "PostalCountry" ||
            control.media === "ContainerPostal" ||
            control.media === "ContainerCountry" ||
            control.media === "Rating" ||
            control.media === "ContainerPrefix" ||
            control.media === "FirstNameContainer" ||
            control.media === "ContainerMiddleName" ||
            control.media === "LastNameContainer" ||
            control.media === "RatingInput" ||
            control.media === "CheckBoxMainContainer" ||
            control.media === "RadioMainContainer" ||
            control.media === "SingleChoiceMainContainer"
          ) {
            updatedStyles.paddingBottom = "0px";
            updatedStyles.paddingTop = "0px";
            updatedStyles.paddingLeft = "0px";
            updatedStyles.paddingRight = "0px";
          }

          if (
            control.media === "DropDownCont" ||
            control.media === "AddressInput" ||
            control.media === "MainAddressHeader" ||
            control.media === "ShortTextLabel" ||
            control.media === "LongTextLabel" ||
            control.media === "MultipleChoiceLabel" ||
            control.media === "SingleChoiceLabel" ||
            control.media === "DropDownLabel" ||
            control.media === "FullNameHeader" ||
            control.media === "PhoneLabel" ||
            control.media === "EamilLabel" ||
            control.media === "DateLabel" ||
            control.media === "RatingLabel" ||
            control.media === "AddressHeaderContriner" ||
            control.media === "RatingConatiner" ||
            control.media === "LongText" ||
            control.media === "DateContainer" ||
            control.media === "DropDown" ||
            control.media === "FullName"
          ) {
            // updatedStyles.paddingBottom = "3px";
            // updatedStyles.paddingTop = "3px";
            // updatedStyles.paddingLeft = "3px";
            // updatedStyles.paddingRight = "3px";
            updatedStyles.paddingBottom = "0px";
            updatedStyles.paddingTop = "0px";
            updatedStyles.paddingLeft = "0px";
            updatedStyles.paddingRight = "0px";
          }

          if (
            control.media === "Address" ||
            control.media === "CityState" ||
            control.media === "ContainerSec" ||
            control.media === "ContainerThird" ||
            control.media === "ContainerStreet2" ||
            control.media === "ContainerStreet1" ||
            control.media === "ContainerPostal" ||
            control.media === "PostalCountry" ||
            control.media === "ContainerCountry" ||
            control.media === "ShortText" ||
            control.media === "LongText" ||
            control.media === "MultipleChoiceMaincontainer" ||
            control.media === "CheckBoxMainContainer" ||
            control.media === "SingleChoiceMainContainer" ||
            control.media === "RadioMainContainer" ||
            control.media === "DropDown" ||
            control.media === "FullName" ||
            control.media === "FirstLastName" ||
            control.media === "ContainerPrefix" ||
            control.media === "FirstNameContainer" ||
            control.media === "ContainerMiddleName" ||
            control.media === "LastNameContainer" ||
            control.media === "HeadingContainer" ||
            control.media === "PhoneContainer" ||
            control.media === "EmailContainer" ||
            control.media === "DateContainer" ||
            control.media === "Rating" ||
            control.media === "RatingInput" ||
            control.media === "MainAddressHeader" ||
            control.media === "ShortTextLabel" ||
            control.media === "LongTextLabel" ||
            control.media === "MultipleChoiceLabel" ||
            control.media === "SingleChoiceLabel" ||
            control.media === "DropDownLabel" ||
            control.media === "FullNameHeader" ||
            control.media === "PhoneLabel" ||
            control.media === "EamilLabel" ||
            control.media === "DateLabel" ||
            control.media === "RatingLabel" ||
            control.media === "AddressHeaderContriner" ||
            control.media === "Rating" ||
            control.media === "AddressInput" ||
            control.media === "RatingConatiner"
          ) {
            updatedStyles.marginLeft = "0px";
            updatedStyles.marginBottom = "0px";
            updatedStyles.marginTop = "0px";
            updatedStyles.marginRight = "0px";
          }

          if (
            control.media === "CityState" ||
            control.media === "ContainerStreet2" ||
            control.media === "ContainerStreet1" ||
            control.media === "PostalCountry"
          ) {
            updatedStyles.marginBottom = "4px";
          }

          if (
            control.media === "ContainerMiddleName" ||
            control.media === "ContainerPrefix" ||
            control.media === "ContainerCountry" ||
            control.media === "ImageContainer"
          ) {
            updatedStyles.display = "none";
          }

          if (
            control.media === "CityState" ||
            control.media === "PostalCountry" ||
            control.media === "FirstLastName" ||
            control.media === "Address" ||
            control.media === "ShortText" ||
            control.media === "LongText" ||
            control.media === "MultipleChoiceMaincontainer" ||
            control.media === "SingleChoiceMainContainer" ||
            control.media === "DropDown" ||
            control.media === "FullName" ||
            control.media === "PhoneContainer" ||
            control.media === "EmailContainer" ||
            control.media === "DateContainer" ||
            control.media === "Rating"
          ) {
            updatedStyles.gap = "10px";
          }

          if (
            control.media === "MainAddressHeader" ||
            control.media === "ImageHeading"
          ) {
            updatedStyles.maxWidth = "100%";
            updatedStyles.width = "100%";
          }

          if (
            control.checkposition === "AddressStreet1Textbox" ||
            control.checkposition === "AddressStreet2Textbox" ||
            control.checkposition === "CityTextbox" ||
            control.checkposition === "StateTextbox" ||
            control.checkposition === "PostalTextbox" ||
            control.checkposition === "CountryTextbox" ||
            control.checkposition === "FirstNameTextBox" ||
            control.checkposition === "LastNameTextBox" ||
            control.checkposition === "MiddleNameTextBox" ||
            control.checkposition === "PrefixTextBox"
          ) {
            updatedStyles.paddingLeft = "3px";
          }

          const updatedControl = {
            ...control,
            id: newID,
            styles: JSON.stringify(updatedStyles),
          };

          if (updatedControl.control && updatedControl.control.length > 0) {
            updatedControl.control = updatedControl.control.map(
              (child, childIndex) => {
                const childNewID = `${newID}.${childIndex + 1}`;
                return applyStylesRecursively(
                  { ...child, id: childNewID },
                  defaultStyle,
                  newID
                );
              }
            );
          }

          return updatedControl;
        };

        const calculateNextStyleIdBlocks = () => {
          const deletedItemId = localStorage.getItem("deletedItemId");

          const deletedItemNumber = deletedItemId
            ? parseInt(deletedItemId.replace(/[a-zA-Z]/g, ""))
            : -1;

          let maxStyleId = 0;

          const findMaxStyleId = (node) => {
            const styleIdRegex = /([a-zA-Z]+)(\d*)/;
            if (typeof node.styleid === "string") {
              const matches = node.styleid.match(styleIdRegex);
              if (matches && matches[1] === "agrfb") {
                const currentId = parseInt(matches[2]);
                if (!isNaN(currentId) && currentId > maxStyleId) {
                  maxStyleId = currentId;
                }
              }
            }

            if (node.control && node.control.length > 0) {
              for (const childNode of node.control) {
                findMaxStyleId(childNode);
              }
            }
          };

          treeData[0].Source[0].control.forEach((rootNode) => {
            findMaxStyleId(rootNode);
          });

          const nextStyleId =
            deletedItemNumber > maxStyleId
              ? deletedItemNumber + 1
              : maxStyleId + 1;

          return `agrfb${nextStyleId}`;
        };

        if (controlType === "LongText") {
          let currentMaxStyleId = calculateNextStyleIdBlocks();

          const LongTextCount = selectedNode.control.filter(
            (control) => control.controltype === "LongText"
          ).length;

          const adjustedLongText = LongTextJSON.map((item, index) => {
            const baseID = item.id.split(".")[0];
            const newID = `${selectedNode.id}.${getNextIDSforDatablocks(selectedNode) + index}`;

            const itemIndex = LongTextCount > 0 ? LongTextCount + 1 : index + 1;

            const assignUniqueStyleId = (control) => {
              const styleIdNumber = parseInt(
                currentMaxStyleId.replace(/[a-zA-Z]/g, "")
              );
              const newStyleId = `agrfb${styleIdNumber + index}`;
              currentMaxStyleId = `agrfb${styleIdNumber + index + 1}`;

              return {
                ...control,
                styleid: newStyleId,
                parentid: newID,
                // stylemedia: controlToAdd.stylemedia,
                name:
                  itemIndex > 1
                    ? `${control.name}${itemIndex - 1}`
                    : control.name,
                control: control.control.map(assignUniqueStyleId),
              };
            };
            const updatedItem = applyStylesRecursively(
              assignUniqueStyleId({
                ...item,
                id: newID,
              }),
              defaultStyle,
              selectedNode.id
            );

            return updatedItem;
          });

          selectedNode.control.splice(insertionIndex, 0, ...adjustedLongText);

          setOpenDialog(false);
          setTreeData([...treeData]);
          return;
        }

        if (controlType === "DateBlock") {
          let currentMaxStyleId = calculateNextStyleIdBlocks();

          const DateBlockCount = selectedNode.control.filter(
            (control) => control.controltype === "DateBlock"
          ).length;

          const adjustedLongText = DateJSON.map((item, index) => {
            const newID = `${selectedNode.id}.${getNextIDSforDatablocks(selectedNode) + index}`;

            const itemIndex =
              DateBlockCount > 0 ? DateBlockCount + 1 : index + 1;

            const assignUniqueStyleId = (control) => {
              const styleIdNumber = parseInt(
                currentMaxStyleId.replace(/[a-zA-Z]/g, "")
              );
              const newStyleId = `agrfb${styleIdNumber + index}`;
              currentMaxStyleId = `agrfb${styleIdNumber + index + 1}`;

              return {
                ...control,
                styleid: newStyleId,
                parentid: newID,
                // stylemedia: controlToAdd.stylemedia,
                name:
                  itemIndex > 1
                    ? `${control.name}${itemIndex - 1}`
                    : control.name,
                control: control.control.map(assignUniqueStyleId),
              };
            };
            const updatedItem = applyStylesRecursively(
              assignUniqueStyleId({ ...item, id: newID }),
              defaultStyle,
              selectedNode.id
            );

            return updatedItem;
          });
          selectedNode.control.splice(insertionIndex, 0, ...adjustedLongText);

          setOpenDialog(false);
          setTreeData([...treeData]);
          return;
        }

        if (controlType === "ShortText") {
          let currentMaxStyleId = calculateNextStyleIdBlocks();

          const ShortTextCount = selectedNode.control.filter(
            (control) => control.controltype === "ShortText"
          ).length;

          const adjustedLongText = ShortTextJSON.map((item, index) => {
            const baseID = item.id.split(".")[0];
            const newID = `${selectedNode.id}.${getNextIDSforDatablocks(selectedNode) + index}`;

            const itemIndex =
              ShortTextCount > 0 ? ShortTextCount + 1 : index + 1;

            const assignUniqueStyleId = (control) => {
              const styleIdNumber = parseInt(
                currentMaxStyleId.replace(/[a-zA-Z]/g, "")
              );
              const newStyleId = `agrfb${styleIdNumber + index}`;
              currentMaxStyleId = `agrfb${styleIdNumber + index + 1}`;

              return {
                ...control,
                styleid: newStyleId,
                parentid: newID,
                // stylemedia: controlToAdd.stylemedia,
                name:
                  itemIndex > 1
                    ? `${control.name}${itemIndex - 1}`
                    : control.name,

                control: control.control.map(assignUniqueStyleId),
              };
            };

            const updatedItem = applyStylesRecursively(
              assignUniqueStyleId({ ...item, id: newID }),
              defaultStyle,
              selectedNode.id
            );

            return updatedItem;
          });

          selectedNode.control.splice(insertionIndex, 0, ...adjustedLongText);

          setOpenDialog(false);
          setTreeData([...treeData]);
          return;
        }

        if (controlType === "PhoneNumber") {
          let currentMaxStyleId = calculateNextStyleIdBlocks();

          const PhoneNumberCount = selectedNode.control.filter(
            (control) => control.controltype === "PhoneNumber"
          ).length;

          const adjustedLongText = PhoneJSON.map((item, index) => {
            const baseID = item.id.split(".")[0];
            // const newID = `0${baseID}.${getNextIDS(treeData) + index}`;
            const newID = `${selectedNode.id}.${getNextIDSforDatablocks(selectedNode) + index}`;
            const assignUniqueStyleId = (control) => {
              const styleIdNumber = parseInt(
                currentMaxStyleId.replace(/[a-zA-Z]/g, "")
              );
              const newStyleId = `agrfb${styleIdNumber + index}`;
              currentMaxStyleId = `agrfb${styleIdNumber + index + 1}`;
              const itemIndex =
                PhoneNumberCount > 0 ? PhoneNumberCount + 1 : index + 1;

              return {
                ...control,
                styleid: newStyleId,
                parentid: newID,
                // stylemedia: controlToAdd.stylemedia,
                name:
                  itemIndex > 1
                    ? `${control.name}${itemIndex - 1}`
                    : control.name,

                control: control.control.map(assignUniqueStyleId),
              };
            };

            const updatedItem = applyStylesRecursively(
              assignUniqueStyleId({ ...item, id: newID }),
              defaultStyle,
              selectedNode.id
            );

            return updatedItem;
          });

          selectedNode.control.splice(insertionIndex, 0, ...adjustedLongText);

          setOpenDialog(false);
          setTreeData([...treeData]);
          return;
        }

        if (controlType === "Email") {
          let currentMaxStyleId = calculateNextStyleIdBlocks();

          const EmailsCount = selectedNode.control.filter(
            (control) => control.controltype === "Email"
          ).length;

          const adjustedLongText = EmailJSON.map((item, index) => {
            const baseID = item.id.split(".")[0];
            // const newID = `0${baseID}.${getNextIDS(treeData) + index}`;
            const newID = `${selectedNode.id}.${getNextIDSforDatablocks(selectedNode) + index}`;
            const assignUniqueStyleId = (control) => {
              const styleIdNumber = parseInt(
                currentMaxStyleId.replace(/[a-zA-Z]/g, "")
              );
              const newStyleId = `agrfb${styleIdNumber + index}`;
              currentMaxStyleId = `agrfb${styleIdNumber + index + 1}`;

              const itemIndex = EmailsCount > 0 ? EmailsCount + 1 : index + 1;

              return {
                ...control,
                styleid: newStyleId,
                parentid: newID,
                // stylemedia: controlToAdd.stylemedia,
                name:
                  itemIndex > 1
                    ? `${control.name}${itemIndex - 1}`
                    : control.name,

                control: control.control.map(assignUniqueStyleId),
              };
            };

            const updatedItem = applyStylesRecursively(
              assignUniqueStyleId({ ...item, id: newID }),
              defaultStyle,
              selectedNode.id
            );

            return updatedItem;
          });

          selectedNode.control.splice(insertionIndex, 0, ...adjustedLongText);

          setOpenDialog(false);
          setTreeData([...treeData]);
          return;
        }

        if (controlType === "Heading") {
          let currentMaxStyleId = calculateNextStyleIdBlocks();
          const HeadingCount = selectedNode.control.filter(
            (control) => control.controltype === "Heading"
          ).length;

          const adjustedLongText = HeadingJSON.map((item, index) => {
            const baseID = item.id.split(".")[0];
            const newID = `${selectedNode.id}.${getNextIDSforDatablocks(selectedNode) + index}`;
            const assignUniqueStyleId = (control) => {
              const styleIdNumber = parseInt(
                currentMaxStyleId.replace(/[a-zA-Z]/g, "")
              );
              const newStyleId = `agrfb${styleIdNumber + index}`;
              currentMaxStyleId = `agrfb${styleIdNumber + index + 1}`;

              const itemIndex = HeadingCount > 0 ? HeadingCount + 1 : index + 1;

              return {
                ...control,
                styleid: newStyleId,
                parentid: newID,
                // stylemedia: controlToAdd.stylemedia,
                name:
                  itemIndex > 1
                    ? `${control.name}${itemIndex - 1}`
                    : control.name,

                control: control.control.map(assignUniqueStyleId),
              };
            };
            const updatedItem = applyStylesRecursively(
              assignUniqueStyleId({ ...item, id: newID }),
              defaultStyle,
              selectedNode.id
            );

            return updatedItem;
          });

          selectedNode.control.splice(insertionIndex, 0, ...adjustedLongText);

          setOpenDialog(false);
          setTreeData([...treeData]);
          return;
        }

        if (controlType === "MultipleChoice") {
          let currentMaxStyleId = calculateNextStyleIdBlocks();
          const MultipleChoiceCount = selectedNode.control.filter(
            (control) => control.controltype === "MultipleChoice"
          ).length;

          const adjustedMultipleChoice = MultipleChoiceJSON.map(
            (item, index) => {
              const baseID = item.id.split(".")[0];
              const newID = `${selectedNode.id}.${getNextIDSforDatablocks(selectedNode) + index}`;

              const itemIndex =
                MultipleChoiceCount > 0 ? MultipleChoiceCount + 1 : index + 1;

              const assignUniqueStyleId = (control) => {
                const styleIdNumber = parseInt(
                  currentMaxStyleId.replace(/[a-zA-Z]/g, "")
                );
                const newStyleId = `agrfb${styleIdNumber + index}`;
                currentMaxStyleId = `agrfb${styleIdNumber + index + 1}`;

                if (control.checkposition === "CheckBoxMainContainer") {
                  const existingCheckBoxCount = control.control.filter(
                    (c) => c.styleid && c.styleid.startsWith("agrfb")
                  ).length;

                  if (existingCheckBoxCount < 4) {
                    for (let i = 0; i < 4; i++) {
                      const updatedNoneJSON = GenderCheckBoxJSON.map(
                        (noneItem, noneIndex) => ({
                          ...noneItem,
                          id: `${newID}.${getNextIDS(treeData) + noneIndex + i}`,
                          parentid: newID,
                          // stylemedia: controlToAdd.stylemedia,
                          name:
                            itemIndex > 1
                              ? `${noneItem.name}${itemIndex - 1}`
                              : noneItem.name,

                          styleid: `agrfb${styleIdNumber + i}`,
                          data: `data${noneIndex + i}`,
                          inputText: `data${noneIndex + i}`,
                          switchoption: `data${noneIndex + i}`,
                        })
                      );

                      control.control = [
                        ...control.control,
                        ...updatedNoneJSON,
                      ];
                    }
                  }
                }

                return {
                  ...control,
                  styleid: newStyleId,
                  parentid: newID,
                  name:
                    itemIndex > 1
                      ? `${control.name}${itemIndex - 1}`
                      : control.name,
                  control: control.control.map(assignUniqueStyleId),
                };
              };

              const updatedItem = applyStylesRecursively(
                assignUniqueStyleId({ ...item, id: newID }),
                defaultStyle,
                selectedNode.id
              );

              return updatedItem;
            }
          );

          selectedNode.control.splice(
            insertionIndex,
            0,
            ...adjustedMultipleChoice
          );

          setOpenDialog(false);
          setTreeData([...treeData]);
          return;
        }

        if (controlType === "SingleChoice") {
          let currentMaxStyleId = calculateNextStyleIdBlocks();

          const SingleChoiceCount = selectedNode.control.filter(
            (control) => control.controltype === "SingleChoice"
          ).length;

          const adjustedSingleChoice = SingleChoiceJSON.map((item, index) => {
            const baseID = item.id.split(".")[0];
            const newID = `${selectedNode.id}.${getNextIDSforDatablocks(selectedNode) + index}`;

            const assignUniqueStyleId = (control) => {
              const styleIdNumber = parseInt(
                currentMaxStyleId.replace(/[a-zA-Z]/g, "")
              );
              const newStyleId = `agrfb${styleIdNumber + index}`;
              currentMaxStyleId = `agrfb${styleIdNumber + index + 1}`;

              const itemIndex =
                SingleChoiceCount > 0 ? SingleChoiceCount + 1 : index + 1;

              if (control.checkposition === "RadioMainContainer") {
                // Prevent adding GenderJSON multiple times
                const existingRadioCount = control.control.filter(
                  (c) => c.styleid && c.styleid.startsWith("agrfb")
                ).length;

                if (existingRadioCount < 4) {
                  for (let i = 0; i < 4; i++) {
                    const updatedNoneJSON = GenderJSON.map(
                      (noneItem, noneIndex) => ({
                        ...noneItem,
                        id: `${newID}.${getNextIDS(treeData) + noneIndex + i}`,
                        parentid: newID,
                        // stylemedia: controlToAdd.stylemedia,
                        name:
                          itemIndex > 1
                            ? `${noneItem.name}${itemIndex - 1}`
                            : noneItem.name,

                        styleid: `agrfb${styleIdNumber + i}`,
                        data: `data${noneIndex + i}`,
                        inputText: `data${noneIndex + i}`,
                        switchoption: `data${noneIndex + i}`,
                      })
                    );

                    control.control = [...control.control, ...updatedNoneJSON];
                  }
                }
              }

              return {
                ...control,
                styleid: newStyleId,
                parentid: newID,
                // stylemedia: controlToAdd.stylemedia,
                name:
                  itemIndex > 1
                    ? `${control.name}${itemIndex - 1}`
                    : control.name,

                control: control.control.map(assignUniqueStyleId),
              };
            };

            const updatedItem = applyStylesRecursively(
              assignUniqueStyleId({ ...item, id: newID }),
              defaultStyle,
              selectedNode.id
            );

            return updatedItem;
          });

          selectedNode.control.splice(
            insertionIndex,
            0,
            ...adjustedSingleChoice
          );

          setOpenDialog(false);
          setTreeData([...treeData]);
          return;
        }

        if (controlType === "DropDown") {
          let currentMaxStyleId = calculateNextStyleIdBlocks();
          const DropDownCount = selectedNode.control.filter(
            (control) => control.controltype === "DropDown"
          ).length;
          const adjustedLongText = DropDownJSON.map((item, index) => {
            const newID = `${selectedNode.id}.${getNextIDSforDatablocks(selectedNode) + index}`;
            const assignUniqueStyleId = (control) => {
              const styleIdNumber = parseInt(
                currentMaxStyleId.replace(/[a-zA-Z]/g, "")
              );
              const newStyleId = `agrfb${styleIdNumber + index}`;
              currentMaxStyleId = `agrfb${styleIdNumber + index + 1}`;

              const itemIndex =
                DropDownCount > 0 ? DropDownCount + 1 : index + 1;

              return {
                ...control,
                styleid: newStyleId,
                parentid: newID,
                // stylemedia: controlToAdd.stylemedia,
                name:
                  itemIndex > 1
                    ? `${control.name}${itemIndex - 1}`
                    : control.name,

                control: control.control.map(assignUniqueStyleId),
              };
            };
            const updatedItem = applyStylesRecursively(
              assignUniqueStyleId({ ...item, id: newID }),
              defaultStyle,
              selectedNode.id
            );

            return updatedItem;
          });

          selectedNode.control.splice(insertionIndex, 0, ...adjustedLongText);

          setOpenDialog(false);
          setTreeData([...treeData]);
          return;
        }

        if (controlType === "FullName") {
          let currentMaxStyleId = calculateNextStyleIdBlocks();
          const FullNameCount = selectedNode.control.filter(
            (control) => control.controltype === "FullName"
          ).length;
          const adjustedLongText = FullNameJSON.map((item, index) => {
            // const newID = `0${item.id.split(".")[0]}.${getNextIDS(treeData) + index}`;
            const newID = `${selectedNode.id}.${getNextIDSforDatablocks(selectedNode) + index}`;
            const assignUniqueStyleId = (control) => {
              const styleIdNumber = parseInt(
                currentMaxStyleId.replace(/[a-zA-Z]/g, "")
              );
              const newStyleId = `agrfb${styleIdNumber + index}`;
              currentMaxStyleId = `agrfb${styleIdNumber + index + 1}`;
              const itemIndex =
                FullNameCount > 0 ? FullNameCount + 1 : index + 1;

              return {
                ...control,
                styleid: newStyleId,
                // stylemedia: controlToAdd.stylemedia,
                name:
                  itemIndex > 1
                    ? `${control.name}${itemIndex - 1}`
                    : control.name,

                parentid: newID,
                control: control.control.map(assignUniqueStyleId),
              };
            };
            const updatedItem = applyStylesRecursively(
              assignUniqueStyleId({ ...item, id: newID }),
              defaultStyle,
              selectedNode.id
            );

            return updatedItem;
          });

          selectedNode.control.splice(insertionIndex, 0, ...adjustedLongText);

          setOpenDialog(false);
          setTreeData([...treeData]);
          return;
        }

        if (controlType === "Address") {
          let currentMaxStyleId = calculateNextStyleIdBlocks();
          const addressCount = selectedNode.control.filter(
            (control) => control.controltype === "Address"
          ).length;

          const adjustedAddress = AddressJSON[0].map((item, index) => {
            const newID = `${selectedNode.id}.${getNextIDSforDatablocks(selectedNode) + index}`;

            // Ensure the index starts from 1 for new Address controls
            const itemIndex = addressCount > 0 ? addressCount + 1 : index + 1;

            // Modify this logic to hide the index if it's 0
            const assignUniqueStyleId = (control) => {
              const styleIdNumber = parseInt(
                currentMaxStyleId.replace(/[a-zA-Z]/g, "")
              );
              const newStyleId = `agrfb${styleIdNumber + index}`;
              currentMaxStyleId = `agrfb${styleIdNumber + index + 1}`;

              return {
                ...control,
                styleid: newStyleId,
                parentid: newID,
                // stylemedia: controlToAdd.stylemedia,
                name:
                  itemIndex > 1
                    ? `${control.name}${itemIndex - 1}`
                    : control.name,
                control: control.control.map(assignUniqueStyleId),
              };
            };

            const updatedItem = applyStylesRecursively(
              assignUniqueStyleId({
                ...item,
                id: newID,
                // Only append the index if it's greater than 0
              }),
              defaultStyle,
              selectedNode.id
            );

            return updatedItem;
          });

          selectedNode.control.splice(insertionIndex, 0, ...adjustedAddress);

          setOpenDialog(false);
          setTreeData([...treeData]);
          return;
        }

        if (controlType === "RatingBlock") {
          let currentMaxStyleId = calculateNextStyleIdBlocks();

          const SingleChoiceCount = selectedNode.control.filter(
            (control) => control.controltype === "RatingBlock"
          ).length;

          const adjustedSingleChoice = RatingJSON.map((item, index) => {
            const baseID = item.id.split(".")[0];
            const newID = `${selectedNode.id}.${getNextIDSforDatablocks(selectedNode) + index}`;

            const assignUniqueStyleId = (control) => {
              const styleIdNumber = parseInt(
                currentMaxStyleId.replace(/[a-zA-Z]/g, "")
              );
              const newStyleId = `agrfb${styleIdNumber + index}`;
              currentMaxStyleId = `agrfb${styleIdNumber + index + 1}`;

              const itemIndex =
                SingleChoiceCount > 0 ? SingleChoiceCount + 1 : index + 1;

              if (control.checkposition === "RatingStarInput") {
                const existingRadioCount = control.control.filter(
                  (c) => c.styleid && c.styleid.startsWith("agrfb")
                ).length;

                if (existingRadioCount < 4) {
                  for (let i = 0; i < 4; i++) {
                    const updatedNoneJSON = StarJSON.map(
                      (noneItem, noneIndex) => ({
                        ...noneItem,
                        id: `${newID}.${getNextIDS(treeData) + noneIndex + i}`,
                        parentid: newID,
                        // stylemedia: controlToAdd.stylemedia,
                        name:
                          itemIndex > 1
                            ? `${noneItem.name}${itemIndex - 1}`
                            : noneItem.name,

                        styleid: `agrfb${styleIdNumber + i}`,
                        data: `data${noneIndex + i}`,
                      })
                    );

                    control.control = [...control.control, ...updatedNoneJSON];
                  }
                }
              }

              return {
                ...control,
                styleid: newStyleId,
                parentid: newID,
                name:
                  itemIndex > 1
                    ? `${control.name}${itemIndex - 1}`
                    : control.name,

                control: control.control.map(assignUniqueStyleId),
              };
            };

            const updatedItem = applyStylesRecursively(
              assignUniqueStyleId({ ...item, id: newID }),
              defaultStyle,
              selectedNode.id
            );

            return updatedItem;
          });

          selectedNode.control.splice(
            insertionIndex,
            0,
            ...adjustedSingleChoice
          );

          setOpenDialog(false);
          setTreeData([...treeData]);
          return;
        }

        if (selectedNode && selectedNode.control) {
          if (
            selectedNode.title === "Display" ||
            selectedNode.title === "Input" ||
            selectedNode.title === "Icon" ||
            selectedNode.title === "Datablocks"
          ) {
            const newID = getNextIDS(treeData);

            const newNode = {
              id: `0.${newID}`,
              name: newName,
              controlName: newName,
              tableHeader: "",
              data: getDataForControlType(
                controlType,
                controlToAdd.description
              ),
              type: controlType,
              parentid: "0",
              styleid: styleid,
              htmlTag: customHtmlTag,
              controltype: controlType,
              styles: JSON.stringify({ ...mergedStyles }, null, 2),
              styleType: controlToAdd.styleType,
              mediaURl: controlToAdd.mediaURl,
              check: isChecked,
              switchcheck: isSwitched,
              title: Controls.find((category) =>
                category.items.some((item) => item.controltype === controlType)
              )?.title,
              value: getValueForControlType(
                controlType,
                controlToAdd.description
              ),
              media: getMediaForControlType(
                controlType,
                controlToAdd.description
              ),
              uniqueKey: generateNewKeys,
              option: selectedOptioncheck,
              switchoption: selectedOptionSwitchcheck,
              position: selectedOptionmedia,
              checkposition: selectedPositioncheck,
              tab: tabSelect,
              inputType: inputType,
              inputText: getInputTextForControlType(
                controlType,
                controlToAdd.description
              ),
              imageText: imageText,
              sourceData: getSourceDataForControlType(
                controlType,
                controlToAdd.description
              ),
              controlChange: controlType,
              tableProp: "null",
              url: url,
              control: [],
            };

            treeData[0].Source[0].control.splice(
              footerContainerIndex,
              0,
              newNode
            );
            setOpenDialog(false);
            setTreeData([...treeData]);
            return;
          }
          selectedNode.control.push(newNode);
          const itemToInsert = selectedNode.control.pop();
          selectedNode.control.splice(insertionIndex, 0, itemToInsert);

          setOpenDialog(false);
          setTreeData([...treeData]);
        } else {
          const { childID } = getNextIDS(treeData);

          if (
            Controls.find((category) =>
              category.items.some((item) => item.controltype === controlType)
            )?.title === "Display" ||
            Controls.find((category) =>
              category.items.some((item) => item.controltype === controlType)
            )?.title === "Input" ||
            Controls.find((category) =>
              category.items.some((item) => item.controltype === controlType)
            )?.title === "Icon" ||
            Controls.find((category) =>
              category.items.some((item) => item.controltype === controlType)
            )?.title === "Datablocks"
          ) {
            return;
          }

          const newNode = {
            id: childID,
            name: newName,
            controlName: newName,
            tableHeader: "",
            data: controlToAdd.description,
            type: controlType,
            parentid: "0",
            styleid: styleid,
            htmlTag: customHtmlTag,
            controltype: controlType,
            styles: JSON.stringify({ ...mergedStyles }, null, 2),
            styleType: controlToAdd.styleType,
            mediaURl: controlToAdd.mediaURl,
            check: isChecked,
            switchcheck: isSwitched,
            title: Controls.find((category) =>
              category.items.some((item) => item.controltype === controlType)
            )?.title,
            value: getValueForControlType(
              controlType,
              controlToAdd.description
            ),
            media: getMediaForControlType(
              controlType,
              controlToAdd.description
            ),
            uniqueKey: generateNewKeys,
            option: selectedOptioncheck,
            switchoption: selectedOptionSwitchcheck,
            position: selectedOptionmedia,
            checkposition: selectedPositioncheck,
            tab: tabSelect,
            inputType: inputType,
            inputText: getInputTextForControlType(
              controlType,
              controlToAdd.description
            ),
            imageText: imageText,
            sourceData: getSourceDataForControlType(
              controlType,
              controlToAdd.description
            ),
            controlChange: controlType,
            tableProp: "null",
            url: url,
            control: [],
          };
          treeData[0].Source[0].control.splice(
            footerContainerIndex,
            0,
            newNode
          );

          setOpenDialog(false);
          setTreeData([...treeData]);
        }
      }

      if (
        footerContainerIndex !== -1 &&
        footerContainerIndex !== treeData[0].Source[0].control.length - 1
      ) {
        const [removed] = treeData[0].Source[0].control.splice(
          footerContainerIndex,
          1
        );
        treeData[0].Source[0].control.push(removed);
        setTreeData([...treeData]);
      }
    } catch (error) {
      console.error("An error occurred in handleAddControl", error);
      setError("An error occurred in handleAddControl", error);
    }
  };

  const onDragEnd = (result) => {
    try {
      setIsDragging(false);
      const { destination, source, draggableId } = result;
      let draggedControl = null;
      let sourceIndex = -1;
      let sourceControlArray = -1;

      Controls.forEach((controlArray, index) => {
        const foundControl = controlArray.items.find(
          (control) => String(control.id) === draggableId
        );
        if (foundControl) {
          draggedControl = foundControl;
          sourceIndex = source.index;
          sourceControlArray = index;
        }
      });

      if (!draggedControl) {
        return;
      }

      if (!destination) {
        handleAddControl(draggedControl.controltype);
        return;
      }

      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return;
      }
    } catch (error) {
      console.error("An error occurred in onDragEnd", error);
      setError("An error occurred in onDragEnd", error);
    }
  };

  const onDragStart = () => {
    try {
      setIsDragging(true);
    } catch (error) {
      console.error("An error occurred in onDragStart", error);
      setError("An error occurred in onDragStart", error);
    }
  };

  const findLastStyleId = (item) => {
    try {
      if (!item.control || item.control.length === 0) {
        return item.styleid;
      }
      const lastControl = item.control[item.control.length - 1];

      // Ensure that the recursion terminates when there is no deeper control
      if (!lastControl.control || lastControl.control.length === 0) {
        return lastControl.styleid;
      }

      return findLastStyleId(lastControl);
    } catch (error) {
      console.error("An error occurred in findLastStyleId", error);
      setError("An error occurred in findLastStyleId", error);
    }
  };

  const handleDelete = (idToDelete) => {
    try {
      const itemToDelete = findItemById(treeData, idToDelete);

      if (itemToDelete && itemToDelete.id) {
        const lastStyleId = findLastStyleId(itemToDelete);

        localStorage.setItem("deletedItemId", lastStyleId);

        if (isRootItem(itemToDelete, treeData)) {
          return;
        }
        onDeleteItem(itemToDelete.id);
      }
    } catch (error) {
      console.error("An error occurred in handleDelete", error);
      setError("An error occurred in handleDelete", error);
    }
  };

  const findItemById = (treeData, id) => {
    try {
      let foundItem = null;
      const searchTree = (node) => {
        if (node.id === id) {
          foundItem = node;
          return;
        }
        if (node.control) {
          for (const child of node.control) {
            searchTree(child);
          }
        }
      };

      treeData.forEach((rootItem) => {
        rootItem.Source.forEach((sourceItem) => {
          searchTree(sourceItem);
        });
      });

      return foundItem;
    } catch (error) {
      console.error("An error occurred in findItemById", error);
      setError("An error occurred in findItemById", error);
    }
  };

  console.log("treedata", treeData);

  const onDeleteItem = (itemIdToDelete) => {
    try {
      let previousItem = null;

      const findPreviousItem = (node, parent = null) => {
        if (node.id === itemIdToDelete) {
          return previousItem;
        }

        previousItem = node;

        if (node.control) {
          for (let i = 0; i < node.control.length; i++) {
            const result = findPreviousItem(node.control[i], node);
            if (result) return result;
          }
        }
        return null;
      };

      treeData.forEach((rootItem) => {
        rootItem.Source.forEach((sourceItem) => {
          findPreviousItem(sourceItem);
        });
      });

      const deleteItemRecursively = (node) => {
        if (node.id === itemIdToDelete) {
          return null;
        }

        if (node.control) {
          const updatedControl = node.control
            .map((child) => deleteItemRecursively(child))
            .filter(Boolean);

          return {
            ...node,
            control: updatedControl,
          };
        }

        return node;
      };

      const updatedTreeData = treeData.map((rootItem) => ({
        ...rootItem,
        Source: rootItem.Source.map((sourceItem) => ({
          ...sourceItem,
          control: sourceItem.control
            .map((item) => deleteItemRecursively(item))
            .filter(Boolean),
        })),
      }));

      setTreeData(updatedTreeData);

      const element = document.getElementById(selectedItem.id);
      const focusedNode = document.querySelector(".focusingClass");

      if (element) {
        if (focusedNode) {
          focusedNode.classList.remove("focusingClass");
        }
        element.classList.add("focusingClass");
      }
      setTimeout(() => {
        const itemToSelect = previousItem || updatedTreeData[0]?.Source[0];
        if (itemToSelect) {
          setSelectedNode(itemToSelect);
          setSelectedItem(itemToSelect);
          setSelectedControl(itemToSelect);
          setEditedName(itemToSelect.name);
          setSelectedItemID(itemToSelect.id);
          setSelectedOptioncheck(itemToSelect.check ? "true" : "false");
          setSelectedOptionSwitchcheck(
            itemToSelect.switchcheck ? "true" : "false"
          );
          setSelectedPositioncheck(
            itemToSelect.checkposition ||
              (itemToSelect.media ? "left" : "right")
          );
          setEditedName(itemToSelect.name || editedName);
          setEditedData(itemToSelect.data || "");
          setSelectedOption(
            itemToSelect.imageText ||
              itemToSelect.inputText ||
              itemToSelect.data
          );

          settabSelect(itemToSelect.tab || "");
          setTableHeader(itemToSelect.tableHeader ? "TableHeader" : "");
          setSelectedControlType(
            itemToSelect.controlChange || itemToSelect.selectedControlType
          );
          setIsOptionSelected(!isOptionSelected);
          settableProp(itemToSelect.tableProp ? "tableProp" : "null");
        }
      }, 0);
    } catch (error) {
      console.error("An error occurred in onDeleteItem", error);
      setError("An error occurred in onDeleteItem", error);
    }
  };

  let lastFocusedId = null;

  // const setIconsforFocus = (id) => {
  //   try {
  //     if (lastFocusedId && lastFocusedId !== id) {
  //       const previousIconContainer = document.getElementById(
  //         `iconContainer-${lastFocusedId}`
  //       );
  //       if (previousIconContainer) {
  //         previousIconContainer.remove();
  //       }
  //     }

  //     lastFocusedId = id;

  //     const element = document.getElementById(id);

  //     if (element && element.hasAttribute("styleid")) {
  //       const styleId = element.getAttribute("styleid");

  //       if (styleId) {
  //         const existingIconContainer = document.getElementById(
  //           `iconContainer-${id}`
  //         );
  //         if (!existingIconContainer) {
  //           let iconContainerHtml = `
  //           <span id="iconContainer-${id}" class="icon-container">
  //             <span class="icon-wrapper setting-icon-wrapper">
  //               <span class="fa fa-cog setting-icon" id="setIconFcs-${id}"></span>
  //               <span class="icon-text" id="setTextFcs-${id}">Properties</span>
  //             </span>
  //             ${
  //               id !== "0"
  //                 ? `<span class="icon-wrapper delete-icon-wrapper">
  //                     <span class="fa fa-trash delete-icon" id="trashIconFcs-${id}"></span>
  //                     <span class="icon-text">Delete</span>
  //                   </span>`
  //                 : ""
  //             }
  //           </span>`;

  //           element.insertAdjacentHTML("afterend", iconContainerHtml);

  //           const updateIconPosition = () => {
  //             const iconContainer = document.getElementById(
  //               `iconContainer-${id}`
  //             );
  //             if (iconContainer) {
  //               const rect = element.getBoundingClientRect();
  //               const elementCenterY =
  //                 rect.top + window.scrollY + rect.height / 2;
  //               const isInFooter = element.closest("footer");

  //               if (isInFooter) {
  //                 const topCenterPositionY =
  //                   rect.top + window.scrollY - iconContainer.offsetHeight / 2;
  //                 const centeredLeftPosition =
  //                   rect.left +
  //                   window.scrollX +
  //                   rect.width / 2 -
  //                   iconContainer.offsetWidth / 2;

  //                 iconContainer.style.top = `${topCenterPositionY}px`;
  //                 iconContainer.style.left = `${centeredLeftPosition}px`;
  //               } else if (id === "0") {
  //                 iconContainer.style.top = `${elementCenterY - iconContainer.offsetHeight / 2}px`;
  //                 iconContainer.style.left = `${rect.right + window.scrollX + 10}px`;
  //               } else {
  //                 const bottomCenterLeftPosition = rect.bottom + window.scrollY;
  //                 const centeredLeftPosition =
  //                   rect.left +
  //                   window.scrollX +
  //                   rect.width / 2 -
  //                   iconContainer.offsetWidth / 2;

  //                 iconContainer.style.top = `${bottomCenterLeftPosition}px`;
  //                 iconContainer.style.left = `${centeredLeftPosition}px`;
  //               }
  //             }
  //           };

  //           // Call updateIconPosition to set the initial position
  //           updateIconPosition();

  //           // Recalculate icon position on scroll, resize, and on click events
  //           document
  //             .querySelectorAll(".container-scrol-icon")
  //             .forEach((container) => {
  //               container.addEventListener("scroll", updateIconPosition);
  //             });
  //           window.addEventListener("scroll", updateIconPosition);
  //           window.addEventListener("resize", updateIconPosition);

  //           // Handle the interactions with the setting and delete icons
  //           const settingIcon = document.getElementById(`setIconFcs-${id}`);
  //           const settingText = document.getElementById(`setTextFcs-${id}`);
  //           const deleteIcon = document.getElementById(`trashIconFcs-${id}`);

  //           if (settingIcon && settingText) {
  //             settingIcon.addEventListener("click", handleTabshwButtonClick);
  //             settingText.addEventListener("click", handleTabshwButtonClick);
  //           }

  //           if (deleteIcon) {
  //             deleteIcon.addEventListener("click", () => {
  //               handleDelete(id);
  //             });
  //           }
  //         }
  //       }
  //     }
  //   } catch (error) {
  //     console.error("An error occurred in setIconsforFocus", error);
  //     showError("An error occurred in setIconsforFocus", error);
  //   }
  // };

  const setIconsforFocus = (id) => {
    try {
      const element = document.getElementById(id);

      if (!element || !element.hasAttribute("styleid")) return;

      // Remove the previous icon container if it's for a different ID
      if (lastFocusedId && lastFocusedId !== id) {
        const previousIconContainer = document.getElementById(
          `iconContainer-${lastFocusedId}`
        );
        if (previousIconContainer) {
          previousIconContainer.remove();
        }
      }

      lastFocusedId = id;

      // Remove the existing icon container for the same ID to recreate it
      const existingIconContainer = document.getElementById(
        `iconContainer-${id}`
      );
      if (existingIconContainer) {
        existingIconContainer.remove();
      }

      const styleId = element.getAttribute("styleid");
      if (!styleId) return;

      // Create the icon container HTML
      const iconContainerHtml = `
      <span id="iconContainer-${id}" class="icon-container">
        <span class="icon-wrapper setting-icon-wrapper">
          <span class="fa fa-cog setting-icon" id="setIconFcs-${id}"></span>
          <span class="icon-text" id="setTextFcs-${id}">Properties</span>
        </span>
        ${
          id !== "0"
            ? `<span class="icon-wrapper delete-icon-wrapper">
                <span class="fa fa-trash delete-icon" id="trashIconFcs-${id}"></span>
                <span class="icon-text">Delete</span>
              </span>`
            : ""
        }
      </span>`;

      // Insert the icon container after the focused element
      element.insertAdjacentHTML("afterend", iconContainerHtml);

      const updateIconPosition = () => {
        const rect = element.getBoundingClientRect();
        const iconContainer = document.getElementById(`iconContainer-${id}`);
        if (iconContainer) {
          const elementCenterY = rect.top + window.scrollY + rect.height / 2;
          const isInFooter = element.closest("footer");

          if (isInFooter) {
            const topCenterPositionY =
              rect.top + window.scrollY - iconContainer.offsetHeight / 2;
            const centeredLeftPosition =
              rect.left +
              window.scrollX +
              rect.width / 2 -
              iconContainer.offsetWidth / 2;

            iconContainer.style.top = `${topCenterPositionY}px`;
            iconContainer.style.left = `${centeredLeftPosition}px`;
          } else if (id === "0") {
            iconContainer.style.top = `${
              elementCenterY - iconContainer.offsetHeight / 2
            }px`;
            iconContainer.style.left = `${rect.right + window.scrollX + 10}px`;
          } else {
            const bottomCenterLeftPosition = rect.bottom + window.scrollY;
            const centeredLeftPosition =
              rect.left +
              window.scrollX +
              rect.width / 2 -
              iconContainer.offsetWidth / 2;

            iconContainer.style.top = `${bottomCenterLeftPosition}px`;
            iconContainer.style.left = `${centeredLeftPosition}px`;
          }
        }
      };

      // Update the icon position initially
      updateIconPosition();

      // Recalculate position on scroll, resize, and other events
      document
        .querySelectorAll(".container-scrol-icon")
        .forEach((container) => {
          container.addEventListener("scroll", updateIconPosition);
        });
      window.addEventListener("scroll", updateIconPosition);
      window.addEventListener("resize", updateIconPosition);

      // Set up event listeners for the setting and delete icons
      const settingIcon = document.getElementById(`setIconFcs-${id}`);
      const settingText = document.getElementById(`setTextFcs-${id}`);
      const deleteIcon = document.getElementById(`trashIconFcs-${id}`);

      if (settingIcon && settingText) {
        settingIcon.addEventListener("click", handleTabshwButtonClick);
        settingText.addEventListener("click", handleTabshwButtonClick);
      }

      if (deleteIcon) {
        deleteIcon.addEventListener("click", () => {
          handleDelete(id);
        });
      }
    } catch (error) {
      console.error("An error occurred in setIconsforFocus", error);
      setError("An error occurred in setIconsforFocus", error);
    }
  };

  const handleSelectItem = (
    id,
    name,
    type,
    data,
    styles,
    styleid,
    htmlTag,
    text,
    styleType,
    check,
    media,
    option,
    position,
    switchcheck,
    switchoption,
    checkposition,
    value,
    tab,
    inputType,
    tableHeader,
    sourceData,
    controltype,
    controlName,
    controlChange,
    tableProp,
    inputText,
    imageText,
    newPosition,
    url,
    title
  ) => {
    try {
      const element = document.getElementById(id);
      const nodeListfocused = document.querySelectorAll(".focusingClass")[0];
      if (element && !datablockChildControlIds.includes(id)) {
        if (nodeListfocused) {
          nodeListfocused.classList.remove("focusingClass");
        }
        element.classList.add("focusingClass");

        setSelectedItemID(id);
        setSelectedControl((prevSelectedItem) => ({
          ...prevSelectedItem,
          type,
        }));

        setSelectedItem((prevSelectedItem) => ({
          ...prevSelectedItem,
          id,
          name: name || editedName,
          type,
          data: data || editedData,
          styleid,
          styles,
          htmlTag,
          text,
          styleType,
          check: check || isChecked,
          media: media || checkboxPosition || RadioPosition,
          position: position || selectedOptionmedia,
          switchcheck: switchcheck || isSwitched,
          switchoption: switchoption || selectedOptionSwitchcheck,
          checkposition,
          tab: tabSelect || tab,
          inputType: inputType || inputType,
          tableHeader: tableHeader || tableHeader,
          sourceData: sourceData || sourceData,
          controltype,
          controlName,
          controlChange,
          tableProp,
          inputText,
          imageText,
          url,
          title,
        }));

        setSelectedOptioncheck(check ? "true" : "false");
        setSelectedOptionSwitchcheck(switchcheck ? "false" : "true");
        setSelectedPositioncheck(checkposition || (media ? "left" : "right"));
        setEditedName(name || editedName);
        setEditedData(data || editedData);
        setSelectedOption(imageText || inputText || data);

        settabSelect(tab || "");
        setTableHeader(tableHeader ? "TableHeader" : "");
        setSelectedControlType(controlChange || selectedControlType);
        setIsOptionSelected(!isOptionSelected);
        settableProp(tableProp ? "tableProp" : "null");
      } else {
        console.error("Element with ID", id, "not found in the DOM");
      }
    } catch (error) {
      console.error("An error occurred in handleSelectItem", error);
      setError("An error occurred in handleSelectItem", error);
    }
  };

  useLayoutEffect(() => {
    try {
      if (treeData?.length > 0 && !selectedNode) {
        const firstItem = treeData[0].Source[0];
        const firstItemElement = document.getElementById(firstItem.id);
        if (firstItemElement) {
          firstItemElement.classList.add("focusingClass");
        }
        setSelectedNode(firstItem);
        setSelectedItem(firstItem);
        setSelectedControl(firstItem);
        setEditedName(firstItem.name);
        setSelectedItemID(firstItem.id);
      }
    } catch (error) {
      console.error("An error occurred in useLayoutEffect", error);
      setError("An error occurred in useLayoutEffect", error);
    }
  }, [treeData, selectedNode]);

  // const updateCustomStyle = (styleid, style) => {
  //   setCustomStyles((prevCustomStyles) => ({
  //     ...prevCustomStyles,
  //     [styleid]: style,
  //   }));
  //   setTreeData((prevTreeData) => {
  //     const updatedTreeData = [...prevTreeData];
  //     const rootNode = updatedTreeData[0].Source[0];
  //     updateStyleInTree(rootNode, styleid, style);
  //     return updatedTreeData;
  //   });
  // };

  // const updateStyleInTree = (node, styleid, style) => {
  //   if (node.styleid === styleid) {
  //     node.styles = JSON.stringify({ ...style }, null, 2);
  //   }
  //   if (node.control && node.control.length > 0) {
  //     node.control.forEach((child) => updateStyleInTree(child, styleid, style));
  //   }
  // };

  const updateCustomStyle = (styleid, style, updateParentStyles = false) => {
    try {
      setCustomStyles((prevCustomStyles) => ({
        ...prevCustomStyles,
        [styleid]: style,
      }));

      setTreeData((prevTreeData) => {
        const updatedTreeData = [...prevTreeData];
        const rootNode = updatedTreeData[0].Source[0];
        updateStyleInTree(rootNode, styleid, style, updateParentStyles);
        return updatedTreeData;
      });
    } catch (error) {
      console.error("An error occurred in updateCustomStyle", error);
      setError("An error occurred in updateCustomStyle", error);
    }
  };

  const updateStyleInTree = (node, styleid, style, updateParentStyles) => {
    try {
      if (node.styleid === styleid) {
        // Update `parentstyles` if specified, otherwise update `styles`
        if (updateParentStyles) {
          node.parentstyles = JSON.stringify({ ...style }, null, 2);
        } else {
          node.styles = JSON.stringify({ ...style }, null, 2);
        }
      }

      // Recursively apply to child nodes if they exist
      if (node.control && node.control.length > 0) {
        node.control.forEach((child) =>
          updateStyleInTree(child, styleid, style, updateParentStyles)
        );
      }
    } catch (error) {
      console.error("An error occurred in updateStyleInTree", error);
      setError("An error occurred in updateStyleInTree", error);
    }
  };

  // const updateCustomStyle = (styleid, style, updateParentStyles = false) => {
  //   setCustomStyles((prevCustomStyles) => ({
  //       ...prevCustomStyles,
  //       [styleid]: style,
  //   }));

  //   setTreeData((prevTreeData) => {
  //       const updatedTreeData = [...prevTreeData];
  //       const rootNode = updatedTreeData[0].Source[0];
  //       updateStyleInTree(rootNode, styleid, style, updateParentStyles);
  //       return updatedTreeData;
  //   });
  // };

  // const updateStyleInTree = (node, styleid, style, updateParentStyles) => {
  //   if (node.styleid === styleid) {
  //       // Conditionally update parentstyles or styles
  //       if (updateParentStyles) {
  //           node.parentstyles = JSON.stringify(style, null, 2);
  //       } else {
  //           node.styles = JSON.stringify(style, null, 2);
  //       }
  //   }

  //   // Recursively apply to child nodes if they exist
  //   if (node.control && node.control.length > 0) {
  //       node.control.forEach((child) =>
  //           updateStyleInTree(child, styleid, style, updateParentStyles)
  //       );
  //   }
  // };

  const handleRightClick = (event, control) => {
    try {
      event.preventDefault();
      event.stopPropagation();

      const element = document.getElementById(control.id);
      const focusedNode = document.querySelector(".focusingClass");

      if (element) {
        // Remove any existing icons first
        const prevIcons = document.querySelectorAll(".icon-container");
        prevIcons.forEach((icon) => icon.remove());

        if (focusedNode) {
          focusedNode.classList.remove("focusingClass");
        }
        element.classList.add("focusingClass");
      }

      const styles = control.styles;
      const x = event.clientX;
      const y = event.clientY;

      setPopupPosition({ x, y });
      toggleDrawer(false);
      setSelectedItemID(control.id);
      setContextMenuStyles(styles);
      setSelectedControl(control);
      setSelectedNode(control);

      setSelectedItem(control);

      setSelectedOptioncheck(control.check ? "true" : "false");
      setSelectedOptionSwitchcheck(control.switchcheck ? "true" : "false");
      setSelectedPositioncheck(
        control.checkposition || (control.media ? "left" : "right")
      );
      setEditedName(control.name || editedName);
      setEditedData(control.data || "");
      setSelectedOption(control.imageText || control.inputText || control.data);

      settabSelect(control.tab || "");
      setTableHeader(control.tableHeader ? "TableHeader" : "");
      setSelectedControlType(
        control.controlChange || control.selectedControlType
      );
      setIsOptionSelected(!isOptionSelected);
      settableProp(control.tableProp ? "tableProp" : "null");

      setShowPopup(true);
    } catch (error) {
      console.error("An error occurred in handleRightClick", error);
      setError("An error occurred in handleRightClick", error);
    }
  };
  console.log("treeData", treeData);

  const renderControl = (control) => {
    try {
      if (!control || typeof control.id === "undefined") {
        return null;
      }

      const isItemSelected = control.id === selectedItemID;

      const onUpdateStyleFromTextarea = (updatedStyles) => {
        setContextMenuStyles(updatedStyles);

        try {
          const parsedStyles = JSON.parse(updatedStyles);
          updateCustomStyle(selectedControl.styleid, parsedStyles);
        } catch (error) {
          const keyValuePairs = updatedStyles.split(/\s*,\s*/);
          const nonJsonStyles = {};
          keyValuePairs.forEach((pair) => {
            const [key, ...valueParts] = pair.split(/\s*:\s*/);
            const value = valueParts.join(":");
            nonJsonStyles[key] = value;
          });
          updateCustomStyle(selectedControl.styleid, nonJsonStyles);
        }
      };

      const shouldRenderMediaUrl = control.styleType === "Icon";

      const controlTypeToRender = shouldRenderMediaUrl ? (
        <>
          {control.mediaURl && (
            <FontAwesomeIcon
              icon={{
                prefix: control.mediaURl.startsWith("fas")
                  ? "fas"
                  : control.mediaURl.startsWith("fab")
                    ? "fab"
                    : "fa",
                iconName: control.mediaURl,
              }}
            />
          )}
        </>
      ) : control.htmlTag === "input" ? (
        control.styleType
      ) : (
        control.data
      );

      const createdElement = (
        <CustomHtmlTag
          keyss={control.id}
          styleType={control.styleType}
          text={control.text}
          htmlTag={control.htmlTag}
          styleid={control.styleid}
          controltype={controlTypeToRender}
          isSelected={isItemSelected}
          styles={control.styles}
          onContextMenu={(event) => {
            handleRightClick(event, control);
          }}
          controlName={control.type}
          mediaURl={shouldRenderMediaUrl ? controlTypeToRender : ""}
          customStyles={customStyles}
          updateCustomStyle={updateCustomStyle}
          data={control.data}
          inputType={control.inputType}
          editedData={editedData}
          handleSelectItem={handleSelectItem}
          setIconsforFocus={setIconsforFocus}
          control={control}
          selectedNode={selectedNode}
          controlToAdd={control.controlToAdd}
          handleAddControl={handleAddControl}
          numberOfRadioInputs={numberOfRadioInputs}
          numberOfCheckbox={numberOfCheckbox}
          treeData={treeData}
          setTreeData={setTreeData}
          handleCheckboxChange={handleCheckboxChange}
          value={control.value}
          check={control.check}
          media={control.media}
          checkboxPosition={checkboxPosition}
          uniqueKey={control.uniqueKey}
          selectedValue={selectedValue}
          handleRadioChangeData={handleRadioChangeData}
          switchcheck={control.switchcheck}
          handleSelectChange={handleSelectChange}
          onClickforFocus={onClickforFocus}
          name={control.name}
          rightsideStyletab={rightsideStyletab}
          setOpenDialog={setOpenDialog}
          inputRef={inputRef}
          position={control.position}
          selectedPositioncheck={selectedPositioncheck}
          checkposition={control.checkposition}
          handleDateChange={handleDateChange}
          setControlsVisible={setControlsVisible}
          controlsVisible={controlsVisible}
          selectedInputType={inputType}
          inputText={control.inputText}
          imageText={control.imageText}
          url={control.url}
          selectedItem={selectedItem}
          setCustomStyles={setCustomStyles}
          defaultStyle={defaultStyle}
          setDefaultStyle={setDefaultStyle}
          selectedItemID={selectedItemID}
          onMouseOver={(event) => {
            handleMouseOver(event, control);
          }}
          onMouseOut={handleMouseOut}
          showTheme={showTheme}
          tableProp={control.tableProp}
          sourceData={control.sourceData}
        >
          {/* {control.control &&
          control.control.length > 0 &&
          control.control.map((childControl) => (
            <React.Fragment key={childControl.id}>
              <div
                onContextMenu={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                }}
               
              >
                {renderControl(childControl)}
              </div>
            </React.Fragment>
          ))} */}
          {control.control &&
            control.control.length > 0 &&
            control.control.map((childControl) => (
              <React.Fragment key={childControl.id}>
                {/* <span
                  onContextMenu={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                  className="control-render-page"
                > */}
                {renderControl(childControl)}
                {/* </span> */}
              </React.Fragment>
            ))}

          {selectedControl && selectedControl.id === control.id && (
            <React.Fragment onClick={handleRightClick}>
              <CustomPopUpForm
                onClose={handleClose}
                title={`Edit Styles: ${selectedControl.name}`}
                open={showPopup}
              >
                <div>
                  <textarea
                    value={contextMenuStyles}
                    onChange={(e) => onUpdateStyleFromTextarea(e.target.value)}
                    style={{ width: "100%", minHeight: "300px" }}
                  />
                </div>
              </CustomPopUpForm>
            </React.Fragment>
          )}
        </CustomHtmlTag>
      );
      return createdElement;
    } catch (error) {
      console.error("An error occurred in renderControl", error);
      setError("An error occurred in renderControl", error);
    }
  };

  const accumulateHtmlContent = () => {
    try {
      let accumulatedContent = "";

      treeData?.length > 0 &&
        treeData.forEach((item) => {
          const controlsHtml = item.Source[0].control
            .map((control) => {
              const createdElement = renderControl(control);
              let htmlString =
                ReactDOMServer.renderToStaticMarkup(createdElement);
              return htmlString;
            })
            .join("");
          accumulatedContent += `${controlsHtml}`;
        });
      setHtmlContent(accumulatedContent);
    } catch (error) {
      console.error("An error occurred in accumulateHtmlContent", error);
      setError("An error occurred in accumulateHtmlContent", error);
    }
  };

  useEffect(() => {
    accumulateHtmlContent();
  }, [accumulateHtmlContent]);

  useEffect(() => {
    const isThemesPage = window.location.href.includes("Themes");
    setShowTheme(!isThemesPage);
  }, []);

  const generateHTMLFile = (htmlContent) => {
    try {
      // const templateArid =
      //   treeData?.length > 0 && treeData[0]?.Source[0]?.templateArid;

      const tagMatches = htmlContent.matchAll(/<(?!(\/))(\S+?)(?=\s|\/|>)/g);
      const tags = [...tagMatches].map((match) => match[0].slice(1));

      const regex = /style="([^"]*)"/g;
      const styleMatches = [...htmlContent.matchAll(regex)];
      const styles = styleMatches.map((match) => `{${match[1]}}`);
      const styleidMatches = htmlContent.match(/styleid="([^"]*)"/g);
      const styleids = styleidMatches
        ? styleidMatches.map((styleidMatch) => {
            return styleidMatch.slice(9, -1);
          })
        : [];

      const nameMatches = htmlContent.match(/name="([^"]*)"/g);
      const names = nameMatches
        ? nameMatches.map((nameMatch) => {
            return nameMatch.slice(6, -1);
          })
        : [];

      htmlContent = htmlContent.replace(/ style="([^"]*)"/g, "");

      const tagStylePairs = tags.map((tag, index) => ({
        tag,
        style: styles[index] || "{}",
        styleid: styleids[index] || "",
        name: names[index] || "",
      }));

      let formattedTagWithStyle = "";
      tagStylePairs.forEach(({ tag, style, styleid, name }) => {
        let tagWithStyle = `${styleid ? `[styleid="${styleid}"]` : ""}`;
        if (style !== "{}" || styleid || name) {
          tagWithStyle += `${style === "{}" ? "" : style}`;
          tagWithStyle += `${style === "{}" ? "" : style}`;
          formattedTagWithStyle += `${tagWithStyle}\r\n`;
        }
      });

      let switchCSSGenerated = false;
      let sliderCSSGenerated = false;
      let RadioCSSGenerated = false;
      let CheckCSSGenerated = false;

      const generateStyle = `<style>
/*-- GEN <generic> --*/

  ${formattedTagWithStyle
    .split("\r\n")
    .filter((line) => line.trim() !== "")
    .map((line) => {
      const [selector, properties] = line.split("{");
      const formattedProperties = properties ? properties.split(";") : [];
      const formattedPropertiesString = formattedProperties
        .filter((property) => property.trim() !== "")
        .map((property) => `${property.trim()}`)
        .map((property) => `${property.trim()}`)
        .join(";\r\n");
      return `${selector.trim()}{\r\n${formattedPropertiesString}\r\n`;
    })
    .join("\r\n")}

    .control-render-page {
      display: contents;
    }
      ${
        !switchCSSGenerated && names.includes("Switch")
          ? `  
   
      .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
      }
   
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }
   
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
      }
   
      .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
      }
   
      input:checked + .slider {
        background-color: #2196F3;
      }
   
      input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
      }
   
      input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
   
      .slider.round {
        border-radius: 34px;
      }
   
      .slider.round:before {
        border-radius: 50%;
      }
      `
          : ""
      }
  ${
    !sliderCSSGenerated && names.includes("Slider")
      ? `
  .sliderwidth {
  width:400px;
  }`
      : ""
  }

       ${
         !RadioCSSGenerated && names.includes("Radio")
           ? `  
   
      .check{
  margin-right: 5px;
}
.checkleft{
  margin-left: 5px;
}
      `
           : ""
       }

           ${
             !CheckCSSGenerated && names.includes("Checkbox")
               ? `  
   
      .check{
  margin-left: 5px;
}
.checkleft{
  margin-right: 5px;
}
      `
               : ""
           }


.controlCls:focus {
    outline: none;
  }

tr{
display : flex;
}
          
  </style>`;

      if (names.includes("Switch")) {
        switchCSSGenerated = true;
      }

      if (names.includes("Slider")) {
        sliderCSSGenerated = true;
      }

      if (names.includes("Radio")) {
        RadioCSSGenerated = true;
      }

      if (names.includes("Checkbox")) {
        CheckCSSGenerated = true;
      }

      let restOfHtmlContent = htmlContent;

      let headerContent = "";
      let footerContent = "";

      if (htmlContent.includes("<header")) {
        const headerStartIndex = htmlContent.indexOf("<header");
        const headerEndIndex =
          htmlContent.indexOf("</header>") + "</header>".length;
        headerContent = htmlContent.substring(headerStartIndex, headerEndIndex);
        restOfHtmlContent =
          htmlContent.substring(0, headerStartIndex) +
          htmlContent.substring(headerEndIndex);
      }

      if (restOfHtmlContent.includes("<footer")) {
        const footerStartIndex = restOfHtmlContent.indexOf("<footer");
        const footerEndIndex =
          restOfHtmlContent.indexOf("</footer>") + "</footer>".length;
        footerContent = restOfHtmlContent.substring(
          footerStartIndex,
          footerEndIndex
        );
        restOfHtmlContent =
          restOfHtmlContent.substring(0, footerStartIndex) +
          restOfHtmlContent.substring(footerEndIndex);
      }

      const bodyHeaderComment = (index) => {
        return `<!-- Body Header ${index} -------->
<!------------------------------------------------------>
<!-- GEN <generic>`;
      };

      const theadComment = () => {
        return `\r\n<!------------------------------------------------------>
<!-- Inner Query loop 1 --->
<!------------------------------------------------------>
<!-- GEN <generic>`;
      };

      const closingDiv = `</div></div>`;

      const openingDiv = `<div class="form-group ctable col-sm-12">
    <div class="table-responsive">`;

      const extractTagsAfterHeader = (html) => {
        const headerEndIndex = html.indexOf("</header>");
        if (headerEndIndex !== -1) {
          return html.substring(headerEndIndex + "</header>".length);
        } else {
          return html;
        }
      };

      const tagsAfterHeader = extractTagsAfterHeader(htmlContent);
      let combinedOutput = `\t${bodyHeaderComment(1)}<div id="0.0X" class="childdiv" style="display: flex; flex-direction : column; margin:auto;  background-color :${styleObject.backgroundColor}; background-image:${styleObject.backgroundImage || null}; background-repeat: no-repeat;
 max-width: 900px;
    min-width: 900px;
background-position: center;
${templateArid == 1 ? `width: 100%;` : `width:${styleObject.width};`};
background-size: cover;min-height: 100vh;">${tagsAfterHeader}`;
      let currentIndex = 0;
      let bodyHeaderIndex = 1;
      while (currentIndex !== -1) {
        const closingTableIndex = tagsAfterHeader.indexOf(
          "</table>",
          currentIndex
        );
        if (closingTableIndex !== -1) {
          const startTableIndex = tagsAfterHeader.lastIndexOf(
            "<table",
            closingTableIndex
          );
          if (startTableIndex !== -1) {
            const tableContent = tagsAfterHeader.substring(
              startTableIndex,
              closingTableIndex + "</table>".length
            );
            combinedOutput = combinedOutput.replace(
              tableContent,
              `${openingDiv}${tableContent}${closingDiv}${bodyHeaderComment(++bodyHeaderIndex)}`
            );
            currentIndex = closingTableIndex + "</table>".length;
          }
        } else {
          if (bodyHeaderIndex < 3) {
            combinedOutput += `\t${bodyHeaderComment(++bodyHeaderIndex)}`;
          } else {
            break;
          }
        }
      }

      let closingTheadIndex = combinedOutput.indexOf("</thead>");

      while (closingTheadIndex !== -1) {
        combinedOutput =
          combinedOutput.slice(0, closingTheadIndex + "</thead>".length) +
          `\n${theadComment(currentIndex)}\n` +
          combinedOutput.slice(closingTheadIndex + "</thead>".length);

        closingTheadIndex = combinedOutput.indexOf(
          "</thead>",
          closingTheadIndex + 1
        );
        currentIndex++;
      }

      const trComment = () => {
        return `\r\n<!------------------------------------------------------>
<!-- Inner Query loop 2 --->
<!------------------------------------------------------>
<!-- GEN <generic>`;
      };

      const innerLoopComment = `\r\n<!------------------------------------------------------>
<!-- Inner Query loop 3 --->
<!------------------------------------------------------>
<!-- GEN <generic>`;

      const footerComment = (index) => {
        return `<!------------------------------------------------------>
<!-- Body Footer ${index} -------->
<!------------------------------------------------------>
<!-- GEN <generic>`;
      };

      let footerindex = 1;

      let closingTRIndex = combinedOutput.indexOf("</tr>");

      while (closingTRIndex !== -1) {
        combinedOutput =
          combinedOutput.slice(0, closingTRIndex + "</tr>".length) +
          `\n${trComment(currentIndex)}\n${footerComment(footerindex++)}\n` +
          combinedOutput.slice(closingTRIndex + "</tr>".length);

        closingTRIndex = combinedOutput.indexOf("</tr>", closingTRIndex + 1);
        currentIndex++;
      }

      let theadStartIndex = combinedOutput.indexOf(theadComment());
      let theadEndIndex = combinedOutput.indexOf(trComment());

      while (theadStartIndex !== -1 && theadEndIndex !== -1) {
        let contentBetweenComments = combinedOutput.substring(
          theadStartIndex + theadComment().length,
          theadEndIndex
        );

        let trCommentIndex = combinedOutput.indexOf(trComment(), theadEndIndex);
        if (trCommentIndex !== -1) {
          combinedOutput =
            combinedOutput.slice(0, trCommentIndex + trComment().length) +
            "\n" +
            contentBetweenComments +
            innerLoopComment +
            contentBetweenComments +
            combinedOutput.slice(trCommentIndex + trComment().length);
        }

        theadStartIndex = combinedOutput.indexOf(
          theadComment(),
          trCommentIndex + trComment().length
        );
        theadEndIndex = combinedOutput.indexOf(
          trComment(),
          trCommentIndex + trComment().length
        );
      }

      const sections = combinedOutput.split("<!-- Body Header");

      for (let i = 1; i < sections.length; i++) {
        const bodyHeaderIndex = sections[i].indexOf("<!-- GEN <generic>");
        const closingIndex = sections[i].indexOf("-->");

        if (closingIndex !== -1) {
          if (bodyHeaderIndex === -1) {
            const bodyHeader = sections[i].substring(0, closingIndex + 3);
            const afterHeader = sections[i].substring(closingIndex + 3);
            sections[i] =
              `${bodyHeader}\n<!-- GEN <generic> -->\n${afterHeader}`;
          }

          const afterHeader = sections[i].substring(closingIndex + 3);
          if (!afterHeader.includes("<!-- Inner Query loop 1 --->")) {
            sections[i] +=
              `\r\n${theadComment()}${trComment()}${innerLoopComment}${footerComment(footerindex++)}`;
          }
        }
      }

      combinedOutput = sections.join(
        "<!------------------------------------------------------>\n<!-- Body Header"
      );

      const formatAttributes = (attributes) => {
        return attributes.replace(/(\w+="[^"]+")/g, "\r\n$1").trim();
      };

      const footerFormattedContent = footerContent.replace(
        /<footer([^>]*)>/,
        (match, attributes) => {
          const formattedAttributes = formatAttributes(attributes);
          return `<footer\r\n      ${formattedAttributes}>`;
        }
      );

      const headerFormattedContent = headerContent.replace(
        /<header([^>]*)>/,
        (match, attributes) => {
          const formattedAttributes = formatAttributes(attributes);
          return `<header\r\n      ${formattedAttributes}>`;
        }
      );

      const formatTag = (tag, content) => {
        return content.replace(
          new RegExp(`<${tag}([^>]*)>`),
          (match, attributes) => {
            const formattedAttributes = formatAttributes(attributes);
            return `<${tag}\r\n      ${formattedAttributes}>`;
          }
        );
      };

      const formattedTags = {};

      const tagsRegex = /<(\w+)([^>]*)>/g;
      let match;
      while ((match = tagsRegex.exec(restOfHtmlContent)) !== null) {
        const tagName = match[1];
        if (
          tagName.toLowerCase() === "svg" ||
          tagName.toLowerCase() === "path"
        ) {
          continue;
        }
        if (!formattedTags[tagName]) {
          formattedTags[tagName] = [];
        }
        formattedTags[tagName].push(match[0]);
      }

      for (const tagName in formattedTags) {
        const tagInstances = formattedTags[tagName];
        for (const tagInstance of tagInstances) {
          restOfHtmlContent = restOfHtmlContent.replace(
            tagInstance,
            formatTag(tagName, tagInstance)
          );
        }
      }

      const modifyCombinedOutput = () => {
        const trRegex = /(<thead[^>]*>)/g;
        const modifiedWithtr = combinedOutput.replace(trRegex, "$1<tr>");

        const closeTrRegex = /(<\/thead>)/g;
        const modifiedOutput = modifiedWithtr.replace(closeTrRegex, "</tr>$1");

        return modifiedOutput;
      };

      const modifiedCombinedOutput = modifyCombinedOutput(combinedOutput);

      const modifiedOutput = modifiedCombinedOutput.replace(
        /<footer[^>]*>.*?<\/footer>/s,
        ""
      );

      const wrapperDiv = "";

      const filteredLines = modifiedOutput
        .split(/(<\/?[^>]+>|<!--[^>]*?-->)(?![^<>]*<\/generic>)/g)
        .filter(
          (line) =>
            !line.includes("<header") &&
            !line.includes("<footer") &&
            !line.includes("</footer")
        )
        .filter((line) => line.trim() !== "")
        .map((line) => {
          if (line.includes("<!--")) {
            line += "\n";
          }
          if (line.includes("<generic>")) {
            line = line.replace("<generic>", "<generic> -->");
          }
          return line.trim() === "" ? "" : line.trim();
        })
        .join("\n")
        .replace(/<div[^>]+id="0\.2[LR]"[^>]*>[\s\S]*?<\/div>/g, "")
        .replace(/\n+/g, "\n")
        .replace(/(<div[^>]+id="0\.0X"[^>]*>)/, `${wrapperDiv}$1`)
        .replace(/<generic> -->/g, "<generic> -->\n");

      const head = `
    <head>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <title>${extractedArrayName}</title>
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css" />
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.2.0/jquery.min.js"></script>
    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/js/bootstrap.min.js"></script>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/flatpickr/dist/flatpickr.min.css" />
    <script src="https://cdn.jsdelivr.net/npm/flatpickr"></script>


<!------------------------------------------------------>
<!-- Style Definition --->
<!------------------------------------------------------>
${generateStyle}
</head>`;

      const body = `
\r\n<body${templateArid == 1 ? `style="background-color: ${styleObject.borderColor}; background-image: ${parentstyleObject.backgroundImage || "none"}; background-position: center;"` : ""}>


<!------------------------------------------------------>
<!-- Page Header -------->
<!------------------------------------------------------>
<!-- GEN <generic> -->

<div class="maindiv" style="
  display: flex; 
  flex-direction: column; 
  background-image: ${parentstyleObject.backgroundImage || "none"}; 
  background-repeat: no-repeat;
  background-position: center;
  ${templateArid !== 1 ? "min-height: 100vh;" : ""}
  ${templateArid !== 1 ? `background-color: ${styleObject.borderColor}; background-image: ${parentstyleObject.backgroundImage || "none"};` : ""} background-size: cover;"   ${templateArid == 1 ? "width: 80%; margin: auto;" : ""}>
${headerFormattedContent}
${filteredLines}
</div>

</div>

<!------------------------------------------------------>
<!-- Page Footer -->
<!------------------------------------------------------>
<!-- GEN <generic> -->
${footerFormattedContent}

<!------------------------------------------------------>
<!-- Bottom Page -->
<!------------------------------------------------------>
</body>
`;

      const htmlFileContent = `<!DOCTYPE html>\r\n<html lang="en">${head}${body}</html>`;
      return htmlFileContent;
    } catch (error) {
      console.error("An error occurred in generateHTMLFile", error);
      setError("An error occurred in generateHTMLFile", error);
    }
  };

  const saveHtmlToFileSystem = async (content, fileName) => {
    try {
      const htmlFileContent = generateHTMLFile(content);
      console.log("htmlFileContent", htmlFileContent);
      if (window.showSaveFilePicker) {
        const fileHandle = await window.showSaveFilePicker({
          suggestedName: fileName,
          types: [
            {
              description: "HTML Files",
              accept: {
                "text/html": [".html"],
              },
            },
          ],
        });

        const writable = await fileHandle.createWritable({
          keepExistingData: false,
        });

        await writable.write(htmlFileContent);
        await writable.close();

        toast.success("HTML content saved to file system successfully");
      } else {
        const currentDate = new Date()
          .toLocaleString("sv-SE", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          })
          .replace(" ", "_")
          .replace(/-/g, "_")
          .replace(/:/g, "_");
        const updatedFileName = `${extractedArrayName}"Export"${currentDate}.html`;

        const blob = new Blob([htmlFileContent], { type: "text/html" });
        const anchor = document.createElement("a");
        anchor.href = URL.createObjectURL(blob);
        anchor.download = updatedFileName;
        anchor.click();
        toast.success("HTML content saved to file system successfully");
      }
    } catch (error) {
      console.error("Error saving HTML content to file system:", error);
      toast.error("Error saving HTML content to file system:", error);
    }
  };

  const handleSaveToFile = async () => {
    try {
      const currentDate = new Date()
        .toLocaleString("sv-SE", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        })
        .replace(" ", "_")
        .replace(/-/g, "_")
        .replace(/:/g, "_");
      const isTemplate = treeData[0]?.IsTemplate;
      if (isTemplate === 0) {
        onSaveClick();
      } else {
        onSaveClick1();
      }
      await saveHtmlToFileSystem(
        htmlContent,
        `${extractedArrayName}"Export"${currentDate}.html`
      );
    } catch (error) {
      console.error("An error occurred in handleSaveToFile", error);
      setError("An error occurred in handleSaveToFile", error);
    }
  };

  const [fieldData, setFieldData] = useState([]);

  const [extractedArrayName, setextractedArrayName] = useState("");
  const [tempNum, setTempNum] = useState();
  const [templateSource, setTemplateSource] = useState("");

  const [loading, setLoading] = useState(true);

  const [preJSON, setPrevJSON] = useState([]);

  useEffect(() => {
    try {
      setLoading(true);
      const fetchData = async () => {
        try {
          const jsonData = await ReadArray(arId, accessKey);

          console.log("jsonData", jsonData);

          const defaultStyleCopy = JSON.parse(JSON.stringify(jsonData));
          setPrevJSON(defaultStyleCopy);

          if (jsonData) {
            setTreeData(jsonData);

            const arrayName = jsonData[0].arrayName;
            const templateNum = jsonData[0].IsTemplate;
            const dataSourceArID = jsonData[0].dataSourceArID || null;
            setTempNum(templateNum);
            setextractedArrayName(arrayName);

            if (dataSourceArID > 0 || dataSourceArID !== null) {
              setFieldData(SourceOFDataSource);
            }
          } else {
            // console.error("Error fetching data:", error);
            // setError("An error occurred in Fetching Data Source");
            // localStorage.setItem(
            //   "error",
            //   "An error occurred in Fetching Data Source"
            // );
            // localStorage.setItem("navigate", "/Open");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          setError("Error fetching data:");
          localStorage.setItem("error", "Error fetching data:");
          localStorage.setItem("navigate", "/Open");
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    } catch (error) {
      console.error("An error occurred in useEffect setLoading", error);
      setError("An error occurred in useEffect setLoading");
      localStorage.setItem(
        "error",
        "An error occurred in useEffect setLoading"
      );
      localStorage.setItem("navigate", "/Open");
    }
  }, [arId]);

  useEffect(() => {
    try {
      const arrayFieldNames = fieldData
        ? fieldData
            .filter((item) => item.elementtype === "array")
            .flatMap((item) =>
              item.elements.map((element) => element.fieldname)
            )
        : [];
      setFieldNamestable(arrayFieldNames);
    } catch (error) {
      console.error("An error occurred in useEffect fieldData", error);
      setError("An error occurred in useEffect fieldData", error);
    }
  }, [fieldData]);

  const handleGoBack = async () => {
    function compareJSON(json1, json2) {
      if (typeof json1 !== typeof json2) return false;

      if (typeof json1 !== "object" || json1 === null || json2 === null) {
        return json1 === json2;
      }

      const keys1 = Object.keys(json1);
      const keys2 = Object.keys(json2);

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (let key of keys1) {
        if (!keys2.includes(key) || !compareJSON(json1[key], json2[key])) {
          return false;
        }
      }

      return true;
    }

    const compare = compareJSON(preJSON[0].Source, treeData[0].Source);
    try {
      if (preJSON) {
        if (!compare) {
          setOpenConfirmation(true);
        } else {
          navigate("/Open", {
            state: {
              defaultStyle: stylesFromLoc,
              ReadAllData: ReadArrayAllData,
            },
          });
        }
      } else {
        console.log("Failed to fetch data");
        setError("Error fetch data:");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("An error occurred in fetching data");
      localStorage.setItem("error", "An error occurred in fetching data");
      localStorage.setItem("navigate", "/Open");
    }

    // console.log("updated prevJSON" , preJSON[0].Source);
    // console.log("updated prevJSON" , treeData[0].Source)
  };

  const handleGoBackTheme = async () => {
    const filteredObjects = ReadArrayAllData.filter(
      (item) => item.IsTemplate === 4
    );

    Navigate("/Themes", {
      state: {
        defaultStyle: stylesFromLoc,
        ThemeData: filteredObjects,
      },
    });
  };

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  const handleConfirmGoBack = () => {
    setOpenConfirmation(false);
    // Navigate("/Open");
    navigate("/Open", {
      state: {
        defaultStyle: stylesFromLoc,
        ReadAllData: ReadArrayAllData,
      },
    });
  };

  const handleConfirmGoBackWithSave = () => {
    handleConfirmGoBack();
  };
  const isRootItem = (item, treeData) => {
    return treeData[0].Source[0].id === item.id;
  };

  useEffect(() => {
    setInputValue(inputValue);
  }, []);

  const handleEnterForNumber = (event) => {
    if (event.key === "Enter") {
      handleAgree(event);
    }
  };
  const handleDateChange = (event, timeID) => {
    try {
      const newDate = event.target.value;

      const updateTreeData = updateTreeDataDate(treeData, timeID, newDate);
      setTreeData(updateTreeData);

      if (selectedItem && selectedItem.id === timeID) {
        setSelectedItem({
          ...selectedItem,
          data: newDate,
        });
      }
    } catch (error) {
      console.error("An error occurred in handleDateChange", error);
      setError("An error occurred in handleDateChange", error);
    }
  };

  const updateTreeDataDate = (treeData, timeID, newDate) => {
    return treeData.map((item) => {
      const updatedSource = item.Source.map((source) => ({
        ...source,
        control: updateControlsDate(source.control, timeID, newDate),
      }));
      return { ...item, Source: updatedSource };
    });
  };

  const updateControlsDate = (controls, timeID, newDate) => {
    return controls.map((c) => {
      if (c.id === timeID) {
        return {
          ...c,
          data: newDate,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlsDate(c.control, timeID, newDate),
        };
      }
      return c;
    });
  };

  const rootStyle =
    treeData?.length > 0 &&
    treeData[0].Source && // Check if Source exists
    treeData[0].Source.find((item) => item.type === "Root");

  const [tabValue1, setTabValue1] = useState(0);
  const [tabValue2, setTabValue2] = useState(0);
  const [openpaint, setOpenpaint] = useState(false);

  const toggleDrawer = (newOpen) => () => {
    try {
      handleMenuButtonClick();
      setOpenpaint(newOpen);
      // const focusedNode = document.querySelector(".focusingClass");
      const settingWrapper = document.querySelector(".setting-icon-wrapper");
      const DeleteWrapper = document.querySelector(".delete-icon-wrapper");

      // if (focusedNode) {
      //   focusedNode.classList.remove("focusingClass");
      // }
      if (settingWrapper) {
        settingWrapper.remove();
      }
      if (DeleteWrapper) {
        DeleteWrapper.remove();
      }
      // const prevIcons = document.querySelectorAll(".icon-container");
      // prevIcons.forEach((icon) => icon.remove());
    } catch (error) {
      console.error("An error occurred in toggleDrawer", error);
      setError("An error occurred in toggleDrawer", error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue1(newValue);
  };
  const handleNestedTabChange = (event, newValue) => {
    setTabValue2(newValue);
  };

  let styleObject = {};

  let parentstyleObject = {};

  if (rootStyle && rootStyle.styles) {
    try {
      styleObject = JSON.parse(rootStyle.styles);
    } catch (error) {
      console.error("Error parsing styles:", error);
      setError("Error parsing styles:", error);
    }
  }

  if (rootStyle && rootStyle.parentstyles) {
    try {
      parentstyleObject = JSON.parse(rootStyle.parentstyles);
    } catch (error) {
      console.error("Error parsing styles:", error);
      setError("Error parsing styles:", error);
    }
  }

  const [boderColor, setBoderColor] = useState("#ffffff");

  const [selectedColorScheme, setSelectedColorScheme] = useState(null);

  const rgbToHex = (rgb) => {
    try {
      const result = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

      return result
        ? `#${(
            (1 << 24) +
            (parseInt(result[1]) << 16) +
            (parseInt(result[2]) << 8) +
            parseInt(result[3])
          )
            .toString(16)
            .slice(1)
            .toUpperCase()}`
        : rgb === "transparent"
          ? "#000000"
          : rgb;
    } catch (error) {
      console.error("An error occurred in rgbToHex", error);
      setError("An error occurred in rgbToHex", error);
    }
  };

  function applyInputColorToStylesforInput1(stylesArray) {
    stylesArray.forEach((style) => {
      const styles = JSON.parse(style.styles);
      styles.backgroundColor = "#ffffff";
      setInputColor(styles.backgroundColor);
      updateCustomStyle(style.styleid, styles);
    });
  }

  const handleColorSchemeChange = (scheme) => {
    try {
      if (!scheme) return;

      const hexBgColor =
        scheme.backgroundColor === "transparent"
          ? "#00000000"
          : scheme.backgroundColor && scheme.backgroundColor.startsWith("rgb")
            ? rgbToHex(scheme.backgroundColor)
            : scheme.backgroundColor;

      const hexBorderColor =
        scheme.borderColor === "transparent"
          ? "#00000000"
          : scheme.borderColor && scheme.borderColor.startsWith("rgb")
            ? rgbToHex(scheme.borderColor)
            : scheme.borderColor;

      const hexFontColor =
        scheme.color && scheme.color.startsWith("rgb")
          ? rgbToHex(scheme.color)
          : scheme.color;

      setBgColor(hexBgColor);
      setBoderColor(hexBorderColor);
      setFontColor(hexFontColor);

      setSelectedColorScheme({
        ...scheme,
        parentBackgroundColor: hexBorderColor,
      });
    } catch (error) {
      console.error("An error occurred in handleColorSchemeChange", error);
      setError("An error occurred in handleColorSchemeChange", error);
    }
  };

  const handleInputChangebgcolor = (e) => {
    try {
      const newColor = e.target.value;
      setBoderColor(newColor);
    } catch (error) {
      console.error("An error occurred in handleInputChangebgcolor", error);
      setError("An error occurred in handleInputChangebgcolor", error);
    }
  };

  const [FormWidth, setFormWidth] = useState(100);
  const [verticalSpacing, setVerticalSpacing] = useState("0px");
  const [horizontalSpacing, setHorizontalSpacing] = useState("0px");
  // const [showPopup1, setShowPopup1] = useState(false);
  useEffect(() => {
    try {
      const rootStyle =
        treeData?.length > 0 &&
        treeData[0].Source.find((item) => item.type === "Root");

      const newStyles = JSON.parse(rootStyle && rootStyle.styles);

      if (newStyles) {
        updateCustomStyle("rootStyleId", {
          ...newStyles,
          backgroundColor: bgColor,
          borderColor: boderColor,
          maxWidth: FormWidth,
          paddingTop: verticalSpacing,
          paddingBottom: verticalSpacing,
          paddingLeft: horizontalSpacing,
          paddingRight: horizontalSpacing,
        });
      } else {
        // Navigate('/Open');
      }
    } catch (error) {
      console.error("An error occurred in useEffect Update rootStyle", error);
      setError("An error occurred in useEffect Update rootStyle", error);
    }
  }, [bgColor, boderColor, FormWidth, verticalSpacing, horizontalSpacing]);

  const [initialContainer, setInitialContainer] = useState(false);

  function collectStylesofcontainer(node, result = []) {
    try {
      if (node.styleType && node.styleType === "Container") {
        result.push({
          styleid: node.styleid,
          styles: node.styles,
        });
      }

      if (node.control && node.control.length > 0) {
        node.control.forEach((child) =>
          collectStylesofcontainer(child, result)
        );
      }

      return result;
    } catch (error) {
      console.error("An error occurred in collectStylesofcontainer", error);
      setError("An error occurred in collectStylesofcontainer", error);
    }
  }

  // Assuming treeData is defined and contains your structure
  const stylesofcontainer = collectStylesofcontainer(
    treeData?.length > 0 && treeData[0].Source[0]
  );

  function applyFontColorToStylesconatiner(stylesArray, bgColor) {
    try {
      stylesArray.forEach((style) => {
        const styles = JSON.parse(style.styles);

        styles.backgroundColor = bgColor;

        updateCustomStyle(style.styleid, styles);
      });
    } catch (error) {
      console.error(
        "An error occurred in applyFontColorToStylesconatiner",
        error
      );
      setError("An error occurred in applyFontColorToStylesconatiner", error);
    }
  }

  useEffect(() => {
    if (initialContainer) {
      applyFontColorToStylesconatiner(stylesofcontainer, bgColor);
    }
  }, [bgColor, initialContainer]);

  useEffect(() => {
    try {
      const rootStyle =
        treeData?.length > 0 &&
        treeData[0]?.Source?.find((item) => item.type === "Root");

      let newStyles;
      try {
        newStyles = rootStyle?.styles ? JSON.parse(rootStyle.styles) : null;
        if (!newStyles || Object.keys(newStyles).length === 0) {
          throw new Error("newStyles is empty or undefined.");
        }
        setBgColor(newStyles.backgroundColor || "");
        setBoderColor(newStyles.borderColor || "");
        setFormWidth(newStyles.maxWidth || "");
        setVerticalSpacing(newStyles.paddingTop || "");
        setVerticalSpacing(newStyles.paddingBottom || "");
        setHorizontalSpacing(newStyles.paddingLeft || "");
        setHorizontalSpacing(newStyles.paddingRight || "");
      } catch (error) {
        if (treeData?.length > 0) {
          console.error("Error processing rootStyle.styles", error);
          setError("Error processing rootStyle.styles");
          localStorage.setItem("error", "Error processing rootStyle.styles");
          localStorage.setItem("navigate", "/Open");
        }
      }
    } catch (error) {
      console.error("An error occurred in useEffect rootStyle", error);
      setError("An error occurred in useEffect rootStyle", error);
    }
  }, [treeData]);

  // function collectStyleIdsWithStyles(node, result = []) {
  //   if (node.data && node.data !== "") {
  //     result.push({
  //       styleid: node.styleid,
  //       styles: node.styles,
  //     });
  //   }

  //   if (node.control && node.control.length > 0) {
  //     node.control.forEach((child) => collectStyleIdsWithStyles(child, result));
  //   }

  //   return result;
  // }

  function collectStyleIdsWithStyles(node, result = []) {
    try {
      if (
        node.controltype === "Label" ||
        node.controltype === "Date" ||
        node.controltype === "Header" ||
        node.controltype === "Iframe" ||
        node.controltype === "SubHeader" ||
        node.controltype === "List" ||
        node.controltype === "Table" ||
        node.controltype === "TextBox" ||
        node.controltype === "TextArea" ||
        node.controltype === "Checkbox" ||
        node.controltype === "Dropdown" ||
        node.controltype === "Radio" ||
        node.controltype === "Address" ||
        node.controltype === "ShortText" ||
        node.controltype === "LongText" ||
        node.controltype === "FullName" ||
        node.controltype === "MultipleChoice" ||
        node.controltype === "SingleChoice" ||
        node.controltype === "PhoneNumber" ||
        node.controltype === "Email" ||
        node.controltype === "Heading" ||
        node.controltype === "DateBlock"
      ) {
        result.push({
          styleid: node.styleid,
          styles: node.styles,
        });
      }

      if (node.control && node.control.length > 0) {
        node.control.forEach((child) =>
          collectStyleIdsWithStyles(child, result)
        );
      }

      return result;
    } catch (error) {
      console.error("An error occurred in collectStyleIdsWithStyles", error);
      setError("An error occurred in collectStyleIdsWithStyles", error);
    }
  }
  const styleIdsWithStyles = useMemo(() => {
    return collectStyleIdsWithStyles(
      treeData?.length > 0 && treeData[0].Source[0]
    );
  }, [treeData]);

  function handleSelectfontfamily(event) {
    const value = event.target.value;
    setFontFamily(value);
    setIsFontFamilyInput(true);
  }

  function applyFontColorToStyles(
    stylesArray,
    fontColor
    // FontSize,
    // fontFamily
  ) {
    try {
      stylesArray.forEach((style) => {
        const styles = JSON.parse(style.styles);

        styles.color = fontColor;
        // styles.fontSize = `${FontSize}px`;
        // styles.fontFamily = fontFamily;

        updateCustomStyle(style.styleid, styles);
      });
    } catch (error) {
      console.error("An error occurred in applyFontColorToStyles", error);
      setError("An error occurred in applyFontColorToStyles", error);
    }
  }

  function applyFontfamilyToStyles(
    stylesArray,

    fontFamily
  ) {
    try {
      stylesArray.forEach((style) => {
        const styles = JSON.parse(style.styles);

        // styles.color = fontColor;
        // styles.fontSize = `${FontSize}px`;
        styles.fontFamily = fontFamily;

        updateCustomStyle(style.styleid, styles);
      });
    } catch (error) {
      console.error("An error occurred in applyFontColorToStyles", error);
      setError("An error occurred in applyFontColorToStyles", error);
    }
  }

  function applyFontSizeToStyles(
    stylesArray,

    FontSize
  ) {
    try {
      stylesArray.forEach((style) => {
        const styles = JSON.parse(style.styles);

        // styles.color = fontColor;
        styles.fontSize = `${FontSize}px`;
        // styles.fontFamily = fontFamily;

        updateCustomStyle(style.styleid, styles);
      });
    } catch (error) {
      console.error("An error occurred in applyFontColorToStyles", error);
      setError("An error occurred in applyFontColorToStyles", error);
    }
  }

  useEffect(() => {
    try {
      // Only apply styles when FontSize changes from the input
      if (isFontSizeInput && initialFontSizeSet) {
        applyFontSizeToStyles(styleIdsWithStyles, FontSize);
        setIsFontSizeInput(false);
      }
    } catch (error) {
      console.error("An error occurred in useEffect fontSize", error);
      setError("An error occurred in useEffect fontSize", error);
    }
  }, [FontSize]);

  useEffect(() => {
    try {
      // Only apply styles when FontSize changes from the input
      if (isFontFamilyInput) {
        applyFontfamilyToStyles(styleIdsWithStyles, fontFamily);

        setIsFontFamilyInput(false); // Reset the flag after applying the change
      }
    } catch (error) {
      console.error("An error occurred in useEffect fontSize", error);
      setError("An error occurred in useEffect fontSize", error);
    }
  }, [fontFamily]);

  useEffect(() => {
    try {
      // Only apply styles when FontSize changes from the input
      if (isFontColorInput && initialColorSet) {
        applyFontColorToStyles(styleIdsWithStyles, fontColor);

        setIsFontColorInput(false); // Reset the flag after applying the change
      }
    } catch (error) {
      console.error("An error occurred in useEffect fontSize", error);
      setError("An error occurred in useEffect fontSize", error);
    }
  }, [fontColor]);

  function handleFontSizeChange(event) {
    const newFontSize = parseInt(event.target.value, 10);
    setFontSize(newFontSize);
    setIsFontSizeInput(true);
  }

  const handlefontColorChange = (event) => {
    const newFontSize = event.target.value;
    setFontColor(newFontSize);
    setIsFontColorInput(true);
  };

  useEffect(() => {
    try {
      if (!initialColorSet || !initialFontSizeSet) {
        if (styleIdsWithStyles.length > 0) {
          styleIdsWithStyles.forEach((styleObj) => {
            const parsedStyle = JSON.parse(styleObj.styles);

            if (!initialColorSet && parsedStyle.color) {
              // if (fontColor !== parsedStyle.color) {
              //   setFontColor(parsedStyle.color);
              // }
              setInitialColorSet(true);
            }

            if (!initialFontSizeSet && parsedStyle.fontSize) {
              const parsedFontSize = parseInt(parsedStyle.fontSize, 10);
              // if (FontSize !== parsedFontSize) {
              //   setFontSize(parsedFontSize);
              //   setIsFontSizeInput(true)
              // }
              setInitialFontSizeSet(true);
            }

            if (
              parsedStyle.fontFamily &&
              fontFamily !== parsedStyle.fontFamily
            ) {
              // setFontFamily(parsedStyle.fontFamily);
            }
          });
        }
      }
    } catch (error) {
      console.error("An error occurred in useEffect fontColor", error);
      setError("An error occurred in useEffect fontColor", error);
    }
  }, [
    styleIdsWithStyles,
    initialColorSet,
    initialFontSizeSet,
    fontColor,
    FontSize,
    fontFamily,
  ]);

  function collectStyleIdsWithStylesWidth(node, result = []) {
    try {
      if (
        node.controltype &&
        (node.controltype === "Label" ||
          node.controltype === "subHeader" ||
          node.controltype === "Header")
      ) {
        result.push({
          styleid: node.styleid,
          styles: node.styles,
        });
      }

      if (node.control && node.control.length > 0) {
        node.control.forEach((child) =>
          collectStyleIdsWithStylesWidth(child, result)
        );
      }

      return result;
    } catch (error) {
      console.error(
        "An error occurred in collectStyleIdsWithStylesWidth",
        error
      );
      setError("An error occurred in collectStyleIdsWithStylesWidth", error);
    }
  }
  const [marginTop, setMarginTop] = useState(0);
  const [paddingTop, setPaddingTop] = useState(0);
  const [isInitialMarginTopSet, setIsInitialMarginTopSet] = useState(false);
  const [isInitialPaddingTopSet, setIsInitialPaddingTopSet] = useState(false);

  function collectStyleIdsWithStylesMarginTop(node, result = []) {
    try {
      if (
        node.controltype &&
        (node.controltype === "Address" ||
          node.controltype === "ShortText" ||
          node.controltype === "LongText" ||
          node.controltype === "FullName" ||
          node.controltype === "DropDown" ||
          node.controltype === "MultipleChoice" ||
          node.controltype === "SingleChoice" ||
          node.controltype === "PhoneNumber" ||
          node.controltype === "Email" ||
          node.controltype === "Heading" ||
          node.controltype === "DateBlock" ||
          node.controltype === "RatingBlock" ||
          node.media === "AddTempContainer")
      ) {
        result.push({
          styleid: node.styleid,
          styles: node.styles,
        });
      }

      if (node.control && node.control.length > 0) {
        node.control.forEach((child) =>
          collectStyleIdsWithStylesMarginTop(child, result)
        );
      }

      return result;
    } catch (error) {
      console.error(
        "An error occurred in collectStyleIdsWithStylesMarginTop",
        error
      );
      setError(
        "An error occurred in collectStyleIdsWithStylesMarginTop",
        error
      );
    }
  }

  function collectStyleIdsWithStylesPaddingTop(node, result = []) {
    try {
      if (
        node.controltype &&
        (node.controltype === "Address" ||
          node.controltype === "ShortText" ||
          node.controltype === "LongText" ||
          node.controltype === "FullName" ||
          node.controltype === "DropDown" ||
          node.controltype === "MultipleChoice" ||
          node.controltype === "SingleChoice" ||
          node.controltype === "PhoneNumber" ||
          node.controltype === "Email" ||
          node.controltype === "Heading" ||
          node.controltype === "DateBlock" ||
          node.controltype === "RatingBlock" ||
          node.media === "AddTempContainer")
      ) {
        result.push({
          styleid: node.styleid,
          styles: node.styles,
        });
      }

      if (node.control && node.control.length > 0) {
        node.control.forEach((child) =>
          collectStyleIdsWithStylesPaddingTop(child, result)
        );
      }

      return result;
    } catch (error) {
      console.error(
        "An error occurred in collectStyleIdsWithStylesPaddingTop",
        error
      );
      setError(
        "An error occurred in collectStyleIdsWithStylesPaddingTop",
        error
      );
    }
  }

  const styleIdsWithStylesPaddingTop = collectStyleIdsWithStylesPaddingTop(
    treeData?.length > 0 && treeData[0].Source[0]
  );

  const styleIdsWithStylesWidth = collectStyleIdsWithStylesWidth(
    treeData?.length > 0 && treeData[0].Source[0]
  );

  const styleIdsWithStylesMarginTop = collectStyleIdsWithStylesMarginTop(
    treeData?.length > 0 && treeData[0].Source[0]
  );

  function applyMarginTopToStyles(stylesArray, marginTop) {
    try {
      stylesArray.forEach((style) => {
        const styles = JSON.parse(style.styles);

        styles.marginTop = `${marginTop}px`;

        updateCustomStyle(style.styleid, styles);
      });
    } catch (error) {
      console.error("An error occurred in applyMarginTopToStyles", error);
      setError("An error occurred in applyMarginTopToStyles", error);
    }
  }

  function applyPaddingTopToStyles(stylesArray, paddingTop) {
    try {
      stylesArray.forEach((style) => {
        const styles = JSON.parse(style.styles);

        styles.paddingTop = `${paddingTop}px`;
        styles.paddingBottom = `${paddingTop}px`;
        styles.paddingLeft = `${paddingTop}px`;
        styles.paddingRight = `${paddingTop}px`;
        updateCustomStyle(style.styleid, styles);
      });
    } catch (error) {
      console.error("An error occurred in applyPaddingTopToStyles", error);
      setError("An error occurred in applyPaddingTopToStyles", error);
    }
  }

  useEffect(() => {
    try {
      if (!isInitialPaddingTopSet && styleIdsWithStylesPaddingTop.length > 0) {
        const firstStyle = JSON.parse(styleIdsWithStylesPaddingTop[0].styles);

        if (firstStyle.paddingTop) {
          const paddingTopValue = parseInt(
            firstStyle.paddingTop.replace("px", ""),
            10
          );
          setPaddingTop(paddingTopValue);
          setIsInitialPaddingTopSet(true);
        }
      }
    } catch (error) {
      console.error(
        "An error occurred in useEffect styleIdsWithStylesPaddingTop",
        error
      );
      setError(
        "An error occurred inuseEffect styleIdsWithStylesPaddingTop",
        error
      );
    }
  }, [styleIdsWithStylesPaddingTop, isInitialPaddingTopSet]);

  useEffect(() => {
    if (isInitialPaddingTopSet) {
      applyPaddingTopToStyles(styleIdsWithStylesPaddingTop, paddingTop);
    }
  }, [paddingTop, isInitialPaddingTopSet]);

  useEffect(() => {
    try {
      if (!isInitialMarginTopSet && styleIdsWithStylesMarginTop.length > 0) {
        const firstStyle = JSON.parse(styleIdsWithStylesMarginTop[0].styles);

        if (firstStyle.marginTop) {
          const marginTopValue = parseInt(
            firstStyle.marginTop.replace("px", ""),
            10
          );
          setMarginTop(marginTopValue);
          setIsInitialMarginTopSet(true);
        }
      }
    } catch (error) {
      console.error(
        "An error occurred in useEffect styleIdsWithStylesMarginTop",
        error
      );
      setError(
        "An error occurred inuseEffect styleIdsWithStylesMarginTop",
        error
      );
    }
  }, [styleIdsWithStylesMarginTop, isInitialMarginTopSet]);

  useEffect(() => {
    if (isInitialMarginTopSet) {
      applyMarginTopToStyles(styleIdsWithStylesMarginTop, marginTop);
    }
  }, [marginTop, isInitialMarginTopSet]);

  function collectStyleIdsWithStylesforInput(node, result = []) {
    try {
      if (
        node.htmlTag &&
        (node.htmlTag === "input" || node.htmlTag === "select")
      ) {
        result.push({
          styleid: node.styleid,
          styles: node.styles,
        });
      }

      if (node.control && node.control.length > 0) {
        node.control.forEach((child) =>
          collectStyleIdsWithStylesforInput(child, result)
        );
      }

      return result;
    } catch (error) {
      console.error(
        "An error occurred in collectStyleIdsWithStylesforInput",
        error
      );
      setError("An error occurred collectStyleIdsWithStylesforInput", error);
    }
  }

  const styleIdsWithStylesforInput = collectStyleIdsWithStylesforInput(
    treeData?.length > 0 && treeData[0].Source[0]
  );
  // -------------------- fixed height start
  const [isFullHeight, setIsFullHeight] = useState(false);

  const checkFullHeight = () => {
    setIsFullHeight(window.innerHeight > 500);
  };
  useEffect(() => {
    checkFullHeight();
    window.addEventListener("resize", checkFullHeight);
    return () => window.removeEventListener("resize", checkFullHeight);
  }, []);

  // ----------------fixed height end

  function applyInputColorToStylesforInput(stylesArray, inputColor) {
    try {
      stylesArray.forEach((style) => {
        const styles = JSON.parse(style.styles);
        styles.backgroundColor = inputColor;
        updateCustomStyle(style.styleid, styles);
      });
    } catch (error) {
      console.error(
        "An error occurred in applyInputColorToStylesforInput",
        error
      );
      setError("An error occurred applyInputColorToStylesforInput", error);
    }
  }
  useEffect(() => {
    if (window.innerWidth < 1001) {
      handleMenuButtonClick();
    }
  }, []);
  useEffect(() => {
    if (initialInputColorSet) {
      applyInputColorToStylesforInput(styleIdsWithStylesforInput, inputColor);
    }
  }, [inputColor, initialInputColorSet]);

  useEffect(() => {
    try {
      if (!initialInputColorSet && styleIdsWithStylesforInput.length > 0) {
        const firstStyle = JSON.parse(styleIdsWithStylesforInput[0].styles);

        if (firstStyle.backgroundColor) {
          setInputColor(firstStyle.backgroundColor);
        }

        setInitialInputColorSet(true);
      }
    } catch (error) {
      console.error(
        "An error occurred in useEffect styleIdsWithStylesforInput",
        error
      );
      setError("An error occurred useEffect styleIdsWithStylesforInput", error);
    }
  }, [styleIdsWithStylesforInput, initialInputColorSet]);

  const [alignMent, setAlignMent] = useState("");

  useEffect(() => {
    try {
      if (treeData?.length > 0 && treeData[0]?.Source?.length > 0) {
        const rootElement = treeData[0].Source[0];

        try {
          const styles = JSON.parse(rootElement.styles);
          const treeAlgnment = styles.alignMent;

          setAlignMent(treeAlgnment);
        } catch (error) {
          console.error("Failed to parse styles:", error);
          setError("Failed to parse styles:", error);
        }
      } else {
        console.warn("Source array is empty or undefined.");
      }
    } catch (error) {
      console.error("An error occurred in useEffect treeData", error);
      setError("An error occurred useEffect treeData", error);
    }
  }, [treeData]);

  function collectStyleIdsWithStylesforContainer(node, result = []) {
    try {
      if (node.htmlTag) {
        if (
          node.tableHeader === "MainContainer" &&
          node.media !== "HeadingContainer"
        ) {
          result.push({
            styleid: node.styleid,
            styles: node.styles,
            type: "MainContainer",
          });
        }

        const positions = [
          "AddressHeader",
          "DropDownLabel",
          "EmailLabel",
          "FullName",
          "LongTextLabel",
          "ShortTextLabel",
          "Checkboxlabel",
          "PhoneNumberLabel",
          "MultipleChoiceLabel",
          "SingleChoiceLabel",
          "DateLabel",
          "RatingLabel",
          "GeneralLabel",
          "ImageLabel",
          "SwitchLabel",
          "IframeLabel",
        ];

        if (positions.includes(node.checkposition)) {
          result.push({
            styleid: node.styleid,
            styles: node.styles,
            type: node.checkposition,
          });
        }
      }

      if (node.control && node.control.length > 0) {
        node.control.forEach((child) =>
          collectStyleIdsWithStylesforContainer(child, result)
        );
      }

      return result;
    } catch (error) {
      console.error(
        "An error occurred in collectStyleIdsWithStylesforContainer",
        error
      );
      setError(
        "An error occurred collectStyleIdsWithStylesforContainer",
        error
      );
    }
  }

  const styleIdsWithStylesforContainer = collectStyleIdsWithStylesforContainer(
    treeData?.length > 0 && treeData[0].Source[0]
  );

  function applyInputColorToStylesforContainer(stylesArray, alignMent) {
    try {
      stylesArray.forEach((style) => {
        const styles = JSON.parse(style.styles);

        if (style.type === "MainContainer") {
          switch (alignMent) {
            case "left":
            case "right":
              styles.flexDirection = "row";
              break;
            case "column":
              styles.flexDirection = "column";
              break;
            default:
              break;
          }
        }

        if (
          [
            "AddressHeader",
            "ShortTextLabel",
            "LongTextLabel",
            "checkboxlabel",
            "DropDownLabel",
            "EmailLabel",
            "FullName",
            "PhoneNumberLabel",
            "SingleChoiceLabel",
            "MultipleChoiceLabel",
            "DateLabel",
            "RatingLabel",
            "GeneralLabel",
            "ImageLabel",
            "SwitchLabel",
            "IframeLabel",
          ].includes(style.type)
        ) {
          styles.textAlign = alignMent === "right" ? "right" : "left";
        }

        updateCustomStyle(style.styleid, styles);
      });
    } catch (error) {
      console.error(
        "An error occurred in applyInputColorToStylesforContainer",
        error
      );
      setError("An error occurred applyInputColorToStylesforContainer", error);
    }
  }

  function collectStyleIdsWithStylesWidthRatingInput(node, result = []) {
    try {
      if (!node) {
        return result;
      }

      // Check if the node has the media property and it matches "RatingInput"
      if (node.media && node.media === "RatingInput") {
        result.push({
          styleid: node.styleid,
          styles: node.styles,
        });
      }

      if (node.control && node.control.length > 0) {
        node.control.forEach((child) =>
          collectStyleIdsWithStylesWidthRatingInput(child, result)
        );
      }

      return result;
    } catch (error) {
      console.error(
        "An error occurred in collectStyleIdsWithStylesWidthRatingInput",
        error
      );
      setError(
        "An error occurred in collectStyleIdsWithStylesWidthRatingInput",
        error
      );
    }
  }

  function applyflexDireRating(stylesArray) {
    try {
      stylesArray.forEach((style) => {
        const styles = JSON.parse(style.styles);

        // Update flexDirection to 'row'
        styles.flexDirection = "row-reverse";

        updateCustomStyle(style.styleid, styles);
      });
    } catch (error) {
      console.error("An error occurred in applyflexDireRating", error);
      setError("An error occurred in applyflexDireRating", error);
    }
  }

  const handleSelectChange1 = (event) => {
    try {
      const value = event.target.value;

      if (value === "column") {
        const RatingstyleIds = collectStyleIdsWithStylesWidthRatingInput(
          treeData?.length > 0 && treeData[0].Source[0]
        );

        applyflexDireRating(RatingstyleIds);
      }

      setAlignMent(value);
      const updatedTreeData = [...treeData];
      const rootElement = updatedTreeData[0]?.Source[0];

      try {
        let styles = JSON.parse(rootElement.styles);
        styles.alignMent = value;
        rootElement.styles = JSON.stringify(styles);

        setTreeData(updatedTreeData);
      } catch (error) {
        console.error("Failed to update alignment:", error);
        setError("Failed to update alignment:", error);
      }
    } catch (error) {
      console.error("An error occurred in handleSelectChange1", error);
      setError("An error occurred in handleSelectChange1", error);
    }
  };

  useEffect(() => {
    applyInputColorToStylesforContainer(
      styleIdsWithStylesforContainer,
      alignMent
    );
  }, [alignMent]);

  const [selectedColor, setSelectedColor] = useState("#1976D2");
  const [selectedgradient, setselectedGradient] = useState("#1976D2");

  const handleButtonClick = (color) => {
    setSelectedColor(color);
  };

  const handleButtonClickGradient = (color) => {
    setselectedGradient(color);
  };

  function collectStyleIdsWithStylesforButton(node, result = []) {
    try {
      if (node.htmlTag && node.controltype === "Button") {
        result.push({
          styleid: node.styleid,
          styles: node.styles,
        });
      }

      if (node.control && node.control.length > 0) {
        node.control.forEach((child) =>
          collectStyleIdsWithStylesforButton(child, result)
        );
      }

      return result;
    } catch (error) {
      console.error(
        "An error occurred in collectStyleIdsWithStylesforButton",
        error
      );
      setError(
        "An error occurred in collectStyleIdsWithStylesforButton",
        error
      );
    }
  }

  const styleIdsWithStylesforButton = collectStyleIdsWithStylesforButton(
    treeData?.length > 0 && treeData[0].Source[0]
  );

  function applyInputColorToStylesforButton(stylesArray, selectedColor) {
    try {
      stylesArray.forEach((style) => {
        const styles = JSON.parse(style.styles);
        styles.backgroundColor = selectedColor;
        delete styles.background;
        updateCustomStyle(style.styleid, styles);
      });
    } catch (error) {
      console.error(
        "An error occurred in applyInputColorToStylesforButton",
        error
      );
      setError("An error occurred in applyInputColorToStylesforButton", error);
    }
  }

  useEffect(() => {
    applyInputColorToStylesforButton(
      styleIdsWithStylesforButton,
      selectedColor
    );
  }, [selectedColor]);

  function collectStyleIdsWithStylesforgradient(node, result = []) {
    try {
      if (node.htmlTag && node.controltype === "Button") {
        result.push({
          styleid: node.styleid,
          styles: node.styles,
        });
      }

      if (node.control && node.control.length > 0) {
        node.control.forEach((child) =>
          collectStyleIdsWithStylesforgradient(child, result)
        );
      }

      return result;
    } catch (error) {
      console.error(
        "An error occurred in collectStyleIdsWithStylesforgradient",
        error
      );
      setError(
        "An error occurred in collectStyleIdsWithStylesforgradient",
        error
      );
    }
  }

  const styleIdsWithStylesforgradient = collectStyleIdsWithStylesforgradient(
    treeData?.length > 0 && treeData[0].Source[0]
  );

  function applyInputColorToStylesforgradient(stylesArray, selectedgradient) {
    try {
      stylesArray.forEach((style) => {
        const styles = JSON.parse(style.styles);
        styles.backgroundColor = "";
        styles.background = selectedgradient;
        updateCustomStyle(style.styleid, styles);
      });
    } catch (error) {
      console.error(
        "An error occurred in applyInputColorToStylesforgradient",
        error
      );
      setError(
        "An error occurred in applyInputColorToStylesforgradient",
        error
      );
    }
  }

  useEffect(() => {
    applyInputColorToStylesforgradient(
      styleIdsWithStylesforgradient,
      selectedgradient
    );
  }, [selectedgradient]);

  const hasButtonControl = (data) => {
    try {
      const findButton = (controls) => {
        for (const control of controls) {
          if (control.controltype === "Button") return true;
          if (control.control && findButton(control.control)) return true;
        }
        return false;
      };

      return data?.some((item) => findButton(item.Source || []));
    } catch (error) {
      console.error("An error occurred in hasButtonControl", error);
      setError("An error occurred in hasButtonControl", error);
    }
  };

  const showButtonStyles = hasButtonControl(treeData);

  const handleondragStart = () => {
    try {
      const firstItem = treeData[0].Source[0];
      setSelectedNode(firstItem);
      setSelectedItem(firstItem);
      setSelectedControl(firstItem);
      setEditedName(firstItem.name);
      setSelectedItemID(firstItem.id);

      const nodeListfocused = document.querySelectorAll(".focusingClass");
      nodeListfocused.forEach((node) => {
        if (node.id !== firstItem.id) {
          node.classList.remove("focusingClass");
        }
      });

      const firstItemElement = document.getElementById(firstItem.id);
      if (firstItemElement) {
        firstItemElement.classList.add("focusingClass");
      }
    } catch (error) {
      console.error("An error occurred in handleondragStart", error);
      setError("An error occurred in handleondragStart", error);
    }
  };

  const handleOnDragEnd = (result) => {
    try {
      if (!result.destination) return;

      const { source, destination } = result;
      const updatedTreeData = [...treeData];

      if (
        selectedNode &&
        selectedNode.control &&
        selectedNode.control.length > 0
      ) {
        const sourceIndex = source.index;
        const destinationIndex = destination.index;

        if (sourceIndex < selectedNode.control.length) {
          const [movedItem] = selectedNode.control.splice(sourceIndex, 1);
          selectedNode.control.splice(destinationIndex, 0, movedItem);

          setTreeData(updatedTreeData);
        } else {
          console.error("Source index is out of bounds");
        }
      } else {
        console.error("Selected node or its controls not found");
      }
    } catch (error) {
      console.error("An error occurred in handleOnDragEnd", error);
      setError("An error occurred in handleOnDragEnd", error);
    }
  };

  const Images = [
    {
      Image1:
        "https://files.jotform.com/jufs/beril/form_files/galaxy-bg-image.6405a140a1b097.18087512.png?md5=UODyKOGQCLZs_lPkqUV3_Q&expires=1726133382",
      Image2:
        "https://files.jotform.com/jufs/beril/form_files/ocean-bg.6408a42b157931.17796133.png?md5=7KtjN3OUY8lTzQrOYGtW_A&expires=1726133517",
      Image3:
        "https://files.jotform.com/jufs/beril/form_files/morgane-le-breton-Ym4my-Xj8EY-unsplash%201.6390950a64d8e1.88003745.png?md5=Pz6c4G4BNnA3yLUf4hrBpA&expires=1726133982",
    },
  ];

  const handleImageClick1 = (imageUrl) => {
    const rootStyle =
      treeData?.length > 0 &&
      treeData[0].Source.find((item) => item.type === "Root");

    const newStyles = JSON.parse(rootStyle && rootStyle.styles);

    updateCustomStyle("rootStyleId", {
      ...newStyles,
      backgroundImage: `url(${imageUrl})`,
    });
  };

  const handleRemoveBackgroundImage = (event) => {
    try {
      event.stopPropagation();
      const rootStyle =
        treeData?.length > 0 &&
        treeData[0].Source.find((item) => item.type === "Root");

      const newStyles = JSON.parse(rootStyle && rootStyle.styles);

      const newStyle = {
        ...newStyles,
        backgroundImage: null,
      };
      updateCustomStyle("rootStyleId", newStyle);
    } catch (error) {
      console.error("An error occurred in handleRemoveBackgroundImage", error);
      setError("An error occurred in handleRemoveBackgroundImage", error);
    }
  };

  const handleRemoveParentBackgroundImage = (event) => {
    try {
      // Find the "Root" item in the treeData
      event.stopPropagation();
      const rootItem =
        treeData?.length > 0 &&
        treeData[0].Source.find((item) => item.type === "Root");

      if (!rootItem) return;

      // Parse the parentstyles JSON string
      const parentStyles = JSON.parse(rootItem.parentstyles || "{}");

      // Update the backgroundImage property
      const updatedParentStyles = {
        ...parentStyles,
        backgroundImage: null,
      };

      updateCustomStyle("rootStyleId", updatedParentStyles, true);

      // Update the rootItem's parentstyles property
      rootItem.parentstyles = JSON.stringify(updatedParentStyles);

      // Update the treeData or call any update function if needed
      updateTreeData([...treeData]);
    } catch (error) {
      console.error(
        "An error occurred in handleRemoveParentBackgroundImage",
        error
      );
      setError("An error occurred in handleRemoveParentBackgroundImage", error);
    }
  };

  const handleImageUpload = (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const newBackgroundImage = reader.result;
          const newStyles = JSON.parse(rootStyle && rootStyle.styles);

          const newStyle = {
            ...newStyles,
            backgroundImage: `url(${newBackgroundImage})`,
          };
          updateCustomStyle("rootStyleId", newStyle);
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      console.error("An error occurred in handleImageUpload", error);
      setError("An error occurred in handleImageUpload", error);
    }
  };

  const handleImageUploadparent = (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const newBackgroundImage = reader.result;

          // Parse current parentstyles if they exist
          const currentParentStyles = JSON.parse(
            rootStyle?.parentstyles || "{}"
          );
          // Update parentstyles with the new background image
          const updatedParentStyles = {
            ...currentParentStyles,
            backgroundImage: `url(${newBackgroundImage})`,
          };
          // Update the tree data and apply the style change to parentstyles
          updateCustomStyle("rootStyleId", updatedParentStyles, true);
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      console.error("An error occurred in handleImageUploadparent", error);
      setError("An error occurred in handleImageUploadparent", error);
    }
  };

  const [themearidSource, setThemeAridSource] = useState([]);
  const [ReadArrayAllData, setReadAllData] = useState([]);

  const ReadArrayAllHtmloutput = async () => {
    try {
      const isTemplate = await ReadArrayAll(accessKey);
      setReadAllData(isTemplate);
      const templateObject = isTemplate.filter((item) => item.IsTemplate === 4);
      const arIDs = templateObject.map((item) => item.ArID);
      const aridThemeSourceArray = [];

      const htmlOutputs = [];
      for (const arID of arIDs) {
        const htmlOutput = await ReadArray(arID, accessKey);

        let modifiedHtml = htmlOutput[0]?.HtmlOutput || "";
        const ThemeSource = htmlOutput[0]?.Source;

        console.log("ThemeNum:", ThemeSource[0]?.ThemeNum);

        aridThemeSourceArray.push({ arID, ThemeSource });

        setThemeAridSource(aridThemeSourceArray);

        const parser = new DOMParser();
        const doc = parser.parseFromString(modifiedHtml, "text/html");

        const elements = doc.querySelectorAll(
          '[styleid="agrfbTemp2Footer"], [styleid="agrfbTemp2Header"]'
        );
        elements.forEach((element) => {
          element.style.height = "20px";
        });

        const headerElements = doc.querySelectorAll(
          '[styleid="agrfbTemp1Head"], [styleid="agrfbTemp1Footer"]'
        );
        headerElements.forEach((headerElement) => {
          headerElement.style.minHeight = "20px";
          headerElement.style.height = "20px";
        });

        const labelElements = doc.querySelectorAll("label[styleid]");
        labelElements.forEach((label) => {
          label.style.fontSize = "5px";
          label.style.marginTop = "0px";
          label.style.marginRight = "0px";
          label.style.marginBottom = "0px";
          label.style.marginLeft = "4px";
          label.style.paddingTop = "0px";
          label.style.paddingRight = "0px";
          label.style.paddingBottom = "0px";
          label.style.paddingLeft = "0px";
          label.style.width = "90%";
        });

        if (ThemeSource[0]?.ThemeNum == 2) {
          const elementsForChild = doc.querySelectorAll('[class="childdiv"]');
          elementsForChild.forEach((element) => {
            element.style.height = "100px";
            element.style.width = "100px";
            element.style.removeProperty("min-height");
            element.style.removeProperty("max-width");
            element.style.removeProperty("min-width");
          });
        } else {
          const elementsForChild = doc.querySelectorAll('[class="childdiv"]');
          elementsForChild.forEach((element) => {
            element.style.height = "100px";
            element.style.width = "100px";
            element.style.removeProperty("min-height");
            element.style.removeProperty("min-width");
            element.style.removeProperty("max-width");
          });
        }

        if (ThemeSource[0]?.ThemeNum == 2) {
          const elementsformain = doc.querySelectorAll('[class="maindiv"]');
          elementsformain.forEach((element) => {
            element.style.height = "auto";
            element.style.width = "auto";
            element.style.removeProperty("min-height");
          });
        } else {
          const elementsformain = doc.querySelectorAll('[class="maindiv"]');
          elementsformain.forEach((element) => {
            element.style.height = "auto";
            element.style.width = "auto";
            element.style.removeProperty("min-height");
          });
        }

        modifiedHtml = doc.documentElement.outerHTML;

        htmlOutputs.push({
          arID,
          html: modifiedHtml,
        });
      }

      setThemehtmlouput(htmlOutputs);
      setThemeArray(templateObject);
    } catch (error) {
      console.error("Error during request ReadArrayAllHtmloutput:", error);
      // setError("An error occurred in ReadArrayAllHtmloutput");
      // localStorage.setItem(
      //   "error",
      //   "An error occurred in ReadArrayAllHtmloutput"
      // );
      // localStorage.setItem("navigate", "/Open");
    }
  };

  useEffect(() => {
    try {
      if (!authData || !authData.accessKey) {
        navigate("/");
        sessionStorage.clear();
        return;
      }
      ReadArrayAllHtmloutput();
    } catch (error) {
      console.error("An error occurred in useEffect accessKey", error);
      setError("An error occurred in useEffect accessKey", error);
    }
  }, [accessKey]);

  const handleSelect = async (arid, event) => {
    event.preventDefault();
    const selectedAridObject = themearidSource.find(
      (item) => item.arID === arid
    );

    console.log("ThemeNum:", selectedAridObject.ThemeSource[0].ThemeNum);
    try {
      if (selectedAridObject) {
        const rootStyle = selectedAridObject.ThemeSource.find(
          (item) => item.type === "Root"
        );

        const styles = rootStyle ? JSON.parse(rootStyle.styles) : null;
        const pstyles = rootStyle ? JSON.parse(rootStyle.parentstyles) : null;

        const treeDatarootparentStyle = treeData[0].Source.find(
          (item) => item.type === "Root"
        );

        const treeDataparentStyles = treeDatarootparentStyle
          ? JSON.parse(treeDatarootparentStyle.parentstyles)
          : null;

        updateCustomStyle(
          "rootStyleId",
          {
            ...treeDataparentStyles,
            backgroundImage: pstyles?.backgroundImage || "",
          },
          true
        );

        const treeDatarootStyle = treeData[0].Source.find(
          (item) => item.type === "Root"
        );

        const treeDataStyles = treeDatarootStyle
          ? JSON.parse(treeDatarootStyle.styles)
          : null;

        updateCustomStyle("rootStyleId", {
          ...treeDataStyles,
          backgroundImage: styles?.backgroundImage || "",
          backgroundColor: styles?.backgroundColor || "",
          borderColor: styles?.borderColor || "",
        });

        setBoderColor(styles?.borderColor);
        setBgColor(styles?.backgroundColor);

        // Clear previous templateArid
        treeData[0].Source[0].templateArid = null;
        treeData[0].Source[0].templateArid =
          selectedAridObject.ThemeSource[0].ThemeNum;

        function controlExists(option) {
          return treeData[0].Source[0].control.some((c) => c.option === option);
        }

        function findHeaderContainers(controls) {
          controls.forEach((control) => {
            if (selectedAridObject.ThemeSource[0].ThemeNum == 2) {
              // Remove old containers for Template1
              const removeContainers = (controls) => {
                for (let i = controls.length - 1; i >= 0; i--) {
                  const control = controls[i];
                  if (
                    [
                      "Temp2Footer",
                      "Temp2Right",
                      "Temp2Left",
                      "Temp2Header",
                    ].includes(control.option)
                  ) {
                    controls.splice(i, 1);
                  }
                  if (control.control && control.control.length > 0) {
                    removeContainers(control.control);
                  }
                }
              };

              // Remove containers from the main structure
              if (treeData?.length > 0 && treeData[0].Source.length > 0) {
                removeContainers(treeData[0].Source[0].control);
              }
              setTreeData([...treeData]); // Update state

              // Add Template1 containers if they don't exist
              if (
                control.option === "Template1HeaderContainer" &&
                !controlExists("Template1HeaderContainer")
              ) {
                control.id = "0.1H";
                treeData[0].Source[0].control.unshift(control);
              }

              if (
                control.option === "Template1FooterContainer" &&
                !controlExists("Template1FooterContainer")
              ) {
                control.id = "0.1F";
                treeData[0].Source[0].control.push(control);
              }

              if (
                control.option === "Template1LeftContainer" &&
                !controlExists("Template1LeftContainer")
              ) {
                control.id = "0.2L";
                const parsedStyles = JSON.parse(control.styles);
                parsedStyles.width = `${FormWidth * 0.2}px`;
                control.styles = JSON.stringify(parsedStyles);

                const headerIndex = treeData[0].Source[0].control.findIndex(
                  (c) => c.option === "Template1HeaderContainer"
                );

                if (headerIndex !== -1) {
                  treeData[0].Source[0].control.splice(
                    headerIndex + 1,
                    0,
                    control
                  );
                } else {
                  treeData[0].Source[0].control.push(control);
                }
              }
            }

            if (selectedAridObject.ThemeSource[0].ThemeNum == 1) {
              // Similar logic for Template2 with duplicate control prevention
              const removeContainers = (controls) => {
                for (let i = controls.length - 1; i >= 0; i--) {
                  const control = controls[i];
                  if (
                    [
                      "Template1HeaderContainer",
                      "Template1LeftContainer",
                      "Template1RightContainer",
                      "Template1FooterContainer",
                    ].includes(control.option)
                  ) {
                    controls.splice(i, 1);
                  }
                  if (control.control && control.control.length > 0) {
                    removeContainers(control.control);
                  }
                }
              };

              if (treeData?.length > 0 && treeData[0].Source.length > 0) {
                removeContainers(treeData[0].Source[0].control);
              }
              setTreeData([...treeData]);

              if (
                control.option === "Temp2Footer" &&
                !controlExists("Temp2Footer")
              ) {
                control.id = "0.1F";
                control.htmlTag = "footer";
                control.controlType = "FooterContainer";
                control.styleType = "FooterContainer";
                control.controlChange = "FooterContainer";
                control.type = "FooterContainer";
                treeData[0].Source[0].control.push(control);
              }

              if (
                control.option === "Temp2Left" &&
                !controlExists("Temp2Left")
              ) {
                control.id = "0.2L";
                const parsedStyles = JSON.parse(control.styles);
                parsedStyles.width = `${FormWidth * 0.2}px`;
                control.styles = JSON.stringify(parsedStyles);

                const headerIndex = treeData[0].Source[0].control.findIndex(
                  (c) => c.option === "Temp2Header"
                );

                if (headerIndex !== -1) {
                  treeData[0].Source[0].control.splice(
                    headerIndex + 1,
                    0,
                    control
                  );
                } else {
                  treeData[0].Source[0].control.push(control);
                }
              }

              if (
                control.option === "Temp2Right" &&
                !controlExists("Temp2Right")
              ) {
                control.id = "0.2R";
                const parsedStyles = JSON.parse(control.styles);
                parsedStyles.width = `${FormWidth * 0.2}px`;
                control.styles = JSON.stringify(parsedStyles);

                const footerIndex = treeData[0].Source[0].control.findIndex(
                  (c) => c.option === "Temp2Footer"
                );

                if (footerIndex !== -1) {
                  treeData[0].Source[0].control.splice(footerIndex, 0, control);
                } else {
                  treeData[0].Source[0].control.push(control);
                }
              }

              if (
                control.option === "Temp2Header" &&
                !controlExists("Temp2Header")
              ) {
                control.id = "0.1H";
                control.htmlTag = "header";
                control.controlType = "HeaderContainer";
                control.styleType = "HeaderContainer";
                control.controlChange = "HeaderContainer";
                control.type = "HeaderContainer";

                // const parsedStyles = JSON.parse(control.styles);
                // parsedStyles.maxWidth = `${FormWidth}px`;
                // control.styles = JSON.stringify(parsedStyles);

                treeData[0].Source[0].control.unshift(control);
              }
            }

            if (control.control && control.control.length > 0) {
              findHeaderContainers(control.control);
            }
          });
        }

        if (
          selectedAridObject.ThemeSource.length > 0 &&
          selectedAridObject.ThemeSource
        ) {
          findHeaderContainers(selectedAridObject.ThemeSource);
        } else {
          console.log("No data found in Source.");
        }
      }
    } catch (error) {
      console.error("Error in handleSelect:", error);
      setError("An error occurred in control selection ");
      localStorage.setItem("error", "An error occurred in control selection");
      localStorage.setItem("navigate", "/Open");
    }
  };

  const handleRemove = () => {
    try {
      if (treeData?.length > 0 && treeData[0].Source.length > 0) {
        treeData[0].Source[0].templateArid = "";
      }

      const treeDatarootStyle = treeData[0].Source.find(
        (item) => item.type === "Root"
      );
      const treeDataparentStyles = treeDatarootStyle
        ? JSON.parse(treeDatarootStyle.parentstyles)
        : null;

      updateCustomStyle(
        "rootStyleId",
        {
          ...treeDataparentStyles,
          backgroundImage: null,
          // backgroundColor : null,
          // borderColor : null,
        },
        true
      );

      const treeDataStyles = treeDatarootStyle
        ? JSON.parse(treeDatarootStyle.styles)
        : null;

      updateCustomStyle("rootStyleId", {
        ...treeDataStyles,
        backgroundImage: null,
        backgroundColor: "#ffffff",
        borderColor: "#ffffff",
      });

      setBoderColor("#ffffff");
      setBgColor("#ffffff");

      const removeContainers = (controls) => {
        for (let i = controls.length - 1; i >= 0; i--) {
          const control = controls[i];

          if (
            control.option === "Template1HeaderContainer" ||
            control.option === "Template1LeftContainer" ||
            control.option === "Template1RightContainer" ||
            control.option === "Template1FooterContainer" ||
            control.option === "Temp2Footer" ||
            control.option === "Temp2Right" ||
            control.option === "Temp2Left" ||
            control.option === "Temp2Header"
          ) {
            controls.splice(i, 1);
          }

          if (control.control && control.control.length > 0) {
            removeContainers(control.control);
          }
        }
      };
      if (treeData?.length > 0 && treeData[0].Source.length > 0) {
        removeContainers(treeData[0].Source[0].control);
      }

      setTreeData([...treeData]);
    } catch (error) {
      console.error("An error occurred in handleRemove", error);
      setError("An error occurred in handleRemove", error);
    }
  };

  const nodeListfocused = document.querySelectorAll(".focusingClass")[0];

  const handlePreViewBack = () => {
    try {
      const firstItem = selectedNode.id || "";
      setSelectedNode(firstItem);
      setSelectedItem(firstItem);
      setSelectedItemID(firstItem.id);
      setSelectedControl(firstItem.type);
      const firstItem1 = treeData[0].Source[0];
      setSelectedNode(firstItem1);
      setSelectedItem(firstItem1);
      setSelectedControl(firstItem1);
      setEditedName(firstItem1.name);
      setSelectedItemID(firstItem1.id);
      setControlsVisible(true);
      if (nodeListfocused) {
        nodeListfocused.classList.remove("focusingClass");
      }
    } catch (error) {
      console.error("An error occurred in handlePreViewBack", error);
      setError("An error occurred in handlePreViewBack", error);
    }
  };

  const location = useLocation();
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    if (!location?.state) {
      navigate("/", { replace: true });
      sessionStorage.clear();
    } else {
      setIsAuthorized(true);
    }
  }, [location, navigate]);

  const stylesFromLoc = location?.state?.defaultStyle || [];

  const fetchData = useCallback(async () => {
    try {
      const fetchedData = stylesFromLoc;
      setReadArrayStyles(fetchedData);

      const styledDataWithIds = fetchedData.map((style, index) => ({
        id: index + 1,
        ...style,
      }));

      setDefaultStyle(styledDataWithIds);
    } catch (error) {
      console.error("Error during request:", error);
      setError("An error occurred in fetchData");
      localStorage.setItem("error", "An error occurred in fetchData");
      localStorage.setItem("navigate", "/Open");
    }
  }, [accessKey]);

  useEffect(() => {
    // Only fetch data if the user is authorized
    if (isAuthorized) {
      fetchData();
    }
  }, [isAuthorized, fetchData]);

  const arrayName =
    location?.state?.ArrayName || location?.state?.arrayName || "";

  const description =
    location?.state?.Description || location?.state?.description || "";

  const category = location?.state?.Category || location?.state?.category || "";

  const dataSourceArID =
    location?.state?.DataSourceArID || location?.state?.dataSourceArID || "";

  const SourceOFDataSource =
    location?.state?.SourceOFDataSource ||
    location?.state?.SourceOFDataSource ||
    "";
  const isInactive =
    location?.state?.isInactive || location?.state?.isInactive || "";

  // useEffect(()=>{
  //   if (isInactive == 1){
  //     console.error("Form is Inactive");
  //     setError("Form is Inactive");
  //     localStorage.setItem("error", "Form is Inactive");
  //     localStorage.setItem("navigate", "/Open");
  //   }

  // },[isInactive])

  async function onSaveClick(showToast) {
    try {
      const htmlFileContent = generateHTMLFile(htmlContent);

      const response = await WriteArray(
        treeData,
        arId,
        arrayName,
        description,
        category,
        htmlFileContent,
        dataSourceArID,
        accessKey,
        userName,
        isInactive
      );

      if (response.ok) {
        console.log("Data saved successfully");

        if (showToast) toast.success("Changes saved successfully");
      } else {
        console.error("Failed to save data to the backend");
        setError("Failed to save data to the backend");

        if (showToast) toast.error("Failed to save data to the backend");
      }
      // }
    } catch (error) {
      console.error("Error while saving data:", error);
      setError("Error while saving data:", error);

      if (showToast) toast.error("Error while saving data");
    }
  }

  async function onSaveClick1(showToast) {
    try {
      const htmlFileContent = generateHTMLFile(htmlContent);
      let isValid = true;

      const response = await WriteArray1(
        treeData,
        arId,
        arrayName,
        description,
        category,
        htmlFileContent,
        dataSourceArID,
        accessKey,
        userName,
        isInactive
      );

      if (response.ok) {
        console.log("Data saved successfully");

        if (showToast) toast.success("Changes saved successfully");
      } else {
        console.error("Failed to save data to the backend");
        setError("Failed to save data to the backend");

        if (showToast) toast.error("Failed to save data to the backend");
      }
      // }
    } catch (error) {
      console.error("Error while saving data:", error);
      setError("Error while saving data:", error);

      if (showToast) toast.error("Error while saving data:");
    }
  }

  async function onSaveClickTheme(showToast) {
    try {
      const htmlFileContent = generateHTMLFile(htmlContent);
      let isValid = true;

      const response = await WriteArrayTheme(
        treeData,
        arId,
        arrayName,
        description,
        category,
        htmlFileContent,
        dataSourceArID,
        accessKey,
        userName,
        isInactive
      );

      if (response.ok) {
        console.log("Data saved successfully");

        if (showToast) toast.success("Changes saved successfully");
      } else {
        console.error("Failed to save data to the backend");
        setError("Failed to save data to the backend");

        if (showToast) toast.error("Failed to save data to the backend");
      }
    } catch (error) {
      console.error("Error while saving data:", error);
      setError("Error while saving data:", error);

      if (showToast) toast.error("Error while saving data");
    }
  }

  function collectControlTypes(data, optionValue) {
    try {
      let controlTypes = [];

      function traverse(node) {
        if (!node) return; // Safety check to avoid accessing properties of undefined/null

        // Check if the current node's option matches
        if (node.option === optionValue) {
          // Start collecting all "controltype" and their corresponding "id" recursively
          function collectAllControlTypes(controlNode) {
            if (!controlNode) return; // Safety check
            if (controlNode.controltype) {
              controlTypes.push({
                controltype: controlNode.controltype,
                id: controlNode.id,
              });
            }
            if (controlNode.control && controlNode.control.length > 0) {
              controlNode.control.forEach(collectAllControlTypes);
            }
          }
          if (node.control && node.control.length > 0) {
            node.control.forEach(collectAllControlTypes);
          }
        }

        if (node.control && node.control.length > 0) {
          node.control.forEach(traverse);
        }
      }

      traverse(data);

      return controlTypes;
    } catch (error) {
      console.error("An error occurred in collectControlTypes", error);
      setError("An error occurred in collectControlTypes", error);
    }
  }

  const handleFormWidthChange = debounce((value) => {
    if (value > 0) {
      setFormWidth(value);
    }
  }, 100);

  const findData = (treeData, selectedItemID, checkposition) => {
    for (let item of treeData) {
      for (let source of item.Source) {
        let foundData = findInControls(
          source.control,
          selectedItemID,
          checkposition
        );
        const matchingNode = findNodeById(
          treeData[0]?.Source[0]?.control,
          selectedItem.id
        );

        if (foundData === "Middle Name" && matchingNode) {
          // setEditedDataMiddleTextboxPresent(true);
          setCheckboxCheckedMiddleName(true);
        }

        if (foundData) return foundData;
      }
    }
    return "";
  };

  const findInControls = (controls, selectedItemID, checkposition) => {
    for (let control of controls) {
      if (control.id === selectedItemID) {
        const foundControl = findControl(control.control, checkposition);
        if (foundControl) {
          return foundControl.data;
        }
      }
      if (control.control && control.control.length > 0) {
        let foundData = findInControls(
          control.control,
          selectedItemID,
          checkposition
        );
        if (foundData) return foundData;
      }
    }
    return null;
  };

  const findControl = (controls, checkposition) => {
    for (let control of controls) {
      if (control.checkposition === checkposition) {
        return control;
      }
      if (control.control && control.control.length > 0) {
        let foundControl = findControl(control.control, checkposition);
        if (foundControl) return foundControl;
      }
    }
    return null;
  };

  const findData1 = (treeData, selectedItemID, checkposition) => {
    for (let item of treeData) {
      for (let source of item.Source) {
        let foundData = findInControls1(
          source.control,
          selectedItemID,
          checkposition
        );
        const matchingNode = findNodeById(
          treeData[0]?.Source[0]?.control,
          selectedItem.id
        );

        if (foundData === "Middle Name" && matchingNode) {
          // setEditedDataMiddleTextboxPresent(true);
          setCheckboxCheckedMiddleName(true);
        }

        if (foundData) return foundData;
      }
    }
    return "";
  };

  const findInControls1 = (controls, selectedItemID, checkposition) => {
    try {
      for (let control of controls) {
        if (control.id === selectedItemID) {
          const foundControl = findControl(control.control, checkposition);
          if (foundControl) {
            return foundControl.sourceData;
          }
        }
        if (control.control && control.control.length > 0) {
          let foundData = findInControls1(
            control.control,
            selectedItemID,
            checkposition
          );
          if (foundData) return foundData;
        }
      }
      return null;
    } catch (error) {
      console.error("Error processing controls:", error);
      setError("Error processing controls:");
      localStorage.setItem("error", "Error processing controls:");
      localStorage.setItem("navigate", "/Open");
      return null; // Return null in case of an error
    }
  };

  useEffect(() => {
    try {
      if (selectedItem?.controltype === "FullName") {
        const firstName = findData(
          treeData,
          selectedItemID,
          "FirstNameTextBox"
        );
        const lastName = findData(treeData, selectedItemID, "LastNameTextBox");
        const prefix = findData(treeData, selectedItemID, "PrefixTextBox");
        const middleName = findData(
          treeData,
          selectedItemID,
          "MiddleNameTextBox"
        );

        const middleName1 = findData1(
          treeData,
          selectedItemID,
          "MiddleNameTextBox"
        );

        const prefix1 = findData1(treeData, selectedItemID, "PrefixTextBox");

        setEditedDataFirstNameTextbox(firstName);
        setEditedDataLastNameTextbox(lastName);
        setEditedDataPrefixTextbox(prefix);
        setEditedDataMiddleTextbox(middleName);

        setCheckboxCheckedPrefix(prefix1 !== "");
        setCheckboxCheckedMiddleName(middleName1 !== "");

        const updatedTreeData = JSON.parse(JSON.stringify(treeData));

        if (JSON.stringify(updatedTreeData) !== JSON.stringify(treeData)) {
          setTreeData(updatedTreeData);
        }
      }
    } catch (error) {
      console.error("Error processing FullName data:", error);
      setError("Error processing FullName data:");
      localStorage.setItem("error", "Error processing FullName data:");
      localStorage.setItem("navigate", "/Open");
    }
  }, [treeData, selectedItemID, selectedItem]);

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div className="containertopsection">
          {controlsVisible && (
            <FormTopSection
              searchQuery={searchQuery}
              handleSearch={handleSearch}
              handleControls={handleControls}
              handleMenuButtonClick={handleMenuButtonClick}
              handleCloseLeftMenu={handleCloseLeftMenu}
              handleTabshwButtonClick={handleTabshwButtonClick}
              onSaveClick={onSaveClick}
              handleSaveToFile={handleSaveToFile}
              handleGoBack={handleGoBack}
              openConfirmation={openConfirmation}
              handleCloseConfirmation={handleCloseConfirmation}
              handleConfirmGoBackWithSave={handleConfirmGoBackWithSave}
              treeData={treeData}
              renderControl={renderControl}
              arId={arId}
              setControlsVisible={setControlsVisible}
              controlsVisible={controlsVisible}
              onSaveClick1={onSaveClick1}
              templateSource={templateSource}
              tempNum={tempNum}
              dataSourceArID={dataSourceArID}
              category={category}
              selectedNode={selectedNode}
              setSelectedNode={setSelectedNode}
              setSelectedItemID={setSelectedItemID}
              setSelectedItem={setSelectedItem}
              htmlFileContent={generateHTMLFile(htmlContent)}
              selectedItem={selectedItem}
              setEditedName={setEditedName}
              setSelectedControl={setSelectedControl}
              showTheme={showTheme}
              onSaveClickTheme={onSaveClickTheme}
              handleGoBackTheme={handleGoBackTheme}
              authData={authData}
              preJSON={preJSON}
              stylesFromLoc={stylesFromLoc}
              ReadArrayAllData={ReadArrayAllData}
              error={error}
              setError={setError}
            />
          )}

          <div
            // ref={bgImgRef}
            className="bg-img container-scrol-icon"
            style={{
              backgroundImage: parentstyleObject.backgroundImage,
              backgroundColor: boderColor,
              // height: "100%",
              // overflow: isScrolled ? "auto" : "hidden",
              // height: dynamicHeight,
              // overflowY: dynamicHeight === "auto" ? "scroll" : "hidden",
              ...(templateArid == "" && { overflow: "auto" }),
              ...(templateArid == 1 && { overflow: "auto" }),
              ...(templateArid == 2 && { overflow: "auto" }),
              ...(category === "Json Form" && { overflow: "auto" }),
            }}
          >
            {showTheme && (
              <div
                className="prnt-header"
                style={{
                  // display: "flex",
                  // flexDirection: "column",
                  zIndex: "3",
                }}
              >
                {/* {treeData.map((item) => {
                  return item.Source[0].control
                    .filter(
                      (control) => control.option === "Template1HeaderContainer"
                    )
                    .map((headerControl) => renderControl(headerControl));
                })} */}
                {treeData?.length > 0 &&
                  treeData.map((item, itemIndex) => {
                    return item.Source[0].control
                      .filter(
                        (control) =>
                          control.option === "Template1HeaderContainer"
                      )
                      .map((headerControl, headerControlIndex) => {
                        try {
                          const isHeader =
                            headerControl.option === "Template1HeaderContainer";
                          const renderedControl = renderControl(headerControl);

                          if (isHeader) {
                            const iconContainer = document.getElementById(
                              `iconContainer-${headerControl.id}`
                            );

                            if (iconContainer) {
                              const element = document.getElementById(
                                headerControl.id
                              );
                              if (element) {
                                const rect = element.getBoundingClientRect();
                                iconContainer.style.top = `${rect.bottom + window.scrollY + 10}px`;
                                iconContainer.style.left = `${rect.left + window.scrollX + rect.width / 2 - iconContainer.offsetWidth / 2}px`;
                                iconContainer.style.zIndex = "4";
                                iconContainer.style.marginTop = "-9px";
                              }
                            }
                          }

                          return renderedControl;
                        } catch (headerControlError) {
                          console.error(
                            `Error rendering header control at item ${itemIndex}, headerControl ${headerControlIndex}:`,
                            headerControlError
                          );
                          return null; // Return null in case of an error
                        }
                      });
                  })}
              </div>
            )}

            {loading ? (
              <CircularProgress
                style={{
                  position: "relative",
                  minHeight: "100vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
            ) : (
              <div
                className={
                  "container-top" +
                  (controlsVisible && !showmenu ? " lftpanelopened" : "") +
                  (showmenu ? " leftpanel" : "")
                }
                style={{
                  minHeight: isFullHeight ? "100vh" : "auto",
                  height: isFullHeight ? "auto" : "auto",
                  overflowY: "auto",
                }}
              >
                {controlsVisible && (
                  <div
                    id="sidebar"
                    className={
                      "sidebar" +
                      (showmenu ? " show" : "") +
                      (showmenudsk ? " showdsk" : "")
                    }
                  >
                    {controlsVisible && (
                      <div className="sidebardata">
                        <DialogComponent
                          open={openDialog}
                          onClose={() => setOpenDialog(false)}
                          handleAddControl={handleAddControl}
                          searchQuery={searchQuery}
                          filterTreeData={filterTreeData}
                          setContainerIcon={setContainerIcon}
                          ContainerIcon={ContainerIcon}
                          setInputClicked={setInputClicked}
                          InputClicked={InputClicked}
                          setDisplayClicked={setDisplayClicked}
                          displayClicked={displayClicked}
                          setIconClicked={setIconClicked}
                          IconsClicked={IconsClicked}
                          DataClicked={DataClicked}
                          setDataClicked={setDataClicked}
                          onDragStart={onDragStart}
                          onDragEnd={onDragEnd}
                          showTheme={showTheme}
                          authData={authData}
                          selectedItem={selectedItem}
                        />
                      </div>
                    )}

                    {controlsVisible && (
                      <div className="treeitem">
                        {treeData?.length > 0 && (
                          <DragDropContext>
                            <Droppable droppableId="tree" type="CONTROL">
                              {(provided) => {
                                try {
                                  return (
                                    <div
                                      {...provided.droppableProps}
                                      ref={provided.innerRef}
                                    >
                                      {treeData[0].Source &&
                                      treeData[0].Source.length > 0 &&
                                      treeData[0].Source &&
                                      filterTreeData(
                                        treeData[0].Source,
                                        searchQuery
                                      ).length > 0 ? (
                                        filterTreeData(
                                          treeData[0].Source,
                                          searchQuery
                                        ).map((item, index) => {
                                          const uniqueKey = `${item.type}-${index}`;
                                          try {
                                            return (
                                              <TreeItem
                                                key={uniqueKey}
                                                item={item}
                                                addItem={handleAddItem}
                                                selectedNode={selectedNode}
                                                setSelectedNode={
                                                  setSelectedNode
                                                }
                                                index={index}
                                                treeData={treeData}
                                                setTreeData={setTreeData}
                                                handleSelectItem={
                                                  handleSelectItem
                                                }
                                                setIconsforFocus={
                                                  setIconsforFocus
                                                }
                                                selectedItem={selectedItem}
                                                setEditedName={setEditedName}
                                                setSelectedItem={
                                                  setSelectedItem
                                                }
                                                setSelectedControl={
                                                  setSelectedControl
                                                }
                                                setSelectedItemID={
                                                  setSelectedItemID
                                                }
                                                setSelectedOptioncheck={
                                                  setSelectedOptioncheck
                                                }
                                                setSelectedOptionSwitchcheck={
                                                  setSelectedOptionSwitchcheck
                                                }
                                                setSelectedPositioncheck={
                                                  setSelectedPositioncheck
                                                }
                                                editedName={editedName}
                                                setEditedData={setEditedData}
                                                setSelectedOption={
                                                  setSelectedOption
                                                }
                                                settabSelect={settabSelect}
                                                setTableHeader={setTableHeader}
                                                setSelectedControlType={
                                                  setSelectedControlType
                                                }
                                                setIsOptionSelected={
                                                  setIsOptionSelected
                                                }
                                                isOptionSelected={
                                                  isOptionSelected
                                                }
                                                settableProp={settableProp}
                                                authData={authData}
                                              />
                                            );
                                          } catch (itemError) {
                                            console.error(
                                              `Error rendering item at index ${index}:`,
                                              itemError
                                            );
                                            setError(
                                              `Error rendering item at index ${index}:`
                                            );
                                            localStorage.setItem(
                                              "error",
                                              `Error rendering item at index ${index}:`
                                            );
                                            localStorage.setItem(
                                              "navigate",
                                              "/Open"
                                            );
                                            return null; // Return null in case of an error
                                          }
                                        })
                                      ) : (
                                        <p
                                          style={{
                                            textAlignLast: "center",
                                            fontStyle: "italic",
                                          }}
                                        >
                                          No Results
                                        </p>
                                      )}
                                      {provided.placeholder}
                                    </div>
                                  );
                                } catch (error) {
                                  console.error(
                                    "Error in DragDropContext:",
                                    error
                                  );
                                  setError("Error in DragDropContext:");
                                  localStorage.setItem(
                                    "error",
                                    "Error in DragDropContext:"
                                  );
                                  localStorage.setItem("navigate", "/Open");
                                  return null; // Return null in case of an error
                                }
                              }}
                            </Droppable>
                          </DragDropContext>
                        )}
                      </div>
                    )}
                  </div>
                )}

                {controlsVisible && (
                  <div
                    className={
                      "tgl-menu-panel" + (showmenudsk ? " reverticn" : "")
                    }
                    onClick={handleMenushwDskButtonClick}
                  >
                    <Tooltip
                      id="tooltipstyleLft"
                      arrow
                      title={
                        <span className="tooltipstyleLft">
                          Show/Hide left panel
                        </span>
                      }
                      placement="right-end"
                    >
                      {Theme.ChevronRight}
                    </Tooltip>
                  </div>
                )}

                {showTheme && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {treeData?.length > 0 &&
                      treeData.map((item, index) => {
                        try {
                          return item.Source[0].control
                            .filter(
                              (control) =>
                                control.option === "Template1LeftContainer" ||
                                control.option === "Temp2Left"
                            )
                            .map((headerControl, headerIndex) => {
                              try {
                                return renderControl(headerControl);
                              } catch (headerError) {
                                console.error(
                                  `Error rendering control at item ${index}, headerControl ${headerIndex}:`,
                                  headerError
                                );
                                setError(
                                  `Error rendering control at item ${index}, headerControl ${headerIndex}: ${headerError}`
                                );
                                localStorage.setItem(
                                  "error",
                                  `Error rendering control at item ${index}, headerControl ${headerIndex}: ${headerError}`
                                );
                                localStorage.setItem("navigate", "/Open");
                                return null; // Return null in case of an error
                              }
                            });
                        } catch (itemError) {
                          console.error(
                            `Error processing item at index ${index}:`,
                            itemError
                          );
                          setError(`Error processing control at item ${index}`);
                          localStorage.setItem(
                            "error",
                            `Error processing control at item ${index}`
                          );
                          localStorage.setItem("navigate", "/Open");
                          return null; // Return null in case of an error
                        }
                      })}
                  </div>
                )}

                <div
                  className="middle-section container-scrol-icon"
                  // ref={middleSectionRef}
                  id="0"
                  styleid="rootStyleId"
                  style={{
                    width: styleObject.width,
                    maxWidth: `${styleObject.maxWidth}px`,
                    // minWidth: `${styleObject.minWidth}px`,
                    backgroundColor: styleObject.backgroundColor,
                    margin: "0px auto",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    boxShadow: "0 0 8px #00000040",
                    overflow: styleObject.overflow,
                    ...(templateArid != 1),
                  }}
                >
                  <div className="temp-sec">
                    {showTheme && (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {treeData?.length > 0 &&
                          treeData.map((item, index) => {
                            try {
                              return item.Source[0].control
                                .filter(
                                  (control) => control.option === "Temp2Header"
                                )
                                .map((headerControl, headerIndex) => {
                                  try {
                                    return renderControl(headerControl);
                                  } catch (headerError) {
                                    console.error(
                                      `Error rendering control at item ${index}, headerControl ${headerIndex}:`,
                                      headerError
                                    );
                                    return null; // Return null in case of an error
                                  }
                                });
                            } catch (itemError) {
                              console.error(
                                `Error processing item at index ${index}:`,
                                itemError
                              );
                              setError(
                                `Error processing item at index ${index}:`
                              );
                              localStorage.setItem(
                                "error",
                                `Error processing item at index ${index}:`
                              );
                              localStorage.setItem("navigate", "/Open");
                              return null; // Return null in case of an error
                            }
                          })}
                      </div>
                    )}

                    <div
                      className="frst-prnt-cont container-scrol-icon"
                      styleid="rootStyleId"
                      style={{
                        backgroundImage: styleObject.backgroundImage,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        paddingLeft: styleObject.paddingLeft,
                        paddingBottom: styleObject.paddingBottom,
                        paddingRight: styleObject.paddingRight,
                        paddingTop: styleObject.paddingTop,
                        overflow: styleObject.overflow,
                        minHeight: isFullHeight ? "100vh" : "auto",
                        height: isFullHeight ? "auto" : "auto",
                      }}
                    >
                      {treeData?.length > 0 &&
                        treeData
                          ?.filter(
                            (control) =>
                              !showTheme ||
                              (control.controltype !== "HeaderContainer" &&
                                control.option !== "Template1HeaderContainer" &&
                                control.option !== "Template1FooterContainer" &&
                                control.option !== "Template1LeftContainer" &&
                                control.option !== "Template1RightContainer" &&
                                control.option !== "Temp2Right" &&
                                control.option !== "Temp2Left" &&
                                control.option !== "Temp2Footer" &&
                                control.option !== "Temp2Header")
                          )
                          .map((item) => {
                            if (!controlsVisible) {
                              const filterByFieldTypes = (data, fieldTypes) => {
                                try {
                                  return Array.isArray(data)
                                    ? data.filter((field) =>
                                        fieldTypes.includes(field.fieldtype)
                                      )
                                    : [];
                                } catch (error) {
                                  console.error(
                                    "Error filtering by field types:",
                                    error
                                  );
                                  setError("Error filtering by field types:");
                                  localStorage.setItem(
                                    "error",
                                    "Error filtering by field types:"
                                  );
                                  localStorage.setItem("navigate", "/Open");
                                  return [];
                                }
                              };

                              const filteredFields = filterByFieldTypes(
                                fieldData,
                                [
                                  "text",
                                  "datetime",
                                  "date",
                                  "time",
                                  "Image",
                                  "Iframe",
                                  "boolean",
                                  "number",
                                  "TextArea",
                                ]
                              );

                              const filterTable = (() => {
                                try {
                                  return Array.isArray(fieldData)
                                    ? fieldData.filter(
                                        (field) =>
                                          field.elementtype === "array" &&
                                          field.type === "table"
                                      )
                                    : [];
                                } catch (error) {
                                  console.error(
                                    "Error filtering table data:",
                                    error
                                  );
                                  setError("Error filtering table data:");
                                  localStorage.setItem(
                                    "error",
                                    "Error filtering table data:"
                                  );
                                  localStorage.setItem("navigate", "/Open");
                                  return [];
                                }
                              })();
                              

                              const processControl = (control) => {
                                const hasCurlyBrackets = (str) =>
                                  /{.*?}/.test(str);

                                const getCleanedData = (data) => {
                                  try {
                                    return data
                                      .replace(/{|}/g, "")
                                      .toLowerCase();
                                  } catch (error) {
                                    console.error(
                                      "Error cleaning data:",
                                      error
                                    );
                                    setError("Error cleaning data:");
                                    localStorage.setItem(
                                      "error",
                                      "Error cleaning data:"
                                    );
                                    localStorage.setItem("navigate", "/Open");
                                    return data;
                                  }
                                };

                                const replaceDataIfNeeded = (
                                  data,
                                  matchingField
                                ) => {
                                  try {
                                    return hasCurlyBrackets(data) &&
                                      matchingField
                                      ? matchingField.fieldvalue
                                      : data;
                                  } catch (error) {
                                    console.error(
                                      "Error replacing data:",
                                      error
                                    );
                                    setError("Error replacing data:");
                                    localStorage.setItem(
                                      "error",
                                      "Error replacing data:"
                                    );
                                    localStorage.setItem("navigate", "/Open");
                                    return data;
                                  }
                                };
                                try {
                                  if (
                                    control.type === "TextBox" ||
                                    control.type === "TextArea" ||
                                    control.type === "Header" ||
                                    control.type === "SubHeader" ||
                                    control.type === "Checkbox" ||
                                    control.type === "Radio" ||
                                    control.htmlTag === "td" ||
                                    control.type === "Label"
                                  ) {
                                    const cleanedData = getCleanedData(
                                      control.data
                                    );
                                    let matchingField;

                                    if (control.type !== "Table") {
                                      matchingField = filteredFields.find(
                                        (field) =>
                                          field.fieldname.toLowerCase() ===
                                          cleanedData
                                      );
                                    } else {
                                    
                                      matchingField = filterTable.reduce(
                                        (found, table) => {
                                          if (found) return found;
                                          return table.elements.find(
                                            (field) =>
                                              field.fieldname.toLowerCase() ===
                                              cleanedData
                                          );
                                        },
                                        null
                                      );
                                    }

                                    return {
                                      ...control,
                                      data: replaceDataIfNeeded(
                                        control.data,
                                        matchingField
                                      ),
                                    };
                                  } else if (control.type === "Date") {
                                    const cleanedData = getCleanedData(
                                      control.inputText
                                    );
                                    let matchingField;

                                    if (control.type !== "Table") {
                                      matchingField = filteredFields.find(
                                        (field) =>
                                          field.fieldname.toLowerCase() ===
                                          cleanedData
                                      );
                                    } else {
                                      
                                      matchingField = filterTable.reduce(
                                        (found, table) => {
                                          if (found) return found;
                                          return table.elements.find(
                                            (field) =>
                                              field.fieldname.toLowerCase() ===
                                              cleanedData
                                          );
                                        },
                                        null
                                      );
                                    }

                                    return {
                                      ...control,
                                      inputText: replaceDataIfNeeded(
                                        control.inputText,
                                        matchingField
                                      ),
                                    };
                                  } else if (
                                    control.type === "Image" ||
                                    control.type === "Iframe" ||
                                    control.type === "Switch"
                                  ) {
                                    const cleanedData = getCleanedData(
                                      control.imageText
                                    );
                                    let matchingField;

                                    if (control.type !== "Table") {
                                      matchingField = filteredFields.find(
                                        (field) =>
                                          field.fieldname.toLowerCase() ===
                                          cleanedData
                                      );
                                    } else {
                                     
                                      matchingField = filterTable.reduce(
                                        (found, table) => {
                                          if (found) return found;
                                          return table.elements.find(
                                            (field) =>
                                              field.fieldname.toLowerCase() ===
                                              cleanedData
                                          );
                                        },
                                        null
                                      );
                                    }

                                    return {
                                      ...control,
                                      imageText: replaceDataIfNeeded(
                                        control.imageText,
                                        matchingField
                                      ),
                                    };
                                  } else {
                                    return control;
                                  }
                                } catch (error) {
                                  console.error(
                                    "Error processing control:",
                                    error
                                  );
                                  setError("Error processing control:");
                                  localStorage.setItem(
                                    "error",
                                    "Error processing control:"
                                  );
                                  localStorage.setItem("navigate", "/Open");
                                  return control;
                                }
                              };

                              const processControls = (controls) => {
                                try {
                                  return controls
                                    .filter(
                                      (control) =>
                                        !showTheme ||
                                        (control.controltype !==
                                          "HeaderContainer" &&
                                          control.option !==
                                            "Template1HeaderContainer" &&
                                          control.option !==
                                            "Template1FooterContainer" &&
                                          control.option !==
                                            "Template1LeftContainer" &&
                                          control.option !== "Temp2Footer" &&
                                          control.option !== "Temp2Header" &&
                                          control.option !== "Temp2Right" &&
                                          control.option !== "Temp2Left" &&
                                          control.option !==
                                            "Template1RightContainer")
                                    )
                                    .map((control) => {
                                      if (
                                        control.control &&
                                        control.control.length > 0
                                      ) {
                                        const processedNestedControls =
                                          processControls(control.control);

                                        return {
                                          ...control,
                                          control: processedNestedControls
                                            .filter(
                                              (control) =>
                                                control.option !==
                                                  "Template1HeaderContainer" &&
                                                control.option !==
                                                  "Template1FooterContainer" &&
                                                control.option !==
                                                  "Temp2Footer" &&
                                                control.option !==
                                                  "Temp2Header" &&
                                                control.option !==
                                                  "Template1LeftContainer" &&
                                                control.option !==
                                                  "Temp2Right" &&
                                                control.option !==
                                                  "Temp2Left" &&
                                                control.option !==
                                                  "Template1RightContainer"
                                            )
                                            .map((nestedControl) => {
                                              if (
                                                nestedControl.type === "Date" &&
                                                nestedControl.inputText
                                              ) {
                                                const dateParts =
                                                  nestedControl.inputText.split(
                                                    "/"
                                                  );
                                                if (dateParts.length === 3) {
                                                  const month =
                                                    dateParts[0].padStart(
                                                      2,
                                                      "0"
                                                    );
                                                  const day =
                                                    dateParts[1].padStart(
                                                      2,
                                                      "0"
                                                    );
                                                  const year = dateParts[2];
                                                  const formattedDate = `${year}-${month}-${day}`;
                                                  nestedControl.data =
                                                    formattedDate;
                                                  nestedControl.inputType =
                                                    "date";
                                                  nestedControl.inputText = "";
                                                } else {
                                                  console.error(
                                                    "Invalid date format:",
                                                    nestedControl.inputText
                                                  );
                                                }
                                              } else if (
                                                nestedControl.type ===
                                                  "Image" &&
                                                nestedControl.imageText
                                              ) {
                                                nestedControl.media =
                                                  nestedControl.imageText;
                                                nestedControl.imageText = "";
                                              } else if (
                                                nestedControl.type ===
                                                  "Iframe" &&
                                                nestedControl.imageText
                                              ) {
                                                nestedControl.data =
                                                  nestedControl.imageText;
                                                nestedControl.imageText = "";
                                              } else if (
                                                nestedControl.type ===
                                                  "Checkbox" &&
                                                (nestedControl.data ===
                                                  "true" ||
                                                  nestedControl.data ===
                                                    "false" ||
                                                  nestedControl.data === 0 ||
                                                  nestedControl.data === 1)
                                              ) {
                                                nestedControl.check =
                                                  nestedControl.data ===
                                                    "true" ||
                                                  nestedControl.data === 1;
                                              } else if (
                                                nestedControl.type ===
                                                  "Radio" &&
                                                (nestedControl.data ===
                                                  "true" ||
                                                  nestedControl.data ===
                                                    "false" ||
                                                  nestedControl.data === 0 ||
                                                  nestedControl.data === 1)
                                              ) {
                                                nestedControl.check =
                                                  nestedControl.data ===
                                                    "true" ||
                                                  nestedControl.data === 1;
                                              } else if (
                                                nestedControl.type ===
                                                  "Switch" &&
                                                nestedControl.imageText
                                              ) {
                                                nestedControl.switchcheck =
                                                  nestedControl.imageText ===
                                                    "true" ||
                                                  nestedControl.data === 1;

                                                nestedControl.imageText = "";
                                              } else if (
                                                nestedControl.type ===
                                                  "Radio" &&
                                                nestedControl.imageText
                                              ) {
                                                nestedControl.check =
                                                  nestedControl.imageText ===
                                                    "true" ||
                                                  nestedControl.data === 1;

                                                nestedControl.imageText = "";
                                              }
                                              return nestedControl;
                                            }),
                                        };
                                      } else {
                                        return processControl(control);
                                      }
                                    });
                                } catch (error) {
                                  console.error(
                                    "Error processing controls:",
                                    error
                                  );
                                  setError("Error processing control:");
                                  localStorage.setItem(
                                    "error",
                                    "Error processing control:"
                                  );
                                  localStorage.setItem("navigate", "/Open");
                                  return controls;
                                }
                              };

                              const processedControls = item.Source.filter(
                                (control) =>
                                  !showTheme ||
                                  (control.controltype !== "HeaderContainer" &&
                                    control.option !==
                                      "Template1HeaderContainer" &&
                                    control.option !==
                                      "Template1FooterContainer" &&
                                    control.option !== "Temp2Footer" &&
                                    control.option !== "Temp2Header" &&
                                    control.option !==
                                      "Template1LeftContainer" &&
                                    control.option !== "Temp2Right" &&
                                    control.option !== "Temp2Left" &&
                                    control.option !==
                                      "Template1RightContainer")
                              ).map((source) => {
                                return {
                                  ...source,
                                  control: processControls(source.control),
                                };
                              });
                              return processedControls.map((source) =>
                                source.control.map((control) => {
                                  try {
                                    if (
                                      control.type === "Date" &&
                                      control.inputText
                                    ) {
                                      const dateParts =
                                        control.inputText.split("/");
                                      if (dateParts.length === 3) {
                                        const month = dateParts[0].padStart(
                                          2,
                                          "0"
                                        );
                                        const day = dateParts[1].padStart(
                                          2,
                                          "0"
                                        );
                                        const year = dateParts[2];
                                        const formattedDate = `${year}-${month}-${day}`;
                                        control.data = formattedDate;
                                        control.inputType = "date";
                                        control.inputText = "";
                                      } else {
                                        console.error(
                                          "Invalid date format:",
                                          control.inputText
                                        );
                                      }
                                    } else if (
                                      control.type === "Image" &&
                                      control.imageText
                                    ) {
                                      control.media = control.imageText;
                                      control.imageText = "";
                                    } else if (
                                      control.type === "Iframe" &&
                                      control.imageText
                                    ) {
                                      control.data = control.imageText;
                                      control.imageText = "";
                                    } else if (
                                      control.type === "Switch" &&
                                      control.imageText
                                    ) {
                                      control.switchcheck =
                                        control.imageText === "true" ||
                                        control.imageText === 1;
                                      control.imageText = "";
                                    } else if (
                                      control.type === "Checkbox" &&
                                      (control.data === "true" ||
                                        control.data === "false" ||
                                        control.data === 0 ||
                                        control.data === 1)
                                    ) {
                                      control.check =
                                        control.data === "true" ||
                                        control.data === 1;
                                    } else if (
                                      control.type === "Radio" &&
                                      control.data
                                    ) {
                                      control.check =
                                        control.data === "true" ||
                                        control.data === 1;
                                    }
                                    return renderControl(control);
                                  } catch (error) {
                                    console.error(
                                      "Error processing control:",
                                      error
                                    );
                                    setError("Error processing control:");
                                    localStorage.setItem(
                                      "error",
                                      "Error processing control:"
                                    );
                                    localStorage.setItem("navigate", "/Open");
                                    return control;
                                  }
                                })
                              );
                            } else {
                              return (
                                <DragDropContext
                                  onDragStart={handleondragStart}
                                  onDragEnd={handleOnDragEnd}
                                >
                                  <Droppable droppableId="controls">
                                    {(provided) => (
                                      <div
                                        className="parentOfControls"
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                      >
                                        {(() => {
                                          try {
                                            return item.Source[0].control
                                              .filter(
                                                (control) =>
                                                  !showTheme ||
                                                  (control.controltype !==
                                                    "HeaderContainer" &&
                                                    control.option !==
                                                      "Template1HeaderContainer" &&
                                                    control.option !==
                                                      "Template1FooterContainer" &&
                                                    control.option !==
                                                      "Temp2Footer" &&
                                                    control.option !==
                                                      "Temp2Header" &&
                                                    control.option !==
                                                      "Template1LeftContainer" &&
                                                    control.option !==
                                                      "Temp2Right" &&
                                                    control.option !==
                                                      "Temp2Left" &&
                                                    control.option !==
                                                      "Template1RightContainer")
                                              )
                                              .map((control, index) => (
                                                <Draggable
                                                  key={control.id || index}
                                                  draggableId={`draggable-${control.id || index}`}
                                                  index={index}
                                                >
                                                  {(provided) => (
                                                    <div
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                      style={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        padding: "2px",
                                                        ...provided
                                                          .draggableProps.style,
                                                      }}
                                                    >
                                                      {renderControl(control)}
                                                    </div>
                                                  )}
                                                </Draggable>
                                              ));
                                          } catch (error) {
                                            console.error(
                                              "Error rendering draggable controls:",
                                              error
                                            );
                                            setError(
                                              "Error rendering draggable controls:"
                                            );
                                            localStorage.setItem(
                                              "error",
                                              "Error rendering draggable controls:"
                                            );
                                            localStorage.setItem(
                                              "navigate",
                                              "/Open"
                                            );
                                            return null; // Safeguard in case of errors
                                          }
                                        })()}
                                        {provided.placeholder}
                                      </div>
                                    )}
                                  </Droppable>
                                </DragDropContext>
                              );
                            }
                          })}
                    </div>

                    {showTheme && (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        {treeData?.length > 0 &&
                          treeData.map((item) => {
                            try {
                              return item.Source[0].control
                                .filter(
                                  (control) => control.option === "Temp2Footer"
                                )
                                .map((headerControl) =>
                                  renderControl(headerControl)
                                );
                            } catch (error) {
                              console.error(
                                "Error processing treeData footer control:",
                                error
                              );
                              setError(
                                "Error processing treeData footer control:"
                              );
                              localStorage.setItem(
                                "error",
                                "Error processing treeData footer control:"
                              );
                              localStorage.setItem("navigate", "/Open");
                              return null;
                            }
                          })}
                      </div>
                    )}
                  </div>
                </div>

                {showTheme && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {treeData?.length > 0 &&
                      treeData.map((item) => {
                        try {
                          return item.Source[0].control
                            .filter(
                              (control) =>
                                control.option === "Template1RightContainer" ||
                                control.option === "Temp2Right"
                            )
                            .map((headerControl) =>
                              renderControl(headerControl)
                            );
                        } catch (error) {
                          console.error(
                            "Error processing treeData control:",
                            error
                          );
                          setError("Error processing treeData control:");
                          localStorage.setItem(
                            "error",
                            "Error processing treeData control:"
                          );
                          localStorage.setItem("navigate", "/Open");
                          return null; // Return null if there's an error to prevent breaking the rendering
                        }
                      })}
                  </div>
                )}

                {controlsVisible && (
                  <div
                    className={
                      "tgl-right-panel " + (showtabdsk ? "reverticn" : "")
                    }
                    onClick={handleTabshwDskButtonClick}
                  >
                    <Tooltip
                      id="tooltipstyleRht"
                      arrow
                      title={
                        <span className="tooltipstyleRht">
                          Show/Hide right panel
                        </span>
                      }
                      placement="left-end"
                    >
                      {Theme.ChevronRight}
                    </Tooltip>
                  </div>
                )}

                {controlsVisible && (
                  <div
                    id="stylesection"
                    className={
                      ""
                      /* "style-section" +
                    (showtab ? " show" : "") +
                    (showtabdsk ? " showdsk" : "") */
                    }
                  >
                    {/* <div className="stng-drowr">
                  <div>{selectedItem?.controltype} Properties</div>
                  <div style={{cursor:"pointer"}}
                  onClick={handleTabshwButtonClick}>
                    {Theme.Cancel}</div>
                  </div> */}
                    <Drawer
                      anchor="right"
                      open={openpaint}
                      onClose={toggleDrawer(false)}
                      PaperProps={{
                        className: "cstm-drwr-stl-rgt",
                      }}
                    >
                      {!showtabdsk && (
                        <>
                          <div
                            className="dwr-right-heading"
                            onClick={toggleDrawer(false)}
                          >
                            <div>Form Designer</div>
                            <div style={{ cursor: "pointer" }}>
                              {Theme.Cancel}
                            </div>
                          </div>
                          <Box
                            className="dwr-box"
                            sx={{
                              width: 380,
                              padding: 0,
                              position: "relative",
                              overflow: "auto",
                            }}
                            role="presentation"
                            onClick={(e) => e.stopPropagation()}
                          >
                            <div className="dwr-box-tab">
                              <Tabs
                                className="dwr-inside-tab"
                                value={tabValue1}
                                onChange={handleTabChange}
                                centered
                                TabIndicatorProps={{
                                  className: "custom-tab-indicator",
                                }}
                              >
                                <Tab
                                  className={`dwr-inside-tab-btn ${tabValue1 === 0 ? "tab-selected" : ""}`}
                                  label="Style Settings"
                                />
                                <Tab
                                  className={`dwr-inside-tab-btn ${tabValue1 === 1 ? "tab-selected" : ""}`}
                                  label="Color Settings"
                                />
                                <Tab
                                  className={`dwr-inside-tab-btn ${tabValue1 === 2 ? "tab-selected" : ""}`}
                                  label="Background"
                                />
                              </Tabs>
                            </div>
                            {tabValue1 === 0 && (
                              <Box sx={{ padding: 2 }}>
                                <div style={{ marginTop: "7px" }}>
                                  {/* Style Theme Options */}
                                  <div className="drowerseparater">
                                    <div className="frm-inp-wrap">
                                      <div className="clr-schm-text">
                                        <div className="theme-row">
                                          <div className="theme-row">
                                            <label> Form Width </label>
                                            <div className="inpt-cntr-unit">
                                              {/* <input
                                                className="theme-input-select"
                                                type="number"
                                                value={FormWidth}
                                                // onChange={(e) =>
                                                //   setFormWidth(
                                                //     parseInt(e.target.value, 10)
                                                //   )
                                                // }
                                                onChange={(e) =>
                                                  handleFormWidthChange(
                                                    parseInt(e.target.value, 10)
                                                  )
                                                }
                                              /> */}
                                              <input
                                                className="theme-input-select"
                                                type="number"
                                                value={FormWidth}
                                                onChange={(e) => {
                                                  const value =
                                                    e.target.value === ""
                                                      ? ""
                                                      : parseInt(
                                                          e.target.value,
                                                          10
                                                        );
                                                  setFormWidth(value);
                                                  handleFormWidthChange(value);
                                                }}
                                              />

                                              <span className="inpt-unit">
                                                px
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="clr-schm-text">
                                        <div className="theme-row">
                                          <label>Label Alignment</label>
                                          <div className="inpt-cntr-unit">
                                            <select
                                              className="theme-input-slt"
                                              value={alignMent}
                                              onChange={handleSelectChange1}
                                            >
                                              <option value="left">Left</option>
                                              <option value="column">
                                                Top
                                              </option>
                                              <option value="right">
                                                Right
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="drowerseparater">
                                    <div className="frm-inp-wrap">
                                      <div className="clr-schm-text">
                                        <div className="theme-row">
                                          <label>Question Spacing</label>
                                          <div className="inpt-cntr-unit">
                                            <input
                                              className="theme-input-select"
                                              type="number"
                                              value={marginTop}
                                              onChange={(e) =>
                                                setMarginTop(
                                                  parseInt(e.target.value, 10)
                                                )
                                              }
                                            />
                                            <span className="inpt-unit">
                                              px
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="drowerseparater">
                                    <div className="frm-inp-wrap">
                                      <div className="clr-schm-text">
                                        <div className="theme-row">
                                          <label>Padding</label>
                                          <div className="inpt-cntr-unit">
                                            <input
                                              className="theme-input-select"
                                              type="number"
                                              value={paddingTop}
                                              onChange={(e) =>
                                                setPaddingTop(
                                                  parseInt(e.target.value, 10)
                                                )
                                              }
                                            />
                                            <span className="inpt-unit">
                                              px
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="drowerseparater">
                                    <div className="frm-inp-wrap">
                                      <div className="clr-schm-text">
                                        <div className="theme-row">
                                          <label>Font</label>
                                          <div className="inpt-cntr-unit">
                                            <select
                                              className="theme-input-slt"
                                              value={fontFamily}
                                              onChange={handleSelectfontfamily}
                                            >
                                              {fontFamilies.map(
                                                (font, index) => (
                                                  <option
                                                    key={index}
                                                    value={font}
                                                    style={{ fontFamily: font }}
                                                  >
                                                    {font}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="clr-schm-text">
                                        <div className="theme-row">
                                          <label>Font Size</label>
                                          <div className="inpt-cntr-unit">
                                            <input
                                              className="theme-input-select"
                                              type="number"
                                              value={FontSize}
                                              onChange={handleFontSizeChange}
                                            />
                                            <span className="inpt-unit">
                                              px
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {showButtonStyles && (
                                    <div>
                                      Button Styles
                                      <Tabs
                                        value={tabValue2}
                                        onChange={handleNestedTabChange}
                                      >
                                        <Tab label="Flat Styles" />
                                        <Tab label="Gradient Styles" />
                                      </Tabs>
                                      {tabValue2 === 0 && (
                                        <ul
                                          style={{
                                            listStyleType: "none",
                                            padding: 0,
                                            display: "flex",
                                            flexWrap: "wrap",
                                          }}
                                        >
                                          {buttonStylesFlat.map(
                                            (style, index) => (
                                              <li
                                                key={index}
                                                className="color-scheme"
                                                style={{
                                                  display: "inline-block",
                                                  margin: "5px",
                                                  padding: "10px",
                                                  cursor: "pointer",
                                                  borderWidth: "2px",
                                                  borderStyle: "solid",
                                                  backgroundColor:
                                                    style.backgroundColor,
                                                  color: style.color,
                                                  borderRadius: "4px",
                                                  border: "none",
                                                }}
                                                onClick={() =>
                                                  handleButtonClick(
                                                    style.backgroundColor
                                                  )
                                                }
                                              >
                                                <button
                                                  type="button"
                                                  style={{
                                                    backgroundColor:
                                                      "transparent",
                                                    border: "none",
                                                    color: "inherit",
                                                    fontSize: "16px",
                                                  }}
                                                >
                                                  {style.text}
                                                </button>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      )}
                                      {tabValue2 === 1 && (
                                        <ul
                                          style={{
                                            listStyleType: "none",
                                            padding: 0,
                                            display: "flex",
                                            flexWrap: "wrap",
                                          }}
                                        >
                                          {buttonStylesGradient.map(
                                            (style, index) => (
                                              <li
                                                key={index}
                                                aria-label={style.label}
                                                className="color-scheme"
                                                style={{
                                                  display: "inline-block",
                                                  margin: "5px",
                                                  padding: "10px",
                                                  cursor: "pointer",
                                                  borderWidth: "2px",
                                                  borderStyle: "solid",
                                                  background: style.background,
                                                  color: style.color,
                                                  borderRadius: "4px",
                                                  border: "none",
                                                }}
                                                onClick={() =>
                                                  handleButtonClickGradient(
                                                    style.background
                                                  )
                                                }
                                              >
                                                <button
                                                  type="button"
                                                  style={{
                                                    backgroundColor:
                                                      "transparent",
                                                    border: "none",
                                                    color: "inherit",
                                                    fontSize: "16px",
                                                  }}
                                                >
                                                  {style.text}
                                                </button>
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </Box>
                            )}

                            {tabValue1 === 1 && (
                              <Box sx={{ padding: 2 }}>
                                <div className="clr-schm-text">
                                  Color Scheme
                                </div>
                                <ul
                                  className="drowerseparater"
                                  style={{
                                    listStyleType: "none",
                                    // padding: 0,
                                    display: "flex",
                                    flexWrap: "wrap",
                                    paddingTop: "0px",
                                  }}
                                >
                                  {colorSchemes.map((scheme, index) => (
                                    <li
                                      key={index}
                                      aria-label={scheme.label}
                                      className="color-scheme"
                                      style={{
                                        display: "inline-block",
                                        margin: "5px",
                                        padding: "3px 5px",
                                        cursor: "pointer",
                                        borderWidth: "4px",
                                        borderStyle: "solid",
                                        borderColor: scheme.borderColor,
                                        backgroundColor: scheme.backgroundColor,
                                        color: scheme.color,
                                        borderRadius: "4px",
                                      }}
                                      onClick={() =>
                                        handleColorSchemeChange(scheme)
                                      }
                                    >
                                      <button
                                        type="button"
                                        style={{
                                          backgroundColor: "transparent",
                                          border: "none",
                                          color: "inherit",
                                          fontSize: "16px",
                                        }}
                                      >
                                        {scheme.buttonLabel}
                                      </button>
                                    </li>
                                  ))}
                                </ul>
                                <div className="drowerseparater">
                                  <div className="frm-inp-wrap">
                                    <div className="clr-schm-text">
                                      <span>Form Color</span>
                                      <div className="bg-color-int-color">
                                        <input
                                          value={bgColor}
                                          onChange={(e) =>
                                            setBgColor(e.target.value)
                                          }
                                          className="custom-input-wap text-input"
                                        />
                                        <input
                                          type="color"
                                          value={bgColor}
                                          onChange={(e) =>
                                            setBgColor(e.target.value)
                                          }
                                          className="custom-input-text color-input"
                                        />
                                      </div>
                                    </div>
                                    <div className="clr-schm-text">
                                      <span>Form Image</span>
                                      <div className="bg-color-int-color">
                                        <input
                                          type="file"
                                          id="fileInput"
                                          onChange={handleImageUpload}
                                          style={{ display: "none" }}
                                        />
                                        <label
                                          htmlFor="fileInput"
                                          className="cst-file-chose"
                                        >
                                          <ImFilePicture className="file-icon" />
                                          <span>
                                            {fileName
                                              ? fileName
                                              : "Choose a file"}
                                          </span>
                                        </label>
                                        <span
                                          onClick={handleRemoveBackgroundImage}
                                        >
                                          <Tooltip
                                            title={
                                              <Typography
                                                fontSize={11}
                                                color="#fff"
                                              >
                                                Remove Background image
                                              </Typography>
                                            }
                                            arrow
                                            placement="bottom"
                                            PopperProps={{
                                              sx: {
                                                "& .MuiTooltip-tooltip": {
                                                  backgroundColor:
                                                    "rgba(97, 97, 97, 0.9);",
                                                  color: "#fff",
                                                  fontSize: 11,
                                                  boxShadow:
                                                    "0px 2px 10px rgba(0, 0, 0, 0.2)",
                                                },
                                                "& .MuiTooltip-arrow": {
                                                  Color:
                                                    "rgba(97, 97, 97, 0.9);",
                                                },
                                              },
                                            }}
                                          >
                                            <span className="file-icon-cancel">
                                              {Theme.photocancel}
                                            </span>
                                          </Tooltip>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="drowerseparater">
                                  <div className="frm-inp-wrap">
                                    <div className="clr-schm-text">
                                      <span>Font Color</span>
                                      <div className="bg-color-int-color">
                                        <input
                                          value={fontColor}
                                          onChange={handlefontColorChange}
                                          className="custom-input-wap text-input"
                                        />
                                        <input
                                          type="color"
                                          value={fontColor}
                                          onChange={handlefontColorChange}
                                          className="custom-input-text color-input"
                                        />
                                      </div>
                                    </div>
                                    <div className="clr-schm-text">
                                      <span>Input Background</span>
                                      <div className="bg-color-int-color">
                                        <input
                                          value={inputColor}
                                          onChange={(e) =>
                                            setInputColor(e.target.value)
                                          }
                                          className="custom-input-wap text-input"
                                        />
                                        <input
                                          type="color"
                                          value={inputColor}
                                          onChange={(e) =>
                                            setInputColor(e.target.value)
                                          }
                                          className="custom-input-text color-input"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="btn-rmov-img">
                                  {/* <input type="file" onChange={handleImageUpload} /> */}
                                </div>

                                <div></div>
                              </Box>
                            )}

                            {tabValue1 === 2 && (
                              <>
                                <div className="bg-clr-inpt">
                                  <input
                                    type="file"
                                    id="fileInput"
                                    onChange={handleImageUploadparent}
                                    style={{ display: "none" }}
                                  />
                                  <label
                                    htmlFor="fileInput"
                                    className="cst-file-chose"
                                  >
                                    <ImFilePicture className="file-icon" />
                                    <span>
                                      {fileName ? fileName : "Choose a file"}
                                    </span>
                                  </label>
                                  <span
                                    onClick={handleRemoveParentBackgroundImage}
                                  >
                                    <Tooltip
                                      title={
                                        <Typography fontSize={11} color="#fff">
                                          Remove Background image
                                        </Typography>
                                      }
                                      arrow
                                      placement="bottom"
                                      PopperProps={{
                                        sx: {
                                          "& .MuiTooltip-tooltip": {
                                            backgroundColor:
                                              "rgba(97, 97, 97, 0.9);",
                                            color: "#fff",
                                            fontSize: 11,
                                            boxShadow:
                                              "0px 2px 10px rgba(0, 0, 0, 0.2)",
                                          },
                                          "& .MuiTooltip-arrow": {
                                            Color: "rgba(97, 97, 97, 0.9);",
                                          },
                                        },
                                      }}
                                    >
                                      <span className="file-icon-cancel">
                                        {Theme.photocancel}
                                      </span>
                                    </Tooltip>
                                  </span>
                                </div>

                                {showTheme && (
                                  <>
                                    <div>
                                      <Box
                                        sx={{ padding: "18px 18px 17px 14px" }}
                                      >
                                        <div
                                          container
                                          spacing={2}
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          {themeArray.map((item) => {
                                            let matchingHtmlOutput = null;
                                            try {
                                              matchingHtmlOutput =
                                                themehtmlouput.find(
                                                  (output) =>
                                                    output.arID === item.ArID
                                                );
                                            } catch (error) {
                                              console.error(
                                                "Error finding matchingHtmlOutput:",
                                                error
                                              );
                                              setError(
                                                "Error finding matchingHtmlOutput:"
                                              );
                                              localStorage.setItem(
                                                "error",
                                                "Error finding matchingHtmlOutput:"
                                              );
                                              localStorage.setItem(
                                                "navigate",
                                                "/Open"
                                              );
                                            }

                                            return (
                                              <div
                                                item
                                                key={item.ArID}
                                                xs={12}
                                                sm={12}
                                                md={12}
                                                onMouseEnter={(e) => {
                                                  e.currentTarget.style.border =
                                                    "2px solid #007BFF";
                                                  e.currentTarget.style.backgroundColor =
                                                    "#f0f8ff";
                                                }}
                                                onMouseLeave={(e) => {
                                                  e.currentTarget.style.border =
                                                    "2px solid transparent";
                                                  e.currentTarget.style.backgroundColor =
                                                    "transparent";
                                                }}
                                                style={{
                                                  padding: "0px",
                                                  marginBottom: "10px",
                                                  border:
                                                    "2px solid transparent",
                                                  transition:
                                                    "border 0.3s ease",
                                                  cursor: "pointer",
                                                  borderRadius: "6px",
                                                }}
                                              >
                                                <Card
                                                  sx={{
                                                    minWidth: 68,
                                                    maxWidth: 168,
                                                    height: 190,
                                                    boxShadow: 3,
                                                  }}
                                                >
                                                  <CardContent
                                                    style={{ padding: "0px" }}
                                                  >
                                                    <div
                                                      variant="h5"
                                                      component="div"
                                                      gutterBottom
                                                    >
                                                      {matchingHtmlOutput && (
                                                        <>
                                                          <iframe
                                                            srcDoc={
                                                              matchingHtmlOutput.html
                                                            }
                                                            style={{
                                                              width: "100%",
                                                              height: "149px",
                                                              border: "none",
                                                            }}
                                                          />
                                                          <div className="theme-btn-varint">
                                                            <Button
                                                              // variant="contained"
                                                              onClick={(
                                                                event
                                                              ) =>
                                                                handleSelect(
                                                                  item.ArID,
                                                                  event
                                                                )
                                                              }
                                                              style={{
                                                                width: "100%",
                                                              }}
                                                            >
                                                              Select
                                                            </Button>
                                                          </div>
                                                        </>
                                                      )}
                                                    </div>
                                                  </CardContent>
                                                </Card>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </Box>
                                    </div>

                                    <div className="rmv-hndle-them">
                                      <button onClick={handleRemove}>
                                        Remove Theme
                                      </button>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </Box>
                        </>
                      )}
                      {controlsVisible && showtabdsk && (
                        <div
                          id="stylesection"
                          className={
                            "" +
                            /* "style-section" + 
                    (showtab ? " show" : "") +*/
                            (showtabdsk ? " showdsk" : "")
                          }
                        >
                          <div className="stng-drowr">
                            <div>
                              {selectedItem?.type === "Root"
                                ? "Root"
                                : selectedItem?.id === "0.2L"
                                  ? "Left Container"
                                  : selectedItem?.id === "0.2R"
                                    ? "Right Container"
                                    : selectedItem?.controltype}{" "}
                              Properties
                            </div>
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={toggleDrawer(false)}
                            >
                              {Theme.Cancel}
                            </div>
                          </div>

                          {controlsVisible && (
                            <GeneralSection
                              tabValue={tabValue}
                              setTabValue={setTabValue}
                              rightsidetabs={rightsidetabs}
                              selectedItem={selectedItem}
                              isRootItem={isRootItem}
                              treeData={treeData}
                              editedData={editedData}
                              handleEditChange={handleEditChange}
                              handleEditChangeforTable={
                                handleEditChangeforTable
                              }
                              tabSelect={tabSelect}
                              handleEnterForNumber={handleEnterForNumber}
                              handleAgree={handleAgree}
                              handlePopupClick={handlePopupClick}
                              isPopupVisible={isPopupVisible}
                              handleClose={handleClose}
                              selectedOption={selectedOption}
                              dataSourceArID={dataSourceArID}
                              fieldNames={fieldNames}
                              fieldNamestable={fieldNamestable}
                              handleChange={handleChange}
                              inputValueRadio={inputValueRadio}
                              handleInputChangeRadio={handleInputChangeRadio}
                              handleAddControlRadio={handleAddControlRadio}
                              handleRadioPositionChange={
                                handleRadioPositionChange
                              }
                              inputValue={inputValue}
                              handleInputChange={handleInputChange}
                              handleAddControlCheckbox={
                                handleAddControlCheckbox
                              }
                              selectedOptioncheck={selectedOptioncheck}
                              handleCheckboxChange={handleCheckboxChange}
                              handleRadioChange={handleRadioChange}
                              selectedPositioncheck={selectedPositioncheck}
                              handleCheckboxPositionChange={
                                handleCheckboxPositionChange
                              }
                              selectedOptionSwitchcheck={
                                selectedOptionSwitchcheck
                              }
                              isSwitched={isSwitched}
                              handleSwitchChange={handleSwitchChange}
                              inputRef={inputRef}
                              handleImageChange={handleImageChange}
                              handleImageClick={handleImageClick}
                              handleIFrameChange={handleIFrameChange}
                              handleIFrameClick={handleIFrameClick}
                              handleKeyPress={handleKeyPress}
                              handleDateChange={handleDateChange}
                              isOpen={isOpen}
                              handleEditChangeName1={handleEditChangeName1}
                              handleRenameConfirm={handleRenameConfirm}
                              editedName={editedName}
                              isEditing={isEditing}
                              setControlsVisible={setControlsVisible}
                              controlsVisible={controlsVisible}
                              inputType={inputType}
                              addNewColumn={addNewColumn}
                              setTreeData={setTreeData}
                              selectedItemID={selectedItemID}
                              selectedControlType={selectedControlType}
                              setSelectedControlType={setSelectedControlType}
                              handleControlTypeChange={handleControlTypeChange}
                              tableProp={tableProp}
                              setEditedData={setEditedData}
                              setEditedName={setEditedName}
                              fieldData={fieldData}
                              setFieldNames={setFieldNames}
                              inputText={inputText}
                              url={url}
                              selectedControl={selectedControl}
                              defaultStyle={defaultStyle}
                              selectedNode={selectedNode}
                              setTableHeader={setTableHeader}
                              tableHeader={tableHeader}
                              settableProp={settableProp}
                              rightsideStyletab={rightsideStyletab}
                              updateCustomStyle={updateCustomStyle}
                              setAlignMent={setAlignMent}
                              alignMent={alignMent}
                              isRequiredFullName={isRequiredFullName}
                              setIsRequiredFullName={setIsRequiredFullName}
                              isRequiredAddress={isRequiredAddress}
                              setIsRequiredAddress={setIsRequiredAddress}
                              isRequiredEmail={isRequiredEmail}
                              setIsRequiredEmail={setIsRequiredEmail}
                              isRequiredPhoneNumber={isRequiredPhoneNumber}
                              setIsRequiredPhoneNumber={
                                setIsRequiredPhoneNumber
                              }
                              CheckboxCheckedStreet1={CheckboxCheckedStreet1}
                              setCheckboxCheckedStreet1={
                                setCheckboxCheckedStreet1
                              }
                              CheckboxCheckedStreet2={CheckboxCheckedStreet2}
                              setCheckboxCheckedStreet2={
                                setCheckboxCheckedStreet2
                              }
                              setCheckboxCheckedCity={setCheckboxCheckedCity}
                              CheckboxCheckedCity={CheckboxCheckedCity}
                              setCheckboxCheckedState={setCheckboxCheckedState}
                              CheckboxCheckedState={CheckboxCheckedState}
                              setCheckboxCheckedPostal={
                                setCheckboxCheckedPostal
                              }
                              CheckboxCheckedPostal={CheckboxCheckedPostal}
                              CheckboxCheckedCountry={CheckboxCheckedCountry}
                              setCheckboxCheckedCountry={
                                setCheckboxCheckedCountry
                              }
                              CheckboxCheckedPrefix={CheckboxCheckedPrefix}
                              setCheckboxCheckedPrefix={
                                setCheckboxCheckedPrefix
                              }
                              FontSize={FontSize}
                              setFontSize={setFontSize}
                              authData={authData.accessKey || ""}
                              setFieldData={setFieldData}
                              setSelectedItem={setSelectedItem}
                              error={error}
                              setError={setError}
                            />
                          )}
                          {controlsVisible && (
                            <AdvanceSection
                              selectedItem={selectedItem}
                              treeData={treeData}
                              controlsVisible={controlsVisible}
                              setTreeData={setTreeData}
                              selectedItemID={selectedItemID}
                              rightsideStyletab={rightsideStyletab}
                              defaultStyle={defaultStyle}
                              inputRef={inputRef}
                              selectedNode={selectedNode}
                              editedDataFirstNameTextbox={
                                editedDataFirstNameTextbox
                              }
                              setEditedDataFirstNameTextbox={
                                setEditedDataFirstNameTextbox
                              }
                              editedDataLastNameTextbox={
                                editedDataLastNameTextbox
                              }
                              setEditedDataLastNameTextbox={
                                setEditedDataLastNameTextbox
                              }
                              CheckboxCheckedStreet1={CheckboxCheckedStreet1}
                              setCheckboxCheckedStreet1={
                                setCheckboxCheckedStreet1
                              }
                              CheckboxCheckedStreet2={CheckboxCheckedStreet2}
                              setCheckboxCheckedStreet2={
                                setCheckboxCheckedStreet2
                              }
                              setCheckboxCheckedCity={setCheckboxCheckedCity}
                              CheckboxCheckedCity={CheckboxCheckedCity}
                              setCheckboxCheckedState={setCheckboxCheckedState}
                              CheckboxCheckedState={CheckboxCheckedState}
                              setCheckboxCheckedPostal={
                                setCheckboxCheckedPostal
                              }
                              CheckboxCheckedPostal={CheckboxCheckedPostal}
                              CheckboxCheckedCountry={CheckboxCheckedCountry}
                              setCheckboxCheckedCountry={
                                setCheckboxCheckedCountry
                              }
                              CheckboxCheckedPrefix={CheckboxCheckedPrefix}
                              setCheckboxCheckedPrefix={
                                setCheckboxCheckedPrefix
                              }
                              dataSourceArID={dataSourceArID}
                              fieldData={fieldData}
                              setFieldNames={setFieldNames}
                              fieldNames={fieldNames}
                              authData={authData.accessKey || ""}
                              updateCustomStyle={updateCustomStyle}
                              editedDataStreet1Textbox={
                                editedDataStreet1Textbox
                              }
                              setEditedDataStreet1Textbox={
                                setEditedDataStreet1Textbox
                              }
                              editedDataCityTextbox={editedDataCityTextbox}
                              setEditedDataCityTextbox={
                                setEditedDataCityTextbox
                              }
                              editedDataStateTextbox={editedDataStateTextbox}
                              setEditedDataStateTextbox={
                                setEditedDataStateTextbox
                              }
                              editedDataPostalTextbox={editedDataPostalTextbox}
                              setEditedDataPostalTextbox={
                                setEditedDataPostalTextbox
                              }
                              editedDataPrefixTextbox={editedDataPrefixTextbox}
                              setEditedDataPrefixTextbox={
                                setEditedDataPrefixTextbox
                              }
                              editedDataMiddleTextbox={editedDataMiddleTextbox}
                              setEditedDataMiddleTextbox={
                                setEditedDataMiddleTextbox
                              }
                              CheckboxCheckedMiddleName={
                                CheckboxCheckedMiddleName
                              }
                              setCheckboxCheckedMiddleName={
                                setCheckboxCheckedMiddleName
                              }
                            />
                          )}

                          {controlsVisible && (
                            <OptionSection
                              rightsidetabs={rightsidetabs}
                              selectedItem={selectedItem}
                              controlsVisible={controlsVisible}
                              treeData={treeData}
                              dataSourceArID={dataSourceArID}
                              setTreeData={setTreeData}
                              selectedItemID={selectedItemID}
                              fieldData={fieldData}
                              setFieldNames={setFieldNames}
                              defaultStyle={defaultStyle}
                              rightsideStyletab={rightsideStyletab}
                              updateCustomStyle={updateCustomStyle}
                              CheckboxCheckedStreet1={CheckboxCheckedStreet1}
                              setCheckboxCheckedStreet1={
                                setCheckboxCheckedStreet1
                              }
                              CheckboxCheckedStreet2={CheckboxCheckedStreet2}
                              setCheckboxCheckedStreet2={
                                setCheckboxCheckedStreet2
                              }
                              setCheckboxCheckedCity={setCheckboxCheckedCity}
                              CheckboxCheckedCity={CheckboxCheckedCity}
                              setCheckboxCheckedState={setCheckboxCheckedState}
                              CheckboxCheckedState={CheckboxCheckedState}
                              setCheckboxCheckedPostal={
                                setCheckboxCheckedPostal
                              }
                              CheckboxCheckedPostal={CheckboxCheckedPostal}
                              CheckboxCheckedCountry={CheckboxCheckedCountry}
                              setCheckboxCheckedCountry={
                                setCheckboxCheckedCountry
                              }
                              authData={authData.accessKey || ""}
                              CheckboxCheckedMiddleName={
                                CheckboxCheckedMiddleName
                              }
                              setCheckboxCheckedMiddleName={
                                setCheckboxCheckedMiddleName
                              }
                              CheckboxCheckedPrefix={CheckboxCheckedPrefix}
                              setCheckboxCheckedPrefix={
                                setCheckboxCheckedPrefix
                              }
                            />
                          )}
                        </div>
                      )}
                    </Drawer>
                  </div>
                )}
              </div>
            )}
            {showTheme && (
              <>
                {treeData?.length > 0 &&
                  treeData.map((item) => {
                    return item.Source[0].control
                      .filter(
                        (control) =>
                          control.option === "Template1FooterContainer"
                      )
                      .map((footerControl) => {
                        const isFooter =
                          footerControl.option === "Template1FooterContainer";
                        const renderedFooterControl =
                          renderControl(footerControl);

                        if (isFooter) {
                          try {
                            const iconContainer = document.getElementById(
                              `iconContainer-${footerControl.id}`
                            );
                            if (iconContainer) {
                              const element = document.getElementById(
                                footerControl.id
                              );
                              if (element) {
                                const rect = element.getBoundingClientRect();
                                const iconRect =
                                  iconContainer.getBoundingClientRect();

                                // Fix icon position to the top of the footer element (positioned fixed)
                                iconContainer.style.position = "fixed"; // Fix the position to the viewport
                                iconContainer.style.top = `${rect.top + window.scrollY - iconRect.height - 10}px`; // Set the top position with scroll offset
                                iconContainer.style.left = `${rect.left + window.scrollX + rect.width / 2 - iconRect.width / 2}px`; // Center horizontally
                                iconContainer.style.zIndex = "0";
                                iconContainer.style.marginTop = "0px";
                              }
                            }
                          } catch (error) {
                            console.error(
                              "Error positioning icon container:",
                              error
                            );
                            setError("Error positioning icon container:");
                            localStorage.setItem(
                              "error",
                              "Error positioning icon container:"
                            );
                            localStorage.setItem("navigate", "/Open");
                          }
                        }

                        return renderedFooterControl;
                      });
                  })}
              </>
            )}
          </div>

          {controlsVisible && (
            <div className="stl-right-drower">
              <span className="custom-tooltip-form" onClick={paintIconClick}>
                <span className="paint-icon">{Theme.Paint}</span>
                <span className="tooltip-text-dgn-frm">Form Designer</span>
              </span>
            </div>
          )}

          {!controlsVisible && (
            <div className="stl-right-back">
              <span
                className="custom-tooltip-form-back"
                onClick={handlePreViewBack}
              >
                <span className="paint-icon">{Theme.Back}</span>
                <span className="tooltip-text-dgn-frm">Preview Back</span>
              </span>
            </div>
          )}
        </div>
      </DndProvider>
      {error && <ErrorBanner />}
    </>
  );
};
export default RecursiveTreeView;
