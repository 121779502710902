import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import InfoIcon from "@mui/icons-material/Info";
import { Theme } from "../../Components/IncludeFile/Theme";
import themeProvider from "../../ThemeProvider";
import { ThemeProvider } from "@mui/styles";
import NavBar from "../../Components/navbarComponents/NavBar";
import CustomPopUpForm from "../../layout/CustomPopUpForm";
import { ToastContainer, toast } from "react-toastify";
import { TiExport } from "react-icons/ti";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
} from "@mui/material";
import ExpandableStyleView from "../../layout/ExpandableStyleView";
import { DialogTitle, useMediaQuery } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import { getCurrentDateTime, loadLoginData } from "../../apiCalls/DiagramsAPI";
import ErrorBanner from "../../Error/ErrorBanner";

const EditControlsStyles = () => {
  let APIProvider = window.APIProvider;
  const location = useLocation();
  const navigate = useNavigate();
  const selectedNodesName = location.state?.selectedNodes || [];
  const defaultStyleFromLoca = location.state?.defaultStyleFromLoca || [];

  const authData = loadLoginData() || [];
  const accessKey = authData.accessKey;
  const userName = authData?.userName || "";
  const [infoPopup, setInfoPopup] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [error, setError] = useState(null);

  const leftSidebarTree = [
    { id: "1", propertyName: "Align items" },

    ...(selectedNodesName[0] !== "Label" &&
    selectedNodesName[0] !== "Header" &&
    selectedNodesName[0] !== "Image" &&
    selectedNodesName[0] !== "List" &&
    selectedNodesName[0] !== "Radio" &&
    selectedNodesName[0] !== "RadioContainer" &&
    selectedNodesName[0] !== "Switch" &&
    selectedNodesName[0] !== "Checkbox" &&
    selectedNodesName[0] !== "Icon" &&
    selectedNodesName[0] !== "SubHeader"
      ? [{ id: "2", propertyName: "Body Colour" }]
      : []),

    ...(selectedNodesName[0] !== "Label" &&
    selectedNodesName[0] !== "Header" &&
    selectedNodesName[0] !== "Image" &&
    selectedNodesName[0] !== "Checkbox" &&
    selectedNodesName[0] !== "List" &&
    selectedNodesName[0] !== "Switch" &&
    selectedNodesName[0] !== "Date" &&
    selectedNodesName[0] !== "Icon" &&
    selectedNodesName[0] !== "Divider" &&
    selectedNodesName[0] !== "TextBox" &&
    selectedNodesName[0] !== "TextArea" &&
    selectedNodesName[0] !== "Table" &&
    selectedNodesName[0] !== "Button" &&
    selectedNodesName[0] !== "Dropdown" &&
    selectedNodesName[0] !== "SubHeader"
      ? [{ id: "3", propertyName: "Layout" }]
      : []),

    ...(selectedNodesName[0] !== "Container" &&
    selectedNodesName[0] !== "HeaderContainer" &&
    selectedNodesName[0] !== "Image" &&
    selectedNodesName[0] !== "Switch" &&
    selectedNodesName[0] !== "Divider" &&
    selectedNodesName[0] !== "Icon" &&
    selectedNodesName[0] !== "Iframe" &&
    selectedNodesName[0] !== "FooterContainer"
      ? [{ id: "8", propertyName: "Font" }]
      : []),

    ...(selectedNodesName[0] !== "Label" &&
    selectedNodesName[0] !== "Header" &&
    selectedNodesName[0] !== "Divider" &&
    selectedNodesName[0] !== "List" &&
    selectedNodesName[0] !== "Checkbox" &&
    selectedNodesName[0] !== "Switch" &&
    selectedNodesName[0] !== "Icon" &&
    selectedNodesName[0] !== "SubHeader" &&
    selectedNodesName[0] !== "Radio"
      ? [{ id: "4", propertyName: "Border" }]
      : []),

    { id: "5", propertyName: "Margin" },

    ...(selectedNodesName[0] !== "Divider" &&
    selectedNodesName[0] !== "Switch" &&
    selectedNodesName[0] !== "Icon" &&
    selectedNodesName[0] !== "Divider" &&
    selectedNodesName[0] !== "Image"
      ? [{ id: "6", propertyName: "Padding" }]
      : []),

    { id: "7", propertyName: "Size" },
  ];

  const [selectedItem, setSelectedItem] = useState();
  const [matchedDefaultStyles, setMatchedDefaultStyles] = useState("");

  const [defaultStyle, setDefaultStyle] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setMatchedDefaultStyles(defaultStyleFromLoca);
        setDefaultStyle(defaultStyleFromLoca);
      } catch (error) {
        console.error("Error fetching source data:", error);
        setError("An error occurred in fetching source data");
        localStorage.setItem("error", "An error occurred in fetching source data");
        localStorage.setItem("navigate", "/Open");
      }
    };
    fetchData();
  }, []);

  const matchedStyles = defaultStyle.filter((style) =>
    selectedNodesName.includes(style.styleType)
  );
  const [defaultStyleState, setDefaultStyleState] = useState([]);
  const isMobile = useMediaQuery("(max-width:600px)");
  const handleInfoPopup = () => {
    setInfoPopup(!infoPopup);
  };

  const leftButton = {
    icon: <InfoIcon className={isMobile ? "mobile-icon-edit" : ""} />,
    text: "Info",
    handleClick: () => {
      handleInfoPopup();
    },
  };

  const handleRestore = () => {
    setDefaultStyle(matchedDefaultStyles);
    toast.success("Changes Are Restored Successsfully");
  };

  const rightButtons = [
    {
      icon: Theme.Save,
      text: "Save",
      color: "secondary",
      handleClick: () => {
        handleOnSave();
      },
    },
    {
      icon: Theme.Restore,
      text: "Restore",
      color: "secondary",
      handleClick: () => {
        handleRestore();
      },
    },
    {
      icon: Theme.GoBack,
      text: "Back",
      color: "secondary",
      handleClick: () => {
        navigate(-1);
        // handleGoBack()
      },
    },
  ];

  const matchedLabelStyle = matchedStyles.length > 0 ? matchedStyles[0] : null;

  const [alignSelfValue, setAlignSelfValue] = useState("");
  const [backgroundColorValue, setBackgroundColorValue] = useState("");
  const [borderColor, setbordercolor] = useState("");
  const [colorValue, setColorValue] = useState("");
  const [widthValue, setWidthValue] = useState("");
  const [heightValue, setHeightValue] = useState("");
  const [minWidthValue, setMinWidthValue] = useState("");
  const [minHeightValue, setMinHeightValue] = useState("");
  const [maxWidthValue, setMaxWidthValue] = useState("");
  const [maxHeightValue, setMaxHeightValue] = useState("");
  const [fontSizeValue, setFontSizeValue] = useState("");
  const [fontFamilyValue, setFontFamilyValue] = useState("");
  const [fontWeightValue, setFontWeightValue] = useState("");
  const [fontStyleValue, setFontStyleValue] = useState("");
  const [textDecorationValue, settextDecorationValue] = useState("");
  const [textAlignValue, setTextAlignValue] = useState("");
  const [marginTop, setMarginTop] = useState("");
  const [marginBottom, setmarginBottom] = useState("");
  const [marginLeft, setMarginLeft] = useState("");
  const [marginRight, setMarginRight] = useState("");
  const [paddingTop, setPaddingTop] = useState("");
  const [paddingBottom, setPaddingBottom] = useState("");
  const [paddingLeft, setPaddingLeft] = useState("");
  const [paddingRight, setPaddingRight] = useState("");
  const [borderWidth, setborderWidth] = useState("");
  const [borderRadius, setborderRadius] = useState("");
  const [borderColorValue, setborderColorValue] = useState("");
  const [flexDirection, setFlexDirection] = useState("");
  const [alignItems, setAlignItems] = useState("");
  const [alignContent, setAlignContent] = useState("");
  const [justifyItems, setJustifyItems] = useState("");
  const [justifyContent, setJustifyContent] = useState("");

  useEffect(() => {
    const initialPaddingTop = matchedLabelStyle
      ? matchedLabelStyle.style.paddingTop || ""
      : "";
    const initialalignSelf = matchedLabelStyle
      ? matchedLabelStyle.style.alignSelf || ""
      : "";
    const initiialbackgroundColorValue = matchedLabelStyle
      ? matchedLabelStyle.style.backgroundColor || ""
      : "";
    const initialcolorValue = matchedLabelStyle
      ? matchedLabelStyle.style.color || ""
      : "";
    const initialwidthValue = matchedLabelStyle
      ? matchedLabelStyle.style.width || ""
      : "";
    const initialheightValue = matchedLabelStyle
      ? matchedLabelStyle.style.height || ""
      : "";
    const initialminWidthValue = matchedLabelStyle
      ? matchedLabelStyle.style.minWidth || ""
      : "";
    const initialminHeightValue = matchedLabelStyle
      ? matchedLabelStyle.style.minHeight || ""
      : "";
    const initialmaxWidthValue = matchedLabelStyle
      ? matchedLabelStyle.style.maxWidth || ""
      : "";
    const initialmaxHeightValue = matchedLabelStyle
      ? matchedLabelStyle.style.maxHeight || ""
      : "";
    const initialfontSizeValue = matchedLabelStyle
      ? matchedLabelStyle.style.fontSize || ""
      : "";
    const initialfontFamilyValue = matchedLabelStyle
      ? matchedLabelStyle.style.fontFamily || ""
      : "";
    const initialfontWeightValue = matchedLabelStyle
      ? matchedLabelStyle.style.fontWeight || ""
      : "";
    const initialfontStyleValue = matchedLabelStyle
      ? matchedLabelStyle.style.fontStyle || ""
      : "";
    const initialtextDecorationValue = matchedLabelStyle
      ? matchedLabelStyle.style.textDecoration || ""
      : "";
    const initialtextAlignValue = matchedLabelStyle
      ? matchedLabelStyle.style.textAlign || ""
      : "";
    const initialPaddingBottom = matchedLabelStyle
      ? matchedLabelStyle.style.paddingBottom || ""
      : "";
    const initialPaddingLeft = matchedLabelStyle
      ? matchedLabelStyle.style.paddingLeft || ""
      : "";
    const initialPaddingRight = matchedLabelStyle
      ? matchedLabelStyle.style.paddingRight || ""
      : "";
    const initialMarginTop = matchedLabelStyle
      ? matchedLabelStyle.style.marginTop || ""
      : "";
    const initialMarginBottom = matchedLabelStyle
      ? matchedLabelStyle.style.marginBottom || ""
      : "";
    const initialMarginLeft = matchedLabelStyle
      ? matchedLabelStyle.style.marginLeft || ""
      : "";
    const initialMarginRight = matchedLabelStyle
      ? matchedLabelStyle.style.marginRight || ""
      : "";
    const initialBorderWidth = matchedLabelStyle
      ? matchedLabelStyle.style.borderWidth || ""
      : "";
    const initialBorderRadious = matchedLabelStyle
      ? matchedLabelStyle.style.borderRadius || ""
      : "";
    const initialBorderColourValue = matchedLabelStyle
      ? matchedLabelStyle.style.borderColor || ""
      : "";
    const initialFlexDirection = matchedLabelStyle
      ? matchedLabelStyle.style.flexDirection || ""
      : "";
    const initialalignItems = matchedLabelStyle
      ? matchedLabelStyle.style.alignItems || ""
      : "";
    const initialalignContent = matchedLabelStyle
      ? matchedLabelStyle.style.alignContent || ""
      : "";
    const initialjustifyItems = matchedLabelStyle
      ? matchedLabelStyle.style.justifyItems || ""
      : "";
    const initialjustifyContent = matchedLabelStyle
      ? matchedLabelStyle.style.justifyContent || ""
      : "";
    const initialborderColor = matchedLabelStyle
      ? matchedLabelStyle.style.borderColor || ""
      : "";

    setPaddingTop(initialPaddingTop);
    setAlignSelfValue(initialalignSelf);
    setBackgroundColorValue(initiialbackgroundColorValue);
    setColorValue(initialcolorValue);
    setWidthValue(initialwidthValue);
    setHeightValue(initialheightValue);
    setMinWidthValue(initialminWidthValue);
    setMinHeightValue(initialminHeightValue);
    setMaxWidthValue(initialmaxWidthValue);
    setMaxHeightValue(initialmaxHeightValue);
    setFontSizeValue(initialfontSizeValue);
    setFontFamilyValue(initialfontFamilyValue);
    setFontWeightValue(initialfontWeightValue);
    setFontStyleValue(initialfontStyleValue);
    settextDecorationValue(initialtextDecorationValue);
    setTextAlignValue(initialtextAlignValue);
    setPaddingBottom(initialPaddingBottom);
    setPaddingLeft(initialPaddingLeft);
    setPaddingRight(initialPaddingRight);
    setMarginTop(initialMarginTop);
    setmarginBottom(initialMarginBottom);
    setMarginLeft(initialMarginLeft);
    setMarginRight(initialMarginRight);
    setborderWidth(initialBorderWidth);
    setborderRadius(initialBorderRadious);
    setborderColorValue(initialBorderColourValue);
    setFlexDirection(initialFlexDirection);
    setAlignItems(initialalignItems);
    setAlignContent(initialalignContent);
    setJustifyItems(initialjustifyItems);
    setJustifyContent(initialjustifyContent);
    setbordercolor(initialborderColor);
  }, [matchedLabelStyle]);

  const updateDefaultStyle = () => {
    const filterStyle = (style) => {
      return Object.keys(style).reduce((acc, key) => {
        if (
          style[key] !== "" &&
          style[key] !== undefined &&
          style[key] !== null
        ) {
          acc[key] = style[key];
        }
        return acc;
      }, {});
    };

    // Update styles
    const updatedStyles = defaultStyle.map((style) => {
      if (selectedNodesName.includes(style.styleType)) {
        const updatedStyle = {
          ...style.style,
          alignSelf: alignSelfValue,
          backgroundColor: backgroundColorValue,
          color: colorValue,
          width: widthValue,
          height: heightValue,
          minWidth: minWidthValue,
          minHeight: minHeightValue,
          maxWidth: maxWidthValue,
          maxHeight: maxHeightValue,
          fontSize: fontSizeValue,
          fontFamily: fontFamilyValue,
          fontWeight: fontWeightValue,
          fontStyle: fontStyleValue,
          textDecoration: textDecorationValue,
          textAlign: textAlignValue,
          paddingTop: paddingTop,
          paddingBottom: paddingBottom,
          paddingLeft: paddingLeft,
          paddingRight: paddingRight,
          marginTop: marginTop,
          marginBottom: marginBottom,
          marginLeft: marginLeft,
          marginRight: marginRight,
          borderWidth: borderWidth,
          borderRadius: borderRadius,
          borderColor: borderColorValue,
          flexDirection: flexDirection,
          alignItems: alignItems,
          alignContent: alignContent,
          justifyItems: justifyItems,
          justifyContent: justifyContent,
        };

        return {
          ...style,
          style: filterStyle(updatedStyle),
        };
      } else {
        return style;
      }
    });

    setDefaultStyle(updatedStyles);
  };

  const dateTime = getCurrentDateTime();

  const handleOnSave = async () => {
    if (!authData || !authData.accessKey) {
      navigate("/");
      sessionStorage.clear();
      return;
      return;
    }
    try {
      const url = `${APIProvider.baseURL}${APIProvider.MethodName.PostQuickInfo}`;
      const arId = "10";
      const arrayName = "DefaultStyles";
      const description = "DefaultStyles";
      const category = "DefaultStyles";
      const dataSourceArID = "0";

      const requestBody = {
        accessKey: authData.accessKey,
        guid: APIProvider.GUID.WriteArrayGUID,
        param1: "",
        param2: "",
        param3: "",
        param4: "",
        recordSet: "",
        body: JSON.stringify({
          ArID: arId,
          ArrayName: arrayName,
          Description: description,
          Source: JSON.stringify(defaultStyle),
          Category: category,
          IsTemplate: "3",
          htmlOutput: "New",
          DataSourceArID: dataSourceArID,
          isInactive: 0,
          ModifiedBy: userName,
          ModifiedDate: dateTime,
        }),
      };


      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });


  

 
      if (!response.ok) {
        throw new Error("Failed to save data");
      }

      console.log("Changes saved successfully");
      toast.success("Changes Saved Successfully");

    } catch (error) {
      console.error("Error on Save:", error);
      setError("An error occurred in On Save");
      localStorage.setItem("error", "An error occurred in On Save");
      localStorage.setItem("navigate", "/Open");
    }
  };


  const handleCloseConfirmation = () => {
    setOpenConfirmation(false);
  };

  const handleConfirmGoBack = () => {
    setOpenConfirmation(false);
    navigate(-1);
  };

  const handleConfirmGoBackWithSave = () => {
    handleConfirmGoBack();
  };

  return (
    <>
    <ThemeProvider theme={themeProvider}>
      <ToastContainer position="bottom-right" autoClose={5000} />
      <NavBar
        rightButtons={rightButtons}
        title={`Edit ${selectedNodesName[0]} properties`}
        leftButton={leftButton}
      />

      {infoPopup && (
        <CustomPopUpForm
          open={infoPopup}
          onClose={handleInfoPopup}
          title="Information"
          height="60%"
        >
          <Typography>
            This Page allows you to to edit the properties of the tools on the
            left hand side of the form builder.
          </Typography>
          <></>
        </CustomPopUpForm>
      )}

      <Dialog
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: "25px" }}>
          <div className="cusm-usve-btn">
            <DialogTitle id="alert-dialog-title">
              <div className="close-popup-frm">
                <p className="con-frm-cls">Unsaved Changes</p>
                <IconButton
                  onClick={handleCloseConfirmation}
                  color="inherit"
                  edge="end"
                  style={{ marginTop: "-9px" }}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <p className="con-txt-fr">
                  Are you sure you want to Leave this page?
                  <br /> Changes you made will not be saved.
                </p>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseConfirmation}
                color="primary"
                className="cancl-page-btn"
              >
                Cancel
              </Button>
              <Button
                onClick={handleConfirmGoBackWithSave}
                color="primary"
                autoFocus
                className="leave-page-btn"
              >
                Leave Page
              </Button>
            </DialogActions>
          </div>
        </div>
      </Dialog>

      <ExpandableStyleView
        leftSidebarTree={leftSidebarTree}
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        matchedStyles={matchedStyles}
        selectedNodesName={selectedNodesName}
        updateDefaultStyle={updateDefaultStyle}
        alignSelfValue={alignSelfValue}
        setAlignSelfValue={setAlignSelfValue}
        setDefaultStyleState={setDefaultStyleState}
        backgroundColorValue={backgroundColorValue}
        setBackgroundColorValue={setBackgroundColorValue}
        colorValue={colorValue}
        setColorValue={setColorValue}
        widthValue={widthValue}
        setWidthValue={setWidthValue}
        heightValue={heightValue}
        setHeightValue={setHeightValue}
        minWidthValue={minWidthValue}
        minHeightValue={minHeightValue}
        setMinHeightValue={setMinHeightValue}
        setMinWidthValue={setMinWidthValue}
        maxWidthValue={maxWidthValue}
        maxHeightValue={maxHeightValue}
        setMaxHeightValue={setMaxHeightValue}
        setMaxWidthValue={setMaxWidthValue}
        fontSizeValue={fontSizeValue}
        setFontSizeValue={setFontSizeValue}
        fontFamilyValue={fontFamilyValue}
        setFontFamilyValue={setFontFamilyValue}
        fontWeightValue={fontWeightValue}
        setFontWeightValue={setFontWeightValue}
        fontStyleValue={fontStyleValue}
        setFontStyleValue={setFontStyleValue}
        textDecorationValue={textDecorationValue}
        settextDecorationValue={settextDecorationValue}
        textAlignValue={textAlignValue}
        setTextAlignValue={setTextAlignValue}
        marginTop={marginTop}
        setMarginTop={setMarginTop}
        marginBottom={marginBottom}
        setmarginBottom={setmarginBottom}
        marginLeft={marginLeft}
        marginRight={marginRight}
        setMarginLeft={setMarginLeft}
        setMarginRight={setMarginRight}
        paddingTop={paddingTop}
        paddingBottom={paddingBottom}
        paddingLeft={paddingLeft}
        paddingRight={paddingRight}
        setPaddingTop={setPaddingTop}
        setPaddingBottom={setPaddingBottom}
        setPaddingLeft={setPaddingLeft}
        setPaddingRight={setPaddingRight}
        borderWidth={borderWidth}
        setborderWidth={setborderWidth}
        borderRadius={borderRadius}
        setborderRadius={setborderRadius}
        borderColorValue={borderColorValue}
        setborderColorValue={setborderColorValue}
        flexDirection={flexDirection}
        setFlexDirection={setFlexDirection}
        alignItems={alignItems}
        setAlignItems={setAlignItems}
        alignContent={alignContent}
        setAlignContent={setAlignContent}
        justifyItems={justifyItems}
        setJustifyItems={setJustifyItems}
        justifyContent={justifyContent}
        setJustifyContent={setJustifyContent}
        borderColor={borderColor}
        setbordercolor={setbordercolor}
        defaultStyleFromLoca={defaultStyleFromLoca}
      ></ExpandableStyleView>
    </ThemeProvider>
    {error && <ErrorBanner />}
    </>
  );
};

export default EditControlsStyles;

