import {
  Card,
  CardContent,
  Box,
  Button,
  CardActions,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import { Star, StarBorder } from "@mui/icons-material";
import themeProvider from "../ThemeProvider";
import Tooltip from "@mui/material/Tooltip";

export default function MenuCardComponent({
  data,
  size,
  handleDetails,
  handleEditDiagram,
  isAddCard,
  editTemplate,
  caller,
  handleToggleFavorite,
  handleToggleProjectActivity,
  handleToggleTemplateActivity,
  firstButtonTitle,
  firstButtonToolTip,
  secondButtonTitle,
  secondButtonToolTip,
}) {
  const isTemplate = data?.Bid === 0;
  let isInactive = data?.isInactive === 1 || data?.isInactive;
  if (isInactive) {
    console.log("data ", data);
  }
  let isFav = data?.isFav === 1;
  const [isExpanded, setIsExpanded] = useState(false);
  const handleFavoriteClick = () => {
    handleToggleFavorite(data?.Bid, isFav ? 0 : 1);
  };

  // const formatDate = (dateString) => {
  //   if (!dateString) return "Unknown date";
  //   const date = new Date(dateString);
  //   return date.toLocaleDateString("en-US", {
  //     year: "numeric",
  //     month: "long",
  //     day: "numeric",
  //   });
  // };
  const formatDate = (dateString) => {
    if (!dateString) return "Unknown date";
    const dateObj = new Date(dateString);

    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  const renderActionButton = (text, onClick, variant, tooltip) => (
    <Tooltip title={tooltip}>
      <Button
        sx={{
          fontSize: "1rem",
          width: "50%",
          height: "85%",
          borderRadius: "30px",
          textTransform: "none",
          fontFamily: "Arial",
        }}
        color="primary"
        variant={variant ?? "outlined"}
        onClick={onClick}
      >
        {text}
      </Button>
    </Tooltip>
  );
  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleActivity = () => {
    if (handleToggleProjectActivity) {
      handleToggleProjectActivity(data?.Bid);
    }
  };
  return (
    <Card
      className="card-data"
      sx={{
        position: "relative", // Ensure the card is positioned for the pseudo-element
        width: `${size}px`,
        height: "auto",
        maxHeight: "230px",
        marginBottom: "1rem",
        marginRight: "1rem",
        display: "flex",
        flexDirection: "column",
        transition: "box-shadow 0.3s",
        "&:hover": {
          boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
        },
        "&::before": isInactive
          ? {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "6px",
              height: "100%", // Height of the red bar
              backgroundColor: "#fca09d", // Red color
              borderRadius: "4px 4px 0 0", // Optional: Rounded corners at the top
            }
          : {},
      }}
    >
      <CardContent
        sx={{ height: isAddCard ? "100%" : "auto" }}
        className="card-content-dt"
      >
        {isAddCard ? (
          <Tooltip title={"Create a new template"}>
            <Box
              sx={{
                height: "150px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={handleEditDiagram}
            >
              <AddIcon
                fontSize="large"
                color="primary"
                sx={{ fontSize: 100 }}
              />
            </Box>
          </Tooltip>
        ) : (
          <>
            {/* icons */}
            <div className="header-top-title">
              {/* Header Section */}
              <Box
                sx={{
                  display: "flex",
                  // justifyContent: "space-between",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: "8px",
                }}
              >
                {data?.mediaURL && (
                  <img
                    src={data.mediaURL}
                    alt={data?.name}
                    style={{
                      width: "2rem",
                      height: "2rem",
                      marginRight: "0.5rem",
                    }}
                  />
                )}
                <div
                  className="title-flow"
                  style={{ fontSize: "16px", color: "#333", fontWeight: "600" }}
                  data-full-text={data?.title || data?.name}
                  title={data?.Bid === 0 ? data?.name : data?.title}
                >
                  {data?.Bid === 0 ? data?.name : data?.title}
                </div>
              </Box>
              <div sx={{ position: "absolute", top: 0, right: 8 }}>
                {!isTemplate ? (
                  <>
                    {isFav && !isInactive ? (
                      <Tooltip title="Remove from favorites">
                        <Star
                          fontSize="medium"
                          sx={{
                            color: themeProvider.palette.fav.main,
                            cursor: "pointer",
                          }}
                          onClick={handleFavoriteClick}
                        />
                      </Tooltip>
                    ) : (
                      !isFav &&
                      !isInactive && (
                        <Tooltip title="Add to favorites">
                          <StarBorder
                            fontSize="medium"
                            sx={{
                              color: "gray",
                              cursor: "pointer",
                              "&:hover": {
                                color: themeProvider.palette.fav.main,
                              },
                            }}
                            onClick={handleFavoriteClick}
                          />
                        </Tooltip>
                      )
                    )}
                    {isInactive ? (
                      <Tooltip title="Make project active">
                        <SettingsBackupRestoreIcon
                          className="close-icon"
                          fontSize="small"
                          sx={{
                            color: "gray",
                            cursor: "pointer",
                            marginRight: "0.5rem",
                          }}
                          onClick={toggleActivity}
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Make project inactive">
                        <CloseIcon
                          className="close-icon"
                          fontSize="medium"
                          sx={{
                            color: "gray",
                            cursor: "pointer",
                            marginRight: "0.5rem",
                          }}
                          onClick={toggleActivity}
                        />
                      </Tooltip>
                    )}
                  </>
                ) : (
                  <span className="icon-for-edit edit-flow">
                    <Tooltip title="Edit tools selection in template">
                      <EditIcon
                        onClick={() =>
                          editTemplate?.(
                            data?.diagTempID,
                            data?.title ?? data?.name
                          )
                        }
                        sx={{ cursor: "pointer" }}
                      />
                    </Tooltip>
                    {isInactive && handleToggleTemplateActivity ? (
                      <Tooltip title="Make template active">
                        <SettingsBackupRestoreIcon
                          onMouseOver={(e) =>
                            (e.currentTarget.style.color = "red")
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.color = "gray")
                          }
                          fontSize="small"
                          sx={{
                            color: "gray",
                            cursor: "pointer",
                            marginRight: "0.5rem",
                            "&:hover": {
                              color: "red",
                            },
                          }}
                          onClick={() =>
                            handleToggleTemplateActivity(data?.diagTempID)
                          }
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Make template inactive">
                        <CloseIcon
                          onMouseOver={(e) =>
                            (e.currentTarget.style.color = "red")
                          }
                          onMouseLeave={(e) =>
                            (e.currentTarget.style.color = "gray")
                          }
                          className="close-icon"
                          fontSize="small"
                          sx={{
                            color: "gray",
                            cursor: "pointer",
                            marginRight: "0.5rem",
                            "&:hover": {
                              color: "red",
                            },
                          }}
                          onClick={() =>
                            handleToggleTemplateActivity(data?.diagTempID)
                          }
                        />
                      </Tooltip>
                    )}
                  </span>
                )}
              </div>
              {/* Header Section */}
              {/* <Box
              sx={{
                display: "flex",
                // justifyContent: "space-between",
                flexDirection: "row",
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              {data?.mediaURL && (
                <img
                  src={data.mediaURL}
                  alt={data?.name}
                  style={{
                    width: "2rem",
                    height: "2rem",
                    marginRight: "0.5rem",
                  }}
                />
              )}
              <div
                // variant="h6"
                sx={{ fontWeight: "bold", fontSize: "18px", color: "#1976d2" }}
              >
                {data?.title || data?.name}
              </div>
            </Box> */}
            </div>
            {/* Description Section */}
            {/* <Box sx={{ marginBottom: "8px" }}>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "14px",
                  color: "gray",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {data?.explanation || "No description available."}
              </Typography>
            </Box> */}
            <Box sx={{ marginBottom: "8px" }}>
              <div className="card-read-propty">
                <span
                  style={{
                    fontSize: "12px",
                    textAlign: "justify",
                    padding: "5px 10px 0px 0px",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: isExpanded ? "unset" : 4,
                    overflow: "hidden",
                    lineHeight: "15px",
                    height: isExpanded ? "auto" : "62px",
                    cursor: "pointer",
                  }}
                >
                  {data?.explanation || "No description available."}
                </span>
                {(data?.explanation?.length || 0) > 150 && (
                  <span
                    onClick={toggleExpanded}
                    style={{
                      color: "#007BFF",
                      cursor: "pointer",
                      marginLeft: "5px",
                      display: "inline-block",
                    }}
                  >
                    {isExpanded ? "Less" : "More"}
                  </span>
                )}
              </div>
            </Box>

            {/* Metadata Section */}
            {data?.Bid !== 0 && (
              <Box
                sx={{ fontSize: "12px", color: "gray", marginBottom: "0px" }}
              >
                <Typography variant="caption">Template {data?.name}</Typography>
              </Box>
            )}
            <Box sx={{ fontSize: "12px", color: "gray", marginBottom: "0px" }}>
              <Typography variant="caption">
                Modified by {data?.modifiedBy || "Unknown User"} on{" "}
                {formatDate(data?.modifiedDate)}
              </Typography>
            </Box>
          </>
        )}
      </CardContent>
      {!isAddCard && (
        <CardActions
          sx={{
            marginTop: "auto",
            display: "flex",
            justifyContent: "center",
            padding: "0px 8px 8px 8px",
          }}
        >
          {caller === 1 && isTemplate ? (
            renderActionButton(
              firstButtonTitle ?? "Summary",
              () => handleDetails(data),
              "outlined",
              firstButtonToolTip
            )
          ) : (
            <>
              {renderActionButton(
                firstButtonTitle ?? "Overview",
                () => handleDetails(data),
                "outlined",
                firstButtonToolTip
              )}
              {renderActionButton(
                secondButtonTitle ?? "Edit",
                () => handleEditDiagram(data),
                "contained",
                secondButtonToolTip
              )}
            </>
          )}
        </CardActions>
      )}
    </Card>
  );
}
