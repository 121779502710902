import React from "react";
import themeProvider from "../ThemeProvider";

const LoadingSpinner = () => {
  return (
    <div style={styles.container}>
      <div style={styles.spinner}></div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "160px",
  },
  spinner: {
    width: "48px",
    height: "48px",
    border: "4px solid #f3f3f3",
    borderTop: `4px solid ${themeProvider.palette.primary.main}`,
    borderRadius: "50%",
    animation: "spin 1s linear infinite",
    position: "relative",
  },
  "@keyframes spin": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
};

// Add the keyframe animation to the document
const styleSheet = document.createElement("style");
styleSheet.textContent = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
document.head.appendChild(styleSheet);

export default LoadingSpinner;
