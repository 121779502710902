import React, { useState, useEffect, useMemo } from "react";
import { Box, Button, Typography } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import themeProvider from "../../ThemeProvider";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import NavBar from "../../Components/navbarComponents/NavBar";
import { getFormattedLocalDateTime } from "../../functions/ModifiedDate";
import LoadingSpinner from "../../Components/LoadingSpinner";

import {
  UpdateDiagTools,
  MakeToolInactive,
  UpdateTemplateDetails,
  UnselectToolInTemplate,
  UpdateToolProperties,
  GetToolPropertiesAll,
  GetDiagTools,
  postResource,
  createRequestBody,
  loadLoginData,
} from "../../apiCalls/DiagramsAPI";

import {
  DataGrid,
  GridActionsCellItem,
  GridRowModes,
  useGridApiRef,
} from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CustomPopUpForm from "../../layout/CustomPopUpForm";
import { EditToolbar } from "../../Components/diagramsComponents/EditToolbar";
import { ToastContainer, toast } from "react-toastify";
import { Theme } from "../../Components/IncludeFile/Theme";
import { Checkbox } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import "../../styles.css";
import { useAppContext } from "../../Components/diagramsComponents/RegionContext";
import EditGridRow from "../../Components/NodeSelectorComponent/EditGridRow";

const NodeSelector = () => {
  const navigate = useNavigate();
  const location = useLocation();
  //When called from EditTemplatePage
  const { DiagTempID, DiagTempTitle } = location.state || {};
  const [tools, setTools] = useState([]);
  const [originalTools, setOriginalTools] = useState([]);
  const [templateTools, setTemplateTools] = useState([]);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [deletePopup, setDeletePopup] = useState(false);
  const [deleteID, setDeleteID] = useState();
  const [fetched, setFetched] = useState(false);
  const [tag, setTag] = useState("");
  const [infoPopup, setInfoPopup] = useState(false);
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [showSelectedOnly, setShowSelectedOnly] = React.useState(false);
  const [newProperties, setNewProperties] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [gridRows, setGridRows] = useState([]);

  const authData = loadLoginData();
  const accessKey = authData?.accessKey || "";
  const { getRegions, getRegionInfo } = useAppContext();

  const fetchRegions = async () => {
    try {
      const storedRegion = localStorage.getItem("region");
      const info = await getRegionInfo(storedRegion);
      const response = await getRegions();
      if (response !== null && response !== undefined) {
        setRegions(response);
      }
      if (info !== null && info !== undefined) {
        const toolJSON = JSON.parse(info.ToolsJson);
        setNewProperties(toolJSON);
        return true;
      }
    } catch (error) {
      console.error("Error fetching regions:", error);
      return false;
    }
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const result = await fetchRegions();
      setFetched(result);
    };

    if (!newProperties) {
      fetchData();
    }
  }, [newProperties]);
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const preSelectedRegion = localStorage.getItem("region");
        const fetchedTools = await postResource(
          "GetQuickInfo",
          createRequestBody({
            accessKey: accessKey,
            guid: `${window.APIProvider.recordPath.ListDiagTools}`,
            param1: preSelectedRegion.toString(),
          })
        );
        console.log("All Tools ", fetchedTools);
        setTools(fetchedTools);
        setOriginalTools(fetchedTools);
        let fetchedTemplateTools;
        if (DiagTempID) {
          fetchedTemplateTools = await postResource(
            "GetQuickInfo",
            createRequestBody({
              accessKey: accessKey,
              guid: `${window.APIProvider.recordPath.ListDiagToolsByTemplate}`,
              param1: DiagTempID.toString(),
            })
          );
          setTemplateTools(fetchedTemplateTools);
          console.log("fetchedTemplateTools ", fetchedTemplateTools);
          console.log(
            "fetchedTemplateTools MAP ",
            fetchedTemplateTools?.map((item) => item.tasktypeid)
          );
          setSelectedNodes(
            fetchedTemplateTools?.map((item) => item.tasktypeid) || []
          );
          console.log("Template Tools ", fetchedTemplateTools);
        }
        if (fetchedTemplateTools) {
          const newFetchedTools = fetchedTools.map((tool) => ({
            ...tool,
            seq:
              fetchedTemplateTools.find((t) => t.tasktypeid === tool.tasktypeid)
                ?.seq || tool.seq,
          }));
          setTools(newFetchedTools);
        }
        let rows = fetchedTools.map((tool) => ({
          id: tool.tasktypeid,
          name: tool.execname,
          title: tool.title,
          notes: tool.notes,
          caption: tool.caption,
          description: tool.description,
          region: tool.region,
          toolTags: tool.tooltags,
          // seq: DiagTempID
          //   ? fetchedTemplateTools.find(
          //       (templateTool) => templateTool.tasktypeid === tool.tasktypeid
          //     )?.seq
          //   : tool.seq,
          seq: tool.seq,
          Preview: tool.tasktypeid,
        }));
        console.log("rows ", rows);
        setGridRows(rows);
      } catch (error) {
        console.error("Fetch Error ", error);
        toast.error("Error fetching data: " + error.message);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, [DiagTempID]);
  //commented on 02-01-2025 templatetools is not saving the data on first render
  // useEffect(() => {
  //   async function fetchData() {
  //     setIsLoading(true);
  //     let fetchedData = null;
  //     try {
  //       const preSelectedRegion = localStorage.getItem("region");
  //       const tools = await postResource(
  //         "GetQuickInfo",
  //         createRequestBody({
  //           accessKey: accessKey,
  //           guid: `${window.APIProvider.recordPath.ListDiagTools}`,
  //           param1: preSelectedRegion.toString(),
  //         })
  //       );
  //       setTools(tools);
  //       // setSelectedNodes(tools?.map((item) => item.diagtoolid) || []);

  //       if (DiagTempID) {
  //         fetchedData = await postResource(
  //           "GetQuickInfo",
  //           createRequestBody({
  //             accessKey: accessKey,
  //             guid: `${window.APIProvider.recordPath.ListDiagToolsByTemplate}`,
  //             param1: DiagTempID.toString(),
  //           })
  //         );
  //         setTemplateTools(fetchedData);
  //         //selectedNodes is an array that only has the ids
  //         setSelectedNodes(fetchedData?.map((item) => item.diagtoolid) || []);
  //       }

  //       setFetched(true);
  //     } catch (error) {
  //       console.error("Fetch Error ", error);
  //       toast.error("Error fetching data: " + error.message);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   }
  //   if (!fetched) {
  //     fetchData();
  //   }
  // }, [fetched]);

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  React.useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  async function saveData(newTool, oldTool) {
    console.log("newTool ", newTool);
    console.log("oldTool ", oldTool);
    newTool.seq = parseInt(newTool.seq);
    const userName = authData?.userName || "Unknown User";
    const todayDate = getFormattedLocalDateTime();

    const isOnlySeqChanged =
      newTool.seq !== oldTool.seq &&
      Object.keys(newTool).every(
        (key) =>
          key === "seq" ||
          JSON.stringify(newTool[key]) === JSON.stringify(oldTool[key])
      );

    let toolToSave;
    let guid;
    let response1;
    let response2;

    if (DiagTempID && newTool.seq.toString() !== oldTool.seq.toString()) {
      toolToSave = {
        DiagTempID: DiagTempID,
        DiagToolID: newTool.tasktypeid,
        Seq: newTool.seq,
        createdBy: newTool.createdby || userName,
        createdDate: newTool.createddate || todayDate,
        modifiedBy: userName,
        modifiedDate: todayDate,
      };
      guid = `${window.APIProvider.recordPath.UpsertQinfoDiagTemplateDetails}`;
      console.log("toolToSave: ", toolToSave);
      try {
        response1 = await postResource(
          "PostQuickInfo",
          createRequestBody({
            accessKey,
            guid,
            param1: DiagTempID?.toString(),
            body: JSON.stringify(toolToSave),
          })
        );
      } catch (error) {
        console.error(`Error saving data: ${error.message}`);
      }
    }
    if (DiagTempID === undefined || DiagTempID === null || !isOnlySeqChanged) {
      console.log("originalTools ", originalTools);
      const newToolInOriginal = originalTools.find(
        (tool) => tool.tasktypeid === newTool.tasktypeid
      );
      console.log("newToolInOriginal ", newToolInOriginal);
      toolToSave = {
        ...newTool,
        DseqID:
          DiagTempID !== undefined && DiagTempID !== null
            ? newToolInOriginal.seq
            : newTool.seq,
        modifiedBy: userName,
        modifiedDate: todayDate,
        createdBy: newTool.createdby || userName,
        createdDate: newTool.createddate || todayDate,
      };

      delete toolToSave.seq;
      delete toolToSave.modifiedby;
      delete toolToSave.modifieddate;
      delete toolToSave.createdby;
      delete toolToSave.createddate;

      guid = `${window.APIProvider.recordPath.UpsertDiagTools}`;
      console.log("toolToSave: ", toolToSave);
      try {
        response2 = await postResource(
          "PostQuickInfo",
          createRequestBody({
            accessKey,
            guid,
            body: JSON.stringify(toolToSave),
          })
        );
      } catch (error) {
        console.error(`Error saving data: ${error.message}`);
      }
    }

    if (response1?.length <= 1) {
      toast.error(`Error saving data: ${response1.Message || "Unknown error"}`);
    } else if (response2?.Status === "ERROR") {
      toast.error(`Error saving data: ${response2.Message || "Unknown error"}`);
    } else {
      let newRow = {
        id: newTool.tasktypeid,
        name: newTool.execname,
        title: newTool.title,
        notes: newTool.notes,
        caption: newTool.caption,
        description: newTool.description,
        region: newTool.region,
        toolTags: newTool.tooltags,
        seq: newTool.seq,
        Preview: newTool.tasktypeid,
      };
      setGridRows((prevRows) => {
        // Update the specific row or add it to the rows
        const updatedRows = prevRows.map((row) =>
          row.id === newTool.tasktypeid ? { ...row, ...newRow } : row
        );
        return updatedRows;
      });
      // Update tools array
      setTools((prevTools) => {
        return prevTools.map((tool) =>
          tool.tasktypeid === newTool.tasktypeid
            ? { ...tool, ...newTool }
            : tool
        );
      });
      toast.success("Saved successfully!");
    }
  }

  useEffect(() => {
    console.log("gridRows changed:", gridRows);
  }, [gridRows]);

  const [rowModesModel, setRowModesModel] = useState({});
  const [openEditRow, setOpenEditRow] = useState(false);
  const [rowData, setRowData] = useState({});

  const handleEdit = (id) => () => {
    setOpenEditRow(true);
    let tool = tools.find((tool) => tool.tasktypeid === id);
    console.log("templateTools ", templateTools);
    console.log("gridRows ", gridRows);
    console.log("tool.seq ", tool.seq);
    let newRow = {
      id: tool.tasktypeid,
      name: tool.name,
      title: tool.title,
      notes: tool.notes,
      caption: tool.caption,
      description: tool.description,
      region: tool.region,
      toolTags: tool.tooltags,
      seq: DiagTempID
        ? templateTools?.find(
            (templateTool) => templateTool.tasktypeid === tool.tasktypeid
          )?.seq
        : tool.seq,
      Preview: tool.tasktypeid,
    };
    console.log("newRow ", newRow);
    setRowData(tool);
  };

  const handleNavigation = (path, propValue) => {
    // Filter the tools based on selected tools
    const filteredTools = tools.filter((tool) =>
      propValue.includes(tool.tasktypeid)
    );
    navigate(path, { state: { prop: propValue, tools: filteredTools } });
  };

  const handleEditToolsProperties = async (buttonType) => {
    try {
      console.log("DiagTempID ", DiagTempID);
      if (DiagTempID !== null && DiagTempID !== undefined) {
        const userName = authData?.userName || "Unknown User";
        const todayDate = getFormattedLocalDateTime();
        const newlySelectedNodes = selectedNodes
          .filter(
            (tasktypeid) =>
              !templateTools?.some((tool) => tool.tasktypeid === tasktypeid)
          )
          .map((tasktypeid) => {
            const matchingTool = templateTools?.find(
              (tool) => tool.tasktypeid === tasktypeid
            );
            console.log("matchingTool ", matchingTool);
            return {
              DiagTempID: parseInt(DiagTempID),
              tasktypeid: parseInt(tasktypeid),
              Seq: parseInt(matchingTool?.seq || 0),
              CreatedBy: matchingTool?.createdby ?? userName,
              CreatedDate: matchingTool?.createddate ?? todayDate,
              ModifiedBy: userName,
              ModifiedDate: todayDate,
            };
          });

        console.log("templateTools ", templateTools);
        console.log("selectedNodes ", selectedNodes);
        // Deselected tools (in templateTools but not in selectedNodes)
        const deselectedNodes = (templateTools || [])
          .filter(
            (tool) =>
              tool.detdiagtempid !== "0" &&
              !selectedNodes.includes(tool.tasktypeid)
          )
          .map((tool) => tool.detdiagtempid);

        console.log("newlySelectedNodes ", newlySelectedNodes);
        console.log("deselectedNodes ", deselectedNodes);
        let templateDetailsResponse;
        let postRequests;
        let results;
        console.log("newlySelectedNodes ", newlySelectedNodes);
        console.log("deselectedNodes ", deselectedNodes);
        if (newlySelectedNodes.length > 0) {
          templateDetailsResponse = await postResource(
            "PostQuickInfo",
            createRequestBody({
              accessKey: accessKey,
              guid: `${window.APIProvider.recordPath.UpsertQinfoDiagTemplateDetails}`,
              param1: DiagTempID?.toString(),
              body: JSON.stringify(newlySelectedNodes),
            })
          );
        }
        if (deselectedNodes.length > 0) {
          // Use Promise.all to post each deselected node
          postRequests = deselectedNodes.map((deselectednode) => {
            return postResource(
              "GetQuickInfo",
              createRequestBody({
                accessKey: accessKey,
                guid: `${window.APIProvider.recordPath.DeleteDiagToolxReferenceTemplate}`,
                param1: deselectednode.toString(),
              })
            );
          });

          // Await all requests to complete
          results = await Promise.all(postRequests);
          console.log("results ", results);
        }
        if (templateDetailsResponse?.Status === "ERROR") {
          toast.error(
            `Error saving data: ${templateDetailsResponse.Message || "Unknown error"}`
          );
        } else if (postRequests?.Status === "ERROR") {
          toast.error(
            `Error saving data: ${postRequests.Message || "Unknown error"}`
          );
        } else {
          toast.success("Saved successfully!");
        }
      }
      console.log("NAVIGATION");
      handleNavigation(`/${buttonType}`, selectedNodes);
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  };

  const handleToggleShowSelectedOnly = () => {
    setShowSelectedOnly(!showSelectedOnly);
  };

  const handleInfoPopup = () => {
    setInfoPopup(!infoPopup);
  };
  const leftButton = {
    icon: <CheckBoxIcon />,
    text: showSelectedOnly ? "Show All" : "Show Selected Only",
    handleClick: () => {
      // Handle click for the left button
      handleToggleShowSelectedOnly();
    },
  };

  const rightButtons = [
    {
      icon: Theme.GoBack,
      text: "Back",
      color: "secondary",
      tooltip:"Go back to previous page",
      handleClick: () => {
        navigate(-1);
      },
    },
  ];

  const editButton = [
    {
      icon: Theme.Edit,
      text: "Edit Tools Properties",
      color: selectedNodes.length === 0 ? "grey" : "secondary",
      handleClick: () => {
        handleEditToolsProperties("ToolProperties");
      },
      disabled: selectedNodes.length === 0,
      sx:
        selectedNodes.length === 0
          ? { backgroundColor: "grey !important", color: "white" }
          : undefined,
    },
  ];

  const IconElement = React.memo(
    ({ iconSize, displayBootstrapIcon, displayUrlIcon, IconLink }) => (
      <>
        {displayBootstrapIcon === "flex" && (
          <i
            className={`glyphicon ${IconLink}`}
            style={{ fontSize: iconSize, display: displayBootstrapIcon }}
          />
        )}
        {displayUrlIcon === "flex" && IconLink && (
          <img
            src={IconLink}
            alt="icon"
            style={{
              width: iconSize,
              height: iconSize,
              display: displayUrlIcon,
            }}
          />
        )}
      </>
    )
  );

  const renderToolPreview = (params) => {
    if (!tools || tools.length === 0) {
      return <div>Loading tools...</div>;
    }

    const matchingTool = tools.find((tool) => tool.tasktypeid === params.value);

    if (!newProperties) {
      return <div>Loading Preview Image...</div>;
    }

    let captionStyles;
    if (matchingTool) {
      const nodeData =
        newProperties?.Nodes.find(
          (node) => node.id.toString() === matchingTool?.tasktypeid?.toString()
        ) || null;

      const categoryProperties =
        nodeData?.customstyle ??
        newProperties[matchingTool.category || "<all>" || ""];
      if (categoryProperties) {
        captionStyles = categoryProperties.caption;
        if (captionStyles) {
          Object.entries(captionStyles).forEach(([key, value]) => {
            try {
              captionStyles[key] = value;
            } catch (e) {
              console.error("Error parsing value for", key, e);
            }
          });
        }
      } else {
        console.log(
          `No properties found for category: ${matchingTool.category}`
        );
      }
    } else {
      console.log("No tool found with the provided id.");
      return <div>No matching tool found.</div>;
    }

    const iconProps = {
      iconSize:
        captionStyles?.IconSize === "large"
          ? "48px"
          : captionStyles?.IconSize === "medium"
            ? "36px"
            : "24px",
      displayBootstrapIcon:
        captionStyles?.IconType === "Bootstrap" ? "flex" : "none",
      displayUrlIcon: captionStyles?.IconType === "Url" ? "flex" : "none",
      IconLink: captionStyles?.IconLink,
    };

    return (
      <div
        style={{
          backgroundColor: captionStyles?.IconbackgroundColor,
          margin: captionStyles?.Iconmargin,
          borderRadius: captionStyles?.IconborderRadius,
          padding: captionStyles?.Iconpadding,
        }}
      >
        <IconElement {...iconProps} />
      </div>
    );
  };

  //Columns in table
  const columns = React.useMemo(
    () => [
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        width: 150,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEdit(id)}
              color="inherit"
            />,
          ];
        },
      },
      { field: "name", headerName: "Name", editable: true, flex: 1 },
      {
        field: "title",
        headerName: "Title",
        editable: true,
        flex: 1,
        fontWeight: "bold",
      },
      {
        field: "notes",
        headerName: "Notes",
        editable: true,
        sortable: true,
        flex: 1,
      },
      {
        field: "caption",
        headerName: "Caption",
        editable: true,
        sortable: true,
        flex: 1,
      },
      {
        field: "description",
        headerName: "Description",
        editable: true,
        sortable: true,
        flex: 1,
      },
      {
        field: "region",
        headerName: "Region",
        editable: true,
        sortable: true,
        flex: 1,
      },
      {
        field: "toolTags",
        headerName: "Tags",
        editable: true,
        sortable: true,
        flex: 1,
        renderCell: (params) => {
          if (!params.value) {
            return null; // Return null or empty content if params.value is empty
          }

          const values = params.value.split(",").map((value) => value.trim()); // Split by comma and trim whitespace

          return (
            <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
              {values.map((value, index) => {
                return (
                  <Chip
                    key={index}
                    label={value}
                    clickable
                    onClick={() => handleChipClick(value)}
                    variant="outlined"
                    color="secondary"
                    style={{ marginRight: 4 }}
                  />
                );
              })}
            </div>
          );
        },
      },
      {
        field: "seq",
        headerName: "Sequence",
        width: 150,
        editable: true,
        sortable: true,
      },
      {
        field: "Preview",
        headerName: "Preview",
        width: 100,
        // flex: 1,
        editable: false,
        sortable: true,
        renderCell: renderToolPreview,
      },
    ],
    [rowModesModel, tools, newProperties]
  );
  // Function to calculate the row height based on the number of chips
  const getRowHeight = (params) => {
    if (!params.model.toolTags) return 52; // Default row height for empty content

    const values = params.model.toolTags
      .split(",")
      .map((value) => value.trim());

    const baseHeight = 52; // Default row height
    const numberOfTags = values.length;
    // Check screen width
    const isWideScreen = window.innerWidth > 1130;

    // Determine the group size based on screen width
    const groupSize = isWideScreen ? 3 : 2;

    // Calculate extra height
    const extraHeight = Math.floor(numberOfTags / groupSize) * baseHeight;

    return baseHeight + extraHeight;
  };

  //inactive row color
  const getRowClassName = (params) => {
    const tool = tools.find((item) => item.tasktypeid === params.id);
    if (tool && tool.isInactive === 1) {
      return "inactive-row";
    }
    return "";
  };

  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setFilterModel({
      items: [{ field: "name", operator: "contains", value: e.target.value }],
    });
    handleClearTag();
  };
  //tags functions
  const handleChipClick = (clickedTag) => {
    setTag(clickedTag);
    handleTagFilterChange(clickedTag);
  };
  const handleClearTag = () => {
    setTag("");
    setFilterModel({
      items: [{ field: "name", operator: "contains", value: searchQuery }],
    });
  };
  const getApplyQuickFilterFnByTag = (value) => {
    return (cellValue) => {
      if (Array.isArray(cellValue)) {
        return cellValue.includes(value);
      }
      return false;
    };
  };
  const [filterModel, setFilterModel] = React.useState({
    items: [],
  });

  const handleTagFilterChange = (clickedTag) => {
    setFilterModel({
      items: [
        { field: "toolTags", operator: "contains", value: `${clickedTag}` },
      ],
    });
  };

  console.log("selectedNodes ", selectedNodes);

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <ThemeProvider theme={themeProvider}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <NavBar
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        isSearchBar={true}
        rightButtons={rightButtons}
        title={`Choose [${localStorage.getItem("region")}]${DiagTempTitle ? ` [${DiagTempTitle}]` : ""} Tools`}
        isMenuOpenleft={false}
        leftButton={leftButton}
        editButton={editButton}
      />
      <div style={{ height: "94vh", overflowX: "auto" }}>
        <Box
          sx={{
            width: "100%",
            padding: isSmallScreen ? "1rem" : "1rem 2rem",
          }}
        >
          <DataGrid
            rows={
              showSelectedOnly
                ? gridRows.filter((row) => selectedNodes.includes(row.id))
                : gridRows
            }
            columns={columns}
            getRowClassName={getRowClassName} //for coloring inactive rows red
            getRowHeight={getRowHeight} // Dynamically adjust row height
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25]}
            checkboxSelection
            rowSelectionModel={selectedNodes}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setSelectedNodes(newRowSelectionModel);
            }}
            disableRowSelectionOnClick
            sx={{
              "& .MuiDataGrid-root": {
                width: "100%",
              },
              "& .MuiDataGrid-columnHeaderTitleContainer": {
                color: themeProvider.palette.primary.dark,
              },
              ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
                {
                  marginTop: "1em",
                  marginBottom: "1em",
                },
            }}
            slots={{
              toolbar: () =>
                EditToolbar(
                  tag,
                  handleClearTag,
                  "",
                  showSelectedOnly,
                  setShowSelectedOnly
                ),
              //add tooltip to select all checkbox
              baseCheckbox: React.forwardRef((props, ref) => {
                // Check if this is the select all checkbox based on aria-label
                if (props.inputProps?.["aria-label"] === "Select all rows") {
                  return (
                    <Tooltip title="Select all rows" placement="top" arrow>
                      <Checkbox ref={ref} {...props} />
                    </Tooltip>
                  );
                } else if (
                  props.inputProps?.["aria-label"] === "Unselect all rows"
                ) {
                  return (
                    <Tooltip title="Unselect all rows" placement="top" arrow>
                      <Checkbox ref={ref} {...props} />
                    </Tooltip>
                  );
                }
                // For other checkboxes, return default Checkbox
                return <Checkbox ref={ref} {...props} />;
              }),
            }}
            filterModel={filterModel} //for tags
          />
        </Box>
        <div
          style={{
            margin: "1rem",
            display: "flex",
            justifyContent: isSmallScreen ? "center" : "flex-end",
          }}
        >
          {DiagTempID !== null && DiagTempID !== undefined && (
            <div className="control-property-btn">
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={() => handleEditToolsProperties("Landing")}
                sx={{ textTransform: "none", fontFamily: "Arial" }}
              >
                Save Template's Tools
              </Button>
            </div>
          )}
        </div>
      </div>
      {openEditRow && (
        <EditGridRow
          open={openEditRow}
          handleClose={() => setOpenEditRow(!openEditRow)}
          rowData={rowData}
          regions={regions}
          handleSave={saveData}
        />
      )}
      {infoPopup && (
        <CustomPopUpForm
          open={infoPopup}
          onClose={handleInfoPopup}
          title="Information"
        >
          <Typography>
            This page allows you to choose tools and add/change their
            properties. Choose a number of tools by clicking the checkbox and
            when you are done press "Edit Tools Properties" button at the bottom
            right. If you want to show more or less tools in one page you can do
            so by setting the drop down at the bottom right names "Rows per
            page"
          </Typography>
          <></>
        </CustomPopUpForm>
      )}
    </ThemeProvider>
  );
};

export default NodeSelector;
