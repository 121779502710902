import { createTheme } from "@mui/material/styles";

export const themeProvider = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 830,
      lg: 1280,
      xl: 1920,
    },
  },

  palette: {
    mode: "light",
    primary: {
      main: window.palette.primary.main,
      light: window.palette.primary.light,
      dark: window.palette.primary.dark,
      contrastText: window.palette.primary.contrastText,
    },
    secondary: {
      main: window.palette.secondary.main,
      light: window.palette.secondary.light,
      dark: window.palette.secondary.dark,
      contrastText: window.palette.secondary.contrastText,
    },
    grey: {
      main: window.palette.grey.main,
    },
    background: {
      default: window.palette.background.default,
    },
    landingBg: {
      light: window.palette.landingBg.light,
      dark: window.palette.landingBg.dark,
      copyright: window.palette.landingBg.copyright,
      bannerText: window.palette.landingBg.bannerText,
      navBar: window.palette.landingBg.navBar,
    },
    bg: {
      lightWhite: window.palette.bg.lightWhite,
      lightGray: window.palette.bg.lightGray,
    },
    fav: {
      main: window.palette.fav.main,
    },
    alert: {
      success: window.palette.alert.success,
      successProgressBar: window.palette.alert.successProgressBar,
      info: window.palette.alert.info,
      infoProgressBar: window.palette.alert.infoProgressBar,
      error: window.palette.alert.error,
      errorProgressBar: window.palette.alert.errorProgressBar,
      warning: window.palette.alert.warning,
      warningProgressBar: window.palette.alert.warningProgressBar,
    },
  },
});

export default themeProvider;
