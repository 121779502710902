const Nodes = [
    {
        "diagtoolid": 41,
        "name": "Add To File",
        "title": "Add To File",
        "explain": "Add Prompts with Variables to File",
        "notes": "New Note",
        "caption": "Add To File",
        "description": "Add Prompts with Variables to File",
        "source": "AI UI",
        "tasktypeid": 30,
        "isInactive": 0,
        "category": "File",
        "params": [
            {
                "taskParamID": 9,
                "direction": 0,
                "paramName": "Output ",
                "paramExplanation": "Output FileName or {recordset}",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            },
            {
                "taskParamID": 10,
                "direction": 0,
                "paramName": "Prompt",
                "paramExplanation": "Prompt",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            }
        ],
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 124,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#d0e6fb"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/MotzIjQ.png"
            }
        ]
    },
    {
        "diagtoolid": 35,
        "name": "AI Branch",
        "notes": "",
        "caption": "AI Branch",
        "source": "AI UI",
        "tooltags": "Simple, ai",
        "category": "Flow",
        "diagTempID": 54,
        "detDiagTempID": 125,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#c4cea1"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/UcvfKZy.png"
            }
        ]
    },
    {
        "diagtoolid": 36,
        "name": "AI End",
        "notes": "",
        "caption": "AI End",
        "source": "AI UI",
        "tooltags": "Simple, ai",
        "category": "Flow",
        "diagTempID": 54,
        "detDiagTempID": 126,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#c4cea1"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/UcvfKZy.png"
            }
        ]
    },
    {
        "diagtoolid": 34,
        "name": "AI Start",
        "notes": "",
        "caption": "AI Start",
        "source": "AI UI",
        "tooltags": "Simple, ai",
        "category": "Flow",
        "diagTempID": 54,
        "detDiagTempID": 128,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#c4cea1"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/UcvfKZy.png"
            }
        ]
    },
    {
        "diagtoolid": 42,
        "name": "Capture Code",
        "title": "Capture Code",
        "explain": "Python: Extract code from response.",
        "notes": "",
        "caption": "Capture Code",
        "description": "Python: Extract code from response.",
        "source": "AI UI",
        "tasktypeid": 31,
        "isInactive": 0,
        "category": "Capture",
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 130,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#e1e5f2"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/tqgksI7.png"
            }
        ]
    },
    {
        "diagtoolid": 43,
        "name": "Capture JSON",
        "title": "Capture JSON",
        "explain": "Build a prompt with mail merge {tablename.fieldName), send prompt",
        "notes": "",
        "caption": "Capture JSON",
        "description": "Build a prompt with mail merge {tablename.fieldName), send prompt",
        "source": "AI UI",
        "tasktypeid": 32,
        "isInactive": 0,
        "category": "Capture",
        "params": [
            {
                "taskParamID": 13,
                "direction": 0,
                "paramName": "Input Key Field 1",
                "paramExplanation": "Input Key Field 1",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            },
            {
                "taskParamID": 14,
                "direction": 0,
                "paramName": "Input Key Field 2",
                "paramExplanation": "Input Key Field 2",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            },
            {
                "taskParamID": 15,
                "direction": 0,
                "paramName": "Output Key Field 1",
                "paramExplanation": "Output Key Field 1",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            },
            {
                "taskParamID": 16,
                "direction": 0,
                "paramName": "Output Key Field 2",
                "paramExplanation": "Output Key Field 2",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            },
            {
                "taskParamID": 17,
                "direction": 0,
                "paramName": "Prompt",
                "paramExplanation": "Prompt",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            }
        ],
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 131,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#e1e5f2"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/tqgksI7.png"
            }
        ]
    },
    {
        "diagtoolid": 44,
        "name": "Capture SQL",
        "title": "Capture SQL",
        "explain": "Build a prompt with mail merge {tablename.fieldName), send prompt",
        "notes": "",
        "caption": "Capture SQL",
        "description": "Build a prompt with mail merge {tablename.fieldName), send prompt",
        "source": "AI UI",
        "tasktypeid": 33,
        "isInactive": 0,
        "category": "Capture",
        "params": [
            {
                "taskParamID": 18,
                "direction": 0,
                "paramName": "Prompt",
                "paramExplanation": "Prompt",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            }
        ],
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 132,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#e1e5f2"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/tqgksI7.png"
            }
        ]
    },
    {
        "diagtoolid": 45,
        "name": "Capture TAB",
        "title": "Capture TAB",
        "explain": "Build and send a prompt that result in a tab delimited value",
        "notes": "",
        "caption": "Capture TAB",
        "description": "Build and send a prompt that result in a tab delimited value",
        "source": "AI UI",
        "tasktypeid": 34,
        "isInactive": 0,
        "category": "Capture",
        "params": [
            {
                "taskParamID": 19,
                "direction": 0,
                "paramName": "Input Key Field 1",
                "paramExplanation": "Input Key Field 1",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            },
            {
                "taskParamID": 20,
                "direction": 0,
                "paramName": "Input Key Field 2",
                "paramExplanation": "Input Key Field 2",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            },
            {
                "taskParamID": 21,
                "direction": 0,
                "paramName": "Output Key Field 1",
                "paramExplanation": "Output Key Field 1",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            },
            {
                "taskParamID": 22,
                "direction": 0,
                "paramName": "Output Key Field 2",
                "paramExplanation": "Output Key Field 2",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            },
            {
                "taskParamID": 23,
                "direction": 0,
                "paramName": "Output TableName",
                "paramExplanation": "Output TableName",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            },
            {
                "taskParamID": 24,
                "direction": 0,
                "paramName": "Prompt",
                "paramExplanation": "Prompt",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            }
        ],
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 133,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#e1e5f2"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/tqgksI7.png"
            }
        ]
    },
    {
        "diagtoolid": 54,
        "name": "Close Current Conversation",
        "title": "Close Current Conversation",
        "explain": "Python: Use this when Master Loop is not used. Update the number of UsedTokems in the chat header and resets the uploaded files.",
        "notes": "",
        "caption": "Close Current Conversation",
        "description": "Python: Use this when Master Loop is not used. Update the number of UsedTokems in the chat header and resets the uploaded files.",
        "source": "AI UI",
        "tasktypeid": 47,
        "isInactive": 0,
        "category": "AI",
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 134,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#ebdede"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/BzlPsH5.png"
            }
        ]
    },
    {
        "diagtoolid": 46,
        "name": "Close Loop",
        "title": "Close Loop",
        "explain": "Python: Close Last inner loop. Update the number of UsedTokems in the chat header and resets the uploaded files.",
        "notes": "",
        "caption": "Close Loop",
        "description": "Python: Close Last inner loop. Update the number of UsedTokems in the chat header and resets the uploaded files.",
        "source": "AI UI",
        "tasktypeid": 35,
        "isInactive": 0,
        "category": "Loop",
        "nodeType": 6,
        "diagTempID": 54,
        "detDiagTempID": 135,
        "seq": 99,
        "style": [
            {
                "DiagToolID": 46,
                "propertyName": "Node_Background",
                "diagPropvalue": "#b191ee"
            },
            {
                "DiagToolID": 46,
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "DiagToolID": 46,
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "DiagToolID": 46,
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/1VQKKZ5.png"
            }
        ]
    },
    {
        "diagtoolid": 68,
        "name": "Consolidate Content",
        "title": "Consolidate Content",
        "explain": "Python: Gets the project id and then returns all the content details of that project in 1 variable. Then this can be used in when sending a prompt as such : {ContentDetails}",
        "notes": "",
        "caption": "Consolidate Content",
        "description": "Python: Gets the project id and then returns all the content details of that project in 1 variable. Then this can be used in when sending a prompt as such : {ContentDetails}",
        "source": "AI UI",
        "tasktypeid": 62,
        "isInactive": 0,
        "category": "Content",
        "params": [
            {
                "taskParamID": 52,
                "direction": 0,
                "paramName": "Project ID",
                "paramExplanation": "Project ID. can also be set to {ProcessedQuery}. if set to {ProcessedQuery} makesure to add ProjectID in the select statement.",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            }
        ],
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 136,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#b5b6b3"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/TU9hUF1.png"
            }
        ]
    },
    {
        "diagtoolid": 39,
        "name": "Convert",
        "title": "Convert",
        "explain": "Get PDF from Library, split into pages, convert into text and send each page at a time and get outcome by page and combined summary for document - store summary outcome into qinfolibdetails.summary",
        "notes": "",
        "caption": "Convert",
        "description": "Get PDF from Library, split into pages, convert into text and send each page at a time and get outcome by page and combined summary for document - store summary outcome into qinfolibdetails.summary",
        "source": "AI UI",
        "tasktypeid": 7,
        "isInactive": 0,
        "category": "Process",
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 137,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#b2f6b6"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/3quBivE.png"
            }
        ]
    },
    {
        "diagtoolid": 47,
        "name": "Execute Workflow",
        "title": "Execute Workflow",
        "explain": "Python: Execute object when done",
        "notes": "",
        "caption": "Execute Workflow",
        "description": "Python: Execute object when done",
        "source": "AI UI",
        "tasktypeid": 36,
        "isInactive": 0,
        "category": "Process",
        "params": [
            {
                "taskParamID": 25,
                "direction": 0,
                "paramName": "Qinfo Object ID",
                "paramExplanation": "Id of the object that will be executed",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            }
        ],
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 138,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#b2f6b6"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/3quBivE.png"
            }
        ]
    },
    {
        "diagtoolid": 67,
        "name": "Extract By Rules",
        "title": "Extract By Rules",
        "explain": "Python: Takes a pdf file from a previous actions (like OCR or File Extract), Get the rules from the table FeRules (pages, reges and exact), and then according to the rules, the \"RuleID, PageX, PageY, isExact, isRegex, isPage\" fields are updated in QuickInfoFileExtract for each page of the pdf.",
        "notes": "",
        "caption": "Extract By Rules",
        "description": "Python: Takes a pdf file from a previous actions (like OCR or File Extract), Get the rules from the table FeRules (pages, reges and exact), and then according to the rules, the \"RuleID, PageX, PageY, isExact, isRegex, isPage\" fields are updated in QuickInfoFileExtract for each page of the pdf.",
        "source": "AI UI",
        "tasktypeid": 61,
        "isInactive": 0,
        "category": "Content",
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 139,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#b5b6b3"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/TU9hUF1.png"
            }
        ]
    },
    {
        "diagtoolid": 66,
        "name": "Extract Elements",
        "title": "Extract Elements",
        "explain": "Python: Takes a pdf file from a previous actions (like OCR or File Extract) and Extracts text elements font, size and location from the pdf and puts it in QuickInfoFileExtractelements.",
        "notes": "",
        "caption": "Extract Elements",
        "description": "Python: Takes a pdf file from a previous actions (like OCR or File Extract) and Extracts text elements font, size and location from the pdf and puts it in QuickInfoFileExtractelements.",
        "source": "AI UI",
        "tasktypeid": 60,
        "isInactive": 0,
        "category": "Content",
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 140,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#b5b6b3"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/TU9hUF1.png"
            }
        ]
    },
    {
        "diagtoolid": 69,
        "name": "File Extract",
        "title": "File Extract",
        "explain": "Python: Transform PDF to image and then OCR",
        "notes": "",
        "caption": "File Extract",
        "description": "Python: Transform PDF to image and then OCR",
        "source": "Ai UI",
        "tasktypeid": 63,
        "isInactive": 0,
        "category": "File",
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 162,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#d0e6fb"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/MotzIjQ.png"
            }
        ]
    },
    {
        "diagtoolid": 65,
        "name": "Get Dimension",
        "title": "Get Dimension",
        "explain": "Python: Gets max number of x pixels and y pixels of a pdf. this action has to be after a File Extract. it will update the Height and Width column of QuickInfoFileExtract",
        "notes": "",
        "caption": "Get Dimension",
        "description": "Python: Gets max number of x pixels and y pixels of a pdf. this action has to be after a File Extract. it will update the Height and Width column of QuickInfoFileExtract",
        "source": "AI UI",
        "tasktypeid": 59,
        "isInactive": 0,
        "category": "Content",
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 141,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#b5b6b3"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/TU9hUF1.png"
            }
        ]
    },
    {
        "diagtoolid": 48,
        "name": "Init File",
        "title": "Init File",
        "explain": "Check if file exists - clear content if not empty",
        "notes": "",
        "caption": "Init File",
        "description": "Check if file exists - clear content if not empty",
        "source": "AI UI",
        "tasktypeid": 37,
        "isInactive": 0,
        "category": "File",
        "params": [
            {
                "taskParamID": 26,
                "direction": 0,
                "paramName": "Output FileName",
                "paramExplanation": "Output FileName",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            }
        ],
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 142,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#d0e6fb"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/MotzIjQ.png"
            }
        ]
    },
    {
        "diagtoolid": 70,
        "name": "Json to Factsheet",
        "title": "Json to Factsheet",
        "notes": "",
        "caption": "Json to Factsheet",
        "source": "Ai UI",
        "tasktypeid": 64,
        "isInactive": 0,
        "category": "Content",
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 163,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#b5b6b3"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/TU9hUF1.png"
            }
        ]
    },
    {
        "diagtoolid": 58,
        "name": "JSON To FileExtract",
        "title": "JSON To FileExtract",
        "explain": "Python: finds all the extracted json from the Action 'Capture Code' in the current chat and inserts the json in QuickInfoFileExtract : insert the path of the elements in 'ExtractReference', and the value in ExtractText",
        "notes": "",
        "caption": "JSON To FileExtract",
        "description": "Python: finds all the extracted json from the Action 'Capture Code' in the current chat and inserts the json in QuickInfoFileExtract : insert the path of the elements in 'ExtractReference', and the value in ExtractText",
        "source": "AI UI",
        "tasktypeid": 52,
        "isInactive": 0,
        "category": "File",
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 143,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#d0e6fb"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/MotzIjQ.png"
            }
        ]
    },
    {
        "diagtoolid": 52,
        "name": "Langchain Text Loader",
        "title": "Langchain Text Loader",
        "explain": "Upload Text file to langchain loader",
        "notes": "",
        "caption": "Langchain Text Loader",
        "description": "Upload Text file to langchain loader",
        "source": "AI UI",
        "tasktypeid": 45,
        "isInactive": 0,
        "category": "AI",
        "params": [
            {
                "taskParamID": 31,
                "direction": 0,
                "paramName": "Text File Name",
                "paramExplanation": "Text File Name",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            }
        ],
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 144,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#ebdede"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/BzlPsH5.png"
            }
        ]
    },
    {
        "diagtoolid": 74,
        "name": "Library Master Loop",
        "title": "Library Master Loop",
        "explain": "Python: Handles looping operations by fetching data from executing a SQL query and defining the name of each column inside the loop",
        "notes": "",
        "caption": "Library Master Loop",
        "description": "Python: Handles looping operations by fetching data from executing a SQL query and defining the name of each column inside the loop",
        "source": "Ai UI",
        "tasktypeid": 68,
        "isInactive": 0,
        "category": "Loop",
        "params": [
            {
                "taskParamID": 54,
                "direction": 0,
                "paramName": "Filter",
                "paramExplanation": "Filter used for the select query exp :\"QLibDetID in (4809)\"",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            }
        ],
        "nodeType": 3,
        "diagTempID": 54,
        "detDiagTempID": 161,
        "seq": 99,
        "style": [
            {
                "DiagToolID": 46,
                "propertyName": "Node_Background",
                "diagPropvalue": "#92b1c3"
            },
            {
                "DiagToolID": 46,
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "DiagToolID": 46,
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "DiagToolID": 46,
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/tfGpyfj.png"
            }
        ]
    },
    {
        "diagtoolid": 37,
        "name": "Loop Dataset",
        "title": "Loop Dataset",
        "explain": "Loop records and send values to chat",
        "notes": "",
        "caption": "Loop Dataset",
        "description": "Loop records and send values to chat",
        "source": "AI UI",
        "tasktypeid": 5,
        "tooltags": "Loop, Ai",
        "isInactive": 0,
        "category": "Loop",
        "params": [
            {
                "taskParamID": 1,
                "direction": 0,
                "paramName": "Fields to be loaded",
                "paramExplanation": "Fields to be loaded",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            },
            {
                "taskParamID": 2,
                "direction": 0,
                "paramName": "Filter to build sql",
                "paramExplanation": "Filter to build sql",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            },
            {
                "taskParamID": 3,
                "direction": 0,
                "paramName": "KeyField",
                "paramExplanation": "KeyField",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            },
            {
                "taskParamID": 4,
                "direction": 0,
                "paramName": "Table Name",
                "paramExplanation": "Table Name",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            }
        ],
        "nodeType": 3,
        "diagTempID": 54,
        "detDiagTempID": 145,
        "seq": 99,
        "style": [
            {
                "DiagToolID": 46,
                "propertyName": "Node_Background",
                "diagPropvalue": "#92b1c3"
            },
            {
                "DiagToolID": 46,
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "DiagToolID": 46,
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "DiagToolID": 46,
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/tfGpyfj.png"
            }
        ]
    },
    {
        "diagtoolid": 49,
        "name": "Loop Library",
        "title": "Loop Library",
        "explain": "This is the 2nd Loop when using Master Loop\\Loop Library duo\r\nOpen QinfoLibDetails system table used to store content into a recordset ",
        "notes": "",
        "caption": "Loop Library",
        "description": "This is the 2nd Loop when using Master Loop\\Loop Library duo\r\nOpen QinfoLibDetails system table used to store content into a recordset ",
        "source": "AI UI",
        "tasktypeid": 38,
        "tooltags": "AI, Loop, Library",
        "isInactive": 0,
        "category": "Loop",
        "params": [
            {
                "taskParamID": 27,
                "direction": 0,
                "paramName": "Fields to be loaded",
                "paramExplanation": "Fields to be loaded",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            },
            {
                "taskParamID": 28,
                "direction": 0,
                "paramName": "Filter to build sql",
                "paramExplanation": "Filter to build sql",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            },
            {
                "taskParamID": 29,
                "direction": 0,
                "paramName": "KeyField",
                "paramExplanation": "KeyField",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            },
            {
                "taskParamID": 30,
                "direction": 0,
                "paramName": "QinfoLibDetails",
                "paramExplanation": "QinfoLibDetails",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            }
        ],
        "nodeType": 3,
        "diagTempID": 54,
        "detDiagTempID": 146,
        "seq": 99,
        "style": [
            {
                "DiagToolID": 46,
                "propertyName": "Node_Background",
                "diagPropvalue": "#92b1c3"
            },
            {
                "DiagToolID": 46,
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "DiagToolID": 46,
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "DiagToolID": 46,
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/tfGpyfj.png"
            }
        ]
    },
    {
        "diagtoolid": 62,
        "name": "Mapping List",
        "title": "Mapping List",
        "explain": "Python: Input Library Content Details (Text version of the document)  as the main text to be surveyed. List of questions provided in the QinfoLibMappingPrep, questions are stored in the Caption and merged using Param1 exp: How many time $caption$ is mentionned in the text.\r\nAnswers to each of the 'caption' questions stored in QinfoLibMappingPrep.Value",
        "notes": "",
        "caption": "Mapping List",
        "description": "Python: Input Library Content Details (Text version of the document)  as the main text to be surveyed. List of questions provided in the QinfoLibMappingPrep, questions are stored in the Caption and merged using Param1 exp: How many time $caption$ is mentionned in the text.\r\nAnswers to each of the 'caption' questions stored in QinfoLibMappingPrep.Value",
        "source": "AI UI",
        "tasktypeid": 56,
        "isInactive": 0,
        "category": "Content",
        "params": [
            {
                "taskParamID": 46,
                "direction": 0,
                "paramName": "Prompt",
                "paramExplanation": "Prompt to be merged with QinfoLibMappingPrep.Caption prior to be sent to AI. $caption$ will be replaced by the captions",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            }
        ],
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 147,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#b5b6b3"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/TU9hUF1.png"
            }
        ]
    },
    {
        "diagtoolid": 55,
        "name": "Master Loop",
        "title": "Master Loop",
        "explain": "Python: Handles looping operations by fetching data from executing a SQL query and defining the name of each column inside the loop",
        "notes": "",
        "caption": "Master Loop",
        "description": "Python: Handles looping operations by fetching data from executing a SQL query and defining the name of each column inside the loop",
        "source": "AI UI",
        "tasktypeid": 49,
        "isInactive": 0,
        "category": "Loop",
        "params": [
            {
                "taskParamID": 33,
                "direction": 1,
                "paramName": "Table Name",
                "paramExplanation": "Name of the table we will use to SELECT from",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            },
            {
                "taskParamID": 34,
                "direction": 0,
                "paramName": "Filter",
                "paramExplanation": "Filter for the SELECT statement exp : ContactID in (1234)",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            },
            {
                "taskParamID": 35,
                "direction": 1,
                "paramName": "Primary key",
                "paramExplanation": "Primary key of the table we will SELECT from. Will also be added in the Select statement",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            },
            {
                "taskParamID": 36,
                "direction": 1,
                "paramName": "Field 1",
                "paramExplanation": "Variable Name in the query result. Will be used in the select Statement. Example \"Notes\".",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            },
            {
                "taskParamID": 59,
                "direction": 1,
                "paramName": "Field 2",
                "paramExplanation": "Variable Name in the query result. Will be used in the select Statement. Example \"Notes\".",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            },
            {
                "taskParamID": 60,
                "direction": 1,
                "paramName": "Field 3",
                "paramExplanation": "Variable Name in the query result. Will be used in the SELECT Statement. Example \"Notes\".",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            }
        ],
        "nodeType": 3,
        "diagTempID": 54,
        "detDiagTempID": 148,
        "seq": 99,
        "style": [
            {
                "DiagToolID": 46,
                "propertyName": "Node_Background",
                "diagPropvalue": "#92b1c3"
            },
            {
                "DiagToolID": 46,
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "DiagToolID": 46,
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "DiagToolID": 46,
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/tfGpyfj.png"
            }
        ]
    },
    {
        "diagtoolid": 32,
        "name": "New Tool 32",
        "title": "New Tool",
        "explain": "New Tool Explanation",
        "notes": "New Tool Notes",
        "caption": "New Tool",
        "description": "New Tool Description",
        "source": "AI UI",
        "isInactive": 1,
        "category": "Flow",
        "node_Width": 4,
        "node_Height": "0",
        "nodeType": 0,
        "diagTempID": 54,
        "detDiagTempID": 129,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#c4cea1"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/UcvfKZy.png"
            }
        ]
    },
    {
        "diagtoolid": 33,
        "name": "New Tool 33",
        "title": "New Tool",
        "explain": "New Tool Explanation",
        "notes": "New Tool Notes",
        "caption": "New Tool",
        "description": "New Tool Description",
        "source": "AI UI",
        "tooltags": "Simple, ai",
        "isInactive": 1,
        "category": "Loop",
        "node_Width": 4,
        "node_Height": "0",
        "nodeType": 0,
        "diagTempID": 54,
        "detDiagTempID": 127,
        "seq": 99,
        "style": [
            {
                "DiagToolID": 46,
                "propertyName": "Node_Background",
                "diagPropvalue": "#92b1c3"
            },
            {
                "DiagToolID": 46,
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "DiagToolID": 46,
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "DiagToolID": 46,
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/tfGpyfj.png"
            }
        ]
    },
    {
        "diagtoolid": 57,
        "name": "OCR",
        "title": "OCR",
        "explain": "Python: Extract all the text from the following format file : .pdf .docx .html .jpg .png .tiff .pptx .json .txt and insert the exctacted text in the column ContentDetails of table QinfoLibDetails of the corresponding file. (For images text extraction, python is using tesseract library.)\r\nAdded support for URL like 'http%' and extract rules.",
        "notes": "",
        "caption": "OCR",
        "description": "Python: Extract all the text from the following format file : .pdf .docx .html .jpg .png .tiff .pptx .json .txt and insert the exctacted text in the column ContentDetails of table QinfoLibDetails of the corresponding file. (For images text extraction, python is using tesseract library.)\r\nAdded support for URL like 'http%' and extract rules.",
        "source": "AI UI",
        "tasktypeid": 51,
        "isInactive": 0,
        "category": "Content",
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 149,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#b5b6b3"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/TU9hUF1.png"
            }
        ]
    },
    {
        "diagtoolid": 50,
        "name": "Process Content",
        "title": "Process Content",
        "explain": "Using the selected QlibDetID, add the match info in QInfoLibMatch. The values for ContactID and QLibDetID come from Param2 and Param3 of the Master Loop.",
        "notes": "",
        "caption": "Process Content",
        "description": "Using the selected QlibDetID, add the match info in QInfoLibMatch. The values for ContactID and QLibDetID come from Param2 and Param3 of the Master Loop.",
        "source": "AI UI",
        "tasktypeid": 42,
        "isInactive": 0,
        "category": "Content",
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 150,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#b5b6b3"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/TU9hUF1.png"
            }
        ]
    },
    {
        "diagtoolid": 51,
        "name": "Process Scoring",
        "title": "Process Scoring",
        "explain": "Python: Extract score from json code block and add the value to QinfoLibScores and add the match info in QInfoLibMatch. The values for ContactID and QLibDetID come from Param2 and Param3 of the Master Loop.",
        "notes": "",
        "caption": "Process Scoring",
        "description": "Python: Extract score from json code block and add the value to QinfoLibScores and add the match info in QInfoLibMatch. The values for ContactID and QLibDetID come from Param2 and Param3 of the Master Loop.",
        "source": "AI UI",
        "tasktypeid": 43,
        "isInactive": 0,
        "category": "Content",
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 151,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#b5b6b3"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/TU9hUF1.png"
            }
        ]
    },
    {
        "diagtoolid": 59,
        "name": "Query",
        "title": "Query",
        "explain": "Python: Execute the query in {Param2} and saves the result in the code as tab delimited text in a dictionary where the key is {Param1}.(the key will be used in the prompt as '{Param1}')",
        "notes": "",
        "caption": "Query",
        "description": "Python: Execute the query in {Param2} and saves the result in the code as tab delimited text in a dictionary where the key is {Param1}.(the key will be used in the prompt as '{Param1}')",
        "source": "AI UI",
        "tasktypeid": 53,
        "isInactive": 0,
        "category": "Content",
        "params": [
            {
                "taskParamID": 42,
                "direction": 0,
                "paramName": "Query",
                "paramExplanation": "The query that will be executed to get the results and create the tab delimited text. Example 'Select top 40 ExtractReference, ExtractText from QuickInfoFileExtract where QLibDetID = 4718'",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            },
            {
                "taskParamID": 43,
                "direction": 1,
                "paramName": "Query Name",
                "paramExplanation": "The name of the query ( how it will be identified in in the prompt ). Example 'testQuery1' (usage in prompt: 'extract values from this table {testQuery1}')",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            }
        ],
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 152,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#b5b6b3"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/TU9hUF1.png"
            }
        ]
    },
    {
        "diagtoolid": 71,
        "name": "Save To Text",
        "title": "Save To Text",
        "notes": "",
        "caption": "Save To Text",
        "source": "Ai UI",
        "tasktypeid": 65,
        "isInactive": 0,
        "category": "File",
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 164,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#d0e6fb"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/MotzIjQ.png"
            }
        ]
    },
    {
        "diagtoolid": 40,
        "name": "Score",
        "title": "Score",
        "explain": "Get Summary from QinfoLibDetails.QlibDetID and combine with Contacts.notes received as a param2 - outcome to be upsert in QinfoLibMatch:\r\nContactID, QlibDetID, AiMatching (chat outcome)",
        "notes": "",
        "caption": "Score",
        "description": "Get Summary from QinfoLibDetails.QlibDetID and combine with Contacts.notes received as a param2 - outcome to be upsert in QinfoLibMatch:\r\nContactID, QlibDetID, AiMatching (chat outcome)",
        "source": "AI UI",
        "tasktypeid": 8,
        "isInactive": 0,
        "category": "Content",
        "params": [
            {
                "taskParamID": 5,
                "direction": 0,
                "paramName": "ContactID",
                "paramExplanation": "ContactID",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            },
            {
                "taskParamID": 6,
                "direction": 0,
                "paramName": "QLibDetID",
                "paramExplanation": "QLibDetID",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            },
            {
                "taskParamID": 7,
                "direction": 1,
                "paramName": "ContactID",
                "paramExplanation": "ContactID",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            },
            {
                "taskParamID": 8,
                "direction": 1,
                "paramName": "QlibDetID",
                "paramExplanation": "QlibDetID",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            }
        ],
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 153,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#b5b6b3"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/TU9hUF1.png"
            }
        ]
    },
    {
        "diagtoolid": 61,
        "name": "Send Master Prompt",
        "title": "Send Master Prompt",
        "explain": "Python: Build a prompt with merge and send it\r\nThis will create a new ChatHeader",
        "notes": "",
        "caption": "Send Master Prompt",
        "description": "Python: Build a prompt with merge and send it\r\nThis will create a new ChatHeader",
        "source": "AI UI",
        "tasktypeid": 55,
        "isInactive": 0,
        "category": "Prompt",
        "params": [
            {
                "taskParamID": 45,
                "direction": 0,
                "paramName": "Prompt",
                "paramExplanation": "Prompt that will replace value names by values. Works with Query , Master Loop and Upload Files. Example : ' i will provide you with 2 variables and an uploaded file :  variable 1 \"{testQuery1}\" and variable 2 \"{QinfoLibDetails.ContentDetails}\". find corelation between the 2 variables and the uploaded '.(variable 1 is a Query, varibale 2 is from Library Master loop)\r\nNote: when using the Upload Files, the aichat assistant will know what uplpaded file we are tallking about.",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            }
        ],
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 154,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#ebd5ab"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/Wf5SWIk.png"
            }
        ]
    },
    {
        "diagtoolid": 60,
        "name": "Send Prompt",
        "title": "Send Prompt",
        "explain": "Python: Build a prompt with merge, send prompt does not reset the chatHeader",
        "notes": "",
        "caption": "Send Prompt",
        "description": "Python: Build a prompt with merge, send prompt does not reset the chatHeader",
        "source": "AI UI",
        "tasktypeid": 54,
        "isInactive": 0,
        "category": "Prompt",
        "params": [
            {
                "taskParamID": 44,
                "direction": 0,
                "paramName": "Prompt",
                "paramExplanation": "Prompt that will replace value names by values. Works with Query , Master Loop and Upload Files. Example : ' i will provide you with 2 variables and an uploaded file :  variable 1 \"{testQuery1}\" and variable 2 \"{QinfoLibDetails.ContentDetails}\". find corelation between the 2 variables and the uploaded '.(variable 1 is a Query, varibale 2 is from Library Master loop)\r\nNote: when using the Upload Files, the aichat assistant will know what uplpaded file we are tallking about.",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            }
        ],
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 155,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#ebd5ab"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/Wf5SWIk.png"
            }
        ]
    },
    {
        "diagtoolid": 64,
        "name": "Split PDF",
        "title": "Split PDF",
        "explain": "Python: Takes a pdf file from a previous actions (like OCR or File Extract) and devides it to multiple pdf files by splitting, every split has {Param1} pages. Creates the pdf file in the same directory as the original file, and also create records for those files in qinfolibdetails",
        "notes": "",
        "caption": "Split PDF",
        "description": "Python: Takes a pdf file from a previous actions (like OCR or File Extract) and devides it to multiple pdf files by splitting, every split has {Param1} pages. Creates the pdf file in the same directory as the original file, and also create records for those files in qinfolibdetails",
        "source": "AI UI",
        "tasktypeid": 58,
        "isInactive": 0,
        "category": "Content",
        "params": [
            {
                "taskParamID": 50,
                "direction": 0,
                "paramName": "Pages per split",
                "paramExplanation": "Pages per split",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            }
        ],
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 156,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#b5b6b3"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/TU9hUF1.png"
            }
        ]
    },
    {
        "diagtoolid": 38,
        "name": "SplitDoc",
        "title": "SplitDoc",
        "explain": "Get PDF from Library, split into pages, convert into text and send each page at a time and get outcome by page",
        "notes": "",
        "caption": "SplitDoc",
        "description": "Get PDF from Library, split into pages, convert into text and send each page at a time and get outcome by page",
        "source": "AI UI",
        "tasktypeid": 6,
        "tooltags": "PDF, AI, Loop, Extract",
        "isInactive": 0,
        "category": "Content",
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 157,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#b5b6b3"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/TU9hUF1.png"
            }
        ]
    },
    {
        "diagtoolid": 56,
        "name": "Start New Conversation",
        "title": "Start New Conversation",
        "explain": "Python: This is a crucial step for the flow to work. Initializes a new conversation thread with OpenAI. Also initialize a new chat header in the database where we insert the following values into QInfoChatHeader: In the ‘InstanceID’ column, insert the task ID .In the ‘BID’ column insert the BID number of the workflow (get the bid number by executing the query in processQuery column in the quickinfoRuntasks table of the taskID we are in). All other values are hardcoded for now. All the T PAram are not used in Python",
        "notes": "",
        "caption": "Start New Conversation",
        "description": "Python: This is a crucial step for the flow to work. Initializes a new conversation thread with OpenAI. Also initialize a new chat header in the database where we insert the following values into QInfoChatHeader: In the ‘InstanceID’ column, insert the task ID .In the ‘BID’ column insert the BID number of the workflow (get the bid number by executing the query in processQuery column in the quickinfoRuntasks table of the taskID we are in). All other values are hardcoded for now. All the T PAram are not used in Python",
        "source": "AI UI",
        "tasktypeid": 50,
        "isInactive": 0,
        "category": "AI",
        "node_Width": 250,
        "params": [
            {
                "taskParamID": 38,
                "direction": 0,
                "paramName": "AI Engine",
                "paramExplanation": "Version of chat engine (if not set, will use gpt 4 turbo)",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            },
            {
                "taskParamID": 39,
                "direction": 0,
                "paramName": "AI Profile",
                "paramExplanation": "Ai Profile Name. If left empty will use a default ai chat assistant. (if not set will use a default assistant)",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            }
        ],
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 158,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#ebdede"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/BzlPsH5.png"
            }
        ]
    },
    {
        "diagtoolid": 63,
        "name": "Summary",
        "title": "Summary",
        "explain": "Python: Send Prompt as Param1 to AI, set response in Summary",
        "notes": "",
        "caption": "Summary",
        "description": "Python: Send Prompt as Param1 to AI, set response in Summary",
        "source": "AI UI",
        "tasktypeid": 57,
        "isInactive": 0,
        "category": "Content",
        "params": [
            {
                "taskParamID": 48,
                "direction": 0,
                "paramName": "Prompt",
                "paramExplanation": "Prompt to request a summary of information used in contentDetails.\r\nIf blank provide generic summary prompt to reduce content.",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            }
        ],
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 159,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#b5b6b3"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/TU9hUF1.png"
            }
        ]
    },
    {
        "diagtoolid": 72,
        "name": "Test Step 1",
        "title": "Test Step 1",
        "notes": "",
        "caption": "Test Step 1",
        "source": "Ai UI",
        "tasktypeid": 66,
        "isInactive": 0,
        "category": "Flow",
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 165,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#c4cea1"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/UcvfKZy.png"
            }
        ]
    },
    {
        "diagtoolid": 73,
        "name": "Test Step 2",
        "title": "Test Step 2",
        "notes": "",
        "caption": "Test Step 2",
        "source": "Ai UI",
        "tasktypeid": 67,
        "isInactive": 0,
        "category": "Flow",
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 166,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#c4cea1"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/UcvfKZy.png"
            }
        ]
    },
    {
        "diagtoolid": 53,
        "name": "Upload Files",
        "title": "Upload Files",
        "explain": "Python: Files will be uploaded to assistant file system. Curent supported file types are : .c, .cs, .cpp, .doc, .docx, .html, .java, .json, .md, .pdf, .php, .pptx, .py, .rb, .tex, .txt, .css, .js, .sh, .ts.\r\nexample:\r\n'SELECT * from QinfoLibDetails WHERE {Param1}'",
        "notes": "",
        "caption": "Upload Files",
        "description": "Python: Files will be uploaded to assistant file system. Curent supported file types are : .c, .cs, .cpp, .doc, .docx, .html, .java, .json, .md, .pdf, .php, .pptx, .py, .rb, .tex, .txt, .css, .js, .sh, .ts.\r\nexample:\r\n'SELECT * from QinfoLibDetails WHERE {Param1}'",
        "source": "AI UI",
        "tasktypeid": 46,
        "isInactive": 0,
        "category": "File",
        "params": [
            {
                "taskParamID": 32,
                "direction": 0,
                "paramName": "Filter",
                "paramExplanation": "enter filter for the qinfolibdetails file, if set to {ProcessedQuery} will get the value of ProcessedQuery from quickinfoRuntasks. Example: QlibDetID IN (4717, 4716)",
                "paramType": "{\r\n\t\"type\": \"string\",\r\n\t\"mailmerge\": \"No\",\r\n\t\"fieldlist\": \"No\"\r\n}"
            }
        ],
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 160,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#d0e6fb"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/MotzIjQ.png"
            }
        ]
    },
    {
        "diagtoolid": 75,
        "name": "Rectangle",
        "title": "",
        "explain": "",
        "notes": "",
        "caption": "",
        "description": "",
        "source": "AI UI",
        "tasktypeid": 46,
        "isInactive": 0,
        "category": "General",
        "params": [ ],
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 263 ,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#d0e6fb"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/MotzIjQ.png"
            }
        ]
    },
    {
        "diagtoolid": 75,
        "name": "Circle",
        "title": "Circle",
        "explain": "Circle",
        "notes": "",
        "caption": "Circle",
        "description": "",
        "source": "AI UI",
        "tasktypeid": 46,
        "isInactive": 0,
        "category": "General",
        "params": [ ],
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 263 ,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#d0e6fb"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/MotzIjQ.png"
            }
        ]
    },
    {
        "diagtoolid": 75,
        "name": "Line",
        "title": "Line",
        "explain": "Line",
        "notes": "",
        "caption": "Line",
        "description": "",
        "source": "AI UI",
        "tasktypeid": 46,
        "isInactive": 0,
        "category": "General",
        "params": [ ],
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 263 ,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#d0e6fb"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/MotzIjQ.png"
            }
        ]
    },
    {
        "diagtoolid": 75,
        "name": "Text",
        "title": "Text",
        "explain": "Text",
        "notes": "",
        "caption": "Text",
        "description": "Text",
        "source": "AI UI",
        "tasktypeid": 46,
        "isInactive": 0,
        "category": "General",
        "params": [ ],
        "nodeType": 2,
        "diagTempID": 54,
        "detDiagTempID": 263 ,
        "seq": 99,
        "style": [
            {
                "propertyName": "Node_Background",
                "diagPropvalue": "#d0e6fb"
            },
            {
                "propertyName": "Node_Border_color",
                "diagPropvalue": "transparent"
            },
            {
                "propertyName": "Caption_IconType",
                "diagPropvalue": "Url"
            },
            {
                "propertyName": "Caption_IconLink",
                "diagPropvalue": "https://i.imgur.com/MotzIjQ.png"
            }
        ]
    }
]

export {Nodes}