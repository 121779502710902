import React, { useState } from "react";
import IconBox from "../IconBox";
import { TextField, Select, MenuItem, Autocomplete } from "@mui/material";
import {
  PiAlignRightSimpleBold,
  PiAlignLeftSimpleDuotone,
} from "react-icons/pi";
import { BsAlignCenter } from "react-icons/bs";
import {
  postResource,
  createRequestBody,
  getAccessKey,
} from "../../../apiCalls/DiagramsAPI";
import { ToastContainer, toast } from "react-toastify";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  autocomplete: {
    width: "auto",
    height: "30px",
    padding: 0,
    margin: 0,
    minWidth: "75%",
    fontSize: "14px !important",
    marginLeft: "11px",
    boxShadow: "none !important",
  },
});

function CaptionIconDropDown({
  properties,
  selectedTool,
  handlePropertyChange,
}) {
  const classes = useStyles();
  const [captionIcon, setCaptionIcon] = useState({
    iconPosition: "",
    iconSize: "",
    iconType: "",
    iconLink: "",
  });
  const [glyphIcons, setGlyphIcons] = useState();

  React.useEffect(() => {
    async function fetchData() {
      // Check if icons are already cached and not expired
      const cachedData = localStorage.getItem("glyphIconsWithTimestamp");

      if (cachedData) {
        try {
          const { icons, timestamp } = JSON.parse(cachedData);
          console.log("icons ", icons);
          // Check if cache is less than 24 hours old
          const isExpired = Date.now() - timestamp > 24 * 60 * 60 * 1000;

          if (!isExpired) {
            setGlyphIcons(icons);
            return;
          }
        } catch (parseError) {
          console.error("Error parsing cached icons", parseError);
        }
      }

      // Fetch and cache logic remains the same as previous example
      try {
        const accessKey = await getAccessKey();
        const icons = await postResource(
          "GetQuickInfo",
          createRequestBody({
            accessKey: accessKey,
            guid: `${window.APIProvider.recordPath.GetGlyphicons}`,
          })
        );

        // Cache with timestamp
        localStorage.setItem(
          "glyphIconsWithTimestamp",
          JSON.stringify({
            icons,
            timestamp: Date.now(),
          })
        );

        setGlyphIcons(icons);
      } catch (error) {
        console.error("Fetch Error ", error);
        toast.error("Error fetching icons: " + error.message);
      }
    }

    fetchData();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCaptionIcon((prevState) => {
      const updatedCaptionIcon = {
        ...prevState,
        [name]: value,
      };
      console.log("name ", name);
      console.log("value ", value);
      handlePropertyChange(
        selectedTool,
        "caption",
        name.charAt(0).toUpperCase() + name.slice(1),
        value
      );
      return updatedCaptionIcon;
    });
  };

const handleIconLinkChange = (e, option) => {
    console.log("option ", option);
    setCaptionIcon((prevState) => {
      const updatedCaptionIcon = {
        ...prevState,
        iconLink: option ? option : "",
      };
      handlePropertyChange("Caption_IconLink", updatedCaptionIcon.iconLink);
      return updatedCaptionIcon;
    });
  };

  const handleIconPositionChange = (name, value) => {
    setCaptionIcon({
      ...captionIcon,
      iconPosition: name,
    });
    handlePropertyChange(selectedTool, "caption", "IconPosition", name);
  };
  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="rht-info fontlft-size">Icon Position</div>
      <IconBox
        handleChange={handleIconPositionChange}
        buttonList={[
          {
            icon: <PiAlignLeftSimpleDuotone />,
            name: "Left",
            value: captionIcon.iconPosition === "Left" ? 1 : 0,
          },
          // {
          //   icon: <BsAlignCenter />,
          //   name: "Center",
          //   value: captionIcon.iconPosition === "Center" ? 1 : 0,
          // },
          {
            icon: <PiAlignRightSimpleBold />,
            name: "Right",
            value: captionIcon.iconPosition === "Right" ? 1 : 0,
          },
        ]}
      />
      <div className="rht-info fontlft-size">Icon Size</div>
      <Select
        name="iconSize"
        className="rht-fld-select"
        value={properties.caption.IconSize}
        onChange={(e) => {
          handleChange(e);
        }}
      >
        {["small", "medium", "large"].map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
      <div className="rht-info fontlft-size">Icon Type</div>
      <Select
        name="iconType"
        className="rht-fld-select"
        value={properties.caption.IconType}
        onChange={(e) => {
          handleChange(e);
        }}
      >
        {["Bootstrap", "Url"].map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
      {properties.caption.IconType === "Url" ? (
        <>
          <div className="rht-info fontlft-size">Icon Link</div>
          <input
            name="iconLink"
            label="Icon Link"
            className="rht-fld-select"
            value={properties.caption.IconLink}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </>
      ) : (
        <>
          <div className="rht-info fontlft-size">Icon Link</div>
          <Select
            name="iconLink"
            className="rht-fld-select"
            value={properties.caption.IconLink}
            onChange={(e) => {
              handleChange(e);
            }}
          >
            {glyphIcons?.map((option) => (
              <MenuItem key={option.cValue} value={option.cValue}>
                {option.cValue}
              </MenuItem>
            ))}
          </Select>
          {/* <Autocomplete
            name="iconLink"
            options={glyphIcons}
            // onChange={handleIconLinkChange}
            size="small"
            renderInput={(params) => (
              <TextField
                className={classes.autocomplete}
                name="iconLink"
                {...params}
              />
            )}
          /> */}
        </>
      )}
    </>
  );
}

export default CaptionIconDropDown;
