import React, { useState, useEffect } from "react";
import { SplitScreen } from "./SplitScreen";
import MultiSelector from "./multiSelector/MultiSelector";
import MenuCardComponent from "./MenuCardComponent";
import { Grid, ThemeProvider } from "@mui/material";
import { loadLoginData } from "../apiCalls/DiagramsAPI";
import { ToastContainer, toast } from "react-toastify";
import themeProvider from "../ThemeProvider";

function CreateOrOpenDiag({
  handleDetails,
  handleEditDiagram,
  searchQuery,
  caller,
  isMenuOpenleft,
  selected,
  setSelected,
  data,
  fetched,
  editTemplate,
  handleToggleFavorite,
  handleToggleProjectActivity,
  handleToggleTemplateActivity,
  setSelectedItem,
  setOpenDiagHeader,
}) {
  const authData = loadLoginData();
  const key = `${authData?.userName || "Unknown User"}_${localStorage.getItem("region")}_favourites`;
  //filter for search (only for the cards)
  // const itemsToDisplay = selected?.filter(
  //   (section) =>
  //     section?.name?.toLowerCase().includes(searchQuery) ||
  //     section?.title?.toLowerCase().includes(searchQuery)
  // );

  const itemsToDisplay = selected
    ?.filter(
      (section) =>
        section?.name?.toLowerCase().includes(searchQuery) ||
        section?.title?.toLowerCase().includes(searchQuery)
    )
    .sort((a, b) => {
      if (a.favourite === b.favourite) return 0;
      return a.favourite ? -1 : 1;
    });

  return (
    <ThemeProvider theme={themeProvider}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <SplitScreen
        leftWeight={1}
        rightWeight={2}
        isMenuOpenleft={isMenuOpenleft}
      >
        <MultiSelector
          data={fetched ? data : []}
          selected={fetched ? selected : []}
          setSelected={setSelected}
          setSelectedItem={setSelectedItem}
          setOpenDiagHeader={setOpenDiagHeader}
        />
        <Grid sx={{ flexGrow: 1 }} container padding={1}>
          {itemsToDisplay.map((item, index) => (
            <MenuCardComponent
              key={index}
              data={item}
              size={310}
              textSize={17}
              isMobile={window.innerWidth < 750 ? true : false}
              handleDetails={handleDetails}
              handleEditDiagram={handleEditDiagram}
              caller={caller}
              editTemplate={editTemplate}
              handleToggleFavorite={handleToggleFavorite}
              handleToggleProjectActivity={handleToggleProjectActivity}
              handleToggleTemplateActivity={handleToggleTemplateActivity}
              firstButtonTitle={
                item.Bid === 0 ? "Template Info" : "Project Info"
              }
              firstButtonToolTip={
                item.Bid === 0
                  ? "View and edit template information"
                  : "View and edit project information"
              }
              secondButtonTitle={"Flow"}
              secondButtonToolTip={"Edit flow on canvas"}
            />
          ))}
        </Grid>
      </SplitScreen>
    </ThemeProvider>
  );
}

export default CreateOrOpenDiag;
