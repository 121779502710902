import {
  Button,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import "../assets/icofontcharts/icofont.min.css";
import { Theme } from "../Components/IncludeFile/Theme";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import CustomPopUpForm from "../layout/CustomPopUpForm";
import { makeStyles } from "@mui/styles";
import Discover from "./Discover";
import { getCurrentDateTime } from "../apiCalls/DiagramsAPI";
import { ReadArray } from "../Components/Api/ApiCalls";
import ErrorBanner from "../Error/ErrorBanner";

const useStyles = makeStyles({
  customSelectRoot: {
    "&:before": {
      borderBottom: "none !important",
    },
    "&:after": {
      borderBottom: "none !important",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent  !important",
    },
  },
});

const ViewCardInfo = ({
  authData,
  arrayNames,
  res,
  defaultStyle,
  ReadAllData,
  error,
  setError,
}) => {
  let APIProvider = window.APIProvider;
  const location = useLocation();

  const ArID = location.state ? location.state.ArID : null;
  const ArrayName = location.state ? location.state.ArrayName : null;
  const Category = location.state ? location.state.Category : null;
  const DataSourceArID = location.state ? location.state.DataSourceArID : null;
  const isInactive = location.state ? location.state.isInactive : null;
 

  const [editedcategory, seteditedcategory] = useState(Category);
  const [editedArrayName, setEditedArrayName] = useState("");
  const [editedDescription, setEditedDescription] = useState("");
  const [arrayNameError, setArrayNameError] = useState("");
  const [isEditable, setIsEditable] = useState(true);
  const [isOpenSelectPage, setIsOpenSelectpage] = useState(true);
  // const [arrayNames, setArrayNames] = useState([]);
  const [selectedArrayName, setSelectedArrayName] = useState("");
  const [selectedArrayId, setSelectedArrayId] = useState(null);
  const [showDiscover, setShowDiscover] = useState(false);
  const [isOpenDiscover, setIsOpenDiscover] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  const [formNameExists, setFormNameExists] = useState(false);
  const navigate = useNavigate();
  let accessKey = authData.accessKey || "";

  const userName = authData.userName || "";

  const dateTime = getCurrentDateTime();

  const baseURL = `${APIProvider.baseURL}${APIProvider.MethodName.GetQuickInfo}`;

  // const fetchDatafordataSourceName = async () => {

  //   try {
  //     const result = ReadAllData.find((item) => item.ArID === DataSourceArID);
  //     const arrayName = result?.ArrayName;
  //     setSelectedArrayName(arrayName);
  //     setSelectedArrayId(result.ArID);
  //   } catch (error) {
  //     console.error("Error during fetch:", error);
  //   }
  // };

  const fetchDatafordataSourceName = async () => {
    try {
      const result = ReadAllData.find((item) => item.ArID === DataSourceArID);

      if (result) {
        const arrayName = result.ArrayName;
        setSelectedArrayName(arrayName);
        setSelectedArrayId(result.ArID);
      } else {
        console.warn(`No match found for DataSourceArID: ${DataSourceArID}`);
        setSelectedArrayName(null);
        setSelectedArrayId(0);
      }
    } catch (error) {
      console.error("Error during fetch:", error);
      setError("An error during fetch");
      localStorage.setItem("error", "An error during fetch");
      localStorage.setItem("navigate", "/Landing");
    }
  };

  useEffect(() => {
    fetchDatafordataSourceName();
  }, [DataSourceArID]);

  const handleCreate = async () => {
    if (!authData || !authData.accessKey) {
      navigate("/");
      sessionStorage.clear();
      return;
    }
    setLoading(true);
    try {
      if (!editedArrayName.trim()) {
        setArrayNameError("Form Name is required");
        setLoading(false);
        return;
      }

      const arrayNames = ReadAllData.map((item) => item.ArrayName);
      if (
        arrayNames.some(
          (name) => name.toLowerCase() === editedArrayName.toLowerCase()
        )
      ) {
        toast.error(
          "Form name already exists. Please choose a different name."
        );
        return;
      }
      const jsonData = await ReadArray(ArID, accessKey);

      if (jsonData) {
        const sourceData = jsonData[0].Source;
        const requestBodywriteArray = {
          accessKey: authData.accessKey,
          guid: APIProvider.GUID.WriteArrayGUID,
          param1: "",
          param2: "",
          param3: "",
          param4: "",
          recordSet: "",
          body: JSON.stringify({
            ArID: "0",
            ArrayName: editedArrayName,
            Description: editedDescription,
            Source: sourceData,
            Category: Category,
            IsTemplate: "0",
            htmlOutput: "new",
            DataSourceArID: selectedArrayId || 0 || null,
            isInactive: isInactive,
            CreatedBy: userName,
            CreatedDate: dateTime,
            ModifiedBy: userName,
            ModifiedDate: dateTime,
          }),
        };
     
        const response = await fetch(
          `${APIProvider.baseURL}${APIProvider.MethodName.PostQuickInfo}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBodywriteArray),
          }
        );
        if (response.ok) {
          const data = await response.json();
          const resultData = JSON.parse(data.apiResponse);
         

          // Extract ARID from the response
          const aridObject = resultData.find(
            (item) =>
              item.intType === 4 && item.outFields && item.outFields.ARID
          );
          const ARID = aridObject?.outFields?.ARID;
          const arID1 = ARID;
          const newArrayName = editedArrayName;
          const newDescription = editedDescription;
          const newCategory = Category;
          const newdataSourceArID = selectedArrayId || 0 || null;

          let SourceofDataSource = null;
          if (newdataSourceArID > 0) {
            const jsonData = await ReadArray(newdataSourceArID, accessKey);
            SourceofDataSource = jsonData?.[0]?.Source || null;
          }

          if (Category === "Blank Form") {
            navigate(`/Form/${arID1}`, {
              state: {
                ArrayName: newArrayName,
                ArID: arID1,
                source: JSON.stringify(sourceData),
                dataSourceArID: newdataSourceArID,
                Category: newCategory,
                Description: newDescription,
                defaultStyle: defaultStyle,
                SourceofDataSource: SourceofDataSource,
                isInactive:isInactive
              },
            });
          } else if (newdataSourceArID > 0) {
            navigate("/Open", {
              state: {
                ArrayName: newArrayName,
                ArID: arID1,
                Source: JSON.stringify(sourceData),
                DataSourceArID: newdataSourceArID,
                Category: newCategory,
                Description: newDescription,
                defaultStyle: defaultStyle,
                ReadAllData: ReadAllData,
                isInactive:isInactive
              },
            });
          } else {
         
            navigate(`/Form/${arID1}`, {
              state: {
                ArrayName: newArrayName,
                ArID: arID1,
                Source: JSON.stringify(sourceData),
                dataSourceArID: newdataSourceArID,
                Category: newCategory,
                Description: newDescription,
                defaultStyle: defaultStyle,
                SourceofDataSource: SourceofDataSource,
                isInactive:isInactive
              },
            });
          }
        }

        // } else {
        //   console.error("Error creating array:");
        // }
      }
    } catch (error) {
      console.error("Error:", error.message);
      setError("An error occurred on post for Creating Card");
      localStorage.setItem("error", "An error occurred on post for Creating Card");
      localStorage.setItem("navigate", "/Landing");
    }
    setLoading(false);
    setShowDiscover(true);
    setIsOpen(true);
  };

  const handleArrayNameChange1 = async (event) => {
    setSelectedArrayName(event.target.value);
    try {
      const selectedArray = res.find(
        (item) => item.ArrayName === event.target.value
      );
      if (selectedArray) {
        setSelectedArrayId(selectedArray.ArID);
      } else {
        console.error("Selected array not found");
      }
    } catch (error) {
      console.error("Error fetching array names:", error);
    }
  };

  const toggleEditState = () => {
    setIsEditable(!isEditable);
  };
  const handleSave = async () => {
    try {
      if (!editedArrayName.trim()) {
        setArrayNameError("Form Name is required");
        setLoading(false);
        return;
      } else {
        setArrayNameError("");
      }
      if (formNameExists) {
        toast.error(
          "Form name already exists. Please choose a different name."
        );
        return;
      }
      toggleEditState();
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const handleEdit = () => {
    setIsEditable(!isEditable);
  };

  const iconButtonsSaveAndRestore = [
    {
      icon: Theme.Restore,
      color: "primary",
      handleClick: () => {
        handleCancelClick();
      },
      tooltip: "Refresh",
    },
    {
      icon: Theme.Save,
      color: "primary",
      handleClick: () => {
        handleSave();
      },
      tooltip: "Save",
    },
  ];

  const handleCancelClick = () => {
    setEditedArrayName("");
    setEditedDescription("");
    setSelectedArrayName("");
    setSelectedArrayId(null);
  };

  const iconButtonsEdit = [
    {
      icon: Theme.Edit,
      color: "primary",
      handleClick: () => {
        handleEdit();
      },
      tooltip: "Click To Edit",
    },
  ];

  const handleCancel = () => {
    setIsOpen(false);
    setIsOpenSelectpage(false);
  };

  const classes = useStyles();

  return (
    <CustomPopUpForm
      open={isOpenSelectPage}
      onClose={handleCancel}
      title="Create New Form"
      iconButtons={isEditable ? iconButtonsSaveAndRestore : iconButtonsEdit}
      height="60%"
    >
      <>
        {!isEditable ? (
          <>
            <div className="card-info">
              <div className="card-info-inside-view">
                <Typography className="card-info-inside-text">
                  Form Name*:
                </Typography>
                <div>
                  <Typography style={{ color: "#000" }}>
                    {editedArrayName}
                  </Typography>
                </div>
              </div>
              <div className="card-info-inside">
                <Typography className="card-info-inside-text">
                  Description:
                </Typography>
                <div>
                  <Typography style={{ color: "#000" }}>
                    {editedDescription}
                  </Typography>
                </div>
              </div>
              <div className="card-info-inside">
                <Typography className="card-info-inside-text">
                  Category:
                </Typography>
                <div>
                  <Typography style={{ color: "#000" }}>
                    {editedcategory}
                  </Typography>
                </div>
              </div>
              <div className="card-info-inside">
                <Typography className="card-info-inside-text">
                  Data Source:
                </Typography>
                <div>
                  <Typography style={{ color: "#000" }}>
                    {selectedArrayName}
                  </Typography>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="card-info card-info-view-crd">
            <div className="card-info-inside card-info-inside-view">
              <Typography style={{ fontWeight: "bold" }}>
                Form Name*:
              </Typography>
              <TextField
                required
                type="text"
                className="edit-popup"
                name="Name"
                onChange={(e) => {
                  setEditedArrayName(e.target.value);
                  setArrayNameError("");
                }}
                value={editedArrayName}
                error={!!arrayNameError}
                helperText={arrayNameError}
              />
            </div>
            <div className="card-info-inside">
              <Typography style={{ fontWeight: "bold" }}>
                Description:
              </Typography>
              <TextField
                type="text"
                className="edit-popup"
                name="Title"
                onChange={(e) => setEditedDescription(e.target.value)}
                value={editedDescription}
              />
            </div>
            <div className="card-info-inside">
              <Typography style={{ fontWeight: "bold" }}>Category:</Typography>
              <TextField
                type="text"
                className="edit-popup"
                name="Explanation"
                value={editedcategory}
                style={{ backgroundColor: "#bdbdbd" }}
                readOnly
              />
            </div>
            <div className="card-info-inside">
              <Typography style={{ fontWeight: "bold" }}>
                Data Source:
              </Typography>
              <Select
                value={selectedArrayName || "0"}
                onChange={handleArrayNameChange1}
                className={`${classes.customSelectRoot} data-select-crd`}
              >
                <MenuItem value="0">Select DataSource </MenuItem>
                {arrayNames.map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        )}
      </>
      <>
        <div style={{ width: "96%", display: "flex" }}>
          <Button fullWidth onClick={handleCancel}>
            Back
          </Button>
          <Button
            variant="contained"
            fullWidth
            onClick={handleCreate}
            disabled={formNameExists}
          >
            {loading ? <CircularProgress size={24} color="inherit" /> : "Next"}
          </Button>
        </div>
        {showDiscover && (
          <Discover
            ArrayName={editedArrayName}
            Description={editedDescription}
            Category={editedcategory}
            setArrayName={setEditedArrayName}
            setDescription={setEditedDescription}
            setCategory={seteditedcategory}
            setIsOpen={setIsOpen}
            isOpenDiscover={isOpenDiscover}
            setIsOpenDiscover={setIsOpenDiscover}
            selectedArrayName={selectedArrayName}
            selectedArrayId={selectedArrayId}
            setSelectedArrayId={setSelectedArrayId}
            setSelectedArrayName={setSelectedArrayName}
            handleArrayNameChange1={handleArrayNameChange1}
            isOpen={isOpen}
            isOpenSelectPage={isOpenSelectPage}
            setIsOpenSelectpage={setIsOpenSelectpage}
            authData={authData}
            defaultStyle={defaultStyle}
            ReadAllData={ReadAllData}
            error={error}
            setError={setError}
            isInactive={isInactive}
          />
        )}
        {error && <ErrorBanner />}
      </>
    </CustomPopUpForm>
  );
};

export default ViewCardInfo;
