import React, { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { IconButton, Menu, MenuItem, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { ChevronRight, ChevronLeft } from "@mui/icons-material";
import SidebarCollapse from "../Components/SideBarCollapseComponents/SidebarCollapse";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { Theme } from "../Components/IncludeFile/Theme";
import { v4 as uuid } from "uuid";

const drawerWidth = 260;
const secondDrawerWidth = 300;

const MainContent = styled("main")({
  flexGrow: 1,
  padding: "16px",
  transition: "margin-right 0.3s ease",
  // marginRight: ({ open }) => (open ? drawerWidth : 0),
});

const Sidebar = styled("aside")(({ theme, collapse }) => ({
  width: collapse ? 0 : drawerWidth,
  flexShrink: 0,
  height: "calc(100vh - 54px)",
  // height: "100vh",
  overflow: "auto",
  borderRight: `1px solid ${theme.palette.divider}`,
  transition: "width 0.3s ease",
  [`& .MuiDrawer-paper`]: {
    width: drawerWidth,
    boxSizing: "border-box",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const RightSidebar = styled("aside")(({ theme, collapse }) => ({
  width: collapse ? 0 : secondDrawerWidth,
  flexShrink: 0,
  height: "calc(100vh - 54px)",
  // height: "100vh",
  overflow: "auto",
  borderRight: collapse ? null : `1px solid ${theme.palette.divider}`,
  transition: "width 0.3s ease",
  [`& .MuiDrawer-paper`]: {
    width: secondDrawerWidth,
    boxSizing: "border-box",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const RightDrawer = styled(Drawer)(({ theme }) => ({
  width: secondDrawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  [`& .MuiDrawer-paper`]: {
    width: secondDrawerWidth,
    boxSizing: "border-box",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const ExpandableView = ({
  children,
  leftSidebarTree,
  selectedItem,
  setSelectedItem,
  handleSaveAs,
  leftCollapse,
  setLeftCollapse,
  rightCollapse,
  setRightCollapse,
  showLeftIcon,
  setShowLeftIcon,
  propertyData,
  tools,
  tempNodes,
}) => {
  const [mainContent, rightContent] = children;

  const [treeData, setTreeData] = useState(leftSidebarTree);

  // useEffect(() => {
  //   setTreeData(leftSidebarTree);
  // }, [leftSidebarTree]);

  // New state to hold the category value
  const [category, setCategory] = useState(null);

  // UseEffect to check if node.name equals title in tools JSON and get category
  useEffect(() => {
    if (treeData && tools && tools.length > 0) {
      treeData.forEach((node) => {
        tools.forEach((tool) => {
          if (node.name === tool.title || node.name === tool.execname) {
            setCategory(tool.category); // Set category if names match
          }
        });
      });
    }
  }, [treeData, tools]); // Run on load and when treeData or tools change

  // Create a cache for icon links
  const iconLinksMap = React.useMemo(() => {
    const map = new Map();

    if (propertyData) {
      tools.forEach((tool) => {
        // Case 3: Check Nodes first
        const customNode = propertyData?.Nodes?.find(
          (node) => node?.id === tool?.tasktypeid
        )?.customstyle?.caption?.IconLink;

        if (customNode) {
          map.set(tool?.tasktypeid?.toString(), customNode);
          return;
        }

        // Case 1: Check category
        const categoryIcon =
          propertyData[tool?.category]?.caption?.IconLink || null;
        if (categoryIcon) {
          map.set(tool?.tasktypeid?.toString(), categoryIcon);
          return;
        }

        console.log("categoryIcon:", categoryIcon);

        // Case 2: Fallback to <all>
        const defaultIcon = propertyData["<all>"]?.caption?.IconLink;
        if (defaultIcon) {
          map.set(tool?.tasktypeid?.toString(), defaultIcon);
        }
      });
    }

    return map;
  }, [tools, propertyData]);

  const IconComponent = ({ nodeId }) => {
    // Get the icon directly from the map
    const iconSrc = iconLinksMap.get(nodeId.toString());

    return iconSrc ? (
      <img
        src={iconSrc}
        alt="icon"
        style={{ width: "20px", height: "20px", marginRight: "10px" }}
      />
    ) : null;
  };

  // const iconLinksWithKeys = [];

  // for (const key in propertyData) {
  //   console.log("key: ", key);
  //   console.log("propertyData[key]: ", propertyData[key]);
  //   if (propertyData[key].caption && propertyData[key].caption.IconLink) {
  //     // Push an object containing the key and IconLink to the array
  //     iconLinksWithKeys.push({
  //       key: key,
  //       IconLink: propertyData[key].caption.IconLink,
  //     });
  //     // Log the key and IconLink to the console
  //     console.log(`${key}: ${propertyData[key].caption.IconLink}`);
  //   }
  // }
  // for (const key in propertyData) {
  //   console.log("key: ", key);
  //   console.log("propertyData[key]: ", propertyData[key]);

  //   if (key === "Nodes" && Array.isArray(propertyData[key])) {
  //     // Iterate through each node in the Nodes array
  //     propertyData[key].forEach((node) => {
  //       console.log("Node:", node);

  //       if (
  //         node.id &&
  //         node.customstyle &&
  //         node.customstyle.caption &&
  //         node.customstyle.caption.IconLink
  //       ) {
  //         iconLinksWithKeys.push({
  //           key: node.id, // Use the node's id as the key
  //           IconLink: node.customstyle.caption.IconLink,
  //         });

  //         console.log(
  //           `Node ID (${node.id}): ${node.customstyle.caption.IconLink}`
  //         );
  //       }
  //     });
  //   } else if (
  //     propertyData[key].caption &&
  //     propertyData[key].caption.IconLink
  //   ) {
  //     iconLinksWithKeys.push({
  //       key: key,
  //       IconLink: propertyData[key].caption.IconLink,
  //     });
  //     console.log(`${key}: ${propertyData[key].caption.IconLink}`);
  //   }
  // }

  // console.log("iconLinksWithKeys: ", iconLinksWithKeys);
  const dynamicKey = Object.keys(tempNodes || {})[0];

  const captionColor = tempNodes?.[dynamicKey]?.caption?.color || "#000";

  const handleListItemClick = (node, event) => {
    if (event.target.closest(".right-sidebar")) {
      return;
    }
    if (node.children && node.children.length > 0) {
      const updatedTreeData = toggleNodeExpansion(treeData, node);
      setTreeData(updatedTreeData);
    }
    if (window.innerWidth < 600) {
      if (node.children && node.children.length === 0) {
        setLeftCollapse(true);
        setShowLeftIcon(false);
      }
    }

    if (node.children && node.children.length === 0) {
      setSelectedItem(node);
      setRightCollapse(false);
    }
  };

  // const toggleNodeExpansion = (data, nodeToToggle) => {
  //   return data.map((node) => {
  //     if (node === nodeToToggle) {
  //       return { ...node, open: !node.open };
  //     } else if (node.children) {
  //       return {
  //         ...node,
  //         children: toggleNodeExpansion(node.children, nodeToToggle),
  //       };
  //     } else {
  //       return node;
  //     }
  //   });
  // };

  useEffect(() => {
    const expandedTreeData = toggleNodeExpansion(leftSidebarTree);
    setTreeData(expandedTreeData);

    const firstChildNode = findFirstChildNode(expandedTreeData);
    if (firstChildNode) {
      setSelectedItem(firstChildNode);
      setRightCollapse(false);
    }
  }, [leftSidebarTree]);

  const findFirstChildNode = (data) => {
    if (!data || !Array.isArray(data)) {
      return null;
    }

    for (const node of data) {
      if (node.children && node.children.length > 0) {
        return node.children[0];
      }
    }

    return null;
  };

  const toggleNodeExpansion = (data = [], nodeToToggle = null) => {
    if (!data || !Array.isArray(data)) {
      return [];
    }

    return data.map((node) => {
      if (!nodeToToggle) {
        return {
          ...node,
          open: true,
          children: node.children ? toggleNodeExpansion(node.children) : [],
        };
      } else if (node === nodeToToggle) {
        return { ...node, open: !node.open };
      } else if (node.children) {
        return {
          ...node,
          children: toggleNodeExpansion(node.children, nodeToToggle),
        };
      } else {
        return node;
      }
    });
  };

  const handleMainContentClick = () => {
    setSelectedItem(null);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMoreClick = (event, node) => {
    event.stopPropagation();
    setSelectedItem(node);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const renderTree = (treeData, level = 1) => {
  //   return (
  //     <div
  //       style={{
  //         width: leftCollapse ? 0 : drawerWidth,
  //         transition: "width 0.3s ease",
  //         overflow: "hidden",
  //       }}
  //     >
  //       <List>
  //         {treeData?.map((node) => (
  //           <React.Fragment key={node.id}>
  //             <ListItem
  //               button
  //               onClick={(event) => handleListItemClick(node, event)}
  //               style={{
  //                 paddingLeft: `${level * 20}px`,
  //                 backgroundColor:
  //                   node === selectedItem ? "rgba(0, 0, 0, 0.1)" : "white",
  //                 height: "35px",
  //               }}
  //             >
  //               {node.children &&
  //                 (node.children.length > 0 ? (
  //                   node.open ? (
  //                     <ExpandLess />
  //                   ) : (
  //                     <ExpandMore />
  //                   )
  //                 ) : null)}
  //               <ListItemText primary={node.name} />
  //               {node.children.length > 0 && (
  //                 <div
  //                   className="Editdeletebitton"
  //                   onMouseDown={(e) => e.stopPropagation()}
  //                 >
  //                   <MoreHorizIcon
  //                     onClick={(event) => handleMoreClick(event, node)}
  //                   />
  //                 </div>
  //               )}
  //             </ListItem>

  //             {node.children && node.open && (
  //               <Collapse in={true} timeout="auto" unmountOnExit>
  //                 {renderTree(node.children, level + 1)}
  //               </Collapse>
  //             )}
  //           </React.Fragment>
  //         ))}
  //       </List>
  //     </div>
  //   );
  // };

  const renderTree = (treeData, level = 1) => {
    return (
      <div
        style={{
          width: leftCollapse ? 0 : drawerWidth,
          transition: "width 0.3s ease",
          overflow: "hidden",
        }}
      >
        <List className="tools-view">
          {treeData?.map((node) => {
            // Check if the category matches any key in iconLinksWithKeys
            // const matchingIcon = iconLinksWithKeys.find(
            //   (icon) => icon.key === category
            // );

            const isChildNode = level > 1; // Identifying if the node is a child node (not a top-level node)

            return (
              <div key={uuid()} style={{ padding: 0 }}>
                <ListItem
                  button
                  onClick={(event) => handleListItemClick(node, event)}
                  style={{
                    padding: isChildNode ? "0.2rem 1.5rem" : "0.5rem 0",
                    // paddingLeft: `${level * 20}px`,
                    backgroundColor:
                      node === selectedItem ? "rgba(0, 0, 0, 0.1)" : "white",
                    // height: "35px",
                  }}
                  className="tools-list"
                >
                  {node.children &&
                    (node.children.length > 0 ? (
                      node.open ? (
                        <div className="downarrow expandedArw">
                          {Theme.ArrowDown}
                        </div>
                      ) : (
                        <div className="downarrow shrinkedArw">
                          {Theme.ChevronRight}
                        </div>
                      )
                    ) : null)}
                  {/* Render the icon before the node name only if it's not a child node */}
                  {!isChildNode && <IconComponent nodeId={node.id} />}

                  <ListItemText
                    primary={node.name}
                    style={{
                      color: isChildNode ? "inherit" : captionColor,
                    }}
                  />
                  {/* {node.children.length > 0 && (
                    <div
                      className="Editdeletebitton"
                      onMouseDown={(e) => e.stopPropagation()}
                    >
                      <MoreHorizIcon
                        onClick={(event) => handleMoreClick(event, node)}
                      />
                    </div>
                  )} */}
                </ListItem>

                {node.children && node.open && (
                  <Collapse
                    in={true}
                    timeout="auto"
                    unmountOnExit
                    sx={{
                      "& .MuiCollapse-wrapper": { padding: 0 },
                      "& .MuiCollapse-wrapperInner": { padding: 0 },
                    }}
                  >
                    {renderTree(node.children, level + 1)}
                  </Collapse>
                )}
              </div>
            );
          })}
        </List>
      </div>
    );
  };

  const handleSave = () => {
    handleClose();
    handleSaveAs();
  };
  const handleMenushwDskButtonClick = () => {
    setLeftCollapse(!leftCollapse);
  };

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Sidebar collapse={leftCollapse}>
          <>
            {renderTree(treeData)}
            <Menu
              PaperProps={{
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              disablePortal
              className="custom-menu"
            >
              <MenuItem className="custom-menu-item" onClick={handleSave}>
                <SaveAsIcon className="list-nav-icon" />
                Save as
              </MenuItem>
            </Menu>
          </>
        </Sidebar>
        <SidebarCollapse
          showmenudsk={leftCollapse}
          setShowdskmenu={setLeftCollapse}
          direction={0}
        />
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          // justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          {/* <Grid item xs={12} sm={3}> */}
          <RightSidebar collapse={rightCollapse}>{rightContent}</RightSidebar>
          {/* <SidebarCollapse
              showmenudsk={rightCollapse}
              setShowdskmenu={setRightCollapse}
              direction={0}
            /> */}
          {/* </Grid> */}
        </div>
        <Grid item xs={12} sm={9}>
          <MainContent onClick={handleMainContentClick}>
            {mainContent}
          </MainContent>
        </Grid>
      </div>
    </div>
  );
};

export default ExpandableView;
