import React, { useState } from "react";
import PropTypes, { node } from "prop-types";
import Collapse from "@mui/material/Collapse";
import { Typography, Box, List, ListItem, ListItemText } from "@mui/material";

const ConnectionDrawerComponent = ({ selectedNodeId, nodes, edges }) => {
  return <div>ConnectionDrawerComponent</div>;
};
//   const gatherVariablePool = (nodeId, nodes, edges) => {
//     const visited = new Set();
//     const variablePool = [];

//     const traverseParents = (currentNodeId) => {
//       if (visited.has(currentNodeId)) return;
//       visited.add(currentNodeId);

//       const node = nodes?.find((n) => n?.id === currentNodeId);
//       console.log("Node", node);
//       if (!node) return;

//       // Collect "out" parameters from the node
//       const nodeParams = node?.data[0]?.params || [];
//       console.log("Node Params", nodeParams);
//       nodeParams
//         .filter((param) => param?.direction === 1) // Outgoing variables
//         .forEach((param) => {
//           variablePool?.push({
//             ...param,
//             originatingNode: node?.id,
//             originatingTaskName:
//               node?.data[0]?.title || node?.data[0]?.name || "Unnamed Node",
//           });
//         });

//       // Traverse upstream nodes using edges
//       edges
//         .filter((edge) => edge?.target === currentNodeId)
//         .forEach((edge) => traverseParents(edge?.source));
//     };

//     traverseParents(nodeId);
//     return variablePool;
//   };

//   const variablePool = gatherVariablePool(selectedNodeId, nodes, edges);

//   console.log("Variable Pool", variablePool);
//   // Group variables by originating node
//   const groupedVariables = variablePool.reduce((acc, variable) => {
//     const key = variable?.originatingNode;
//     if (!acc[key]) acc[key] = [];
//     acc[key].push(variable);
//     return acc;
//   }, {});

//   console.log("Grouped Variables", groupedVariables);

//   return (
//     <Box
//       sx={{
//         width: "300px",
//         backgroundColor: "#f9f9f9",
//         borderLeft: "1px solid #ddd",
//         padding: "10px",
//       }}
//     >
//       <Typography variant="h6" sx={{ mb: 2 }}>
//         Variable Pool
//       </Typography>
//       {Object.entries(groupedVariables).map(([nodeId, variables]) => {
//         console.log("nodeId", nodeId);
//         console.log("variables", variables);
//         return (
//           <CollapsibleSection
//             key={nodeId}
//             title={variables[0]?.originatingTaskName || "Unnamed Node"}
//             variables={variables}
//           />
//         );
//       })}
//     </Box>
//   );
// };

// const CollapsibleSection = ({ title, variables }) => {
//   console.log("variablesvariables ", variables);
//   const [isOpen, setIsOpen] = useState(false);

//   return (
//     <Box sx={{ mb: 2 }}>
//       <Typography
//         variant="h6"
//         onClick={() => setIsOpen(!isOpen)}
//         sx={{
//           backgroundColor: getColorByNode(title),
//           padding: "10px",
//           cursor: "pointer",
//           borderRadius: "4px",
//         }}
//       >
//         {title}
//       </Typography>
//       <Collapse in={isOpen}>
//         <List>
//           {variables.map((variable, index) => (
//             <ListItem key={index} sx={{ borderBottom: "1px solid #e0e0e0" }}>
//               <ListItemText
//                 primary={variable?.paramName || "Unnamed Variable"}
//                 secondary={`Type: ${
//                   JSON.parse(variable?.paramType || "{}").type || "Unknown"
//                 }`}
//               />
//             </ListItem>
//           ))}
//         </List>
//       </Collapse>
//     </Box>
//   );
// };

// const getColorByNode = (nodeId) => {
//   const colors = ["#FFC107", "#4CAF50", "#2196F3", "#FF5722"];
//   return colors[parseInt(nodeId, 10) % colors.length];
// };

// // PropTypes for type checking
// ConnectionDrawerComponent.propTypes = {
//   selectedNodeId: PropTypes.string.isRequired,
//   nodes: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.string.isRequired,
//       data: PropTypes.array.isRequired,
//     })
//   ).isRequired,
//   edges: PropTypes.arrayOf(
//     PropTypes.shape({
//       id: PropTypes.string.isRequired,
//       source: PropTypes.string.isRequired,
//       target: PropTypes.string.isRequired,
//     })
//   ).isRequired,
// };

export default ConnectionDrawerComponent;
