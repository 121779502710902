import React, { useState, useEffect, useContext, useMemo } from "react";
import { SplitScreen } from "./SplitScreen";
import CardContentData from "./CardContentData";
import { CircularProgress, Grid } from "@mui/material";
import SelectorData from "./SelectorData";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getCurrentDateTime } from "../apiCalls/DiagramsAPI";
import ErrorBanner from "../Error/ErrorBanner";

function CreateCards({
  handleDetails,
  handleEditDiagram,
  searchQuery,
  caller,
  handleClick,
  handleCreate,
  isMenuOpenleft,
  setOpenCreateInfo,
  openCreateInfo,
  setOpenViewCreateInfo,
  openViewCreateInfo,
  openDiscoverPage,
  setOpenDiscoverPage,
  authData,
  ReadAllData,
  defaultStyle,
  error,
  setError,
}) {
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [apiData, setApiData] = useState([]);
  const [loading, setLoading] = useState(true);

  let APIProvider = window.APIProvider;

  const baseURL = `${APIProvider.baseURL}${APIProvider.MethodName.GetQuickInfo}`;
  const userName = authData?.userName || "";
  useEffect(() => {
    async function fetchData() {
      try {
        const scriptElement = document.createElement("script");
        scriptElement.src = `FormJson.js?v=${Date.now()}`;

        scriptElement.onload = () => {
          const fetchedData = window.MyVar || [];
          // setData(fetchedData);

          const extractedData = fetchedData.map((item) => ({
            Name: item.Name,
            Explanation: item.Explanation,
            ArID: item.ArID,
            DiagID: item.DiagID,
          }));

          // setSelected(extractedData);
          setFetched(true);
          setLoading(false);
        };

        document.head.appendChild(scriptElement);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("An error occurred in UseEffect fetchData");
        localStorage.setItem(
          "error",
          "An error occurred in UseEffect fetchData"
        );
        localStorage.setItem("navigate", "/Open");
      }
    }
    if (!fetched) {
      fetchData();
    }
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (!authData || !authData.accessKey) {
      navigate("/");
      sessionStorage.clear();
    }
  }, [navigate]);

  const ReadArrayAllArID = async () => {
    try {
      console.log("ReadAllDatafromCreateCards", ReadAllData);
      setApiData(ReadAllData);
      selected
        .flat()
        .filter(
          (item) => !apiData.some((apiItem) => apiItem.ArID === item.ArID)
        );
    } catch (error) {
      console.error("Error during request:", error);
      setError("An error occurred during request");
      localStorage.setItem("error", "An error occurred during request");
      localStorage.setItem("navigate", "/Open");
    }
  };

  useEffect(() => {
    if (!authData || !authData.accessKey) {
      navigate("/");
      sessionStorage.clear();
      return;
    }
    ReadArrayAllArID();
  }, [ReadAllData]);

  const [showInactive, setShowInactive] = useState(false);

  const [favorites, setFavorites] = useState({});

  useEffect(() => {
    const handletoGetFavorite = async () => {
      try {
        const requestBody = {
          accessKey: authData.accessKey,
          guid: window.APIProvider.GUID.ReadArrayAllGUID,
          param1: "",
          param2: "",
          param3: "",
          param4: "",
          recordSet: "",
          body: JSON.stringify([{ username: authData.userName }]),
        };

        const response = await fetch(
          `${APIProvider.baseURL}${APIProvider.MethodName.PostQuickInfo}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        const resultData = JSON.parse(responseData.apiResponse);

        const favoriteData = resultData.reduce((acc, item) => {
          acc[item.ArID] = item.isFav;
          return acc;
        }, {});

        setApiData((prevApiData) =>
          prevApiData.map((item) => ({
            ...item,
            isFav:
              favoriteData[item.ArID] !== undefined
                ? favoriteData[item.ArID]
                : 0,
          }))
        );
      } catch (error) {
        console.error("Error in handletoGetFavorite:", error);
        toast.error("An error occurred while processing your request.");
      }
    };

    handletoGetFavorite();
  }, [authData]);

  const itemsToDisplay = useMemo(() => {
    const result = selected
      .flat()
      .filter((section) => {
        const matchesSearchQuery =
          section.ArrayName &&
          section.ArrayName.toLowerCase().includes(searchQuery.toLowerCase());
        const isInSelectedCategory = selected.some(
          (s) => s.Category === section.Category
        );
        const shouldInclude =
          isInSelectedCategory || showInactive || section.isInactive === 0;
        return matchesSearchQuery && shouldInclude;
      })
      .map((item) => ({
        ...item,
        isFav:
          apiData.find((dataItem) => dataItem.ArID === item.ArID)?.isFav || 0,
      }))
      .sort((a, b) =>
        a.IsTemplate !== b.IsTemplate
          ? a.IsTemplate
            ? 1
            : -1
          : a.ArID - b.ArID
      );

    return result;
  }, [selected, apiData, searchQuery, showInactive]);

  const handleDelete = (arIDToDelete) => {
    const updatedSelected = selected.filter(
      (item) => item.ArID !== arIDToDelete
    );
    setSelected(updatedSelected);

    const updatedApiData = apiData.filter((item) => item.ArID !== arIDToDelete);
    setApiData(updatedApiData);
  };

  const dateTime = getCurrentDateTime();

  const [datasourceNames, setDatasourceNames] = useState({});

  async function fetchArrayName(DataSourceArID) {
    const existingArrayName = apiData.find(
      (data) => data.ArID === DataSourceArID
    )?.ArrayName;
    if (existingArrayName) {
      setDatasourceNames((prevNames) => ({
        ...prevNames,
        [DataSourceArID]: existingArrayName,
      }));
      return;
    }
  }

  useEffect(() => {
    if (selected.length > 0) {
      selected.forEach((item) => {
        const currentArID = item.DataSourceArID;
        if (currentArID > 0 && !datasourceNames[currentArID]) {
          fetchArrayName(currentArID);
        }
      });
    }
  }, [selected]);




  return (
    <>
      {loading ? (
        <CircularProgress
          size={48}
          color="success"
          style={{
            position: "relative",
            minHeight: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        />
      ) : (
        <SplitScreen
          leftWeight={1}
          rightWeight={3}
          isMenuOpenleft={isMenuOpenleft}
        >
          <SelectorData
            apiData={fetched ? apiData : []}
            selected={fetched ? selected : []}
            setSelected={setSelected}
            handleDelete={handleDelete}
            showInactive={showInactive}
            setShowInactive={setShowInactive}
          />

          <Grid sx={{ flexGrow: 1 }} container padding={1}>
            {itemsToDisplay.map((item, index) => (
              <CardContentData
                key={index}
                data={item}
                size={325}
                textSize={17}
                isMobile={window.innerWidth < 750 ? true : false}
                handleDetails={handleDetails}
                handleEditDiagram={handleEditDiagram}
                // caller={caller}
                ArrayName={item.ArrayName}
                Description={item.Description}
                Category={item.Category}
                DataSourceArID={item.DataSourceArID}
                datasourcearrayName={datasourceNames[item.DataSourceArID] || ""}
                ArID={item.ArID}
                ModifiedBy={item.ModifiedBy}
                ModifiedDate={item.ModifiedDate}
                handleDelete={handleDelete}
                isTemplate={item.IsTemplate}
                handleClick={handleClick}
                handleCreate={handleCreate}
                setSelected={setSelected}
                setOpenCreateInfo={setOpenCreateInfo}
                openCreateInfo={openCreateInfo}
                setOpenViewCreateInfo={setOpenViewCreateInfo}
                openViewCreateInfo={openViewCreateInfo}
                openDiscoverPage={openDiscoverPage}
                setOpenDiscoverPage={setOpenDiscoverPage}
                //   isDeleteConfirmation={isDeleteConfirmation}
                // DeleteConfirmation={DeleteConfirmation}
                apiData={apiData}
                authData={authData}
                selected={selected}
                isInactive={item.isInactive}
                data1={data}
                setData={setData}
                setApiData={setApiData}
                isFav={item.isFav}
                defaultStyle={defaultStyle}
                ReadAllData={ReadAllData}
                error={error}
                setError={setError}
              />
            ))}
          </Grid>
        </SplitScreen>
      )}
      <ToastContainer position="bottom-right" autoClose={3000} />
      {error && <ErrorBanner />}
    </>
  );
}

export default CreateCards;
