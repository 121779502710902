import {
  Button,
  FormControl,
  IconButton,


  Tab,
  Tabs,
  TextField,
  
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import {

  ImageJSON,
  MiddleNameJSON,
  PrefixJSON,
} from "../IncludeFile/AddJSON";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { LuArrowDownToLine } from "react-icons/lu";
import { AiOutlineCaretDown } from "react-icons/ai";
import { findNodeById } from "../Api/ApiCalls";

const AdvanceSection = ({
  rightsideStyletab,

  selectedItem,
  selectedNode,
  treeData,
  inputRef,
  controlsVisible,
  setTreeData,
  selectedItemID,
  defaultStyle,
  editedDataFirstNameTextbox,
  setEditedDataFirstNameTextbox,
  editedDataLastNameTextbox,
  setEditedDataLastNameTextbox,
  CheckboxCheckedStreet1,
  CheckboxCheckedStreet2,
  CheckboxCheckedCity,

  CheckboxCheckedState,
  editedDataStreet1Textbox,
  setEditedDataStreet1Textbox,
  editedDataCityTextbox,
  setEditedDataCityTextbox,
  editedDataStateTextbox,
  setEditedDataStateTextbox,
  editedDataPostalTextbox,
  setEditedDataPostalTextbox,
  CheckboxCheckedPostal,
  dataSourceArID,
  fieldData,
  setFieldNames,
  fieldNames,
  updateCustomStyle,
  editedDataPrefixTextbox,
  setEditedDataPrefixTextbox,
  editedDataMiddleTextbox,
  setEditedDataMiddleTextbox,
  CheckboxCheckedMiddleName,
  setCheckboxCheckedMiddleName,
  CheckboxCheckedPrefix, 
  setCheckboxCheckedPrefix
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [imageWidth, setImageWidth] = useState(0);
  const [showTabs, setShowTabs] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [imageExist, setImageExist] = useState(false);
  const [imageName, setImagename] = useState("");
  const [alignMent, setAlignMent] = useState("");
  const [imagealignself, setImagealignself] = useState("");

  useEffect(() => {
    const fieldNames = fieldData
      ? fieldData
          .filter(
            (item) => item.elementtype === "field" && item.fieldtype === "text"
          )
          .map((item) => item.fieldname.toLowerCase())
      : [];

    setFieldNames(fieldNames);
  }, [fieldData]);

  // const [editedDate, setEditedDataDate] = useState("");
  // const [showCustomDateInput, setShowCustomDateInput] = useState(false);

  const [editedDate, setEditedDataDate] = useState("");
  const [editedDateDS, setEditedDataDateDS] = useState("");
  const [showCustomDateInput, setShowCustomDateInput] = useState(false);

  useEffect(() => {
    if (!editedDateDS && fieldNames.length > 0) {
      setEditedDataDateDS(`{${fieldNames[0].toLowerCase()}}`); // Set to the first field if not already set
    }
  }, [fieldNames]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const [isImageAdded, setIsImageAdded] = useState(false);

  const handleopenImageTab = () => {
    setShowTabs(true);
  };

  // const handleImageUploadClick = () => {
  //   inputRef.current.click();
  //   setTreeData((prevTreeData) => {
  //     const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));
  //     const matchingNode = findNodeById(
  //       updatedTreeData[0].Source[0].control,
  //       selectedItem.id
  //     );

  //     if (matchingNode) {
  //       setIsImageAdded((prev) => !prev);
  //       updatedTreeData[0].Source[0].control = addImageJSONToContainer(
  //         updatedTreeData[0].Source[0].control,
  //         "ImageContainer",
  //         ImageJSON
  //       );
  //     }

  //     return updatedTreeData;
  //   });
  // };

  // const addImageJSONToContainer = (controlArray, mediaToAdd, ImageJSON) => {
  //   return controlArray.map((control) => {
  //     if (
  //       control.controltype === "Heading" &&
  //       control.id === selectedItem.id
  //     ) {
  //       const childWithImageContainer = control.control.find(
  //         (child) => child.media === mediaToAdd
  //       );

  //       if (childWithImageContainer && childWithImageContainer.control.length === 0) {
  //         childWithImageContainer.styles = JSON.stringify({
  //           ...JSON.parse(childWithImageContainer.styles || "{}"),
  //           minWidth: "100px",
  //           minHeight: "40px",
  //           display: "flex",
  //         });

  //         // Calculate new style IDs for the ImageJSON
  //         const currentMaxStyleId = calculateNextStyleIdBlocks();
  //         const styleIdNumber = parseInt(
  //           currentMaxStyleId.replace(/[a-zA-Z]/g, "")
  //         );

  //         const styledImageJSON = ImageJSON.map((json, index) => {
  //           const newStyleId = `agrfb${styleIdNumber + index}`;
  //           return {
  //             ...applyStylesRecursively(json, defaultStyle),
  //             id: `${childWithImageContainer.id}.${index + 1}`,
  //             styleid: newStyleId,
  //           };
  //         });

  //         // Add ImageJSON as child controls to the ImageContainer
  //         childWithImageContainer.control.push(...styledImageJSON);
  //       }
  //     }

  //     // Recursively check for nested controls
  //     if (control.control && control.control.length > 0) {
  //       control.control = addImageJSONToContainer(
  //         control.control,
  //         mediaToAdd,
  //         ImageJSON
  //       );
  //     }

  //     return control;
  //   });
  // };

  const [editedDataLongTextTextbox, setEditedDataLongTextTextbox] =
    useState("");
  const [editedDataShortTextTextbox, setEditedDataShortTextTextbox] =
    useState("");

  const [editedDataPhoneTextbox, setEditedDataPhoneTextbox] = useState("");
  const [editedDataEmailTextbox, setEditedDataEmailTextbox] = useState("");
  const [editedDataImageHeading, setEditedDataImageHeading] = useState("");
  const [editedDataStreet2Textbox, setEditedDataStreet2Textbox] = useState("");

  const [editedDataPostalTextboxPresent, setEditedDataPostalTextboxPresent] =
    useState("");
  const [editedDataStreet1TextboxPresent, setEditedDataStreet1TextboxPresent] =
    useState("");
  const [editedDataStreet2TextboxPresent, setEditedDataStreet2TextboxPresent] =
    useState("");



  // ----------------------------midlde--------------------------------------

  const applyStylesRecursively = (control, defaultStyle, parentID = "") => {
    const defaultSty = defaultStyle.find(
      (style) => style.styleType === control.styleType
    );

    const newID = parentID
      ? `${parentID}.${control.id.split(".").pop()}`
      : control.id;

    let updatedStyles = { ...defaultSty?.style };

    const updatedControl = {
      ...control,
      id: newID,
      styles: JSON.stringify(updatedStyles),
    };

    if (updatedControl.control && updatedControl.control.length > 0) {
      updatedControl.control = updatedControl.control.map(
        (child, childIndex) => {
          const childNewID = `${newID}.${childIndex + 1}`;
          return applyStylesRecursively(
            { ...child, id: childNewID },
            defaultStyle,
            newID
          );
        }
      );
    }

    return updatedControl;
  };

  const calculateNextStyleIdBlocks = () => {
    const deletedItemId = localStorage.getItem("deletedItemId");

    const deletedItemNumber = deletedItemId
      ? parseInt(deletedItemId.replace(/[a-zA-Z]/g, ""))
      : -1;

    let maxStyleId = 0;

    const findMaxStyleId = (node) => {
      const styleIdRegex = /([a-zA-Z]+)(\d*)/;
      if (typeof node.styleid === "string") {
        const matches = node.styleid.match(styleIdRegex);
        if (matches && matches[1] === "agrfb") {
          const currentId = parseInt(matches[2]);
          if (!isNaN(currentId) && currentId > maxStyleId) {
            maxStyleId = currentId;
          }
        }
      }

      if (node.control && node.control.length > 0) {
        for (const childNode of node.control) {
          findMaxStyleId(childNode);
        }
      }
    };

    treeData[0].Source[0].control.forEach((rootNode) => {
      findMaxStyleId(rootNode);
    });

    const nextStyleId =
      deletedItemNumber > maxStyleId ? deletedItemNumber + 1 : maxStyleId + 1;

    return `agrfb${nextStyleId}`;
  };

  // const handleRemoveImageHeading = () => {
  //   setTreeData((prevTreeData) => {
  //     const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));

  //     const removeImageHeadingFromNode = (node, targetMedia, checkPosition) => {
  //       if (node.media === targetMedia) {
  //         // Filter out controls with checkposition: "ImageHeading"
  //         node.control = node.control.filter((child) => {
  //           if (child.checkposition === checkPosition) {
  //             console.log("Removing Node:", child);
  //             return false; // Remove this node
  //           }

  //           // Recursively check for nested controls
  //           if (child.control && child.control.length > 0) {
  //             removeImageHeadingFromNode(child, targetMedia, checkPosition);
  //           }
  //           return true; // Keep this node
  //         });
  //       }

  //       // Recursively apply the function to all child nodes
  //       if (node.control && node.control.length > 0) {
  //         node.control.forEach((childNode) => {
  //           removeImageHeadingFromNode(childNode, targetMedia, checkPosition);
  //         });
  //       }
  //     };

  //     // Find the matching node
  //     const matchingNode = findNodeById(
  //       updatedTreeData[0].Source[0].control,
  //       selectedItem.id
  //     );

  //     if (matchingNode) {
  //       removeImageHeadingFromNode(matchingNode, "ImageContainer", "ImageHeading");
  //     }
  // console.log("updatedTreeData",updatedTreeData)
  //     return updatedTreeData;
  //   });

  //   setIsImageAdded(false);
  // };


  //  -----------------------------Heading image------------

  const updateTreeDatanewValueAddressImage = (
    treeData,
    selectedItemID,
    newValue,
    newImagevalue
  ) => {
    return treeData.map((item) => {
      return {
        ...item,
        Source: item.Source.map((source) => ({
          ...source,
          control: updateControlsnewValueAddressImage(
            source.control,
            selectedItemID,
            newValue,
            newImagevalue
          ),
        })),
      };
    });
  };

  const updateControlsnewValueAddressImage = (
    controls,
    selectedItemID,
    newValue,
    newImagevalue
  ) => {
    return controls.map((control) => {
      if (control.id === selectedItemID) {
        return {
          ...control,
          control: updateControlsWithinAddressImage(
            control.control,
            newValue,
            newImagevalue
          ),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsnewValueAddressImage(
            control.control,
            selectedItemID,
            newValue,
            newImagevalue
          ),
        };
      }

      return control;
    });
  };

  const updateControlsWithinAddressImage = (
    controls,
    newValue,
    newImagevalue
  ) => {
    return controls.map((control) => {
      if (control.checkposition === "ImageHeading") {
        return {
          ...control,
          media: newValue,
          value: newImagevalue,
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsWithinAddressImage(
            control.control,
            newValue,
            newImagevalue
          ),
        };
      }

      return control;
    });
  };

  const findAddressImageData = (treeData, selectedItemID) => {
    for (let item of treeData) {
      for (let source of item.Source) {
        let foundData = findInControlsImage(source.control, selectedItemID);
        if (foundData) return foundData;
      }
    }
    return "";
  };

  const findInControlsImage = (controls, selectedItemID) => {
    for (let control of controls) {
      if (control.id === selectedItemID) {
        const addressImageControl = findAddressImageControl(control.control);
        if (addressImageControl) {
          return addressImageControl.media;
        }
      }
      if (control.control && control.control.length > 0) {
        let foundData = findInControlsImage(control.control, selectedItemID);
        if (foundData) return foundData;
      }
    }
    return null;
  };

  const findAddressImageControl = (controls) => {
    for (let control of controls) {
      if (control.checkposition === "ImageHeading") {
        return control;
      }
      if (control.control && control.control.length > 0) {
        let foundControl = findAddressImageControl(control.control);
        if (foundControl) return foundControl;
      }
    }
    return null;
  };

  const findAddressImageData1 = (treeData, selectedItemID) => {
    for (let item of treeData) {
      for (let source of item.Source) {
        let foundData = findInControlsImage1(source.control, selectedItemID);
        if (foundData) return foundData;
      }
    }
    return "";
  };

  const findInControlsImage1 = (controls, selectedItemID) => {
    for (let control of controls) {
      if (control.id === selectedItemID) {
        const addressImageControl1 = findAddressImageControl1(control.control);
        if (addressImageControl1) {
          return addressImageControl1.value;
        }
      }
      if (control.control && control.control.length > 0) {
        let foundData = findInControlsImage1(control.control, selectedItemID);
        if (foundData) return foundData;
      }
    }
    return null;
  };

  const findAddressImageControl1 = (controls) => {
    for (let control of controls) {
      if (control.checkposition === "ImageHeading") {
        return control;
      }
      if (control.control && control.control.length > 0) {
        let foundControl = findAddressImageControl1(control.control);
        if (foundControl) return foundControl;
      }
    }
    return null;
  };

  const handleFieldChangeAddressImage = (event) => {
    const file = event.target.files[0];

    setImagename(file.name);
    if (!file) return;

    // First, update the treeData to add ImageJSON at the desired location
    setTreeData((prevTreeData) => {
      const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));

      // Find the matching node in treeData using selectedItemID
      const matchingNode = findNodeById(
        updatedTreeData[0].Source[0].control,
        selectedItemID
      );

      if (matchingNode) {
        // Add ImageJSON before adding the media URL
        updatedTreeData[0].Source[0].control = addImageJSONToContainer(
          updatedTreeData[0].Source[0].control,
          "ImageContainer", // Adjust this key based on your logic
          ImageJSON // Insert the ImageJSON structure
        );
      } else {
        console.log("No matching node found.");
        return updatedTreeData;
      }

      // Proceed to load the image from the file picker and update the media field
      const reader = new FileReader();

      reader.onload = () => {
        const imageUrl = reader.result;

        // Now update the treeData with the media URL
        const updatedTreeDataWithMedia = updateTreeDatanewValueAddressImage(
          updatedTreeData, // Use the updated treeData with ImageJSON added
          selectedItemID, // Use the correct selectedItemID for your logic
          imageUrl,
          file.name // Pass the image URL as newValue to update media
        );

        setTreeData(updatedTreeDataWithMedia);
      };

      reader.readAsDataURL(file); // Convert the file to a data URL

      return updatedTreeData; // Return the updated treeData with ImageJSON added
    });
  };

  const handleUrlChange = (event) => {
    setEditedDataImageHeading(event.target.value);
  };

  const handleUrlSubmit = () => {
    if (editedDataImageHeading) {
      try {
        new URL(editedDataImageHeading);
        const updatedTreeData = JSON.parse(JSON.stringify(treeData));

        // Find the matching node by ID in the tree data
        const matchingNode = findNodeById(
          updatedTreeData[0].Source[0].control,
          selectedItem.id
        );

        if (matchingNode) {
          updatedTreeData[0].Source[0].control = addImageJSONToContainer(
            updatedTreeData[0].Source[0].control,
            "ImageContainer",
            [
              {
                ...ImageJSON[0], // Assuming ImageJSON contains default image properties

                media: editedDataImageHeading, // Update the media property with the URL
              },
            ]
          );

          setTreeData(updatedTreeData);
          setEditedDataImageHeading("");
        } else {
          alert("Could not find matching node");
        }
      } catch (e) {
        alert("Invalid URL");
      }
    }
  };

  const handleImageUploadClick = () => {
    inputRef.current.click();
  };

  const addImageJSONToContainer = (controlArray, mediaToAdd, ImageJSON) => {
    return controlArray.map((control) => {
      if (control.controltype === "Heading" && control.id === selectedItem.id) {
        const childWithImageContainer = control.control.find(
          (child) => child.media === mediaToAdd
        );

        if (
          childWithImageContainer &&
          childWithImageContainer.control.length === 0
        ) {
          if (childWithImageContainer.media === "ImageContainer") {
            childWithImageContainer.styles = JSON.stringify({
              ...JSON.parse(childWithImageContainer.styles || "{}"),
              display: "flex",
            });
          }

          const currentMaxStyleId = calculateNextStyleIdBlocks();
          const styleIdNumber = parseInt(
            currentMaxStyleId.replace(/[a-zA-Z]/g, "")
          );

          // Add ImageJSON with updated styles
          const styledImageJSON = ImageJSON.map((json, index) => {
            const newStyleId = `agrfb${styleIdNumber + index}`;
            return {
              ...applyStylesRecursively(json, defaultStyle),
              id: `${childWithImageContainer.id}.${index + 1}`,
              styleid: newStyleId,
            };
          });

          childWithImageContainer.control.push(...styledImageJSON);
        }
      }

      // Recursively apply to child controls
      if (control.control && control.control.length > 0) {
        control.control = addImageJSONToContainer(
          control.control,
          mediaToAdd,
          ImageJSON
        );
      }

      return control;
    });
  };

  const doesImageJSONExist = (treeData, selectedItemID) => {
    // Function to find the node that matches selectedItem.id

    const matchingNode = findNodeById(
      treeData[0].Source[0].control,
      selectedItemID
    );

if (matchingNode && matchingNode.control) {
  // Check if the ImageContainer has any images in its control array
  const imageContainer = matchingNode.control.find(
    (control) => control.media === "ImageContainer"
  );


  if (imageContainer && imageContainer.control && imageContainer.control.length > 0) {
    // If there's an ImageContainer and it contains controls (i.e., ImageJSON), return true
    return true;
  }
}

    // If no ImageContainer or it contains no controls, return false
    return false;
  };

  useEffect(() => {
    if (treeData && selectedItemID) {
      const imageExists = doesImageJSONExist(treeData, selectedItemID);
      setImageExist(imageExists);
    }
  }, [treeData, selectedItemID]);

  const handleRemoveImageHeading = () => {
    setTreeData((prevTreeData) => {
      const updatedTreeData = JSON.parse(JSON.stringify(prevTreeData));

      const removeImageHeadingFromNode = (node, targetMedia, checkPosition) => {
        if (node.media === targetMedia) {
          node.styles = JSON.stringify({
            ...JSON.parse(node.styles || "{}"),
            minWidth: "50px",
            minHeight: "30px",
            display: "none",
          });

          console.log(`Updated styles: ${node.styles}`);
        }

        if (node.media === targetMedia) {
          // Filter out controls with checkposition: "ImageHeading"
          node.control = node.control.filter((child) => {
            if (child.checkposition === checkPosition) {
              return false; // Remove this node
            }

            // Recursively check for nested controls
            if (child.control && child.control.length > 0) {
              removeImageHeadingFromNode(child, targetMedia, checkPosition);
            }
            return true; // Keep this node
          });
        }

        // Recursively apply the function to all child nodes
        if (node.control && node.control.length > 0) {
          node.control.forEach((childNode) => {
            removeImageHeadingFromNode(childNode, targetMedia, checkPosition);
          });
        }
      };

      // Find the matching node
      const matchingNode = findNodeById(
        updatedTreeData[0].Source[0].control,
        selectedItem.id
      );

      if (matchingNode) {
        removeImageHeadingFromNode(
          matchingNode,
          "ImageContainer",
          "ImageHeading"
        );
      }
      console.log("updatedTreeData", updatedTreeData);
      return updatedTreeData;
    });

    setIsImageAdded(false);
  };

  const handleChangeImageWidth = (e) => {
    const newValue = e.target.value;
    setImageWidth(newValue); // Update the imageWidth state with the new value

    const matchingNode = findNodeById(
      treeData[0].Source[0].control,
      selectedItemID
    );

    if (matchingNode) {
      // Find the ImageContainer inside the matching node
      const imageContainer = matchingNode.control.find(
        (control) => control.media === "ImageContainer"
      );

      if (imageContainer) {
        // Find the control where checkposition is "ImageHeading"
        const imageHeadingControl = imageContainer.control.find(
          (control) => control.checkposition === "ImageHeading"
        );

        if (imageHeadingControl) {
          const styles = JSON.parse(imageHeadingControl.styles);

          // Set the current width from the styles
          if (styles.width && !imageWidth) {
            setImageWidth(parseInt(styles.width)); // Set the initial width value
          }

          // Update the width and height in the styles with new value
          styles.width = `${newValue}px`;
          styles.height = `${newValue}px`;

          // Assuming updateCustomStyle updates the styles in treeData
          updateCustomStyle(imageHeadingControl.styleid, styles);
        } else {
          console.log("No control with checkposition 'ImageHeading' found.");
        }
      } else {
        console.log("No ImageContainer found.");
      }
    } else {
      console.log("No matching node found.");
    }
  };

  function collectStylesofcontainerforAlignment(node, result = []) {
    if (!node) return result; // Check if the node is valid

    if (node.media === "HeadingContainer") {
      result.push({
        styleid: node.styleid,
        styles: node.styles,
      });
    }

    // Recursively call for child nodes if they exist
    if (Array.isArray(node.control) && node.control.length > 0) {
      node.control.forEach((child) =>
        collectStylesofcontainerforAlignment(child, result)
      );
    }

    return result;
  }

  const matchingNode = findNodeById(
    treeData[0].Source[0].control,
    selectedItem.id
  );

  const stylesofcontainerForAlignment = matchingNode
    ? collectStylesofcontainerforAlignment(matchingNode)
    : [];

  function applyFontColorToStylesconatiner(stylesArray, alignment) {
    stylesArray.forEach((style) => {
      let styles;
      try {
        styles = JSON.parse(style.styles); // Parse styles safely
      } catch (e) {
        console.error("Failed to parse styles:", style.styles);
        return; // Skip to the next style if parsing fails
      }
      styles.flexDirection = alignment;

      setAlignMent(styles.flexDirection);
      updateCustomStyle(style.styleid, styles);
    });
  }

  const handleAlignmentChangeHeading = (alignment) => {
    applyFontColorToStylesconatiner(stylesofcontainerForAlignment, alignment);
  };

  useEffect(() => {
    // Assuming stylesArray is updated elsewhere, we parse the first element's textAlign if it exists
    if (
      stylesofcontainerForAlignment &&
      stylesofcontainerForAlignment.length > 0
    ) {
      let styles;
      try {
        styles = JSON.parse(stylesofcontainerForAlignment[0].styles); // Parse styles safely
        if (styles.flexdirection) {
          setAlignMent(styles.flexdirection); // Set the alignment state
        }
      } catch (e) {
        console.error(
          "Failed to parse styles:",
          stylesofcontainerForAlignment[0].styles
        );
      }
    }
  }, [stylesofcontainerForAlignment]);

  const handleChangeimagealignself = (newAlignSelf) => {
    // Update the imagealignself state with the new value
    setImagealignself(newAlignSelf);

    const matchingNode = findNodeById(
      treeData[0].Source[0].control,
      selectedItemID
    );

    if (matchingNode) {
      const labelHeadingControl = matchingNode.control.find(
        (control) => control.checkposition === "LabelHeading"
      );

      if (labelHeadingControl) {
        const styles = labelHeadingControl.styles
          ? JSON.parse(labelHeadingControl.styles)
          : {};

        styles.alignSelf = newAlignSelf;

        updateCustomStyle(labelHeadingControl.styleid, styles);
      } else {
        console.log("No control with checkposition 'LabelHeading' found.");
      }
    } else {
      console.log("No matching node found in treeData.");
    }
  };

  const checkImageWidth = (treeData) => {
    const controls = treeData[0].Source[0].control;
    const relevantControl = controls.find(
      (control) => control.id === selectedItem.id
    );

    if (relevantControl) {
      const childControl = relevantControl.control.find(
        (child) => child.media === "ImageContainer"
      );

      if (childControl) {
        // Find the control where checkposition is "ImageHeading"
        const imageHeadingControl = childControl.control.find(
          (control) => control.checkposition === "ImageHeading"
        );

        if (imageHeadingControl) {
          const existingStyles = imageHeadingControl.styles
            ? JSON.parse(imageHeadingControl.styles)
            : {};

          return existingStyles.width || "auto";
        }
      }
    }
  };

  useEffect(() => {
    const imageWidthcheck = checkImageWidth(treeData);
    const numericWidth = imageWidthcheck
      ? parseInt(imageWidthcheck.replace("px", ""))
      : 0;
    setImageWidth(numericWidth);
  }, [treeData, selectedItem]);

  useEffect(() => {
    if (selectedItem?.controltype === "Heading") {
      const initialDataImageHeading = findAddressImageData(
        treeData,
        selectedItemID
      );
      setEditedDataImageHeading(initialDataImageHeading);
      const initialDataImageHeading1 = findAddressImageData1(
        treeData,
        selectedItemID
      );
      setImagename(initialDataImageHeading1);
    }
  }, [treeData, selectedItemID, selectedItem]);

  const updateTreeData = (
    treeData,
    selectedItemID,
    newValue,
    checkposition
  ) => {
    return treeData.map((item) => ({
      ...item,
      Source: item.Source.map((source) => ({
        ...source,
        control: updateControls(
          source.control,
          selectedItemID,
          newValue,
          checkposition
        ),
      })),
    }));
  };

  const updateControls = (
    controls,
    selectedItemID,
    newValue,
    checkposition
  ) => {
    return controls.map((control) => {
      if (control.id === selectedItemID) {
        return {
          ...control,
          control: updateControlsWithin(
            control.control,
            newValue,
            checkposition
          ),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControls(
            control.control,
            selectedItemID,
            newValue,
            checkposition
          ),
        };
      }

      return control;
    });
  };

  const updateControlsWithin = (controls, newValue, checkposition) => {
    return controls.map((control) => {
      if (control.checkposition === checkposition) {
        return {
          ...control,
          data: newValue,
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsWithin(
            control.control,
            newValue,
            checkposition
          ),
        };
      }

      return control;
    });
  };

  const findData = (treeData, selectedItemID, checkposition) => {
    for (let item of treeData) {
      for (let source of item.Source) {
        let foundData = findInControls(
          source.control,
          selectedItemID,
          checkposition
        );
        const matchingNode = findNodeById(
          treeData[0]?.Source[0]?.control,
          selectedItem.id
        );

        if (foundData === "Middle Name" && matchingNode) {
        
          setCheckboxCheckedMiddleName(true);
        }

        if (foundData) return foundData;
      }
    }
    return "";
  };

  const findInControls = (controls, selectedItemID, checkposition) => {
    for (let control of controls) {
      if (control.id === selectedItemID) {
        const foundControl = findControl(control.control, checkposition);
        if (foundControl) {
          return foundControl.data;
        }
      }
      if (control.control && control.control.length > 0) {
        let foundData = findInControls(
          control.control,
          selectedItemID,
          checkposition
        );
        if (foundData) return foundData;
      }
    }
    return null;
  };

  const findControl = (controls, checkposition) => {
    for (let control of controls) {
      if (control.checkposition === checkposition) {
        return control;
      }
      if (control.control && control.control.length > 0) {
        let foundControl = findControl(control.control, checkposition);
        if (foundControl) return foundControl;
      }
    }
    return null;
  };

  const findData1 = (treeData, selectedItemID, checkposition) => {
    for (let item of treeData) {
      for (let source of item.Source) {
        let foundData = findInControls1(
          source.control,
          selectedItemID,
          checkposition
        );
        const matchingNode = findNodeById(
          treeData[0]?.Source[0]?.control,
          selectedItem.id
        );

        if (foundData === "Middle Name" && matchingNode) {
          setCheckboxCheckedMiddleName(true);
        }

        if (foundData) return foundData;
      }
    }
    return "";
  };

  const findInControls1 = (controls, selectedItemID, checkposition) => {
    for (let control of controls) {
      if (control.id === selectedItemID) {
        const foundControl = findControl(control.control, checkposition);
        if (foundControl) {
          return foundControl.sourceData;
        }
      }
      if (control.control && control.control.length > 0) {
        let foundData = findInControls1(
          control.control,
          selectedItemID,
          checkposition
        );
        if (foundData) return foundData;
      }
    }
    return null;
  };

  useEffect(() => {
    if (selectedItem?.controltype === "Address") {
      setEditedDataStreet1Textbox(
        findData(treeData, selectedItemID, "AddressStreet1Textbox")
      );

      const dataStreet1Textbox = findData(
        treeData,
        selectedItemID,
        "AddressStreet1Textbox"
      );

      // Ensure `dataStreet1Textbox` is not undefined or null instead of checking its content directly
      if (dataStreet1Textbox !== null && dataStreet1Textbox !== undefined) {
        setEditedDataStreet1TextboxPresent(true);
      } else {
        setEditedDataStreet1TextboxPresent(false);
      }

      setEditedDataStreet2Textbox(
        findData(treeData, selectedItemID, "AddressStreet2Textbox")
      );

      const dataStreet2Textbox = findData(
        treeData,
        selectedItemID,
        "AddressStreet2Textbox"
      );
      setEditedDataStreet2TextboxPresent(dataStreet2Textbox !== "");

      setEditedDataCityTextbox(
        findData(treeData, selectedItemID, "CityTextbox")
      );
      setEditedDataStateTextbox(
        findData(treeData, selectedItemID, "StateTextbox")
      );
      setEditedDataPostalTextbox(
        findData(treeData, selectedItemID, "PostalTextbox")
      );

      const dataPostalTextbox = findData(
        treeData,
        selectedItemID,
        "PostalTextbox"
      );
      setEditedDataPostalTextboxPresent(dataPostalTextbox !== "");
    }

    if (selectedItem?.controltype === "LongText") {
      setEditedDataLongTextTextbox(
        findData(treeData, selectedItemID, "LongTextTextbox")
      );
    }

    if (selectedItem?.controltype === "ShortText") {
      setEditedDataShortTextTextbox(
        findData(treeData, selectedItemID, "ShortTextTextBox")
      );
    }

    if (selectedItem?.controltype === "PhoneNumber") {
      setEditedDataPhoneTextbox(
        findData(treeData, selectedItemID, "PhoneTextBox")
      );
    }

    if (selectedItem?.controltype === "Email") {
      setEditedDataEmailTextbox(
        findData(treeData, selectedItemID, "EmailTextBox")
      );
    }
  }, [treeData, selectedItemID, selectedItem]);

  //------------------------editedData for Date---------------------------------------//
  const handleFieldChange = (event, checkposition, setter) => {
    const newValue = event.target.value;
    setter(newValue);
    const updatedTreeData = updateTreeData(
      treeData,
      selectedItemID,
      newValue,
      checkposition
    );
    setTreeData(updatedTreeData);
  };

  const updateTreeDatanewValueDate = (treeData, selectedItemID, newValue) => {
    return treeData.map((item) => {
      return {
        ...item,
        Source: item.Source.map((source) => ({
          ...source,
          control: updateControlsnewValueDate(
            source.control,
            selectedItemID,
            newValue
          ),
        })),
      };
    });
  };

  const updateControlsnewValueDate = (controls, selectedItemID, newValue) => {
    return controls.map((control) => {
      if (control.id === selectedItemID) {
        return {
          ...control,
          control: updateControlsWithinDate(control.control, newValue),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsnewValueDate(
            control.control,
            selectedItemID,
            newValue
          ),
        };
      }

      return control;
    });
  };

  const updateControlsWithinDate = (controls, newValue) => {
    return controls.map((control) => {
      if (control.checkposition === "DateField") {
        return {
          ...control,
          data: newValue,
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsWithinDate(control.control, newValue),
        };
      }

      return control;
    });
  };

  const findDateData = (treeData, selectedItemID) => {
    for (let item of treeData) {
      for (let source of item.Source) {
        let foundData = findInControlsDate(source.control, selectedItemID);
        if (foundData) return foundData;
      }
    }
    return "";
  };

  const findInControlsDate = (controls, selectedItemID) => {
    for (let control of controls) {
      if (control.id === selectedItemID) {
        const DateControl = findDateControl(control.control);
        if (DateControl) {
          return DateControl.data;
        }
      }
      if (control.control && control.control.length > 0) {
        let foundData = findInControlsDate(control.control, selectedItemID);
        if (foundData) return foundData;
      }
    }
    return null;
  };

  const findDateControl = (controls) => {
    for (let control of controls) {
      if (control.checkposition === "DateField") {
        return control;
      }
      if (control.control && control.control.length > 0) {
        let foundControl = findDateControl(control.control);
        if (foundControl) return foundControl;
      }
    }
    return null;
  };

  const handleFieldChangeDate = () => {
    const newValue = "";
    setShowCustomDateInput(false);

    setEditedDataDate(newValue);
    const updatedTreeDataDate = updateTreeDatanewValueDate(
      treeData,
      selectedItemID,
      newValue
    );
    setTreeData(updatedTreeDataDate);
  };

  const handleFieldChangeCurrent = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0];
    setShowCustomDateInput(false);

    setEditedDataDate(formattedDate);
    const updatedTreeDataDate = updateTreeDatanewValueDate(
      treeData,
      selectedItemID,
      formattedDate
    );
    setTreeData(updatedTreeDataDate);
  };

  const handleCustomDateChange = (event) => {
    const selectedDate = event.target.value;
    setEditedDataDate(selectedDate);

    const updatedTreeDataDate = updateTreeDatanewValueDate(
      treeData,
      selectedItemID,
      selectedDate
    );
    setTreeData(updatedTreeDataDate);
  };

  useEffect(() => {
    if (selectedItem?.controltype === "DateBlock") {
      const initialDataDate = findDateData(treeData, selectedItemID);
      setEditedDataDate(initialDataDate);
    }
  }, [treeData, selectedItemID, selectedItem]);

  // -------------------------CheckBox--------------------------

  const [selectedValueCheckBox, setSelectedValuCheckBox] = useState("");

  const findControlById = (controlArray, id) => {
    for (let control of controlArray) {
      if (control.data === id) {
        return control;
      }
      if (control.control && control.control.length > 0) {
        const foundControl = findControlById(control.control, id);
        if (foundControl) {
          return foundControl;
        }
      }
    }
    return null;
  };

  const findAndUpdateControlById = (controlArray, id) => {
    for (let control of controlArray) {
      if (control.data === id) {
        control.check = !control.check;
        return control;
      }
      if (control.control && control.control.length > 0) {
        const foundControl = findAndUpdateControlById(control.control, id);
        if (foundControl) {
          return foundControl;
        }
      }
    }
    return null;
  };

  const handleCheckBoxChange = (id) => {
    const updatedTreeData = [...treeData];
    const matchingNode = findNodeById(
      updatedTreeData[0]?.Source[0]?.control,
      selectedItem.id
    );

    if (matchingNode) {
      if (matchingNode.control && Array.isArray(matchingNode.control)) {
        findAndUpdateControlById2(matchingNode.control, id);
      } else {
        console.error(
          "Matching node control is not an array:",
          matchingNode.control
        );
      }
      setTreeData((prevTreeData) => {
        return prevTreeData.map((node) =>
          node.id === selectedItem.id ? matchingNode : node
        );
      });
    } else {
      console.error("No matching node found for the selected ID");
    }
  };

  const [selectedValue, setSelectedValue] = useState("");
  const [radioControls, setRadioControls] = useState([]);
  const [CheckBoxControls, setCheckBoxControls] = useState([]);

  const getRadioControls = (controlArray, result = []) => {
    if (!Array.isArray(controlArray)) {
      return result;
    }

    controlArray.forEach((control) => {
      // Check if the control has a media of "Radio"
      if (control.media === "Radio") {
        result.push(control);
      }
      // Recursively check if there are nested controls
      if (control.control && control.control.length > 0) {
        getRadioControls(control.control, result);
      }
    });

    return result;
  };

  // Recursive function to get CheckBox controls
  const getCheckBoxControls = (controlArray, result = []) => {
    if (!Array.isArray(controlArray)) {
      return result;
    }

    controlArray.forEach((control) => {
      // Check if the control has a media of "Radio"
      if (control.media === "CheckBox") {
        result.push(control);
      }
      // Recursively check if there are nested controls
      if (control.control && control.control.length > 0) {
        getCheckBoxControls(control.control, result);
      }
    });

    return result;
  };

  useEffect(() => {
    if (selectedItem) {
      const selectedItemId = selectedItem.id;
      const matchingNode = findNodeById(
        treeData[0]?.Source[0]?.control,
        selectedItemId
      );

      if (matchingNode) {
        const radioControlsList = getRadioControls(matchingNode.control);
        setRadioControls(radioControlsList); // Store the radio controls in state

        // Find the radio control with check: true and set its data as the selectedValue
        const checkedControl = radioControlsList.find(
          (control) => control.check === true
        );
        if (checkedControl) {
          setSelectedValue(checkedControl.data);
        } else {
          setSelectedValue(""); // Reset if no checked control is found
        }
      } else {
        setRadioControls([]);
        setSelectedValue(""); // Reset if no matching node is found
      }
    }
  }, [selectedItem, treeData]);

  const findAndUpdateControlById1 = (controlArray, id) => {
    if (!Array.isArray(controlArray)) {
      console.error("controlArray is not an array:", controlArray);
      return; // Exit if not an array
    }

    let foundControl = null;

    for (let control of controlArray) {
      if (control.media === "Radio") {
        if (control.data === id) {
          foundControl = control; // Store the control that matches the selected ID
        } else {
          control.check = false; // Uncheck only other radio buttons within the same group
        }
      }

      if (control.control && control.control.length > 0) {
        findAndUpdateControlById1(control.control, id);
      }
    }

    if (foundControl) {
      foundControl.check = true;
    }
  };

  // ---------------------------------------------

  useEffect(() => {
    if (selectedItem) {
      const selectedItemId = selectedItem.id;
      const matchingNode = findNodeById(
        treeData[0]?.Source[0]?.control,
        selectedItemId
      );

      if (matchingNode) {
        const CheckBoxControlsList = getCheckBoxControls(matchingNode.control);
        setCheckBoxControls(CheckBoxControlsList); // Store the radio controls in state

        // Find the radio control with check: true and set its data as the selectedValue
        const checkedControl = CheckBoxControlsList.find(
          (control) => control.check === true
        );
        if (checkedControl) {
          setSelectedValuCheckBox(checkedControl.data);
        } else {
          setSelectedValuCheckBox(""); // Reset if no checked control is found
        }
      } else {
        console.log("No matching node found for the selected ID");
        setCheckBoxControls([]);
        setSelectedValuCheckBox(""); // Reset if no matching node is found
      }
    }
  }, [selectedItem, treeData]);

  const findAndUpdateControlById2 = (controlArray, id) => {
    if (!Array.isArray(controlArray)) {
      console.error("controlArray is not an array:", controlArray);
      return; // Exit if not an array
    }

    let foundControl = null;

    for (let control of controlArray) {
      if (control.media === "CheckBox") {
        if (control.data === id) {
          foundControl = control;
        }
      }

      if (control.control && control.control.length > 0) {
        findAndUpdateControlById2(control.control, id);
      }
    }

    if (foundControl) {
      foundControl.check = !foundControl.check;
    }
  };

  //-------------------

  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    setSelectedValue(selectedId);

    // Make a deep copy of the `treeData` to avoid mutating the original state directly
    const updatedTreeData = [...treeData];

    // Find the node matching `selectedItem.id`
    const matchingNode = findNodeById(
      updatedTreeData[0]?.Source[0]?.control,
      selectedItem.id
    );

    if (matchingNode) {
      // Update the matching node's controls by finding and updating the radio control with selectedId
      if (matchingNode.control && Array.isArray(matchingNode.control)) {
        findAndUpdateControlById1(matchingNode.control, selectedId);
      } else {
        console.error(
          "Matching node control is not an array:",
          matchingNode.control
        );
      }

      // Update the `treeData` state with the updated node
      setTreeData((prevTreeData) => {
        return prevTreeData.map((node) =>
          node.id === selectedItem.id ? matchingNode : node
        );
      });
    } else {
      console.error("No matching node found for the selected ID");
    }
  };

  const handleSelectChangeCheckBox = (event) => {
    const selectedId = event.target.value;
    setSelectedValuCheckBox(selectedId);

    // Make a deep copy of the `treeData` to avoid mutating the original state directly
    const updatedTreeData = [...treeData];

    // Find the node matching `selectedItem.id`
    const matchingNode = findNodeById(
      updatedTreeData[0]?.Source[0]?.control,
      selectedItem.id
    );

    if (matchingNode) {
      // Update the matching node's controls by finding and updating the radio control with selectedId
      if (matchingNode.control && Array.isArray(matchingNode.control)) {
        findAndUpdateControlById2(matchingNode.control, selectedId);
      } else {
        console.error(
          "Matching node control is not an array:",
          matchingNode.control
        );
      }

      // Update the `treeData` state with the updated node
      setTreeData((prevTreeData) => {
        return prevTreeData.map((node) =>
          node.id === selectedItem.id ? matchingNode : node
        );
      });
    } else {
      console.error("No matching node found for the selected ID");
    }
  };

  // Data source for textbox

  useEffect(() => {
    const fieldNames = fieldData
      ? fieldData
          .filter(
            (item) => item.elementtype === "field" && item.fieldtype === "text"
          )
          .map((item) => item.fieldname.toLowerCase())
      : [];

    setFieldNames(fieldNames);
  }, [fieldData]);

  return (
    <React.Fragment>
      {controlsVisible &&
        rightsideStyletab === "4" &&
        (() => {
          const targetElement = document.getElementById(
            rightsideStyletab + "_tab"
          );
          if (!targetElement) {
            console.error(
              `Target container with id ${rightsideStyletab + "_tab"} not found`
            );
            return null;
          }

          return createPortal(
            <>
              {selectedItem &&
                selectedItem.controltype === "Address" &&
                controlsVisible && (
                  <div>
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="label-algnmt">
                          <div className="inpt-fild-test">Sublabels</div>
                          <div className="advnce-field-stree ">
                            {/* {CheckboxCheckedStreet1 && (
                        <>
                          <div className="fld-advnce">
                            <div className="fld-name">Field Street1</div>
                            <TextField
                              className="inpt-text-fld-name"
                              value={editedDataStreet1Textbox}
                              onChange={(e) =>
                                handleFieldChange(
                                  e,
                                  "AddressStreet1Textbox",
                                  setEditedDataStreet1Textbox
                                )
                              }
                              InputProps={{
                                classes: { input: "fild-inpt" },
                              }}
                            />
                          </div>
                        </>
                      )} */}

                            {CheckboxCheckedStreet1 && (
                              <>
                                <div className="fld-advnce">
                                  <div className="fld-name">Field Street1</div>
                                  <TextField
                                    className="inpt-text-fld-name"
                                    value={editedDataStreet1Textbox}
                                    onChange={(e) =>
                                      handleFieldChange(
                                        e,
                                        "AddressStreet1Textbox",
                                        setEditedDataStreet1Textbox
                                      )
                                    }
                                    InputProps={{
                                      classes: { input: "fild-inpt" },
                                    }}
                                  />
                                </div>
                              </>
                            )}

                            {CheckboxCheckedStreet2 && (
                              <>
                                <div className="fld-advnce">
                                  <div className="fld-name">Field Street2</div>
                                  <TextField
                                    className="inpt-text-fld-name"
                                    value={editedDataStreet2Textbox}
                                    onChange={(e) =>
                                      handleFieldChange(
                                        e,
                                        "AddressStreet2Textbox",
                                        setEditedDataStreet2Textbox
                                      )
                                    }
                                    InputProps={{
                                      classes: { input: "fild-inpt" },
                                    }}
                                  />
                                </div>
                              </>
                            )}

                            {CheckboxCheckedCity && (
                              <>
                                <div className="fld-advnce">
                                  <div className="fld-name">Field City</div>
                                  <TextField
                                    className="inpt-text-fld-name"
                                    value={editedDataCityTextbox}
                                    onChange={(e) =>
                                      handleFieldChange(
                                        e,
                                        "CityTextbox",
                                        setEditedDataCityTextbox
                                      )
                                    }
                                    InputProps={{
                                      classes: { input: "fild-inpt" },
                                    }}
                                  />
                                </div>
                              </>
                            )}

                            {CheckboxCheckedState && (
                              <>
                                <div className="fld-advnce">
                                  <div className="fld-name">Field State</div>
                                  <TextField
                                    className="inpt-text-fld-name"
                                    value={editedDataStateTextbox}
                                    onChange={(e) =>
                                      handleFieldChange(
                                        e,
                                        "StateTextbox",
                                        setEditedDataStateTextbox
                                      )
                                    }
                                    InputProps={{
                                      classes: { input: "fild-inpt" },
                                    }}
                                  />
                                </div>
                              </>
                            )}

                            {CheckboxCheckedPostal && (
                              <>
                                <div className="fld-advnce">
                                  <div className="fld-name">Field Postal</div>
                                  <TextField
                                    className="inpt-text-fld-name"
                                    value={editedDataPostalTextbox}
                                    onChange={(e) =>
                                      handleFieldChange(
                                        e,
                                        "PostalTextbox",
                                        setEditedDataPostalTextbox
                                      )
                                    }
                                    InputProps={{
                                      classes: { input: "fild-inpt" },
                                    }}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {dataSourceArID !== 0 && (
                      <>
                        <div className="clr-schm-option">
                          <div className="drowerseparater">
                            <div className="label-algnmt">
                              <div className="inpt-fild-test">Data Source</div>
                              <div className="advnce-field-stree ">
                                {CheckboxCheckedStreet1 && (
                                  <>
                                    <div className="fld-advnce-data">
                                      <div className="fld-name">
                                        Field Street1
                                      </div>
                                      <FormControl>
                                        <select
                                          className="theme-input-slt-dtsrc"
                                          value={editedDataStreet1Textbox}
                                          onChange={(e) =>
                                            handleFieldChange(
                                              {
                                                target: {
                                                  value: e.target.value,
                                                },
                                              },
                                              "AddressStreet1Textbox",
                                              setEditedDataStreet1Textbox
                                            )
                                          }
                                          MenuProps={{
                                            PaperProps: {
                                              className: "dt-dropdn-menu",
                                            },
                                            MenuListProps: {
                                              className: "custom-menu-list",
                                            },
                                          }}
                                        >
                                          <option value="">
                                            select DataSource
                                          </option>
                                          {fieldNames.map(
                                            (fieldName, index) => (
                                              <option
                                                key={index}
                                                value={`{${fieldName.toLowerCase()}}`}
                                                className="mui_root_name"
                                              >
                                                {fieldName}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </FormControl>
                                    </div>
                                  </>
                                )}
                                {CheckboxCheckedStreet2 && (
                                  <>
                                    <div className="fld-advnce-data">
                                      <div className="fld-name">
                                        Field Street2
                                      </div>
                                      <FormControl>
                                        <select
                                          className="theme-input-slt-dtsrc"
                                          value={editedDataStreet2Textbox}
                                          onChange={(e) =>
                                            handleFieldChange(
                                              {
                                                target: {
                                                  value: e.target.value,
                                                },
                                              },
                                              "AddressStreet2Textbox",
                                              setEditedDataStreet2Textbox
                                            )
                                          }
                                          MenuProps={{
                                            PaperProps: {
                                              className: "dt-dropdn-menu",
                                            },
                                            MenuListProps: {
                                              className: "custom-menu-list",
                                            },
                                          }}
                                        >
                                          <option value="">
                                            select DataSource
                                          </option>
                                          {fieldNames.map(
                                            (fieldName, index) => (
                                              <option
                                                key={index}
                                                value={`{${fieldName.toLowerCase()}}`}
                                                className="mui_root_name"
                                              >
                                                {fieldName}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </FormControl>
                                    </div>
                                  </>
                                )}
                                {CheckboxCheckedCity && (
                                  <>
                                    <div className="fld-advnce-data">
                                      <div className="fld-name">Field City</div>
                                      <FormControl>
                                        <select
                                          className="theme-input-slt-dtsrc"
                                          value={editedDataCityTextbox}
                                          onChange={(e) =>
                                            handleFieldChange(
                                              {
                                                target: {
                                                  value: e.target.value,
                                                },
                                              },
                                              "CityTextbox",
                                              setEditedDataCityTextbox
                                            )
                                          }
                                          MenuProps={{
                                            PaperProps: {
                                              className: "dt-dropdn-menu",
                                            },
                                            MenuListProps: {
                                              className: "custom-menu-list",
                                            },
                                          }}
                                        >
                                          <option value="">
                                            select DataSource
                                          </option>
                                          {fieldNames.map(
                                            (fieldName, index) => (
                                              <option
                                                key={index}
                                                value={`{${fieldName.toLowerCase()}}`}
                                                className="mui_root_name"
                                              >
                                                {fieldName}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </FormControl>
                                    </div>
                                  </>
                                )}
                                {CheckboxCheckedState && (
                                  <>
                                    <div className="fld-advnce-data">
                                      <div className="fld-name">
                                        Field State
                                      </div>
                                      <FormControl>
                                        <select
                                          className="theme-input-slt-dtsrc"
                                          value={editedDataStateTextbox}
                                          onChange={(e) =>
                                            handleFieldChange(
                                              {
                                                target: {
                                                  value: e.target.value,
                                                },
                                              },
                                              "StateTextbox",
                                              setEditedDataStateTextbox
                                            )
                                          }
                                          MenuProps={{
                                            PaperProps: {
                                              className: "dt-dropdn-menu",
                                            },
                                            MenuListProps: {
                                              className: "custom-menu-list",
                                            },
                                          }}
                                        >
                                          <option value="">
                                            select DataSource
                                          </option>
                                          {fieldNames.map(
                                            (fieldName, index) => (
                                              <option
                                                key={index}
                                                value={`{${fieldName.toLowerCase()}}`}
                                                className="mui_root_name"
                                              >
                                                {fieldName}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </FormControl>
                                    </div>
                                  </>
                                )}
                                {CheckboxCheckedPostal && (
                                  <>
                                    <div className="fld-advnce-data">
                                      <div className="fld-name">
                                        Field Postal
                                      </div>
                                      <FormControl>
                                        <select
                                          className="theme-input-slt-dtsrc"
                                          value={editedDataPostalTextbox}
                                          onChange={(e) =>
                                            handleFieldChange(
                                              {
                                                target: {
                                                  value: e.target.value,
                                                },
                                              },
                                              "PostalTextbox",
                                              setEditedDataPostalTextbox
                                            )
                                          }
                                          MenuProps={{
                                            PaperProps: {
                                              className: "dt-dropdn-menu",
                                            },
                                            MenuListProps: {
                                              className: "custom-menu-list",
                                            },
                                          }}
                                        >
                                          <option value="">
                                            select DataSource
                                          </option>
                                          {fieldNames.map(
                                            (fieldName, index) => (
                                              <option
                                                key={index}
                                                value={`{${fieldName.toLowerCase()}}`}
                                                className="mui_root_name"
                                              >
                                                {fieldName}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </FormControl>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}

              {selectedItem &&
                selectedItem.controltype === "LongText" &&
                controlsVisible && (
                  <>
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="label-algnmt">
                          <div className="inpt-fild-test">Sublabels</div>
                          <div className="advnce-field-stree">
                            <div className="fld-advnce">
                              <div className="fld-name">Field TextArea</div>
                              <TextField
                                className="inpt-text-fld-name"
                                value={editedDataLongTextTextbox}
                                onChange={(e) =>
                                  handleFieldChange(
                                    e,
                                    "LongTextTextbox",
                                    setEditedDataLongTextTextbox
                                  )
                                }
                                InputProps={{
                                  classes: { input: "fild-inpt" },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="label-algnmt">
                            <div className="inpt-fild-test">Data Source</div>
                            <div className="advnce-field-stree ">
                              <div className="fld-advnce-data">
                                <div className="fld-name">Field LongText</div>
                                <FormControl>
                                  <select
                                    className="theme-input-slt-dtsrc"
                                    value={editedDataLongTextTextbox}
                                    onChange={(e) =>
                                      handleFieldChange(
                                        { target: { value: e.target.value } },
                                        "LongTextTextbox",
                                        setEditedDataLongTextTextbox
                                      )
                                    }
                                    MenuProps={{
                                      PaperProps: {
                                        className: "dt-dropdn-menu",
                                      },
                                      MenuListProps: {
                                        className: "custom-menu-list",
                                      },
                                    }}
                                  >
                                    <option value="">select DataSource</option>
                                    {fieldNames.map((fieldName, index) => (
                                      <option
                                        key={index}
                                        value={`{${fieldName.toLowerCase()}}`}
                                        className="mui_root_name"
                                      >
                                        {fieldName}
                                      </option>
                                    ))}
                                  </select>
                                </FormControl>
                              </div>
                            </div>
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </>
                  </>
                )}

              {selectedItem &&
                selectedItem.controltype === "ShortText" &&
                controlsVisible && (
                  <>
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="label-algnmt">
                          <div className="inpt-fild-test">Sublabels</div>
                          <div className="advnce-field-stree ">
                            <div className="fld-advnce">
                              <div className="fld-name">Field TextBox</div>
                              <TextField
                                className="inpt-text-fld-name"
                                value={editedDataShortTextTextbox}
                                // onChange={handleFieldChangeAddressShortTextTextbox}
                                onChange={(e) =>
                                  handleFieldChange(
                                    e,
                                    "ShortTextTextBox",
                                    setEditedDataShortTextTextbox
                                  )
                                }
                                InputProps={{
                                  classes: { input: "fild-inpt" },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="label-algnmt">
                            <div className="inpt-fild-test">Data Source</div>
                            <div className="advnce-field-stree ">
                              <div className="fld-advnce-data">
                                <div className="fld-name">Short Text</div>
                                <FormControl>
                                  <select
                                    className="theme-input-slt-dtsrc"
                                    value={editedDataShortTextTextbox}
                                    onChange={(e) =>
                                      handleFieldChange(
                                        { target: { value: e.target.value } },
                                        "ShortTextTextBox",
                                        setEditedDataShortTextTextbox
                                      )
                                    }
                                    MenuProps={{
                                      PaperProps: {
                                        className: "dt-dropdn-menu",
                                      },
                                      MenuListProps: {
                                        className: "custom-menu-list",
                                      },
                                    }}
                                  >
                                    <option value="">select DataSource</option>
                                    {fieldNames.map((fieldName, index) => (
                                      <option
                                        key={index}
                                        value={`{${fieldName.toLowerCase()}}`}
                                        className="mui_root_name"
                                      >
                                        {fieldName}
                                      </option>
                                    ))}
                                  </select>
                                </FormControl>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </>
                )}

              {selectedItem &&
                selectedItem.controltype === "FullName" &&
                controlsVisible && (
                  <>
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="label-algnmt">
                          <div className="inpt-fild-test">Sublabels</div>
                          <div className="advnce-field-stree">
                            <div className="fld-frst-last">
                              <div className="fld-name">Field FirstName</div>
                              <TextField
                                className="inpt-text-fld-name"
                                value={editedDataFirstNameTextbox}
                                onChange={(e) =>
                                  handleFieldChange(
                                    e,
                                    "FirstNameTextBox",
                                    setEditedDataFirstNameTextbox
                                  )
                                }
                                InputProps={{
                                  classes: { input: "fild-inpt" },
                                }}
                              />
                            </div>
                            <div className="fld-frst-last">
                              <div className="fld-name">Field LastName</div>
                              <TextField
                                className="inpt-text-fld-name"
                                value={editedDataLastNameTextbox}
                                onChange={(e) =>
                                  handleFieldChange(
                                    e,
                                    "LastNameTextBox",
                                    setEditedDataLastNameTextbox
                                  )
                                }
                                InputProps={{
                                  classes: { input: "fild-inpt" },
                                }}
                              />
                            </div>
                            {CheckboxCheckedPrefix && (
                              <>
                                <div className="fld-frst-last">
                                  <div className="fld-name">Field Prefix</div>
                                  <TextField
                                    className="inpt-text-fld-name"
                                    value={editedDataPrefixTextbox}
                                    onChange={(e) =>
                                      handleFieldChange(
                                        e,
                                        "PrefixTextBox",
                                        setEditedDataPrefixTextbox
                                      )
                                    }
                                    InputProps={{
                                      classes: { input: "fild-inpt" },
                                    }}
                                  />
                                </div>
                              </>
                            )}

                            {CheckboxCheckedMiddleName && (
                              <>
                                <div className="fld-frst-last">
                                  <div className="fld-name">
                                    Field MiddleName
                                  </div>
                                  <TextField
                                    className="inpt-text-fld-name"
                                    value={editedDataMiddleTextbox}
                                    onChange={(e) =>
                                      handleFieldChange(
                                        e,
                                        "MiddleNameTextBox",
                                        setEditedDataMiddleTextbox
                                      )
                                    }
                                    InputProps={{
                                      classes: { input: "fild-inpt" },
                                    }}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                          
                        </div>
                      </div>
                    </div>
                    <>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="label-algnmt">
                            <div className="inpt-fild-test">Data Source</div>
                            <div className="advnce-field-stree">
                              <div className="fld-advnce-data">
                                <div className="fld-name">Field FirstName</div>
                                <FormControl>
                                  <select
                                    className="theme-input-slt-dtsrc"
                                    value={editedDataFirstNameTextbox}
                                    onChange={(e) =>
                                      handleFieldChange(
                                        { target: { value: e.target.value } },
                                        "FirstNameTextBox",
                                        setEditedDataFirstNameTextbox
                                      )
                                    }
                                    MenuProps={{
                                      PaperProps: {
                                        className: "dt-dropdn-menu",
                                      },
                                      MenuListProps: {
                                        className: "custom-menu-list",
                                      },
                                    }}
                                  >
                                    <option value="">select DataSource</option>
                                    {fieldNames.map((fieldName, index) => (
                                      <option
                                        key={index}
                                        value={`{${fieldName.toLowerCase()}}`}
                                        className="mui_root_name"
                                      >
                                        {fieldName}
                                      </option>
                                    ))}
                                  </select>
                                </FormControl>
                              </div>
                              <div className="fld-advnce-data">
                                <div className="fld-name">Field LastName</div>
                                <FormControl>
                                  <select
                                    className="theme-input-slt-dtsrc"
                                    value={editedDataLastNameTextbox}
                                    onChange={(e) =>
                                      handleFieldChange(
                                        { target: { value: e.target.value } },
                                        "LastNameTextBox",
                                        setEditedDataLastNameTextbox
                                      )
                                    }
                                    MenuProps={{
                                      PaperProps: {
                                        className: "dt-dropdn-menu",
                                      },
                                      MenuListProps: {
                                        className: "custom-menu-list",
                                      },
                                    }}
                                  >
                                    <option value="">select DataSource</option>
                                    {fieldNames.map((fieldName, index) => (
                                      <option
                                        key={index}
                                        value={`{${fieldName.toLowerCase()}}`}
                                        className="mui_root_name"
                                      >
                                        {fieldName}
                                      </option>
                                    ))}
                                  </select>
                                </FormControl>
                              </div>
                              {CheckboxCheckedPrefix && (
                                <div className="fld-advnce-data">
                                  <div className="fld-name">Field Prefix</div>
                                  <FormControl>
                                    <select
                                      className="theme-input-slt-dtsrc"
                                      value={editedDataPrefixTextbox}
                                      onChange={(e) =>
                                        handleFieldChange(
                                          { target: { value: e.target.value } },
                                          "PrefixTextBox",
                                          setEditedDataPrefixTextbox
                                        )
                                      }
                                      MenuProps={{
                                        PaperProps: {
                                          className: "dt-dropdn-menu",
                                        },
                                        MenuListProps: {
                                          className: "custom-menu-list",
                                        },
                                      }}
                                    >
                                      <option value="">
                                        select DataSource
                                      </option>
                                      {fieldNames.map((fieldName, index) => (
                                        <option
                                          key={index}
                                          value={`{${fieldName.toLowerCase()}}`}
                                          className="mui_root_name"
                                        >
                                          {fieldName}
                                        </option>
                                      ))}
                                    </select>
                                  </FormControl>
                                </div>
                              )}

                              {CheckboxCheckedMiddleName && (
                                <div className="fld-advnce-data">
                                  <div className="fld-name">Field Middle</div>
                                  <FormControl>
                                    <select
                                      className="theme-input-slt-dtsrc"
                                      value={editedDataMiddleTextbox}
                                      onChange={(e) =>
                                        handleFieldChange(
                                          { target: { value: e.target.value } },
                                          "MiddleNameTextBox",
                                          setEditedDataMiddleTextbox
                                        )
                                      }
                                      MenuProps={{
                                        PaperProps: {
                                          className: "dt-dropdn-menu",
                                        },
                                        MenuListProps: {
                                          className: "custom-menu-list",
                                        },
                                      }}
                                    >
                                      <option value="">
                                        select DataSource
                                      </option>
                                      {fieldNames.map((fieldName, index) => (
                                        <option
                                          key={index}
                                          value={`{${fieldName.toLowerCase()}}`}
                                          className="mui_root_name"
                                        >
                                          {fieldName}
                                        </option>
                                      ))}
                                    </select>
                                  </FormControl>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  </>
                )}

              {selectedItem &&
                selectedItem.controltype === "PhoneNumber" &&
                controlsVisible && (
                  <>
                    <div>
                      <div className="fld-name-heding">Phone Number</div>
                      <div className="advnce-field">
                        <div className="phone-mrkd">
                          <div className="mrkd-text">Masked</div>
                          <TextField
                            className="inpt-text-fld-name"
                            value={editedDataPhoneTextbox}
                            onChange={(e) =>
                              handleFieldChange(
                                e,
                                "PhoneTextBox",
                                setEditedDataPhoneTextbox
                              )
                            }
                            InputProps={{
                              classes: { input: "fild-inpt" },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

              {selectedItem &&
                selectedItem.controltype === "Email" &&
                controlsVisible && (
                  <>
                    <div className="fld-email">
                      <div className="fld-email-text">Field Email</div>
                      <TextField
                        className="fld-textbx"
                        value={editedDataEmailTextbox}
                        onChange={(e) =>
                          handleFieldChange(
                            e,
                            "EmailTextBox",
                            setEditedDataEmailTextbox
                          )
                        }
                        InputProps={{
                          classes: { input: "fild-inpt-box" },
                        }}
                      />
                    </div>
                  </>
                )}
              {selectedItem &&
                selectedItem.controltype === "Heading" &&
                controlsVisible && (
                  <>
                    <div className="fld-email">
                      {!imageExist && (
                        <div className="fld-email-text">Heading Image</div>
                      )}
                      {imageExist && (
                        //     <>
                        // <img src={editedDataImageHeading}/>
                        //        <label>{imageName}</label>
                        //       <button onClick={handleRemoveImageHeading }>Remove Image</button>
                        //       <br></br>
                        //       <div style={{display : "grid"}}>
                        //         <label>Image Width</label>
                        //         <input type="number" onChange={handleChangeImageWidth} value={imageWidth}/>
                        //       </div>
                        //       <div style={{display : "grid"}}>
                        //       <label>Image Alignment</label>
                        //     <div className="btn-group-gnr">
                        //               <Button
                        //                 onClick={() =>
                        //                   handleAlignmentChangeHeading("row")
                        //                 }
                        //                 className={`button-gnr ${alignMent === "row" ? "active" : ""}`}
                        //               >
                        //                 Left
                        //               </Button>
                        //               <Button
                        //                 onClick={() =>
                        //                   handleAlignmentChangeHeading("column")
                        //                 }
                        //                 className={`button-gnr ${alignMent === "column" ? "active" : ""}`}
                        //               >
                        //                 Top
                        //               </Button>
                        //               <Button
                        //                 onClick={() =>
                        //                   handleAlignmentChangeHeading("row-reverse")
                        //                 }
                        //                 className={`button-gnr ${alignMent === "row-reverse" ? "active" : ""}`}
                        //               >
                        //                 Right
                        //               </Button>
                        //             </div>
                        //       </div>

                        //       <div style={{display : "grid"}}>
                        //       <label>Label Alignment</label>
                        //     <div className="btn-group-gnr">
                        //               <Button
                        //                 onClick={() =>
                        //                   handleChangeimagealignself("flex-start")
                        //                 }
                        //                 className={`button-gnr ${imagealignself === "flex-start" ? "active" : ""}`}
                        //               >
                        //                 Left
                        //               </Button>
                        //               <Button
                        //                 onClick={() =>
                        //                   handleChangeimagealignself("center")
                        //                 }
                        //                 className={`button-gnr ${imagealignself === "center" ? "active" : ""}`}
                        //               >
                        //                 Top
                        //               </Button>
                        //               <Button
                        //                 onClick={() =>
                        //                   handleChangeimagealignself("flex-end")
                        //                 }
                        //                 className={`button-gnr ${imagealignself === "flex-end" ? "active" : ""}`}
                        //               >
                        //                 Right
                        //               </Button>
                        //             </div>
                        //       </div>
                        //       </>
                        <>
                          <div class="clr-schm-option">
                            <div class="drowerseparater">
                              <div class="heading-gnr-alinment">
                                <div className="heding-rht-img">
                                  <div>
                                    <img src={editedDataImageHeading} />
                                  </div>
                                  <div className="heding-rht-text">
                                    <div>{imageName}</div>
                                    <span
                                      className="heding-remove-img"
                                      onClick={handleRemoveImageHeading}
                                    >
                                      Remove Image
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="clr-schm-option">
                            <div class="drowerseparater">
                              <div class="heading-gnr-input">
                                <div>
                                  <div>Image Width</div>
                                  <div className="input-with-unit">
                                    <input
                                      type="number"
                                      onChange={handleChangeImageWidth}
                                      value={imageWidth}
                                      className="heading-gnr-alinment img-align-width"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="clr-schm-option">
                            <div class="drowerseparater">
                              <div class="heading-gnr-alinment">
                                <div>
                                  <div>Image Alignment</div>
                                  <div className="btn-group-gnr">
                                    <Button
                                      onClick={() =>
                                        handleAlignmentChangeHeading("row")
                                      }
                                      className={`button-gnr ${alignMent === "row" ? "active" : ""}`}
                                    >
                                      Left
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        handleAlignmentChangeHeading("column")
                                      }
                                      className={`button-gnr ${alignMent === "column" ? "active" : ""}`}
                                    >
                                      Top
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        handleAlignmentChangeHeading(
                                          "row-reverse"
                                        )
                                      }
                                      className={`button-gnr ${alignMent === "row-reverse" ? "active" : ""}`}
                                    >
                                      Right
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="clr-schm-option">
                            <div class="drowerseparater">
                              <div class="heading-gnr-alinment">
                                <div>
                                  <div>Label Alignment</div>
                                  <div className="btn-group-gnr">
                                    <Button
                                      onClick={() =>
                                        handleChangeimagealignself("flex-start")
                                      }
                                      className={`button-gnr ${imagealignself === "flex-start" ? "active" : ""}`}
                                    >
                                      Left
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        handleChangeimagealignself("center")
                                      }
                                      className={`button-gnr ${imagealignself === "center" ? "active" : ""}`}
                                    >
                                      Top
                                    </Button>
                                    <Button
                                      onClick={() =>
                                        handleChangeimagealignself("flex-end")
                                      }
                                      className={`button-gnr ${imagealignself === "flex-end" ? "active" : ""}`}
                                    >
                                      Right
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {!imageExist && (
                        <div className="upld-img">
                          <button
                            onClick={handleopenImageTab}
                            className="icon-text-upld"
                          >
                            <AddPhotoAlternateIcon
                              style={{ marginRight: "5px" }}
                            />
                            {isImageAdded ? "Choose A File" : "Choose A File"}
                          </button>
                        </div>
                      )}
                    </div>
                    {!imageExist && showTabs && (
                      <div className="heading-tab-img">
                        <Tabs
                          value={activeTab}
                          onChange={handleTabChange}
                          className="img-upld-tag"
                        >
                          <Tab label="Upload Image" />
                          <Tab label="Enter URL" />
                        </Tabs>

                        {activeTab === 0 && (
                          <div className="heading-file">
                            <div className="titleSection">
                              <input
                                type="file"
                                id="imagePicker"
                                ref={inputRef}
                                accept="image/jpeg, image/png"
                                style={{ display: "none" }}
                                onChange={(e) =>
                                  handleFieldChangeAddressImage(e)
                                }
                              />
                              <label onClick={handleImageUploadClick}>
                                <div className="dwn-arw-file-upld">
                                  <LuArrowDownToLine />
                                  Upload File
                                </div>
                              </label>
                            </div>
                          </div>
                        )}

                        {activeTab === 1 && (
                          <div>
                            <div className="heading-file hding-file-wrap">
                              <div className="input-container">
                                <TextField
                                  value={editedDataImageHeading}
                                  onChange={handleUrlChange}
                                  placeholder="Enter image URL"
                                  fullWidth
                                  InputProps={{
                                    className: "entr-url-img",
                                  }}
                                />
                                <IconButton
                                  onClick={handleUrlSubmit}
                                  className="iconbtn-upl"
                                >
                                  <div className="upld-add-lnk">Add Link</div>
                                </IconButton>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}

              {selectedItem &&
                selectedItem.controltype === "MultipleChoice" &&
                controlsVisible && (
                  <div className="select-dflt">
                    <div htmlFor="checkbox-dropdown" className="inpt-fild-test">
                      Choose options:
                    </div>
                    <div className="checkbox-select-container">
                      <button
                        id="checkbox-dropdown"
                        type="button"
                        onClick={toggleDropdown}
                        className="drpdwn-button"
                      >
                        <div className="slct-drpdwn">
                          <div className="slct-optn-text">Select Options</div>
                          <div className="slct-optn-icn">
                            <AiOutlineCaretDown />
                          </div>
                        </div>
                      </button>
                      {dropdownOpen && (
                        <div className="checkbox-dropdown-menu">
                          {CheckBoxControls.map((control) => (
                            <div key={control.id} className="drpdwn-item">
                              <input
                                type="checkbox"
                                id={`checkbox-${control.id}`}
                                checked={control.check || false}
                                onChange={() =>
                                  handleCheckBoxChange(control.data)
                                }
                                className="cstm-checkbox"
                              />
                              <label htmlFor={`checkbox-${control.id}`}>
                                {control.data}
                              </label>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}

              {selectedItem &&
                selectedItem.controltype === "SingleChoice" &&
                controlsVisible && (
                  <div>
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="optns-predefnd">
                          <div htmlFor="radioSelect" className="inpt-fild-test">
                            Select a Radio Control:
                          </div>
                          <div className="inpt-cntr-unit unit-option">
                            <select
                              className="theme-input-slt"
                              id="radioSelect"
                              value={selectedValue}
                              onChange={handleSelectChange}
                            >
                              <option value="">-- Select --</option>
                              {radioControls.map((control) => (
                                <option key={control.id} value={control.data}>
                                  {`${control.data}`}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            </>,
            targetElement
          );
        })()}
    </React.Fragment>
  );
};

export default AdvanceSection;
