import { useState, useEffect } from "react";
import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import "./MultiSelectorStyles.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";

export default function MultiSelector({
  data,
  selected,
  setSelected,
  setSelectedItem,
  setOpenDiagHeader,
}) {
  const isAllSelected = data.length > 0 && selected.length === data.length;

  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredData = data.filter((section) => {
    if (searchTerm === "") {
      return true;
    }
    return section.name.toLowerCase().includes(searchTerm.toLowerCase());
  });
  console.log("filteredData ", filteredData);
  console.log("selected ", selected);
  const handleToggle = (value) => () => {
    setSelected((prevSelected) => {
      const isSelected = prevSelected.some((section) => section.name === value);

      if (isSelected) {
        // If the item is already in the selected array, remove all occurrences
        return prevSelected.filter((section) => section.name !== value);
      } else {
        // If the item is not in the selected array, add all occurrences at the correct position
        const selectedSections = filteredData.filter(
          (section) => section.name === value
        );
        const insertIndex = filteredData.findIndex(
          (section) => section.name === value
        );

        return [
          ...prevSelected.slice(0, insertIndex),
          ...selectedSections,
          ...prevSelected.slice(insertIndex),
        ];
      }
    });
  };
  const handleSelectAll = () => {
    setSelected((prevSelected) => {
      if (isAllSelected) {
        // If all items are currently selected, deselect all
        return [];
      } else {
        // If some or none are selected, select all
        return [...filteredData];
      }
    });
  };

  const handleFilterClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSearchChange = (event) => {
    event.stopPropagation();
    setSearchTerm(event.target.value);
  };

  const handleCreateProject = (event, template) => {
    event.stopPropagation();
    console.log("template ", template);
    setSelectedItem(template);
    setOpenDiagHeader(true);
  };
  return (
    <List>
      <ListItem button onClick={handleSelectAll}>
        <Tooltip title={selected.length > 0 ? "Deselect all" : "Select all"}>
          <ListItemIcon>
            <Checkbox
              checked={isAllSelected}
              indeterminate={
                selected.length > 0 && selected.length < filteredData.length
              }
            />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary={isAllSelected ? "Unselect All" : "Select All"} />
        <Tooltip title={"Search templates"}>
          <IconButton edge="end" onClick={(event) => handleFilterClick(event)}>
            <MoreVertIcon color="secondary" />
          </IconButton>
        </Tooltip>
      </ListItem>

      {/* {favoriteCards.length > 0 && (
        <ListItem button onClick={handleFavouriteToggle}>
          <ListItemIcon>
            <Checkbox
              checked={isFavouriteChecked}
              onChange={handleFavouriteToggle}
            />
          </ListItemIcon>
          <ListItemText primary="Favourites" />
        </ListItem>
      )} */}

      {filteredData.map((section) => {
        if (section.Bid === 0) {
          const isSelected = selected.some(
            (item) => item.diagTempID === section.diagTempID
          );
          console.log("isSelected ", isSelected);
          return (
            <ListItem
              key={section.diagTempID}
              button
              onClick={handleToggle(section.name)}
            >
              <Tooltip
                title={
                  !isSelected
                    ? `Select ${section.name}`
                    : `Deselect ${section.name}`
                }
              >
                <ListItemIcon>
                  <Checkbox checked={isSelected} />
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={section.name} />
              <Tooltip title={"Create a project from this template"}>
                <IconButton
                  edge="end"
                  onClick={(event) => handleCreateProject(event, section)}
                >
                  <AddIcon color="secondary" />
                </IconButton>
              </Tooltip>
            </ListItem>
          );
        }
        return null; // If Bid is not 0, return null (or an empty fragment)
      })}
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem>
          <TextField
            label="Search"
            variant="standard"
            size="small"
            fullWidth
            value={searchTerm}
            onChange={handleSearchChange}
            onClick={(event) => event.stopPropagation()} // Prevent ListItem from handling click
            onKeyDown={(event) => event.stopPropagation()}
          />
        </MenuItem>
        {/* Additional menu items can be added here */}
      </Menu>
    </List>
  );
}
