import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Button,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
} from "@mui/material";

import { useNavigate, useLocation } from "react-router-dom";
import { Theme } from "../Components/IncludeFile/Theme";
import { ReadArray } from "../Components/Api/ApiCalls";

import { toast } from "react-toastify";
import { useAppState } from "./AppStateProvider ";
import CustomPopUpForm from "../layout/CustomPopUpForm";
import { makeStyles } from "@mui/styles";
import DiscoverEdit from "./DiscoverEdit";
import Discover from "./Discover";
import { getCurrentDateTime } from "../apiCalls/DiagramsAPI";
import ErrorBanner from "../Error/ErrorBanner";

const useStyles = makeStyles({
  customSelectRoot: {
    "&:before": {
      borderBottom: "none !important",
    },
    "&:after": {
      borderBottom: "none !important",
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent  !important",
    },
  },
});

const CreateInfo = ({ authData, defaultStyle, ReadAllData, setError, error }) => {
  const location = useLocation();


  const ArID = location.state ? location.state.ArID : null;
  const arrayName = location.state ? location.state.ArrayName : null;
  const selected = location.state ? location.state.selected : null;
  const description = location.state ? location.state.Description : null;
  const category = location.state ? location.state.Category : null;
  const dataSourceArID = location.state ? location.state.DataSourceArID : null;
  const isInactive = location.state ? location.state.isInactive : null;



  const [editedArrayName, setEditedArrayName] = useState(arrayName);
  const [updatedItems, setUpdatedItems] = useState(selected);
  const [editedDescription, setEditedDescription] = useState(description);
  const [editedcategory, seteditedcategory] = useState(category);
  const [editeddataSourceArID, setediteddataSourceArID] =
    useState(dataSourceArID);
  const [isOpen, setIsOpen] = useState(true);
  const [isEditMode, setIsEditMode] = useState(false);
  const [arrayNameError, setArrayNameError] = useState("");
  const { appState, setArrayData } = useAppState();
  const [showDiscoverEdit, setShowDiscoverEdit] = useState(false);
  const [source, setSource] = useState(null);
  // const [sourceData, setSourceData] = useState(null);
  // const [arID, setArID] = useState(null);
  const [isOpenDiscoverEdit, setIsOpenDiscoverEdit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [active , setActive] = useState("")

  let accessKey = authData.accessKey;
  const userName = authData?.userName || "";
  let APIProvider = window.APIProvider;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (ArID) {
          const jsonData = await ReadArray(ArID, accessKey);
          const sourceData = jsonData[0].Source;
          setActive(jsonData[0].isInactive)
          setSource(sourceData);
        } else {
          console.error("Error: Unable to fetch ArID");
        }
      } catch (error) {
        console.error("Error:", error.message);
      setError("An error occurred in fetchData");
      localStorage.setItem("error", "An error occurred in fetchData");
      localStorage.setItem("navigate", "/Landing");
      }
    };

    fetchData();
  }, []);

  const [SourceofDataSource,setSourceofDataSource] = useState([])


  useEffect(() => {
  const fetchDataSource = async () => {
    try {
      if (editeddataSourceArID > 0) {
        const jsonData = await ReadArray(editeddataSourceArID, accessKey);
        const sourceData = jsonData[0].Source; 
        setSourceofDataSource(sourceData);
      } else {
        setSourceofDataSource(null);
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  fetchDataSource();
}, [editeddataSourceArID, accessKey]);
const handleOpenDiscoverEdit = async () => {
    if (!authData || !authData.accessKey) {
      navigate("/");
      sessionStorage.clear();
      return;
    }
    setLoading(true);
    try {
      if (ArID) {
        await handleEditArray(
          ArID,
          editedArrayName,
          editedDescription,
          editedcategory,
          editeddataSourceArID
        );
        setEditedArrayName(editedArrayName);
        setEditedDescription(editedDescription);
        seteditedcategory(editedcategory);
        setediteddataSourceArID(editeddataSourceArID);
      } else {
        console.error("Error: Unable to fetch ArID");
      }
    } catch (error) {
      console.error("Error:", error.message);
      setError("An error occurred during fetching");
      localStorage.setItem("error", "An error occurred during fetching");
      localStorage.setItem("navigate", "/Open");
    }

    try {
      if (
        source[0]?.control?.length === 0 ||
        editeddataSourceArID == 0 ||
        editeddataSourceArID == null
      ) {
        navigate(`/Form/${ArID}`, {
          state: {
            ArID: ArID,
            ArrayName: editedArrayName,
            Description: editedDescription,
            Category: category,
            Source: JSON.stringify(source),
            IsTemplate: "0",
            DataSourceArID: editeddataSourceArID,
            defaultStyle: defaultStyle,
            SourceofDataSource:SourceofDataSource,
            isInactive:isInactive
          },
        });
  
        setLoading(false);
        setShowDiscoverEdit(false);
        setIsOpen(false);
        setIsOpenDiscoverEdit(false);
      } else {
        setArrayData(
          editedArrayName,
          editedDescription,
          category,
          editeddataSourceArID
        );
       
        setLoading(false);
        setShowDiscoverEdit(true);
        setIsOpen(true);
        setIsOpenDiscoverEdit(true);
      }
    } catch (error) {
      console.error("Error:", error.message);
      setError("An error occurred during fetching");
      localStorage.setItem("error", "An error occurred during fetching");
      localStorage.setItem("navigate", "/Open");
    }
  };
  const handleCancel = () => {
    setIsOpen(false);
  };

  const handleCancelClick = () => {
    setEditedArrayName(arrayName);
    setEditedDescription(description);
    seteditedcategory(category);
    setediteddataSourceArID(dataSourceArID);
    setArrayNameError("");
    setLoading(false);
    setIsOpenDiscoverEdit(false);
  };

  const dateTime = getCurrentDateTime();

  const handleEditArray = async (
    ArID,
    editedArrayName,
    editedDescription,
    editedCategory,
    editeddataSourceArID
  ) => {
 
    if (!authData || !authData.accessKey) {
      navigate("/");
      sessionStorage.clear();
      return;
    }

    try {
      if (!editedArrayName.trim()) {
        setArrayNameError("Form Name is required");
        setLoading(false);
        return;
      } else {
        setArrayNameError("");
      }

      const requestBody = {
        accessKey: authData.accessKey,
        guid: APIProvider.GUID.EditArrayGUID,
        param1: "",
        param2: "",
        param3: "",
        param4: "",
        recordSet: "",
        body: JSON.stringify({
          ArID: ArID,
          ArrayName: editedArrayName,
          Description: editedDescription,
          Category: editedCategory,
          IsTemplate: "0",
          DataSourceArID: editeddataSourceArID,
          isInactive:isInactive,
          ModifiedBy: userName,
          ModifiedDate: dateTime,
        }),
      };

      console.log("requestBody",requestBody)
      const response = await fetch(
        `${APIProvider.baseURL}${APIProvider.MethodName.PostQuickInfo}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.ok) {
      } else {
        console.error("Failed to edit item.");
        toast.error("Failed to edit item.");
        const responseBody = await response.text();
        console.error("Response Body:", responseBody);
        toast.error("Response Body:");
      }
    } catch (error) {
      console.error("Error editing item:", error);
      setError("An error occurred during post on editing item");
      localStorage.setItem("error", "An error occurred during post on editing item");
      localStorage.setItem("navigate", "/Open");
    }
  };

  const navigate = useNavigate();

  const handleSave = async () => {

    if (!authData || !authData.accessKey) {
      navigate("/");
      sessionStorage.clear();
      return;
    }
    setIsEditMode(false);
    try {
      await handleEditArray(
        ArID,
        editedArrayName,
        editedDescription,
        editedcategory,
        editeddataSourceArID
      );

      setEditedArrayName(editedArrayName);
      setEditedDescription(editedDescription);
      seteditedcategory(editedcategory);
      setediteddataSourceArID(editeddataSourceArID);
    } catch (error) {
      console.error("Error:", error.message);
      setError("An error occurred on save in overview popup");
      localStorage.setItem("error", "An error occurred on save in overview popup");
      localStorage.setItem("navigate", "/Open");
      return;
    }
  };

  const [fetched1, setFetch] = useState(false);

  const [filteredData, setFilteredData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    if (!authData || !authData.accessKey) {
      navigate("/");
      sessionStorage.clear();
      return;
    }
    console.log("fetchDataarrayname is called", dateTime);
    fetchDataarrayname();
  }, []);

  const fetchDataarrayname = async () => {
    console.log("fetchDataarrayname is called", dateTime);
    if (!authData || !authData.accessKey) {
      navigate("/");
      sessionStorage.clear();
      return;
    }
    try {
      const filteredJsonData = ReadAllData.filter(
        (item) => Number(item.IsTemplate) === 2
      );
      setFilteredData(filteredJsonData);
    } catch (error) {
      console.error("Error fetching array names:", error);
      setError("An error occurred in fetching array names");
      localStorage.setItem("error", "An error occurred in fetching array names");
      localStorage.setItem("navigate", "/Open");
    }
  };

  const handleSelectChange = (event) => {
    const selectedArID = event.target.value;
    if (selectedArID === "0") {
      setSelectedItem(null);
      setediteddataSourceArID("0");
      return;
    }
    const selectedItem = filteredData.find(
      (item) => item.ArID === parseInt(selectedArID)
    );

    setSelectedItem(selectedItem);
    setediteddataSourceArID(selectedArID);
  };

  const iconButtonsSaveAndRestore = [
    {
      icon: Theme.Restore,
      color: "primary",
      handleClick: () => {
        handleCancelClick();
      },
      tooltip: "Restore",
    },
    {
      icon: Theme.Save,
      color: "primary",
      handleClick: () => {
        handleSave();
      },
      tooltip: "Save",
    },
  ];

  const iconButtonsEdit = [
    {
      icon: Theme.Edit,
      color: "primary",
      handleClick: () => {
        handleEdit();
      },
      tooltip: "Click To Edit",
    },
  ];

  const classes = useStyles();

  const handleEdit = () => {
    setIsEditMode(true);
  };

  const updateArrayNameInJson = () => {
    const updatedItemsList = updatedItems.map((item) => {
      if (item.ArrayName === arrayName) {
        return {
          ...item,
          ArrayName: editedArrayName,
        };
      }
      return item;
    });

    setUpdatedItems(updatedItemsList);
  };

  const handleArrayNameChange = (e) => {
    const trimmedValue = e.target.value;
    setEditedArrayName(trimmedValue);
    setArrayNameError("");
  };

  useEffect(() => {
    updateArrayNameInJson();
  }, [editedArrayName]);

  const [dataSourceName, setDataSourceName] = useState("");

  const fetchDataSource = async () => {
    try {
      if (!editeddataSourceArID && !dataSourceArID) {
        console.warn(
          "No valid dataSourceArID. Setting dataSourceName to null."
        );
        setDataSourceName(null);
        return;
      }

      const matchingItem = ReadAllData.find(
        (item) =>
          Number(item.ArID) === Number(editeddataSourceArID || dataSourceArID)
      );

      if (matchingItem) {
        setDataSourceName(matchingItem.ArrayName);
      } else {
        console.warn(
          "No matching item found in readarrayAll. Setting dataSourceName to null."
        );
        setDataSourceName(null);
      }
    } catch (error) {
      console.error("Error:", error.message);
      setError("An error occurred in fetching DataSource");
      localStorage.setItem("error", "An error occurred in fetching DataSource");
      localStorage.setItem("navigate", "/Open");
      setDataSourceName(null);
    }
  };

  useEffect(() => {
    fetchDataSource();
  }, [editeddataSourceArID]);

  return (
    <CustomPopUpForm
      open={isOpen}
      onClose={handleCancel}
      title="Overview"
      iconButtons={isEditMode ? iconButtonsSaveAndRestore : iconButtonsEdit}
      height="60%"
    >
      <>
        {!isEditMode ? (
          <>
            <div className="card-info card-info-ovr-flow">
              <div className="card-info-inside card-info-inside-view">
                <Typography className="card-info-inside-text">
                  Form Name*:
                </Typography>
                <div>
                  <Typography style={{ color: "#000" }}>
                    {editedArrayName}
                  </Typography>
                </div>
              </div>
              <div className="card-info-inside">
                <Typography className="card-info-inside-text">
                  Description:
                </Typography>
                <div>
                  <Typography style={{ color: "#000" }}>
                    {editedDescription}
                  </Typography>
                </div>
              </div>
              <div className="card-info-inside">
                <Typography className="card-info-inside-text">
                  Category:
                </Typography>
                <div>
                  <Typography style={{ color: "#000" }}>
                    {editedcategory}
                  </Typography>
                </div>
              </div>
              <div className="card-info-inside">
                <Typography className="card-info-inside-text">
                  Data Source:
                </Typography>
                <div>
                  {/* <Typography style={{ color: "#000" }}>
                    {fetched1 ? "Loading..." : dataSourceName}
                  </Typography> */}

                  <Typography style={{ color: "#000" }}>
                    {fetched1
                      ? "Loading..."
                      : dataSourceName !== null && dataSourceName !== undefined
                        ? dataSourceName
                        : "Select DataSource"}
                  </Typography>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="card-info">
            <div className="card-info-inside card-info-inside-view">
              <Typography style={{ fontWeight: "bold" }}>
                Form Name*:
              </Typography>
              <TextField
                required
                type="text"
                className="edit-popup"
                name="Form Name"
                onChange={handleArrayNameChange}
                value={editedArrayName}
                error={!!arrayNameError}
                helperText={arrayNameError}
              />
            </div>
            <div className="card-info-inside">
              <Typography style={{ fontWeight: "bold" }}>
                Description:
              </Typography>
              <TextField
                type="text"
                className="edit-popup"
                name="Description"
                onChange={(e) => setEditedDescription(e.target.value)}
                value={editedDescription}
              />
            </div>
            <div className="card-info-inside">
              <Typography style={{ fontWeight: "bold" }}>Category:</Typography>
              <TextField
                type="text"
                className="edit-popup"
                name="Category"
                value={editedcategory}
                style={{ backgroundColor: "#bdbdbd" }}
                readOnly
              />
            </div>
            <div className="card-info-inside">
              <Typography style={{ fontWeight: "bold" }}>
                Data Source:
              </Typography>
              <Select
                onChange={handleSelectChange}
                value={editeddataSourceArID || "0"}
                className={`${classes.customSelectRoot} data-select-crd`}
              >
                <MenuItem value="0">Select DataSource</MenuItem>
                {filteredData.map((item) => (
                  <MenuItem key={item.ArID} value={item.ArID}>
                    {item.ArrayName}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        )}
      </>
      <>
        <div style={{ width: "96%", display: "flex" }}>
          <Button fullWidth onClick={handleCancel}>
            Back
          </Button>
          <Button
            variant="contained"
            fullWidth
            onClick={handleOpenDiscoverEdit}
          >
            {loading || fetched1 ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Next"
            )}
          </Button>
        </div>
        {showDiscoverEdit && editeddataSourceArID > 0 && (
          <DiscoverEdit
            showDiscoverEdit={showDiscoverEdit}
            setShowDiscoverEdit={setShowDiscoverEdit}
            arrayName={editedArrayName}
            description={editedDescription}
            category={editedcategory}
            dataSourceArID={editeddataSourceArID}
            source={source}
            arID={ArID}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            isOpenDiscoverEdit={isOpenDiscoverEdit}
            setIsOpenDiscoverEdit={setIsOpenDiscoverEdit}
            authData={authData}
            defaultStyle={defaultStyle}
            ReadAllData={ReadAllData}
            error = {error}
            setError = {setError}
            isInactive={isInactive}
          />
        )}
        {error && <ErrorBanner />}
      </>
    </CustomPopUpForm>
  );
};

export default CreateInfo;
