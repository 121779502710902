  //The default edge
  export const defaultEdgeOptions = {
    // type: "smoothstep",
    type: "floating",
    markerEnd: {
      type: "arrow",
      width: 15,
      height: 15,
    },
    style: {
      strokeWidth: 2,
    },
    animated: false,
    interactionWidth: 30,
  };