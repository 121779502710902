import React, { useState } from "react";
import {
  Button,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import CustomPopUpForm from "../../layout/CustomPopUpForm";
const textFieldNames = [
  "name",
  "title",
  "caption",
  "tooltags",
  "seq",
  "description",
  "notes",
];
const EditGridRow = ({ open, handleClose, rowData, regions, handleSave }) => {
  const [formData, setFormData] = useState(rowData);
  console.log("rowData ", rowData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    handleSave(formData, rowData);
    handleClose();
  };

  return (
    <CustomPopUpForm
      open={open}
      onClose={handleClose}
      title={`Edit [${formData.title}]`}
    >
      <>
        <div className="card-info">
          {["execname", "title"].map((field) => (
            <div className="card-info-inside" key={field}>
              <Typography className="name-of-contol">{`${field}:`}</Typography>
              <TextField
                name={field}
                value={formData[field]}
                onChange={handleChange}
                fullWidth
                multiline
                InputProps={{
                  style: { padding: "7px" },
                }}
              />
            </div>
          ))}
          <div className="card-info-inside">
            <Typography className="name-of-contol">Region:</Typography>
            <FormControl fullWidth>
              <Select
                size="small"
                name="region"
                value={
                  regions?.find(
                    (region) =>
                      region.toLowerCase() === formData.region.toLowerCase()
                  ) || ""
                }
                onChange={handleChange}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                }}
                sx={{
                  "& .MuiSelect-select": {
                    padding: "7px",
                  },
                }}
              >
                {regions?.map((region, index) => (
                  <MenuItem key={index} value={region}>
                    {region}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="card-info-inside">
            <Typography className="name-of-contol">
              Tool Tags (separate with commas):
            </Typography>
            <TextField
              name="tooltags"
              value={formData.tooltags}
              onChange={handleChange}
              fullWidth
              multiline
              placeholder="Enter tags separated by commas"
              InputProps={{
                style: { padding: "7px" },
              }}
            />
          </div>
          <div className="card-info-inside">
            <Typography className="name-of-contol">Seq:</Typography>
            <TextField
              size="small"
              name="seq"
              value={formData.seq}
              onChange={handleChange}
              fullWidth
              type="number"
              InputProps={{
                inputProps: { min: 0 },
              }}
            />
          </div>
          {/* Other editable fields */}
          {["description", "notes"].map((field) => (
            <div className="card-info-inside" key={field}>
              <Typography className="name-of-contol">{`${field}:`}</Typography>
              <TextField
                name={field}
                value={formData[field]}
                onChange={handleChange}
                fullWidth
                multiline
                InputProps={{
                  style: { padding: "7px" },
                }}
              />
            </div>
          ))}
        </div>
      </>
      <>
        {/* Action buttons */}
        <Button
          fullWidth
          onClick={handleClose}
          sx={{ textTransform: "none", fontFamily: "Arial" }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          fullWidth
          onClick={handleSubmit}
          sx={{ textTransform: "none", fontFamily: "Arial" }}
        >
          Save
        </Button>
      </>
    </CustomPopUpForm>
  );
};

export default EditGridRow;
