import React, { useState, useEffect, useMemo } from "react";
import PropertiesAccordion from "../../../reusableComponents/PropertiesAccordion";
import IconBox from "../IconBox";
import FontDropDown from "./FontDropDown";
import DividerDropDown from "./DividerDropDown";

import {
  PiAlignRightSimpleBold,
  PiAlignLeftSimpleDuotone,
} from "react-icons/pi";
import { BsAlignCenter } from "react-icons/bs";
import { RxStretchHorizontally } from "react-icons/rx";

function LinkDropDowns({
  selectedItemID,
  newProperties,
  handlePropertyChange,
  tempNodes,
  selectedTool,
}) {
  const nodeId = selectedTool?.tasktypeid?.toString();

  // Retrieve default or special properties based on node ID
  const specialProperties = useMemo(
    () =>
      newProperties?.Nodes?.find((node) => node?.id?.toString() === nodeId) ||
      null,
    [newProperties?.Nodes, nodeId]
  );

  const properties = useMemo(
    () =>
      specialProperties
        ? specialProperties.customstyle
        : tempNodes[selectedItemID]
          ? tempNodes[selectedItemID]
          : newProperties[selectedTool?.category || "<all>"] || {},
    [specialProperties, newProperties, selectedTool, tempNodes, selectedItemID]
  );
  const [linkHorizontalAlignment, setLinkHorizontalAlignment] = useState({
    left: properties.link.justifyContent === "start" ? 1 : 0,
    horizontalCenter: properties.link.justifyContent === "center" ? 1 : 0,
    right: properties.link.justifyContent === "end" ? 1 : 0,
  });
  const handleLinkAlignmentChange = (name, value) => {
    // Update the appropriate state variable based on the name passed
    if (name === "start" || name === "center" || name === "end") {
      setLinkHorizontalAlignment({
        left: name === "start" ? value : 0,
        horizontalCenter: name === "center" ? value : 0,
        right: name === "end" ? value : 0,
      });
      handlePropertyChange(selectedTool, "link", "justifyContent", name);
    }
  };
  return (
    <>
      <PropertiesAccordion isOpen={false} title={"Link Alignment"}>
        <IconBox
          handleChange={handleLinkAlignmentChange}
          buttonList={[
            {
              icon: <PiAlignLeftSimpleDuotone />,
              name: "start",
              value: linkHorizontalAlignment.left,
            },
            {
              icon: <BsAlignCenter />,
              name: "center",
              value: linkHorizontalAlignment.horizontalCenter,
            },
            {
              icon: <PiAlignRightSimpleBold />,
              name: "end",
              value: linkHorizontalAlignment.right,
            },
          ]}
        />
      </PropertiesAccordion>
      <PropertiesAccordion isOpen={false} title={"Link Font"}>
        <FontDropDown
          properties={properties}
          selectedTool={selectedTool}
          handlePropertyChange={handlePropertyChange}
          propSection="link"
        />
      </PropertiesAccordion>
      <PropertiesAccordion isOpen={false} title={"Link Divider"}>
        <DividerDropDown
          properties={properties}
          selectedTool={selectedTool}
          handlePropertyChange={handlePropertyChange}
          propSection="linkDivider"
        />
      </PropertiesAccordion>
      <PropertiesAccordion isOpen={false} title={"Link Color"}>
        <div className="backgrounclr ">
          Background Color
          <div>
            {" "}
            <input
              type="color"
              name="backgroundColor"
              value={properties.link.backgroundColor}
              onChange={(e) => {
                handlePropertyChange(
                  selectedTool,
                  "link",
                  "backgroundColor",
                  e.target.value
                );
              }}
            />
            <div className="iconset clearColorWrap">
              <div
                className="clearColor"
                onClick={(e) => {
                  handlePropertyChange(
                    selectedTool,
                    "link",
                    "backgroundColor",
                    "transparent"
                  );
                }}
              >
                <span className="crsLinelft"></span>
                <span className="crsLinerht"></span>
                <span className="tooltiptext">Transparent</span>
              </div>
            </div>
          </div>
        </div>
        <div className="backgrounclr ">
          Color
          <input
            type="color"
            name="color"
            value={properties.link.color}
            onChange={(e) => {
              handlePropertyChange(
                selectedTool,
                "link",
                "color",
                e.target.value
              );
            }}
          />
        </div>
      </PropertiesAccordion>
    </>
  );
}

export default LinkDropDowns;
