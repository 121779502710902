import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ReadArray, ReadArrayAll } from '../Components/Api/ApiCalls';
import { loadLoginData } from '../apiCalls/DiagramsAPI';
import { toast } from 'react-toastify';
import CustomPopUpForm from "../layout/CustomPopUpForm";
import styled from 'styled-components';
const ErrorBanner = () => {
  const [error, setError] = useState(null);
  const [ErrorPopup, setErrorPopup] = useState(true); 
  const navigate = useNavigate();

  const authData = loadLoginData();




  const handleClose = () => {
    setErrorPopup(false); // Close the popup
    localStorage.removeItem('error'); // Clear the error from localStorage
    localStorage.removeItem('navigate'); // Clear the error from localStorage
    setError(null); // Reset error state
  };

  useEffect(() => {
    const storedError = localStorage.getItem('error');
    if (storedError) {
      setError(storedError);
    }
    const navigateTo = localStorage.getItem('navigate');
    if (navigateTo) {
      setTimeout(() => {
          navigate(navigateTo); 
        handleClose(); // Close the popup
      }, 3000); 

    }
  }, []); 
  
  if (!error) return null;
  return (
    <CustomPopUpForm 
    open={ErrorPopup}
    onClose={handleClose}
    title="Error"
    showCloseIcon={false}
  >
    {/* <Dialog open={ErrorPopup} onClose={handleClose}> */}
      {/* <DialogTitle>Error</DialogTitle> */}
      <DialogContent>
        <DialogContentText style={{textAlign:"center"}}>{error}</DialogContentText>
      </DialogContent>
    {/* </Dialog> */}
    </CustomPopUpForm>
  );
};


export default ErrorBanner;
