import React from "react";
import { Select, MenuItem } from "@mui/material";

function NodeBorderDropDown({
  properties,
  selectedTool,
  handlePropertyChange,
}) {
  return (
    <div>
      <div className="colorforborder">
        <div className="inptcheck">
          <div className="ipubrdr">
            <input
              type="number"
              name="borderSize"
              value={properties.node.borderWidth.replace(/px/, "")}
              // onChange={(e) => {
              //   handlePropertyChange(
              //     selectedTool,
              //     "node",
              //     "borderWidth",
              //     e.target.value + "px"
              //   );
              // }}
              onChange={(e) => {
    const value = e.target.value;
    // Ensure the value is 0 or more
    if (value >= 0 || value === "") {
      handlePropertyChange(
        selectedTool,
        "node",
        "borderWidth",
        value + "px"
      );
    }
  }}
  min="0"
            />
            <span className="unit-dropdown">
              <Select
                value="px"
                disabled
                style={{
                  width: "45px",
                  height: "27px",
                  padding: "10px 11px 10px 0px",
                }}
                displayEmpty
                IconComponent={() => null}
                MenuProps={{ style: { display: "none" } }}
              >
                <MenuItem value="px" disabled className="custom-menuitem">
                  px
                </MenuItem>
              </Select>
            </span>
          </div>
          <span className="thickness">Border Size</span>
        </div>

        <div className="colrfrborder">
          <input
            type="color"
            name="borderColor"
            value={properties.node.borderColor}
            onChange={(e) => {
              handlePropertyChange(
                selectedTool,
                "node",
                "borderColor",
                e.target.value
              );
            }}
          />
        </div>
        <div className="iconset clearColorWrap">
          <div
            className="clearColor"
            onClick={(e) => {
              handlePropertyChange(
                selectedTool,
                "node",
                "borderColor",
                "transparent"
              );
            }}
          >
            <span className="crsLinelft"></span>
            <span className="crsLinerht"></span>
            <span className="tooltiptext">Transparent</span>
          </div>
        </div>
      </div>
      <div className="colorforborder">
        <div className="inptcheck">
          <div className="ipubrdr">
            <input
              type="number"
              name="borderRadius"
              value={properties.node.borderRadius.replace(/px/, "")}
              // onChange={(e) => {
              //   handlePropertyChange(
              //     selectedTool,
              //     "node",
              //     "borderRadius",
              //     e.target.value + "px"
              //   );
              // }}
              onChange={(e) => {
    const value = e.target.value;
    if (value >= 0 || value === "") {
      handlePropertyChange(
        selectedTool,
        "node",
        "borderRadius",
        value + "px"
      );
    }
  }}
  min="0"
            />
            <span className="unit-dropdown">
              <Select
                value="px"
                disabled
                style={{
                  width: "45px",
                  height: "27px",
                  padding: "10px 11px 10px 0px",
                }}
                displayEmpty
                IconComponent={() => null}
                MenuProps={{ style: { display: "none" } }}
              >
                <MenuItem value="px" disabled className="custom-menuitem">
                  px
                </MenuItem>
              </Select>
            </span>
          </div>
          <span className="thickness">Border Radius</span>
        </div>
      </div>
    </div>
  );
}

export default NodeBorderDropDown;
