import { debounce } from "lodash";
import React, { useState } from "react";
import NavBar from "../navbarComponents/NavBar";
import { Theme } from "../IncludeFile/Theme";
import { useMenu } from "../../reusableComponents/menuUtils";
import ThemeCards from "./ThemeCards";
import { useAppState } from "../../PopUpForm/AppStateProvider ";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { loadLoginData } from "../../apiCalls/DiagramsAPI";
import { ReadArray, ReadArrayAll } from "../../Components/Api/ApiCalls";
import ErrorBanner from "../../Error/ErrorBanner";

const ThemeTemplates = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentComponent, setCurrentComponent] = useState("1");
    const [error, setError] = useState(null);
  const authData = loadLoginData();

  const location = useLocation();

  const [defaultStyle, setDefaultStyle] = React.useState([]);
  const [ThemeData, setThemeData] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      if (!authData || !authData.accessKey) {
        navigate("/");
        sessionStorage.clear();
        return;
      }
      try {
        const apiResponse = await ReadArrayAll(authData.accessKey);

        console.log("apiResponse",apiResponse)

        const templateObject = apiResponse.find(
          (item) => item.IsTemplate === 3
        );
        const filteredObjects = apiResponse.filter(
          (item) => item.IsTemplate === 4
        );

        console.log("filteredObjects",filteredObjects)
        setThemeData(filteredObjects);

        const jsonData = await ReadArray(
          templateObject.ArID,
          authData.accessKey
        );
        const fetchedData = jsonData[0].Source;
        setDefaultStyle(fetchedData);
      } catch (error) {
        console.error("Error during request:", error);
        setError("An error occurred in fetchData");
        localStorage.setItem("error", "An error occurred in fetchData");
        localStorage.setItem("navigate", "/Open");
      } finally {
      }
    };

    fetchData();
  }, []);

  const { isMenuOpenleft, toggleMenu } = useMenu();

  const debouncedHandleSearch = debounce((query) => {
    setSearchQuery(query);
  }, 100);

  const handleSearch = (e) => {
    const newQuery = e.target.value;
    debouncedHandleSearch(newQuery);
  };

  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/Landing");
  };

  const rightButtons = [
    {
      icon: Theme.GoBack,
      text: "Back",
      color: "secondary",
      handleClick: () => {
        handleBack();
      },
    },
  ];

  const renderComponent = (currentComponent) => {
    switch (currentComponent) {
      case "1":
        return (
          <ThemeCards
            searchQuery={searchQuery}
            authData={authData}
            ReadArrayAllData={ThemeData}
            defaultStylefromloc={defaultStyle}
            error = {error}
            setError = {setError}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
    <div className="popup-form">
      <>
        <NavBar
          searchQuery={searchQuery}
          handleSearch={handleSearch}
          isSearchBar={true}
          rightButtons={rightButtons}
          title="Choose Theme"
          // isMenuOpenleft={isMenuOpenleft}
          toggleMenu={toggleMenu}
          isMenuOpenleft={true}
        />
        <div id="pop-render-form">{renderComponent(currentComponent)}</div>
      </>
    </div>
    {error && <ErrorBanner />}
    </>
  );
};

export default ThemeTemplates;
