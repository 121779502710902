export default function CustomMarkers() {
  return (
    <>
      <svg style={{ position: "absolute", top: "-9999px", left: "-9999px" }}>
        <defs>
          <marker
            id="closed-circle-marker"
            viewBox="0 0 10 10"
            refX="5"
            refY="5"
            markerWidth="10"
            markerHeight="10"
          >
            <circle cx="5" cy="5" r="4" fill="#1A192B" />
          </marker>
        </defs>
        <defs>
          <marker
            id={`circle-marker`}
            viewBox="0 0 100 100"
            refX="50"
            refY="50"
            markerWidth="10"
            markerHeight="10"
          >
            <circle
              cx="50"
              cy="50"
              r="40"
              fill="none"
              stroke="#1A192B"
              strokeWidth="2"
            />{" "}
          </marker>
        </defs>
        <defs>
          <marker
            id={`closed-square-marker`}
            viewBox="0 0 100 100"
            refX="50"
            refY="50"
            markerWidth="15"
            markerHeight="15"
          >
            <rect x="20" y="20" width="60" height="60" fill="#1A192B" />
          </marker>

          <marker
            id={`square-marker`}
            viewBox="0 0 100 100"
            refX="50"
            refY="50"
            markerWidth="15"
            markerHeight="15"
          >
            <rect
              x="20"
              y="20"
              width="60"
              height="60"
              fill="none"
              stroke="#1A192B"
              strokeWidth="2"
            />{" "}
          </marker>
        </defs>
        <defs>
          <marker
            id={`closed-rhombus-marker`}
            viewBox="0 0 100 100"
            refX="50"
            refY="50"
            markerWidth="20"
            markerHeight="20"
          >
            <polygon points="50,20 80,50 50,80 20,50" fill="#1A192B" />
          </marker>

          <marker
            id={`rhombus-marker`}
            viewBox="0 0 100 100"
            refX="50"
            refY="50"
            markerWidth="20"
            markerHeight="20"
          >
            <polygon
              points="50,20 80,50 50,80 20,50"
              fill="none"
              stroke="#1A192B"
              strokeWidth="2"
            />{" "}
          </marker>
        </defs>
      </svg>
    </>
  );
}
