import React, { useState, useEffect } from "react";
import { Theme, fontFamilies } from "../../IncludeFile/Theme";
import { Select, MenuItem, Switch } from "@mui/material";
import IconBox from "../IconBox";

const predefinedFontSizes = [
  { value: "35px", label: "Headline1" },
  { value: "24px", label: "Headline2" },
  { value: "18px", label: "Headline3" },
  { value: "16px", label: "LargeText" },
  { value: "14px", label: "NormalText" },
  { value: "12px", label: "SmallText" },
];
function FontDropDown({
  properties,
  selectedTool,
  handlePropertyChange,
  propSection,
}) {
  const initializeFontStyle = () => {
    const fontFamily = properties[propSection]?.fontFamily || "Arial";
    const fontWeight = properties[propSection]?.fontWeight || "normal";
    const fontSize = properties[propSection]?.fontSize || "16px";

    // Extract font size and unit
    const numericSize = parseFloat(fontSize);
    const fontUnit = fontSize.replace(/[0-9.]/g, "") || "px";

    // Match predefined font size
    const fontNameEntry = predefinedFontSizes.find(
      (size) => size.value === fontSize
    );

    return {
      fontName: fontNameEntry ? fontNameEntry.label : "Custom",
      fontSize: numericSize,
      fontUnit,
      fontFamily,
      fontWeight,
    };
  };

  const [fontStyle, setFontStyle] = useState(initializeFontStyle);

  const [fontStylesWeight, setFontStylesWeight] = useState({
    bold: 0,
    italic: 0,
    underline: 0,
  });
  // Synchronize fontStyle state when properties change
  useEffect(() => {
    setFontStyle(initializeFontStyle());
    setFontStylesWeight({
      ...fontStylesWeight,
      bold:
        properties[propSection]?.fontWeight?.toString().toLowerCase() === "bold"
          ? 1
          : 0,
    });
  }, [properties]);

  const handleSizeChange = (e) => {
    const { name, value } = e.target;

   

    if (name === "fontName") {
      // Find the corresponding size object based on the label
      const selectedSize = predefinedFontSizes.find(
        (size) => size.label === value
      );

      setFontStyle({
        ...fontStyle,
        fontName: value,
        fontSize: selectedSize ? selectedSize.value.replace(/[^\d]/g, "") : "",
        fontUnit: selectedSize ? selectedSize.value.replace(/\d+/g, "") : "",
      });
      handlePropertyChange(
        selectedTool,
        propSection,
        "fontSize",
        selectedSize.value
      );
    } else if (name === "fontSize") {
      // Check if the size exists in predefinedFontSizes
      const matchingSize = predefinedFontSizes.find(
        (size) => size.value === value + fontStyle.fontUnit
      );
      setFontStyle({
        ...fontStyle,
        fontSize: value,
        fontName: matchingSize ? matchingSize.label : "Custom",
      });
      handlePropertyChange(
        selectedTool,
        propSection,
        "fontSize",
        value + fontStyle.fontUnit
      );
    } else if (name === "fontUnit") {
      if (value !== "px") {
        setFontStyle({
          ...fontStyle,
          fontUnit: value,
          fontName: "Custom",
        });
        handlePropertyChange(
          selectedTool,
          propSection,
          "fontSize",
          fontStyle.fontSize + value
        );
      } else {
        // Check if the current fontSize and unit match a predefined size
        const fullSize = `${fontStyle.fontSize}${value}`;
        const matchingSize = predefinedFontSizes.find(
          (size) => size.value === fullSize
        );

        setFontStyle({
          ...fontStyle,
          fontUnit: value,
          fontName: matchingSize ? matchingSize.label : "Custom",
        });
        handlePropertyChange(
          selectedTool,
          propSection,
          "fontSize",
          fontStyle.fontSize + value
        );
      }
    }
  };

  const handleFontFamilyChange = (value) => {
    setFontStyle({
      ...fontStyle,
      fontFamily: value,
    });
    handlePropertyChange(selectedTool, propSection, "fontFamily", value);
  };

  const handleFontWeightChange = (name, value) => {
    setFontStylesWeight((prevFontStylesWeight) => ({
      ...prevFontStylesWeight,
      bold: name === "bold" ? value : prevFontStylesWeight.bold,
      italic: name === "italic" ? value : prevFontStylesWeight.italic,
      underline: name === "underline" ? value : prevFontStylesWeight.underline,
    }));
    if (name === "bold") {
      setFontStyle({
        ...fontStyle,
        fontWeight: value === 1 ? "bold" : "normal",
      });
      handlePropertyChange(
        selectedTool,
        propSection,
        "fontWeight",
        value === 1 ? "bold" : "normal"
      );
    }
  };
  return (
    <>
      <div className="stle-font">
        <div className="rht-fld-selectwrap for-fild-styl">
          <div className="rht-info fontlft-size">Font Size</div>
          <Select
            name="fontName"
            className="rht-fld-select"
            value={fontStyle.fontName}
            onChange={(e) => {
              handleSizeChange(e);
            }}
            renderValue={(selected) => {
              // If it's 'Custom', show 'Custom' but don't allow re-selection
              if (selected === "Custom") {
                return <em>Custom</em>;
              }
              // For predefined options, return the label normally
              return selected;
            }}
          >
            {[
              ...predefinedFontSizes.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.label}
                  style={{ fontSize: option.value }}
                >
                  {option.label}
                </MenuItem>
              )),
              // Render Custom as a disabled MenuItem
              <MenuItem
                key="custom"
                value="Custom"
                disabled
                style={{ display: "none" }}
              >
                Custom
              </MenuItem>,
            ]}
          </Select>
          <div className="rht-fnt">
            <div className="ipubrdr fontszefr-inp">
              <div className="rht-fld">
              <input
            name="fontSize"
            type="number"
            value={fontStyle.fontSize}
            onChange={(e) => {
              const value = e.target.value;
              // Ensure the value is 0 or more
              if (value >= 0 || value === "") {
                handleSizeChange(e); 
              }
            }}
            min="0"
          />
                <span className="unit-dropdown unit-font-drop">
                  <Select
                    name="fontUnit"
                    value={fontStyle.fontUnit}
                    onChange={(e) => {
                      handleSizeChange(e);
                    }}
                    style={{
                      width: "40px",
                      height: "25px",
                      backgroundColor: "#CFD8DD",
                      fontSize: "15px",
                    }}
                    displayEmpty
                    IconComponent={() => null}
                    MenuProps={{
                      style: { top: "5px" },
                    }}
                  >
                    <MenuItem value="px" className="custom-menuitem">
                      px
                    </MenuItem>
                    <MenuItem value="em" className="custom-menuitem">
                      em
                    </MenuItem>
                    <MenuItem value="pc" className="custom-menuitem">
                      %
                    </MenuItem>
                  </Select>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div> 




      <div className="rht-fld-selectwrap font-style-displ">
        <div className="rht-info fontlft-size">Font Family</div>

        <Select
          className="rht-fld-select"
          name="fontFamily"
          value={fontStyle.fontFamily}
          onChange={(e) => handleFontFamilyChange(e.target.value)}
        >
          {fontFamilies.map((font, index) => (
            <MenuItem key={index} value={font} style={{ fontFamily: font }}>
              {font}
            </MenuItem>
          ))}
        </Select>
      </div>
      <IconBox
        handleChange={handleFontWeightChange}
        buttonList={[
          {
            icon: Theme.FormatBold,
            name: "bold",
            value: fontStylesWeight.bold,
          },
          // {
          //   icon: Theme.FormatItalic,
          //   name: "italic",
          //   value: fontStylesWeight.italic,
          // },
          // {
          //   icon: Theme.FormatUnderline,
          //   name: "underline",
          //   value: fontStylesWeight.underline,
          // },
        ]}
      />
    </>
  );
}

export default FontDropDown;
