import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PopUpRenderer from "./PopUpForm/PopUpRenderer";
import RecursiveTreeView from "./Components/RecursiveTreeView/RecursiveTreeView";
import Landing from "./pages/landingPage/Landing";
import Diagrams from "./pages/diagramPages/Diagrams";
import DiagramsRenderer from "./Components/diagramsComponents/diagramsFormComponents/DiagramsRenderer";
import PanelComponent from "./reusableComponents/PanelComponent";
import NotFound from "./Components/RecursiveTreeView/NotFound";
import CreateInfo from "./PopUpForm/CreateInfo";
import ViewCardInfo from "./PopUpForm/ViewCardInfo";
import NodeSelector from "./pages/diagramPages/NodeSelectorPage";
import NotificationCenter from "./Components/AlertComponents/NotificationCenter";
import themeProvider from "./ThemeProvider";
import NodeProperties from "./Components/diagramsComponents/toolProperties/NodeProperties";
import Discover from "./PopUpForm/Discover";
import DiscoverEdit from "./PopUpForm/DiscoverEdit";
import EditTemplatePage from "./pages/diagramPages/EditTemplatePage";
import EditControls from "./pages/diagramPages/EditControls";
import EditControlsStyles from "./pages/diagramPages/EditControlsStyles";
import Login from "./pages/Login";
import LoginBusiness from "./pages/Login/LoginBusiness";
import { AppProvider } from "./Components/diagramsComponents/RegionContext";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ThemeTemplates from "./Components/ThemeBuilder/ThemeTemplates";
import DiagramBuilderSidebar from "./pages/TestPage";
import { loadLoginData } from "./apiCalls/DiagramsAPI";
import PrivateRoute from "./PrivateRoute";
import { ErrorProvider } from "./Error/ErrorContext";
import ErrorBanner from "./Error/ErrorBanner";

function App() {
  useEffect(() => {
    const timer = setTimeout(() => {
      if (window.location.pathname === "/") {
        sessionStorage.clear();
      }
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  document.documentElement.style.setProperty(
    "--primary-main",
    themeProvider.palette.primary.main
  );
  document.documentElement.style.setProperty(
    "--primaryContrastColor-main",
    themeProvider.palette.primary.contrastText
  );

  const loginData = loadLoginData();


  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <ErrorProvider>
        <AppProvider loginData={loginData}>
          <NotificationCenter />
          <ErrorBanner />
          <Routes>
            <Route index element={<Login />} />
            <Route
              path="/Landing"
              element={
                <PrivateRoute>
                  <Landing />
                </PrivateRoute>
              }
            />

            <Route
              path="/Form/:arId"
              element={
                <PrivateRoute>
                  <DndProvider backend={HTML5Backend}>
                    <RecursiveTreeView />
                  </DndProvider>
                </PrivateRoute>
              }
            />
            <Route
              path="/Themes/:arId"
              element={
                <PrivateRoute>
                  <DndProvider backend={HTML5Backend}>
                    <RecursiveTreeView />
                  </DndProvider>
                </PrivateRoute>
              }
            />
            <Route
              path="/Open"
              element={
                <PrivateRoute>
                  <PopUpRenderer />
                </PrivateRoute>
              }
            />
            <Route
              path="/test"
              element={
                <PrivateRoute>
                  <DiagramBuilderSidebar />
                </PrivateRoute>
              }
            />
            <Route
              path="/Themes"
              element={
                <PrivateRoute>
                  <ThemeTemplates />
                </PrivateRoute>
              }
            />

            <Route
              path="/Diagrams"
              element={
                <PrivateRoute>
                  <Diagrams />
                </PrivateRoute>
              }
            />
            <Route
              path="/Select"
              element={
                <PrivateRoute>
                  <DiagramsRenderer />
                </PrivateRoute>
              }
            />
            <Route
              path="/PanelComponent"
              element={
                <PrivateRoute>
                  <PanelComponent />
                </PrivateRoute>
              }
            />
            <Route
              path="/ViewCardInfo"
              element={
                <PrivateRoute>
                  <ViewCardInfo />
                </PrivateRoute>
              }
            />
            <Route
              path="/CreateInfo"
              element={
                <PrivateRoute>
                  <CreateInfo />
                </PrivateRoute>
              }
            />
            <Route
              path="/Discover"
              element={
                <PrivateRoute>
                  <Discover />
                </PrivateRoute>
              }
            />
            <Route
              path="/DiscoverEdit"
              element={
                <PrivateRoute>
                  <DiscoverEdit />
                </PrivateRoute>
              }
            />
            <Route
              path="/EditTemplates"
              element={
                <PrivateRoute>
                  <EditTemplatePage />
                </PrivateRoute>
              }
            />
            <Route
              path="/ToolSelector"
              element={
                <PrivateRoute>
                  <NodeSelector />
                </PrivateRoute>
              }
            />
            <Route
              path="/Edit"
              element={
                <PrivateRoute>
                  <EditControls />
                </PrivateRoute>
              }
            />
            <Route
              path="/Styles"
              element={
                <PrivateRoute>
                  <EditControlsStyles />
                </PrivateRoute>
              }
            />
            <Route
              path="/ToolProperties"
              element={
                <PrivateRoute>
                  <NodeProperties />
                </PrivateRoute>
              }
            />

            <Route
              path="/Login2"
              element={
                <PrivateRoute>
                  <LoginBusiness />
                </PrivateRoute>
              }
            />

            <Route
              path="*"
              element={
                <PrivateRoute>
                  <NotFound />
                </PrivateRoute>
              }
            />
          </Routes>
        </AppProvider>
      </ErrorProvider>
    </BrowserRouter>
  );
}

export default App;



// import React, { useEffect, useState } from 'react';

// const IpInfoComponent = () => {
//   const [ipAddress, setIpAddress] = useState(null);
//   const [ipInfo, setIpInfo] = useState(null);

//   useEffect(() => {
//     fetch('https://api.ipify.org?format=json')
//       .then((response) => response.json())
//       .then((data) => {
//         setIpAddress(data.ip); 
//         fetch(`https://freeipapi.com/api/json/${data.ip}`)
//           .then((response) => response.json())
//           .then((data) => setIpInfo(data))
//           .catch((error) => console.error('Error fetching IP info:', error));
//       })
//       .catch((error) => console.error('Error fetching IP address:', error));
//   }, []);

//   return (
//     <div>
//       <h1>IP Information</h1>
//       <p>Your IP Address: {ipAddress}</p>
//       {ipInfo ? (
//         <pre>{JSON.stringify(ipInfo, null, 2)}</pre>
//       ) : (
//         <p>Loading IP information...</p>
//       )}
//     </div>
//   );
// };

// export default IpInfoComponent;


// import React, { useEffect, useState } from 'react';
// import axios from 'axios';

// const App = () => {
  // const [IPDetails, setIPDetails] = useState({
  //   Country: "Unknown",
  //   Region: "Unknown",
  //   City: "Unknown",
  //   LoginTime: "Unknown",
  //   IPAddress: "Unknown",
  //   Latitude: "Unknown",
  //   Longitude: "Unknown",
  // });

  // useEffect(() => {
  //   axios
  //     .get('https://freeipapi.com/api/json') 
  //     .then((response) => {
  //       const data = response.data;
  //       console.log("data",data)
  //       const updatedDetails = {
  //         Country: data.countryName || "Unknown",
  //         Region: data.regionName || "Unknown",
  //         City: data.cityName || "Unknown",
  //         LoginTime: data.timeZone || "Unknown",
  //         IPAddress: data.ipAddress || "Unknown",
  //         Latitude: data.latitude || "Unknown",
  //         Longitude: data.longitude || "Unknown",
  //       };
  //       setIPDetails(updatedDetails);
  //       GetToken(updatedDetails); 
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching IP details:", error);
  //     });
  // }, []);

  // const GetToken = (IPDetails) => {
  //   console.log("Passing IPDetails to GetToken method:", IPDetails);
  // };

//   return (
//     <div>
//       <h1>IP Details</h1>
//       <pre>{JSON.stringify(IPDetails, null, 2)}</pre>
//     </div>
//   );
// };

// export default App;
