import React, { useState, useEffect, useCallback } from "react";
import { Select, MenuItem } from "@mui/material";
import _ from "lodash";

function PaddingDropDown({ properties, selectedTool, handlePropertyChange }) {
  // Initialize the padding state from properties
  const initializePadding = () => {
    const defaultPadding = { top: 0, right: 0, bottom: 0, left: 0 };
    const defaultUnits = { top: "px", right: "px", bottom: "px", left: "px" };

    if (properties?.node?.padding) {
      const paddingParts = properties.node.padding.split(" ");
      if (paddingParts.length === 4) {
        return {
          top: parsePaddingPart(paddingParts[0], defaultUnits.top),
          right: parsePaddingPart(paddingParts[1], defaultUnits.right),
          bottom: parsePaddingPart(paddingParts[2], defaultUnits.bottom),
          left: parsePaddingPart(paddingParts[3], defaultUnits.left),
        };
      }
    }

    return {
      top: { value: defaultPadding.top, unit: defaultUnits.top },
      right: { value: defaultPadding.right, unit: defaultUnits.right },
      bottom: { value: defaultPadding.bottom, unit: defaultUnits.bottom },
      left: { value: defaultPadding.left, unit: defaultUnits.left },
    };
  };

  const parsePaddingPart = (paddingPart, defaultUnit) => {
    const value = parseFloat(paddingPart);
    const unit = paddingPart.replace(/[0-9.]/g, "") || defaultUnit;
    return { value: value || 0, unit };
  };

  const [nodePadding, setNodePadding] = useState(initializePadding);

  // Synchronize padding state when properties change
  useEffect(() => {
    setNodePadding(initializePadding());
  }, [properties]);

  const debouncedHandlePropertyChange = useCallback(
    _.debounce((updatedPadding) => {
      const combinedPadding = `${updatedPadding.top.value}${updatedPadding.top.unit} ${updatedPadding.right.value}${updatedPadding.right.unit} ${updatedPadding.bottom.value}${updatedPadding.bottom.unit} ${updatedPadding.left.value}${updatedPadding.left.unit}`;
      
      handlePropertyChange(
        selectedTool,
        "node",
        "padding",
        combinedPadding
      );
    }, 200),
    [handlePropertyChange, selectedTool]
  );
  
  // const handlePaddingChange = (name, value, isUnit) => {
  //   setNodePadding((prev) => {
  //     const updatedPadding = {
  //       ...prev,
  //       [name]: isUnit
  //         ? { ...prev[name], unit: value }
  //         : { ...prev[name], value: value },
  //     };
  
  //     // Debounce property change with combined padding
  //     debouncedHandlePropertyChange(updatedPadding);
  
  //     return updatedPadding;
  //   });
  // };

  const handlePaddingChange = (name, value, isUnit) => {
    const newValue = Math.max(0, parseFloat(value)); 
    
    setNodePadding((prev) => {
      const updatedPadding = {
        ...prev,
        [name]: isUnit
          ? { ...prev[name], unit: value }
          : { ...prev[name], value: newValue },
      };
  
      // Debounce property change with combined padding
      debouncedHandlePropertyChange(updatedPadding);
  
      return updatedPadding;
    });
  };
  
  
  const renderInput = (name) => (
    <>
      <input
        type="number"
        name={name}
        value={nodePadding[name].value}
        onChange={(e) => handlePaddingChange(name, e.target.value, false)}
      />
    </>
  );

  const renderSelect = (name) => (
    <Select
      name={`${name}Unit`}
      value={nodePadding[name].unit}
      onChange={(e) => handlePaddingChange(name, e.target.value, true)}
      style={{ width: "50px", height: "27px", padding: "10px 11px 10px 13px" }}
      displayEmpty
      IconComponent={() => null}
      MenuProps={{ style: { top: "5px" } }}
      className="custom-dropdown"
    >
      <MenuItem value="px" className="custom-menuitem">
        px
      </MenuItem>
      <MenuItem value="em" className="custom-menuitem">
        em
      </MenuItem>
      <MenuItem value="%" className="custom-menuitem">
        %
      </MenuItem>
    </Select>
  );

  return (
    <div>
      <div className="rht-flds-wrap">
        <div className="rht-fld-info">
          <div className="ipubrdr">
            <div className="rht-fld">
              {renderInput("top")}
              <span className="unit-dropdown">{renderSelect("top")}</span>
            </div>
          </div>
          <div className="rht-info">Top</div>
        </div>
        <div className="rht-fld-info">
          <div className="ipubrdr">
            <div className="rht-fld">
              {renderInput("right")}
              <span className="unit-dropdown">{renderSelect("right")}</span>
            </div>
          </div>
          <div className="rht-info">Right</div>
        </div>
      </div>

      <div className="rht-flds-wrap">
        <div className="rht-fld-info">
          <div className="ipubrdr">
            <div className="rht-fld">
              {renderInput("bottom")}
              <span className="unit-dropdown">{renderSelect("bottom")}</span>
            </div>
          </div>
          <div className="rht-info">Bottom</div>
        </div>
        <div className="rht-fld-info">
          <div className="ipubrdr">
            <div className="rht-fld">
              {renderInput("left")}
              <span className="unit-dropdown">{renderSelect("left")}</span>
            </div>
          </div>
          <div className="rht-info">Left</div>
        </div>
      </div>
    </div>
  );
}

export default PaddingDropDown;
