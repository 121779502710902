import React, { useState, useEffect } from "react";
import MenuCardComponent from "../../PopUpForm/MenuCardComponent";
import themeProvider from "../../ThemeProvider";
import { ThemeProvider } from "@mui/material";
import NavBar from "../../Components/navbarComponents/NavBar";
import { useNavigate } from "react-router-dom";
import { Grid, Box, Card, CardContent } from "@mui/material";
import { Theme } from "../../Components/IncludeFile/Theme";
import {
  UpdateTemplateHeader,
  postResource,
  createRequestBody,
  loadLoginData,
} from "../../apiCalls/DiagramsAPI";
import Overview from "../../Components/diagramsComponents/Overview";
import { ToastContainer, toast } from "react-toastify";
import { useAppContext } from "../../Components/diagramsComponents/RegionContext";
import { getFormattedLocalDateTime } from "../../functions/ModifiedDate";
function EditTemplatePage() {
  const navigate = useNavigate();
  const [selectedItem, setSelectedItem] = useState(null);
  const [selected, setSelected] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [openOverview, setOpenOverview] = useState(false);
  const [openAddPopup, setOpenAddPopup] = useState(false);
  const [overviewDirtyFlag, setOverviewDirtyFlag] = useState(false);
  const [overviewDirtyFlagNext, setOverviewDirtyFlagNext] = useState(false);

  const authData = loadLoginData();
  const accessKey = authData?.accessKey || "";
  //get info from RegionContext
  const { getRegionInfo } = useAppContext();
  const [regionInfo, setRegionInfo] = useState(null);
  const fetchRegions = async () => {
    try {
      const storedRegion = localStorage.getItem("region");
      console.log("storedRegion ", storedRegion);
      const info = await getRegionInfo(storedRegion);
      console.log("info ", info);
      setRegionInfo(info);
      // Call fetchData only if info is valid and fetched is false
      if (info !== null && info !== undefined) {
        fetchData(info);
        return true;
      }
    } catch (error) {
      console.error("Error fetching regions:", error);
      return false;
    }
  };

  async function fetchData(info) {
    try {
      console.log("info.API1 ", info);
      const templates = await postResource(
        "GetQuickInfo",
        createRequestBody({
          accessKey: accessKey,
          guid: `${info.API1}`,
          param1: authData?.userName?.toString() || "Unknown User",
        })
      );
      console.log("templates ", templates);
      setTemplates(templates.filter((item) => item.Bid === 0));
      setSelected(templates.filter((item) => item.Bid === 0));
      setFetched(true);
    } catch (error) {
      console.error("Fetch Error ", error);
      toast.error("Error getting templates: " + error.message);
    }
  }
  React.useEffect(() => {
    const fetchData = async () => {
      const result = await fetchRegions();
      setFetched(result); // Only set fetched to true if fetchRegions returns true
    };

    fetchData(); // Call the async function
  }, [fetched]);

  const handleOverviewPopup = () => {
    setOpenOverview(!openOverview);
  };
  var diagTempID;
  const handleSave = async (data) => {
    try {
      data.Region = regionInfo.Region;
      const userName = authData?.userName || "Unknown User";
      const todayDate = getFormattedLocalDateTime();
      if (!data.createdBy) {
        data.createdBy = userName;
      }
      if (!data.createdDate) {
        data.createdDate = todayDate;
      }
      // Update 'modifiedBy' and 'modifiedDate'
      data.modifiedBy = userName;
      data.modifiedDate = todayDate;
      console.log("data ", data);
      const templatePostResponse = await postResource(
        "PostQuickInfo",
        createRequestBody({
          accessKey: accessKey,
          guid: `${window.APIProvider.recordPath.UpsertQinfoDiagTemplateHeader}`,
          body: JSON.stringify(data),
        })
      );
      // Update selected data with new information
      const selectedData = selected.find(
        (item) => item?.diagTempID === data.diagTempID
      );
      console.log("selectedData ", selectedData);
      console.log("selected ", selected);
      console.log("data ", data);
      if (selectedData) {
        Object.assign(selectedData, data);
      } else {
        selected.push(data);
      }
      console.log("templatePostResponse ", templatePostResponse);
      diagTempID = templatePostResponse.find(
        (item) => item.outFields && item.outFields.DiagTempID
      )?.outFields.DiagTempID;
      console.log("diagTempID ", diagTempID);
    } catch (error) {
      console.error("Fetch Error ", error);
      toast.error("Error saving data: " + error.message);
    }
  };
  const handleAddClick = async () => {
    setOpenAddPopup(true);
  };

  //popup bottom buttons
  const handleBack = () => {
    console.log("Back");
    setSelectedItem(null);
    handleOverviewPopup();
    setOpenAddPopup(!openAddPopup);
  };
  const handleAddBack = () => {
    setOpenAddPopup(!openAddPopup);
  };
  const handleAddNext = (title) => {
    if (diagTempID !== undefined) {
      setOpenAddPopup(!openAddPopup);
      navigate("/ToolSelector", {
        state: { DiagTempID: diagTempID, DiagTempTitle: title },
      });
    }
  };

  const handleNext = (data) => {
    //call backend
    navigate("/ToolSelector", {
      state: {
        DiagTempID: data?.diagTempID ?? selectedItem.diagTempID,
        DiagTempTitle:
          data?.title ?? data?.name ?? selectedItem.title ?? selectedItem.name,
      },
    });
  };

  const handleDetails = (item) => {
    console.log("item ", item);
    if (item) {
      setSelectedItem(item);
    } else {
      setSelectedItem(null);
    }
    handleOverviewPopup();
  };

  const handleToggleTemplateActivity = async (diagTempID) => {
    try {
      const response = await postResource(
        "GetQuickInfo",
        createRequestBody({
          accessKey: accessKey,
          guid: `${window.APIProvider.recordPath.ToggleTemplateActiveness}`,
          param1: diagTempID.toString(),
        })
      );
      const updatedSelected = selected.map((item) =>
        item.diagTempID === diagTempID
          ? { ...item, isInactive: item.isInactive === 1 ? 0 : 1 }
          : item
      );
      console.log("updatedSelected ", updatedSelected);
      setSelected(updatedSelected);
      console.log("response ", response);
      if (response.Status === "SUCCESS") {
        toast.success("Success!");
      }
    } catch (error) {
      console.error("Error ", error);
      toast.error("Error Toggling Project Activity: " + error.message);
    }
  };
  //edit icon Click
  const editTemplate = (tempID, title) => {
    console.log("tempID ", tempID);
    navigate("/ToolSelector", {
      state: { DiagTempID: tempID, DiagTempTitle: title },
    });
  };

  //search
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };
  const itemsToDisplay = selected?.filter(
    (section) =>
      section?.name?.toLowerCase().includes(searchQuery) ||
      section?.title?.toLowerCase().includes(searchQuery)
  );

  //navbar button
  const rightButtons = [
    {
      icon: Theme.GoBack,
      text: "Back",
      color: "secondary",
      tooltip: "Go back to previous page",
      handleClick: () => {
        navigate("/Landing");
      },
    },
  ];
  return (
    <ThemeProvider theme={themeProvider}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <NavBar
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        isSearchBar={true}
        rightButtons={rightButtons}
        title={`Choose [${localStorage.getItem("region")}] Template`}
        isMenuOpenleft={false}
      />
      <div
        style={{
          height: "94vh",
          overflowX: "auto",
          backgroundColor: themeProvider.palette.bg.lightGray,
        }}
      >
        <Grid sx={{ flexGrow: 1 }} container padding={1}>
          <MenuCardComponent
            size={360}
            textSize={17}
            handleDetails={handleDetails}
            handleEditDiagram={handleAddClick}
            isAddCard={1}
          />
          {itemsToDisplay.map((item, index) => (
            <MenuCardComponent
              key={index}
              data={item}
              size={360}
              textSize={17}
              editTemplate={editTemplate}
              handleDetails={handleDetails}
              handleEditDiagram={handleNext}
              handleToggleTemplateActivity={handleToggleTemplateActivity}
              caller={1}
              firstButtonTitle={"Template Info"}
              firstButtonToolTip={"View and edit template information"}
            />
          ))}
        </Grid>
      </div>
      {/* overview clicked */}
      {openOverview && (
        <Overview
          open={openOverview}
          handlePopup={handleOverviewPopup}
          item={selectedItem}
          handleBack={handleBack}
          handleNext={handleNext}
          handleSave={handleSave}
          overviewDirtyFlag={overviewDirtyFlag}
          setOverviewDirtyFlag={setOverviewDirtyFlag}
        />
      )}
      {openAddPopup && (
        <Overview
          open={openAddPopup}
          handlePopup={() => setOpenAddPopup(false)}
          handleBack={handleAddBack}
          handleNext={handleAddNext}
          handleSave={handleSave}
          overviewDirtyFlag={overviewDirtyFlagNext}
          setOverviewDirtyFlag={setOverviewDirtyFlagNext}
        />
      )}
    </ThemeProvider>
  );
}

export default EditTemplatePage;
