import { styled } from "@mui/material/styles";
import themeProvider from "../ThemeProvider";

const shouldForwardProp = (prop) => prop !== "weight";

export const Container = styled("div")({
  display: "flex",
});

export const Pane = styled("div", { shouldForwardProp })((props) => ({
  flex: props.weight ? props.weight : 1,
  maxHeight: "94vh",
  overflowY: "auto",
}));

export const SplitScreen = ({
  children,
  leftWeight = 1,
  rightWeight = 1,
  isMenuOpenleft,
}) => {
  const [left, right] = children;

  return (
    <Container>
      <Pane
        weight={leftWeight}
        className={`leftIcon ${isMenuOpenleft ? "open" : ""}`}
      >
        {left}
      </Pane>
      <Pane
        weight={rightWeight}
        sx={{ backgroundColor: themeProvider.palette.bg.lightGray }}
      >
        {right}
      </Pane>
    </Container>
  );
};
