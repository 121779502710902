// deleteNodeFunctions.js

import { useCallback } from "react";
import { defaultEdgeOptions } from "../data/defaultEdgeOptions";

//JUST IF NEEDED IN THE FUTURE
export const useDeleteNodeWithoutRerouting = (setNodes) => {
  return useCallback(
    (nodeId) => {
      console.log("nodeId ", nodeId);
      setNodes((prevNodes) => prevNodes.filter((node) => node.id !== nodeId));
    },
    [setNodes]
  );
};

export const useDeleteNode = (
  setEdges,
  setNodes,
  nodes,
  edges,
  getIncomers,
  getOutgoers,
  getConnectedEdges,
  MarkerType
) => {
  return useCallback(
    (deletedNodes) => {
      // First remove all nodes that are being deleted
      setNodes((prevNodes) =>
        prevNodes.filter((node) => !deletedNodes.some((n) => n.id === node.id))
      );

      // Get all edges connected to deleted nodes
      const allConnectedEdges = getConnectedEdges(deletedNodes, edges);

      // Find all remaining edges that don't connect to any deleted nodes
      const remainingEdges = edges.filter(
        (edge) => !allConnectedEdges.some((e) => e.id === edge.id)
      );

      // For each deleted node, create new edges between its incomers and outgoers
      const newEdgesMap = new Map(); // Use map to prevent duplicate edges

      deletedNodes.forEach((deletedNode) => {
        const incomers = getIncomers(deletedNode, nodes, edges).filter(
          (node) => !deletedNodes.some((n) => n.id === node.id)
        );
        const outgoers = getOutgoers(deletedNode, nodes, edges).filter(
          (node) => !deletedNodes.some((n) => n.id === node.id)
        );

        incomers.forEach((source) => {
          outgoers.forEach((target) => {
            const edgeId = `${source.id}-${target.id}`;
            // Only create edge if it doesn't already exist
            if (
              !newEdgesMap.has(edgeId) &&
              !remainingEdges.some((e) => e.id === edgeId)
            ) {
              newEdgesMap.set(edgeId, {
                id: edgeId,
                type: "floating",
                source: source.id,
                target: target.id,
                animated: true,
                style: {
                  strokeWidth: 4,
                  stroke: "red",
                },
                markerEnd: {
                  type: MarkerType.ArrowClosed,
                  width: 15,
                  height: 15,
                  color: "red",
                },
              });
            }
          });
        });
      });

      // Convert map to array and add timeouts
      const newEdgesWithTimeouts = Array.from(newEdgesMap.values()).map(
        (edge) => ({
          ...edge,
          timeoutId: setTimeout(() => {
            setEdges((prevEdges) =>
              prevEdges.map((prevEdge) =>
                prevEdge.id === edge.id
                  ? {
                      ...prevEdge,
                      ...defaultEdgeOptions,
                    }
                  : prevEdge
              )
            );
          }, 3000),
        })
      );

      // Update edges with remaining and new edges
      setEdges([...remainingEdges, ...newEdgesWithTimeouts]);
    },
    [
      setEdges,
      setNodes,
      nodes,
      edges,
      getIncomers,
      getOutgoers,
      getConnectedEdges,
      MarkerType,
    ]
  );
};

//commented 27-12-2024 does not support multiple node deletion
// export const useDeleteNode = (
//   setEdges,
//   setNodes,
//   nodes,
//   edges,
//   getIncomers,
//   getOutgoers,
//   getConnectedEdges,
//   MarkerType
// ) => {
//   return useCallback(
//     (deleted) => {
//       console.log("deleted ", deleted);
//       setEdges(
//         deleted.reduce((acc, node) => {
//           console.log("acc ", acc);
//           console.log("node ", node);
//           const incomers = getIncomers(node, nodes, edges);
//           const outgoers = getOutgoers(node, nodes, edges);
//           console.log("incomers ", incomers);
//           console.log("outgoers ", outgoers);
//           const connectedEdges = getConnectedEdges([node], edges);
//           const remainingEdges = acc.filter(
//             (edge) => !connectedEdges.includes(edge)
//           );
//           console.log("remainingEdges ", remainingEdges);
//           const createdEdges = incomers.flatMap(({ id: source }) =>
//             outgoers.map(({ id: target }) => ({
//               id: `${source}-${target}`,
//               type: "floating",
//               source,
//               target,
//               animated: true,
//               style: {
//                 strokeWidth: 4,
//                 stroke: "red",
//               },
//               markerEnd: {
//                 type: MarkerType.ArrowClosed,
//                 width: 15,
//                 height: 15,
//                 color: "red",
//               },
//             }))
//           );
//           console.log("createdEdges ", createdEdges);
//           setNodes((prevNodes) => prevNodes.filter((n) => n.id !== node.id));
//           const updatedEdges = createdEdges.map((edge) => ({
//             ...edge,
//             timeoutId: setTimeout(() => {
//               setEdges((prevEdges) =>
//                 prevEdges.map((prevEdge) =>
//                   prevEdge.id === edge.id
//                     ? {
//                         ...prevEdge,
//                         animated: false,
//                         style: {
//                           strokeWidth: 1,
//                         },
//                         markerEnd: {
//                           type: MarkerType.ArrowClosed,
//                           width: 15,
//                           height: 15,
//                         },
//                       }
//                     : prevEdge
//                 )
//               );
//             }, 3000),
//           }));

//           return [...remainingEdges, ...updatedEdges];
//         }, edges)
//       );
//     },
//     [
//       setEdges,
//       nodes,
//       edges,
//       getIncomers,
//       getOutgoers,
//       getConnectedEdges,
//       MarkerType,
//     ]
//   );
// };

export const useUpdateNodeAndChildren = (nodes, edges) => {
  const updateNodeAndChildren = useCallback(
    (parentNode, node) => {
      // Calculate the new position based on the dragged node's position
      const offsetY = parentNode.position.y - node.position.y + 100;

      // Update the position of the current node
      node.position.y = node.position.y + offsetY;

      // Recursively update children nodes
      edges
        .filter((edge) => edge.source === node.id)
        .forEach((edge) => {
          const connectedNode = nodes.find((n) => n.id === edge.target);
          if (connectedNode) {
            updateNodeAndChildren(node, connectedNode);
          }
        });
    },
    [nodes, edges]
  );

  return updateNodeAndChildren;
};

export const useNodeDragStop = (
  setNodes,
  nodes,
  edges,
  updateNodeAndChildren
) => {
  return useCallback(
    (event, draggedNode) => {
      var connectedNodes = edges
        .filter((edge) => edge.source === draggedNode.id)
        .map((edge) => edge.target);

      // Update the dragged node and its connected nodes
      nodes.forEach((node) => {
        if (node.id === draggedNode.id || connectedNodes.includes(node.id)) {
          updateNodeAndChildren(draggedNode, node);
        }
      });

      setNodes([...nodes]); // Trigger a state update to re-render
    },
    [setNodes, nodes, edges, updateNodeAndChildren]
  );
};

// export const useNodeClick = (setSelectedNode, toggleDrawer) => {
//   return useCallback(
//     (event, clickedNode) => {
//       console.log("clickedNode ", clickedNode);
//       setSelectedNode(clickedNode);
//       toggleDrawer(2);
//     },
//     [setSelectedNode]
//   );
// };

// export const useNodeClick = (setSelectedNode, toggleDrawer ,setTabValue ,tabValue) => {
//   return useCallback(
//     (event, clickedNode) => {
//       console.log("clickedNode ", clickedNode);
//       setSelectedNode(clickedNode);
//       toggleDrawer(2);
//       if (tabValue !== "2"){
//         setTabValue("1")
//       }

//     },
//     [setSelectedNode]
//   );
// };

export const useNodeClick = (
  setSelectedNode,
  toggleDrawer,
  setTabValue,
  tabValue
) => {
  return useCallback(
    (event, clickedNode) => {
      setSelectedNode(clickedNode);
      toggleDrawer(2);
      setTabValue("1");
    },
    [setSelectedNode, toggleDrawer, setTabValue]
  );
};
