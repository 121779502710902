import React, { useState, useEffect, useCallback } from "react";
import { Select, MenuItem } from "@mui/material";
import _ from "lodash";

function DividerDropDown({
  properties,
  selectedTool,
  handlePropertyChange,
  propSection,
}) {
  const initializeDivider = () => {
    const dividerWidth = properties[propSection]?.borderWidth || "1px";

    // Extract numeric value and unit
    const numericSize = parseFloat(dividerWidth);
    const dividerUnit = dividerWidth.replace(/[0-9.]/g, "") || "px";

    return {
      dividerSize: numericSize,
      dividerUnit,
    };
  };

  const [divider, setDivider] = useState({
    dividerSize: "",
    dividerUnit: "px",
  });

  // Synchronize padding state when properties change
  useEffect(() => {
    setDivider(initializeDivider());
  }, [properties]);

  // const handleDividerChange = (e) => {
  //   const { name, value, type, checked } = e.target;
  //   setDivider((prevDividerStyles) => {
  //     const updatedDividerStyles = {
  //       ...prevDividerStyles,
  //       [name]: type === "checkbox" ? checked : value,
  //     };
  //     handlePropertyChange(
  //       selectedTool,
  //       propSection,
  //       "borderWidth",
  //       updatedDividerStyles.dividerSize + updatedDividerStyles.dividerUnit
  //     );
  //     return updatedDividerStyles;
  //   });
  // };

  const debouncedHandlePropertyChange = useCallback(
    _.debounce((updatedStyles) => {
      handlePropertyChange(
        selectedTool,
        propSection,
        "borderWidth",
        updatedStyles.dividerSize + updatedStyles.dividerUnit
      );
    }, 200),
    [handlePropertyChange, selectedTool, propSection]
  );

  const handleDividerChange = (e) => {
    const { name, value, type, checked } = e.target;
    setDivider((prevDividerStyles) => {
      const updatedDividerStyles = {
        ...prevDividerStyles,
        [name]: type === "checkbox" ? checked : value,
      };
      debouncedHandlePropertyChange(updatedDividerStyles);

      return updatedDividerStyles;
    });
  };

   return (
    <>
      <div
        className="backgrounclr "
        style={{ justifyContent: "space-between" }}
      >
        Color
        <div>
          <input
            type="color"
            name="dividerColor"
            value={properties[propSection].color}
            // value={dividerColor}
            onChange={(e) => {
              handlePropertyChange(
                selectedTool,
                propSection,
                "borderColor",
                e.target.value
              );
            }}
          />
        </div>
      </div>
      <div className="stle-font">
        <div className="rht-fld-selectwrap for-fild-styl">
          <div className="rht-info fontlft-size">Divider Size</div>
          <div className="rht-fnt">
            <div className="ipubrdr fontszefr-inp">
              <div className="rht-fld">
                <input
                  name="dividerSize"
                  type="number"
                  value={divider.dividerSize}
                  onChange={(e) => {
                    const value = e.target.value;
                     // Ensure the value is 0 or more
                      if (value >= 0 || value === "") {
                        handleDividerChange(e); 
                       }
                       }}
                     min="0"
                />
                <span className="unit-dropdown unit-font-drop">
                  <Select
                    name="dividerUnit"
                    value={divider.dividerUnit}
                    onChange={(e) => {
                      handleDividerChange(e);
                    }}
                    style={{
                      width: "40px",
                      height: "25px",
                      backgroundColor: "#CFD8DD",
                      fontSize: "15px",
                    }}
                    displayEmpty
                    IconComponent={() => null}
                    MenuProps={{
                      style: { top: "5px" },
                    }}
                  >
                    <MenuItem value="px" className="custom-menuitem">
                      px
                    </MenuItem>
                    <MenuItem value="em" className="custom-menuitem">
                      em
                    </MenuItem>
                    <MenuItem value="%" className="custom-menuitem">
                      %
                    </MenuItem>
                  </Select>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DividerDropDown;
