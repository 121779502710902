import React, { useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
} from "@mui/material";
import CustomPopUpForm from "../layout/CustomPopUpForm";
function ChooseRegion({
  open,
  onClose,
  title,
  dropdownOptions,
  selectedDropdownOption,
  handleDropdownChange,
}) {
  const [internalRegion, setInternalRegion] = useState(selectedDropdownOption);
  const regionChangeInternal = (region) => {
    setInternalRegion(region);
  };

  const handleSubmit = () => {
    handleDropdownChange(internalRegion);
    onClose();
  };

  console.log("internalRegion ", internalRegion);
  return (
    <CustomPopUpForm open={open} onClose={onClose} title={title}>
      {dropdownOptions &&
        selectedDropdownOption !== null &&
        selectedDropdownOption !== undefined &&
        handleDropdownChange && (
          <FormControl fullWidth size="small">
            {/* <InputLabel>Region</InputLabel> */}
            <Typography
              className="name-of-contol"
              color="primary"
            >{`Title:`}</Typography>
            <Select
              color="primary"
              fullWidth
              value={internalRegion}
              onChange={(event) => regionChangeInternal(event.target.value)}
              size="small"
              sx={{
                "& .MuiSelect-select": {
                  padding: "7px",
                },
              }}
            >
              {dropdownOptions.map((option, index) => (
                <MenuItem key={index} value={option}>
                  {option}
                </MenuItem>
                // <MenuItem key={index} value={option.region}>
                //   {option.region}
                // </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      <Button
        style={{
          marginLeft: "1rem",
          marginRight: "2.5rem",
          textTransform: "none",
        }}
        fullWidth
        variant="contained"
        onClick={handleSubmit}
      >
        Go
      </Button>
    </CustomPopUpForm>
  );
}

export default ChooseRegion;
