import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faLock, faUser } from "@fortawesome/free-solid-svg-icons";
import { FaUser } from "react-icons/fa";
import { Button, ThemeProvider } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Image, Theme } from "../Components/IncludeFile/Theme";
import ReCAPTCHA from "react-google-recaptcha";
import { AuthContext } from "../PopUpForm/AppStateProvider ";
import CryptoJS from "crypto-js";
import themeProvider from "../ThemeProvider";
import axios from "axios";

const ContainerLoginPageBg = styled.div`
  background-image: url(${Image.Loginuser});
  background-size: cover;
  background-position: left top;
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

const LoginFormContainer = styled.div`
  // Add your styles for LoginFormContainer here
`;

const InputWrapper = styled.div`
  margin-bottom: 15px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 9px;
  outline: none;
  background-color: #fff !important;
  font-size: 13px;
  border-radius: 0 4px 4px 0;
  border-left-width: 0 !important;
  border-color: #ececec !important;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: #fff;
`;

const Login = ({ onLogin }) => {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    tenant: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [showOTP, setShowOTP] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [domainError, setDomainError] = useState("");
  const [reCaptchaValue, setReCaptchaValue] = useState(null);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const navigate = useNavigate();

  const validate = () => {
    const newErrors = {};
    if (!formData.username.trim()) newErrors.username = "Username is required.";
    if (!formData.password) newErrors.password = "Password is required.";
    if (!formData.tenant.trim()) newErrors.tenant = "Tenant is required.";
    setErrors(newErrors);
    return newErrors;
  };

  let APIProvider = window.APIProvider;

  const secretKey = APIProvider.secretKey.secretKey;

  useEffect(() => {
    if (window.location.pathname === "/") {
      sessionStorage.clear();
    }
  }, []);

  // const encryptData = (data) => {
  //   return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
  // };

  const encryptData = (data) => {
    const encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      secretKey
    ).toString();
    return encryptedData;
  };

  const [IPDetails, setIPDetails] = useState({});
  
  useEffect(() => {
    axios
      .get('https://freeipapi.com/api/json') 
      .then((response) => {
        const data = response.data;
        const loginTime = new Date().toUTCString();
        const updatedDetails = {
          Country: data.countryName || "",
          Region: data.regionName || "",
          City: data.cityName || "",
          LoginTime: loginTime || "",
          IPAddress: data.ipAddress || "",
          Latitude: data.latitude || "",
          Longitude: data.longitude || "",
        };
        setIPDetails(updatedDetails);
      
      })
      .catch((error) => {
        console.error("Error fetching IP details:", error);
      });
  }, []);
  
// console.log("IPDetails",JSON.stringify(IPDetails))
// const payloaddata = {
//   userName: formData.username,
//   password: formData.password,
//   tenant: formData.tenant,
//   iPDetails:JSON.stringify(IPDetails)
// };
// console.log("IPDetails",payloaddata)

  const handleLogin = async () => {
    const newErrors = validate();
    if (Object.keys(newErrors).length > 0) {
      return;
    }

    const payload = {
      userName: formData.username,
      password: formData.password,
      tenant: formData.tenant,
      ipDetails:JSON.stringify(IPDetails),
    };

    try {
      const response = await fetch(
        `${APIProvider.baseURL}${APIProvider.MethodName.Login}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const data = await response.json();

        const encryptedData = encryptData(data);

        sessionStorage.setItem("loginData", encryptedData);

        // onLogin(JSON.stringify(data));
        if (data.errorMessage === "" && data.showOTP === true) {
          setShowOTP(data);
        } else if (data.errorMessage === "" && data.showOTP === false) {
          setShowOTP(data);

          toast.success(`Login succeed to ${formData.username}`);
          setTimeout(() => {
            navigate("/Landing");
          }, 1000);
        } else {
          toast.error("please enter valid credentials");
        }
      } else {
        const errorData = await response.json();
        console.error("Login failed:", errorData);
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({ ...prev, [id]: value }));
    if (errors[id]) {
      setErrors((prev) => ({ ...prev, [id]: "" }));
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    Object.keys(errors).forEach((key) => {
      if (formData[key] && errors[key]) {
        setErrors((prev) => ({ ...prev, [key]: "" }));
      }
    });
  }, [formData]);

  const handleOTPSubmit = async (otpValue) => {
    const payload = {
      userName: formData.username,
      tenant: formData.tenant,
      otp: otpValue,
    };

    try {
      const response = await fetch(
        `${APIProvider.baseURL}${APIProvider.MethodName.ValidateOTP}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const data = await response.json();
        // setOtpSuccess(data);

        if (data.isSuccess && data.isValid) {
          navigate("/Landing");
        }
      } else {
        const errorData = await response.json();
        toast.error(`Please Enter Valid OTP`);
        console.error("Please Enter Valid OTP");
      }
    } catch (error) {
      toast.error("Error occurred during OTP validation. Please try again.");
      console.error("Error during OTP validation:", error);
    }
  };
  const handleInputChangeotp = (e) => {
    const otpValue = e.target.value;
    setOtp(otpValue);

    if (otpValue.length === 6) {
      setOtpError(""); // Clear error if valid
      handleOTPSubmit(otpValue);
    } else {
      // setOtpError("Please enter a valid 6-digit OTP."); // Set error if not valid
    }
  };

  const handleTenantBlur = async () => {
    const newErrors = validate();
    if (Object.keys(newErrors).length > 0) {
      return;
    }
    const payload = {
      username: formData.username,
      password: formData.password,
      tenant: formData.tenant,
    };

    try {
      const response = await fetch(
        `${APIProvider.baseURL}${APIProvider.MethodName.GetCaptcah}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const data = await response.json();
        setReCaptchaValue(data);
        // setShowReCaptcha(true);
        setDomainError("");
        if (data.isRequired === false || isCaptchaVerified) {
          handleLogin(); // Call login directly
        }
      } else {
        console.error("Get captcha failed:", response.statusText);
        toast.error("Please enter valid domain");
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const handleprocessafterCaptcha = async () => {
    const newErrors = validate();
    if (Object.keys(newErrors).length > 0) {
      return;
    }

    const payload = {
      userName: formData.username,
      password: formData.password,
      tenant: formData.tenant,
      ipDetails:JSON.stringify(IPDetails),
    };

    try {
      const response = await fetch(
        `${APIProvider.baseURL}${APIProvider.MethodName.Login}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const data = await response.json();

        const encryptedData = encryptData(data);

        sessionStorage.setItem("loginData", encryptedData);

        // onLogin(JSON.stringify(data));
        if (data.errorMessage === "" && data.showOTP === true) {
          setShowOTP(data);
        } else {
          console.log("");
        }
      } else {
        const errorData = await response.json();
        console.error("Login failed:", errorData);
      }
    } catch (error) {
      console.error("Error during login:", error);
    }
  };

  const handleCaptchaChange = (value) => {
    if (value) {
      setIsCaptchaVerified(true);
     handleprocessafterCaptcha();
     handleLogin();
    } else {
      setIsCaptchaVerified(false);
    }
  };

  const handleRecaptchaExpired = () => {
    toast.error("reCAPTCHA has expired. Please verify again.");
    setIsCaptchaVerified(false);
  };

  return (
    <ThemeProvider theme={themeProvider}>
      <ContainerLoginPageBg>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div className="containe-top-left"></div>
        <div className="containe-top-right">
          <LoginFormContainer className="cont-bg-login-form">
            <div className="login-form-name">
              <div className="login-head">
                <img src="https://aiworks.agiline.com/Images/agiline-logo-login.png"></img>
              </div>
              <InputWrapper>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FontAwesomeIcon icon={faUser} className="icon-user" />
                  <StyledInput
                    type="text"
                    id="username"
                    value={formData.username}
                    onChange={handleInputChange}
                  />
                </div>
                {errors.username && (
                  <ErrorMessage>{errors.username}</ErrorMessage>
                )}
              </InputWrapper>

              <InputWrapper>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    backgroundColor: "#fff",
                    borderRadius: "4px",
                  }}
                  className="input-login-bg"
                >
                  <FontAwesomeIcon icon={faLock} className="icon-user" />
                  <InputContainer className="input-wrapper">
                    <StyledInput
                      type={showPassword ? "text" : "password"}
                      id="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      className="login-pass"
                    />
                    <span
                      className="them-eye-password"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? Theme.preview : Theme.eyeOff}
                    </span>
                  </InputContainer>
                </div>
                {errors.password && (
                  <ErrorMessage>{errors.password}</ErrorMessage>
                )}
              </InputWrapper>

            <InputWrapper>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FontAwesomeIcon icon={faGlobe} className="icon-user" />
                <StyledInput
                  type="text"
                  id="tenant"
                  value={formData.tenant}
                  onChange={handleInputChange}
                  // onBlur={handleTenantBlur}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleTenantBlur();
                    }
                  }}
                />
              </div>
              {errors.tenant && <ErrorMessage>{errors.tenant}</ErrorMessage>}
              {domainError && <ErrorMessage>{domainError}</ErrorMessage>}
            </InputWrapper>
            {reCaptchaValue?.isRequired === true && (
              <div className="login-recaptch">
                <ReCAPTCHA
                  sitekey={reCaptchaValue?.key}
                  onChange={handleCaptchaChange}
                  onExpired={handleRecaptchaExpired}
                />
              </div>
            )}

              {showOTP.showOTP === true && (
                <>
                  <InputWrapper>
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      className="input-login-bg"
                    >
                      <FontAwesomeIcon icon={faLock} className="icon-user" />
                      <StyledInput
                        id="otp"
                        placeholder="Enter 6-digit OTP"
                        value={otp}
                        onChange={handleInputChangeotp}
                        maxLength={6}
                      />
                    </div>
                    {otpError && <ErrorMessage>{otpError}</ErrorMessage>}
                  </InputWrapper>
                </>
              )}

              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleTenantBlur}
                style={{
                  marginTop: "10px",
                }}
              >
                Login
              </Button>
            </div>
          </LoginFormContainer>
        </div>
      </ContainerLoginPageBg>
    </ThemeProvider>
  );
};

export default Login;
