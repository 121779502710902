import React, { useRef, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { ToastContainer } from "react-toastify";
import {
  Box,
  CircularProgress,
  DialogContent,
  DialogContentText,
  TextField,
  Typography,
} from "@mui/material";
import { Theme, Image } from "../IncludeFile/Theme";
import Tooltip from "@mui/material/Tooltip";
import { TiExport } from "react-icons/ti";
import { FaRegEye } from "react-icons/fa";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialogActions from "@mui/material/DialogActions";
import "./RecursiveTreeView.css";
import CustomPopUpForm from "../../layout/CustomPopUpForm";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaAngleDown } from "react-icons/fa6";
import { FiMenu } from "react-icons/fi";
import { getCurrentDateTime, loadLoginData } from "../../apiCalls/DiagramsAPI";
import ErrorBanner from "../../Error/ErrorBanner";

const FormTopSection = ({
  searchQuery,
  handleSearch,
  handleControls,
  handleMenuButtonClick,
  handleTabshwButtonClick,
  onSaveClick,
  handleSaveToFile,
  handleGoBack,
  openConfirmation,
  handleCloseConfirmation,
  handleConfirmGoBackWithSave,
  setControlsVisible,
  handleCloseLeftMenu,
  controlsVisible,

  onSaveClick1,
  tempNum,
  templateSource,
  dataSourceArID,
  category,
  htmlFileContent,
  treeData,
  setSelectedNode,
  selectedNode,
  setSelectedItem,
  setSelectedItemID,
  setEditedName,
  setSelectedControl,
  showTheme,
  onSaveClickTheme,
  authData,
  handleGoBackTheme,
  arId,
  preJSON,
  stylesFromLoc,
  ReadArrayAllData,
  error,
  setError,
}) => {
  const userName = authData?.userName || "";

  const [openSavePopUp, setOpenSavesPopup] = useState(false);
  const [arrayNameError, setArrayNameError] = useState("");
  const [formNameExists, setFormNameExists] = useState(false);
  const [loading, setLoading] = useState(false);
  const [prevArrayName, setPrevArrayName] = useState("");

  const [arrayNamevalue, setArrayNamevalue] = useState("");
  const [descriptionvalue, setDescriptionvalue] = useState("");

  const navigate = useNavigate();

  const toggleControlsVisibility = () => {
    if (treeData.length > 0 && !selectedNode) {
      const firstItem = selectedNode.id;
      setSelectedNode(firstItem);
      setSelectedItem(firstItem);
      setSelectedItemID(firstItem.id);
    }
    setControlsVisible(!controlsVisible);
  };

  const nodeListfocused = document.querySelectorAll(".focusingClass")[0];

  const handlePreViewBack = () => {
    // const firstItem = treeData[0].Source[0];
    const firstItem = selectedNode.id || "";
    setSelectedNode(firstItem);
    setSelectedItem(firstItem);
    setSelectedItemID(firstItem.id);
    setSelectedControl(firstItem.type);
    const firstItem1 = treeData[0].Source[0];
    setSelectedNode(firstItem1);
    setSelectedItem(firstItem1);
    setSelectedControl(firstItem1);
    setEditedName(firstItem1.name);
    setSelectedItemID(firstItem1.id);
    setControlsVisible(true);
    if (nodeListfocused) {
      nodeListfocused.classList.remove("focusingClass");
    }
  };

  // const loginData = JSON.parse(sessionStorage.getItem("loginData"));

  const inputRef = useRef(null);
  const [arrayName, setArrayName] = useState("");
  const [description, setDescription] = useState("");

  let APIProvider = window.APIProvider;

  const dateTime = getCurrentDateTime();

  const handleSaveClick = async () => {
    if (!arrayName.trim()) {
      setArrayNameError("Form Name is required");
      return;
    } else {
      setArrayNameError("");
    }

    if (!authData || !authData.accessKey) {
      navigate("/");
      sessionStorage.clear();
      return;
    }

    const requestData = {
      accessKey: authData.accessKey,
      guid: APIProvider.GUID.WriteArrayGUID,
      param1: "",
      param2: "",
      param3: "",
      param4: "",
      recordSet: "",
      body: JSON.stringify({
        ArrayName: arrayName,
        Description: description,
        Category: category,
        Source: JSON.stringify(treeData[0].Source),
        IsTemplate: "1",
        htmlOutput: htmlFileContent,
        DataSourceArID: dataSourceArID,
        isInactive: 0,
        CreatedBy: userName,
        CreatedDate: dateTime,
        ModifiedBy: userName,
        ModifiedDate: dateTime,
      }),
    };

    try {
      // Show loading state
      setLoading(true);

      const response = await fetch(`${APIProvider.baseURL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      setTimeout(() => {
        navigate("/Open", {
          state: {
            defaultStyle: stylesFromLoc,
            ReadAllData: ReadArrayAllData,
          },
        });
      }, 2000);
    } catch (error) {
      console.error("There was an error with the POST request:", error);
      setError("An error occurred in POST request");
      localStorage.setItem("error", "An error occurred in POST request");
      localStorage.setItem("navigate", "/Open");
    } finally {
      setLoading(false);
    }
  };

  const handleSaveClick1 = async () => {
    if (!authData || !authData.accessKey) {
      navigate("/");
      sessionStorage.clear();
      navigate("/");
      sessionStorage.clear();
      return;
    }

    if (!arrayName.trim()) {
      setArrayNameError("Form Name is required");
      return;
    } else {
      setArrayNameError("");
    }

    const requestData = {
      accessKey: authData.accessKey,
      guid: APIProvider.GUID.WriteArrayGUID,
      param1: "",
      param2: "",
      param3: "",
      param4: "",
      recordSet: "",
      body: JSON.stringify({
        ArrayName: arrayName,
        Description: description,
        Category: category,
        Source: JSON.stringify(treeData[0].Source),
        IsTemplate: "0",
        htmlOutput: htmlFileContent,
        DataSourceArID: dataSourceArID,
        isInactive: 0,
        CreatedBy: userName,
        CreatedDate: dateTime,
        ModifiedBy: userName,
        ModifiedDate: dateTime,
      }),
    };

    try {
      setLoading(true);

      const response = await fetch(
        `${APIProvider.baseURL}${APIProvider.MethodName.PostQuickInfo}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      setTimeout(() => {
        navigate("/Open", {
          state: {
            defaultStyle: stylesFromLoc,
            ReadAllData: ReadArrayAllData,
          },
        });
      }, 2000);
    } catch (error) {
      console.error("There was an error with the POST request:", error);
      setError("An error occurred in POST request");
      localStorage.setItem("error", "An error occurred in POST request");
      localStorage.setItem("navigate", "/Open");
    } finally {
      setLoading(false);
    }
  };

  const [selectedValue, setSelectedValue] = useState("");

  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedValue(value);
    setOpenSavesPopup(value !== "");
  };

  const GetIsTemplate = authData
    ? {
        accessKey: authData.accessKey,
        guid: APIProvider.GUID.ReadArrayAllGUID,
        param1: "",
        param2: "",
        param3: "",
        param4: "",
        recordSet: "",
        body: "",
      }
    : null;

  if (!authData) {
    window.location.href = "/";
  }

  useEffect(() => {
    const checkFormNameExists = async () => {
      try {
        const fetchDataResponseArray = await fetch(
          `${APIProvider.baseURL}${APIProvider.MethodName.GetQuickInfo}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(GetIsTemplate),
          }
        );

        if (fetchDataResponseArray.ok) {
          const resultData = await fetchDataResponseArray.json();

          if (resultData.apiResponse) {
            const data = JSON.parse(resultData.apiResponse);
            const arrayNames = data.map((item) => item.ArrayName);

            if (
              arrayNames.some(
                (name) => name.toLowerCase() === arrayName.toLowerCase()
              )
            ) {
              toast.error(
                "Form name already exists. Please choose a different name."
              );
              setFormNameExists(true);
            } else {
              setFormNameExists(false);
            }
          } else {
            console.error("Error: apiResponse is missing");
          }
        } else {
          console.error("Failed to fetch array names");
        }
      } catch (error) {
        console.error("Error:", error.message);
        setError("An error occurred in UseEffect checkFormNameExists");
        localStorage.setItem(
          "error",
          "An error occurred in UseEffect checkFormNameExists"
        );
        localStorage.setItem("navigate", "/Open");
      }
    };

    if (arrayName.trim()) {
      checkFormNameExists();
    }
  }, [arrayName]);

  const [toggleDisplay, setToggleDisplay] = useState(false);

  const toggleDropdown = () => {
    setToggleDisplay(!toggleDisplay);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  // const handleClose = () => {
  //   setOpen(false);
  // };
  const [isSaved, setIsSaved] = useState(false);

  const handleClose = () => {
    if (!isSaved) {
      setArrayNamevalue(prevArrayName);
    }
    setIsSaved(false); 
    setOpen(false); 
  };

  const [isTemplate, setIsTemplate] = useState(null);
  const [isInactive, setIsInactive] = useState(null);

  useEffect(() => {
    if (preJSON && preJSON.length > 0) {
      const record = preJSON[0];
      setArrayNamevalue(record.arrayName || "");
      setPrevArrayName(record.arrayName || "");
      setDescriptionvalue(record.description || "");
      setIsTemplate(record.IsTemplate);
      setIsInactive(record.isInactive);
      setIsSaved(false); 
    } else {
      console.error("preJSON is invalid or empty");
    }
  }, [preJSON]);

  // const handleSave = async () => {
  //   try {
  //     const requestBody = {
  //       accessKey: authData.accessKey,
  //       guid: APIProvider.GUID.EditArrayGUID,
  //       param1: "",
  //       param2: "",
  //       param3: "",
  //       param4: "",
  //       recordSet: "",
  //       body: JSON.stringify({
  //         ArID: arId,
  //         ArrayName: arrayNamevalue,
  //         Description: descriptionvalue,
  //         Category: category,
  //         IsTemplate: isTemplate,
  //         DataSourceArID: dataSourceArID,
  //         isInactive: isInactive,
  //         ModifiedBy: userName,
  //         ModifiedDate: dateTime,
  //       }),
  //     };

  //     const response = await fetch(
  //       `${APIProvider.baseURL}${APIProvider.MethodName.PostQuickInfo}`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(requestBody),
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error("Failed to save data");
  //     }
  //     const responseData = await response.json();
  //     toast.success("Data saved successfully!");
  //     console.log("Save response:", responseData);
  //     handleClose();
  //   } catch (error) {
  //     console.error("Error editing item:", error);
  //     setError("An error occurred in handleSave");
  //     localStorage.setItem("error", "An error occurred in handleSave");
  //     localStorage.setItem("navigate", "/Open");
  //   }
  // };

  const handleSave = async () => {
    try {
      const requestBody = {
        accessKey: authData.accessKey,
        guid: APIProvider.GUID.EditArrayGUID,
        param1: "",
        param2: "",
        param3: "",
        param4: "",
        recordSet: "",
        body: JSON.stringify({
          ArID: arId,
          ArrayName: arrayNamevalue,
          Description: descriptionvalue,
          Category: category,
          IsTemplate: isTemplate,
          DataSourceArID: dataSourceArID,
          isInactive: isInactive,
          ModifiedBy: userName,
          ModifiedDate: dateTime,
        }),
      };

      const response = await fetch(
        `${APIProvider.baseURL}${APIProvider.MethodName.PostQuickInfo}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to save data");
      }
      const responseData = await response.json();
      toast.success("Data saved successfully!");
      setPrevArrayName(arrayNamevalue);
      setIsSaved(true);
      setOpen(false);
    } catch (error) {
      console.error("Error editing item:", error);
      setError("An error occurred in POST request");
      localStorage.setItem("error", "An error occurred in POST request");
      localStorage.setItem("navigate", "/Open");
    }
  };


  return (
    <>
      <div className="containerbtnsecction">
        <ToastContainer position="bottom-right" autoClose={1000} />
        {controlsVisible && (
          <div className="menu-srch-form-wrap">
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "30ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <div className="inputfield-wrap">
                <input
                  className="inputfield"
                  id="outlined-multiline-flexible"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearch}
                  ref={inputRef}
                />
                <div className="inputfield-srch-icon">
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      inputRef.current.focus();
                    }}
                  >
                    {Theme.Search}
                  </span>
                </div>
              </div>
            </Box>
          </div>
        )}
        <div className="add-save-expt">
          <div className="addsavebtn">
            <div className="add-sv-lft-btns">
              {controlsVisible && (
                <Button
                  sx={{ padding: "0px 10px", minWidth: "45px" }}
                  className="shwmenubtn"
                  variant="contained"
                  onClick={handleCloseLeftMenu}
                >
                  <Tooltip
                    id="mblShowMenuBtn"
                    arrow
                    title={
                      <span className="mblShowMenuBtn">
                        Show/Hide left panel
                      </span>
                    }
                  >
                    <div className="menu-icon">
                      <FiMenu />
                    </div>
                  </Tooltip>
                </Button>
              )}

              {controlsVisible && (
                <Tooltip
                  id="tooltipstyletop"
                  arrow
                  title={<span className="tooltipstyletop">Add Control</span>}
                >
                  <Button
                    variant="contained"
                    onClick={handleControls}
                    className="addandsave"
                  >
                    {Theme.Add}
                    <h6 className="addcontrollbutton">Add Control</h6>
                  </Button>
                </Tooltip>
              )}

              <div>
                <CustomPopUpForm
                  open={open}
                  onClose={handleClose}
                  title="Edit Information"
                >
                  <div>
                    <div className="title-container">
                      <div
                        className="input-container-title"
                        style={{ width: "100%" }}
                      >
                        <div className="name-of-contol">Form Name*:</div>
                        <div style={{ width: "100%" }}>
                          <TextField
                            size="small"
                            value={arrayNamevalue}
                            onChange={(e) => setArrayNamevalue(e.target.value)}
                            autoFocus
                            className="dynamic-input-text"
                          />
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="title-container">
                      <div
                        className="input-container-title"
                        style={{ width: "100%" }}
                      >
                        <div className="name-of-contol">Description</div>
                        <div style={{ width: "100%" }}>
                          <TextField
                            size="small"
                            value={descriptionvalue}
                            onChange={(e) =>
                              setDescriptionvalue(e.target.value)
                            }
                            autoFocus
                            className="dynamic-input-text"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="btn-edit-popup-frm-bldr">
                    <Button
                      sx={{
                        textTransform: "none",
                        fontFamily: "Arial",
                        "&:hover": {
                          boxShadow:
                            "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)",border: "1px solid #1565C0",
                        },
                        width: "49%",
                      }}
                      smallwidth
                      onClick={handleClose}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      smallwidth
                      sx={{ textTransform: "none", fontFamily: "Arial", width: "49%",border: "1px solid #1565C0", }}
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                  </div>
                </CustomPopUpForm>
                <div className="title-container">
                  <div>
                    {!arrayNamevalue ? (
                      <Typography className="custom-typogrpy">
                        Loading...
                      </Typography>
                    ) : (
                      <Typography 
  className="custom-typogrpy" 
  title={arrayNamevalue.charAt(0).toUpperCase() + arrayNamevalue.slice(1)}>
  {arrayNamevalue.charAt(0).toUpperCase() + arrayNamevalue.slice(1)}
</Typography>

                    )}
                  </div>
                  <div onClick={handleClickOpen} className="edit-popup-drc-ttl">
                    {Theme.Edit}
                  </div>
                </div>
              </div>

              {controlsVisible && (
                <Button
                  className="shwtabbtn"
                  variant="contained"
                  onClick={handleTabshwButtonClick}
                >
                  <Tooltip
                    id="mblShowSetngBtn"
                    arrow
                    title={
                      <span className="mblShowSetngBtn">
                        Show/Hide right panel
                      </span>
                    }
                  >
                    {Theme.TuneIcon}
                  </Tooltip>
                </Button>
              )}
            </div>
            {controlsVisible && showTheme && (
              <div className="save-export-btn">
                <>
                  <Tooltip
                    id="tooltipstyletop"
                    arrow
                    title={<span className="tooltipstyletop">Save</span>}
                  >
                    <div className="slect-sve-itm">
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={tempNum === 1 ? onSaveClick1 : onSaveClick}
                        className="addandsavebutton save-btn-top"
                      >
                        {Theme.Save}
                        <h6 className="addcontrollbutton">Save</h6>
                      </Button>
                      <span className="border-left-edit"></span>
                      <div className="dropdown-top custom-select-container">
                        <div className="custom-select-container">
                          <div
                            className="icon-for-edit-cntrl"
                            onClick={toggleDropdown}
                          >
                            <FaAngleDown />
                          </div>
                          {toggleDisplay && (
                            <div
                              className="dropdown-options"
                              onChange={handleChange}
                            >
                              <div
                                className="seclt-option"
                                onClick={() => {
                                  handleChange({ target: { value: "0" } });
                                  setOpenSavesPopup(true);
                                }}
                              >
                                Save As
                              </div>
                              <div
                                className="seclt-option"
                                onClick={() => {
                                  handleChange({ target: { value: "1" } });
                                  setOpenSavesPopup(true);
                                }}
                              >
                                Save as a Template
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </Tooltip>

                  <Tooltip
                    id="tooltipstyletop"
                    arrow
                    title={<span className="tooltipstyletop">Export</span>}
                  >
                    <Button
                      color="secondary"
                      variant="contained"
                      className="addandsavebutton"
                      onClick={() => {
                        handleSaveToFile();
                        // onSaveClick(false);
                      }}
                    >
                      <TiExport />
                      <h6 className="addcontrollbutton">Export </h6>
                    </Button>
                  </Tooltip>
                  <Tooltip
                    id="tooltipstyletop"
                    arrow
                    title={<span className="tooltipstyletop">Preview</span>}
                  >
                    <Button
                      color="secondary"
                      variant="contained"
                      className="addandsavebutton"
                      onClick={(event) => {
                        toggleControlsVisibility(event);
                      }}
                    >
                      <FaRegEye />
                      <h6 className="addcontrollbutton">Preview</h6>
                    </Button>
                  </Tooltip>
                </>
              </div>
            )}

            {controlsVisible && !showTheme && (
              <Tooltip
                id="tooltipstyletop"
                arrow
                title={<span className="tooltipstyletop">Save</span>}
              >
                <Button
                  color="secondary"
                  variant="contained"
                  className="addandsavebutton"
                  onClick={onSaveClickTheme}
                >
                  {Theme.Save}
                  <h6 className="addcontrollbutton">Save</h6>
                </Button>
              </Tooltip>
            )}
          </div>

          <div className="backbtn-rht" style={{ marginright: "auto" }}>
            {controlsVisible && !showTheme && (
              <Tooltip
                id="tooltipstyletop"
                arrow
                title={<span className="tooltipstyletop">Back</span>}
              >
                <Button variant="contained" onClick={handleGoBackTheme}>
                  {Theme.GoBack}
                  <h6 className="addcontrollbutton">Back</h6>
                </Button>
              </Tooltip>
            )}

            {controlsVisible && showTheme && (
              <Tooltip
                id="tooltipstyletop"
                arrow
                title={<span className="tooltipstyletop">Exit</span>}
              >
                <Button variant="contained" onClick={handleGoBack}>
                  {Theme.GoBack}
                  <h6 className="addcontrollbutton">Exit</h6>
                </Button>
              </Tooltip>
            )}

            <Dialog
              open={openConfirmation}
              onClose={handleCloseConfirmation}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div style={{ padding: "25px" }}>
                <div className="cusm-usve-btn">
                  <DialogTitle id="alert-dialog-title">
                    <div className="close-popup-frm">
                      <p className="con-frm-cls">Unsaved Changes</p>
                      <IconButton
                        onClick={handleCloseConfirmation}
                        color="inherit"
                        edge="end"
                        style={{ marginTop: "-9px" }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      <p className="con-txt-fr">
                        Are you sure you want to Leave this page?
                        <br /> Changes you made will not be saved.
                      </p>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={handleCloseConfirmation}
                      color="primary"
                      className="cancl-page-btn"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleConfirmGoBackWithSave}
                      color="primary"
                      autoFocus
                      className="leave-page-btn"
                    >
                      Leave Page
                    </Button>
                  </DialogActions>
                </div>
              </div>
            </Dialog>
            <CustomPopUpForm
              open={openSavePopUp}
              onClose={() => setOpenSavesPopup(false)}
              title="Save as New Template"
              height="60%"
            >
              <div className="card-info card-info-view-crd">
                <div className="card-info-inside card-info-inside-view">
                  <Typography style={{ fontWeight: "bold" }}>
                    Form Name*:
                  </Typography>
                  <TextField
                    required
                    type="text"
                    className="edit-popup"
                    name="Name"
                    // onChange={(e) => setArrayName(e.target.value)}
                    onChange={(e) => {
                      setArrayName(e.target.value);
                      setArrayNameError("");
                    }}
                    error={!!arrayNameError}
                    helperText={arrayNameError}
                  />
                </div>
                <div className="card-info-inside">
                  <Typography style={{ fontWeight: "bold" }}>
                    Description:
                  </Typography>
                  <TextField
                    type="text"
                    className="edit-popup"
                    name="Description"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
              <>
                <div style={{ width: "96%", display: "flex" }}>
                  <Button fullWidth onClick={() => setOpenSavesPopup(false)}>
                    Cancel
                  </Button>
                  {selectedValue === "0" && (
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={handleSaveClick1}
                      disabled={formNameExists}
                    >
                      {loading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  )}
                  {selectedValue !== "0" && (
                    <Button
                      variant="contained"
                      fullWidth
                      onClick={handleSaveClick}
                      disabled={formNameExists}
                    >
                      {loading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  )}
                </div>
              </>
            </CustomPopUpForm>
          </div>
        </div>
      </div>
      {error && <ErrorBanner />}
    </>
  );
};

export default FormTopSection;