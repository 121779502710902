import React, { useState } from "react";
import { Drawer, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TabsComponent from "../TabsComponent";
// import { getPropertyValue } from "../../../functions/DragnDrop";
import Conditions from "./conditions/Conditions";
import Trigger from "./triggerNodeComponents/Trigger";
import Wait from "./waitNodeComponents/Wait";
import ConditionalSplit from "./conditionalNodeComponents/ConditionalSplit";
import SendEmail from "./sendEmailComponents/SendEmail";
import ScheduleSelector from "../Crono.js/CronExpression";
import ParamsComponent from "./ParamsComponent";
import EditNodeIcon from "../../../assets/EditNodeIcon.png";
import ConnectionDrawerComponent from "../connectionDrawerComponents/ConnectionDrawerComponent";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import DrawerComponent from "../DrawerComponent";
import { useDeleteNode } from "../../../functions/NodeFunctions";
import VariablePoolForNode from "../connectionDrawerComponents/VariablePoolForNode";
import {
  getIncomers,
  getOutgoers,
  getConnectedEdges,
  MarkerType,
} from "reactflow";
import "../../../styles.css";

const NodeDrawerComponent = ({
  isOpen,
  onClose,
  tabValue,
  setTabValue,
  nodes,
  setNodes,
  selectedNode,
  setSelectedNode,
  edges,
  setEdges,
  clickedEdge,
  setClickedEdge,
  region,
  markerColor,
  setMarkerColor,
}) => {
  const isNodeSelected = !!selectedNode && !!selectedNode.data;
  const isEdgeSelected = !!clickedEdge;
  const onDeleteNode = useDeleteNode(
    setEdges,
    setNodes,
    nodes,
    edges,
    getIncomers,
    getOutgoers,
    getConnectedEdges,
    MarkerType
  );

  if (!isNodeSelected && !isEdgeSelected) {
    return null;
  }

  const tabs = isNodeSelected
    ? [
        { label: "Parameters", value: "3" },
        { label: "Variables", value: "1" },
      ]
    : isEdgeSelected
      ? [
          { label: "Connector", value: "4" },
          { label: "Styles", value: "2" },
        ]
      : [];

  const handleCancelNode = () => {
    setSelectedNode(null);
    setClickedEdge(null);
  };

  const handleDeleteNode = () => {
    onDeleteNode([selectedNode]);
    onClose();
  };
  //commented on 27-12-2024 Original node delete function is in NodeFunctions.js
  // const handledeleteNode = () => {
  //   const updatedNodes = nodes.filter((node) => node.id !== selectedNode.id);
  //   const updateddiagramDetails = diagramDetails.filter(
  //     (node) => node.id !== selectedNode.id
  //   );
  //   setNodes(updatedNodes);
  //   setDiagramDetails(updateddiagramDetails);
  //   setSelectedNode(null);
  // };

  const iconType = selectedNode?.data?.[1]?.caption?.IconType;
  const iconLink = selectedNode?.data?.[1]?.caption?.IconLink;
  const iconSize = selectedNode?.data?.[1]?.caption?.IconSize;
  const iconSizeMapping = {
    small: "24px",
    medium: "36px",
    large: "48px",
  };

  return (
    <div className="rht-panel-dwr">
      <div className="rht-panel-dwr-psn">
        <TabsComponent
          tabValue={tabValue}
          setTabValue={setTabValue}
          tabs={tabs}
        >
          {isNodeSelected && (
            <div key="3">
              {isNodeSelected && (
                <div className="add-dsrn-rht">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "15px",
                    }}
                  >
                    {iconType === "Url" && iconLink && (
                      <img
                        src={iconLink}
                        alt="Icon"
                        style={{
                          width: iconSizeMapping[iconSize],
                          marginRight: "0.5rem",
                        }}
                      />
                    )}

                    {iconType === "Bootstrap" && iconLink && (
                      <i
                        className={`fa fa-${iconLink}`}
                        aria-hidden="true"
                        style={{ marginRight: "0.5rem" }}
                      ></i>
                    )}

                    {(!iconType || !iconLink) && (
                      <img
                        src={EditNodeIcon}
                        alt="Icon"
                        style={{
                          width: "30px",
                          marginRight: "0.5rem",
                        }}
                      />
                    )}

                    {selectedNode.data[0].title}
                  </div>
                  <IconButton onClick={handleDeleteNode} className="dlt-node">
                    <DeleteForeverIcon />
                  </IconButton>
                </div>
              )}
              {selectedNode.data[0].diagToolID === "24" && <Trigger />}
              {selectedNode.data[0].diagToolID === "26" && <ConditionalSplit />}
              {selectedNode.data[0].diagToolID === "27" && <Wait />}
              {selectedNode.data[0].diagToolID === "28" && <SendEmail />}
              {selectedNode.data[0].diagToolID === "23" && <ScheduleSelector />}
              {region.toLowerCase() === "ai ui" && (
                <ParamsComponent
                  nodes={nodes}
                  setNodes={setNodes}
                  selectedNode={selectedNode}
                />
              )}
            </div>
          )}

          {isNodeSelected && (
            <div key="1">
              {isNodeSelected && (
                <div className="add-dsrn-rht">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "15px",
                    }}
                  >
                    {iconType === "Url" && iconLink && (
                      <img
                        src={iconLink}
                        alt="Icon"
                        style={{
                          width: iconSizeMapping[iconSize],
                          marginRight: "0.5rem",
                        }}
                      />
                    )}

                    {iconType === "Bootstrap" && iconLink && (
                      <i
                        className={`fa fa-${iconLink}`}
                        aria-hidden="true"
                        style={{ marginRight: "0.5rem" }}
                      ></i>
                    )}

                    {(!iconType || !iconLink) && (
                      <img
                        src={EditNodeIcon}
                        alt="Icon"
                        style={{
                          width: "30px",
                          marginRight: "0.5rem",
                        }}
                      />
                    )}

                    {selectedNode.data[0].title}
                  </div>
                  <IconButton onClick={handleDeleteNode} className="dlt-node">
                    <DeleteForeverIcon />
                  </IconButton>
                </div>
              )}
              <VariablePoolForNode selectedNode={selectedNode} />
            </div>
          )}

          {isEdgeSelected && (
            <div key="4">
              <ConnectionDrawerComponent
                selectedNodeId={clickedEdge.target}
                nodes={nodes}
                edges={edges}
              />
            </div>
          )}
          {isEdgeSelected && (
            <div key="2">
              <DrawerComponent
                edges={edges}
                setEdges={setEdges}
                clickedEdge={clickedEdge}
                setClickedEdge={setClickedEdge}
                markerColor={markerColor}
                setMarkerColor={setMarkerColor}
              />
            </div>
          )}
        </TabsComponent>
        <div className="rht-panel-cls-icon">
          <IconButton onClick={handleCancelNode}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default NodeDrawerComponent;
