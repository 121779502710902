// edgeFunctions.js

import { useCallback } from "react";

export const useFlipArrow = (setEdges, setClickedEdge) => {
  return useCallback(
    (edgeId) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          console.log("edge.id ", edge.id);
          console.log("edgeId ", edgeId);
          if (edge.id === edgeId) {
            // Create flipped edge first so we can use it in the diagramDetails update
            const flippedEdge = {
              ...edge,
              id: `${edge.target}-${edge.source}`,
              source: edge.target,
              sourceHandle: edge.targetHandle,
              target: edge.source,
              targetHandle: edge.sourceHandle,
            };
            console.log("flipped edge ", flippedEdge);
            setClickedEdge(flippedEdge);
            return flippedEdge;
          }
          console.log("final edge ", edge);
          return edge;
        })
      );
    },
    [setEdges]
  );
};

export const useChangeColor = (setEdges) => {
  return useCallback(
    (edgeId, newColor) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          return edge.id === edgeId
            ? {
                ...edge,
                style: { ...edge.style, stroke: newColor },
                // markerEnd: {
                //   ...edge.markerEnd,
                //   color: newColor,
                // },
              }
            : edge;
        })
      );
    },

    [setEdges]
  );
};

// export const useChangeMarkerEndColor = (setEdges) => {
//   return useCallback(
//     (edgeId, newColor) => {
//       setEdges((prevEdges) =>
//         prevEdges.map((edge) =>
//           edge.id === edgeId
//             ? {
//                 ...edge,
//                 markerEnd: {
//                   ...edge.markerEnd,
//                   color: newColor,
//                 },
//               }
//             : edge
//         )
//       );
//     },
//     [setEdges]
//   );
// };

export const useChangeMarkerStartColor = (setEdges) => {
  return useCallback(
    (edgeId, newColor) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          if (edge.id === edgeId) {
            if (typeof edge.markerStart === "object") {
              // If markerStart is an object, update its color property
              return {
                ...edge,
                markerStart: {
                  ...edge.markerStart,
                  color: newColor,
                },
              };
            } else if (typeof edge.markerStart === "string") {
              // If markerStart is a string, keep it as it is
              return edge;
            }
          }
          return edge; // For other edges, return them as they are
        })
      );
    },
    [setEdges]
  );
};
export const useChangeMarkerEndColor = (setEdges) => {
  return useCallback(
    (edgeId, newColor) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          if (edge.id === edgeId) {
            if (typeof edge.markerEnd === "object") {
              // If markerEnd is an object, update its color property
              return {
                ...edge,
                markerEnd: {
                  ...edge.markerEnd,
                  color: newColor,
                },
              };
            } else if (typeof edge.markerEnd === "string") {
              // If markerEnd is a string, keep it as it is
              return edge;
            }
          }
          return edge; // For other edges, return them as they are
        })
      );
    },
    [setEdges]
  );
};

export const useChangeMarkerEnd = (setEdges) => {
  return useCallback(
    (edgeId, newMarkerEnd) => {
      console.log("newMarkerEnd ", newMarkerEnd);
      setEdges((prevEdges) =>
        prevEdges.map((edge) =>
          edge.id === edgeId
            ? {
                ...edge,
                markerEnd: {
                  ...edge.markerEnd,
                  type: newMarkerEnd,
                  // width: 15,
                  // height: 15,
                },
              }
            : edge
        )
      );
    },
    [setEdges]
  );
};
// CustomMarkers.js
export default function CustomMarkers({ markerColor = "#1A192B" }) {
  // Create a color-specific marker ID by removing the '#' and using the hex value
  const markerId = `closed-circle-marker-${markerColor.replace("#", "")}`;

  return (
    <>
      <svg style={{ position: "absolute", top: "-9999px", left: "-9999px" }}>
        <defs>
          <marker
            id={markerId}
            viewBox="0 0 10 10"
            refX="5"
            refY="5"
            markerWidth="10"
            markerHeight="10"
          >
            <circle cx="5" cy="5" r="4" fill={markerColor} />
          </marker>
        </defs>
      </svg>
    </>
  );
}

// useChangeMarkerEndSVG.js
export const useChangeMarkerEndSVG = (setEdges, markerColor) => {
  return useCallback(
    (edgeId, newMarkerEnd) => {
      const markerId = `closed-circle-marker-${markerColor.replace("#", "")}`;

      setEdges((prevEdges) =>
        prevEdges.map((edge) =>
          edge.id === edgeId
            ? {
                ...edge,
                markerEnd: newMarkerEnd,
              }
            : edge
        )
      );
    },
    [setEdges, markerColor]
  );
};
// export const useChangeMarkerEndSVG = (setEdges, markerColor) => {
//   console.log("markerColor ", markerColor);
//   return useCallback(
//     (edgeId, newMarkerEnd) => {
//       setEdges((prevEdges) =>
//         prevEdges.map((edge) =>
//           edge.id === edgeId
//             ? {
//                 ...edge,
//                 markerEnd: {
//                   ...edge.markerEnd,
//                   type: newMarkerEnd,
//                   // width: 15,
//                   // height: 15,
//                 },
//               }
//             : edge
//         )
//       );
//     },
//     [setEdges]
//   );
// };
export const useChangeMarkerStart = (setEdges) => {
  return useCallback(
    (edgeId, newMarkerStart) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) =>
          edge.id === edgeId
            ? {
                ...edge,
                markerStart: {
                  ...edge.markerStart,
                  type: newMarkerStart,
                  // width: 15,
                  // height: 15,
                },
              }
            : edge
        )
      );
    },
    [setEdges]
  );
};
export const useChangeMarkerStartSVG = (setEdges) => {
  return useCallback(
    (edgeId, newMarkerStart) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) =>
          edge.id === edgeId
            ? {
                ...edge,
                markerStart: newMarkerStart,
              }
            : edge
        )
      );
    },
    [setEdges]
  );
};
export const useChangeLineStyle = (setEdges) => {
  return useCallback(
    (edgeId, lineStyle) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) =>
          edge.id === edgeId
            ? {
                ...edge,
                animated: lineStyle === "dashed" ? true : false,
              }
            : edge
        )
      );
    },
    [setEdges]
  );
};

export const useChangeThickness = (setEdges) => {
  return useCallback(
    (edgeId, newStrokeWidth) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          return edge.id === edgeId
            ? {
                ...edge,
                style: { ...edge.style, strokeWidth: newStrokeWidth },
              }
            : edge;
        })
      );
    },
    [setEdges]
  );
};
export const useChangeLabel = (setEdges) => {
  return useCallback(
    (edgeId, text) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          return edge.id === edgeId
            ? {
                ...edge,
                label: text,
              }
            : edge;
        })
      );
    },
    [setEdges]
  );
};

export const useChangeLabelFontStyle = (setEdges) => {
  return useCallback(
    (edgeId, newFontSize) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          console.log("edge.labelStyle ", edge.labelStyle);
          return edge.id === edgeId
            ? {
                ...edge,
                labelStyle: {
                  ...edge.labelStyle,
                  fontSize: newFontSize,
                },
              }
            : edge;
        })
      );
    },
    [setEdges]
  );
};
export const useChangeLabelColorStyle = (setEdges) => {
  return useCallback(
    (edgeId, newColor) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          console.log("edge.labelStyle ", edge.labelStyle);
          return edge.id === edgeId
            ? {
                ...edge,
                labelStyle: {
                  ...edge.labelStyle,
                  fill: newColor,
                },
              }
            : edge;
        })
      );
    },
    [setEdges]
  );
};
export const useChangeLabelBgStyle = (setEdges) => {
  return useCallback(
    (edgeId, newLabelBgStyle) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          return edge.id === edgeId
            ? {
                ...edge,
                labelBgStyle: { ...newLabelBgStyle },
              }
            : edge;
        })
      );
    },
    [setEdges]
  );
};
export const useChangeType = (setEdges) => {
  return useCallback(
    (edgeId, type) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) => {
          return edge.id === edgeId
            ? {
                ...edge,
                type: type,
              }
            : edge;
        })
      );
    },
    [setEdges]
  );
};

export const useDeleteEdge = (setEdges, setClickedEdge) => {
  return useCallback(
    (edgeId) => {
      setEdges((prevEdges) => prevEdges.filter((edge) => edge.id !== edgeId));
      setClickedEdge();
    },
    [setEdges]
  );
};

// export const useEdgeClick = (FlipArrow) => {
//   return useCallback(
//     (event, clickedEdge) => {
//       FlipArrow(clickedEdge.id);
//     },
//     [FlipArrow]
//   );
// };

// export const useEdgeClick = (setClickedEdge, toggleDrawer ,setTabValue ,tabValue) => {

//   return useCallback(
//     (event, clickedEdge) => {
//       setClickedEdge(clickedEdge);
//       toggleDrawer(1);
//       if (tabValue !== "1"){
//         setTabValue("2")
//       }
//       setTabValue("2")

//     },
//     [setClickedEdge]
//   );
// };

export const useEdgeClick = (setClickedEdge, toggleDrawer, setTabValue) => {
  return useCallback(
    (event, clickedEdge) => {
      setClickedEdge(clickedEdge);
      toggleDrawer(1);
      setTabValue();
    },
    [setClickedEdge, toggleDrawer, setTabValue]
  );
};
