import ELK from "elkjs/lib/elk.bundled.js";
import { useCallback } from "react";
import { useReactFlow } from "reactflow";

const elk = new ELK();

const DEFAULT_NODE_WIDTH = 150;
const DEFAULT_NODE_HEIGHT = 40;
const VERTICAL_SPACING = 200;
const HORIZONTAL_SPACING = 200;
const INITIAL_X = 100;
const INITIAL_Y = 100;

export const useCustomLayoutedElements = () => {
  const { getNodes, setNodes, getEdges, fitView } = useReactFlow();

  const customLayout = useCallback((nodes, edges) => {
    let currentX = INITIAL_X;
    let currentY = INITIAL_Y;
    let inLoop = false;
    const positionMap = new Map();

    // First pass: Position all nodes
    const layoutedNodes = nodes.map((node) => {
      const nodeData = node.data[0];
      console.log("nodeData ", nodeData);
      let nodePosition = { x: currentX, y: currentY };

      if (nodeData?.nodetypeid === 3) {
        // Start of loop
        inLoop = true;
        nodePosition = { x: currentX, y: currentY };
        currentY += VERTICAL_SPACING;
        currentX += HORIZONTAL_SPACING;
      } else if (nodeData?.nodetypeid === 6) {
        // End of loop
        inLoop = false;
        currentX -= HORIZONTAL_SPACING;
        nodePosition = { x: currentX, y: currentY };
        currentY += VERTICAL_SPACING;
      } else {
        if (inLoop) {
          nodePosition = { x: currentX, y: currentY };
          currentY += VERTICAL_SPACING;
        } else {
          // Find parent node
          const parentEdge = edges.find((edge) => edge.target === node.id);
          const parentNode = parentEdge
            ? nodes.find((n) => n.id === parentEdge.source)
            : null;

          if (parentNode && positionMap.has(parentNode.id)) {
            const parentPos = positionMap.get(parentNode.id);
            currentY = parentPos.y + VERTICAL_SPACING;
          }
          nodePosition = { x: currentX, y: currentY };
          currentY += VERTICAL_SPACING;
        }
      }

      // Store position for future parent lookups
      positionMap.set(node.id, nodePosition);

      return {
        ...node,
        position: nodePosition,
        width: DEFAULT_NODE_WIDTH,
        height: DEFAULT_NODE_HEIGHT,
      };
    });

    return layoutedNodes;
  });

  const getCustomLayoutedElements = useCallback(
    (options = {}) => {
      const nodes = getNodes();
      const edges = getEdges();

      // Apply custom layout
      const layoutedNodes = customLayout(nodes, edges);

      // Update node positions
      setNodes(layoutedNodes);

      // Fit view after layout is applied
      window.requestAnimationFrame(() => {
        fitView();
      });
    },
    [getNodes, getEdges, setNodes, fitView]
  );

  return { getCustomLayoutedElements };
};

export const useLayoutedElements = () => {
  const { getNodes, setNodes, getEdges, fitView } = useReactFlow();
  const defaultOptions = {
    "elk.algorithm": "layered",
    "elk.layered.spacing.nodeNodeBetweenLayers": 100,
    "elk.spacing.nodeNode": 80,
  };

  const getLayoutedElements = useCallback((options) => {
    const layoutOptions = { ...defaultOptions, ...options };
    const graph = {
      id: "root",
      layoutOptions: layoutOptions,
      children: getNodes(),
      edges: getEdges(),
    };

    elk.layout(graph).then(({ children }) => {
      // By mutating the children in-place we saves ourselves from creating a
      // needless copy of the nodes array.
      children.forEach((node) => {
        node.position = { x: node.x, y: node.y };
      });

      setNodes(children);
      window.requestAnimationFrame(() => {
        fitView();
      });
    });
  }, []);

  return { getLayoutedElements };
};
