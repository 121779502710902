import { useMemo } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Star, StarBorder } from "@mui/icons-material";
import {
  IconButton,
  Card,
  CardActionArea,
  Typography,
  Tooltip,
  Box,
} from "@mui/material";
import themeProvider from "../../../ThemeProvider";

export const NodePanel = ({
  title,
  items,
  collapsed,
  onToggle,
  onDragStart,
  toggleFavorite,
  favoriteNodes,
  groupedNodes,
  properties,
}) => {
  // Create a lookup map for quick access to nodes
  const nodeMap = useMemo(() => {
    const map = new Map();
    if (properties?.Nodes) {
      properties.Nodes.forEach((node) => {
        if (node?.id) map.set(node.id.toString(), node);
      });
    }
    return map;
  }, [properties?.Nodes]);

  // Function to retrieve properties for a given node
  const getPropertyValue = (node) => {
    const nodeId = node.tasktypeid?.toString();

    // Retrieve special properties using the lookup map
    const specialProperties = nodeMap.get(nodeId) || null;

    // Determine final properties
    const nodeProperties = specialProperties
      ? specialProperties.customstyle
      : properties[node?.category || "<all>"] || {};

    return nodeProperties;
  };

  let nodeNamecolor = "";

  if (title === "Favorites") {
    nodeNamecolor = "#333";
  } else if (properties[title] && properties[title]?.nodename) {
    nodeNamecolor = properties[title]?.nodename?.color;
  }

  let nodeBackgroundColor = "";

  if (title) {
    if (title === "Favorites") {
      nodeBackgroundColor = "#ccd2f3";
    } else {
      const fileNodes = groupedNodes[title];
      fileNodes.forEach((node) => {
        // const nodeProperties = getPropertyValue(node);
        // nodeBackgroundColor = nodeProperties?.node?.backgroundColor;
        const nodeProperties = properties[node?.category || "<all>"];
        nodeBackgroundColor = nodeProperties?.node?.backgroundColor;
      });
    }
  }

  return (
    <div>
      <div
        className="node-left-panel"
        style={{
          display: "flex",
          flexDirection: "row",
          // fontSize: "medium",
          fontSize: "small",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "0.2rem",
          cursor: "pointer",
          backgroundColor: nodeBackgroundColor,
        }}
        onClick={onToggle}
      >
        <div style={{ color: nodeNamecolor }}>{title}</div>
        <div>
          <IconButton>
            {collapsed ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
          </IconButton>
        </div>
      </div>
      {!collapsed && (
        <div className="node-dnd">
          {items.map((node, index) => {
            // Get all properties for the current node once
            const nodeProperties = getPropertyValue(node);

            // Extract specific properties as needed
            const borderColor =
              nodeProperties?.node?.borderColor === "transparent"
                ? nodeProperties?.node?.backgroundColor
                : nodeProperties?.node?.color;

            const isFavorite = favoriteNodes.some(
              (favNode) => favNode.tasktypeid === node.tasktypeid
            );

            const iconType = nodeProperties?.caption?.IconType;
            const iconLink = nodeProperties?.caption?.IconLink;

            return (
              <Card
                key={index}
                className="dndnode node-dnd-drpdn"
                sx={{ display: "flex" }}
              >
                <Box
                  sx={{
                    width: "9%", // 1/12 of the card width
                    backgroundColor: borderColor,
                    flexShrink: 0,
                  }}
                />

                <CardActionArea
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    // p: 1,
                    width: "92%",
                    padding: "4px",
                  }} // Remaining 11/12 width
                  draggable
                  onDragStart={(event) => onDragStart(event, node)}
                >
                  {iconType === "Url" && iconLink !== "" && (
                    <img
                      src={iconLink}
                      alt="icon"
                      width={12.5}
                      height={12.5}
                      style={{ marginRight: "0.5rem" }}
                    />
                  )}
                  <Typography variant="body2" sx={{ ml: 1 }}>
                    {node?.title ? node?.title : node?.execname}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <Tooltip
                    title={
                      isFavorite ? "Remove from favorites" : "Add to favorites"
                    }
                  >
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleFavorite(node?.tasktypeid);
                      }}
                    >
                      {isFavorite ? (
                        <Star
                          fontSize="small"
                          sx={{ color: themeProvider.palette.fav.main }}
                        />
                      ) : (
                        <StarBorder fontSize="small" />
                      )}
                    </IconButton>
                  </Tooltip>
                </CardActionArea>
              </Card>
            );
          })}
        </div>
      )}
    </div>
  );
};
