import React, { useEffect, useState } from "react";
import IconBox from "./IconBox";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import LineStyleDropdown from "./edgeProperties/LineStyleDropDown";
import {
  useFlipArrow,
  useDeleteEdge,
  useChangeColor,
  useChangeMarkerStartColor,
  useChangeMarkerEndColor,
  useChangeLineStyle,
  useChangeThickness,
  useChangeType,
  useChangeLabel,
  useChangeMarkerEnd,
  useChangeMarkerEndSVG,
  useChangeMarkerStart,
  useChangeMarkerStartSVG,
  useChangeLabelFontStyle,
  useChangeLabelBgStyle,
  useChangeLabelColorStyle,
} from "../../functions/EdgeFunctions";
import TabsComponent from "./TabsComponent";
import EdgeTypeDropdown from "./edgeProperties/EdgeTypeDropdown";
import MarkerEndDropdown from "./edgeProperties/MarkerDropDown";

const DrawerComponent = ({ edges, setEdges, clickedEdge, setClickedEdge }) => {
  console.log("clickedEdge ", clickedEdge);
  const [formData, setFormData] = useState({
    flipEdge: 0,
    deleteEdge: 0,
    color: clickedEdge?.style?.stroke || "#d3d3d3",
    markerStartColor: clickedEdge?.markerStart?.color || "#d3d3d3",
    markerEndColor: clickedEdge?.markerEnd?.color || "#d3d3d3",
    lineStyle: clickedEdge?.animated ? "dashed" : "solid",
    thickness: clickedEdge?.style?.strokeWidth || 3,
    markerEnd:
      typeof clickedEdge?.markerEnd === "object"
        ? clickedEdge?.markerEnd.type
        : typeof clickedEdge?.markerEnd === "string"
          ? clickedEdge?.markerEnd
          : "none",
    markerStart:
      typeof clickedEdge?.markerStart === "object"
        ? clickedEdge?.markerStart.type
        : typeof clickedEdge?.markerStart === "string"
          ? clickedEdge?.markerStart
          : "none",
    type: clickedEdge?.type || "floating",
    label: clickedEdge?.label || "",
  });

  const [labelStyle, setLabelStyle] = useState({
    labelFontSize: clickedEdge.labelStyle?.fontSize || 15,
    labelBackgroundColor:
      clickedEdge.labelBgStyle?.fill || "rgba(255, 255, 255, 0)",
    labelColor: clickedEdge.labelStyle?.fill || "rgba(0, 0, 0, 0)",
  });

  // useEffect(() => {
  //   if (clickedEdge) {
  //     setFormData({
  //       flipEdge: 0,
  //       deleteEdge: 0,
  //       color: clickedEdge?.style?.stroke || "#d3d3d3",
  //       markerEndColor: clickedEdge?.markerEnd?.color || "#d3d3d3",
  //       lineStyle: clickedEdge?.animated ? "dashed" : "solid",
  //       thickness: clickedEdge?.style?.strokeWidth || 3,
  //       markerEnd:
  //         typeof clickedEdge?.markerEnd === "object"
  //           ? clickedEdge?.markerEnd.type
  //           : typeof clickedEdge?.markerEnd === "string"
  //             ? clickedEdge?.markerEnd
  //             : "none",
  //       markerStart:
  //         typeof clickedEdge?.markerStart === "object"
  //           ? clickedEdge?.markerStart.type
  //           : typeof clickedEdge?.markerStart === "string"
  //             ? clickedEdge?.markerStart
  //             : "none",
  //       type: clickedEdge?.type || "floating",
  //       label: clickedEdge?.label || "",
  //     });

  //     setLabelStyle({
  //       labelFontSize: clickedEdge?.labelStyle?.fontSize || 15,
  //       labelBackgroundColor:
  //         clickedEdge?.labelBgStyle?.fill || "rgba(255, 255, 255, 0)",
  //       labelColor: clickedEdge?.labelStyle?.fill || "rgba(0, 0, 0, 0)",
  //     });
  //   }
  // }, [clickedEdge]);

  const handleFlipArrow = useFlipArrow(setEdges, setClickedEdge);

  const handleDeleteEdge = useDeleteEdge(setEdges, setClickedEdge);
  const handleChangeColor = useChangeColor(setEdges);
  const handleChangeMarkerStartColor = useChangeMarkerStartColor(setEdges);
  const handleChangeMarkerEndColor = useChangeMarkerEndColor(setEdges);
  const handleChangeLineStyle = useChangeLineStyle(setEdges);
  const handleChangeThickness = useChangeThickness(setEdges);
  const handleChangeType = useChangeType(setEdges);
  const handleChangeLabel = useChangeLabel(setEdges);
  const handleChangeMarkerEnd = useChangeMarkerEnd(setEdges);
  const handleChangeMarkerEndSVG = useChangeMarkerEndSVG(
    setEdges,
    formData.markerEndColor
  );
  const handleChangeMarkerStart = useChangeMarkerStart(setEdges);
  const handleChangeMarkerStartSVG = useChangeMarkerStartSVG(setEdges);
  const handleChangeLabelFontStyle = useChangeLabelFontStyle(setEdges);
  const handleChangeLabelBgStyle = useChangeLabelBgStyle(setEdges);
  const handleChangeLabelColorStyle = useChangeLabelColorStyle(setEdges);

  const handleChange = (name, value) => {
    console.log("name ", name);
    console.log("value ", value);
    setFormData({
      ...formData,
      [name]: value,
      // ...(name === "color" && { markerEndColor: value }),
    });
    if (name === "labelFontSize") {
      setLabelStyle({
        ...labelStyle,
        labelFontSize: value,
      });
    }
    if (name === "labelBackgroundColor") {
      setLabelStyle({
        ...labelStyle,
        labelBackgroundColor: value,
      });
    }
    if (name === "labelColor") {
      setLabelStyle({
        ...labelStyle,
        labelColor: value,
      });
    }
    if (name === "flipEdge") {
      handleFlipArrow(clickedEdge.id);
    } else if (name === "deleteEdge") {
      handleDeleteEdge(clickedEdge.id);
    } else {
      switch (name) {
        case "color":
          handleChangeColor(clickedEdge.id, value);
          // handleChangeMarkerEndColor(clickedEdge.id, value);
          break;
        case "markerStartColor":
          handleChangeMarkerStartColor(clickedEdge.id, value);
          break;
        case "markerEndColor":
          handleChangeMarkerEndColor(clickedEdge.id, value);
          break;
        case "lineStyle":
          handleChangeLineStyle(clickedEdge.id, value);
          break;
        case "thickness":
          handleChangeThickness(clickedEdge.id, value);
          break;
        case "type":
          handleChangeType(clickedEdge.id, value);
          break;
        case "label":
          handleChangeLabel(clickedEdge.id, value);
          break;
        case "labelFontSize":
          handleChangeLabelFontStyle(clickedEdge.id, value);
          break;
        case "labelBackgroundColor":
          handleChangeLabelBgStyle(clickedEdge.id, {
            fill: value,
            fillOpacity: 0.7,
          });
          break;
        case "labelColor":
          handleChangeLabelColorStyle(clickedEdge.id, value);
          break;
        case "markerEnd":
          if (value === "arrowclosed" || value === "arrow") {
            handleChangeMarkerEnd(clickedEdge.id, value);
          } else {
            handleChangeMarkerEndSVG(clickedEdge.id, value);
          }
          break;
        case "markerStart":
          if (value === "arrowclosed" || value === "arrow") {
            handleChangeMarkerStart(clickedEdge.id, value);
          } else {
            handleChangeMarkerStartSVG(clickedEdge.id, value);
          }
          break;
        default:
          break;
      }
    }
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="rht-info edge-line-text">Edit Edge</div>
      </div>

      <form key="1">
        <div className="drowerseparater drop-bttom">
          <div class="inpt-fild-test">
            <IconBox
              handleChange={handleChange}
              buttonList={[
                {
                  icon: <FlipCameraAndroidIcon />,
                  name: "flipEdge",
                  value: formData.flipEdge,
                },
                {
                  icon: <DeleteForeverIcon className="edge-delet-icon" />,
                  name: "deleteEdge",
                  value: formData.deleteEdge,
                },
              ]}
            />
          </div>
        </div>
        <div className="drowerseparater drop-bttom">
          <div class="inpt-fild-test">
            <div className="color-picker-container">
              <div className="color-picker color-picker-style">
                <label htmlFor="color" className="inpt-fild-test">
                  Color:
                </label>
                <input
                  type="color"
                  id="color"
                  name="color"
                  style={{ border: "1px solid #333" }}
                  value={formData.color}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>
              <div className="color-picker color-picker-style">
                <label htmlFor="markerStartColor" className="inpt-fild-test">
                  Marker Start Color:
                </label>
                <input
                  type="color"
                  id="markerStartColor"
                  name="markerStartColor"
                  style={{ border: "1px solid #333" }}
                  value={formData.markerStartColor}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>

              <div className="color-picker color-picker-style">
                <label htmlFor="markerEndColor" className="inpt-fild-test">
                  Marker End Color:
                </label>
                <input
                  type="color"
                  id="markerEndColor"
                  name="markerEndColor"
                  style={{ border: "1px solid #333" }}
                  value={formData.markerEndColor}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>
              <div className="color-picker color-picker-style">
                <label htmlFor="labelColor" className="inpt-fild-test">
                  Label Color:
                </label>
                <input
                  type="color"
                  id="labelColor"
                  name="labelColor"
                  style={{ border: "1px solid #333" }}
                  value={labelStyle.labelColor}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>
              <div className="color-picker color-picker-style">
                <label
                  htmlFor="labelBackgroundColor"
                  className="inpt-fild-test"
                >
                  Label Background Color:
                </label>
                <input
                  type="color"
                  id="labelBackgroundColor"
                  name="labelBackgroundColor"
                  style={{ border: "1px solid #333" }}
                  value={labelStyle.labelBackgroundColor}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div class="drowerseparater drop-bttom">
          <div className="style-rht-markr">
            <LineStyleDropdown
              formData={formData}
              onChange={(style) => handleChange("lineStyle", style)}
            />

            <div className="marker-line-text">
              <div className="marker-text-container">
                <div className="rht-info">Thickness</div>
              </div>
              <div className="dropdown-container">
                <input
                  className="rht-select-field-flow rht-input-connector"
                  InputProps={{ style: { height: "30px" } }}
                  size="small"
                  type="number"
                  name="thickness"
                  value={formData.thickness}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  variant="outlined"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="drowerseparater drop-bttom">
          <div className="style-rht-markr">
            <MarkerEndDropdown
              formData={formData}
              isEnd={false}
              onChange={(style) => handleChange("markerStart", style)}
            />
            <div>
              <MarkerEndDropdown
                formData={formData}
                isEnd={true}
                onChange={(style) => handleChange("markerEnd", style)}
              />
            </div>
          </div>
        </div>
        <div class="drowerseparater drop-bttom">
          <div className="style-rht-markr">
            <EdgeTypeDropdown
              formData={formData}
              onChange={(style) => handleChange("type", style)}
            />

            <div className="marker-line-text">
              <div className="marker-text-container">
                <div className="rht-info"> Label Size</div>
              </div>
              <div className="dropdown-container">
                <input
                  className="rht-select-field-flow rht-input-connector"
                  InputProps={{ style: { height: "30px" } }}
                  size="small"
                  type="number"
                  name="labelFontSize"
                  value={labelStyle.labelFontSize}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  variant="outlined"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="drowerseparater drop-bttom">
          <div className="style-rht-markr">
            <div className="marker-line-text">
              <div className="marker-text-container">
                <div className="rht-info">Label</div>
              </div>
              <div className="dropdown-container">
                <input
                  className="rht-select-field-flow rht-input-connector"
                  InputProps={{ style: { height: "30px" } }}
                  size="small"
                  name="label"
                  value={formData.label}
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  variant="outlined"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DrawerComponent;
