import React, { useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { Theme } from "../IncludeFile/Theme";
import { fontFamilies } from "../IncludeFile/Theme";
import { RxStretchHorizontally } from "react-icons/rx";
import { BsAlignCenter } from "react-icons/bs";
import {
  PiAlignRightSimpleBold,
  PiAlignLeftSimpleDuotone,
} from "react-icons/pi";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { ChromePicker } from "react-color";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Tooltip from "@mui/material/Tooltip";
import PropertiesAccordion from "../../reusableComponents/PropertiesAccordion";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";

import { MdExpandMore, MdChevronRight } from "react-icons/md";

const CustomHtmlTag = ({
  htmlTag,
  styleid,
  children,
  isSelected,
  styles,
  onContextMenu,
  mediaURl,
  controltype,
  customStyles,
  updateCustomStyle,
  data,
  handleSelectItem,
  onClickforFocus,
  styleType,
  check,
  inputText,
  imageText,
  switchcheck,
  parentid,
  keyss,
  media,
  handleSelectChange,
  name,
  rightsideStyletab,
  handleDateChange,
  checkposition,
  controlsVisible,

  setCustomStyles,
  selectedItem,
  defaultStyle,
  treeData,
  setTreeData,
  selectedItemID,
  onMouseOver,
  onMouseOut,
  showTheme,
  tableProp,
  sourceData,
}) => {
  const [isClient, setIsClient] = useState(false);

  function isPredefinedFontSize(fontSize) {
    const predefinedSizes = ["35px", "24px", "18px", "16px", "14px", "12px"];
    return predefinedSizes.includes(fontSize);
  }

  useEffect(() => {
    if (!isClient) {
      setIsClient(true);
    }
  }, [isClient]);

  const customStyle = customStyles[styleid] || {};
  const finalStyles = { ...JSON.parse(styles), ...customStyle };

  const [borderWidth, setBorderWidth] = useState(
    customStyle.borderWidth || finalStyles.borderWidth || ""
  );
  const [borderRadius, setBorderRadius] = useState(
    customStyle.borderRadius || finalStyles.borderRadius || ""
  );

  const [marginTop, setMarginTop] = useState(
    customStyle.marginTop || finalStyles.marginTop || ""
  );
  const [marginRight, setMarginRight] = useState(
    customStyle.marginRight || finalStyles.marginRight || ""
  );
  const [marginBottom, setMarginBottom] = useState(
    customStyle.marginBottom || finalStyles.marginBottom || ""
  );
  const [marginLeft, setMarginLeft] = useState(
    customStyle.marginLeft || finalStyles.marginLeft || ""
  );
  const [paddingTop, setPaddingTop] = useState(
    customStyle.paddingTop || finalStyles.paddingTop || ""
  );
  const [paddingRight, setPaddingRight] = useState(
    customStyle.paddingRight || finalStyles.paddingRight || ""
  );
  const [paddingBottom, setPaddingBottom] = useState(
    customStyle.paddingBottom || finalStyles.paddingBottom || ""
  );
  const [paddingLeft, setPaddingLeft] = useState(
    customStyle.paddingLeft || finalStyles.paddingLeft || ""
  );
  const [width, setWidth] = useState(
    customStyle.width || finalStyles.width || ""
  );
  const [height, setHeight] = useState(
    customStyle.height || finalStyles.height || ""
  );

  const [minWidth, setMinWidth] = useState(
    customStyle.minWidth || finalStyles.minWidth || ""
  );
  const [minHeight, setMinHeight] = useState(
    customStyle.minHeight || finalStyles.minHeight || ""
  );

  const [maxWidth, setMaxWidth] = useState(
    customStyle.maxWidth || finalStyles.maxWidth || ""
  );
  const [maxHeight, setMaxHeight] = useState(
    customStyle.maxHeight || finalStyles.maxHeight || ""
  );

  const [borderSpacing, setBorderSpacing] = useState(
    customStyle.borderSpacing || finalStyles.borderSpacing || ""
  );

  const [fontSize, setFontSize] = useState(
    customStyle.fontSize || finalStyles.fontSize || ""
  );

  const [backgroundColor, setBackgroundColor] = useState(
    customStyle.backgroundColor || finalStyles.backgroundColor || ""
  );

  const [color, setColor] = useState(
    customStyle.color || finalStyles.color || ""
  );

  const [borderColor, setBorderColor] = useState(
    customStyle.borderColor || finalStyles.borderColor || ""
  );

  const [sizeVisible, setSizeVisible] = useState(false);

  const handleCustomStyleChange = (properties, value) => {
    const applyStylesToChildren = (control, style) => {
      if (control.control) {
        control.control.forEach((childControl) => {
          if (childControl.type === "Table") {
            updateCustomStyle(childControl.styleid, style);
            applyStylesToChildren(childControl, style);
          }
          if (childControl.htmlTag === "th" || childControl.htmlTag === "td") {
            const existingTdStyle = {
              ...(customStyles[childControl.styleid] || {}),
            };

            const updatedTdStyle = {
              ...existingTdStyle,
              ...style,
              alignSelf: existingTdStyle.alignSelf || style.alignSelf,
              backgroundColor:
                existingTdStyle.backgroundColor || style.backgroundColor,

              color: existingTdStyle.color || style.color,
              fontFamily: existingTdStyle.fontFamily || style.fontFamily,
              fontSize: existingTdStyle.fontSize || style.fontSize,
              fontStyle: existingTdStyle.fontStyle || style.fontStyle,
              textAlign: existingTdStyle.textAlign || style.textAlign,
              textDecoration:
                existingTdStyle.textDecoration || style.textDecoration,
              marginTop: existingTdStyle.marginTop || style.marginTop,
              marginBottom: existingTdStyle.marginBottom || style.marginBottom,
              marginRight: existingTdStyle.marginRight || style.marginRight,
              marginLeft: existingTdStyle.marginLeft || style.marginLeft,
              paddingTop: existingTdStyle.paddingTop || style.paddingTop,
              paddingBottom:
                existingTdStyle.paddingBottom || style.paddingBottom,
              paddingRight: existingTdStyle.paddingRight || style.paddingRight,
              paddingLeft: existingTdStyle.paddingLeft || style.paddingLeft,
              width: existingTdStyle.width || style.width,
              height: existingTdStyle.height || style.height,
              minWidth: existingTdStyle.minWidth || style.minWidth,
              minHeight: existingTdStyle.minHeight || style.minHeight,
              maxWidth: existingTdStyle.maxWidth || style.maxWidth,
              maxHeight: existingTdStyle.maxHeight || style.maxHeight,
            };

            updateCustomStyle(childControl.styleid, updatedTdStyle);
            console.log(
              `Updated style for ${childControl.htmlTag}:`,
              updatedTdStyle
            );
          }
        });
      }
    };

    const getControlByStyleId = (id, controls) => {
      for (let control of controls) {
        if (control.styleid === id) {
          return control;
        }
        if (control.control) {
          const result = getControlByStyleId(id, control.control);
          if (result) return result;
        }
      }
      return null;
    };

    const selectedControl = getControlByStyleId(
      styleid,
      treeData[0].Source[0].control
    );
    if (!selectedControl) return;

    let updatedCustomStyle = { ...(customStyles[styleid] || {}) };

    if (Array.isArray(properties)) {
      properties.forEach((property) => {
        if (property === "textDecoration") {
          const isUnderline =
            customStyles[styleid]?.textDecoration === "underline";
          updatedCustomStyle[property] = isUnderline ? "none" : "underline";
        } else if (property === "fontWeight" || property === "fontStyle") {
          const isBold = customStyles[styleid]?.fontWeight === "bold";
          const isItalic = customStyles[styleid]?.fontStyle === "italic";
          updatedCustomStyle[property] =
            property === "fontWeight"
              ? isBold
                ? "normal"
                : "bold"
              : isItalic
                ? "normal"
                : "italic";
        } else {
          const setterMap = {
            borderWidth: setBorderWidth,
            borderRadius: setBorderRadius,
            marginTop: setMarginTop,
            marginBottom: setMarginBottom,
            marginRight: setMarginRight,
            marginLeft: setMarginLeft,
            paddingTop: setPaddingTop,
            paddingBottom: setPaddingBottom,
            paddingRight: setPaddingRight,
            paddingLeft: setPaddingLeft,
            width: setWidth,
            height: setHeight,
            minWidth: setMinWidth,
            minHeight: setMinHeight,
            maxWidth: setMaxWidth,
            maxHeight: setMaxHeight,
            backgroundColor: setBackgroundColor,
            color: setColor,
            borderColor: setBorderColor,
            borderSpacing: setBorderSpacing,
          };

          if (setterMap[property]) {
            setterMap[property](value);
          }

          updatedCustomStyle[property] = value;
        }
      });
    } else {
      if (properties === "textDecoration") {
        const isUnderline =
          customStyles[styleid]?.textDecoration === "underline";
        updatedCustomStyle[properties] = isUnderline ? "none" : "underline";
      } else if (properties === "fontWeight" || properties === "fontStyle") {
        const isBold = customStyles[styleid]?.fontWeight === "bold";
        const isItalic = customStyles[styleid]?.fontStyle === "italic";
        updatedCustomStyle[properties] =
          properties === "fontWeight"
            ? isBold
              ? "normal"
              : "bold"
            : isItalic
              ? "normal"
              : "italic";
      } else {
        const setterMap = {
          borderWidth: setBorderWidth,
          borderRadius: setBorderRadius,
          marginTop: setMarginTop,
          marginBottom: setMarginBottom,
          marginRight: setMarginRight,
          marginLeft: setMarginLeft,
          paddingTop: setPaddingTop,
          paddingBottom: setPaddingBottom,
          paddingRight: setPaddingRight,
          paddingLeft: setPaddingLeft,
          width: setWidth,
          height: setHeight,
          minWidth: setMinWidth,
          minHeight: setMinHeight,
          maxWidth: setMaxWidth,
          maxHeight: setMaxHeight,
          backgroundColor: setBackgroundColor,
          color: setColor,
          borderColor: setBorderColor,
          borderSpacing: setBorderSpacing,
        };

        if (setterMap[properties]) {
          setterMap[properties](value);
        }

        updatedCustomStyle[properties] = value;
      }
    }

    const mergedCustomStyle = {
      ...(JSON.parse(styles) || {}),
      ...updatedCustomStyle,
    };

    updateCustomStyle(styleid, mergedCustomStyle);

    if (selectedControl.type === "Table") {
      applyStylesToChildren(selectedControl, mergedCustomStyle);
    }
  };

  const commonAttributes = {
    styleid: styleid,
    style: finalStyles,
    id: keyss,
    parentid: parentid,
    tableProp: tableProp,
    tabIndex: "0",
    ...(!controlsVisible ? {} : { onContextMenu: onContextMenu }),
    ...(!controlsVisible ? {} : { onClick: onClickforFocus }),
    name: name,
    className: "controlCls",
    onMouseOver,
    onMouseOut,
  };

  let attributes = { ...commonAttributes };

  if (controltype === "Table") {
    attributes.name = "Table";
  } else {
    attributes.name = name;
  }
  const Switchattributes = {
    styleid: styleid,
    style: finalStyles,
    // onContextMenu: onContextMenu,
    ...(!controlsVisible ? {} : { onContextMenu: onContextMenu }),
    id: keyss,
    tableProp: tableProp,
    tabIndex: "0",
    name: "Switch",
    // onClick: onClickforFocus,
    ...(!controlsVisible ? {} : { onClick: onClickforFocus }),
    className: "controlCls",
    onMouseOver,
    onMouseOut,
  };
  console.log('Switchattributes:', Switchattributes); 
  const RadioAttributes = {
    styleid: styleid,
    style: finalStyles,
    // onContextMenu: onContextMenu,
    ...(!controlsVisible ? {} : { onContextMenu: onContextMenu }),
    id: keyss,
    tableProp: tableProp,
    tabIndex: "0",
    name: "Radio",
    // onClick: onClickforFocus,
    ...(!controlsVisible ? {} : { onClick: onClickforFocus }),
    className: "controlCls",
    onMouseOver,
    onMouseOut,
  };

  const CheckboxAttributes = {
    styleid: styleid,
    style: finalStyles,
    // onContextMenu: onContextMenu,
    ...(!controlsVisible ? {} : { onContextMenu: onContextMenu }),
    id: keyss,
    tableProp: tableProp,
    tabIndex: "0",
    name: "Checkbox",
    // onClick: onClickforFocus,
    ...(!controlsVisible ? {} : { onClick: onClickforFocus }),
    className: "controlCls",
    onMouseOver,
    onMouseOut,
  };

  const DateAtttribute = {
    styleid: styleid,
    style: finalStyles,
    // onContextMenu: onContextMenu,
    ...(!controlsVisible ? {} : { onContextMenu: onContextMenu }),
    id: keyss,
    tableProp: tableProp,
    tabIndex: "0",
    name: "Date",
    // onClick: onClickforFocus,
    ...(!controlsVisible ? {} : { onClick: onClickforFocus }),
    className: "controlCls",
    onMouseOver,
    onMouseOut,
  };

  const shouldRenderMediaUrl = controltype === "Icon" && mediaURl;
  const contentToRender = shouldRenderMediaUrl ? mediaURl : controltype;

  const getNumericAndUnit = (value) => {
    if (typeof value !== "string") {
      value = String(value);
    }

    if (value === undefined || value === null) {
      return {
        numeric: "",
        unit: "px",
      };
    }

    const match = value.match(/^(-?[\d.]+)(\D*)$/);
    if (match) {
      const numeric = match[1];
      const unit = match[2] !== "" ? match[2] : "px";

      return {
        numeric: numeric,
        unit: unit,
      };
    }
    return {
      numeric: "",
      unit: "px",
    };
  };

  const [showPickerBackground, setShowPickerBackground] = useState(false);
  const [showPickerColor, setShowPickerColor] = useState(false);
  const [showPickerBorderColor, setShowPickerBorderColor] = useState(false);

  const inputRef = useRef();
  const pickerRef = useRef();

  const handlePickerMouseLeave = () => {
    setShowPickerBackground(false);
    setShowPickerColor(false);
    setShowPickerBorderColor(false);
  };

  const fixedImageUrl = "https://agiline.com/wp-content/uploads/icon-react.png";

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleDownText = () => {
    if (!isExpanded) {
      var rightsectionExparwBtns = document.querySelectorAll(".expandedArw");
      for (var i = 0; i < rightsectionExparwBtns.length; i++) {
        rightsectionExparwBtns[i].click();
      }
    } else {
      var rightsectionarwShnkBtns = document.querySelectorAll(".shrinkedArw");
      for (var j = 0; j < rightsectionarwShnkBtns.length; j++) {
        rightsectionarwShnkBtns[j].click();
      }
    }
    setIsExpanded(!isExpanded);
  };

  const handleRestore = () => {
    if (selectedItem) {
      const defaultStyleItem = defaultStyle.find(
        (styleItem) => styleItem.styleType === selectedItem.styleType
      );

      if (defaultStyleItem) {
        let newStyles = JSON.stringify({ ...defaultStyleItem.style }, null, 2);

        setCustomStyles(newStyles);
        const updatedTreeData = updateTreeDatadefaultStyle(
          treeData,
          selectedItemID,
          newStyles
        );
        setTreeData(updatedTreeData);
      } else {
        console.log("No default style found for", selectedItem.styleType);
      }
    } else {
      console.log("No item selected to restore");
    }
  };

  const updateTreeDatadefaultStyle = (treeData, selectedItemID, newStyles) => {
    return treeData.map((item) => {
      const updatedSourcedefaultStyle = item.Source.map((source) => ({
        ...source,
        control: updateControlsdefaultStyle(
          source.control,
          selectedItemID,
          newStyles
        ),
      }));
      return { ...item, Source: updatedSourcedefaultStyle };
    });
  };

  const updateControlsdefaultStyle = (
    controls,
    selectedItemID,
    newStyles,
    selectedItemType
  ) => {
    return controls.map((c) => {
      if (c.id === selectedItemID) {
        return {
          ...c,
          styles: newStyles,
        };
      }

      if (c.control && c.control.length > 0) {
        return {
          ...c,
          control: updateControlsdefaultStyle(
            c.control,
            selectedItemID,
            newStyles,
            selectedItemType
          ),
        };
      }
      return c;
    });
  };

  return (
    <React.Fragment>
      {htmlTag === "img" ? (
        <>
          <img {...attributes} src={media || fixedImageUrl} alt="Selected" />
          <label>{imageText}</label>

          {children}
        </>
      ) : htmlTag === "iframe" ? (
        <>
          <iframe {...attributes} src={data} title="Selected"></iframe>
          <label>{imageText}</label>
          {children}
        </>
      ) : htmlTag === "input" && controltype === "TextBox" ? (
        <>
          <input
            {...attributes}
            placeholder={sourceData}
            value={data}
            readOnly
          />
          {children}
        </>
      ) : htmlTag === "input" && controltype === "TextArea" ? (
        <>
          <textarea
            {...attributes}
            placeholder={sourceData}
            value={data}
            readOnly
          />
          {children}
        </>
      ) : htmlTag === "hr" ? (
        <>
          <hr {...attributes} />
          {children}
        </>
      ) : controltype === "Icon" ? (
        <>
          <div
            {...attributes}
            style={{ ...finalStyles }}
            // onClick={onClickforFocus}
            {...(!controlsVisible ? {} : { onClick: onClickforFocus })}
          />
          {children}
        </>
      ) : htmlTag === "li" && typeof data === "string" ? (
        <>
          <ol
            {...attributes}
            style={finalStyles}
            {...(controlsVisible && { onContextMenu })}
          >
            {data
              .trim()
              .split(/[,\n]/)
              .map(
                (item, index) =>
                  item.trim() !== "" && (
                    <li
                      {...attributes}
                      style={finalStyles}
                      {...(controlsVisible && { onContextMenu })}
                      key={index}
                    >
                      {item.trim()}
                    </li>
                  )
              )}
          </ol>

          {children}
        </>
      ) : htmlTag === "input" ? (
        controltype === "Checkbox" ? (
          <>
            {checkposition === "right" ? (
              <>
                <label {...CheckboxAttributes}>
                  <input
                    type={controltype}
                    {...(controlsVisible && { onContextMenu })}
                    checked={check}
                    readOnly
                  />
                  <span className="check">{data}</span>
                </label>
                {children}
              </>
            ) : (
              <>
                <label {...CheckboxAttributes}>
                  <span className="check">{data}</span>
                  <input
                    type={controltype}
                    {...(controlsVisible && { onContextMenu })}
                    checked={check}
                    readOnly
                  />
                </label>
              </>
            )}
            {children}
          </>
        ) : controltype === "Radio" ? (
          <>
            {checkposition === "right" ? (
              <>
                <label {...RadioAttributes}>
                  <input
                    type={controltype}
                    {...(controlsVisible && { onContextMenu })}
                    checked={check}
                    readOnly
                  />
                  <span className="checkleft">{data}</span>
                </label>
                {children}
              </>
            ) : (
              <>
                <label {...RadioAttributes}>
                  <span className="check">{data}</span>
                  <input
                    type={controltype}
                    {...(controlsVisible && { onContextMenu })}
                    checked={check}
                    readOnly
                  />
                </label>
              </>
            )}
            {children}
          </>
        ) : controltype === "TextBox" ? (
          <>
            <input
              type={controltype}
              {...attributes}
              {...(controlsVisible && { onContextMenu })}
              tableProp={tableProp}
              value={data}
              onChange={(e) => {
                handleSelectItem(e.data);
              }}
            />
            {children}
          </>
        ) : controltype === "TextArea" ? (
          <>
            <textarea
              type={controltype}
              {...attributes}
              tableProp={tableProp}
              {...(controlsVisible && { onContextMenu })}
              value={data}
              onChange={(e) => {
                handleSelectItem(e.data);
              }}
            />
            {children}
          </>
        ) : controltype === "Date" ? (
          <>
            <input
              {...DateAtttribute}
              type="date"
              value={data}
              onChange={(e) => handleDateChange(e, keyss)}
            />
            <label>{inputText}</label>
            {children}
          </>
        ) : (
          <>
            <input
              type={controltype}
              {...attributes}
              {...(controlsVisible && { onContextMenu })}
            />
            {children}
          </>
        )
      ) : htmlTag === "select" ? (
        <select
          {...attributes}
          style={finalStyles}
          {...(controlsVisible && { onContextMenu })}
          onChange={(e) => handleSelectChange(e, keyss)}
          value={data}
          {...attributes}
        >
          {data
            .trim()
            .split(/[,|\n]/)
            .map(
              (item, index) =>
                item.trim() !== "" && <option key={index}>{item.trim()}</option>
            )}

          {children}
        </select>
      ) : controltype === "Switch" ? (
        React.createElement(
          "div",
          Switchattributes,
          <>
            <label
              className="switch"
              tableProp={tableProp}
              {...(controlsVisible && { onContextMenu })}
              // onClick={onClickforFocus}
              {...(!controlsVisible ? {} : { onClick: onClickforFocus })}
            >
              <input type="checkbox" checked={switchcheck} />
              <span className="slider round"></span>
            </label>
            <label>{imageText}</label>
            {children}
          </>
        )
      ) : (
        React.createElement(
          htmlTag,
          {
            ...attributes,
            tableProp,
            style: finalStyles,
            ...(controlsVisible && { onContextMenu }),
          },
          shouldRenderMediaUrl && React.isValidElement(mediaURl)
            ? React.cloneElement(mediaURl)
            : contentToRender,
          children
        )
      )}

      {isSelected &&
        isClient &&
        controlsVisible &&
        rightsideStyletab === "2" &&
        (() => {
          const targetElement = document.getElementById(
            rightsideStyletab + "_tab"
          );
          if (!targetElement) {
            console.error(
              `Target container with id ${rightsideStyletab + "_tab"} not found`
            );
            return null;
          }

          return createPortal(
            <div className="custom-style-inputs">
              <div className="reset-btn-top">
                <div
                  className="rht-info fontlft-size"
                  style={{ marginBottom: "-5px" }}
                >
                  Align items
                </div>
                <div className="reset-btn">
                  <button onClick={toggleDownText}>
                    {isExpanded ? "Expand All" : "Collapse All"}{" "}
                  </button>
                </div>
              </div>
              {Object.keys(finalStyles)
                .filter(
                  (property) =>
                    property === "backgroundColor" ||
                    property === "color" ||
                    property === "alignSelf" ||
                    property === "flexDirection" ||
                    property === "textAlign" ||
                    property === "fontWeight" ||
                    property === "fontStyle" ||
                    property === "textDecoration" ||
                    property === "fontFamily" ||
                    property === "fontSize"
                )
                .map((property) => (
                  <div key={property}>
                    {property === "alignSelf" &&
                      htmlTag !== "thead" &&
                      htmlTag !== "th" &&
                      htmlTag !== "tr" &&
                      htmlTag !== "td" && (
                        <div className="fldsSepertr">
                          {/* <div
                          className="rht-info fontlft-size"
                          style={{ marginBottom: "-5px" }}
                        >
                          Align items
                        </div> */}
                          <div className="topicon">
                            <div className="iconset">
                              <div
                                className={
                                  finalStyles.alignSelf === "flex-start"
                                    ? "iconbtnflxtop-active"
                                    : "iconbtnflxtop"
                                }
                                onClick={() => {
                                  handleCustomStyleChange(
                                    "alignSelf",
                                    "flex-start"
                                  );
                                }}
                              >
                                <PiAlignLeftSimpleDuotone />
                                <span className="tooltiptext">Left</span>
                              </div>
                            </div>
                            <div className="iconset">
                              <div
                                className={
                                  finalStyles.alignSelf === "center"
                                    ? "iconbtnflxtop-active"
                                    : "iconbtnflxtop"
                                }
                                onClick={() =>
                                  handleCustomStyleChange("alignSelf", "center")
                                }
                              >
                                <BsAlignCenter />
                                <span className="tooltiptext">Center</span>
                              </div>
                            </div>
                            <div className="iconset">
                              <div
                                className={
                                  finalStyles.alignSelf === "flex-end"
                                    ? "iconbtnflxtop-active"
                                    : "iconbtnflxtop"
                                }
                                onClick={() =>
                                  handleCustomStyleChange(
                                    "alignSelf",
                                    "flex-end"
                                  )
                                }
                              >
                                <PiAlignRightSimpleBold />
                                <span className="tooltiptext">Right</span>
                              </div>
                            </div>
                            <div className="iconset">
                              <div
                                className={
                                  finalStyles.alignSelf === "stretch"
                                    ? "iconbtnflxtop-active"
                                    : "iconbtnflxtop"
                                }
                                onClick={() =>
                                  handleCustomStyleChange(
                                    "alignSelf",
                                    "stretch"
                                  )
                                }
                              >
                                <RxStretchHorizontally />
                                <span className="tooltiptext">Stretch</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    {property === "backgroundColor" &&
                      htmlTag !== "table" &&
                      // styleType !== "Divider" &&
                      styleType !== "Iframe" && (
                        <>
                          <div className="bdy-clr-type fldsSepertr">
                            <div>
                              <PropertiesAccordion
                                title={"Body Color"}
                                isOpen={true}
                              >
                                {" "}
                                <div className="iconsname">
                                  <div className="backgrounclr">
                                    Background Color
                                    <button
                                      style={{
                                        backgroundColor:
                                          finalStyles.backgroundColor,
                                        width: "36px",
                                        height: "36px",
                                        borderRadius: "4px",
                                      }}
                                      onClick={(event) => {
                                        event.preventDefault();
                                        setShowPickerBackground(
                                          !showPickerBackground
                                        );
                                      }}
                                    ></button>
                                    {showPickerBackground && (
                                      <div
                                        style={{
                                          position: "absolute",
                                          zIndex: 999,
                                        }}
                                        ref={inputRef}
                                        onMouseLeave={handlePickerMouseLeave}
                                      >
                                        <ChromePicker
                                          type="color"
                                          color={
                                            customStyle.backgroundColor ||
                                            finalStyles.backgroundColor
                                          }
                                          onChange={(color) =>
                                            handleCustomStyleChange(
                                              "backgroundColor",
                                              color.hex
                                            )
                                          }
                                          ref={pickerRef}
                                        />
                                      </div>
                                    )}
                                    <div className="iconset clearColorWrap forbgClr">
                                      <div
                                        className="clearColor"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleCustomStyleChange(
                                            "backgroundColor",
                                            "transparent"
                                          );
                                        }}
                                      >
                                        <span className="crsLinelft"></span>
                                        <span className="crsLinerht"></span>
                                        <span className="tooltiptext">
                                          Transparent
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>{" "}
                              </PropertiesAccordion>
                            </div>
                          </div>
                        </>
                      )}

                    {property === "flexDirection" &&
                      styleType !== "Iframe" &&
                      styleType !== "Radio" &&
                      styleType !== "Checkbox" && (
                        <>
                          <div className="fldsSepertr">
                            <div className="tglBtn">
                              <PropertiesAccordion title={"Layout"}>
                                {" "}
                                <div className="layoutstyle fldsSepertr">
                                  <div className="laout">
                                    <div className="vrtical">
                                      <div>
                                        <input
                                          type="radio"
                                          value="column"
                                          checked={
                                            customStyle[property] ===
                                              "column" ||
                                            finalStyles[property] === "column"
                                          }
                                          onChange={() =>
                                            handleCustomStyleChange(
                                              property,
                                              "column"
                                            )
                                          }
                                        />
                                        Vertical
                                      </div>
                                    </div>
                                    <div className="horizlt">
                                      <div>
                                        <input
                                          type="radio"
                                          value="row"
                                          checked={
                                            customStyle[property] === "row" ||
                                            finalStyles[property] === "row"
                                          }
                                          onChange={() =>
                                            handleCustomStyleChange(
                                              property,
                                              "row"
                                            )
                                          }
                                        />
                                        Horizontal
                                      </div>
                                    </div>
                                  </div>
                                  {/* <br /> */}
                                  {finalStyles[property] === "column" && (
                                    <>
                                      <div className="rightalinitm">
                                        <div className="iconsetmiddle">
                                          <button
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleCustomStyleChange(
                                                ["alignItems", "alignContent"],
                                                "flex-start"
                                              );
                                            }}
                                            className={
                                              finalStyles?.alignItems ===
                                              "flex-start"
                                                ? "iconbtnflx-active"
                                                : "iconbtnflx"
                                            }
                                          >
                                            <div className="icon-small">
                                              {React.cloneElement(
                                                Theme.HorizontalLeft,
                                                {
                                                  style: {
                                                    width: "16px",
                                                    height: "16px",
                                                  },
                                                }
                                              )}
                                            </div>
                                            <span className="tooltiptext">
                                              Left
                                            </span>
                                          </button>
                                        </div>

                                        <div className="iconsetmiddle">
                                          <button
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleCustomStyleChange(
                                                ["alignItems", "alignContent"],
                                                "center"
                                              );
                                            }}
                                            className={
                                              finalStyles?.alignItems ===
                                              "center"
                                                ? "iconbtnflx-active"
                                                : "iconbtnflx"
                                            }
                                          >
                                            <div className="icon-small">
                                              {React.cloneElement(
                                                Theme.HorizontalCenter,
                                                {
                                                  style: {
                                                    width: "16px",
                                                    height: "16px",
                                                  },
                                                }
                                              )}
                                            </div>
                                            <span className="tooltiptext">
                                              Center
                                            </span>
                                          </button>
                                        </div>

                                        <div className="iconsetmiddle">
                                          <button
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleCustomStyleChange(
                                                ["alignItems", "alignContent"],
                                                "flex-end"
                                              );
                                            }}
                                            className={
                                              finalStyles?.alignItems ===
                                              "flex-end"
                                                ? "iconbtnflx-active"
                                                : "iconbtnflx"
                                            }
                                          >
                                            <div className="icon-small">
                                              {React.cloneElement(
                                                Theme.HorizontalRight,
                                                {
                                                  style: {
                                                    width: "16px",
                                                    height: "16px",
                                                  },
                                                }
                                              )}
                                            </div>

                                            <span className="tooltiptext">
                                              Right
                                            </span>
                                          </button>
                                        </div>

                                        <div className="iconsetmiddle">
                                          <button
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleCustomStyleChange(
                                                ["alignItems", "alignContent"],
                                                "stretch"
                                              );
                                            }}
                                            className={
                                              finalStyles?.alignItems ===
                                              "stretch"
                                                ? "iconbtnflx-active"
                                                : "iconbtnflx"
                                            }
                                          >
                                            {" "}
                                            <div className="icon-small">
                                              {React.cloneElement(
                                                Theme.ViewColumn,
                                                {
                                                  style: {
                                                    width: "16px",
                                                    height: "16px",
                                                  },
                                                }
                                              )}
                                            </div>
                                            <span className="tooltiptext">
                                              Stretch
                                            </span>
                                          </button>
                                        </div>

                                        <br />
                                      </div>
                                      <div className="rightalinitmdown">
                                        <div className="iconsetmiddle">
                                          <button
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleCustomStyleChange(
                                                [
                                                  "justifyItems",
                                                  "justifyContent",
                                                ],
                                                "flex-start"
                                              );
                                            }}
                                            className={
                                              finalStyles?.justifyItems ===
                                              "flex-start"
                                                ? "iconbtnflx-active"
                                                : "iconbtnflx"
                                            }
                                          >
                                            <div className="icon-small">
                                              {React.cloneElement(
                                                Theme.VerticalTop,
                                                {
                                                  style: {
                                                    width: "16px",
                                                    height: "16px",
                                                  },
                                                }
                                              )}
                                            </div>
                                            <span className="tooltiptext">
                                              Top
                                            </span>
                                          </button>
                                        </div>
                                        <div className="iconsetmiddle">
                                          <button
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleCustomStyleChange(
                                                [
                                                  "justifyItems",
                                                  "justifyContent",
                                                ],
                                                "center"
                                              );
                                            }}
                                            className={
                                              finalStyles?.justifyItems ===
                                              "center"
                                                ? "iconbtnflx-active"
                                                : "iconbtnflx"
                                            }
                                          >
                                            <div className="icon-small">
                                              {" "}
                                              {React.cloneElement(
                                                Theme.VerticalCenter,
                                                {
                                                  style: {
                                                    width: "16px",
                                                    height: "16px",
                                                  },
                                                }
                                              )}
                                            </div>
                                            <span className="tooltiptext">
                                              Middle
                                            </span>
                                          </button>
                                        </div>
                                        <div className="iconsetmiddle">
                                          <button
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleCustomStyleChange(
                                                [
                                                  "justifyItems",
                                                  "justifyContent",
                                                ],
                                                "flex-end"
                                              );
                                            }}
                                            className={
                                              finalStyles?.justifyItems ===
                                              "flex-end"
                                                ? "iconbtnflx-active"
                                                : "iconbtnflx"
                                            }
                                          >
                                            {" "}
                                            <div className="icon-small">
                                              {React.cloneElement(
                                                Theme.VerticalBottom,
                                                {
                                                  style: {
                                                    width: "16px",
                                                    height: "16px",
                                                  },
                                                }
                                              )}
                                            </div>
                                            <span className="tooltiptext">
                                              Bottom
                                            </span>
                                          </button>
                                        </div>
                                        <div className="iconsetmiddle">
                                          <button
                                            onClick={(event) => {
                                              event.preventDefault();
                                              handleCustomStyleChange(
                                                [
                                                  "justifyItems",
                                                  "justifyContent",
                                                ],
                                                "space-between"
                                              );
                                            }}
                                            className={
                                              finalStyles?.justifyItems ===
                                              "space-between"
                                                ? "iconbtnflx-active"
                                                : "iconbtnflx"
                                            }
                                          >
                                            <div className="icon-small">
                                              {React.cloneElement(
                                                Theme.RoadSharp,
                                                {
                                                  style: {
                                                    width: "16px",
                                                    height: "16px",
                                                  },
                                                }
                                              )}
                                            </div>
                                            <span className="tooltiptext">
                                              Distribute
                                            </span>
                                          </button>
                                        </div>
                                        <br />
                                      </div>
                                    </>
                                  )}
                                  {finalStyles[property] === "row" && (
                                    <>
                                      <div className="rightalinitm">
                                        <div className="iconsetmiddle">
                                          <button
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleCustomStyleChange(
                                                ["alignItems", "alignContent"],
                                                "flex-start"
                                              );
                                            }}
                                            className={
                                              finalStyles?.alignItems ===
                                              "flex-start"
                                                ? "iconbtnflx-active"
                                                : "iconbtnflx"
                                            }
                                          >
                                            <div className="icon-small">
                                              {React.cloneElement(
                                                Theme.VerticalTop,
                                                {
                                                  style: {
                                                    width: "16px",
                                                    height: "16px",
                                                  },
                                                }
                                              )}
                                            </div>
                                            <span className="tooltiptext">
                                              Top
                                            </span>
                                          </button>
                                        </div>

                                        <div className="iconsetmiddle">
                                          <button
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleCustomStyleChange(
                                                ["alignItems", "alignContent"],
                                                "center"
                                              );
                                            }}
                                            className={
                                              finalStyles?.alignItems ===
                                              "center"
                                                ? "iconbtnflx-active"
                                                : "iconbtnflx"
                                            }
                                          >
                                            <div className="icon-small">
                                              {React.cloneElement(
                                                Theme.VerticalCenter,
                                                {
                                                  style: {
                                                    width: "16px",
                                                    height: "16px",
                                                  },
                                                }
                                              )}
                                            </div>
                                            <span className="tooltiptext">
                                              Middle
                                            </span>
                                          </button>
                                        </div>

                                        <div className="iconsetmiddle">
                                          <button
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleCustomStyleChange(
                                                ["alignItems", "alignContent"],
                                                "flex-end"
                                              );
                                            }}
                                            className={
                                              finalStyles?.alignItems ===
                                              "flex-end"
                                                ? "iconbtnflx-active"
                                                : "iconbtnflx"
                                            }
                                          >
                                            <div className="icon-small">
                                              {React.cloneElement(
                                                Theme.VerticalBottom,
                                                {
                                                  style: {
                                                    width: "16px",
                                                    height: "16px",
                                                  },
                                                }
                                              )}
                                            </div>

                                            <span className="tooltiptext">
                                              Bottom
                                            </span>
                                          </button>
                                        </div>

                                        <div className="iconsetmiddle">
                                          <button
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleCustomStyleChange(
                                                ["alignItems", "alignContent"],
                                                "stretch"
                                              );
                                            }}
                                            className={
                                              finalStyles?.alignItems ===
                                              "stretch"
                                                ? "iconbtnflx-active"
                                                : "iconbtnflx"
                                            }
                                          >
                                            {" "}
                                            <div className="icon-small">
                                              {React.cloneElement(
                                                Theme.RoadSharp,
                                                {
                                                  style: {
                                                    width: "16px",
                                                    height: "16px",
                                                  },
                                                }
                                              )}
                                            </div>
                                            <span className="tooltiptext">
                                              Stretch
                                            </span>
                                          </button>
                                        </div>

                                        <br />
                                      </div>
                                      <div className="rightalinitmdown">
                                        <div className="iconsetmiddle">
                                          <button
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleCustomStyleChange(
                                                [
                                                  "justifyItems",
                                                  "justifyContent",
                                                ],
                                                "flex-start"
                                              );
                                            }}
                                            className={
                                              finalStyles?.justifyItems ===
                                              "flex-start"
                                                ? "iconbtnflx-active"
                                                : "iconbtnflx"
                                            }
                                          >
                                            <div className="icon-small">
                                              {React.cloneElement(
                                                Theme.HorizontalLeft,
                                                {
                                                  style: {
                                                    width: "16px",
                                                    height: "16px",
                                                  },
                                                }
                                              )}
                                            </div>
                                            <span className="tooltiptext">
                                              Left
                                            </span>
                                          </button>
                                        </div>
                                        <div className="iconsetmiddle">
                                          <button
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleCustomStyleChange(
                                                [
                                                  "justifyItems",
                                                  "justifyContent",
                                                ],
                                                "center"
                                              );
                                            }}
                                            className={
                                              finalStyles?.justifyItems ===
                                              "center"
                                                ? "iconbtnflx-active"
                                                : "iconbtnflx"
                                            }
                                          >
                                            <div className="icon-small">
                                              {" "}
                                              {React.cloneElement(
                                                Theme.HorizontalCenter,
                                                {
                                                  style: {
                                                    width: "16px",
                                                    height: "16px",
                                                  },
                                                }
                                              )}
                                            </div>
                                            <span className="tooltiptext">
                                              Center
                                            </span>
                                          </button>
                                        </div>
                                        <div className="iconsetmiddle">
                                          <button
                                            onClick={(e) => {
                                              e.preventDefault();
                                              handleCustomStyleChange(
                                                [
                                                  "justifyItems",
                                                  "justifyContent",
                                                ],
                                                "flex-end"
                                              );
                                            }}
                                            className={
                                              finalStyles?.justifyItems ===
                                              "flex-end"
                                                ? "iconbtnflx-active"
                                                : "iconbtnflx"
                                            }
                                          >
                                            {" "}
                                            <div className="icon-small">
                                              {React.cloneElement(
                                                Theme.HorizontalRight,
                                                {
                                                  style: {
                                                    width: "16px",
                                                    height: "16px",
                                                  },
                                                }
                                              )}
                                            </div>
                                            <span className="tooltiptext">
                                              Right
                                            </span>
                                          </button>
                                        </div>
                                        <div className="iconsetmiddle">
                                          <button
                                            onClick={(event) => {
                                              event.preventDefault();
                                              handleCustomStyleChange(
                                                [
                                                  "justifyItems",
                                                  "justifyContent",
                                                ],
                                                "space-between"
                                              );
                                            }}
                                            className={
                                              finalStyles?.justifyItems ===
                                              "space-between"
                                                ? "iconbtnflx-active"
                                                : "iconbtnflx"
                                            }
                                          >
                                            <div className="icon-small">
                                              {React.cloneElement(
                                                Theme.ViewColumn,
                                                {
                                                  style: {
                                                    width: "16px",
                                                    height: "16px",
                                                  },
                                                }
                                              )}
                                            </div>
                                            <span className="tooltiptext">
                                              Distribute
                                            </span>
                                          </button>
                                        </div>
                                        <br />
                                      </div>
                                    </>
                                  )}
                                </div>
                              </PropertiesAccordion>
                            </div>
                          </div>
                        </>
                      )}

                   

                    {property === "fontSize" &&
                      styleType !== "Switch" &&
                      htmlTag !== "table" &&
                      htmlTag !== "tr" &&
                      htmlTag !== "thead" &&
                      styleType !== "Container" && (
                        <div className="fldsSepertr">
                          <div className="gnr-data-src">
                            <div className="tglBtn">
                              <PropertiesAccordion
                                title={"Font"}
                                className="wefwedfwedfed"
                              >
                                {" "}
                                <div className="backgrounclr font-changewidth">
                                  Color
                                  <button
                                    style={{
                                      backgroundColor: finalStyles.color,
                                      width: "26px",
                                      height: "26px",
                                      borderRadius: "4px",
                                    }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setShowPickerColor(!showPickerColor);
                                    }}
                                  ></button>
                                  {showPickerColor && (
                                    <div
                                      style={{
                                        position: "absolute",
                                        zIndex: 999,
                                      }}
                                      ref={inputRef}
                                      onMouseLeave={handlePickerMouseLeave}
                                    >
                                      <ChromePicker
                                        type="color"
                                        color={
                                          customStyle.color || finalStyles.color
                                        }
                                        onChange={(color) =>
                                          handleCustomStyleChange(
                                            "color",
                                            color.hex
                                          )
                                        }
                                        ref={pickerRef}
                                      />
                                    </div>
                                  )}
                                </div>
                                <div className="stle-font">
                                  <div className="rht-fld-selectwrap for-fild-styl">
                                    <div className="rht-info fontlft-size">
                                      Font Size
                                    </div>
                                    {styleType !== "Icon" && (
                                      <Select
                                        className="rht-fld-select rht-font-ipnt"
                                        value={
                                          customStyle.fontSize ||
                                          finalStyles.fontSize
                                        }
                                        onChange={(e) => {
                                          const selectedValue = e.target.value;
                                          setFontSize(selectedValue);
                                          handleCustomStyleChange(
                                            "fontSize",
                                            selectedValue
                                          );
                                        }}
                                      >
                                        {[
                                          {
                                            value: "35px",
                                            label: "Headline1",
                                          },
                                          {
                                            value: "24px",
                                            label: "Headline2",
                                          },
                                          {
                                            value: "18px",
                                            label: "Headline3",
                                          },
                                          {
                                            value: "16px",
                                            label: "LargeText",
                                          },
                                          {
                                            value: "14px",
                                            label: "NormalText",
                                          },
                                          {
                                            value: "12px",
                                            label: "SmallText",
                                          },
                                        ].map((option) => (
                                          <MenuItem
                                            key={option.value}
                                            value={option.value}
                                            style={{ fontSize: option.value }}
                                          >
                                            {option.label}
                                          </MenuItem>
                                        ))}

                                        {!isPredefinedFontSize(
                                          customStyle.fontSize ||
                                            finalStyles.fontSize
                                        ) && (
                                          <MenuItem
                                            value={
                                              customStyle.fontSize ||
                                              finalStyles.fontSize
                                            }
                                            style={{ fontSize: "26px" }}
                                          >
                                            CustomFontSize
                                          </MenuItem>
                                        )}
                                      </Select>
                                    )}

                                    <div className="rht-fnt">
                                      <div className="ipubrdr fontszefr-inp rht-fld-fontsz">
                                        <div className="rht-fld rht-inpt-field">
                                          <input
                                            type="number"
                                            value={
                                              getNumericAndUnit(
                                                finalStyles.fontSize
                                              ).numeric
                                            }
                                            onChange={(e) => {
                                              const newValue = `${e.target.value}${
                                                getNumericAndUnit(fontSize).unit
                                              }`;
                                              setFontSize(newValue);
                                              handleCustomStyleChange(
                                                "fontSize",
                                                newValue
                                              );
                                            }}
                                          />
                                          <span className="unit-dropdown unit-font-drop">
                                            <Select
                                              value={
                                                getNumericAndUnit(fontSize).unit
                                              }
                                              onChange={(e) => {
                                                const newValue = `${
                                                  getNumericAndUnit(fontSize)
                                                    .numeric
                                                }${e.target.value}`;
                                                setFontSize(newValue);
                                                handleCustomStyleChange(
                                                  "fontSize",
                                                  newValue
                                                );
                                              }}
                                              style={{
                                                width: "40px",
                                                height: "25px",
                                                backgroundColor: "#CFD8DD",
                                                fontSize: "15px",
                                              }}
                                              displayEmpty
                                              IconComponent={() => null}
                                              MenuProps={{
                                                style: { top: "5px" },
                                              }}
                                            >
                                              <MenuItem
                                                value="px"
                                                className="custom-menuitem"
                                              >
                                                px
                                              </MenuItem>
                                              <MenuItem
                                                value="em"
                                                className="custom-menuitem"
                                              >
                                                em
                                              </MenuItem>
                                              <MenuItem
                                                value="%"
                                                className="custom-menuitem"
                                              >
                                                %
                                              </MenuItem>
                                            </Select>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {styleType !== "Icon" && (
                                  <div className="rht-fld-selectwrap font-style-displ">
                                    <div className="rht-info fontlft-size">
                                      Font Family
                                    </div>

                                    <Select
                                      className="rht-fld-select rht-font-ipnt"
                                      value={
                                        customStyle.fontFamily ||
                                        finalStyles.fontFamily
                                      }
                                      onChange={(e) =>
                                        handleCustomStyleChange(
                                          "fontFamily",
                                          e.target.value
                                        )
                                      }
                                    >
                                      {fontFamilies.map((font, index) => (
                                        <MenuItem
                                          key={index}
                                          value={font}
                                          style={{ fontFamily: font }}
                                        >
                                          {font}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </div>
                                )}
                                {styleType !== "Icon" &&
                                  styleType !== "Table" &&
                                  styleType !== "Radio" && (
                                    <div className="textAlgnBtns">
                                      <div className="topmidle">
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleCustomStyleChange(
                                              "textAlign",
                                              "left"
                                            );
                                          }}
                                          className={
                                            finalStyles.textAlign === "left"
                                              ? "iconbtnflxtop-active"
                                              : "iconbtnflxtop"
                                          }
                                        >
                                          <div
                                            style={{
                                              width: "45px",
                                              height: "35px",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                            className={
                                              customStyles[styleid]
                                                ?.textAlign === "left"
                                                ? "mui-button-active"
                                                : "mui-button"
                                            }
                                          >
                                            {Theme.FormatAlignLeft}
                                          </div>
                                          <span className="tooltiptext">
                                            Left
                                          </span>
                                        </button>
                                      </div>
                                      <div className="topmidle">
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleCustomStyleChange(
                                              "textAlign",
                                              "center"
                                            );
                                          }}
                                          style={{ marginRight: "3px" }}
                                          className={
                                            finalStyles.textAlign === "center"
                                              ? "iconbtnflxtop-active"
                                              : "iconbtnflxtop"
                                          }
                                        >
                                          <div
                                            style={{
                                              width: "45px",
                                              height: "35px",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                            className={
                                              customStyles[styleid]
                                                ?.textAlign === "center"
                                                ? "mui-button-active"
                                                : "mui-button"
                                            }
                                          >
                                            {Theme.FormatAlignCenter}
                                          </div>
                                          <span className="tooltiptext">
                                            Middle
                                          </span>
                                        </button>
                                      </div>
                                      <div className="topmidle">
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            handleCustomStyleChange(
                                              "textAlign",
                                              "right"
                                            );
                                          }}
                                          style={{ marginRight: "3px" }}
                                          className={
                                            finalStyles.textAlign === "right"
                                              ? "iconbtnflxtop-active"
                                              : "iconbtnflxtop"
                                          }
                                        >
                                          <div
                                            style={{
                                              width: "45px",
                                              height: "35px",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                            className={
                                              customStyles[styleid]
                                                ?.textAlign === "right"
                                                ? "mui-button-active"
                                                : "mui-button"
                                            }
                                          >
                                            {Theme.FormatAlignRight}
                                          </div>
                                          <span className="tooltiptext">
                                            Right
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                {styleType !== "Icon" && (
                                  <div
                                    className="textAlgnBtns"
                                    style={{ float: "left" }}
                                  >
                                    <div className="topmidle">
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleCustomStyleChange(
                                            "fontWeight",
                                            "bold"
                                          );
                                        }}
                                        className={
                                          finalStyles?.fontWeight === "bold"
                                            ? "button-active"
                                            : "buttontp"
                                        }
                                      >
                                        {customStyles[styleid]?.fontWeight ===
                                        "bold" ? (
                                          <div
                                            style={{
                                              width: "45px",
                                              height: "35px",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                            className={
                                              finalStyles?.fontWeight === "bold"
                                                ? "mui-button-active"
                                                : "mui-button"
                                            }
                                          >
                                            {Theme.FormatBold}
                                          </div>
                                        ) : (
                                          <div
                                            style={{
                                              width: "45px",
                                              height: "35px",
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                            className={
                                              finalStyles?.fontWeight === "bold"
                                                ? "mui-button-active"
                                                : "mui-button"
                                            }
                                          >
                                            {Theme.FormatBold}
                                          </div>
                                        )}
                                        <span className="tooltiptext">
                                          Bold
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                )}
                                {styleType !== "Icon" && (
                                  <div
                                    className="textAlgnBtns"
                                    style={{ float: "left" }}
                                  >
                                    <div className="topmidle">
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleCustomStyleChange(
                                            "fontStyle",
                                            "italic"
                                          );
                                        }}
                                        className={
                                          finalStyles?.fontStyle === "italic"
                                            ? "button-active"
                                            : "buttontp"
                                        }
                                      >
                                        <div
                                          style={{
                                            width: "45px",
                                            height: "35px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                          className={
                                            finalStyles?.fontStyle === "italic"
                                              ? "mui-button-active"
                                              : "mui-button"
                                          }
                                        >
                                          {Theme.FormatItalic}
                                        </div>
                                        <span className="tooltiptext">
                                          Italic
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                )}
                                {styleType !== "Icon" && (
                                  <div
                                    className="textAlgnBtns"
                                    style={{ float: "left" }}
                                  >
                                    <div className="topmidle">
                                      <button
                                        onClick={(e) => {
                                          e.preventDefault();
                                          handleCustomStyleChange(
                                            "textDecoration",
                                            "underline"
                                          );
                                        }}
                                        className={
                                          finalStyles?.textDecoration ===
                                          "underline"
                                            ? "button-active"
                                            : "buttontp"
                                        }
                                      >
                                        <div
                                          style={{
                                            width: "45px",
                                            height: "35px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                          className={
                                            finalStyles?.textDecoration ===
                                            "underline"
                                              ? "mui-button-active"
                                              : "mui-button"
                                          }
                                        >
                                          {Theme.FormatUnderline}
                                        </div>
                                        <span className="tooltiptext">
                                          underline
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </PropertiesAccordion>
                            </div>
                            <div></div>
                          </div>
                        </div>
                      )}
                  </div>
                ))}
            </div>,
            targetElement
          );
        })()}




      {isSelected &&
        styleType !== "Label" &&
        styleType !== "Header" &&
        styleType !== "SubHeader" &&
        htmlTag !== "li" &&
        styleType !== "Switch" &&
        styleType !== "Checkbox" &&
        styleType !== "Radio" &&
        styleType !== "Icon" &&
        styleType !== "Slider" &&
        styleType !== "TableLabel" &&
        styleType !== "TableHeader" &&
        //  inputType === "date" &&
        htmlTag !== "thead" &&
        htmlTag !== "tr" &&
        // htmlTag !== "th" &&
        styleType !== "Time" &&
        isClient &&
        controlsVisible &&
        rightsideStyletab === "2" &&
        (() => {
          const targetElement = document.getElementById(
            rightsideStyletab + "_tab"
          );
          if (!targetElement) {
            console.error(
              `Target container with id ${rightsideStyletab + "_tab"} not found`
            );
            return null;
          }

          return createPortal(
            <div className="fldsSepertr unit-dropdown-border">
              <div className="border1 bortop">
                <div className="tglBtn">
                  <PropertiesAccordion title={"Border"}>
                    <>
                      <div className="colorforborder">
                        <div className="inptcheck">
                          <div className="ipubrdr">
                            <input
                              type="number"
                              value={
                                getNumericAndUnit(finalStyles.borderWidth)
                                  .numeric
                              }
                              onChange={(e) => {
                                const newValue = `${e.target.value}px`;
                                setBorderWidth(newValue);
                                handleCustomStyleChange(
                                  "borderWidth",
                                  newValue
                                );
                              }}
                            />
                            <span className="unit-dropdown">
                              <Select
                                value={
                                  getNumericAndUnit(finalStyles.borderWidth)
                                    .unit
                                }
                                onChange={(e) => {
                                  const newValue = `${
                                    getNumericAndUnit(borderWidth).numeric
                                  }${e.target.value}`;
                                  setBorderWidth(newValue);
                                  handleCustomStyleChange(
                                    "borderWidth",
                                    newValue
                                  );
                                }}
                                style={{
                                  width: "40px",
                                  padding: "9px 11px 10px 12px",
                                  height: "27px",
                                }}
                                displayEmpty
                                IconComponent={() => null}
                                MenuProps={{ style: { top: "5px" } }}
                                className="custom-dropdown"
                              >
                                <MenuItem
                                  value="px"
                                  className="custom-menuitem"
                                >
                                  px
                                </MenuItem>
                                <MenuItem
                                  value="em"
                                  className="custom-menuitem"
                                >
                                  em
                                </MenuItem>
                                <MenuItem value="%" className="custom-menuitem">
                                  %
                                </MenuItem>
                              </Select>
                            </span>
                          </div>
                          <span className="thickness">Thickness</span>
                        </div>

                        <div className="colrfrborder">
                          <button
                            style={{
                              backgroundColor: finalStyles.borderColor,
                              width: "36px",
                              height: "36px",
                              borderRadius: "4px",
                            }}
                            onClick={(e) => {
                              e.preventDefault();
                              setShowPickerBorderColor(!showPickerBorderColor);
                            }}
                          ></button>
                          {showPickerBorderColor && (
                            <div
                              style={{
                                position: "absolute",
                                zIndex: 999,
                              }}
                              ref={inputRef}
                              onMouseLeave={handlePickerMouseLeave}
                            >
                              <ChromePicker
                                type="color"
                                color={
                                  customStyle.borderColor ||
                                  finalStyles.borderColor
                                }
                                onChange={(color) =>
                                  handleCustomStyleChange(
                                    "borderColor",
                                    color.hex
                                  )
                                }
                                ref={pickerRef}
                              />
                            </div>
                          )}
                        </div>
                        <div className="iconset clearColorWrap">
                          <div
                            className="clearColor"
                            onClick={(e) => {
                              e.preventDefault();
                              handleCustomStyleChange(
                                "borderColor",
                                "transparent"
                              );
                            }}
                          >
                            <span className="crsLinelft"></span>
                            <span className="crsLinerht"></span>
                            <span className="tooltiptext">Transparent</span>
                          </div>
                        </div>
                      </div>
                    </>
                    <>
                      {styleType !== "Divider" &&
                        htmlTag !== "thead" &&
                        htmlTag !== "table" && (
                          <div className="colorforborder">
                            <div className="inptcheck">
                              <div className="ipubrdr">
                                <input
                                  type="number"
                                  value={
                                    getNumericAndUnit(finalStyles.borderRadius)
                                      .numeric
                                  }
                                  onChange={(e) => {
                                    const newValue = `${e.target.value}px`;
                                    setBorderRadius(newValue);
                                    handleCustomStyleChange(
                                      "borderRadius",
                                      newValue
                                    );
                                  }}
                                />
                                <span className="unit-dropdown">
                                  <Select
                                    value="px"
                                    onChange={(e) => {}}
                                    style={{
                                      width: "40px",
                                      height: "27px",
                                      padding: "9px 8px 10px -1px",
                                      color: "#ccc",
                                    }}
                                    displayEmpty
                                    IconComponent={() => null}
                                    MenuProps={{ style: { display: "none" } }}
                                  >
                                    <MenuItem
                                      value="px"
                                      disabled
                                      className="custom-menuitem"
                                    >
                                      px
                                    </MenuItem>
                                  </Select>
                                </span>
                              </div>
                              <span className="thickness">Radius</span>
                            </div>
                          </div>
                        )}
                    </>
                  </PropertiesAccordion>
                </div>
              </div>
            </div>,
            targetElement
          );
        })()}

      {isSelected &&
        isClient &&
        controlsVisible &&
        htmlTag !== "thead" &&
        htmlTag !== "th" &&
        htmlTag !== "tr" &&
        htmlTag !== "td" &&
        rightsideStyletab === "2" &&
        (() => {
          const targetElement = document.getElementById(
            rightsideStyletab + "_tab"
          );
          if (!targetElement) {
            console.error(
              `Target container with id ${rightsideStyletab + "_tab"} not found`
            );
            return null;
          }

          return createPortal(
            <div className="fldsSepertr">
              <div className="border1">
                <div className="tglBtn">
                  <PropertiesAccordion title={"Margin"}>
                    <>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          const newValue = "0px";
                          setMarginTop(newValue);
                          setMarginBottom(newValue);
                          setMarginLeft(newValue);
                          setMarginRight(newValue);
                          handleCustomStyleChange(
                            [
                              "marginTop",
                              "marginBottom",
                              "marginRight",
                              "marginLeft",
                            ],
                            newValue
                          );
                        }}
                        className="clear-rcord-btn"
                      >
                        <CleaningServicesIcon /> Clear
                      </button>

                      <div className="rht-flds-wrap">
                        <div className="rht-fld-info">
                          <div className="ipubrdr">
                            <div className="rht-fld">
                              <input
                                type="number"
                                placeholder={marginTop === "auto" ? "auto" : ""}
                                value={
                                  marginTop === "auto"
                                    ? ""
                                    : getNumericAndUnit(finalStyles.marginTop)
                                        .numeric
                                }
                                onChange={(e) => {
                                  const inputNumericValue =
                                    e.target.value.trim();
                                  const newValue =
                                    inputNumericValue === ""
                                      ? ""
                                      : `${inputNumericValue}${
                                          getNumericAndUnit(marginTop).unit
                                        }`;
                                  setMarginTop(newValue);
                                  handleCustomStyleChange(
                                    "marginTop",
                                    newValue === "" ? "auto" : newValue
                                  );
                                }}
                              />

                              <span className="unit-dropdown">
                                <Select
                                  value={
                                    finalStyles.marginTop === "auto"
                                      ? "auto"
                                      : getNumericAndUnit(finalStyles.marginTop)
                                          .unit
                                  }
                                  onChange={(e) => {
                                    const selectedUnit = e.target.value;
                                    let newNumericValue = "";

                                    if (selectedUnit === "auto") {
                                      newNumericValue = "";
                                      setMarginTop("auto");
                                    } else {
                                      newNumericValue =
                                        getNumericAndUnit(marginTop).numeric;
                                      setMarginTop(
                                        `${newNumericValue}${selectedUnit}`
                                      );
                                    }

                                    handleCustomStyleChange(
                                      "marginTop",
                                      selectedUnit === "auto"
                                        ? "auto"
                                        : `${newNumericValue}${selectedUnit}`
                                    );
                                  }}
                                  style={{
                                    width: "40px",
                                    height: "27px",
                                    padding: "9px 11px 10px 12px",
                                  }}
                                  displayEmpty
                                  IconComponent={() => null}
                                  MenuProps={{ style: { top: "5px" } }}
                                  className="custom-dropdown"
                                >
                                  <MenuItem
                                    value="px"
                                    className="custom-menuitem"
                                  >
                                    px
                                  </MenuItem>
                                  <MenuItem
                                    value="em"
                                    className="custom-menuitem"
                                  >
                                    em
                                  </MenuItem>
                                  <MenuItem
                                    value="%"
                                    className="custom-menuitem"
                                  >
                                    %
                                  </MenuItem>
                                  <MenuItem
                                    value="auto"
                                    className="custom-menuitem"
                                  >
                                    auto
                                  </MenuItem>
                                </Select>
                              </span>
                            </div>
                          </div>
                          <div className="rht-info">top</div>
                        </div>
                        <div className="rht-fld-info">
                          <div className="ipubrdr">
                            <div className="rht-fld">
                              <input
                                type="number"
                                placeholder={
                                  marginRight === "auto" ? "auto" : ""
                                }
                                value={
                                  marginRight === "auto"
                                    ? ""
                                    : getNumericAndUnit(finalStyles.marginRight)
                                        .numeric
                                }
                                onChange={(e) => {
                                  const inputNumericValue =
                                    e.target.value.trim();
                                  const newValue =
                                    inputNumericValue === ""
                                      ? ""
                                      : `${inputNumericValue}${
                                          getNumericAndUnit(marginRight).unit
                                        }`;
                                  setMarginRight(newValue);
                                  handleCustomStyleChange(
                                    "marginRight",
                                    newValue === "" ? "auto" : newValue
                                  );
                                }}
                              />

                              <span className="unit-dropdown">
                                <Select
                                  value={
                                    finalStyles.marginRight === "auto"
                                      ? "auto"
                                      : getNumericAndUnit(
                                          finalStyles.marginRight
                                        ).unit
                                  }
                                  onChange={(e) => {
                                    const selectedUnit = e.target.value;
                                    let newNumericValue = "";

                                    if (selectedUnit === "auto") {
                                      newNumericValue = "";
                                      setMarginRight("auto");
                                    } else {
                                      newNumericValue =
                                        getNumericAndUnit(marginRight).numeric;
                                      setMarginRight(
                                        `${newNumericValue}${selectedUnit}`
                                      );
                                    }

                                    handleCustomStyleChange(
                                      "marginRight",
                                      selectedUnit === "auto"
                                        ? "auto"
                                        : `${newNumericValue}${selectedUnit}`
                                    );
                                  }}
                                  style={{
                                    width: "40px",
                                    height: "27px",
                                    padding: "9px 11px 10px 12px",
                                  }}
                                  displayEmpty
                                  IconComponent={() => null}
                                  MenuProps={{ style: { top: "5px" } }}
                                  className="custom-dropdown"
                                >
                                  <MenuItem
                                    value="px"
                                    className="custom-menuitem"
                                  >
                                    px
                                  </MenuItem>
                                  <MenuItem
                                    value="em"
                                    className="custom-menuitem"
                                  >
                                    em
                                  </MenuItem>
                                  <MenuItem
                                    value="%"
                                    className="custom-menuitem"
                                  >
                                    %
                                  </MenuItem>
                                  <MenuItem
                                    value="auto"
                                    className="custom-menuitem"
                                  >
                                    auto
                                  </MenuItem>
                                </Select>
                              </span>
                            </div>
                          </div>
                          <div className="rht-info">Right</div>
                        </div>
                      </div>
                    </>
                    <>
                      <div className="rht-flds-wrap">
                        <div className="rht-fld-info">
                          <div className="ipubrdr">
                            <div className="rht-fld">
                              <input
                                type="number"
                                placeholder={
                                  marginBottom === "auto" ? "auto" : ""
                                }
                                value={
                                  marginBottom === "auto"
                                    ? ""
                                    : getNumericAndUnit(
                                        finalStyles.marginBottom
                                      ).numeric
                                }
                                onChange={(e) => {
                                  const inputNumericValue =
                                    e.target.value.trim();
                                  const newValue =
                                    inputNumericValue === ""
                                      ? ""
                                      : `${inputNumericValue}${
                                          getNumericAndUnit(marginBottom).unit
                                        }`;
                                  setMarginBottom(newValue);
                                  handleCustomStyleChange(
                                    "marginBottom",
                                    newValue === "" ? "auto" : newValue
                                  );
                                }}
                              />

                              <span className="unit-dropdown">
                                <Select
                                  value={
                                    finalStyles.marginBottom === "auto"
                                      ? "auto"
                                      : getNumericAndUnit(
                                          finalStyles.marginBottom
                                        ).unit
                                  }
                                  onChange={(e) => {
                                    const selectedUnit = e.target.value;
                                    let newNumericValue = "";

                                    if (selectedUnit === "auto") {
                                      newNumericValue = "";
                                      setMarginBottom("auto");
                                    } else {
                                      newNumericValue =
                                        getNumericAndUnit(marginBottom).numeric;
                                      setMarginBottom(
                                        `${newNumericValue}${selectedUnit}`
                                      );
                                    }

                                    handleCustomStyleChange(
                                      "marginBottom",
                                      selectedUnit === "auto"
                                        ? "auto"
                                        : `${newNumericValue}${selectedUnit}`
                                    );
                                  }}
                                  style={{
                                    width: "40px",
                                    height: "27px",
                                    padding: "9px 11px 10px 12px",
                                  }}
                                  displayEmpty
                                  IconComponent={() => null}
                                  MenuProps={{ style: { top: "5px" } }}
                                  className="custom-dropdown"
                                >
                                  <MenuItem
                                    value="px"
                                    className="custom-menuitem"
                                  >
                                    px
                                  </MenuItem>
                                  <MenuItem
                                    value="em"
                                    className="custom-menuitem"
                                  >
                                    em
                                  </MenuItem>
                                  <MenuItem
                                    value="%"
                                    className="custom-menuitem"
                                  >
                                    %
                                  </MenuItem>
                                  <MenuItem
                                    value="auto"
                                    className="custom-menuitem"
                                  >
                                    auto
                                  </MenuItem>
                                </Select>
                              </span>
                            </div>
                          </div>
                          <div className="rht-info">Bottom</div>
                        </div>
                        <div className="rht-fld-info">
                          <div className="ipubrdr">
                            <div className="rht-fld">
                              <input
                                type="number"
                                placeholder={
                                  marginLeft === "auto" ? "auto" : ""
                                }
                                value={
                                  marginLeft === "auto"
                                    ? ""
                                    : getNumericAndUnit(finalStyles.marginLeft)
                                        .numeric
                                }
                                onChange={(e) => {
                                  const inputNumericValue =
                                    e.target.value.trim();
                                  const newValue =
                                    inputNumericValue === ""
                                      ? ""
                                      : `${inputNumericValue}${
                                          getNumericAndUnit(marginLeft).unit
                                        }`;
                                  setMarginLeft(newValue);
                                  handleCustomStyleChange(
                                    "marginLeft",
                                    newValue === "" ? "auto" : newValue
                                  );
                                }}
                              />

                              <span className="unit-dropdown">
                                <Select
                                  value={
                                    finalStyles.marginLeft === "auto"
                                      ? "auto"
                                      : getNumericAndUnit(
                                          finalStyles.marginLeft
                                        ).unit
                                  }
                                  onChange={(e) => {
                                    const selectedUnit = e.target.value;
                                    let newNumericValue = "";

                                    if (selectedUnit === "auto") {
                                      newNumericValue = "";
                                      setMarginLeft("auto");
                                    } else {
                                      newNumericValue =
                                        getNumericAndUnit(marginLeft).numeric;
                                      setMarginLeft(
                                        `${newNumericValue}${selectedUnit}`
                                      );
                                    }

                                    handleCustomStyleChange(
                                      "marginLeft",
                                      selectedUnit === "auto"
                                        ? "auto"
                                        : `${newNumericValue}${selectedUnit}`
                                    );
                                  }}
                                  style={{
                                    width: "40px",
                                    height: "27px",
                                    padding: "9px 11px 10px 12px",
                                  }}
                                  displayEmpty
                                  IconComponent={() => null}
                                  MenuProps={{ style: { top: "5px" } }}
                                  className="custom-dropdown"
                                >
                                  <MenuItem
                                    value="px"
                                    className="custom-menuitem"
                                  >
                                    px
                                  </MenuItem>
                                  <MenuItem
                                    value="em"
                                    className="custom-menuitem"
                                  >
                                    em
                                  </MenuItem>
                                  <MenuItem
                                    value="%"
                                    className="custom-menuitem"
                                  >
                                    %
                                  </MenuItem>
                                  <MenuItem
                                    value="auto"
                                    className="custom-menuitem"
                                  >
                                    auto
                                  </MenuItem>
                                </Select>
                              </span>
                            </div>
                          </div>
                          <div className="rht-info">Left</div>
                        </div>
                      </div>
                    </>
                  </PropertiesAccordion>
                </div>
              </div>
            </div>,
            targetElement
          );
        })()}

      {isSelected &&
        styleType !== "Divider" &&
        styleType !== "Checkbox" &&
        styleType !== "Radio" &&
        styleType !== "Icon" &&
        styleType !== "Switch" &&
        styleType !== "Slider" &&
        styleType !== "Image" &&
        styleType !== "Iframe" &&
        styleType !== "Date" &&
        styleType !== "Time" &&
        isClient &&
        controlsVisible &&
        rightsideStyletab === "2" &&
        (() => {
          const targetElement = document.getElementById(
            rightsideStyletab + "_tab"
          );
          if (!targetElement) {
            console.error(
              `Target container with id ${rightsideStyletab + "_tab"} not found`
            );
            return null;
          }

          return createPortal(
            <div className="fldsSepertr">
              <div className="border1">
                <div className="tglBtn">
                  <PropertiesAccordion title={"Padding"}>
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        const newValue = "0px";
                        setPaddingTop(newValue);
                        setPaddingBottom(newValue);
                        setPaddingRight(newValue);
                        setPaddingLeft(newValue);
                        handleCustomStyleChange(
                          [
                            "paddingTop",
                            "paddingBottom",
                            "paddingRight",
                            "paddingLeft",
                          ],
                          newValue
                        );
                      }}
                      className="clear-rcord-btn"
                    >
                      <CleaningServicesIcon /> Clear
                    </button>

                    <>
                      <div className="rht-flds-wrap">
                        <div className="rht-fld-info">
                          <div className="ipubrdr">
                            <div className="rht-fld">
                              <input
                                type="number"
                                value={
                                  getNumericAndUnit(finalStyles.paddingTop)
                                    .numeric
                                }
                                onChange={(e) => {
                                  const newValue = `${e.target.value}${
                                    getNumericAndUnit(paddingTop).unit
                                  }`;
                                  setPaddingTop(newValue);
                                  handleCustomStyleChange(
                                    "paddingTop",
                                    newValue
                                  );
                                }}
                              />
                              <span className="unit-dropdown">
                                <Select
                                  value={
                                    getNumericAndUnit(finalStyles.paddingTop)
                                      .unit
                                  }
                                  onChange={(e) => {
                                    const newValue = `${
                                      getNumericAndUnit(paddingTop).numeric
                                    }${e.target.value}`;
                                    setPaddingTop(newValue);
                                    handleCustomStyleChange(
                                      "paddingTop",
                                      newValue
                                    );
                                  }}
                                  style={{
                                    width: "40px",
                                    height: "27px",
                                    padding: "8px 11px 10px 12px",
                                  }}
                                  displayEmpty
                                  IconComponent={() => null}
                                  MenuProps={{ style: { top: "5px" } }}
                                  className="custom-dropdown"
                                >
                                  <MenuItem
                                    value="px"
                                    className="custom-menuitem"
                                  >
                                    px
                                  </MenuItem>
                                  <MenuItem
                                    value="em"
                                    className="custom-menuitem"
                                  >
                                    em
                                  </MenuItem>
                                  <MenuItem
                                    value="%"
                                    className="custom-menuitem"
                                  >
                                    %
                                  </MenuItem>
                                </Select>
                              </span>
                            </div>
                          </div>
                          <div className="rht-info">top</div>
                        </div>
                        <div className="rht-fld-info">
                          <div className="ipubrdr">
                            <div className="rht-fld">
                              <input
                                type="number"
                                value={
                                  getNumericAndUnit(finalStyles.paddingRight)
                                    .numeric
                                }
                                onChange={(e) => {
                                  const newValue = `${e.target.value}${
                                    getNumericAndUnit(paddingRight).unit
                                  }`;
                                  setPaddingRight(newValue);
                                  handleCustomStyleChange(
                                    "paddingRight",
                                    newValue
                                  );
                                }}
                              />
                              <span className="unit-dropdown">
                                <Select
                                  value={
                                    getNumericAndUnit(finalStyles.paddingRight)
                                      .unit
                                  }
                                  onChange={(e) => {
                                    const newValue = `${
                                      getNumericAndUnit(paddingRight).numeric
                                    }${e.target.value}`;
                                    setPaddingRight(newValue);
                                    handleCustomStyleChange(
                                      "paddingRight",
                                      newValue
                                    );
                                  }}
                                  style={{
                                    width: "40px",
                                    height: "27px",
                                    padding: "8px 11px 10px 12px",
                                  }}
                                  displayEmpty
                                  IconComponent={() => null}
                                  MenuProps={{ style: { top: "5px" } }}
                                  className="custom-dropdown"
                                >
                                  <MenuItem
                                    value="px"
                                    className="custom-menuitem"
                                  >
                                    px
                                  </MenuItem>
                                  <MenuItem
                                    value="em"
                                    className="custom-menuitem"
                                  >
                                    em
                                  </MenuItem>
                                  <MenuItem
                                    value="%"
                                    className="custom-menuitem"
                                  >
                                    %
                                  </MenuItem>
                                </Select>
                              </span>
                            </div>
                          </div>
                          <div className="rht-info">Right</div>
                        </div>
                      </div>
                    </>
                    <>
                      <div className="rht-flds-wrap">
                        <div className="rht-fld-info">
                          <div className="ipubrdr">
                            <div className="rht-fld">
                              <input
                                type="number"
                                value={
                                  getNumericAndUnit(finalStyles.paddingBottom)
                                    .numeric
                                }
                                onChange={(e) => {
                                  const newValue = `${e.target.value}${
                                    getNumericAndUnit(paddingBottom).unit
                                  }`;
                                  setPaddingBottom(newValue);
                                  handleCustomStyleChange(
                                    "paddingBottom",
                                    newValue
                                  );
                                }}
                              />
                              <span className="unit-dropdown">
                                <Select
                                  value={
                                    getNumericAndUnit(finalStyles.paddingBottom)
                                      .unit
                                  }
                                  onChange={(e) => {
                                    const newValue = `${
                                      getNumericAndUnit(paddingBottom).numeric
                                    }${e.target.value}`;
                                    setPaddingBottom(newValue);
                                    handleCustomStyleChange(
                                      "paddingBottom",
                                      newValue
                                    );
                                  }}
                                  style={{
                                    width: "40px",
                                    height: "27px",
                                    padding: "8px 11px 10px 12px",
                                  }}
                                  displayEmpty
                                  IconComponent={() => null}
                                  MenuProps={{ style: { top: "5px" } }}
                                  className="custom-dropdown"
                                >
                                  <MenuItem
                                    value="px"
                                    className="custom-menuitem"
                                  >
                                    px
                                  </MenuItem>
                                  <MenuItem
                                    value="em"
                                    className="custom-menuitem"
                                  >
                                    em
                                  </MenuItem>
                                  <MenuItem
                                    value="%"
                                    className="custom-menuitem"
                                  >
                                    %
                                  </MenuItem>
                                </Select>
                              </span>
                            </div>
                          </div>
                          <div className="rht-info">Bottom</div>
                        </div>
                        <div className="rht-fld-info">
                          <div className="ipubrdr">
                            <div className="rht-fld">
                              <input
                                type="number"
                                value={
                                  getNumericAndUnit(finalStyles.paddingLeft)
                                    .numeric
                                }
                                onChange={(e) => {
                                  const newValue = `${e.target.value}${
                                    getNumericAndUnit(paddingLeft).unit
                                  }`;
                                  setPaddingLeft(newValue);
                                  handleCustomStyleChange(
                                    "paddingLeft",
                                    newValue
                                  );
                                }}
                              />
                              <span className="unit-dropdown">
                                <Select
                                  value={
                                    getNumericAndUnit(finalStyles.paddingLeft)
                                      .unit
                                  }
                                  onChange={(e) => {
                                    const newValue = `${
                                      getNumericAndUnit(paddingLeft).numeric
                                    }${e.target.value}`;
                                    setPaddingLeft(newValue);
                                    handleCustomStyleChange(
                                      "paddingLeft",
                                      newValue
                                    );
                                  }}
                                  style={{
                                    width: "40px",
                                    height: "27px",
                                    padding: "8px 11px 10px 12px",
                                  }}
                                  displayEmpty
                                  IconComponent={() => null}
                                  MenuProps={{ style: { top: "5px" } }}
                                  className="custom-dropdown"
                                >
                                  <MenuItem
                                    value="px"
                                    className="custom-menuitem"
                                  >
                                    px
                                  </MenuItem>
                                  <MenuItem
                                    value="em"
                                    className="custom-menuitem"
                                  >
                                    em
                                  </MenuItem>
                                  <MenuItem
                                    value="%"
                                    className="custom-menuitem"
                                  >
                                    %
                                  </MenuItem>
                                </Select>
                              </span>
                            </div>
                          </div>
                          <div className="rht-info">Left</div>
                        </div>
                      </div>
                    </>
                  </PropertiesAccordion>
                </div>
              </div>
            </div>,
            targetElement
          );
        })()}

      {isSelected &&
        isClient &&
        controlsVisible &&
        rightsideStyletab === "2" &&
        (() => {
          const targetElement = document.getElementById(
            rightsideStyletab + "_tab"
          );
          if (!targetElement) {
            console.error(
              `Target container with id ${rightsideStyletab + "_tab"} not found`
            );
            return null;
          }

          return createPortal(
            <div className="fldsSepertr">
              <div className="border1">
                <div className="tglBtn">
                  <PropertiesAccordion title={"Size"}>
                    <>
                      <div className="rht-flds-wrap">
                        <div className="rht-fld-info">
                          <div className="ipubrdr">
                            <div className="rht-fld">
                              <input
                                type="number"
                                placeholder={width === "auto" ? "auto" : ""}
                                value={
                                  width === "auto"
                                    ? ""
                                    : getNumericAndUnit(finalStyles.width)
                                        .numeric
                                }
                                onChange={(e) => {
                                  const inputNumericValue =
                                    e.target.value.trim();
                                  const newValue =
                                    inputNumericValue === ""
                                      ? ""
                                      : `${inputNumericValue}${
                                          getNumericAndUnit(width).unit
                                        }`;
                                  setWidth(newValue);
                                  handleCustomStyleChange(
                                    "width",
                                    newValue === "" ? "auto" : newValue
                                  );
                                }}
                              />

                              <span className="unit-dropdown">
                                <Select
                                  // value={
                                  //   getNumericAndUnit(finalStyles.width).unit
                                  // }
                                  value={
                                    finalStyles.width === "auto"
                                      ? "auto"
                                      : getNumericAndUnit(finalStyles.width)
                                          .unit
                                  }
                                  onChange={(e) => {
                                    const selectedUnit = e.target.value;
                                    let newNumericValue = "";

                                    if (selectedUnit === "auto") {
                                      newNumericValue = "";
                                      setWidth("auto");
                                    } else {
                                      newNumericValue =
                                        getNumericAndUnit(width).numeric;
                                      setWidth(
                                        `${newNumericValue}${selectedUnit}`
                                      );
                                    }

                                    handleCustomStyleChange(
                                      "width",
                                      selectedUnit === "auto"
                                        ? "auto"
                                        : `${newNumericValue}${selectedUnit}`
                                    );
                                  }}
                                  style={{
                                    width: "40px",
                                    height: "27px",
                                    padding: "9px 11px 10px 12px",
                                  }}
                                  displayEmpty
                                  IconComponent={() => null}
                                  MenuProps={{ style: { top: "5px" } }}
                                  className="custom-dropdown"
                                >
                                  <MenuItem
                                    value="px"
                                    className="custom-menuitem"
                                  >
                                    px
                                  </MenuItem>
                                  <MenuItem
                                    value="em"
                                    className="custom-menuitem"
                                  >
                                    em
                                  </MenuItem>
                                  <MenuItem
                                    value="%"
                                    className="custom-menuitem"
                                  >
                                    %
                                  </MenuItem>
                                  <MenuItem
                                    value="auto"
                                    className="custom-menuitem"
                                  >
                                    auto
                                  </MenuItem>
                                </Select>
                              </span>
                            </div>
                          </div>
                          <div className="rht-info">width</div>
                        </div>

                        <div className="rht-fld-info">
                          <div className="ipubrdr">
                            <div className="rht-fld">
                              <input
                                type="number"
                                placeholder={height === "auto" ? "auto" : ""}
                                value={
                                  height === "auto"
                                    ? ""
                                    : getNumericAndUnit(finalStyles.height)
                                        .numeric
                                }
                                onChange={(e) => {
                                  const inputNumericValue =
                                    e.target.value.trim();
                                  const newValue =
                                    inputNumericValue === ""
                                      ? ""
                                      : `${inputNumericValue}${
                                          getNumericAndUnit(height).unit
                                        }`;
                                  setHeight(newValue);
                                  handleCustomStyleChange(
                                    "height",
                                    newValue === "" ? "auto" : newValue
                                  );
                                }}
                              />

                              <span className="unit-dropdown">
                                <Select
                                  value={
                                    finalStyles.height === "auto"
                                      ? "auto"
                                      : getNumericAndUnit(finalStyles.height)
                                          .unit
                                  }
                                  onChange={(e) => {
                                    const selectedUnit = e.target.value;
                                    let newNumericValue = "";

                                    if (selectedUnit === "auto") {
                                      newNumericValue = "";
                                      setHeight("auto");
                                    } else {
                                      newNumericValue =
                                        getNumericAndUnit(height).numeric;
                                      setHeight(
                                        `${newNumericValue}${selectedUnit}`
                                      );
                                    }

                                    handleCustomStyleChange(
                                      "height",
                                      selectedUnit === "auto"
                                        ? "auto"
                                        : `${newNumericValue}${selectedUnit}`
                                    );
                                  }}
                                  style={{
                                    width: "40px",
                                    height: "27px",
                                    padding: "9px 11px 10px 12px",
                                  }}
                                  displayEmpty
                                  IconComponent={() => null}
                                  MenuProps={{ style: { top: "5px" } }}
                                  className="custom-dropdown"
                                >
                                  <MenuItem
                                    value="px"
                                    className="custom-menuitem"
                                  >
                                    px
                                  </MenuItem>
                                  <MenuItem
                                    value="em"
                                    className="custom-menuitem"
                                  >
                                    em
                                  </MenuItem>
                                  <MenuItem
                                    value="%"
                                    className="custom-menuitem"
                                  >
                                    %
                                  </MenuItem>
                                  <MenuItem
                                    value="auto"
                                    className="custom-menuitem"
                                  >
                                    auto
                                  </MenuItem>
                                </Select>
                              </span>
                            </div>
                          </div>
                          <div className="rht-info">height</div>
                        </div>
                      </div>
                    </>
                    <div className="min-mx-size">
                      {styleType && styleType !== "Divider" && (
                        <Tooltip title="Min & Max Size">
                          <MoreHorizIcon
                            onClick={() => setSizeVisible(!sizeVisible)}
                          />
                        </Tooltip>
                      )}
                    </div>
                    {sizeVisible && styleType !== "Divider" && (
                      <div className="werghn">
                        <>
                          <span className="min-sze">Minimum size</span>
                          <div className="rht-flds-wrap">
                            <div className="rht-fld-info">
                              <div className="ipubrdr">
                                <div className="rht-fld">
                                  <input
                                    type="number"
                                    placeholder={
                                      minWidth === "auto" ? "auto" : ""
                                    }
                                    value={
                                      minWidth === "auto"
                                        ? ""
                                        : getNumericAndUnit(
                                            finalStyles.minWidth
                                          ).numeric
                                    }
                                    onChange={(e) => {
                                      const inputNumericValue =
                                        e.target.value.trim();
                                      const newValue =
                                        inputNumericValue === ""
                                          ? ""
                                          : `${inputNumericValue}${
                                              getNumericAndUnit(minWidth).unit
                                            }`;
                                      setMinWidth(newValue);
                                      handleCustomStyleChange(
                                        "minWidth",
                                        newValue === "" ? "auto" : newValue
                                      );
                                    }}
                                  />

                                  <span className="unit-dropdown">
                                    <Select
                                      // value={
                                      //   getNumericAndUnit(
                                      //     finalStyles.minWidth
                                      //   ).unit
                                      // }

                                      value={
                                        finalStyles.minWidth === "auto"
                                          ? "auto"
                                          : getNumericAndUnit(
                                              finalStyles.minWidth
                                            ).unit
                                      }
                                      onChange={(e) => {
                                        const selectedUnit = e.target.value;
                                        let newNumericValue = "";

                                        if (selectedUnit === "auto") {
                                          newNumericValue = "";
                                          setMinWidth("auto");
                                        } else {
                                          newNumericValue =
                                            getNumericAndUnit(minWidth).numeric;
                                          setMinWidth(
                                            `${newNumericValue}${selectedUnit}`
                                          );
                                        }

                                        handleCustomStyleChange(
                                          "minWidth",
                                          selectedUnit === "auto"
                                            ? "auto"
                                            : `${newNumericValue}${selectedUnit}`
                                        );
                                      }}
                                      style={{
                                        width: "40px",
                                        height: "27px",
                                        padding: "9px 11px 10px 12px",
                                      }}
                                      displayEmpty
                                      IconComponent={() => null}
                                      MenuProps={{ style: { top: "5px" } }}
                                      className="custom-dropdown"
                                    >
                                      <MenuItem
                                        value="px"
                                        className="custom-menuitem"
                                      >
                                        px
                                      </MenuItem>
                                      <MenuItem
                                        value="em"
                                        className="custom-menuitem"
                                      >
                                        em
                                      </MenuItem>
                                      <MenuItem
                                        value="%"
                                        className="custom-menuitem"
                                      >
                                        %
                                      </MenuItem>
                                      <MenuItem
                                        value="auto"
                                        className="custom-menuitem"
                                      >
                                        auto
                                      </MenuItem>
                                    </Select>
                                  </span>
                                </div>
                              </div>
                              <div className="rht-info">Width</div>
                            </div>

                            <div className="rht-fld-info">
                              <div className="ipubrdr">
                                <div className="rht-fld">
                                  <input
                                    type="number"
                                    placeholder={
                                      minHeight === "auto" ? "auto" : ""
                                    }
                                    value={
                                      minHeight === "auto"
                                        ? ""
                                        : getNumericAndUnit(
                                            finalStyles.minHeight
                                          ).numeric
                                    }
                                    onChange={(e) => {
                                      const inputNumericValue =
                                        e.target.value.trim();
                                      const newValue =
                                        inputNumericValue === ""
                                          ? ""
                                          : `${inputNumericValue}${
                                              getNumericAndUnit(minHeight).unit
                                            }`;
                                      setMinHeight(newValue);
                                      handleCustomStyleChange(
                                        "minHeight",
                                        newValue === "" ? "auto" : newValue
                                      );
                                    }}
                                  />

                                  <span className="unit-dropdown">
                                    <Select
                                      value={
                                        finalStyles.minHeight === "auto"
                                          ? "auto"
                                          : getNumericAndUnit(
                                              finalStyles.minHeight
                                            ).unit
                                      }
                                      onChange={(e) => {
                                        const selectedUnit = e.target.value;
                                        let newNumericValue = "";

                                        if (selectedUnit === "auto") {
                                          newNumericValue = "";
                                          setMinHeight("auto");
                                        } else {
                                          newNumericValue =
                                            getNumericAndUnit(
                                              minHeight
                                            ).numeric;
                                          setMinHeight(
                                            `${newNumericValue}${selectedUnit}`
                                          );
                                        }

                                        handleCustomStyleChange(
                                          "minHeight",
                                          selectedUnit === "auto"
                                            ? "auto"
                                            : `${newNumericValue}${selectedUnit}`
                                        );
                                      }}
                                      style={{
                                        width: "40px",
                                        height: "27px",
                                        padding: "9px 11px 10px 12px",
                                      }}
                                      displayEmpty
                                      IconComponent={() => null}
                                      MenuProps={{ style: { top: "5px" } }}
                                      className="custom-dropdown"
                                    >
                                      <MenuItem
                                        value="px"
                                        className="custom-menuitem"
                                      >
                                        px
                                      </MenuItem>
                                      <MenuItem
                                        value="em"
                                        className="custom-menuitem"
                                      >
                                        em
                                      </MenuItem>
                                      <MenuItem
                                        value="%"
                                        className="custom-menuitem"
                                      >
                                        %
                                      </MenuItem>
                                      <MenuItem
                                        value="auto"
                                        className="custom-menuitem"
                                      >
                                        auto
                                      </MenuItem>
                                    </Select>
                                  </span>
                                </div>
                              </div>
                              <div className="rht-info">Height</div>
                            </div>
                          </div>
                        </>
                      </div>
                    )}

                    {sizeVisible &&
                      styleType !== "Slider" &&
                      styleType !== "Divider" && (
                        <>
                          <span className="min-sze">Maximum size</span>
                          <div className="rht-flds-wrap">
                            <div className="rht-fld-info">
                              <div className="ipubrdr">
                                <div className="rht-fld">
                                  <input
                                    type="number"
                                    placeholder={
                                      maxWidth === "auto" ? "auto" : ""
                                    }
                                    value={
                                      maxWidth === "auto"
                                        ? ""
                                        : getNumericAndUnit(maxWidth).numeric
                                    }
                                    onChange={(e) => {
                                      const inputNumericValue =
                                        e.target.value.trim();
                                      const newValue =
                                        inputNumericValue === ""
                                          ? ""
                                          : `${inputNumericValue}${
                                              getNumericAndUnit(maxWidth).unit
                                            }`;
                                      setMaxWidth(newValue);
                                      handleCustomStyleChange(
                                        "maxWidth",
                                        newValue === "" ? "auto" : newValue
                                      );
                                    }}
                                  />

                                  <span className="unit-dropdown">
                                    <Select
                                      value={
                                        finalStyles.maxWidth === "auto"
                                          ? "auto"
                                          : getNumericAndUnit(
                                              finalStyles.maxWidth
                                            ).unit
                                      }
                                      onChange={(e) => {
                                        const selectedUnit = e.target.value;
                                        let newNumericValue = "";

                                        if (selectedUnit === "auto") {
                                          newNumericValue = "";
                                          setMaxWidth("auto");
                                        } else {
                                          newNumericValue =
                                            getNumericAndUnit(maxWidth).numeric;
                                          setMaxWidth(
                                            `${newNumericValue}${selectedUnit}`
                                          );
                                        }

                                        handleCustomStyleChange(
                                          "maxWidth",
                                          selectedUnit === "auto"
                                            ? "auto"
                                            : `${newNumericValue}${selectedUnit}`
                                        );
                                      }}
                                      style={{
                                        width: "40px",
                                        height: "27px",
                                        padding: "9px 11px 10px 12px",
                                      }}
                                      displayEmpty
                                      IconComponent={() => null}
                                      MenuProps={{ style: { top: "5px" } }}
                                      className="custom-dropdown"
                                    >
                                      <MenuItem
                                        value="px"
                                        className="custom-menuitem"
                                      >
                                        px
                                      </MenuItem>
                                      <MenuItem
                                        value="em"
                                        className="custom-menuitem"
                                      >
                                        em
                                      </MenuItem>
                                      <MenuItem
                                        value="%"
                                        className="custom-menuitem"
                                      >
                                        %
                                      </MenuItem>
                                      <MenuItem
                                        value="auto"
                                        className="custom-menuitem"
                                      >
                                        auto
                                      </MenuItem>
                                    </Select>
                                  </span>
                                </div>
                              </div>
                              <div className="rht-info">Width</div>
                            </div>

                            <div className="rht-fld-info">
                              <div className="ipubrdr">
                                <div className="rht-fld">
                                  <input
                                    type="number"
                                    placeholder={
                                      maxHeight === "auto" ? "auto" : ""
                                    }
                                    value={
                                      maxHeight === "auto"
                                        ? ""
                                        : getNumericAndUnit(maxHeight).numeric
                                    }
                                    onChange={(e) => {
                                      const inputNumericValue =
                                        e.target.value.trim();
                                      const newValue =
                                        inputNumericValue === ""
                                          ? ""
                                          : `${inputNumericValue}${
                                              getNumericAndUnit(maxHeight).unit
                                            }`;
                                      setMaxHeight(newValue);
                                      handleCustomStyleChange(
                                        "maxHeight",
                                        newValue === "" ? "auto" : newValue
                                      );
                                    }}
                                  />

                                  <span className="unit-dropdown">
                                    <Select
                                      value={
                                        finalStyles.maxHeight === "auto"
                                          ? "auto"
                                          : getNumericAndUnit(
                                              finalStyles.maxHeight
                                            ).unit
                                      }
                                      onChange={(e) => {
                                        const selectedUnit = e.target.value;
                                        let newNumericValue = "";

                                        if (selectedUnit === "auto") {
                                          newNumericValue = "";
                                          setMaxHeight("auto");
                                        } else {
                                          newNumericValue =
                                            getNumericAndUnit(
                                              maxHeight
                                            ).numeric;
                                          setMaxHeight(
                                            `${newNumericValue}${selectedUnit}`
                                          );
                                        }

                                        handleCustomStyleChange(
                                          "maxHeight",
                                          selectedUnit === "auto"
                                            ? "auto"
                                            : `${newNumericValue}${selectedUnit}`
                                        );
                                      }}
                                      style={{
                                        width: "40px",
                                        height: "27px",
                                        padding: "9px 11px 10px 12px",
                                      }}
                                      displayEmpty
                                      IconComponent={() => null}
                                      MenuProps={{ style: { top: "5px" } }}
                                      className="custom-dropdown"
                                    >
                                      <MenuItem
                                        value="px"
                                        className="custom-menuitem"
                                      >
                                        px
                                      </MenuItem>
                                      <MenuItem
                                        value="em"
                                        className="custom-menuitem"
                                      >
                                        em
                                      </MenuItem>
                                      <MenuItem
                                        value="%"
                                        className="custom-menuitem"
                                      >
                                        %
                                      </MenuItem>
                                      <MenuItem
                                        value="auto"
                                        className="custom-menuitem"
                                      >
                                        auto
                                      </MenuItem>
                                    </Select>
                                  </span>
                                </div>
                              </div>
                              <div className="rht-info">Height</div>
                            </div>
                          </div>
                        </>
                      )}
                  </PropertiesAccordion>
                </div>
              </div>
            </div>,
            targetElement
          );
        })()}

      {isSelected &&
        isClient &&
        controlsVisible &&
        rightsideStyletab === "2" &&
        (() => {
          const targetElement = document.getElementById(
            rightsideStyletab + "_tab"
          );
          if (!targetElement) {
            console.error(
              `Target container with id ${rightsideStyletab + "_tab"} not found`
            );
            return null;
          }

          return createPortal(
            <div className="reset-btn reset-control">
              <button onClick={handleRestore}>Reset Controls</button>
            </div>,
            targetElement
          );
        })()}
    </React.Fragment>
  );
};

export default CustomHtmlTag;
