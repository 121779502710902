import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Paper,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  IconButton,
  Collapse,
} from "@mui/material";
import {
  Search,
  DragIndicator,
  ExpandMore,
  ExpandLess,
  Input as InputIcon,
  Output as OutputIcon,
} from "@mui/icons-material";
import { useReactFlow } from "reactflow";

const VariablePoolForNode = ({ selectedNode }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [expandedGroups, setExpandedGroups] = useState({});
  const { getNodes, getEdges } = useReactFlow();

  // Get all output variables (direction: 1) from all nodes
  const getAllVariables = () => {
    const nodes = getNodes();
    console.log("nodes",nodes)
    return nodes?.flatMap((node) =>
      (node?.data[0]?.params || [])
        .filter((param) => param?.direction === 1)
        .map((param) => ({
          ...param,
          nodeId: node?.id,
          nodeTitle:
            node?.data[0]?.title ?? node?.data[0]?.name ?? "Unknown Node",
        }))
    );
  };

  // Group variables by node title
  const groupVariables = (variables) => {
    console.log("variables ", variables);
    return variables.reduce((acc, variable) => {
      const nodeTitle = variable?.nodeTitle || "Unknown Node";
      if (!acc[nodeTitle]) acc[nodeTitle] = [];
      acc[nodeTitle].push(variable);
      return acc;
    }, {});
  };

  // Filter variables based on search
  const filterVariables = (variables) => {
    return variables?.filter(
      (variable) =>
        variable?.paramName
          ?.toLowerCase()
          ?.includes(searchTerm?.toLowerCase()) ||
        variable?.nodeTitle?.toLowerCase()?.includes(searchTerm?.toLowerCase())
    );
  };

  // Get all parent nodes in the path up to root
  const getParentPath = (nodeId) => {
    const edges = getEdges();
    const parentPath = new Set();

    const findParents = (currentId) => {
      const parentEdges = edges.filter((edge) => edge.target === currentId);
      parentEdges.forEach((edge) => {
        parentPath.add(edge.source);
        findParents(edge.source);
      });
    };

    findParents(nodeId);
    return Array.from(parentPath);
  };
  // Determine if a variable is available for connection based on parent path
  const isVariableAvailableForConnection = (variable) => {
    if (!selectedNode) return false;

    // Get all parent nodes in the path
    const parentNodes = getParentPath(selectedNode.id);

    // Check if the variable's node is in the parent path
    return parentNodes.includes(variable.nodeId);
  };

  const allVariables = getAllVariables();

  const unavailableVariables = allVariables?.filter(
    (v) => !isVariableAvailableForConnection(v)
  );
  const availableVariables = allVariables?.filter(
    isVariableAvailableForConnection
  );
  //   const filteredVariables = filterVariables(allVariables);

  //   const unavailableVariables = filteredVariables?.filter(
  //     (v) => !isVariableAvailableForConnection(v)
  //   );
  //   const availableVariables = filteredVariables?.filter(
  //     (v) => !isVariableAvailableForConnection(v)
  //   );
  console.log("allVariables ", allVariables);
  console.log("unavailableVariables ", unavailableVariables);
  console.log("availableVariables ", availableVariables);

  const groupedUnAvailable = groupVariables(unavailableVariables);
  const groupedAvailable = groupVariables(availableVariables);

  console.log("groupedAvailable",groupedAvailable)


  const VariableGroup = ({ title, variables, groupKey }) => (
    <Box sx={{ mb: 2 }}>
      <ListItem
        button
        onClick={() =>
          setExpandedGroups((prev) => ({
            ...prev,
            [groupKey]: !prev[groupKey],
          }))
        }
      >
        <ListItemIcon>
          {variables[0]?.direction === 1 ? <OutputIcon /> : <InputIcon />}
        </ListItemIcon>
        <ListItemText
          primary={`${title} (${variables?.length})`}
          sx={{
            "& .MuiTypography-root": {
              fontWeight: "bold",
              color: getTypeColor(title),
            },
          }}
        />
        {expandedGroups[groupKey] ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={expandedGroups[groupKey]} timeout="auto">
        <List>
          {variables.map((variable, index) => (
            <ListItem
              key={`${variable?.nodeId}-${variable?.taskParamID}`}
              sx={{
                pl: 4,
                cursor: "grab",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.04)",
                },
              }}
              draggable
              onDragStart={(e) => {
                e.dataTransfer.setData(
                  "application/json",
                  JSON.stringify(variable)
                );
              }}
            >
              <DragIndicator sx={{ mr: 1, opacity: 0.5 }} />
              <ListItemText
                primary={variable.paramName}
                secondary={variable.nodeTitle}
                sx={{
                  "& .MuiTypography-root": {
                    fontSize: "0.9rem",
                  },
                }}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </Box>
  );

  // Get color based on variable type
  const getTypeColor = (type) => {
    const colors = {
      string: "#2196f3",
      number: "#4caf50",
      boolean: "#ff9800",
      unknown: "#9e9e9e",
    };
    return colors[type] || colors.unknown;
  };

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Variable Pool
      </Typography>
      {/* <TextField
        fullWidth
        size="small"
        placeholder="Search variables..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        InputProps={{
          startAdornment: <Search sx={{ mr: 1, opacity: 0.5 }} />,
        }}
        sx={{ mb: 2 }}
      /> */}

      <Paper sx={{ mb: 2, p: 2 }}>
        <Typography variant="subtitle2" sx={{ mb: 1 }}>
          Unavailable Variables
        </Typography>
        {Object.entries(groupedUnAvailable).map(([type, variables]) => (
          <VariableGroup
            key={type}
            title={type}
            variables={variables}
            groupKey={`unavailable-${type}`}
          />
        ))}
        {Object.keys(groupedUnAvailable)?.length === 0 && (
          <Typography variant="body2" color="text.secondary">
            No unavailable variables
          </Typography>
        )}
      </Paper>

      <Paper sx={{ p: 2 }}>
        <Typography variant="subtitle2" sx={{ mb: 1 }}>
          Available Variables
        </Typography>
        {Object.entries(groupedAvailable)?.map(([type, variables]) => (
          <VariableGroup
            key={type}
            title={type}
            variables={variables}
            groupKey={`available-${type}`}
          />
        ))}
        {Object.keys(groupedAvailable)?.length === 0 && (
          <Typography variant="body2" color="text.secondary">
            No available variables
          </Typography>
        )}
      </Paper>
    </Box>
  );
};

export default VariablePoolForNode;
