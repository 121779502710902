import React, { useEffect, useState, useMemo } from "react";
import PropertiesAccordion from "../../../reusableComponents/PropertiesAccordion";
import FontDropDown from "./FontDropDown";
import DividerDropDown from "./DividerDropDown";
import IconBox from "../IconBox";
import CaptionIconDropDown from "./CaptionIconDropDown";
import {
  PiAlignRightSimpleBold,
  PiAlignLeftSimpleDuotone,
} from "react-icons/pi";
import { BsAlignCenter } from "react-icons/bs";

function CaptionDropDowns({
  selectedItemID,
  newProperties,
  handlePropertyChange,
  tempNodes,
  selectedTool,
}) {
  const nodeId = selectedTool?.tasktypeid?.toString();

  // Retrieve default or special properties based on node ID
  const specialProperties = useMemo(
    () =>
      newProperties?.Nodes?.find((node) => node?.id?.toString() === nodeId) ||
      null,
    [newProperties?.Nodes, nodeId]
  );

  console.log("specialProperties", specialProperties);

  const properties = useMemo(
    () =>
      specialProperties
        ? specialProperties.customstyle
        : tempNodes[selectedItemID]
          ? tempNodes[selectedItemID]
          : newProperties[selectedTool?.category || "<all>"] || {},
    [specialProperties, newProperties, selectedTool, tempNodes, selectedItemID]
  );

  console.log("properties", properties);

  const [captionHorizontalAlignment, setCaptionHorizontalAlignment] = useState({
    left: properties?.caption.justifyContent === "start" ? 1 : 0,
    horizontalCenter: properties?.caption.justifyContent === "center" ? 1 : 0,
    right: properties?.caption.justifyContent === "end" ? 1 : 0,
  });
  const [captionColor, setCaptionColor] = useState({
    backgroundColor: "",
    color: "",
  });
  const handleCaptionAlignmentChange = (name, value) => {
    // Update the appropriate state variable based on the name passed
    if (name === "start" || name === "center" || name === "end") {
      setCaptionHorizontalAlignment({
        left: name === "start" ? value : 0,
        horizontalCenter: name === "center" ? value : 0,
        right: name === "end" ? value : 0,
      });
      handlePropertyChange(selectedTool, "caption", "justifyContent", name);
    }
  };

  const handleCaptionColorChange = (name, value) => {
    setCaptionColor({
      ...captionColor,
      [name]: value,
    });
    if (name === "backgroundColor") {
      handlePropertyChange("Caption_Background", value);
    } else {
      handlePropertyChange("Caption_Color", value);
    }
  };
  const handleBgColorClear = () => {
    setCaptionColor({
      ...captionColor,
      backgroundColor: "",
    });
    handlePropertyChange("Caption_Background", "transparent");
  };

  return (
    <>
      <PropertiesAccordion isOpen={false} title={"Caption Alignment"}>
        <IconBox
          handleChange={handleCaptionAlignmentChange}
          buttonList={[
            {
              icon: <PiAlignLeftSimpleDuotone />,
              name: "start",
              value: captionHorizontalAlignment.left,
            },
            {
              icon: <BsAlignCenter />,
              name: "center",
              value: captionHorizontalAlignment.horizontalCenter,
            },
            {
              icon: <PiAlignRightSimpleBold />,
              name: "end",
              value: captionHorizontalAlignment.right,
            },
          ]}
        />
      </PropertiesAccordion>
      <PropertiesAccordion isOpen={false} title={"Caption Font"}>
        <FontDropDown
          properties={properties}
          selectedTool={selectedTool}
          handlePropertyChange={handlePropertyChange}
          propSection="caption"
        />
      </PropertiesAccordion>
      <PropertiesAccordion isOpen={false} title={"Caption Divider"}>
        <DividerDropDown
          properties={properties}
          selectedTool={selectedTool}
          handlePropertyChange={handlePropertyChange}
          propSection="captionDivider"
        />
      </PropertiesAccordion>
      <PropertiesAccordion isOpen={false} title={"Caption Color"}>
        <div className="backgrounclr ">
          Background Color
          <div>
            <input
              type="color"
              name="backgroundColor"
              value={properties?.caption.backgroundColor}
              onChange={(e) => {
                handlePropertyChange(
                  selectedTool,
                  "caption",
                  "backgroundColor",
                  e.target.value
                );
              }}
            />
            <div className="iconset clearColorWrap">
              <div
                className="clearColor"
                onClick={(e) => {
                  handlePropertyChange(
                    selectedTool,
                    "caption",
                    "backgroundColor",
                    "transparent"
                  );
                }}
              >
                <span className="crsLinelft"></span>
                <span className="crsLinerht"></span>
                <span className="tooltiptext">Transparent</span>
              </div>
            </div>
          </div>
        </div>
        <div className="backgrounclr ">
          Color
          <input
            type="color"
            name="color"
            value={properties?.caption.color}
            onChange={(e) => {
              handlePropertyChange(
                selectedTool,
                "caption",
                "color",
                e.target.value
              );
            }}
          />
        </div>
      </PropertiesAccordion>
      <PropertiesAccordion isOpen={false} title={"Caption Icon"}>
        <CaptionIconDropDown
          properties={properties}
          selectedTool={selectedTool}
          handlePropertyChange={handlePropertyChange}
        />
      </PropertiesAccordion>
    </>
  );
}

export default CaptionDropDowns;
