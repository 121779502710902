import React, { useMemo, useState } from "react";

function Preview({ propertyData, tools, selectedItemID, tempNodes, selected }) {
  console.log("propertyData", propertyData);
  // console.log("propertyData", tools);
  // console.log("propertyData", selectedItemID);
  // console.log("propertyData", tempNodes);

  // Find the selected tool by its ID
  const selectedTool = tools.find(
    (tool) => tool?.tasktypeid?.toString() === selectedItemID?.toString()
  );
  const [isExpanded, setIsExpanded] = useState(false);

  const isLongDescription =
    selectedTool?.description && selectedTool.description.length > 100;

  const toggleDescription = () => {
    setIsExpanded((prev) => !prev);
  };
  const nodeId = selectedTool?.tasktypeid?.toString();

  console.log("selectedTool", tools);
  // Retrieve default or special properties based on node ID
  const specialProperties = useMemo(
    () =>
      propertyData?.Nodes?.find((node) => node.id.toString() === nodeId) ||
      null,
    [propertyData?.Nodes, nodeId]
  );

  // const properties = useMemo(
  //   () =>
  //     specialProperties
  //       ? specialProperties.customstyle
  //       : tempNodes[selectedItemID]
  //         ? tempNodes[selectedItemID]
  //         : propertyData[selectedTool?.category || "<all>"] || {},
  //   [specialProperties, propertyData, selectedTool, tempNodes, selectedItemID]
  // );

  // Set node dimensions with fallbacks

  const properties = useMemo(() => {
    if (specialProperties && selectedTool?.category === "Nodes") {
      console.log("Returning specialProperties.customstyle");
      return specialProperties.customstyle;
    }
    if (tempNodes[selectedItemID]) {
      return tempNodes[selectedItemID];
    }
    return propertyData[selectedTool?.category || "<all>"] || {};
  }, [
    specialProperties,
    propertyData,
    selectedTool,
    tempNodes,
    selectedItemID,
  ]);

  console.log("Final properties: ", properties);

  const nodeHeight = useMemo(
    () => (selectedTool?.nodeheight ? `${selectedTool.nodeheight}px` : "auto"),
    [selectedTool?.nodeheight]
  );
  const nodeWidth = useMemo(
    () => (selectedTool?.nodewidth ? `${selectedTool.nodewidth}px` : "240px"),
    [selectedTool?.nodewidth]
  );

  const IconElement = React.memo(
    ({ iconSize, displayBootstrapIcon, displayUrlIcon, IconLink }) => (
      <>
        {displayBootstrapIcon === "flex" && (
          <i
            className={`glyphicon glyphicon-${IconLink}`}
            style={{ fontSize: iconSize, display: displayBootstrapIcon }}
          />
        )}
        {displayUrlIcon === "flex" && IconLink && (
          <img
            src={IconLink}
            alt="icon"
            style={{
              width: iconSize,
              height: iconSize,
              display: displayUrlIcon,
            }}
          />
        )}
      </>
    )
  );

  // Memoized icon properties
  const iconProps = useMemo(() => {
    const { IconSize, IconType, IconLink } = properties?.caption || {};
    // properties.caption.display = "flex";
    return {
      iconSize:
        IconSize === "large" ? "48px" : IconSize === "medium" ? "36px" : "24px",
      displayBootstrapIcon: IconType === "Bootstrap" ? "flex" : "none",
      displayUrlIcon: IconType === "Url" ? "flex" : "none",
      IconLink,
    };
  }, [properties?.caption]);
  const parsedFontWeight =
    properties?.body?.fontWeight?.bold === 1 ? "bold" : "normal";
  const parsedPadding = properties?.body?.padding
    ? JSON.parse(properties.body.padding)
    : null;

  const paddingtop = parsedPadding?.top + parsedPadding?.topUnit;
  const paddingRight = parsedPadding?.right + parsedPadding?.rightUnit;
  const paddingBotttom = parsedPadding?.bottom + parsedPadding?.bottomUnit;
  const paddingleft =
    parsedPadding?.left + parsedPadding?.leftUnit ? "10px" : "10px";

  console.log("properties?.node", properties);
  // Render
  return (
    <>
      {/* NODE */}
      <div
        style={{
          padding: "4px",
          border: selected ? "2px solid #1565C0" : "2px solid #A9A9A9",
          borderRadius: "10px",
        }}
      >
        <div
          style={{
            ...(properties?.node || {}),
            height: nodeHeight,
            width: nodeWidth,
            borderStyle: "solid",
            padding: "5px",
          }}
        >
          {/* CAPTION */}
          <div
            className="caption-inside-text"
            style={{
              ...(properties?.caption || {}),
              display: "flex",
              borderRadius: properties?.node?.borderRadius ?? "0px",
              flexDirection: "column",
            }}
          >
            {/* {properties.caption.IconPosition === "flex-start" && ( */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "7px",
                ...(properties?.caption || {}),
              }}
            >
              {properties?.caption?.IconPosition === "Left" && (
                <div
                  style={{
                    ...properties.caption,
                    backgroundColor: properties.caption.IconbackgroundColor,
                    margin: properties.caption.Iconmargin,
                    borderRadius: properties.caption.IconborderRadius,
                    padding: properties.caption.Iconpadding,
                  }}
                >
                  <IconElement {...iconProps} />
                </div>
              )}

              <div style={{ ...properties?.caption }}>
                <div style={{ marginLeft: "3px" }}>
                  <div style={{ fontSize: "11px" }}>
                    {selectedTool?.category && selectedTool.category}
                  </div>
                  <div style={properties.caption}>
                    {selectedTool?.title
                      ? selectedTool.title
                      : selectedTool?.execname && selectedTool.execname}
                  </div>
                </div>
              </div>
              {properties?.caption?.IconPosition === "Right" && (
                <div
                  style={{
                    ...properties.caption,
                    backgroundColor: properties.caption.IconbackgroundColor,
                    margin: properties.caption.Iconmargin,
                    borderRadius: properties.caption.IconborderRadius,
                    padding: properties.caption.Iconpadding,
                  }}
                >
                  <IconElement {...iconProps} />
                </div>
              )}
            </div>
          </div>

          {/* CAPTION DIVIDER */}
          {selectedTool?.description && selectedTool.description.length > 0 && (
            <div
              style={{
                ...(properties?.captionDivider || {}),
                borderStyle: properties?.captionDivider ? "solid" : "none",
              }}
            />
          )}

          {/* BODY */}
          {/* <div style={properties?.body || {}}> */}
          <div
            style={{
              ...(properties?.body || {}),
              borderRadius: `0px 0px ${properties?.node?.borderRadius} ${properties?.node?.borderRadius}`,
              fontWeight: parsedFontWeight,
              padding: `${paddingtop} ${paddingRight} ${paddingBotttom} ${paddingleft}`,
            }}
          >
            {/* <div style={properties.nodedescription}> */}
            {selectedTool?.description && (
              <div>
                {isExpanded || !isLongDescription
                  ? selectedTool.description
                  : `${selectedTool.description.substring(0, 100)}...`}
                {isLongDescription && (
                  <span
                    onClick={toggleDescription}
                    style={{ cursor: "pointer", color: "#efefef" }}
                  >
                    {isExpanded ? " show less" : " more"}
                  </span>
                )}
              </div>
            )}
            {/* </div> */}
          </div>

          {/* LINK DIVIDER */}
          {selectedTool?.description && selectedTool.description.length > 0 && (
            <div
              style={{
                ...(properties?.linkDivider || {}),
                borderStyle: properties?.linkDivider ? "solid" : "none",
              }}
            />
          )}
          {/* <div
          style={{
            display: "flex",
            ...(properties?.link || {}),
          }}
        >
          link
        </div> */}
        </div>
      </div>
    </>
  );
}

export default Preview;
