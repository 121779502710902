import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppState } from "./AppStateProvider ";
import { Theme } from "../Components/IncludeFile/Theme";
import Button from "@mui/material/Button";

import NavBar from "../Components/navbarComponents/NavBar";
import CreateCards from "./CreateCards";
import ViewCardInfo from "./ViewCardInfo";
import { useMenu } from "../reusableComponents/menuUtils";
import CreateInfo from "./CreateInfo";
import AddTemplate from "./AddTemplate";
import { loadLoginData } from "../apiCalls/DiagramsAPI";
import { toast } from "react-toastify";
import ErrorBanner from "../Error/ErrorBanner";
import { ReadArray, ReadArrayAll } from "../Components/Api/ApiCalls";

const debounce = (func, delay) => {
  let timeoutId;
  return function (...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const PopUpRenderer = () => {
  const [currentComponent, setCurrentComponent] = useState("1");
  const [selectedItem, setSelectedItem] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [ArrayName, setarrayName] = useState("");
  const [description, setdescription] = useState("");
  const [category, setcategory] = useState("");
  const [openDiscoverEdit, setOpenDiscoverEdit] = useState(false);

  const { appState, setArrayData } = useAppState();

  const { isMenuOpenleft, toggleMenu } = useMenu();
  const authData = loadLoginData();

  const [data, setData] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [openCreateInfo, setOpenCreateInfo] = useState(false);
  const [openViewCreateInfo, setOpenViewCreateInfo] = useState(false);
  const [openDiscoverPage, setOpenDiscoverPage] = useState(false);
    const [error, setError] = useState(null);

    const [ReadAllData ,setReadAllData] = React.useState([]);
    const [defaultStyle, setDefaultStyle] = React.useState([]);



  const location = useLocation();


  const handlePopup = () => {
    setOpenCreateInfo(!openCreateInfo);
    setOpenDiscoverEdit(!openDiscoverEdit);
  };

  const handlePopupView = () => {
    setOpenViewCreateInfo(!openViewCreateInfo);
  };

  async function fetchData() {
    const scriptElement = document.createElement("script");
    scriptElement.src = `ToolsJSON.js?v=${Date.now()}`;

    scriptElement.onload = () => {
      const fetchedData = window.MyVar || [];
      setData(fetchedData);
      setFetched(true);
    };
    document.head.appendChild(scriptElement);
  }

  const handleEditDiagram = async () => {
    await fetchData();
    if (fetched) {
    }
  };

  const handleDetails = (item) => {
    if (item !== -1) setSelectedItem(item);
    else {
      setSelectedItem(null);
    }
    setCurrentComponent("2");
  };

  const renderComponent = (currentComponent) => {
    switch (currentComponent) {
      case "1":
        return (
          <CreateCards
            handleDetails={handleDetails}
            handleEditDiagram={handleEditDiagram}
            searchQuery={searchQuery}
            description={description}
            category={category}
            handleClick={handleClick}
            isMenuOpenleft={isMenuOpenleft}
            setOpenCreateInfo={setOpenCreateInfo}
            openCreateInfo={openCreateInfo}
            setOpenViewCreateInfo={setOpenViewCreateInfo}
            openViewCreateInfo={openViewCreateInfo}
            isDeleteConfirmation={isDeleteConfirmation}
            DeleteConfirmation={DeleteConfirmation}
            openDiscoverPage={openDiscoverPage}
            setOpenDiscoverPage={setOpenDiscoverPage}
            authData={authData}
            ReadAllData={ReadAllData}
            defaultStyle={defaultStyle}
            error = {error}
            setError = {setError}
          />
        );
      case "2":
        return (
          <ViewCardInfo
            setOpenViewCreateInfo={setOpenViewCreateInfo}
            openViewCreateInfo={openViewCreateInfo}
            openDiscoverPage={openDiscoverPage}
            setOpenDiscoverPage={setOpenDiscoverPage}
            authData={authData}
            ReadAllData={ReadAllData}
            error = {error}
            setError = {setError}
          />
        );
      default:
        return null;
    }
  };

  const handleDiscoverPopupView = () => {
    setOpenDiscoverPage(!openDiscoverPage);
  };

  const navigate = useNavigate();

  const handleClick = () => {
    setArrayData(ArrayName, description, category);
    setOpen(true);
    setPopupVisible(true);
  };


  
  const handleSearch = (e) => {
    e.preventDefault(); 
    const newQuery = e.target.value;
    setSearchQuery(newQuery);
  };
 
  const [IsAddTemplate, setAddTemplate] = useState(false);

  const handleAddTemplate = () => {
    setAddTemplate(true);
  };

  const rightButtons = [
    {
      icon: Theme.Add,
      text: "JSON Form",
      color: "secondary",
      handleClick: () => {
        handleAddTemplate();
      },
    },
    {
      icon: Theme.GoBack,
      text: "Back",
      color: "secondary",
      handleClick: () => {
        handleBack();
      },
    },
  ];

  const handleBack = () => {
    navigate("/Landing");
  };

  const [isDeleteConfirmation, setIsDeleteConfirmation] = useState(false);
  const [arrayNames, setArrayNames] = useState([]);
  const [res, setRes] = useState([]);

  const DeleteConfirmation = () => {
    setIsDeleteConfirmation(false);
  };



  React.useEffect(() => {
    const fetchData = async () => {
      if (!authData || !authData.accessKey) {
        navigate("/");
        sessionStorage.clear();
        return;
      }
  
      try {
        // First API call
        const apiResponse = await ReadArrayAll(authData.accessKey);
        setReadAllData(apiResponse);
  
        // Finding the template object with IsTemplate === 3
        const templateObject = apiResponse.find((item) => item.IsTemplate === 3);
        if (templateObject) {
          // Second API call for the template with IsTemplate === 3
          const jsonData = await ReadArray(templateObject.ArID, authData.accessKey);
          const fetchedData = jsonData[0].Source;
          setDefaultStyle(fetchedData);
        }
  
        // Filtering items with IsTemplate === 2
        const filteredData = apiResponse.filter((item) => item.IsTemplate === 2);
        const filteredNames = filteredData.map((item) => item.ArrayName);
  
        // Updating state
        setRes(filteredData);
        setArrayNames(filteredNames);
  
      } catch (error) {
        console.error("Error during request:", error);
        toast.error("Error during request.");
  
        setError("An error occurred during data fetching.");
        localStorage.setItem("error", "An error occurred during data fetching.");
        localStorage.setItem("navigate", "/Landing");
      } finally {
    
      }
    };
  
    fetchData();
  }, []);
  


  return (
    <div className="popup-form">
      <>
        <NavBar
          searchQuery={searchQuery}
          handleSearch={handleSearch}
          isSearchBar={true}
          rightButtons={rightButtons}
          title="Choose Form"
          isMenuOpenleft={true}
          toggleMenu={toggleMenu}
        />

        <div id="pop-render-form">
          {renderComponent(currentComponent)}

          {currentComponent === "2" && (
            <div
              style={{
                padding: "1rem",
                flex: "0 0 auto",
                display: "flex",
              }}
            >
              <Button onClick={handleBack} sx={{ fontSize: "1rem" }} fullWidth>
                Back
              </Button>
              <Button sx={{ fontSize: "1rem" }} variant="contained" fullWidth>
                Next
              </Button>
            </div>
          )}

          {openCreateInfo && (
            <CreateInfo
              openCreateInfo={openCreateInfo}
              handlePopup={handlePopup}
              handleBack={handleBack}
              // handleNext={handleNext}
              authData={authData}
              defaultStyle={defaultStyle}
              ReadAllData={ReadAllData}
              error = {error}
              setError = {setError}
            />
          )}

          {openViewCreateInfo && (
            <ViewCardInfo
              openViewCreateInfo={openViewCreateInfo}
              openDiscoverPage={openDiscoverPage}
              handlePopupView={handlePopupView}
              handleDiscoverPopupView={handleDiscoverPopupView}
              handleBack={handleBack}
              // handleNext={handleNext}
              authData={authData}
              arrayNames={arrayNames}
              res={res}
              defaultStyle={defaultStyle}
              ReadAllData={ReadAllData}
              error = {error}
              setError = {setError}
            />
          )}
          {IsAddTemplate && (
            <AddTemplate
              IsAddTemplate={IsAddTemplate}
              setAddTemplate={setAddTemplate}
              arrayNames={arrayNames}
              res={res}
              defaultStyle={defaultStyle}
              ReadAllData={ReadAllData}
              error = {error}
              setError = {setError}
            />
          )}
        </div>
        {error && <ErrorBanner />}
      </>
    </div>
  );
};

export default PopUpRenderer;
