import { useCallback } from "react";
import { useStore, getBezierPath, EdgeText, BaseEdge } from "reactflow";

import { getEdgeParams } from "./utils.js";

function FloatingEdge({
  id,
  source,
  target,
  animated,
  markerStart,
  markerEnd,
  style,
  label,
  labelStyle,
  labelBgStyle,
  ...props
}) {
  const sourceNode = useStore(
    useCallback((store) => store.nodeInternals.get(source), [source])
  );
  const targetNode = useStore(
    useCallback((store) => store.nodeInternals.get(target), [target])
  );

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(
    sourceNode,
    targetNode
  );

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty,
    interactionWidth: 30,
  });
  // console.log("style ", style);
  // console.log("markerEnd ", markerEnd);
  return (
    <>
      <BaseEdge
        path={edgePath}
        animated={true}
        markerStart={markerStart}
        markerEnd={markerEnd}
        style={{
          ...style,
          strokeWidth: style.strokeWidth ?? 2,
          strokeDasharray: animated ? "5,5" : "0",
          animation: animated && "dashedMove 30s linear infinite",
        }}
        // labelX={(sx + tx) / 2}
        // labelY={(sy + ty) / 2}
        labelX={labelX}
        labelY={labelY}
        label={label}
        labelStyle={{ fill: "black", ...labelStyle }}
        labelBgStyle={{ ...labelBgStyle }}
        labelShowBg
        labelBgPadding={[2, 4]}
        labelBgBorderRadius={2}
        {...props}
      />
      {animated && (
        <style>
          {`
          @keyframes dashedMove {
            from {
              stroke-dashoffset: 0;
            }
            to {
              stroke-dashoffset: -500;
            }
          }
        `}
        </style>
      )}
    </>
  );
  // return (
  //   <>
  //     <path
  //       id={id}
  //       className="react-flow__edge-path"
  //       d={edgePath}
  //       markerStart={markerStart}
  //       markerEnd={markerEnd}
  //       style={{
  //         ...style,
  //         strokeWidth: 2,
  //         strokeDasharray: style?.strokeDasharray || "0",
  //       }}
  //       // style={style}
  //       // markerStart: {
  //       //   type: MarkerType.Arrow, // or any other type you want
  //       //   color: '#FF0072', // customize color if needed
  //       // },
  //     />
  //     {label && (
  //       <EdgeText
  //         x={(sx + tx) / 2}
  //         y={(sy + ty) / 2}
  //         label={label}
  //         labelStyle={{ fill: "black", ...labelStyle }}
  //         labelBgStyle={{ ...labelBgStyle }}
  //         labelShowBg
  //         labelBgPadding={[2, 4]}
  //         labelBgBorderRadius={2}
  //       />
  //     )}
  //   </>
  // );
}

export default FloatingEdge;
