import { useCallback } from "react";
import {
  createRequestBody,
  postResource,
  getAccessKey,
  updateDataWithUserAndDates,
  UpdateDiagHeader,
  loadLoginData,
} from "../apiCalls/DiagramsAPI";
import { useReactFlow } from "reactflow";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { Create } from "@mui/icons-material";
import { getFormattedLocalDateTime } from "./ModifiedDate";
import { defaultCanvas } from "../data/SaveAndReadJSONData";
import { defaultEdgeOptions } from "../data/defaultEdgeOptions";
// export const useSave = (flowKey, rfInstance) => {
//   return useCallback(() => {
//     if (rfInstance) {
//       let flow = rfInstance.toObject();
//       // Temporarily saving to local storage
//       localStorage.setItem(flowKey, JSON.stringify(flow));
//     }
//   }, [rfInstance]);
// };

// export const useSave = (
//   diagram,
//   rfInstance,
//   setDiagram,
//   diagramDetails,
//   region
// ) => {
//   return useCallback(async () => {
//     if (rfInstance) {
//       const flow = rfInstance.toObject();

//       const diagID = diagram.diagID;

//       if (region.toLowerCase() === "ai ui") {
//         try {
//           const accessKey = getAccessKey();
//           const saveResponse = await postResource(
//             "PostQuickInfo",
//             createRequestBody({
//               accessKey: accessKey,
//               guid: `${window.APIProvider.recordPath.UpdateProject}`,
//               param1: "",
//               param2: "",
//               param3: "",
//               param4: "",
//               recordSet: "",
//               body: JSON.stringify([
//                 {
//                   ProjectID: diagID,
//                   description: JSON.stringify(diagramDetails),
//                 },
//               ]),
//             }
//             )
//           );
//         } catch (error) {
//           console.error("Error saving data:", error);
//           // Optionally, you could add additional error handling here,
//           // such as showing a toast notification or updating some error state.
//         }
//       }

//       // Prepare diagram data
//       const diagramData = {
//         DiagID: diagram.diagID,
//         DiagTempID: diagram.diagTempID,
//         Title: diagram.title,
//         Description: diagram.explanation,
//         Nodes: JSON.stringify(flow.nodes),
//         Edges: JSON.stringify(flow.edges),
//         ViewPort: JSON.stringify(flow.viewport),
//       };
//       // Optionally update the diagram state here, if needed
//       setDiagram({
//         diagTempID: diagram.diagTempID,
//         diagID: diagram.diagID,
//         name: diagram.name,
//         title: diagram.title,
//         explanation: diagram.explanation,
//         notes: diagram.notes,
//         showMinMap: diagram.showMinMap,
//         layout: diagram.layout,
//         nodes: JSON.stringify(flow.nodes),
//         edges: JSON.stringify(flow.edges),
//         viewPort: JSON.stringify(flow.viewport),
//       });
//       const diagramInfo = {
//         diagTempID: diagram.diagTempID,
//         diagID: diagram.diagID,
//         name: diagram.name,
//         title: diagram.title,
//         explanation: diagram.explanation,
//         notes: diagram.notes,
//         showMinMap: diagram.showMinMap,
//         layout: diagram.layout,
//         nodes: JSON.stringify(flow.nodes),
//         edges: JSON.stringify(flow.edges),
//         viewPort: JSON.stringify(flow.viewport),
//       };
//       localStorage.setItem("diagramInfo", JSON.stringify(diagramInfo));
//       // localStorage.setItem("userEmail", JSON.stringify(value));

//       // Call UpdateDiagHeader with diagramData
//       await UpdateDiagHeader([diagramData])
//         .then(() => {
//           toast.success("Diagram header updated successfully.");
//         })
//         .catch((error) => {
//           toast.error("Error updating diagram header:", error);
//         });
//     }
//   }, [diagram, rfInstance, diagramDetails, region]);
// };
export const useRestore = (
  diagram,
  setNodes,
  setEdges,
  setViewport,
  getInitialNodes,
  getInitialEdges,
  region
) => {
  return useCallback(() => {
    const restoreFlow = async () => {
      if (region.toLowerCase() === "ai ui") {
        const initialNodes = getInitialNodes();
        const initialEdges = getInitialEdges();
        setNodes(initialNodes);
        setEdges(initialEdges);
      } else {
        // Temporarily restoring from local storage
        const initialNodes =
          diagram.nodes !== "" ? JSON.parse(diagram.nodes) : [];
        const initialEdges =
          diagram.nodes !== "" ? JSON.parse(diagram.edges) : [];
        const initialViewPort =
          diagram.nodes !== ""
            ? JSON.parse(diagram.viewPort)
            : { x: 0, y: 0, zoom: 1 };
        setNodes(initialNodes);
        setEdges(initialEdges);
        setViewport(initialViewPort);
      }
    };

    restoreFlow();
  }, [diagram, setNodes, setEdges, setViewport]);
};

export const useSave = (
  diagram,
  rfInstance,
  currentDiagramData,
  region,
  getBgColor,
  showMinMap,
  layout,
  isLeftSideBarClosedRef
) => {
  const authData = loadLoginData();
  const accessKey = authData?.accessKey || "";

  return useCallback(async () => {
    if (rfInstance) {
      const flow = rfInstance.toObject();
      const bgColor = getBgColor();
      const isLeftSideBarClosed = isLeftSideBarClosedRef();

      if (region.toLowerCase() === "ai ui") {
        // Create placement array from current flow
        const placement = flow.nodes.map((node, index) => {
          const existingPlacement = currentDiagramData[0]?.placement?.find(
            (p) => p.nodeid === node.id
          );

          return {
            nodeid: node.id,
            x: node.position.x,
            y: node.position.y,
            width: parseInt(node.width) || existingPlacement?.width || 300,
            height: parseInt(node.height) || existingPlacement?.height || 100,
            order: index,
          };
        });

        // Update canvas information
        const canvas = {
          x: flow.viewport.x,
          y: flow.viewport.y,
          zoom: flow.viewport.zoom,
          showMinMap: showMinMap,
          layout: layout,
          background: bgColor,
          leftMenuHide: isLeftSideBarClosed,
        };

        // Process edges to include style and animation
        const connectors = flow.edges.map((edge) => ({
          type: edge.type ?? "floating",
          markerEnd: edge.markerEnd,
          markerStart: edge.markerStart,
          style: edge.style,
          animated: edge.animated ?? true,
          id: edge.id,
          source: edge.source,
          target: edge.target,
        }));

        // Get the details array from current diagram data
        // const existingDetails = currentDiagramData[0]?.details || [];
        // Update details with current flow information
        const details = flow.nodes.map((node) => {
          // Find corresponding flow node
          const matchingDetail = currentDiagramData[0]?.details.find(
            (detail) => node.id === detail.nodeid
          );
          // Find edges where this node is the target
          const matchingEdges = flow.edges.filter(
            (edge) => edge.target === node.id
          );

          return {
            nodeid: node?.id,
            Bid: diagram?.Bid,
            Dbid: node.data[0].Dbid ?? matchingDetail?.Dbid ?? -1,
            // Dbid: typeof matchingdetail.Dbid === "string" ? -1 : matchingdetail.Dbid,
            TaskTypeID: matchingDetail?.TaskTypeID ?? node?.data[0]?.tasktypeid,
            UpStream: matchingEdges?.map((edge) => ({
              ParentNodeID: edge?.source,
              ConnectorID: edge?.id,
            })),
            Params: node?.data[0]?.params
              ? node.data[0].params.map((param) => {
                  return {
                    direction:
                      param?.direction ?? matchingDetail?.Params.direction ?? 2,
                    taskparamid:
                      param.taskparamid ?? matchingDetail?.taskparamid,
                    paramvalue:
                      param?.paramvalue ?? matchingDetail?.paramvalue ?? "",
                    internalnotes:
                      param?.internalnotes ??
                      matchingDetail?.internalnotes ??
                      "",
                  };
                })
              : [],
          };
        });

        // Combine everything into the final format
        const description = [
          {
            placement,
            canvas,
            connectors,
            details,
          },
        ];

        // Uncomment when ready to save
        try {
          const saveResponse = await postResource(
            "PostQuickInfo",
            createRequestBody({
              accessKey: accessKey,
              guid: `${window.APIProvider.recordPath.UpdateProject}`,
              param1: diagram?.Bid?.toString() ?? "",
              param2: "",
              param3: "",
              param4: "",
              recordSet: "",
              body: JSON.stringify([
                {
                  ProjectID: diagram?.Bid?.toString() ?? "",
                  description: JSON.stringify(description),
                },
              ]),
            })
          );

          if (saveResponse.Status === "ERROR") {
            toast.error(
              `Error saving data: ${saveResponse.Message || "Unknown error"}`
            );
          } else {
            toast.success("Saved successfully!");
          }
        } catch (e) {
          console.error("Error saving data:", e);
        }
      }

      // Regular diagram save logic remains unchanged
      const userName = authData?.userName || "Unknown User";
      const todayDate = getFormattedLocalDateTime();

      const diagramData = {
        Bid: diagram.Bid,
        DiagTempID: diagram.diagTempID,
        Title: diagram.title,
        Description: diagram.explanation ?? "",
        Notes: diagram.notes,
        Nodes: JSON.stringify(flow.nodes),
        Edges: JSON.stringify(flow.edges),
        ViewPort: JSON.stringify(flow.viewport),
        CreatedBy: diagram.CreatedBy ?? userName,
        CreatedDate: diagram.CreatedDate ?? todayDate,
        ModifiedBy: diagram.ModifiedBy ?? userName,
        ModifiedDate: diagram.ModifiedDate ?? todayDate,
      };
    }
  }, [diagram, rfInstance, currentDiagramData, region]);
};
// export const useRestore = (flowKey, setNodes, setEdges, setViewport) => {
//   return useCallback(() => {
//     const restoreFlow = async () => {
//       // Temporarily restoring from local storage
//       const flow = JSON.parse(localStorage.getItem(flowKey));

//       if (flow) {
//         const { x = 0, y = 0, zoom = 1 } = flow.viewport;
//         setNodes(flow.nodes || []);
//         setEdges(flow.edges || []);
//         setViewport({ x, y, zoom });
//       }
//     };

//     restoreFlow();
//   }, [flowKey, setNodes, setEdges, setViewport]);

// Function to sort nodes from root to leaf
const sortNodesByDependency = (details) => {
  // First, filter out null entries
  const validDetails = details.filter((detail) => detail !== null);

  const nodeMap = new Map();
  const sorted = [];
  const visited = new Set();
  const inProgress = new Set();

  // Create a map of nodes and their dependencies
  validDetails.forEach((detail) => {
    // Only consider UpStream entries that have valid ParentNodeID
    const dependencies =
      detail?.UpStream?.filter((up) => up && up?.ParentNodeID) // Filter out empty objects
        ?.map((up) => up.ParentNodeID.toString()) || [];

    nodeMap.set(detail.nodeid?.toString(), {
      node: detail,
      dependencies,
    });
  });

  function visit(nodeId) {
    if (!nodeId) return;
    if (visited.has(nodeId)) return;
    if (inProgress.has(nodeId)) return; // Handle cycles

    inProgress.add(nodeId);
    const node = nodeMap.get(nodeId);

    if (node) {
      // Process dependencies
      for (const depId of node.dependencies) {
        if (depId) visit(depId);
      }
    }

    inProgress.delete(nodeId);
    visited.add(nodeId);
    sorted.push(nodeId); // Changed from unshift to push to maintain correct order
  }

  // Find root nodes (nodes with no valid upstream dependencies)
  const rootNodes = validDetails
    .filter(
      (detail) =>
        !detail.UpStream ||
        detail.UpStream.length === 0 ||
        detail.UpStream.every((up) => !up?.ParentNodeID)
    )
    .map((detail) => detail?.nodeid?.toString());

  // Start visit from root nodes
  rootNodes.forEach((nodeId) => {
    if (!visited.has(nodeId)) {
      visit(nodeId);
    }
  });

  // Visit any remaining unvisited nodes
  nodeMap.forEach((_, nodeId) => {
    if (!visited.has(nodeId)) {
      visit(nodeId);
    }
  });

  // Return the sorted nodes
  return sorted.map((nodeId) => nodeMap.get(nodeId).node);
};

// Helper function to print the node order (for debugging)
const printNodeOrder = (sortedNodes) => {
  sortedNodes?.forEach((node) => {
    const upstream =
      node?.UpStream?.filter((up) => up && up?.ParentNodeID)?.map(
        (up) => up.ParentNodeID
      ) || [];
    console.log(
      `Node ${node?.nodeid} (Type: ${node?.TaskTypeID}) <- Parents: [${upstream?.join(", ")}]`
    );
  });
};
const generatePlacement = (details, data) => {
  const verticalSpacing = 200;
  const horizontalSpacing = 200;
  const DEFAULT_NODE_WIDTH = 300;
  const DEFAULT_NODE_HEIGHT = 136;
  const INITIAL_X = 100;
  const INITIAL_Y = 100;

  let currentX = INITIAL_X;
  let currentY = INITIAL_Y;
  let inLoop = false;

  // Sort nodes from root to leaf
  const sortedNodes = sortNodesByDependency(details);
  const placement = [];
  const positionMap = new Map(); // Store positions for parent lookups

  // Helper function to get node data
  const getNodeData = (taskTypeId) => {
    return data?.find(
      (item) => item?.tasktypeid?.toString() === taskTypeId?.toString()
    );
  };

  // Helper function to get node by ID
  const getNodeById = (nodeId) => {
    return placement.find((p) => p.nodeid === nodeId);
  };

  sortedNodes.forEach((detail) => {
    const filteredData = getNodeData(detail?.TaskTypeID);
    let nodePosition = { x: currentX, y: currentY };
    if (filteredData?.nodetypeid === 3) {
      // Start of loop
      inLoop = true;
      nodePosition = { x: currentX, y: currentY };
      currentY += verticalSpacing;
      currentX += horizontalSpacing;
    } else if (filteredData?.nodetypeid === 6) {
      // End of loop
      inLoop = false;
      currentX -= horizontalSpacing;
      nodePosition = { x: currentX, y: currentY };
      currentY += verticalSpacing;
    } else {
      if (inLoop) {
        nodePosition = { x: currentX, y: currentY };
        currentY += verticalSpacing;
      } else {
        // Check for parent node position
        const parentNode = detail?.UpStream?.[0]?.ParentNodeID
          ? getNodeById(detail?.UpStream[0]?.ParentNodeID?.toString())
          : null;

        if (parentNode) {
          currentY = parentNode.y + verticalSpacing;
        }
        nodePosition = { x: currentX, y: currentY };
        currentY += verticalSpacing;
      }
    }

    // Add node to placement array
    placement.push({
      nodeid: detail?.nodeid,
      x: nodePosition?.x,
      y: nodePosition?.y,
      width: DEFAULT_NODE_WIDTH,
      height: DEFAULT_NODE_HEIGHT,
      order: placement?.length,
    });

    // Store position for future parent lookups
    positionMap.set(detail?.nodeid, nodePosition);
  });

  return placement;
};
//Restoring AI UI
export const processData = (jsonData, data, properties) => {
  // Extract the first item since the JSON is an array with one object
  const diagramData = Array.isArray(jsonData) ? jsonData[0] : jsonData;
  // const { placement, details } = diagramData;
  const canvas = diagramData?.canvas ?? defaultCanvas;
  let placement = diagramData?.placement ?? [];
  const details = diagramData?.details ?? [];
  const connectors = diagramData?.connectors ?? [];
  // Generate placement data if it doesn't exist
  if (placement?.length === 0 && details?.length > 0) {
    placement = generatePlacement(details, data);
  }

  const nodes = [];
  const edges = [];

  // Create a map of nodes by nodeId for quick lookup
  const nodeDetailsMap = {};
  details.forEach((detail) => {
    nodeDetailsMap[detail?.nodeid] = detail;
  });

  // Function to get node data from the data array based on TaskTypeID
  const getNodeData = (taskTypeId) => {
    return data.find(
      (item) => item.tasktypeid?.toString() === taskTypeId?.toString()
    );
  };

  placement.forEach((nodePlacement) => {
    const nodeDetail = nodeDetailsMap[nodePlacement?.nodeid];
    if (!nodeDetail) return;

    const filteredData = getNodeData(nodeDetail?.TaskTypeID);
    if (!filteredData) return;
    // Get the category and matching properties
    const category = filteredData?.category;
    let matchedData = properties[category || "<all>"];

    // Check for custom node properties
    const nodeId = filteredData?.tasktypeid?.toString();
    const nodeData =
      properties?.Nodes?.find((node) => node?.id?.toString() === nodeId) ||
      null;
    if (nodeData !== null) {
      matchedData = { ...matchedData, ...nodeData?.customstyle };
    }

    // Merge parameters from nodeDetail and filteredData
    const mergedParams = filteredData?.params?.map((filteredParam) => {
      const matchingItemParam = nodeDetail?.Params?.find(
        (itemParam) => itemParam?.taskparamid === filteredParam?.taskparamid
      );
      return {
        taskparamid:
          matchingItemParam?.taskparamid ?? filteredParam?.taskparamid ?? "",
        direction:
          matchingItemParam?.direction ?? filteredParam?.direction ?? "",
        paramname: filteredParam?.paramname ?? "", // Use filteredParam's paramName as it's the base definition
        paramexplanation: filteredParam?.paramexplanation ?? "", // Use filteredParam's paramExplanation
        paramtype: filteredParam?.paramtype ?? "", // Use filteredParam's paramType
        // dbid: matchingItemParam?.DBID ?? filteredParam?.dbid ?? "",
        // bDetPid: matchingItemParam?.BDetPid ?? filteredParam?.bDetPid ?? "",
        paramvalue: matchingItemParam?.paramvalue ?? "", // Only use matchingItemParam's value
        internalnotes: matchingItemParam?.internalnotes ?? "", // Only use matchingItemParam's notes
      };
    });

    const filteredDataWithMergedParams = {
      ...filteredData,
      params: mergedParams,
      // Dbid: nodeDetail?.Dbid,
    };

    // Create node
    const node = {
      id: nodePlacement?.nodeid?.toString(),
      type: "dynamicNode",
      data: [filteredDataWithMergedParams, matchedData, nodePlacement?.nodeid],
      position: {
        x: nodePlacement?.x,
        y: nodePlacement?.y,
      },
      style: {
        width: `${nodePlacement?.width}px`,
        height: `${nodePlacement?.height}px`,
      },
      draggable: true,
    };
    nodes.push(node);

    console.log("connectors ", connectors);
    console.log("nodeDetail?.UpStream  ", nodeDetail?.UpStream);
    if (nodeDetail?.UpStream && nodeDetail?.UpStream?.length > 0) {
      nodeDetail?.UpStream?.forEach((upstream) => {
        if (Object.keys(upstream).length !== 0) {
          // Find existing connector with matching ConnectorID
          const existingConnector = connectors.find(
            (connector) => connector.id === upstream.ConnectorID
          );

          if (existingConnector) {
            // Use existing connector properties but update source and target
            edges.push({
              ...existingConnector,
              id: `${upstream?.ParentNodeID}-${nodePlacement?.nodeid}`,
              source: upstream?.ParentNodeID?.toString(),
              target: nodePlacement?.nodeid?.toString(),
            });
          } else {
            // Create new edge with default properties
            edges.push({
              id: `${upstream?.ParentNodeID}-${nodePlacement?.nodeid}`,
              source: upstream?.ParentNodeID?.toString(),
              target: nodePlacement?.nodeid?.toString(),
              ...defaultEdgeOptions,
            });
          }
        }
      });
    }
    // Create edges based on UpStream connections
    // if (nodeDetail?.UpStream && nodeDetail?.UpStream?.length > 0) {
    //   nodeDetail?.UpStream?.forEach((upstream) => {
    //     if (Object.keys(upstream).length !== 0) {
    //       edges.push({
    //         id: `${upstream?.ParentNodeID}-${nodePlacement?.nodeid}`,
    //         source: upstream?.ParentNodeID?.toString(),
    //         target: nodePlacement?.nodeid?.toString(),
    //         type: "floating",
    //         markerEnd: { type: "arrowclosed", width: 15, height: 15 },
    //         interactionWidth: 30,
    //       });
    //     }
    //   });
    // }
  });

  return { nodes, edges, canvas };
};
// export const processData = (jsonData, data, properties) => {
//   const nodes = [];
//   const edges = [];
//   let currentX = 100; // Starting X position for the first graph
//   let currentY = 100; // Starting Y position (reset for each graph)
//   const verticalSpacing = 200;
//   const horizontalSpacing = 200; // Increased to give space between nodes
//   let inLoop = false;
//   let loopStartY = 0;

//   // Maps to organize nodes by ParentDBID and Dbid
//   const parentToChildren = {};
//   const nodeMap = {};

//   // Fill nodeMap and parentToChildren
//   jsonData.forEach((item, index) => {
//     nodeMap[item.Dbid] = item; // Map each node by Dbid for fast lookup

//     if (item.ParentDBID) {
//       if (!parentToChildren[item.ParentDBID]) {
//         parentToChildren[item.ParentDBID] = [];
//       }
//       parentToChildren[item.ParentDBID].push({ item, index }); // Store with original index for tie-breaking
//     } else {
//       if (!parentToChildren[null]) {
//         parentToChildren[null] = [];
//       }
//       parentToChildren[null].push({ item, index }); // Root nodes (start of a graph)
//     }
//   });

//   // Helper to sort children by Seq and original order
//   const sortChildren = (children) => {
//     return children.sort((a, b) => {
//       if (a.item.Seq !== b.item.Seq) {
//         return a.item.Seq - b.item.Seq; // Sort by Seq
//       }
//       return a.index - b.index; // Sort by original order as tiebreaker
//     });
//   };

//   const getNodeById = (id) => nodes.find((node) => node.id === id);

//   const getNodeByParentDBID = (item) => {
//     const foundNode = jsonData.find((data) => {
//       return data?.Dbid?.toString() === item.ParentDBID?.toString();
//     });

//     return foundNode;
//   };
//   // const getShortenedUuid = () => {
//   //   return uuidv4().split("-")[0]; // Take only the first part of the UUID
//   // };

//   // const getNodeId = () => getShortenedUuid();
//   // const usedIds = new Set();
//   const usedIds = new Set(jsonData?.map((data) => data.Dbid));
//   const getNodeId = () => {
//     let newId;
//     do {
//       newId = Math.floor(10000 + Math.random() * 90000);
//     } while (usedIds.has(newId));
//     usedIds.add(newId);
//     return newId.toString();
//   };

//   // Depth-First Search (DFS) to traverse nodes in topological order
//   const addNodeAndChildren = (item) => {
//     const nodeID = getNodeId();
//     let filteredData = data.find(
//       (dataItem) =>
//         dataItem?.tasktypeid?.toString() === item?.TaskTypeID?.toString()
//     );

//     if (filteredData) {
//       item.id = nodeID;
//     }
//     const category = filteredData.category;
//     var matchedData = properties[category || "<all>"];
//     const nodeId = filteredData?.diagtoolid?.toString();
//     const nodeData =
//       properties?.Nodes.find((node) => node.id.toString() === nodeId) || null;
//     if (nodeData !== null) {
//       matchedData = { ...matchedData, ...nodeData.customstyle };
//     }
//     //if filteredProperties = true add properties.customstyle
//     const nodeHeight = filteredData?.node_Height
//       ? filteredData?.node_Height + "px"
//       : "75px";
//     const nodeWidth = filteredData?.node_Width
//       ? filteredData?.node_Width + "px"
//       : "200px";
//     const isDraggable = filteredData?.isDraggable === 1 ? true : false;
//     // Create a new array to store the merged parameters
//     const mergedParams = filteredData?.params?.map((filteredParam) => {
//       // Find matching item parameter by TaskParamID
//       const matchingItemParam = item?.Params?.find(
//         (itemParam) => itemParam?.TaskParamID === filteredParam?.taskParamID
//       );

//       // Merge properties from matchingItemParam if it exists, without modifying filteredData or item
//       return {
//         taskParamID:
//           matchingItemParam?.TaskParamID ?? filteredParam?.taskParamID ?? "",
//         direction:
//           matchingItemParam?.direction ?? filteredParam?.direction ?? "",
//         paramName:
//           matchingItemParam?.paramName ?? filteredParam?.paramName ?? "",
//         paramExplanation:
//           matchingItemParam?.paramExplanation ??
//           filteredParam?.paramExplanation ??
//           "",
//         paramType:
//           matchingItemParam?.paramType ?? filteredParam?.paramType ?? "",
//         dbid: matchingItemParam?.DBID ?? filteredParam?.DBID ?? "",
//         bDetPid: matchingItemParam?.BDetPid ?? filteredParam?.BDetPid ?? "",
//         paramvalue:
//           matchingItemParam?.paramvalue ?? filteredParam?.paramvalue ?? "",
//         internalnotes:
//           matchingItemParam?.internalnotes ??
//           filteredParam?.internalnotes ??
//           "",
//       };
//     });
//     // Create a new object based on filteredData, replacing params with mergedParams
//     const filteredDataWithMergedParams = {
//       ...filteredData,
//       params: mergedParams, // Replace params with mergedParams
//     };
//     const node = {
//       id: nodeID,
//       type: "dynamicNode",
//       data: [filteredDataWithMergedParams, matchedData, nodeID],
//       style: { width: nodeWidth, height: nodeHeight },
//       draggable: true,
//     };

//     // Positioning logic
//     if (filteredData?.nodeType === 3) {
//       // Start of a loop
//       inLoop = true;
//       loopStartY = currentY;
//       node.position = { x: currentX, y: currentY };
//       currentY += verticalSpacing;
//       currentX += horizontalSpacing;
//     } else if (filteredData?.nodeType === 6) {
//       // End of a loop
//       inLoop = false;
//       currentX -= horizontalSpacing;
//       node.position = { x: currentX, y: currentY };
//       currentY += verticalSpacing;
//     } else {
//       if (inLoop) {
//         node.position = { x: currentX, y: currentY };
//         currentY += verticalSpacing;
//       } else {
//         if (item.ParentDBID) {
//           const parentNode = getNodeById(item.ParentDBID.toString());
//           if (parentNode) {
//             currentY = parentNode.position.y + verticalSpacing;
//           }
//         }
//         node.position = { x: currentX, y: currentY };
//         currentY += verticalSpacing;
//       }
//     }

//     nodes.push(node);

//     const parent = getNodeByParentDBID(item);
//     // Add edge if it has a parent
//     if (parent) {
//       edges.push({
//         id: `${parent.id}-${nodeID}`,
//         source: parent.id.toString(),
//         target: nodeID.toString(),
//         type: "floating",
//         markerEnd: { type: "arrowclosed", width: 15, height: 15 },
//       });
//     }

//     // Recursively process children
//     const children = parentToChildren[item.Dbid];
//     if (children) {
//       sortChildren(children).forEach((child) => addNodeAndChildren(child.item));
//     }
//   };

//   // Start with root nodes (those without ParentDBID)
//   const rootNodes = parentToChildren[null] || [];

//   // Process each root node (each graph) separately
//   sortChildren(rootNodes).forEach((root, index) => {
//     if (index > 0) {
//       // After the first graph, reset currentY and shift currentX for next graph
//       currentX += horizontalSpacing * 3; // Adjust this spacing based on node size
//       currentY = 100; // Reset Y position for the new graph
//     }
//     addNodeAndChildren(root.item);
//   });

//   return { nodes, edges };
// };
