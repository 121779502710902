import { toast } from "react-toastify";
import themeProvider from "../../ThemeProvider";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
export const customToast = {
  success: (message) =>
    toast(message, {
      icon: <CheckCircleIcon />,
      style: {
        background: themeProvider.palette.alert.success,
        color: "white",
      },
      progressStyle: {
        background: themeProvider.palette.alert.successProgressBar,
      },
    }),
  error: (message) =>
    toast(message, {
      icon: <ErrorIcon />,
      style: { background: themeProvider.palette.alert.error, color: "white" },
      progressStyle: {
        background: themeProvider.palette.alert.errorProgressBar,
      },
    }),
  warning: (message) =>
    toast(message, {
      icon: <WarningIcon />,
      style: {
        background: themeProvider.palette.alert.warning,
        color: "white",
      },
      progressStyle: {
        background: themeProvider.palette.alert.warningProgressBar,
      },
    }),
  info: (message) =>
    toast(message, {
      icon: <InfoIcon />,
      style: { background: themeProvider.palette.alert.info, color: "white" },
      progressStyle: {
        background: themeProvider.palette.alert.infoProgressBar,
      },
    }),
};
