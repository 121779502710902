import React, { useState } from "react";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { styled } from "@mui/system";

function LineStyleDropdown({ formData, onChange }) {
  const [selectedOption, setSelectedOption] = useState("dashed");

  const handleSelect = (event) => {
    const option = event.target.value;
    setSelectedOption(option);
    onChange(option);
  };

  const lineStyles = [
   
    { name: "Dashed Line", style: "dashed" },
    { name: "Straight Line", style: "solid" },
  ];

  return (
    <FormControl className="style-rht-markr-start" onClick={(e) => e.stopPropagation()}>
    <div className="marker-line-text">
      <div className="marker-text-container">
        <div className="rht-info">Line Style</div>
      </div>
      <div className="dropdown-container">
        <Select
          className="rht-fld-select rht-fld-select-flow rht-select-field-flow"
          value={formData.lineStyle}
          onChange={handleSelect}
          onClick={(e) => e.stopPropagation()} 
        >
          {lineStyles.map((option, index) => (
            <MenuItem key={index} value={option.style} style={{ height: "2rem" }}>
              <LineStylePreview style={lineStyle(option.style)} />
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  </FormControl>
  
  );
}

// Styled component for line style preview
const LineStylePreview = styled("div")({
  width: "50px",
  height: "1px",
  display: "inline-block",
  borderTop: (props) =>
    props.style === "solid" ? "1px solid black" : "1px dashed black",
});

// Inline style function for line styles
const lineStyle = (style) => ({
  width: "50px",
  height: "1px",
  display: "inline-block",
  borderTop: style === "solid" ? "1px solid black" : "1px dashed black",
});

export default LineStyleDropdown;