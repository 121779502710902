import {
  Checkbox,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import "./multiSelector/MultiSelectorStyles.css";
import React, { useEffect, useState } from "react";

export default function SelectorData({
  apiData,
  selected,
  setSelected,
  showInactive,
  setShowInactive,
}) {
  const [uniqueCategories, setUniqueCategories] = useState([]);

  const isAllSelected =
    apiData.length > 0 && selected.length === apiData.length;

  useEffect(() => {
    const categoriesSet = new Set();
    apiData.forEach((section) => {
      categoriesSet.add(section.Category);
    });
    setUniqueCategories(Array.from(categoriesSet));
  }, [apiData]);

  const handleToggle = (value) => () => {
    setSelected((prevSelected) => {
      const isSelected = prevSelected.some(
        (section) => section.Category === value
      );

      if (isSelected) {
        return prevSelected.filter((section) => section.Category !== value);
      } else {
        const selectedSections = apiData.filter(
          (section) => section.Category === value
        );
        return [...prevSelected, ...selectedSections];
      }
    });
  };

  const handleSelectAll = () => {
    setSelected((prevSelected) => {
      const filteredData = showInactive
        ? apiData
        : apiData.filter((section) => section.isInactive === 0);
      return prevSelected.length === filteredData.length
        ? []
        : [...filteredData];
    });
  };

  const toggleShowInactive = () => {
    setShowInactive((prevShowInactive) => {
      const newShowInactive = !prevShowInactive;

      setSelected((prevSelected) => {
        return apiData.filter(
          (section) =>
            section.Category &&
            (prevSelected.some((s) => s.Category === section.Category) ||
              newShowInactive ||
              section.isInactive === 0)
        );
      });

      return newShowInactive;
    });
  };

  const areArraysEqual = (array1, array2) => {
    if (array1.length !== array2.length) {
      return false;
    }

    for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    const filteredData = apiData.filter(
      (section) =>
        (showInactive && section.isInactive === 1) ||
        (!showInactive && section.isInactive === 0)
    );
    if (!areArraysEqual(selected, filteredData)) {
      setSelected(filteredData);
    }
  }, [apiData, showInactive, setSelected]);

  return (
    <div className="selt-data">
      <List>
        <ListItem button onClick={handleSelectAll}>
          <ListItemIcon>
            <Checkbox
              checked={isAllSelected}
              indeterminate={
                selected.length > 0 && selected.length < apiData.length
              }
            />
          </ListItemIcon>
          <ListItemText
            primary={isAllSelected ? "Unselect All" : "Select All"}
          />
        </ListItem>

        {uniqueCategories
          .filter(
            (Category) =>
              Category.trim() !== "" &&
              !Category.includes("JSON") &&
              !Category.includes("DefaultStyles") &&
              !Category.includes("Theme Template")
          )
          .map((Category) => (
            <ListItem key={Category} button onClick={handleToggle(Category)}>
              <ListItemIcon>
                <Checkbox
                  checked={selected.some((item) => item.Category === Category)}
                />
              </ListItemIcon>
              <ListItemText primary={Category} />
            </ListItem>
          ))}

        <ListItem button onClick={toggleShowInactive}>
          <ListItemIcon>
            <Checkbox checked={showInactive} />
          </ListItemIcon>
          <ListItemText primary="Inactive Forms" />
        </ListItem>
      </List>
    </div>
  );
}