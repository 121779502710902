import React, { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import { CircularProgress, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  Box,
  Button,
  CardActions,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import "../assets/icofontcharts/icofont.min.css";
import { Image, Theme } from "../Components/IncludeFile/Theme";
import { useAppState } from "./AppStateProvider ";
import { ToastContainer, toast } from "react-toastify";
import { getCurrentDateTime } from "../apiCalls/DiagramsAPI";
import { Star, StarBorder } from "@mui/icons-material";
import { ReadArray } from "../Components/Api/ApiCalls";
import { FavDictionary } from "../data/FavDictionary";
import ErrorBanner from "../Error/ErrorBanner";

const CardContentData = React.memo(
  ({
    size,
    textSize,
    ArrayName,
    Description,
    DataSourceArID,
    ArID,
    handleDelete,
    Category,
    isTemplate,
    setOpenCreateInfo,
    openCreateInfo,
    setOpenViewCreateInfo,
    openViewCreateInfo,
    openDiscoverPage,
    setOpenDiscoverPage,
    authData,
    isInactive,
    apiData,
    setApiData,
    selected,
    setSelected,
    ModifiedBy,
    ModifiedDate,
    datasourcearrayName,
    isFav,
    defaultStyle,
    ReadAllData,
    error,
    setError,
  }) => {

    const navigate = useNavigate();
    const accessKey = authData.accessKey;
    const [editedArrayName, setEditedArrayName] = useState(ArrayName);
    const [editedDescription, setEditedDescription] = useState(Description);
    const [editedcategory, seteditedcategory] = useState(Category);
    const [editeddataSourceArID, setediteddataSourceArID] =
      useState(DataSourceArID);
    const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
      useState(false);
    const [isExpanded, setIsExpanded] = useState(false);

    let APIProvider = window.APIProvider;
    const userName = authData?.userName || "";

    const openDeleteConfirmation = () => {
      setIsDeleteConfirmationOpen(true);
    };

    // const fetchData = async () => {
    //   const jsonData = await ReadArray(DataSourceArID, accessKey);
    //   const SourceOFDataSource = jsonData[0].Source;
    //   return SourceOFDataSource;
    // };
    const fetchData = async () => {
      try {
        const jsonData = await ReadArray(DataSourceArID || 0, accessKey);

        if (!jsonData || jsonData.length === 0) {
          console.warn("jsonData is null or empty. Returning null.");
          return null;
        }

        const SourceOFDataSource = jsonData[0]?.Source || null;
        return SourceOFDataSource;
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("An error occurred in fetching data");
        localStorage.setItem("error", "An error occurred in fetching data");
        localStorage.setItem("navigate", "/Landing");
        return null;
      }
    };

    const closeDeleteConfirmation = () => {
      setIsDeleteConfirmationOpen(false);
    };

    const onDeleteConfirmation = () => {
      closeDeleteConfirmation();
      onDelete();
      toast.success("Record is deleted successfully");
    };

    const handleSelect = async () => {
      try {
        const SourceOFDataSource = await fetchData();

        const stateObject = {
          ArrayName,
          Description,
          Category,
          DataSourceArID,
          defaultStyle,
          SourceOFDataSource,
          isInactive,
        };

        navigate(`/Form/${ArID}`, { state: stateObject });
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("An error occurred in fetching data");
        localStorage.setItem("error", "An error occurred in fetching data");
        localStorage.setItem("navigate", "/Landing");
      }
    };

    const closeEditDialog = () => {
      setIsEditDialogOpen(false);
    };

    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

    const closeDeleteDialog = () => {
      setIsDeleteDialogOpen(false);
    };

    const onDelete = async () => {
      if (!authData || !authData.accessKey) {
        navigate("/");
        sessionStorage.clear();
        return;
      }
      closeDeleteDialog();
      try {
        const requestBodyDelete = {
          accessKey: authData.accessKey,
          guid: APIProvider.GUID.DeleteArrayByarIDGUID,
          param1: String(ArID),
          param2: "",
          param3: "",
          param4: "",
          recordSet: "",
          body: "",
        };

        const response = await fetch(
          `${APIProvider.baseURL}${APIProvider.MethodName.GetQuickInfo}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBodyDelete),
          }
        );

        if (response.ok) {
          handleDelete(ArID);
        } else {
          console.error("Failed to delete item.");
          toast.error("Failed to delete item.");
        }
      } catch (error) {
        console.error("Error deleting item:", error);
        setError("An error occurred in deleting item");
        localStorage.setItem("error", "An error occurred in deleting item");
        localStorage.setItem("navigate", "/Landing");
      }
    };

    useEffect(() => {
      setEditedArrayName(ArrayName);
      setEditedDescription(Description);
      seteditedcategory(Category);
      setediteddataSourceArID(DataSourceArID);
    }, [ArrayName, Description, Category, DataSourceArID]);

    const dateTime = getCurrentDateTime();

    const formatDate = (dateString) => {
      if (!dateString) {
        return "";
      }

      const date = new Date(dateString);

      if (isNaN(date)) {
        return "";
      }

      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    };

    const stateObject = {
      ArID: ArID,
      ArrayName: ArrayName,
      Description: Description,
      Category: Category,
      DataSourceArID: DataSourceArID,
      selected: selected,
      defaultStyle: defaultStyle,
      ReadAllData: ReadAllData,
      isInactive: isInactive,
    };

    const handleNavigate = () => {
      setOpenViewCreateInfo(!openViewCreateInfo);
      setOpenDiscoverPage(!openDiscoverPage);
      navigate("/Open", { state: stateObject });
    };

    const handleOverView = () => {
      setOpenCreateInfo(!openCreateInfo);
      navigate("/Open", { state: stateObject });
    };

    if (isTemplate === 2) {
      return null;
    }

    const openEdit = async () => {
      const SourceOFDataSource = await fetchData();
      navigate(`/Form/${ArID}`, { state: stateObject, SourceOFDataSource });
    };

    const toggleExpanded = () => {
      setIsExpanded(!isExpanded);
    };

    const handleActive = async () => {
      try {
        const requestBody = {
          accessKey: authData.accessKey,
          guid: APIProvider.GUID.EditArrayGUID,
          param1: "",
          param2: "",
          param3: "",
          param4: "",
          recordSet: "",
          body: JSON.stringify({
            ArID: ArID,
            ArrayName: ArrayName,
            Description: Description,
            Category: Category,
            IsTemplate: isTemplate,
            DataSourceArID: DataSourceArID,
            isInactive: isInactive === 0 ? 1 : 0,
            ModifiedBy: userName,
            ModifiedDate: dateTime,
          }),
        };

        const response = await fetch(
          `${APIProvider.baseURL}${APIProvider.MethodName.PostQuickInfo}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (response.ok) {
          const updatedApiData = apiData.map((item) =>
            item.ArID === ArID
              ? {
                  ...item,
                  ArrayName: ArrayName,
                  Description: Description,
                  Category: Category,
                  DataSourceArID: DataSourceArID,
                  isInactive: isInactive === 0 ? 1 : 0,
                }
              : item
          );

          setApiData(updatedApiData);
          const updatedSelected = selected.map((item) =>
            item.ArID === ArID
              ? {
                  ...item,
                  ArrayName: ArrayName,
                  Description: Description,
                  Category: Category,
                  DataSourceArID: DataSourceArID,
                  isInactive: isInactive === 0 ? 1 : 0,
                }
              : item
          );

          setSelected(updatedSelected);
        } else {
          console.error("Failed to edit item.");
          toast.error("Failed to edit item.");
        }
      } catch (error) {
        console.error("Error editing item:", error);
        setError("An error occurred in editing item");
        localStorage.setItem("error", "An error occurred in editing item");
        localStorage.setItem("navigate", "/Landing");
      }
      setIsActive(!isActive);
    };
    const dateObj = new Date(ModifiedDate);

    // const year = dateObj.getFullYear();
    // const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    // const day = String(dateObj.getDate()).padStart(2, "0");
    // const hours = String(dateObj.getHours()).padStart(2, "0");
    // const minutes = String(dateObj.getMinutes()).padStart(2, "0");

    // const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;

    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");

    let hours = dateObj.getHours();
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");

    const period = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;

    hours = String(hours).padStart(2, "0");

    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes} ${period}`;

    const handleFavoriteClick = async (ArID, currentIsFav) => {
      try {
        const action = currentIsFav === 0 ? 1 : 0;

        const requestBody = {
          accessKey: authData.accessKey,
          guid: window.APIProvider.recordPath.ToggleFavoritesByUser,
          param1: "",
          param2: "",
          param3: "",
          param4: "",
          recordSet: "",
          body: JSON.stringify([
            {
              username: authData.userName,
              isFav: FavDictionary.formProject?.toString(),
              Action: action.toString(),
              RefPkey: ArID.toString(),
            },
          ]),
        };

        const response = await fetch(
          `${APIProvider.baseURL}${APIProvider.MethodName.PostQuickInfo}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestBody),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();

        setApiData((prevApiData) => {
          return prevApiData.map((item) => {
            if (item.ArID === ArID) {
              const updatedItem = { ...item, isFav: action };

              return updatedItem;
            }
            return item;
          });
        });
      } catch (error) {
        console.error("Error in handleFavoriteClick:", error);
        toast.error("An error occurred while processing your request.");
      }
    };

    return (
      <>
        <div className="card-form card-tostfy" style={{ display: "flex" }}>
          <ToastContainer position="bottom-right" autoClose={3000} />
          <>
            {isTemplate !== 2 && isTemplate !== 3 && isTemplate !== 4 && (
              <Card
                className="card-cnt-show"
                sx={{
                  width: `${size}px`,
                  height: "auto",
                  maxHeight: "230px",
                  marginBottom: "1rem",
                  marginRight: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  transition: "box-shadow 0.3s",
                  border: "1px solid #ccc",
                  "&:hover": {
                    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.5)",
                  },
                }}
              >
                <CardContent className="card-content-dt">
                  <Box>
                    <div className="tmplt-icon-tst">
                      {Category === "Contact Form" && (
                        <img
                          src={Image.ContactForm}
                          alt="Contact Form Icon"
                          className="form-icon"
                        />
                      )}
                      {Category === "Survey Form" && (
                        <img
                          src={Image.SurveyForm}
                          alt="Survey Form Icon"
                          className="form-icon"
                        />
                      )}

                      {Category === "Blank Form" && (
                        <img
                          src={Image.UserCreateForm}
                          alt="Blank Form Icon"
                          className="form-icon"
                        />
                      )}
                      {Category === "Course Registration Form" && (
                        <img
                          src={Image.CourseRegistration}
                          alt="Blank Form Icon"
                          className="form-icon"
                        />
                      )}
                      {Category === "FeedBack Form" && (
                        <img
                          src={Image.FeedBackForm}
                          alt="Blank Form Icon"
                          className="form-icon"
                        />
                      )}
                      {Category === "Product Form" && (
                        <img
                          src={Image.ProductForm}
                          alt="Blank Form Icon"
                          className="form-icon"
                        />
                      )}
                      {Category === "Registration Form" && (
                        <img
                          src={Image.RegistrationForm}
                          alt="Blank Form Icon"
                          className="form-icon"
                        />
                      )}

                      {Category === "Json Form" && (
                        <img
                          src={Image.json}
                          alt="Blank Form Icon"
                          className="form-icon"
                        />
                      )}

                      <div className="edit-title">
                        <div
                          className="title-edit-txt-cntr"
                          data-full-text={ArrayName}
                          title={
                            ArrayName.charAt(0).toUpperCase() +
                            ArrayName.slice(1).toLowerCase()
                          }
                        >
                          {ArrayName}
                        </div>
                        <div className="icon-rht">
                          {isTemplate === 1 ? (
                            <>
                              {isFav === 1 ? (
                                <>
                                  <div className="iconset-delet">
                                    <div className="Edit-del-icn dashbord-titl">
                                      <Star
                                        fontSize="medium"
                                        sx={{
                                          color: "green",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleFavoriteClick(ArID, isFav)
                                        }
                                      />
                                      <div className="tooltiptext toltip-top inctive-tooltip">
                                        favorite
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="iconset-delet">
                                    <div className="Edit-del-icn dashbord-titl">
                                      <StarBorder
                                        fontSize="medium"
                                        sx={{
                                          color: "gray",
                                          cursor: "pointer",
                                          "&:hover": {
                                            color: "green",
                                          },
                                        }}
                                        onClick={() =>
                                          handleFavoriteClick(ArID, isFav)
                                        }
                                      />
                                      <div className="tooltiptext toltip-top unfavrate-tooltip">
                                        unfavorite
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}

                              <div className="iconset-delet">
                                <div className="Edit-del-icn dashbord-titl">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={isInactive === 0}
                                      onChange={handleActive}
                                    />
                                    <span className="slider slid-card"></span>
                                  </label>
                                  <div className="tooltiptext toltip-top inctive-tooltip">
                                    {isInactive === 0 ? "Active" : "Inactive"}
                                  </div>
                                </div>
                              </div>

                              <div className="iconset-delet">
                                <div className="Edit-del-icn dashbord-titl">
                                  <span
                                    className="icon-for-edit"
                                    onClick={openEdit}
                                  >
                                    {Theme.Edit}
                                  </span>
                                  <div className="tooltiptext">Edit</div>
                                </div>
                              </div>
                            </>
                          ) : (
                            <>
                              {isFav === 1 ? (
                                <>
                                  <div className="iconset-delet">
                                    <div className="Edit-del-icn dashbord-titl">
                                      <Star
                                        fontSize="medium"
                                        sx={{
                                          color: "green",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleFavoriteClick(ArID, isFav)
                                        }
                                      />
                                      <div className="tooltiptext toltip-top inctive-tooltip">
                                        favorite
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className="iconset-delet">
                                    <div className="Edit-del-icn dashbord-titl">
                                      <StarBorder
                                        fontSize="medium"
                                        sx={{
                                          color: "gray",
                                          cursor: "pointer",
                                          "&:hover": {
                                            color: "green",
                                          },
                                        }}
                                        onClick={() =>
                                          handleFavoriteClick(ArID, isFav)
                                        }
                                      />
                                      <div className="tooltiptext toltip-top unfavrate-tooltip">
                                        unfavorite
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              <div className="iconset-delet">
                                <div className="Edit-del-icn dashbord-titl">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={isInactive === 0}
                                      onChange={handleActive}
                                    />
                                    <span className="slider slid-card"></span>
                                  </label>
                                  <div className="tooltiptext toltip-top inctive-tooltip">
                                    {isInactive === 0 ? "Active" : "Inactive"}
                                  </div>
                                </div>
                              </div>
                              <div className="iconset-delet">
                                <div className="Edit-del-icn">
                                  <span
                                    className="icon delt-icon"
                                    onClick={openDeleteConfirmation}
                                  >
                                    {/* <HighlightOffIcon /> */}
                                    <IoClose />
                                  </span>

                                  <div className="tooltiptext">Delete</div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="card-read-propty">
                      <span
                        style={{
                          fontSize: "12px",
                          textAlign: "justify",
                          padding: "5px 10px 0px 0px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          WebkitBoxOrient: "vertical",
                          WebkitLineClamp: isExpanded ? "unset" : 4,
                          flex: 1,
                          lineHeight: "15px",
                          overflowY: isExpanded ? "scroll" : "hidden",
                          cursor: "pointer",
                          height: "62px",
                        }}
                      >
                        {isExpanded
                          ? Description
                          : `${Description.slice(0, 150)}`}
                        {Description.length > 150 && (
                          <span
                            onClick={toggleExpanded}
                            style={{
                              color: "#007BFF",
                              cursor: "pointer",
                              marginLeft: "5px",
                            }}
                          >
                            {isExpanded ? "Less" : "..More"}
                          </span>
                        )}
                      </span>
                    </div>
                  </Box>
                  <Box>
                    <div
                      style={{
                        fontSize: "12px",
                        textAlign: "left",
                        paddingTop: 3,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        color: "#777",
                      }}
                    >
                      {Category}
                    </div>
                    <div
                      style={{
                        fontSize: "12px",
                        textAlign: "left",
                        color: "#777",
                      }}
                    >
                      DataSource:{" "}
                      {typeof datasourcearrayName === "object"
                        ? JSON.stringify(datasourcearrayName)
                        : datasourcearrayName || "null"}
                    </div>
                    {loading ? (
                      <CircularProgress color="inherit" size="30px" />
                    ) : (
                      ModifiedDate !== null &&
                      ModifiedBy !== null && (
                        <div
                          style={{
                            fontSize: textSize * 0.75,
                            textAlign: "left",
                          }}
                        >
                          <div className="mdf-date">
                            <div className="mdf-admin">
                              Modified by {ModifiedBy} on {formattedDate}
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </Box>
                </CardContent>

                <CardActions
                  sx={{
                    marginTop: "auto",
                    display: "flex",
                    justifyContent: "center",
                    padding: "0px 8px 8px 8px",
                  }}
                >
                  {isTemplate === 1 ? (
                    <>
                      <Button
                        sx={{
                          fontSize: "1rem",
                          width: "50%",
                          height: "85%",
                          borderRadius: "30px",
                        }}
                        variant="outlined"
                        onClick={handleNavigate}
                      >
                        Select
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        sx={{
                          fontSize: "1rem",
                          width: "50%",
                          height: "85%",
                          borderRadius: "30px",
                          color: "#1976D2",
                          border: "1px solid #1976D2",
                          backgroundColor: "#fff",
                          "&:hover": {
                            color: "#1976D2",
                            backgroundColor: "#fff",
                            // border: "1px solid #1976D2",
                            boxShadow:
                              "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                          },
                        }}
                        variant="outlined"
                        onClick={handleOverView}
                      >
                        Overview
                      </Button>
                      <Button
                        sx={{
                          fontSize: "1rem",
                          width: "50%",
                          height: "85%",
                          borderRadius: "30px",
                          backgroundColor: "#1976D2",
                          color: "#fff",
                          border: "1px solid #1976D2",
                          "&:hover": {
                            color: "#fff",
                            backgroundColor: "#1976D2",
                            border: "1px solid #1976D2",
                            boxShadow:
                              "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                          },
                        }}
                        variant="outlined"
                        onClick={handleSelect}
                      >
                        Edit
                      </Button>
                    </>
                  )}
                </CardActions>
              </Card>
            )}

            <div>
              <Dialog
                open={isDeleteConfirmationOpen}
                onClose={closeDeleteConfirmation}
                maxWidth="md"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <div className="dashbord-delete-popup">
                  <div className="dashbord-delete-text">
                    <DialogTitle>
                      <div>
                        {/* <HighlightOffIcon/> */}
                        <IoClose
                          style={{
                            fontSize: "3em",
                            color: "red",
                          }}
                        />
                        <IconButton
                          edge="end"
                          color="inherit"
                          onClick={closeDeleteConfirmation}
                          aria-label="close"
                          sx={{
                            position: "absolute",
                            right: "35px",
                            top: 20,
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </div>
                    </DialogTitle>
                    <DialogContent style={{ paddingBottom: "0px" }}>
                      <div className="card-dlt-icon-txt">
                        <h1>Confirmation</h1>
                        <p>
                          Do you really want to delete this record?
                          <br />
                          This process cannot be undone.
                        </p>
                      </div>
                    </DialogContent>
                    <DialogActions
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        paddingBottom: "20px",
                      }}
                    >
                      <Button
                        onClick={closeDeleteConfirmation}
                        sx={{
                          backgroundColor: "#008e05",
                          border: "1px solid #008e05",
                          color: "#fff",
                          margin: "0 5px",
                          padding: "3px 15px",
                          transition: "background-color 0.3s, color 0.3s",
                          textTransform: "capitalize",
                          "&:hover": {
                            backgroundColor: "#008e05",
                            color: "#ffffff",
                            boxShadow:
                              "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.1)",
                          },
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        onClick={onDeleteConfirmation}
                        sx={{
                          backgroundColor: "#ff0000",
                          margin: "0 5px",
                          color: "#ffffff",
                          padding: "3px 15px",
                          border: "1px solid #ff0000",
                          transition: "background-color 0.3s, border 0.3s",
                          textTransform: "capitalize",
                          "&:hover": {
                            backgroundColor: "#ff0000",
                            boxShadow:
                              "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.1)",
                          },
                        }}
                      >
                        Delete
                      </Button>
                    </DialogActions>
                  </div>
                </div>
              </Dialog>
            </div>
          </>
        </div>
        {error && <ErrorBanner />}
      </>
    );
  }
);

export default CardContentData;
