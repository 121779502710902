import React, { useState, useEffect } from "react";
import { IconButton, ThemeProvider } from "@mui/material";
import { PushWebPages } from "../../apiCalls/LandingPageAPI";
import MobileLandingPage from "../landingPage/MobileLandingPage";
import DesktopLandingPage from "./DesktopLandingPage";
import PopUpComponent from "../../Components/landingPage/PopUpComponent";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import EditIcon from "@mui/icons-material/Edit";
import { Action } from "../../Components/landingPage/ActionList";
import "../../index.css";
import themeProvider from "../../ThemeProvider";
import LandingNavBar from "../../Components/navbarComponents/LandingNavBar";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useLocation, useNavigate } from "react-router-dom";
import { loadLoginData } from "../../apiCalls/DiagramsAPI";
const admin = true;

function Landing() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [landingSectionsData, setLandingSectionsData] = useState(null);
  // const [landingSectionsData, setLandingSectionsData] = useState(window.MyVar);
  

  const location = useLocation();
  const navigate = useNavigate();
  const authData = loadLoginData(); 
 

  useEffect(()=>{
    if (!authData || !authData.accessKey) {
      navigate("/");
      sessionStorage.clear();
      return; 
    }
  },[authData])


  useEffect(() => {
    const fetchData = async () => {
      const scriptElement = document.createElement("script");
      scriptElement.src = `Landing.js?v=${Date.now}`;

      scriptElement.onload = () => {
        setLandingSectionsData(window.MyVar);
        setFetched(true);
      };

      scriptElement.onerror = () => {
        console.error("Error loading script");
        setFetched(true); // Handle error state accordingly
      };

      document.head.appendChild(scriptElement);
    };

    fetchData();
  }, []);

  const isMobile = screenWidth <= 750;

  //Margins before config file
  const margin = `${(screenWidth - 750) / 3}px`;
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [fetched, setFetched] = React.useState(false);

  //Get landing page sections from Cache
  // React.useEffect(() => {
  //   async function FillData() {
  //     const scriptElement = document.createElement("script");
  //     scriptElement.src = `Landing.js?v=${Date.now()}`;

  //     // Append the script tag to the document's head or body
  //     document.head.appendChild(scriptElement);
  //     scriptElement.onload = () => {
  //       setLandingSectionsData(window.MyVar);
  //     };
  //     setLandingSectionsData(window.MyVar);
  //     setFetched(true);
  //   }
  //   if (fetched === false) {
  //     FillData();
  //   }
  // }, []);

  // const margin = window.landingPageMargins.margin;
  const [open, setOpen] = React.useState(false);
  const [ID, setID] = React.useState(0);
  const [title, setTitle] = React.useState("");
  const [URLPicker, setURLPicker] = React.useState(0);
  const handleDialogToggle = (event, ID, title, URLPicker) => {
    event.stopPropagation();
    setID(ID);
    setURLPicker(URLPicker);
    // Set the title based on the URLPicker value
    switch (URLPicker) {
      case Action.ADD_PAGE:
        setTitle("Add Page");
        break;
      case Action.EDIT_PAGE:
        setTitle(`Edit Page [${title}]`);
        break;
      case Action.ADD_ITEM_TO_SECTION:
        setTitle(`Add Section To Page [${title}]`);
        break;
      case Action.EDIT_SECTION:
        setTitle(`Edit Section [${title}]`);
        break;
      case Action.EDIT_ITEM:
        setTitle(`Edit [${title}]`);
        break;
      default:
        setTitle(""); // Set a default title or handle unsupported values
    }
    setOpen(!open);
  };

  async function closePopUp(pageName) {
    setOpen(!open);
    let temp = await PushWebPages(pageName);
    setFetched(false);
  }

  // const leftButton = {
  //   // icon: Theme.Add,
  //   text: "Add Control",
  //   // color: "primary", // "primary" or "secondary"
  //   handleClick: () => {
  //     // Handle click for the left button
  //     console.log("Left button clicked!");
  //   },
  // };

  // const rightButtons = [
  //   {
  //     // icon: Theme.Save,
  //     text: "New Template",
  //     color: "secondary",
  //     handleClick: () => {
  //       // Handle click for the second right button
  //       console.log("Left button clicked!");
  //     },
  //   },
  //   {
  //     // icon: <TiExport />,
  //     text: "Back",
  //     color: "secondary",
  //     handleClick: () => {
  //       console.log("Left button clicked!");
  //     },
  //   },
  //   // Add more buttons as needed
  // ];


  if (!fetched) {
    return  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
    <CircularProgress />
  </Box>;
  }
  return (
    <ThemeProvider theme={themeProvider}>
      <LandingNavBar title="React Form Builder" authData={authData} />
      <div
        className="scrollBars"
        style={{ width: "100%", height: "100%", marginTop: "55px" }}
      >
 
        {isMobile ? (
          <MobileLandingPage
            landingSectionsData={landingSectionsData}
            fetched={fetched}
          />
        ) : (
          <div
            style={{
              marginLeft: margin,
              marginRight: margin,
            }}
          >
            {admin && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <IconButton
                  onClick={(event) =>
                    handleDialogToggle(
                      event,
                      landingSectionsData[0].page.pageID,
                      landingSectionsData[0].page.name,
                      Action.EDIT_PAGE
                    )
                  }
                  color="primary"
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  onClick={(event) =>
                    handleDialogToggle(
                      event,
                      landingSectionsData[0].page.pageID,
                      landingSectionsData[0].page.name,
                      Action.ADD_PAGE
                    )
                  }
                  color="primary"
                >
                  <NoteAddIcon />
                </IconButton>
                <p
                style={{
                    fontWeight: "bold",
                    // marginRight: "10px",
                    margin:"0px 10px 0px 0px",
                    display:"flex",
                    alignItems:"center",
                    color: themeProvider.palette.primary.main,
                  }}
                >
                  {landingSectionsData[0].page.name} Page
                </p>
              </div>
            )}

            <div style={{ border: "1px solid #D4D4D4" }}>
              <DesktopLandingPage
                landingSectionsData={landingSectionsData}
                fetched={fetched}
                admin={admin}
                handleDialogToggle={handleDialogToggle}
              />
            </div>
          </div>
        )}
        {open && (
          <PopUpComponent
            open={open}
            onClose={() => closePopUp(landingSectionsData[0].page.name)}
            title={title}
            ID={ID}
            URLPicker={URLPicker}
            pageName={landingSectionsData[0].page.name}
          />
        )}
      </div>
    </ThemeProvider>
  );
}

export default Landing;
