import React, { useState, useCallback, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ReactFlow, {
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  MiniMap,
  MarkerType,
  Controls,
  getIncomers,
  getOutgoers,
  getConnectedEdges,
  Background,
  BackgroundVariant,
  ControlButton,
} from "reactflow";
import "reactflow/dist/style.css";
import DynamicNode from "../../nodes/DynamicNode.js";
import DesktopSidebar from "../../Components/diagramsComponents/sideBar/DesktopSideBar.js";
import "../../index.css";
// import { initialNodes, initialEdges } from "../../nodesAndEdges.js";
import { jsonData } from "../../nodesAndEdges.js";
import { useDrop, useDragOver } from "../../functions/DragnDrop.js";
import {
  useSave,
  useRestore,
  processData,
} from "../../functions/SaveAndRestore.js";
import { useLayoutedElements } from "../../functions/ElksAlignment.js";
import { useCustomLayoutedElements } from "../../functions/ElksAlignment.js";
import { useDeleteNode } from "../../functions/NodeFunctions.js";
import {
  useNodeDragStop,
  useUpdateNodeAndChildren,
  useNodeClick,
} from "../../functions/NodeFunctions.js";
import {
  useFlipArrow,
  useDeleteEdge,
  useEdgeClick,
} from "../../functions/EdgeFunctions.js";
import NavBar from "../../Components/navbarComponents/NavBar.js";
import { Theme } from "../../Components/IncludeFile/Theme.js";
import {
  createRequestBody,
  postResource,
  getAccessKey,
  loadLoginData,
} from "../../apiCalls/DiagramsAPI.js";
import { defaultEdgeOptions } from "../../data/defaultEdgeOptions.js";
import FloatingEdge from "../../functions/FloatingEdge.js";
import FloatingConnectionLine from "../../functions/ConnectionLine.js";
import DrawerComponent from "../../Components/diagramsComponents/DrawerComponent.js";
import CustomMarkers from "../../functions/MarkerFunctions.js";
import { ToastContainer, toast } from "react-toastify";
import NodeDrawerComponent from "../../Components/diagramsComponents/nodeDrawerComponents/NodeDrawerComponent.js";
import AlignVerticalTopIcon from "@mui/icons-material/AlignVerticalTop";
import AlignHorizontalLeftIcon from "@mui/icons-material/AlignHorizontalLeft";
import AlignVerticalCenterIcon from "@mui/icons-material/AlignVerticalCenter";
import FormatColorFillIcon from "@mui/icons-material/FormatColorFill";
import Grid3x3Icon from "@mui/icons-material/Grid3x3";
import themeProvider from "../../ThemeProvider.js";
import { useAppContext } from "../../Components/diagramsComponents/RegionContext.js";
import { text } from "@fortawesome/fontawesome-svg-core";
import { Nodes } from "../../Components/IncludeFile/Node.js";
import ErrorBanner from "../../Error/ErrorBanner.js";
// const flowKey = "flowJson";

// const fitViewOptions = { padding: 4 };

const nodeTypes = {
  dynamicNode: DynamicNode,
};

const edgeTypes = {
  floating: FloatingEdge,
};

const Diagrams = () => {
  //Get props
  const location = useLocation();
  //project selected from QinfoDiagHeader
  let { project } = location.state || {};
  console.log("project ", project);
  const [diagram, setDiagram] = useState({});
  const [fetched, setFetched] = useState(false);
  const [properties, setProperties] = useState([]);
  const [initialNodes, setInitialNodes] = useState();
  const [initialEdges, setInitialEdges] = useState();
  const [canvas, setCanvas] = useState();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  //Tools by template
  const [data, setData] = useState(null);
  const { setViewport } = useReactFlow();
  const [tabValue, setTabValue] = useState("");
  const [error, setError] = useState(null);

  //Get Tools By Template Backend call
  async function fetchTools(diagTempID) {
    const tools = await postResource(
      "GetQuickInfo",
      createRequestBody({
        accessKey: accessKey,
        guid: `${window.APIProvider.recordPath.ListDiagToolsByTemplate}`,
        param1: diagTempID.toString(),
      })
    );
    return tools;
  }

  //Get tools of this template
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        // const fetchedData = Nodes
        const fetchedData = await fetchTools(project.diagTempID);
        console.log("fetchedData", fetchedData);
        setData(fetchedData);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("An error occurred in fetchedData", error);
        localStorage.setItem("error", "An error occurred in fetchedData");
        localStorage.setItem("navigate", "/Select");
      }
    };

    fetchData();
  }, []);

  const [regions, setRegions] = React.useState([]);

  const authData = loadLoginData();
  const accessKey = authData?.accessKey || "";

  //get info from RegionContext
  const { getRegionInfo } = useAppContext();
  const [regionInfo, setRegionInfo] = useState(null);

  const fetchRegions = async () => {
    try {
      const storedRegion = localStorage.getItem("region");
      const info = await getRegionInfo(storedRegion);
      console.log("Region Names:", info);
      setRegionInfo(info);
      // Call fetchData only if info is valid and fetched is false
      if (!fetched && data && info) {
        getData(info);
      }
    } catch (error) {
      console.error("Error fetching regions:", error);
    }
  };

  React.useEffect(() => {
    fetchRegions();
  }, [fetched, data]);

  //get tool properties and initialize canvas
  async function getData(info) {
    try {
      setDiagram(project);
      var toolProperties;

      // toolProperties = JSON.parse(info.ToolsJson);

      if (info.ToolsJson && typeof info.ToolsJson === "string") {
        try {
          toolProperties = JSON.parse(info.ToolsJson);
        } catch (e) {
          console.error("Invalid ToolsJson:", e);
          toolProperties = null;
        }
      } else {
        console.error("info.ToolsJson is undefined or not a string");
        toolProperties = null;
      }

      if (project.region === "Generic") {
        setNodes(project.nodes ? JSON.parse(project.nodes) : []);
        setEdges(project.edges ? JSON.parse(project.edges) : []);
      } else {
        //get steps for initializing canvas
        const response = await postResource(
          "GetQuickInfo",
          createRequestBody({
            accessKey: accessKey,
            guid: `${window.APIProvider.recordPath.GetDiagramDetails}`,
            param1: project?.Bid?.toString(),
          })
        );
        console.log("steps on canvas ", response);
        const parsedResponse = JSON.parse(response[0].Column1);
        console.log("parsedResponse ", parsedResponse);
        // console.log("parsedResponse ", data);
        // console.log("parsedResponse ", toolProperties);
        let canvasNodes = [];
        let canvasEdges = [];
        let tempCanvas = {};
        // if (parsedResponse && data) {
        if (data) {
          // const result = processData(jsonData, data, toolProperties);
          const result = processData(parsedResponse, data, toolProperties);
          canvasNodes = result.nodes;
          canvasEdges = result.edges;
          tempCanvas = result.canvas;

          console.log("canvas ", tempCanvas);
        }

        // setDiagramDetails(parsedResponse);
        // setInitialDiagramDetails(parsedResponse);
        setNodes(canvasNodes);
        setEdges(canvasEdges);
        // Wait for next render cycle before updating viewport
        setTimeout(() => {
          setViewport({
            x: tempCanvas.x ?? 0,
            y: tempCanvas.y ?? 0,
            zoom: tempCanvas.zoom ?? 1,
          });
        }, 0);
        setCanvas(tempCanvas);
        setInitialNodes(canvasNodes);
        setInitialEdges(canvasEdges);
      }
      setProperties(toolProperties); // Update the state after fetching all data
      setFetched(true); // Set fetched to true after fetching
      return toolProperties;
    } catch (error) {
      throw error;
    }
  }
  // setViewport(
  //   diagram && diagram.viewPort && diagram.viewPort !== ""
  //     ? JSON.parse(diagram.viewPort)
  //     : { x: 0, y: 0, zoom: 1 }
  // );
  //for drawers
  const [openDrawer, setOpenDrawer] = useState(null);
  const [clickedEdge, setClickedEdge] = useState();
  const [selectedNode, setSelectedNode] = useState(null);
  //shrink the whole sidebar
  const [showmenudsk, setShowdskmenu] = useState(canvas?.leftMenuHide || false);
  //React flow variables
  // const [nodes, setNodes, onNodesChange] = useNodesState([]);
  // const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const reactFlowWrapper = useRef(null);
  const [rfInstance, setRfInstance] = useState(null);
  const { getLayoutedElements } = useLayoutedElements();
  const { getCustomLayoutedElements } = useCustomLayoutedElements();
  const { getNode } = useReactFlow();
  const [backgroundColor, setBackgroundColor] = useState(
    canvas?.background ?? themeProvider.palette.bg.lightWhite
  );
  //Function that control the toggle of the right drawer between (edge and node)
  const toggleDrawer = (drawerId) => {
    setOpenDrawer((prevDrawer) => (prevDrawer === drawerId ? null : drawerId));
  };
  //Drag and Drop
  const onDrop = useDrop(
    reactFlowWrapper,
    rfInstance,
    nodes,
    setNodes,
    project.region
  );
  const onDragOver = useDragOver();
  //Save and Restore
  // const onSave = useSave(flowKey, rfInstance);
  const bgColorRef = useRef(backgroundColor);

  // Update the ref whenever backgroundColor changes
  useEffect(() => {
    bgColorRef.current = backgroundColor;
  }, [backgroundColor]);

  const isLeftSideBarClosedRef = useRef(showmenudsk);

  // Update the ref whenever backgroundColor changes
  useEffect(() => {
    isLeftSideBarClosedRef.current = showmenudsk;
  }, [showmenudsk]);

  const onSave = useSave(
    diagram,
    rfInstance,
    jsonData,
    project.region,
    () => bgColorRef.current,
    project.showMinMap,
    project.layout,
    () => isLeftSideBarClosedRef.current
  );

  const initialNodesRef = useRef(initialNodes);

  // Update the ref whenever backgroundColor changes
  useEffect(() => {
    initialNodesRef.current = initialNodes;
  }, [initialNodes]);
  const initialEdgesRef = useRef(initialEdges);

  // Update the ref whenever backgroundColor changes
  useEffect(() => {
    initialEdgesRef.current = initialEdges;
  }, [initialEdges]);
  // const onRestore = useRestore(flowKey, setNodes, setEdges, setViewport);
  const onRestore = useRestore(
    diagram,
    setNodes,
    setEdges,
    setViewport,
    () => initialNodesRef.current,
    () => initialEdgesRef.current,
    project.region
  );
  //node functions
  //handle node change (for controlling delete)
  const handleNodesChange = (changes) => {
    const nextChanges = changes.reduce((acc, change) => {
      // if this change is supposed to remove a node we want to validate it first
      // if (change.type === "remove") {
      //   const node = getNode(change.id);

      //   // if the node can be removed, keep the change, otherwise we skip the change and keep the node
      //   if (node?.data[0]?.isDeletable !== "false") {
      //     return [...acc, change];
      //   }

      //   // change is skipped, node is kept
      //   return acc;
      // }

      // all other change types are just put into the next changes arr
      return [...acc, change];
    }, []);

    // apply the changes we kept
    onNodesChange(nextChanges);
  };
  //recursive node movement
  const updateNodeAndChildren = useUpdateNodeAndChildren(nodes, edges);
  const handleNodeDragStop = useNodeDragStop(
    setNodes,
    nodes,
    edges,
    updateNodeAndChildren
  );

  //delete node
  const onDeleteNode = useDeleteNode(
    setEdges,
    setNodes,
    nodes,
    edges,
    getIncomers,
    getOutgoers,
    getConnectedEdges,
    MarkerType
  );

  //edge functions
  //flip edge
  const handleFlipArrow = useFlipArrow(setEdges);
  //delete edge
  const handleDeleteEdge = useDeleteEdge(setEdges);
  //on edge click
  // const handleEdgeClick = useEdgeClick(handleFlipArrow);
  // const handleEdgeClick = useEdgeClick(setClickedEdge, toggleDrawer);
  // const handleNodeClick = useNodeClick(setSelectedNode, toggleDrawer);

  // const handleEdgeClick = useEdgeClick(
  //   setClickedEdge,
  //   toggleDrawer,
  //   setTabValue,
  //   tabValue
  // );
  // const handleNodeClick = useNodeClick(
  //   setSelectedNode,
  //   toggleDrawer,
  //   setTabValue,
  //   tabValue
  // );

  // const handleEdgeClick = useEdgeClick(
  //   setClickedEdge,
  //   toggleDrawer,
  //   setTabValue
  // );

  const handleEdgeClick = useEdgeClick(setClickedEdge, toggleDrawer, () =>
    setTabValue("2")
  );

  // const handleNodeClick = useNodeClick(
  //   setSelectedNode,
  // toggleDrawer,
  // setTabValue,
  // tabValue
  // );

  // const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // const onNodeRightClick = (event, node) => {
  //   event.preventDefault();
  //   setSelectedNode(node);
  //   setTabValue("1");
  //   setIsDrawerOpen(true);
  // };

  // const onNodeClick = (node) => {
  //   if (isDrawerOpen === true) {
  //     setSelectedNode(node);
  //   }
  // };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const onNodeRightClick = (event = null, node = null) => {
    if (event) {
      event.preventDefault();
    }
    setSelectedNode(node);
    setTabValue("1");
    setIsDrawerOpen(true);
  };

  const onNodeClick = (node) => {
    if (isDrawerOpen === true) {
      setSelectedNode(node);
    }
    if ((tabValue === "2" || tabValue === "3") && isDrawerOpen === false) {
      onNodeRightClick(null, node);
    }
  };

  //creating a new edge
  // const onConnect = useCallback(
  //   (params) => setEdges((eds) => addEdge(params, eds)),
  //   []
  // );
  // const onConnect = useCallback(
  //   ({ source, target }) => {
  //     return setEdges((eds) =>
  //       nodes
  //         .filter((node) => node.id === source || node.selected)
  //         .reduce((eds, node) => addEdge({ source: node.id, target }, eds), eds)
  //     );
  //   },
  //   [nodes]
  // );

  //when 2 nodes get connected by a line this function gets called
  const onConnect = useCallback(
    (params) => {
      const { source, target } = params;
      const sourceNode = nodes.find((node) => node.id === source);
      const targetNode = nodes.find((node) => node.id === target);
      // const edgeId = `e${source}-${target}${params.sourceHandle}${params.targetHandle}`;
      const edgeId = `${sourceNode.id}-${targetNode.id}`;
      let edgeType = "floating";
      // let edgeType = "bezier";

      if (sourceNode?.data[0]?.nodetypeid === "0") {
        edgeType = "smoothstep";
      }

      return setEdges((eds) =>
        addEdge(
          { ...params, id: edgeId, type: edgeType, interactionWidth: 30 },
          eds
        )
      );
    },
    [nodes, setEdges, project.region]
  );

  //Form
  const navigate = useNavigate();

  const handleOpenClick = () => {
    // Navigate to the "/diagrams" route
    navigate("/Select");
  };

  //search
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    console.log("e.target.value ", e.target.value);
    setSearchQuery(e.target.value);
  };

  const leftButton = {
    icon: Theme.Open,
    text: "Open",
    tooltip: "Click to open a different project or view a different template",
    handleClick: () => {
      // Handle click for the left button
      handleOpenClick();
    },
  };

  const rightButtons = [
    {
      icon: Theme.Save,
      text: "Save",
      color: "secondary",
      tooltip: "Click to save your canvas",
      handleClick: () => {
        if (window.confirm("Are you sure you want to save your changes?")) {
          onSave();
        }
      },
    },
    {
      icon: Theme.Restore,
      text: "Restore",
      color: "secondary",
      tooltip: "Click to restore your canvas",
      handleClick: () => {
        if (
          window.confirm(
            `Are you sure you want to restore to original?
          all unsaved changes will be lost.`
          )
        ) {
          onRestore();
        }
      },
    },
    {
      icon: Theme.GoBack,
      text: "Back",
      color: "secondary",
      tooltip: "Go back to previous page",
      handleClick: () => {
        navigate(-1);
      },
    },
  ];

  const changeBackgroundColor = () => {
    setBackgroundColor(
      backgroundColor === themeProvider.palette.bg.lightWhite
        ? "#d3d3d3"
        : themeProvider.palette.bg.lightWhite
    );
  };
  console.log("nodes ", nodes);
  console.log("edges ", edges);
  const handleMenushwDskButtonClick = () => {
    setShowdskmenu(!showmenudsk);
  };

  const [showReactFlow, setShowReactFlow] = useState(false);
  const handleTuneIconClick = () => {
    setShowReactFlow((prev) => !prev);
  };

  const handleOutsideNodeClick = (event) => {
    if (
      !event.target.closest(".react-flow__node") &&
      !event.target.closest(".rht-panel-dwr")
    ) {
      setSelectedNode(null);
      setIsDrawerOpen(false);
      if (
        !event.target.closest(".react-flow__edge") &&
        !event.target.closest(".rht-panel-dwr")
      ) {
        setClickedEdge(null);
      }
    }
  };

  const handleOutsideNodeRightClick = (event) => {
    event.preventDefault();

    if (
      !event.target.closest(".react-flow__node") &&
      !event.target.closest(".rht-panel-dwr")
    ) {
      setSelectedNode(null);
      setIsDrawerOpen(false);

      if (
        !event.target.closest(".react-flow__edge") &&
        !event.target.closest(".rht-panel-dwr")
      ) {
        setClickedEdge(null);
      }
    }
  };

  const [markerColor, setMarkerColor] = useState("#1A192B");
  return (
    <>
      <CustomMarkers />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <NavBar
        isSearchBar={true}
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        leftButton={leftButton}
        rightButtons={rightButtons}
        // title={project.title}
        explanation={project.explanation}
        icon={true}
        formData={project}
        handleMenushwDskButtonClick={handleMenushwDskButtonClick}
      />
      <div className={`dndflow`}>
        <DesktopSidebar
          showmenudsk={showmenudsk}
          setShowdskmenu={setShowdskmenu}
          nodes={data}
          properties={properties}
          Bid={diagram.Bid}
          startPanelsOpen={false}
          region={project.region}
          searchQuery={searchQuery}
        />

        <div
          className="reactflow-wrapper"
          ref={reactFlowWrapper}
          style={{
            display: "flex",
            width: "100%",
          }}
          // onContextMenu={(e) => e.preventDefault()}
          onContextMenu={handleOutsideNodeRightClick}
          onClick={handleOutsideNodeClick}
        >
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={handleNodesChange}
            onNodesDelete={onDeleteNode}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onInit={setRfInstance}
            onDrop={onDrop}
            onDragOver={onDragOver}
            // onNodeDragStop={handleNodeDragStop}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            // onNodeClick={handleNodeClick}
            // onNodeContextMenu={handleNodeClick}
            onNodeContextMenu={onNodeRightClick} // Right-click on nodes
            onNodeClick={(event, node) => onNodeClick(node)} // left-click on nodes
            connectionLineComponent={FloatingConnectionLine}
            onEdgeClick={handleEdgeClick}
            defaultEdgeOptions={defaultEdgeOptions}
            connectionMode="loose"
            // edgeHitTestThreshold={100}
            style={{ background: backgroundColor }}
            // fitView
            // fitViewOptions={fitViewOptions}
          >
            {backgroundColor === themeProvider.palette.bg.lightWhite && (
              // <Background />
              //   <Background
              //   id="1"
              //   gap={10}
              //   color="#f1f1f1"
              //   variant={BackgroundVariant.Lines}
              // />

              <Background
                id="2"
                gap={20}
                color="#f1f1f1"
                variant={BackgroundVariant.Lines}
              />
            )}
            <Controls>
              {(canvas?.layout ?? project.layout) && (
                <>
                  <ControlButton
                    onClick={() =>
                      getLayoutedElements({
                        "elk.algorithm": "layered",
                        "elk.direction": "RIGHT",
                        // "elk.direction": "LEFT",
                      })
                    }
                  >
                    <AlignHorizontalLeftIcon />
                  </ControlButton>
                  <ControlButton
                    onClick={() =>
                      getLayoutedElements({
                        "elk.algorithm": "layered",
                        "elk.direction": "DOWN",
                      })
                    }
                  >
                    <AlignVerticalTopIcon />
                  </ControlButton>
                  <ControlButton
                    onClick={() =>
                      getLayoutedElements({
                        "elk.algorithm": "org.eclipse.elk.force",
                      })
                    }
                  >
                    <AlignVerticalCenterIcon />
                  </ControlButton>
                  {/* <ControlButton onClick={() => getCustomLayoutedElements()}>
                    <Grid3x3Icon />
                  </ControlButton> */}
                  <ControlButton onClick={changeBackgroundColor}>
                    <FormatColorFillIcon />
                  </ControlButton>
                </>
              )}
            </Controls>
            {(canvas?.showMinMap ?? project.showMinMap) && <MiniMap />}
          </ReactFlow>

          {isDrawerOpen && (
            <NodeDrawerComponent
              isOpen={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}
              tabValue={tabValue}
              setTabValue={setTabValue}
              nodes={nodes}
              setNodes={setNodes}
              selectedNode={selectedNode}
              setSelectedNode={setSelectedNode}
              edges={edges}
              setEdges={setEdges}
              clickedEdge={clickedEdge}
              setClickedEdge={setClickedEdge}
              region={project.region}
            />
          )}
          {(tabValue === "2" || tabValue === "4") && clickedEdge ? (
            <NodeDrawerComponent
              isOpen={isDrawerOpen}
              onClose={() => setIsDrawerOpen(false)}
              tabValue={tabValue}
              setTabValue={setTabValue}
              nodes={nodes}
              setNodes={setNodes}
              selectedNode={selectedNode}
              setSelectedNode={setSelectedNode}
              edges={edges}
              setEdges={setEdges}
              clickedEdge={clickedEdge}
              setClickedEdge={setClickedEdge}
              region={project.region}
              markerColor={markerColor}
              setMarkerColor={setMarkerColor}
            />
          ) : null}
        </div>
      </div>
      {error && <ErrorBanner />}
    </>
  );
};

// export default Diagrams;
export default () => (
  <ReactFlowProvider>
    <Diagrams />
  </ReactFlowProvider>
);
