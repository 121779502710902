import React, { useState, useMemo } from "react";
import PropertiesAccordion from "../../../reusableComponents/PropertiesAccordion";
import IconBox from "../IconBox";
import FontDropDown from "./FontDropDown";
import { IconButton } from "@mui/material";

import {
  PiAlignRightSimpleBold,
  PiAlignLeftSimpleDuotone,
  PiAlignBottomSimpleBold,
  PiAlignCenterHorizontalSimpleThin,
} from "react-icons/pi";
import { BsAlignCenter } from "react-icons/bs";
import { RxStretchHorizontally, RxStretchVertically } from "react-icons/rx";
import { TbLayoutAlignTop } from "react-icons/tb";
import EditIcon from "@mui/icons-material/Edit";
import EditCss from "../../../reusableComponents/EditCss";
import BodyContentDropDown from "./BodyContentDropDown";

function BodyDropDowns({
  selectedItemID,
  newProperties,
  handlePropertyChange,
  tempNodes,
  selectedTool
}) {
  const nodeId = selectedTool?.tasktypeid?.toString();

  // Retrieve default or special properties based on node ID
  const specialProperties = useMemo(
    () =>
      newProperties?.Nodes?.find((node) => node?.id?.toString() === nodeId) ||
      null,
    [newProperties?.Nodes, nodeId]
  );

  const properties = useMemo(
    () =>
      specialProperties
        ? specialProperties.customstyle
        : tempNodes[selectedItemID]
          ? tempNodes[selectedItemID]
          : newProperties[selectedTool?.category || "<all>"] || {},
    [specialProperties, newProperties, selectedTool, tempNodes, selectedItemID]
  );
  const [openPopup, setOpenPopup] = useState(false);
  const handlePopup = () => {
    setOpenPopup(!openPopup);
  };
  const [horizontalAlignment, setHorizontalAlignment] = useState({
    left: properties.body.justifyContent === "start" ? 1 : 0,
    horizontalCenter: properties.body.justifyContent === "start" ? 1 : 0,
    right: properties.body.justifyContent === "start" ? 1 : 0,
  });

  // const [verticalAlignment, setVerticalAlignment] = useState({
  //   top: 0,
  //   verticalCenter: 0,
  //   bottom: 0,
  //   verticalStretch: 0,
  // });
  const [bodyColor, setBodyColor] = useState({
    backgroundColor: "",
    color: "",
  });
  const handleAlignmentChange = (name, value) => {
    // Update the appropriate state variable based on the name passed
    if (name === "start" || name === "center" || name === "end") {
      setHorizontalAlignment({
        left: name === "start" ? value : 0,
        horizontalCenter: name === "center" ? value : 0,
        right: name === "end" ? value : 0,
      });
      handlePropertyChange(selectedTool, "body", "justifyContent", name);
    }
  };
  return (
    <>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            margin: "0.5rem 0",
            cursor: "pointer", // Adding cursor style to indicate it's clickable
          }}
          onClick={handlePopup}
        >
          Body Default Css
        </div>
        <IconButton onClick={handlePopup}>
          <EditIcon />
        </IconButton>
      </div> */}
      {/* {openPopup && (
        <EditCss
          value={
            editedProperties
              .flatMap((propertyGroup) => propertyGroup)
              .find(
                (property) =>
                  property.diagToolID === selectedItemID &&
                  property.propertyName === "Body_Default_CSS"
              )?.diagPropValue
          }
          onChange={(newValue) =>
            handlePropertyChange("Body_Default_CSS", newValue.target.value)
          }
          handlePopup={handlePopup}
        />
      )} */}

      <PropertiesAccordion
        isOpen={false}
        title={"Body Alignment"}
        onRightClick={handlePopup}
      >
        <IconBox
          handleChange={handleAlignmentChange}
          buttonList={[
            {
              icon: <PiAlignLeftSimpleDuotone />,
              name: "start",
              value: horizontalAlignment.left,
            },
            {
              icon: <BsAlignCenter />,
              name: "center",
              value: horizontalAlignment.horizontalCenter,
            },
            {
              icon: <PiAlignRightSimpleBold />,
              name: "end",
              value: horizontalAlignment.right,
            },
          ]}
        />
        {/* <IconBox
          handleChange={handleAlignmentChange}
          buttonList={[
            {
              icon: <TbLayoutAlignTop />,
              name: "start",
              value: verticalAlignment.top,
            },
            {
              icon: <PiAlignCenterHorizontalSimpleThin />,
              name: "center",
              value: verticalAlignment.verticalCenter,
            },
            {
              icon: <PiAlignBottomSimpleBold />,
              name: "end",
              value: verticalAlignment.bottom,
            },
            {
              icon: <RxStretchVertically />,
              name: "stretch",
              value: verticalAlignment.verticalStretch,
            },
          ]}
        /> */}
      </PropertiesAccordion>
      <PropertiesAccordion
        isOpen={false}
        title={"Body Font"}
        onRightClick={handlePopup}
      >
        <FontDropDown
          properties={properties}
          selectedTool={selectedTool}
          handlePropertyChange={handlePropertyChange}
          propSection="body"
        />
      </PropertiesAccordion>
      <PropertiesAccordion
        isOpen={false}
        title={"Body Color"}
        onRightClick={handlePopup}
      >
        <div className="backgrounclr ">
          Background Color
          <div>
            <input
              type="color"
              name="backgroundColor"
              value={properties.body.backgroundColor}
              onChange={(e) => {
                handlePropertyChange(
                  selectedTool,
                  "body",
                  "backgroundColor",
                  e.target.value
                );
              }}
            />
            <div className="iconset clearColorWrap">
              <div
                className="clearColor"
                onClick={(e) => {
                  handlePropertyChange(
                    selectedTool,
                    "body",
                    "backgroundColor",
                    e.target.value
                  );
                }}
              >
                <span className="crsLinelft"></span>
                <span className="crsLinerht"></span>
                <span className="tooltiptext">Transparent</span>
              </div>
            </div>
          </div>
        </div>
        <div className="backgrounclr ">
          Color
          <input
            type="color"
            name="color"
            value={properties.body.color}
            onChange={(e) => {
              handlePropertyChange(
                selectedTool,
                "body",
                "color",
                e.target.value
              );
            }}
          />
        </div>
      </PropertiesAccordion>
      {/* <PropertiesAccordion
        isOpen={false}
        title={"Body Content"}
        onRightClick={handlePopup}
      >
        <BodyContentDropDown
          properties={properties}
          selectedTool={selectedTool}
          handlePropertyChange={handlePropertyChange}
        />
      </PropertiesAccordion> */}
    </>
  );
}

export default BodyDropDowns;
