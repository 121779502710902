import React, { useState, useMemo } from "react";
import PropertiesAccordion from "../../../reusableComponents/PropertiesAccordion";
import FontDropDown from "./FontDropDown";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import NodeBorderDropDown from "./NodeBorderDropDown";
import PaddingDropDown from "./PaddingDropDown";
import EditCss from "../../../reusableComponents/EditCss";
import EditIcon from "@mui/icons-material/Edit";

// const nodeTypeLabels = {
//   0: "Default",
//   1: "Start",
//   2: "End",
// };
function NodeDropDowns({
  selectedItemID,
  newProperties,
  handlePropertyChange,
  tempNodes,
  selectedTool,
}) {
  const nodeId = selectedTool?.tasktypeid?.toString();

  // Retrieve default or special properties based on node ID
  const specialProperties = useMemo(
    () =>
      newProperties?.Nodes?.find((node) => node?.id?.toString() === nodeId) ||
      null,
    [newProperties?.Nodes, nodeId]
  );

  const properties = useMemo(
    () =>
      specialProperties
        ? specialProperties.customstyle
        : tempNodes[selectedItemID]
          ? tempNodes[selectedItemID]
          : newProperties[selectedTool?.category || "<all>"] || {},
    [specialProperties, newProperties, selectedTool, tempNodes, selectedItemID]
  );
  const [openPopup, setOpenPopup] = useState(false);

  return (
    <>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            margin: "0.5rem 0",
            cursor: "pointer", // Adding cursor style to indicate it's clickable
          }}
          onClick={() => setOpenPopup(true)}
        >
          Node Default Css
        </div>
        <IconButton onClick={() => setOpenPopup(true)}>
          <EditIcon />
        </IconButton>
      </div> */}
      {/* {openPopup && (
        <EditCss
          value={
            editedProperties
              .flatMap((propertyGroup) => propertyGroup)
              .find(
                (property) =>
                  property.diagToolID === selectedItemID &&
                  property.propertyName === "Node_Default_CSS"
              )?.diagPropValue
          }
          onChange={(newValue) =>
            handlePropertyChange("Node_Default_CSS", newValue.target.value)
          }
          handlePopup={() => setOpenPopup(false)}
        />
      )} */}
      <PropertiesAccordion
        isOpen={false}
        title={"Node Color"}
        onRightClick={() => setOpenPopup(true)}
      >
        <div
          className="backgrounclr"
          style={{ justifyContent: "space-between" }}
        >
          Background Color
          <div>
            <input
              type="color"
              name="backgroundColor"
              value={properties.node.backgroundColor}
              onChange={(e) => {
                handlePropertyChange(
                  selectedTool,
                  "node",
                  "backgroundColor",
                  e.target.value
                );
              }}
            />
            <div className="iconset clearColorWrap">
              <div
                className="clearColor"
                onClick={(e) => {
                  handlePropertyChange(
                    selectedTool,
                    "node",
                    "backgroundColor",
                    "transparent"
                  );
                }}
              >
                <span className="crsLinelft"></span>
                <span className="crsLinerht"></span>
                <span className="tooltiptext">Transparent</span>
              </div>
            </div>
          </div>
        </div>
        <div
          className="backgrounclr"
          style={{ justifyContent: "space-between" }}
        >
          Color
          <input
            type="color"
            name="color"
            value={properties.node.color}
            onChange={(e) => {
              handlePropertyChange(
                selectedTool,
                "node",
                "color",
                e.target.value
              );
            }}
          />
        </div>
      </PropertiesAccordion>
      <PropertiesAccordion
        isOpen={false}
        title={"Node Border"}
        onRightClick={() => setOpenPopup(true)}
      >
        <NodeBorderDropDown
          properties={properties}
          selectedTool={selectedTool}
          handlePropertyChange={handlePropertyChange}
        />
      </PropertiesAccordion>
      <PropertiesAccordion
        isOpen={false}
        title={"Node Padding"}
        onRightClick={() => setOpenPopup(true)}
      >
        <PaddingDropDown
          properties={properties}
          selectedTool={selectedTool}
          handlePropertyChange={handlePropertyChange}
        />
      </PropertiesAccordion>
      <PropertiesAccordion
        isOpen={false}
        title={"Node Font"}
        onRightClick={() => setOpenPopup(true)}
      >
        <FontDropDown
          properties={properties}
          selectedTool={selectedTool}
          handlePropertyChange={handlePropertyChange}
          propSection="node"
        />
      </PropertiesAccordion>
    </>
  );
}

export default NodeDropDowns;
