import React, { useEffect, useState } from "react";
import { Box, Input, TextField, Typography } from "@mui/material";
import TabsComponent from "../diagramsComponents/TabsComponent";
import { Theme, Image, countries } from "../IncludeFile/Theme";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import NativeSelect from "@mui/material/NativeSelect";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import "./RecursiveTreeView.css";
import Controls from "../IncludeFile/Controls";
import { findNodeById } from "../Api/ApiCalls";
import ErrorBanner from "../../Error/ErrorBanner";

const GeneralSection = ({
  tabValue,
  setTabValue,
  rightsidetabs,
  selectedItem,
  isRootItem,
  treeData,
  editedData,
  handleEditChange,
  tabSelect,
  handleEnterForNumber,
  handleAgree,
  handlePopupClick,
  isPopupVisible,
  handleClose,
  selectedOption,
  dataSourceArID,
  fieldNames,
  fieldNamestable,
  handleChange,
  inputValueRadio,
  handleInputChangeRadio,
  handleAddControlRadio,
  inputValue,
  handleInputChange,
  handleAddControlCheckbox,
  selectedOptioncheck,
  handleCheckboxChange,
  selectedPositioncheck,
  handleCheckboxPositionChange,
  selectedOptionSwitchcheck,
  handleSwitchChange,
  inputRef,
  handleImageChange,
  handleImageClick,
  handleDateChange,
  isOpen,
  handleEditChangeName1,
  handleRenameConfirm,
  controlsVisible,
  addNewColumn,
  selectedControlType,
  handleControlTypeChange,
  editedName,
  handleEditChangeforTable,
  fieldData,
  setFieldNames,
  handleRadioChange,
  selectedControl,
  setTreeData,
  setEditedData,
  selectedItemID,
  defaultStyle,
  selectedNode,
  updateCustomStyle,
  isRequiredFullName,
  setIsRequiredFullName,
  isRequiredAddress,
  setIsRequiredAddress,
  isRequiredEmail,
  setIsRequiredEmail,
  isRequiredPhoneNumber,
  setIsRequiredPhoneNumber,
  error,
}) => {

  const [editedDataRatingLabel, setEditedDataRatingLabel] = useState("");
  const [controlChanged, setcontrolChanged] = useState(null);
  const [DateField, SetDateField] = useState([]);
  const [ImageField, setImageField] = useState([]);
  const [IframeField, setIframeField] = useState([]);
  const [switchField, setSwitchField] = useState([]);
  const [checkboxField, setCheckboxField] = useState([]);
  const [RadioField, setRadioField] = useState([]);
  const [editedDataHeader, setEditedDataHeader] = useState("");
  const [editedDataLongTextLabel, setEditedDataLongTextLabel] = useState("");
  const [editedDataShortTextLabel, setEditedDataShortTextLabel] = useState("");
  const [editedDataPhoneLabel, setEditedDataPhoneLabel] = useState("");
  const [editedDataEmailLabel, setEditedDataEmailLabel] = useState("");
  const [editedDataDropDownLabel, setEditedDataDropDownLabel] = useState("");
  const [editedDataFullName, setEditedDataFullName] = useState("");
  const [editedDataHeading, setEditedDataHeading] = useState("");
  const [editedDataSubHeading, setEditedDataSubHeading] = useState("");
  const [editedDataSingleChoiceLabel, setEditedDataSingleChoiceLabel] =
    useState("");
  const [editedDataCheckboxHeading, setEditedDataCheckboxHeading] =
    useState("");
  const [editedDataDateLabel, setEditedDataDateLabel] = useState("");
  const [alignMent, setAlignMent] = useState("");
  const [FontSize, setFontSize] = useState(12);

  useEffect(() => {
    setIsRequiredFullName(editedDataFullName.includes("*"));
    setIsRequiredAddress(editedDataHeader.includes("*"));
    setIsRequiredEmail(editedDataEmailLabel.includes("*"));
    setIsRequiredPhoneNumber(editedDataPhoneLabel.includes("*"));
  }, [
    editedDataFullName,
    editedDataHeader,
    editedDataEmailLabel,
    editedDataPhoneLabel,
  ]);

  useEffect(() => {
    if (fieldData) {
      const textFields = [];
      const imageFields = [];
      const iframeFields = [];
      const switchFields = [];
      const checkboxFields = [];
      const radioFields = [];
  
      fieldData.forEach((item) => {
        if (item && item.fieldname) {
          const fieldName = item.fieldname.toLowerCase();
          if (item.elementtype === "field") {
            if (item.fieldtype === "text") {
              textFields.push(fieldName);
            } else if (item.fieldtype === "Image") {
              imageFields.push(fieldName);
            } else if (item.fieldtype === "Iframe") {
              iframeFields.push(fieldName);
            } else if (
              item.fieldtype === "boolean" ||
              item.fieldtype === "number"
            ) {
              switchFields.push(fieldName);
              checkboxFields.push(fieldName);
              radioFields.push(fieldName);
            }
          }
        }
      });
      setFieldNames(textFields);
      setImageField(imageFields);
      setIframeField(iframeFields);
      setSwitchField(switchFields);
      setCheckboxField(checkboxFields);
      setRadioField(radioFields);
    }
  }, [fieldData]);

  useEffect(() => {
    const fieldNamesDate = fieldData
      ? fieldData
          .filter(
            (item) =>
              (item.elementtype === "field" && item.fieldtype === "datetime") ||
              (item.elementtype === "field" && item.fieldtype === "date") ||
              (item.elementtype === "field" && item.fieldtype === "time")
          )
          .map((item) => item.fieldname.toLowerCase())
      : [];

    SetDateField(fieldNamesDate);
  }, [fieldData]);

  const findControlcontrolChange = (controls, selectedControlType) => {
    for (const control of controls) {
      if (control.controlChange === selectedControlType) {
        return control.controlChange;
      }
      if (control.control && control.control.length > 0) {
        const nestedControlcontrolChange = findControlcontrolChange(
          control.control,
          selectedControlType
        );
        if (nestedControlcontrolChange) return nestedControlcontrolChange;
      }
    }
    return null;
  };

  useEffect(() => {
    if (treeData.length > 0 && treeData[0].Source && treeData[0].Source[0]) {
      const changed = findControlcontrolChange(
        treeData[0].Source[0].control,
        selectedControlType
      );
      setcontrolChanged(changed);
    }
  }, [selectedControlType]);

  const menuItems = Controls.filter(
    (category) => category.title === "Display" || category.title === "Input"
  ).flatMap((category) =>
    category.items
      .filter(
        (item) =>
          ![
            "List",
            "Table",
            "TableLabel",
            "Radio",
            "Dropdown",
            "Date",
            "Divider",
            "Switch",
            "Checkbox",
            "Iframe",
            "Row",
          ].includes(item.controltype)
      )
      .map((item) => ({
        key: item.controltype,
        value: item.controltype,
      }))
  );

  menuItems.sort((a, b) => a.value.localeCompare(b.value));

  const applyStylesRecursively = (control, defaultStyle, parentID = "") => {
    const defaultSty = defaultStyle.find(
      (style) => style.styleType === control.styleType
    );

    const newID = parentID
      ? `${parentID}.${control.id.split(".").pop()}`
      : control.id;

    let updatedStyles = { ...defaultSty?.style };

    if (
      control.media === "CityState" ||
      control.media === "Address" ||
      control.media === "PostalCountry"
    ) {
      updatedStyles.flexDirection = "row";
    }

    if (control.media === "AddressHeader") {
      updatedStyles.width = "20%";
    }

    if (control.media === "AddressInput") {
      updatedStyles.width = "80%";
    }

    if (control.switchoption === "AddressContainer") {
      updatedStyles.marginTop = "0px";
      updatedStyles.marginLeft = "0px";
      updatedStyles.marginRight = "0px";
      updatedStyles.marginBottom = "0px";
      updatedStyles.paddingTop = "0px";

      updatedStyles.paddingRight = "0px";
      updatedStyles.paddingBottom = "0px";
      updatedStyles.paddingLeft = "0px";
    }

    const updatedControl = {
      ...control,
      id: newID,
      styles: JSON.stringify(updatedStyles),
    };

    if (updatedControl.control && updatedControl.control.length > 0) {
      updatedControl.control = updatedControl.control.map(
        (child, childIndex) => {
          const childNewID = `${newID}.${childIndex + 1}`;
          return applyStylesRecursively(
            { ...child, id: childNewID },
            defaultStyle,
            newID
          );
        }
      );
    }

    return updatedControl;
  };

  const calculateNextStyleIdBlocks = () => {
    const deletedItemId = localStorage.getItem("deletedItemId");

    const deletedItemNumber = deletedItemId
      ? parseInt(deletedItemId.replace(/[a-zA-Z]/g, ""))
      : -1;

    let maxStyleId = 0;

    const findMaxStyleId = (node) => {
      const styleIdRegex = /([a-zA-Z]+)(\d*)/;
      if (typeof node.styleid === "string") {
        const matches = node.styleid.match(styleIdRegex);
        if (matches && matches[1] === "agrfb") {
          const currentId = parseInt(matches[2]);
          if (!isNaN(currentId) && currentId > maxStyleId) {
            maxStyleId = currentId;
          }
        }
      }

      if (node.control && node.control.length > 0) {
        for (const childNode of node.control) {
          findMaxStyleId(childNode);
        }
      }
    };

    treeData[0].Source[0].control.forEach((rootNode) => {
      findMaxStyleId(rootNode);
    });

    const nextStyleId =
      deletedItemNumber > maxStyleId ? deletedItemNumber + 1 : maxStyleId + 1;

    return `agrfb${nextStyleId}`;
  };

  function findMediaPostal(treeData) {
    const foundNodes = [];

    function searchTree(node) {
      if (node.media === "Postal") {
        foundNodes.push(node);
      }
      if (node.control && Array.isArray(node.control)) {
        node.control.forEach((child) => searchTree(child));
      }
    }

    treeData.forEach((rootNode) => searchTree(rootNode));

    return foundNodes;
  }

  const addCountryJSONToContainer = (controlArray, mediaToAdd, countryJSON) => {
    return controlArray.map((control) => {
      if (control.media === mediaToAdd && control.control.length === 0) {
        const index = 0;
        const currentMaxStyleId = calculateNextStyleIdBlocks();
        const styleIdNumber = parseInt(
          currentMaxStyleId.replace(/[a-zA-Z]/g, "")
        );

        const styledCountryJSON = countryJSON.map((json, index) => {
          const newStyleId = `agrfb${styleIdNumber + index}`;
          return {
            ...applyStylesRecursively(json, defaultStyle),
            id: `${control.id}.${index + 1}`,
            styleid: newStyleId,
          };
        });

        control.control.push(...styledCountryJSON);
      }

      if (control.control && control.control.length > 0) {
        control.control = addCountryJSONToContainer(
          control.control,
          mediaToAdd,
          countryJSON
        );
      }
      return control;
    });
  };

  const updateControlsnewValueAddressCountryTextbox = (
    controls,
    selectedItemID,
    newValue
  ) => {
    return controls.map((control) => {
      if (control.id === selectedItemID) {
        return {
          ...control,
          control: updateControlsWithinAddressCountryTextbox(
            control.control,
            newValue
          ),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsnewValueAddressCountryTextbox(
            control.control,
            selectedItemID,
            newValue
          ),
        };
      }

      return control;
    });
  };

  const updateControlsWithinAddressCountryTextbox = (controls, newValue) => {
    return controls.map((control) => {
      if (control.checkposition === "CountryTextbox") {
        return {
          ...control,
          data: newValue,
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsWithinAddressCountryTextbox(
            control.control,
            newValue
          ),
        };
      }

      return control;
    });
  };

  const findInControlsCountryTextbox = (controls, selectedItemID) => {
    for (let control of controls) {
      if (control.id === selectedItemID) {
        const addressCountryTextboxControl = findAddressCountryTextboxControl(
          control.control
        );
        if (addressCountryTextboxControl) {
          return addressCountryTextboxControl.data;
        }
      }
      if (control.control && control.control.length > 0) {
        let foundData = findInControlsCountryTextbox(
          control.control,
          selectedItemID
        );
        if (foundData) return foundData;
      }
    }
    return null;
  };

  const findAddressCountryTextboxControl = (controls) => {
    for (let control of controls) {
      if (control.checkposition === "CountryTextbox") {
        return control;
      }
      if (control.control && control.control.length > 0) {
        let foundControl = findAddressCountryTextboxControl(control.control);
        if (foundControl) return foundControl;
      }
    }
    return null;
  };

  //--------------------editedData DropDownLabel---------------------

  const updateTreeDatanewValueAddressDropDownLabel = (
    treeData,
    selectedItemID,
    newValue
  ) => {
    return treeData.map((item) => {
      return {
        ...item,
        Source: item.Source.map((source) => ({
          ...source,
          control: updateControlsnewValueAddressDropDownLabel(
            source.control,
            selectedItemID,
            newValue
          ),
        })),
      };
    });
  };

  const updateControlsnewValueAddressDropDownLabel = (
    controls,
    selectedItemID,
    newValue
  ) => {
    return controls.map((control) => {
      if (control.id === selectedItemID) {
        return {
          ...control,
          control: updateControlsWithinAddressDropDownLabel(
            control.control,
            newValue
          ),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsnewValueAddressDropDownLabel(
            control.control,
            selectedItemID,
            newValue
          ),
        };
      }

      return control;
    });
  };

  const updateControlsWithinAddressDropDownLabel = (controls, newValue) => {
    return controls.map((control) => {
      if (control.checkposition === "DropDownLabel") {
        return {
          ...control,
          data: newValue,
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsWithinAddressDropDownLabel(
            control.control,
            newValue
          ),
        };
      }

      return control;
    });
  };

  const findAddressDropDownLabelData = (treeData, selectedItemID) => {
    for (let item of treeData) {
      for (let source of item.Source) {
        let foundData = findInControlsDropDownLabel(
          source.control,
          selectedItemID
        );
        if (foundData) return foundData;
      }
    }
    return "";
  };

  const findInControlsDropDownLabel = (controls, selectedItemID) => {
    for (let control of controls) {
      if (control.id === selectedItemID) {
        const addressDropDownLabelControl = findAddressDropDownLabelControl(
          control.control
        );
        if (addressDropDownLabelControl) {
          return addressDropDownLabelControl.data;
        }
      }
      if (control.control && control.control.length > 0) {
        let foundData = findInControlsDropDownLabel(
          control.control,
          selectedItemID
        );
        if (foundData) return foundData;
      }
    }
    return null;
  };

  const findAddressDropDownLabelControl = (controls) => {
    for (let control of controls) {
      if (control.checkposition === "DropDownLabel") {
        return control;
      }
      if (control.control && control.control.length > 0) {
        let foundControl = findAddressDropDownLabelControl(control.control);
        if (foundControl) return foundControl;
      }
    }
    return null;
  };

  useEffect(() => {
    if (selectedItem?.controltype === "DropDown") {
      const initialDataDropDownLabel = findAddressDropDownLabelData(
        treeData,
        selectedItemID
      );
      setEditedDataDropDownLabel(initialDataDropDownLabel);
    }
  }, [treeData, selectedItemID, selectedItem]);

  // heading

  const updateTreeDatanewValueAddressHeading = (
    treeData,
    selectedItemID,
    newValue,
    textAlign,
    fontSize
  ) => {
    return treeData.map((item) => {
      return {
        ...item,
        Source: item.Source.map((source) => ({
          ...source,
          control: updateControlsnewValueAddressHeading(
            source.control,
            selectedItemID,
            newValue,
            fontSize
          ),
        })),
      };
    });
  };

  const updateControlsnewValueAddressHeading = (
    controls,
    selectedItemID,
    newValue,

    fontSize
  ) => {
    return controls.map((control) => {
      if (control.id === selectedItemID) {
        return {
          ...control,
          control: updateControlsWithinAddressHeading(
            control.control,
            newValue,

            fontSize
          ),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsnewValueAddressHeading(
            control.control,
            selectedItemID,
            newValue,

            fontSize
          ),
        };
      }

      return control;
    });
  };

  const updateControlsWithinAddressHeading = (
    controls,
    newValue,

    fontSize
  ) => {
    return controls.map((control) => {
      if (control.checkposition === "Heading") {
        let styles = control.styles ? JSON.parse(control.styles) : {};

        if (fontSize) styles.fontSize = fontSize;

        return {
          ...control,
          data: newValue,
          styles: JSON.stringify(styles),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsWithinAddressHeading(
            control.control,
            newValue,

            fontSize
          ),
        };
      }

      return control;
    });
  };

  const updateTreeDatanewValueAddressSubHeading = (
    treeData,
    selectedItemID,
    newValue,
    textAlign,
    fontSize
  ) => {
    return treeData.map((item) => {
      return {
        ...item,
        Source: item.Source.map((source) => ({
          ...source,
          control: updateControlsnewValueAddressSubHeading(
            source.control,
            selectedItemID,
            newValue,
            fontSize
          ),
        })),
      };
    });
  };

  const updateControlsnewValueAddressSubHeading = (
    controls,
    selectedItemID,
    newValue,

    fontSize
  ) => {
    return controls.map((control) => {
      if (control.id === selectedItemID) {
        return {
          ...control,
          control: updateControlsWithinAddressSubHeading(
            control.control,
            newValue,

            fontSize
          ),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsnewValueAddressSubHeading(
            control.control,
            selectedItemID,
            newValue,

            fontSize
          ),
        };
      }

      return control;
    });
  };

  const updateControlsWithinAddressSubHeading = (
    controls,
    newValue,

    fontSize
  ) => {
    return controls.map((control) => {
      if (control.checkposition === "SubHeader") {
        let styles = control.styles ? JSON.parse(control.styles) : {};

        if (fontSize) styles.fontSize = fontSize;

        return {
          ...control,
          data: newValue,
          styles: JSON.stringify(styles),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsWithinAddressSubHeading(
            control.control,
            newValue,

            fontSize
          ),
        };
      }

      return control;
    });
  };

  const matchingNode = findNodeById(
    treeData[0].Source[0].control,
    selectedItem.id
  );

  function collectStylesofcontainer(node, result = []) {
    if (!node) return result; // Check if the node is valid

    if (node.checkposition === "Heading") {
      result.push({
        styleid: node.styleid,
        styles: node.styles,
      });
    }

    // Recursively call for child nodes if they exist
    if (Array.isArray(node.control) && node.control.length > 0) {
      node.control.forEach((child) => collectStylesofcontainer(child, result));
    }

    return result;
  }

  function collectStylesofcontainerforAlignment(node, result = []) {
    if (!node) return result; // Check if the node is valid

    if (
      node.checkposition === "SubHeader" ||
      node.checkposition === "Heading"
    ) {
      result.push({
        styleid: node.styleid,
        styles: node.styles,
      });
    }

    // Recursively call for child nodes if they exist
    if (Array.isArray(node.control) && node.control.length > 0) {
      node.control.forEach((child) =>
        collectStylesofcontainerforAlignment(child, result)
      );
    }

    return result;
  }

  const stylesofcontainer = matchingNode
    ? collectStylesofcontainer(matchingNode)
    : [];

  const stylesofcontainerForAlignment = matchingNode
    ? collectStylesofcontainerforAlignment(matchingNode)
    : [];

  function applyFontColorToStylesconatiner(stylesArray, alignment) {
    stylesArray.forEach((style) => {
      let styles;
      try {
        styles = JSON.parse(style.styles); // Parse styles safely
      } catch (e) {
        console.error("Failed to parse styles:", style.styles);
        return; // Skip to the next style if parsing fails
      }
      styles.textAlign = alignment;

      console.log("styles.textAlign", styles.textAlign);
      setAlignMent(styles.textAlign);
      updateCustomStyle(style.styleid, styles);
    });
  }

  const applyFontSizeToContainer = (stylesArray, FontSize) => {
    stylesArray.forEach((style) => {
      let styles;
      try {
        styles = JSON.parse(style.styles); // Parse styles safely
      } catch (e) {
        console.error("Failed to parse styles:", style.styles);
        return; // Skip to the next style if parsing fails
      }
      styles.fontSize = FontSize; // Apply font size
      setFontSize(FontSize); // Update the font size state
      updateCustomStyle(style.styleid, styles); // Update the style in the DOM/state
    });
  };

  const handleAlignmentChangeHeading = (alignment) => {
    applyFontColorToStylesconatiner(stylesofcontainerForAlignment, alignment);
  };

  useEffect(() => {
    // Assuming stylesArray is updated elsewhere, we parse the first element's textAlign if it exists
    if (
      stylesofcontainerForAlignment &&
      stylesofcontainerForAlignment.length > 0
    ) {
      let styles;
      try {
        styles = JSON.parse(stylesofcontainerForAlignment[0].styles); // Parse styles safely
        if (styles.textAlign) {
          setAlignMent(styles.textAlign); // Set the alignment state
        }
      } catch (e) {
        console.error(
          "Failed to parse styles:",
          stylesofcontainerForAlignment[0].styles
        );
      }
    }
  }, [stylesofcontainerForAlignment]);

  useEffect(() => {
    // Assuming stylesArray is updated elsewhere, we parse the first element's textAlign if it exists
    if (stylesofcontainer && stylesofcontainer.length > 0) {
      let styles;
      try {
        styles = JSON.parse(stylesofcontainer[0].styles); // Parse styles safely
        if (styles.fontSize) {
          setFontSize(styles.fontSize); // Set the alignment state
        }
      } catch (e) {
        console.error("Failed to parse styles:", stylesofcontainer[0].styles);
      }
    }
  }, [stylesofcontainer]);

  const handleFontSizeChangeHeading = (fontSize) => {
    applyFontSizeToContainer(stylesofcontainer, fontSize);
  };

  const findAddressHeadingData = (treeData, selectedItemID) => {
    for (let item of treeData) {
      for (let source of item.Source) {
        let foundData = findInControlsHeading(source.control, selectedItemID);
        if (foundData) return foundData;
      }
    }
    return "";
  };

  const findInControlsHeading = (controls, selectedItemID) => {
    for (let control of controls) {
      if (control.id === selectedItemID) {
        const addressHeadingControl = findAddressHeadingControl(
          control.control
        );
        if (addressHeadingControl) {
          return addressHeadingControl.data;
        }
      }
      if (control.control && control.control.length > 0) {
        let foundData = findInControlsHeading(control.control, selectedItemID);
        if (foundData) return foundData;
      }
    }
    return null;
  };

  const findAddressHeadingControl = (controls) => {
    for (let control of controls) {
      if (control.checkposition === "Heading") {
        return control;
      }
      if (control.control && control.control.length > 0) {
        let foundControl = findAddressHeadingControl(control.control);
        if (foundControl) return foundControl;
      }
    }
    return null;
  };

  const findAddressSubHeadingData = (treeData, selectedItemID) => {
    for (let item of treeData) {
      for (let source of item.Source) {
        let foundData = findInControlsSubHeading(
          source.control,
          selectedItemID
        );
        if (foundData) return foundData;
      }
    }
    return "";
  };

  const findInControlsSubHeading = (controls, selectedItemID) => {
    for (let control of controls) {
      if (control.id === selectedItemID) {
        const addressSubHeadingControl = findAddressSubHeadingControl(
          control.control
        );
        if (addressSubHeadingControl) {
          return addressSubHeadingControl.data;
        }
      }
      if (control.control && control.control.length > 0) {
        let foundData = findInControlsSubHeading(
          control.control,
          selectedItemID
        );
        if (foundData) return foundData;
      }
    }
    return null;
  };

  const findAddressSubHeadingControl = (controls) => {
    for (let control of controls) {
      if (control.checkposition === "SubHeader") {
        return control;
      }
      if (control.control && control.control.length > 0) {
        let foundControl = findAddressSubHeadingControl(control.control);
        if (foundControl) return foundControl;
      }
    }
    return null;
  };

  const handleFieldChangeAddressHeading = (event) => {
    const newValue = event.target.value;
    setEditedDataHeading(newValue);
    const updatedTreeDataAddressHeading = updateTreeDatanewValueAddressHeading(
      treeData,
      selectedItemID,
      newValue
    );
    setTreeData(updatedTreeDataAddressHeading);
  };

  const handleFieldChangeAddressSubHeading = (event) => {
    const newValue = event.target.value;
    setEditedDataSubHeading(newValue);
    const updatedTreeDataAddressSubHeading =
      updateTreeDatanewValueAddressSubHeading(
        treeData,
        selectedItemID,
        newValue
      );
    setTreeData(updatedTreeDataAddressSubHeading);
  };

  // const parentID = "0"

  const findMaxNumber = (node, controlType) => {
    let maxNumber = 0;
    if (node.control) {
      for (const child of node.control) {
        if (child.type === controlType) {
          const number = parseInt(child.name.replace(controlType, ""));
          if (!isNaN(number) && number > maxNumber) {
            maxNumber = number;
          }
        }
        const childMaxNumber = findMaxNumber(child, controlType);
        if (childMaxNumber > maxNumber) {
          maxNumber = childMaxNumber;
        }
      }
    }
    return maxNumber;
  };

  const findItemRecursive = (item, id) => {
    if (item.id === id) {
      return item;
    }

    if (item.control) {
      for (const controlItem of item.control) {
        const foundItem = findItemRecursive(controlItem, id);
        if (foundItem) {
          return foundItem;
        }
      }
    }

    return null;
  };

  useEffect(() => {
    if (selectedItem?.controltype === "Heading") {
      const initialDataHeading = findAddressHeadingData(
        treeData,
        selectedItemID
      );
      setEditedDataHeading(initialDataHeading);
    }
  }, [treeData, selectedItemID, selectedItem]);

  useEffect(() => {
    if (selectedItem?.controltype === "Heading") {
      const initialDataHeading = findAddressSubHeadingData(
        treeData,
        selectedItemID
      );
      setEditedDataSubHeading(initialDataHeading);
    }
  }, [treeData, selectedItemID, selectedItem]);

  const updateTreeDataForLabelType = (
    treeData,
    selectedItemID,
    newValue,
    labelType
  ) => {
    return treeData.map((item) => ({
      ...item,
      Source: item.Source.map((source) => ({
        ...source,
        control: updateControlsForLabelType(
          source.control,
          selectedItemID,
          newValue,
          labelType
        ),
      })),
    }));
  };

  const updateControlsForLabelType = (
    controls,
    selectedItemID,
    newValue,
    labelType
  ) => {
    return controls.map((control) => {
      if (control.id === selectedItemID) {
        return {
          ...control,
          control: updateControlsWithinLabelType(
            control.control,
            newValue,
            labelType
          ),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsForLabelType(
            control.control,
            selectedItemID,
            newValue,
            labelType
          ),
        };
      }

      return control;
    });
  };

  const updateControlsWithinLabelType = (controls, newValue, labelType) => {
    return controls.map((control) => {
      if (control.checkposition === labelType) {
        return {
          ...control,
          data: newValue,
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsWithinLabelType(
            control.control,
            newValue,
            labelType
          ),
        };
      }

      return control;
    });
  };

  const findLabelData = (treeData, selectedItemID, labelType) => {
    for (let item of treeData) {
      for (let source of item.Source) {
        let foundData = findInControlsForLabelType(
          source.control,
          selectedItemID,
          labelType
        );
        if (foundData) return foundData;
      }
    }
    return "";
  };

  const findInControlsForLabelType = (controls, selectedItemID, labelType) => {
    for (let control of controls) {
      if (control.id === selectedItemID) {
        const labelControl = findLabelControl(control.control, labelType);
        if (labelControl) {
          return labelControl.data;
        }
      }
      if (control.control && control.control.length > 0) {
        let foundData = findInControlsForLabelType(
          control.control,
          selectedItemID,
          labelType
        );
        if (foundData) return foundData;
      }
    }
    return null;
  };

  const findLabelControl = (controls, labelType) => {
    for (let control of controls) {
      if (control.checkposition === labelType) {
        return control;
      }
      if (control.control && control.control.length > 0) {
        let foundControl = findLabelControl(control.control, labelType);
        if (foundControl) return foundControl;
      }
    }
    return null;
  };

  const handleFieldChange = (event, labelType, setEditedData, setTreeData) => {
    const newValue = event.target.value;
    setEditedData(newValue);
    const updatedTreeData = updateTreeDataForLabelType(
      treeData,
      selectedItemID,
      newValue,
      labelType
    );
    setTreeData(updatedTreeData);
  };

  const LABEL_TYPES = {
    Header: "AddressHeader",
    AddressStreet1: "AddressStreet1",
    AddressStreet2: "AddressStreet2",
    CityLabel: "CityLabel",
    StateLabel: "StateLabel",
    PostalLabel: "PostalLabel",
    CountryLabel: "CountryLabel",
    CountryTextbox: "CountryTextbox",
    MultipleChoiceLabel: "MultipleChoiceLabel",
    SingleChoiceLabel: "SingleChoiceLabel",
    FullName: "FullName",
    FirstName: "FirstName",
    LastName: "LastName",
    PrefixLabel: "PrefixLabel",
    MiddleNameLabel: "MiddleNameLabel",
    EmailLabel: "EmailLabel",
    PhoneNumberLabel: "PhoneNumberLabel",
    ShortTextLabel: "ShortTextLabel",
    ShortTextTextBox: "ShortTextTextBox",
    LongTextLabel: "LongTextLabel",
    LongTextTextbox: "LongTextTextbox",
    DropDownLabel: "DropDownLabel",
    DateLabel: "DateLabel",
    RatingLabel: "RatingLabel",
    AddressHeaderContainer: "AddressHeaderContainer",
    AddressSubContainer: "AddressSubContainer",
    StreetAddress1Container: "StreetAddress1Container",
    AddressStreet1Textbox: "AddressStreet1Textbox",
    StreetAddressContainer: "StreetAddressContainer",
    AddressStreet2Textbox: "AddressStreet2Textbox",
    CityStateContainer: "CityStateContainer",
    CityContainer: "CityContainer",
    CityTextbox: "CityTextbox",
    StateContainer: "StateContainer",
    StateTextbox: "StateTextbox",
    PostalCountryContainer: "PostalCountryContainer",
    PostalContainer: "PostalContainer",
    PostalTextbox: "PostalTextbox",
    CountryContainer: "CountryContainer",
    CountryTextbox: "CountryTextbox",
    DateField: "DateField",
    DropDownContainer: "DropDownContainer",
    DropDown: "DropDown",
    CheckBoxMainContainer: "CheckBoxMainContainer",
    ShortTextTextBox: "ShortTextTextBox",
    LongTextTextbox: "LongTextTextbox",
  };

  const initializeFieldData = (
    treeData,
    selectedItemID,
    labelType,
    setData,
    setCheckbox
  ) => {
    const data = findLabelData(treeData, selectedItemID, labelType);
    setData(data);
    if (setCheckbox) {
      setCheckbox(data);
    }
  };

  useEffect(() => {
    if (!selectedItem) return;

    const controlType = selectedItem.controltype;

    switch (controlType) {
      case "Address":
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.Header,
          setEditedDataHeader
        );

      case "MultipleChoice":
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.MultipleChoiceLabel,
          setEditedDataCheckboxHeading
        );
        break;

      case "SingleChoice":
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.SingleChoiceLabel,
          setEditedDataSingleChoiceLabel
        );
        break;

      case "PhoneNumber":
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.PhoneNumberLabel,
          setEditedDataPhoneLabel
        );
        break;

      case "DropDown":
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.DropDownLabel,
          setEditedDataDropDownLabel
        );
        break;

      case "Email":
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.EmailLabel,
          setEditedDataEmailLabel
        );
        break;

      case "ShortText":
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.ShortTextLabel,
          setEditedDataShortTextLabel
        );
        break;

      case "LongText":
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.LongTextLabel,
          setEditedDataLongTextLabel
        );
        break;
      case "FullName":
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.FullName,
          setEditedDataFullName
        );

      case "DateBlock":
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.DateLabel,
          setEditedDataDateLabel
        );
        break;
      case "RatingBlock":
        initializeFieldData(
          treeData,
          selectedItemID,
          LABEL_TYPES.RatingLabel,
          setEditedDataRatingLabel
        );
        break;

      default:
        break;
    }
  }, [treeData, selectedItemID, selectedItem]);

  // -------------------------

  const handleSwitchChangeFullName = () => {
    setIsRequiredFullName(!isRequiredFullName);
    const updatedFullNameLabel = !isRequiredFullName
      ? `${editedDataFullName} *`
      : editedDataFullName.replace(" *", "");
    setEditedDataFullName(updatedFullNameLabel);
    const updatedTreeDataFullName = updateTreeDatanewValueFullName(
      treeData,
      selectedItemID,
      updatedFullNameLabel
    );
    setTreeData(updatedTreeDataFullName);
  };

  const updateTreeDatanewValueFullName = (
    treeData,
    selectedItemID,
    updatedFullNameLabel
  ) => {
    return treeData.map((item) => {
      return {
        ...item,
        Source: item.Source.map((source) => ({
          ...source,
          control: updateControlsnewValueFullName(
            source.control,
            selectedItemID,
            updatedFullNameLabel
          ),
        })),
      };
    });
  };

  const updateControlsnewValueFullName = (
    controls,
    selectedItemID,
    updatedFullNameLabel
  ) => {
    return controls.map((control) => {
      if (control.id === selectedItemID) {
        return {
          ...control,
          control: updateControlsWithinFullName(
            control.control,
            updatedFullNameLabel
          ),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsnewValueFullName(
            control.control,
            selectedItemID,
            updatedFullNameLabel
          ),
        };
      }

      return control;
    });
  };

  const updateControlsWithinFullName = (controls, updatedFullNameLabel) => {
    return controls.map((control) => {
      if (control.checkposition === "FullName") {
        let styles = control.styles ? JSON.parse(control.styles) : {};
        return {
          ...control,
          data: updatedFullNameLabel,
          styles: JSON.stringify(styles),
        };
      }
      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsWithinFullName(
            control.control,
            updatedFullNameLabel
          ),
        };
      }
      return control;
    });
  };

  //---------------------------------------------------FullName----------

  const handleSwitchChangeAddress = () => {
    setIsRequiredAddress(!isRequiredAddress);
    const updatedAddressLabel = !isRequiredAddress
      ? `${editedDataHeader} *`
      : editedDataHeader.replace(" *", "");
    setEditedDataHeader(updatedAddressLabel);
    const updatedTreeDataAddress = updateTreeDatanewValueAddress(
      treeData,
      selectedItemID,
      updatedAddressLabel
    );
    setTreeData(updatedTreeDataAddress);
  };

  const updateTreeDatanewValueAddress = (
    treeData,
    selectedItemID,
    updatedAddressLabel
  ) => {
    return treeData.map((item) => {
      return {
        ...item,
        Source: item.Source.map((source) => ({
          ...source,
          control: updateControlsnewValueAddress(
            source.control,
            selectedItemID,
            updatedAddressLabel
          ),
        })),
      };
    });
  };

  const updateControlsnewValueAddress = (
    controls,
    selectedItemID,
    updatedAddressLabel
  ) => {
    return controls.map((control) => {
      if (control.id === selectedItemID) {
        return {
          ...control,
          control: updateControlsWithinAddress(
            control.control,
            updatedAddressLabel
          ),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsnewValueAddress(
            control.control,
            selectedItemID,
            updatedAddressLabel
          ),
        };
      }

      return control;
    });
  };

  const updateControlsWithinAddress = (controls, updatedAddressLabel) => {
    return controls.map((control) => {
      if (control.checkposition === "AddressHeader") {
        let styles = control.styles ? JSON.parse(control.styles) : {};
        return {
          ...control,
          data: updatedAddressLabel,
          styles: JSON.stringify(styles),
        };
      }
      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsWithinAddress(
            control.control,
            updatedAddressLabel
          ),
        };
      }
      return control;
    });
  };

  //------------------------------------------Address------------------------------

  const handleSwitchChangeEmail = () => {
    setIsRequiredEmail(!isRequiredEmail);
    const updatedEmailLabel = !isRequiredEmail
      ? `${editedDataEmailLabel} *`
      : editedDataEmailLabel.replace(" *", "");
    setEditedDataEmailLabel(updatedEmailLabel);
    const updatedTreeDataEmail = updateTreeDatanewValueEmail(
      treeData,
      selectedItemID,
      updatedEmailLabel
    );
    setTreeData(updatedTreeDataEmail);
  };

  const updateTreeDatanewValueEmail = (
    treeData,
    selectedItemID,
    updatedEmailLabel
  ) => {
    return treeData.map((item) => {
      return {
        ...item,
        Source: item.Source.map((source) => ({
          ...source,
          control: updateControlsnewValueEmail(
            source.control,
            selectedItemID,
            updatedEmailLabel
          ),
        })),
      };
    });
  };

  const updateControlsnewValueEmail = (
    controls,
    selectedItemID,
    updatedEmailLabel
  ) => {
    return controls.map((control) => {
      if (control.id === selectedItemID) {
        return {
          ...control,
          control: updateControlsWithinEmail(
            control.control,
            updatedEmailLabel
          ),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsnewValueEmail(
            control.control,
            selectedItemID,
            updatedEmailLabel
          ),
        };
      }

      return control;
    });
  };

  const updateControlsWithinEmail = (controls, updatedEmailLabel) => {
    return controls.map((control) => {
      if (control.checkposition === "EmailLabel") {
        let styles = control.styles ? JSON.parse(control.styles) : {};
        return {
          ...control,
          data: updatedEmailLabel,
          styles: JSON.stringify(styles),
        };
      }
      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsWithinEmail(
            control.control,
            updatedEmailLabel
          ),
        };
      }
      return control;
    });
  };

  //-----------------------------------------Email------------------------

  const handleSwitchChangePhoneNumber = () => {
    setIsRequiredPhoneNumber(!isRequiredPhoneNumber);
    const updatedPhoneNumberLabel = !isRequiredPhoneNumber
      ? `${editedDataPhoneLabel} *`
      : editedDataPhoneLabel.replace(" *", "");
    setEditedDataPhoneLabel(updatedPhoneNumberLabel);
    const updatedTreeDataPhoneNumber = updateTreeDatanewValuePhoneNumber(
      treeData,
      selectedItemID,
      updatedPhoneNumberLabel
    );
    setTreeData(updatedTreeDataPhoneNumber);
  };

  const updateTreeDatanewValuePhoneNumber = (
    treeData,
    selectedItemID,
    updatedPhoneNumberLabel
  ) => {
    return treeData.map((item) => {
      return {
        ...item,
        Source: item.Source.map((source) => ({
          ...source,
          control: updateControlsnewValuePhoneNumber(
            source.control,
            selectedItemID,
            updatedPhoneNumberLabel
          ),
        })),
      };
    });
  };

  const updateControlsnewValuePhoneNumber = (
    controls,
    selectedItemID,
    updatedPhoneNumberLabel
  ) => {
    return controls.map((control) => {
      if (control.id === selectedItemID) {
        return {
          ...control,
          control: updateControlsWithinPhoneNumber(
            control.control,
            updatedPhoneNumberLabel
          ),
        };
      }

      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsnewValuePhoneNumber(
            control.control,
            selectedItemID,
            updatedPhoneNumberLabel
          ),
        };
      }

      return control;
    });
  };

  const updateControlsWithinPhoneNumber = (
    controls,
    updatedPhoneNumberLabel
  ) => {
    return controls.map((control) => {
      if (control.checkposition === "PhoneNumberLabel") {
        let styles = control.styles ? JSON.parse(control.styles) : {};
        return {
          ...control,
          data: updatedPhoneNumberLabel,
          styles: JSON.stringify(styles),
        };
      }
      if (control.control && control.control.length > 0) {
        return {
          ...control,
          control: updateControlsWithinPhoneNumber(
            control.control,
            updatedPhoneNumberLabel
          ),
        };
      }
      return control;
    });
  };

  return (
    <>
      <Box>
        <TabsComponent
          tabValue={tabValue}
          setTabValue={setTabValue}
          tabs={rightsidetabs}
        >
          <div
            key="1"
            className="gnr-tile-name"
            style={{ paddingTop: "0px !important" }}
          >
            <div className="titleSection-gnr">
              {selectedItem &&
                controlsVisible &&
                selectedItem.type !== "FooterContainer" &&
                selectedItem.type !== "HeaderContainer" && (
                  <div className="clr-schm-option">
                    <div className="drowerseparater">
                      <div className="inputfildtet inpt-fild-test">
                        <div>Name</div>
                        <div>
                          <input
                            className="tab-txtbox tab-input-name"
                            value={editedName}
                            onKeyUp={handleRenameConfirm}
                            onChange={handleEditChangeName1}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              {selectedItem &&
                controlsVisible &&
                selectedItem.type !== "Root" &&
                selectedItem.type !== "Container" &&
                selectedItem.styleType !== "Icon" &&
                selectedItem.type !== "FooterContainer" &&
                selectedItem.type !== "HeaderContainer" &&
                selectedItem.type !== "RadioContainer" &&
                selectedItem.type !== "Radio" &&
                selectedItem.type !== "Checkbox" &&
                selectedItem.type !== "Date" &&
                selectedItem.type !== "Table" &&
                selectedItem.type !== "List" &&
                selectedItem.type !== "TableLabel" &&
                // selectedItem.controltype !== "Row" &&
                selectedItem.type !== "Divider" &&
                selectedItem.type !== "Iframe" &&
                selectedItem.type !== "Switch" &&
                selectedItem.inputType !== "Row" &&
                !isRootItem(selectedItem, treeData) && (
                  <div className="clr-schm-option">
                    <div className="drowerseparater">
                      <div className="optns-predefnd">
                        <div className="inpt-fild-test">
                          Change Control Type :
                        </div>
                        <div style={{ width: "100%" }}>
                          {selectedItem &&
                            controlsVisible &&
                            selectedItem.type !== "Root" &&
                            selectedItem.type !== "Container" &&
                            selectedItem.type !== "FooterContainer" &&
                            selectedItem.type !== "HeaderContainer" &&
                            selectedItem.type !== "Table" &&
                            selectedItem.type !== "List" &&
                            selectedItem.type !== "TableLabel" &&
                            !isRootItem(selectedItem, treeData) && (
                              <Select
                                value={selectedControlType}
                                onChange={(e) =>
                                  handleControlTypeChange(e, selectedItem.id)
                                }
                                className="inpt-cntr-unit"
                                MenuProps={{
                                  PaperProps: {
                                    className: "dt-dropdn-menu",
                                  },
                                  MenuListProps: {
                                    className: "custom-menu-list",
                                  },
                                }}
                              >
                                {menuItems.map((menuItem) => (
                                  <MenuItem
                                    key={menuItem.key}
                                    value={menuItem.value}
                                  >
                                    {menuItem.value}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                        </div>
                      </div>
                    </div>
                    {selectedItem &&
                      Array.isArray(treeData) &&
                      treeData.forEach((item) => {
                        const findAndLogMatchedObjects = (controlItems) => {
                          controlItems.forEach((controlItem) => {
                            if (
                              controlItem.controlName ===
                              selectedItem.controlName
                            ) {
                            }

                            if (
                              controlItem.control &&
                              controlItem.control.length > 0
                            ) {
                              findAndLogMatchedObjects(controlItem.control);
                            }
                          });
                        };

                        item.Source.forEach((sourceItem) => {
                          findAndLogMatchedObjects(sourceItem.control);
                        });
                      })}
                  </div>
                )}
            </div>

            {selectedItem &&
              controlsVisible &&
              selectedItem.type !== "Container" &&
              selectedItem.type !== "HeaderContainer" &&
              selectedItem.type !== "ContainerLayout" &&
              selectedItem.type !== "FooterContainer" &&
              selectedItem.type !== "Image" &&
              selectedItem.type !== "Divider" &&
              selectedItem.type !== "Date" &&
              selectedItem.type !== "Radio-Container" &&
              selectedItem.type !== "Switch" &&
              selectedItem.htmlTag !== "tr" &&
              selectedItem.type !== "Slider" &&
              selectedItem.styleType !== "Icon" &&
              selectedItem.inputType !== "Row" &&
              selectedItem.inputType !== "Header" &&
              controlChanged !== "Divider" &&
              controlChanged !== "Image" &&
              controlChanged !== "Slider" &&
              controlChanged !== "Switch" &&
              selectedItem.htmlTag !== "table" &&
              selectedItem.htmlTag !== "thead" &&
              selectedItem.htmlTag !== "tr" &&
              !isRootItem(selectedItem, treeData) && (
                <div className="clr-schm-option">
                  <div className="drowerseparater">
                    <div className="title-set-wrap gnr-data-src">
                      {selectedItem.type === "Iframe" ? (
                        <div className="inpt-fild-test">Enter Iframe URL</div>
                      ) : (
                        <div className="inpt-fild-test">Text</div>
                      )}{" "}
                      <div className="heading-file-gnr">
                        <div className="titleSection-ifrm ">
                          <input
                            className="tab-txtbox"
                            value={editedData}
                            onChange={handleEditChange}
                            onKeyUp={
                              selectedItem.type === "TextBox" &&
                              (tabSelect === "Phone Number" ||
                                tabSelect === "Email")
                                ? handleEnterForNumber
                                : handleAgree
                            }
                          />
                          <span
                            className="iconBtn popIcn"
                            onClick={() => handlePopupClick(selectedItem.data)}
                            title="Edit title in popup"
                          >
                            {Theme.Open}
                          </span>
                        </div>
                      </div>
                      <div>
                        {isPopupVisible && (
                          <Dialog
                            open={isOpen}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            maxWidth="lg"
                          >
                            <div className="expre-edit-place">
                              <DialogTitle
                                className="exedit"
                                style={{ width: "400px" }}
                              >
                                Expression Editor
                                <IconButton
                                  aria-label="close"
                                  onClick={handleClose}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </DialogTitle>

                              
                            </div>

                            <textarea
                              className="gnrtextarea"
                              value={editedData || selectedOption}
                              onChange={handleEditChange}
                            />
                            <div className="slct-itm-nam-sty">
                              <DialogActions className="custombtn">
                                <Button
                                  onClick={handleClose}
                                  className="cancelbtn"
                                >
                                  <span>Cancel</span>
                                </Button>
                                <Button
                                  onClick={handleAgree}
                                  autoFocus
                                  className="savebtn"
                                >
                                  <span>Save</span>
                                </Button>
                              </DialogActions>
                            </div>
                          </Dialog>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

            {selectedItem &&
              controlsVisible &&
              selectedItem.type !== "Container" &&
              selectedItem.htmlTag !== "tr" &&
              selectedItem.type !== "HeaderContainer" &&
              selectedItem.type !== "ContainerLayout" &&
              selectedItem.type !== "FooterContainer" &&
              selectedItem.type !== "Divider" &&
              selectedItem.type !== "Radio-Container" &&
              selectedItem.type !== "Radio" &&
              selectedItem.styleType !== "Icon" &&
              selectedItem.type !== "List" &&
              selectedItem.type !== "Iamge" &&
              selectedItem.type !== "Table" &&
              selectedItem.type !== "Date" &&
              selectedItem.type !== "Checkbox" &&
              selectedItem.type !== "Image" &&
              selectedItem.type !== "Iframe" &&
              selectedItem.type !== "Switch" &&
              tabSelect !== "Phone Number" &&
              tabSelect !== "Email" &&
              selectedItem.type !== "Slider" &&
              selectedItem.htmlTag !== "tr" &&
              // selectedItem.sourceData !== "" &&
              selectedItem.tableHeader !== "tableHead" &&
              controlChanged !== "Divider" &&
              !isRootItem(selectedItem, treeData) && (
                <>
                  {dataSourceArID !== 0 && (
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="optns-predefnd">
                          <FormControl className="gnr-data-src">
                            <div className="inpt-fild-test">Data Source:</div>
                            <Select
                              labelId="select-field-label"
                              value={
                                (editedData && editedData.toLowerCase()) ||
                                (selectedOption && selectedOption.toLowerCase())
                              }
                              onChange={handleEditChange}
                              className="inpt-cntr-unit"
                              MenuProps={{
                                PaperProps: {
                                  className: "dt-dropdn-menu",
                                },
                                MenuListProps: {
                                  className: "custom-menu-list",
                                },
                              }}
                            >
                              {fieldNames.map((fieldName, index) => (
                                <MenuItem
                                  key={index}
                                  value={`{${fieldName.toLowerCase()}}`}
                                  className="mui_root_name"
                                >
                                  {fieldName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

            {selectedItem &&
              controlsVisible &&
              selectedItem.type === "Date" &&
              !isRootItem(selectedItem, treeData) && (
                <>
                  {dataSourceArID !== 0 && (
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="optns-predefnd">
                          <FormControl className="gnr-data-src">
                            <div className="inpt-fild-test">Data Source</div>
                            <Select
                              labelId="select-field-label"
                              value={
                                (selectedOption &&
                                  selectedOption.toLowerCase()) ||
                                (editedData && editedData.toLowerCase())
                              }
                              onChange={handleEditChange}
                              className="inpt-cntr-unit"
                              MenuProps={{
                                PaperProps: {
                                  className: "dt-dropdn-menu",
                                },
                                MenuListProps: {
                                  className: "custom-menu-list",
                                },
                              }}
                            >
                              <MenuItem value="xx" className="mui_root_name">
                                None
                              </MenuItem>
                              {DateField.map((fieldName, index) => (
                                <MenuItem
                                  key={index}
                                  value={`{${fieldName.toLowerCase()}}`}
                                  className="mui_root_name"
                                >
                                  {fieldName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

            {selectedItem &&
              controlsVisible &&
              selectedItem.type === "Image" &&
              !isRootItem(selectedItem, treeData) && (
                <>
                  {dataSourceArID !== 0 && (
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="label-algnmt">
                          <div className="inpt-fild-test">Data Source:</div>
                          <div className="gnr-data-src">
                            {/* <FormControl> */}
                            <Select
                              labelId="select-field-label"
                              value={
                                (selectedOption &&
                                  selectedOption.toLowerCase()) ||
                                (editedData && editedData.toLowerCase())
                              }
                              onChange={handleEditChange}
                              className="inpt-cntr-unit"
                              MenuProps={{
                                PaperProps: {
                                  className: "dt-dropdn-menu",
                                },
                                MenuListProps: {
                                  className: "custom-menu-list",
                                },
                              }}
                            >
                              <MenuItem value="xx" className="mui_root_name">
                                None
                              </MenuItem>
                              {ImageField.map((fieldName, index) => (
                                <MenuItem
                                  key={index}
                                  value={`{${fieldName.toLowerCase()}}`}
                                  className="mui_root_name"
                                >
                                  {fieldName}
                                </MenuItem>
                              ))}
                            </Select>
                            {/* </FormControl> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

            {selectedItem &&
              controlsVisible &&
              selectedItem.type === "Iframe" &&
              !isRootItem(selectedItem, treeData) && (
                <>
                  {dataSourceArID !== 0 && (
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="optns-predefnd">
                          {/* <FormControl className="gnr-data-src"> */}
                          <div className="inpt-fild-test">Data Source</div>
                          <Select
                            labelId="select-field-label"
                            value={
                              (selectedOption &&
                                selectedOption.toLowerCase()) ||
                              (editedData && editedData.toLowerCase())
                            }
                            onChange={handleEditChange}
                            // className="hnd_slt_text-name"
                            className="inpt-cntr-unit"
                            MenuProps={{
                              PaperProps: {
                                className: "dt-dropdn-menu",
                              },
                              MenuListProps: {
                                className: "custom-menu-list",
                              },
                            }}
                          >
                            <MenuItem value="xx" className="mui_root_name">
                              None
                            </MenuItem>
                            {IframeField.map((fieldName, index) => (
                              <MenuItem
                                key={index}
                                value={`{${fieldName.toLowerCase()}}`}
                                className="mui_root_name"
                              >
                                {fieldName}
                              </MenuItem>
                            ))}
                          </Select>
                          {/* </FormControl> */}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

            {selectedItem &&
              controlsVisible &&
              selectedItem.type === "Switch" &&
              !isRootItem(selectedItem, treeData) && (
                <>
                  {dataSourceArID !== 0 && (
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="optns-predefnd">
                          {/* <FormControl> */}
                          <div className="inpt-fild-test">Data Source</div>
                          <Select
                            labelId="select-field-label"
                            value={
                              (selectedOption &&
                                selectedOption.toLowerCase()) ||
                              (editedData && editedData.toLowerCase())
                            }
                            onChange={handleEditChange}
                            className="inpt-cntr-unit"
                            MenuProps={{
                              PaperProps: {
                                className: "dt-dropdn-menu",
                              },
                              MenuListProps: {
                                className: "custom-menu-list",
                              },
                            }}
                          >
                            <MenuItem value="xx" className="mui_root_name">
                              None
                            </MenuItem>
                            {switchField.map((fieldName, index) => (
                              <MenuItem
                                key={index}
                                value={`{${fieldName.toLowerCase()}}`}
                                className="mui_root_name"
                              >
                                {fieldName}
                              </MenuItem>
                            ))}
                          </Select>
                          {/* </FormControl> */}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

            {selectedItem &&
              controlsVisible &&
              selectedItem.type === "Checkbox" &&
              !isRootItem(selectedItem, treeData) && (
                <>
                  {dataSourceArID !== 0 && (
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="optns-predefnd">
                          {/* <FormControl> */}
                          <div className="inpt-fild-test">Data Source</div>
                          <Select
                            labelId="select-field-label"
                            value={
                              (editedData && editedData.toLowerCase()) ||
                              (selectedOption && selectedOption.toLowerCase())
                            }
                            onChange={handleEditChange}
                            className="inpt-cntr-unit"
                            MenuProps={{
                              PaperProps: {
                                className: "dt-dropdn-menu",
                              },
                              MenuListProps: {
                                className: "custom-menu-list",
                              },
                            }}
                          >
                            <MenuItem value="xx" className="mui_root_name">
                              None
                            </MenuItem>
                            {checkboxField.map((fieldName, index) => (
                              <MenuItem
                                key={index}
                                value={`{${fieldName.toLowerCase()}}`}
                                className="mui_root_name"
                              >
                                {fieldName}
                              </MenuItem>
                            ))}
                          </Select>
                          {/* </FormControl> */}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

            {selectedItem &&
              controlsVisible &&
              selectedItem.type === "Radio" &&
              !isRootItem(selectedItem, treeData) && (
                <>
                  {dataSourceArID !== 0 && (
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="optns-predefnd">
                          {/* <FormControl> */}
                          <div className="inpt-fild-test">Data Source</div>
                          <Select
                            labelId="select-field-label"
                            value={
                              (editedData && editedData.toLowerCase()) ||
                              (selectedOption && selectedOption.toLowerCase())
                            }
                            onChange={handleEditChange}
                            className="inpt-cntr-unit"
                            MenuProps={{
                              PaperProps: {
                                className: "dt-dropdn-menu",
                              },
                              MenuListProps: {
                                className: "custom-menu-list",
                              },
                            }}
                          >
                            <MenuItem value="xx" className="mui_root_name">
                              None
                            </MenuItem>
                            {RadioField.map((fieldName, index) => (
                              <MenuItem
                                key={index}
                                value={`{${fieldName.toLowerCase()}}`}
                                className="mui_root_name"
                              >
                                {fieldName}
                              </MenuItem>
                            ))}
                          </Select>
                          {/* </FormControl> */}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

            {selectedItem && controlsVisible && (
              <div className="dt-type">
                {selectedItem &&
                  Array.isArray(treeData) &&
                  treeData.forEach((item) => {
                    const findAndLogMatchedObjects = (controlItems) => {
                      controlItems.forEach((controlItem) => {
                        if (
                          controlItem.controlName === selectedItem.controlName
                        ) {
                        }

                        if (
                          controlItem.control &&
                          controlItem.control.length > 0
                        ) {
                          findAndLogMatchedObjects(controlItem.control);
                        }
                      });
                    };

                    item.Source.forEach((sourceItem) => {
                      findAndLogMatchedObjects(sourceItem.control);
                    });
                  })}
              </div>
            )}

            {selectedItem &&
              selectedItem.type === "Table" &&
              selectedItem.tableHeader !== "tableHead" &&
              selectedItem.htmlTag !== "thead" &&
              selectedItem.htmlTag !== "table" &&
              selectedItem.htmlTag !== "tr" &&
              controlsVisible && (
                <>
                  {dataSourceArID !== 0 && (
                    <div className="clr-schm-option">
                    <div className="drowerseparater">
                      <div className="optns-predefnd">
                    <div className="dt-src-name dt-src-table">
                      {/* <FormControl> */}
                        <div className="inpt-fild-test">
                          Data Source
                        </div>
                        <div className="unit-option">
                        <Select
                       labelId="select-field-label"
                          value={
                            selectedOption &&
                            selectedOption.replace(/[{}]/g, "")
                          }
                          onChange={handleEditChangeforTable}
                          className="inpt-cntr-unit"
                          MenuProps={{
                            PaperProps: {
                              className: "cstm-dropdown-menu-text",
                            },
                            MenuListProps: {
                              className: "cstm-menu-list-text",
                            },
                          }}
                        >
                          {fieldNamestable.map((fieldName, index) => (
                            <MenuItem
                              key={index}
                              value={fieldName}
                              className="mui_root_name"
                            >
                              {fieldName}
                            </MenuItem>
                          ))}
                        </Select>
                        </div>
                      {/* </FormControl> */}
                    </div>
                    </div>
                    </div>
                    </div>
                  )}
                </>
              )}

            {selectedItem &&
              selectedItem.type === "TextBox" &&
              controlChanged === "TextBox" &&
              selectedItem.controltype !== "ShortText" &&
              controlsVisible && (
                <div className="clr-schm-option">
                  <div className="drowerseparater">
                    <div class="optns-predefnd">
                      <FormControl className="custom-edit-text-bx">
                        <div className="inpt-fild-test">Data Type</div>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={tabSelect}
                          onChange={(event) =>
                            handleChange(event, selectedItem.id)
                          }
                          className="inpt-cntr-unit"
                          MenuProps={{
                            PaperProps: {
                              className: "cstm-dropdown-menu-text",
                            },
                            MenuListProps: {
                              className: "cstm-menu-list-text",
                            },
                          }}
                        >
                          <MenuItem value={"Text"} className="cst-menu-item">
                            {Theme.texttype} Text{" "}
                          </MenuItem>
                          <MenuItem
                            value={"Phone Number"}
                            className="cst-menu-item"
                          >
                            {Theme.Phone} Phone Number
                          </MenuItem>
                          <MenuItem value={"Email"} className="cst-menu-item">
                            {Theme.Email} Email
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
              )}

            <div className="edit-value-wrap gnr-tile-name ">
              {selectedItem &&
                selectedItem.type === "Radio" &&
                controlsVisible && (
                  <>
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="title-set-wrap gnr-data-src">
                          <div className="inpt-fild-test">
                            Create Multiple :
                          </div>
                          <div className="heading-file-gnr">
                            <div className="titleSection-ifrm">
                              <input
                                type="number"
                                className="tab-txtbox"
                                value={inputValueRadio}
                                onChange={handleInputChangeRadio}
                              />
                              <span
                                className="iconBtn popIcn"
                                title="Add"
                                onClick={() => handleAddControlRadio()}
                              >
                                {Theme.Add}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="label-algnmt">
                          <div className="inpt-fild-test">State</div>
                          <FormControl className="custom-dropdown-bottom">
                            <NativeSelect
                              value={selectedOptioncheck}
                              onChange={(e) =>
                                handleRadioChange(
                                  e.target.value === "true",
                                  selectedItem.id
                                )
                              }
                              className="custom-native-select"
                            >
                              <option value={false}>False</option>
                              <option value={true}>True</option>
                            </NativeSelect>
                          </FormControl>
                        </div>
                      </div>
                    </div>

                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="label-algnmt">
                          <div className="inpt-fild-test">Position</div>
                          <FormControl className="custom-dropdown-bottom">
                            <NativeSelect
                              value={selectedPositioncheck}
                              onChange={(e) =>
                                handleCheckboxPositionChange(
                                  e.target.value === "right",
                                  selectedItem.id
                                )
                              }
                              style={{ position: "static" }}
                            >
                              <option value="left">Right</option>
                              <option value="right">Left</option>
                            </NativeSelect>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </>
                )}

              {(selectedItem && selectedItem.type === "Checkbox") ||
                (controlChanged === "Checkbox" &&
                  controlsVisible &&
                  selectedItem.styleType !== "RadioContainer" && (
                    <div className="dt-type">
                      <div className="tab-titles">Create Multiple :</div>
                      <div className="titleSection">
                        <input
                          type="number"
                          className="tab-txtbox"
                          value={inputValue}
                          onChange={handleInputChange}
                        />
                        <span
                          className="iconBtn"
                          title="Add"
                          onClick={() => handleAddControlCheckbox()}
                        >
                          {Theme.Add}
                        </span>
                      </div>

                      <div className="gnr-tile-name dt-type">
                        <div className="chck-stst-type sdfgbhdfb">State</div>
                        <FormControl className="custom-dropdown-bottom">
                          <NativeSelect
                            value={selectedOptioncheck}
                            onChange={(e) =>
                              handleCheckboxChange(
                                e.target.value === "true",
                                selectedItem.id
                              )
                            }
                            className="custom-native-select"
                          >
                            <option value={false}>False</option>
                            <option value={true}>True</option>
                          </NativeSelect>
                        </FormControl>
                      </div>

                      <div className="gnr-tile-name dt-type">
                        <div className="check-bxtype">Position</div>
                        <FormControl className="custom-dropdown-bottom">
                          <NativeSelect
                            value={selectedPositioncheck}
                            onChange={(e) =>
                              handleCheckboxPositionChange(
                                e.target.value === "right",
                                selectedItem.id
                              )
                            }
                            style={{ position: "static" }}
                          >
                            <option value="left">Right </option>
                            <option value="right">Left</option>
                          </NativeSelect>
                        </FormControl>
                      </div>
                    </div>
                  ))}
              {/* ----- */}

              {selectedItem &&
                selectedItem.type === "Checkbox" &&
                controlChanged === "Checkbox" &&
                controlsVisible && (
                  <>
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="title-set-wrap gnr-data-src">
                          <div className="inpt-fild-test">
                            Create Multiple :
                          </div>
                          <div className="heading-file-gnr">
                            <div className="titleSection-ifrm">
                              <input
                                type="number"
                                className="tab-txtbox"
                                value={inputValue}
                                onChange={handleInputChange}
                              />
                              <span
                                className="iconBtn popIcn"
                                title="Add"
                                onClick={() => handleAddControlCheckbox()}
                              >
                                {Theme.Add}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ------------ */}
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="label-algnmt">
                          <div className="inpt-fild-test">State</div>
                          <FormControl className="custom-dropdown-bottom">
                            <NativeSelect
                              value={selectedOptioncheck}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  e.target.value === "true",
                                  selectedItem.id
                                )
                              }
                              className="custom-native-select"
                            >
                              <option value={false}>False</option>
                              <option value={true}>True</option>
                            </NativeSelect>
                          </FormControl>
                        </div>
                      </div>
                    </div>

                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="label-algnmt">
                          <div className="inpt-fild-test">Position</div>
                          <FormControl className="custom-dropdown-bottom">
                            <NativeSelect
                              value={selectedPositioncheck}
                              onChange={(e) =>
                                handleCheckboxPositionChange(
                                  e.target.value === "right",
                                  selectedItem.id
                                )
                              }
                              style={{ position: "static" }}
                            >
                              <option value="left">Right</option>
                              <option value="right">Left</option>
                            </NativeSelect>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </>
                )}

              {selectedItem &&
                selectedItem.type === "Switch" &&
                controlChanged === "Switch" &&
                controlsVisible && (
                  <>
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="label-algnmt">
                          <div className="inpt-fild-test">State</div>
                          <FormControl className="custom-dropdown-bottom">
                            <NativeSelect
                              value={selectedOptionSwitchcheck}
                              onChange={(e) =>
                                handleSwitchChange(
                                  e.target.value === "true",
                                  selectedItem.id
                                )
                              }
                            >
                              <option value={false}>False</option>
                              <option value={true}>True</option>
                            </NativeSelect>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </>
                )}

              {(selectedItem && selectedItem.type === "Switch") ||
                (controlChanged === "Switch" && controlsVisible && (
                  <>
                    <div className="gnr-tile-name dt-type">
                      <div>State</div>
                      <FormControl className="custom-dropdown-bottom">
                        <NativeSelect
                          value={selectedOptionSwitchcheck}
                          onChange={(e) =>
                            handleSwitchChange(
                              e.target.value === "true",
                              selectedItem.id
                            )
                          }
                        >
                          <option value={false}>False</option>
                          <option value={true}>True</option>
                        </NativeSelect>
                      </FormControl>
                    </div>
                  </>
                ))}

              {selectedItem &&
                selectedItem.type === "Image" &&
                controlChanged === "Image" &&
                controlsVisible && (
                  <div className="clr-schm-option">
                    <div className="drowerseparater">
                      <div style={{ width: "98%" }}>
                        <div className="inpt-fild-test">Select Image :</div>
                        <div className="heading-file-gnr">
                          <div className="titleSection-ifrm">
                            <input
                              type="file"
                              id="imagePicker"
                              ref={inputRef}
                              accept="image/jpeg, image/png"
                              style={{ display: "none" }}
                              onChange={(e) =>
                                handleImageChange(e, selectedItem.id)
                              }
                              className="tab-txtbox"
                            />

                            <label
                              onClick={handleImageClick}
                              className="dtsrc-img-slct"
                            >
                              <AddPhotoAlternateIcon />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              {selectedItem &&
                selectedItem.styleType === "Date" &&
                controlsVisible && (
                  <div className="clr-schm-option">
                    <div className="drowerseparater">
                      <div className="title-set-wrap gnr-data-src">
                        <div className="inpt-fild-test">Select Date</div>
                        <div className="heading-file-gnr">
                          <div className="titleSection-ifrm ">
                            {selectedItem.inputType === "text" ? (
                              <input
                                className="tab-txtbox"
                                type="date"
                                value={selectedItem.data}
                                onChange={(e) =>
                                  handleDateChange(e, selectedItem.id)
                                }
                                style={{ cursor: "pointer" }}
                              />
                            ) : (
                              <input
                                className="tab-txtbox"
                                type="date"
                                value={selectedItem.data}
                                onChange={(e) =>
                                  handleDateChange(e, selectedItem.id)
                                }
                                style={{ cursor: "pointer" }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              {selectedItem &&
                selectedItem.htmlTag === "table" &&
                controlsVisible && (
                  <div class="gnr-tile-name">
                <div class="titleSection-gnr">
                <div class="clr-schm-option">
                <div class="drowerseparater">
                  <div className="tbl-row-col-btn">
                    <Button onClick={addNewColumn}>Add Columns</Button>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                )}

              {selectedItem &&
                selectedItem.controltype === "Address" &&
                controlsVisible && (
                  <>
                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="label-algnmt-gnr label-algnmt">
                            <div className="inpt-fild-test">Field Label</div>
                            <div>
                              <input
                                className="tab-txtbox"
                                value={editedDataHeader}
                                onChange={(event) =>
                                  handleFieldChange(
                                    event,
                                    "AddressHeader",
                                    setEditedDataHeader,
                                    setTreeData
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="dspl-otr-otion">
                            <div className="inpt-fild-test">Required</div>
                            <div className="tggle-switch unit-option">
                              <div
                                className={`toggle-box ${isRequiredAddress ? "active" : "inactive"}`}
                                onClick={handleSwitchChangeAddress}
                              >
                                <span
                                  className={
                                    isRequiredAddress
                                      ? "on-text"
                                      : "inactive-on"
                                  }
                                >
                                  ON
                                </span>
                                <span
                                  className={
                                    !isRequiredAddress
                                      ? "on-text"
                                      : "inactive-off"
                                  }
                                >
                                  OFF
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              {selectedItem &&
                selectedItem.controltype === "LongText" &&
                controlsVisible && (
                  <>
                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="label-algnmt">
                            <div className="inpt-fild-test">Field Label</div>
                            <input
                              className="tab-txtbox unit-option"
                              value={editedDataLongTextLabel}
                              onChange={(event) =>
                                handleFieldChange(
                                  event,
                                  "LongTextLabel",
                                  setEditedDataLongTextLabel,
                                  setTreeData
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              {selectedItem &&
                selectedItem.controltype === "ShortText" &&
                controlsVisible && (
                  <>
                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="label-algnmt">
                            <div className="inpt-fild-test">Field Label</div>
                            <input
                              className="tab-txtbox unit-option"
                              value={editedDataShortTextLabel}
                              onChange={(event) =>
                                handleFieldChange(
                                  event,
                                  "ShortTextLabel",
                                  setEditedDataShortTextLabel,
                                  setTreeData
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

              {selectedItem &&
                selectedItem.controltype === "PhoneNumber" &&
                controlsVisible && (
                  <>
                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="label-algnmt">
                            <div className="inpt-fild-test">Field Label</div>
                            <input
                              className="tab-txtbox unit-option"
                              value={editedDataPhoneLabel}
                              onChange={(event) =>
                                handleFieldChange(
                                  event,
                                  "PhoneNumberLabel",
                                  setEditedDataPhoneLabel,
                                  setTreeData
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="dspl-otr-otion">
                            <div className="inpt-fild-test">Required</div>
                            <div className="tggle-switch unit-option">
                              <div
                                className={`toggle-box ${isRequiredPhoneNumber ? "active" : "inactive"}`}
                                onClick={handleSwitchChangePhoneNumber}
                              >
                                <span
                                  className={
                                    isRequiredPhoneNumber
                                      ? "on-text"
                                      : "inactive-on"
                                  }
                                >
                                  ON
                                </span>
                                <span
                                  className={
                                    !isRequiredPhoneNumber
                                      ? "on-text"
                                      : "inactive-off"
                                  }
                                >
                                  OFF
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

              {selectedItem &&
                selectedItem.controltype === "Email" &&
                controlsVisible && (
                  <>
                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="label-algnmt">
                            <div className="inpt-fild-test">Field Label</div>
                            {/* <Typography>Field Label sdfghje</Typography> */}
                            <input
                              className="tab-txtbox unit-option"
                              value={editedDataEmailLabel}
                              onChange={(event) =>
                                handleFieldChange(
                                  event,
                                  "EmailLabel",
                                  setEditedDataEmailLabel,
                                  setTreeData
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="dspl-otr-otion">
                            <div className="inpt-fild-test">Required</div>
                            <div className="tggle-switch unit-option">
                              <div
                                className={`toggle-box ${isRequiredEmail ? "active" : "inactive"}`}
                                onClick={handleSwitchChangeEmail}
                              >
                                <span
                                  className={
                                    isRequiredEmail ? "on-text" : "inactive-on"
                                  }
                                >
                                  ON
                                </span>
                                <span
                                  className={
                                    !isRequiredEmail
                                      ? "on-text"
                                      : "inactive-off"
                                  }
                                >
                                  OFF
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

              {selectedItem &&
                selectedItem.controltype === "Heading" &&
                controlsVisible && (
                  <>
                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="label-algnmt">
                            <div className="inpt-fild-test">Sublabels</div>
                            <div className="advnce-field-stree">
                              <div>
                                <div className="fld-advnce">
                                  <div className="fld-name">Field Header</div>
                                  <TextField
                                    className="inpt-text-fld-name"
                                    value={editedDataHeading}
                                    onChange={handleFieldChangeAddressHeading}
                                    InputProps={{
                                      classes: { input: "fild-inpt" },
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="fld-advnce">
                                <div className="fld-name">Field SubHeader</div>
                                <TextField
                                  className="inpt-text-fld-name"
                                  value={editedDataSubHeading}
                                  onChange={handleFieldChangeAddressSubHeading}
                                  InputProps={{
                                    classes: { input: "fild-inpt" },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {dataSourceArID !== 0 && (
                      <div>
                        <div className="clr-schm-option">
                          <div className="drowerseparater">
                            <div className="label-algnmt">
                              <div className="inpt-fild-test">Data Source</div>

                              <div className="advnce-field-stree">
                                <>
                                  <div className="fld-advnce-data">
                                    <div className="fld-name">Field Header</div>
                                    <div className="dt-src-name">
                                      <FormControl>
                                        <select
                                          className="theme-input-slt-dtsrc"
                                          value={editedDataHeading}
                                          onChange={
                                            handleFieldChangeAddressHeading
                                          }
                                          MenuProps={{
                                            PaperProps: {
                                              className: "dt-dropdn-menu",
                                            },
                                            MenuListProps: {
                                              className: "custom-menu-list",
                                            },
                                          }}
                                        >
                                          <option value="">
                                            select DataSource
                                          </option>
                                          {fieldNames.map(
                                            (fieldName, index) => (
                                              <option
                                                key={index}
                                                value={`{${fieldName.toLowerCase()}}`}
                                                className="mui_root_name"
                                              >
                                                {fieldName}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </FormControl>
                                    </div>
                                  </div>
                                </>
                                <>
                                  <div className="fld-advnce-data">
                                    <div className="fld-name">
                                      Field SubHeader
                                    </div>
                                    <div className="dt-src-name">
                                      <FormControl>
                                        <select
                                          className="theme-input-slt-dtsrc"
                                          value={editedDataSubHeading} // Bind to the same value as TextField
                                          onChange={
                                            handleFieldChangeAddressSubHeading
                                          }
                                          MenuProps={{
                                            PaperProps: {
                                              className: "dt-dropdn-menu",
                                            },
                                            MenuListProps: {
                                              className: "custom-menu-list",
                                            },
                                          }}
                                        >
                                          <option value="">
                                            select DataSource
                                          </option>
                                          {fieldNames.map(
                                            (fieldName, index) => (
                                              <option
                                                key={index}
                                                value={`{${fieldName.toLowerCase()}}`}
                                                className="mui_root_name"
                                              >
                                                {fieldName}
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </FormControl>
                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="label-algnmt-gnr gnr-alignment">
                            <div className="inpt-fild-test label-algnmt">
                              Text Alignment
                            </div>
                            <div className="btn-group-gnr">
                              <Button
                                onClick={() =>
                                  handleAlignmentChangeHeading("left")
                                }
                                className={`button-gnr ${alignMent === "left" ? "active" : ""}`}
                              >
                                Left
                              </Button>
                              <Button
                                onClick={() =>
                                  handleAlignmentChangeHeading("center")
                                }
                                className={`button-gnr ${alignMent === "center" ? "active" : ""}`}
                              >
                                Center
                              </Button>
                              <Button
                                onClick={() =>
                                  handleAlignmentChangeHeading("right")
                                }
                                className={`button-gnr ${alignMent === "right" ? "active" : ""}`}
                              >
                                Right
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="label-algnmt-gnr gnr-alignment">
                            <div className="inpt-fild-test label-algnmt">
                              Heading Size
                            </div>
                            <div className="btn-group-gnr">
                              <Button
                                onClick={() =>
                                  handleFontSizeChangeHeading("0.875em")
                                }
                                className={`button-gnr ${FontSize === "0.875em" ? "active" : ""}`}
                              >
                                Default
                              </Button>
                              <Button
                                onClick={() =>
                                  handleFontSizeChangeHeading("2em")
                                }
                                className={`button-gnr ${FontSize === "2em" ? "active" : ""}`}
                              >
                                Large
                              </Button>
                              <Button
                                onClick={() =>
                                  handleFontSizeChangeHeading("0.75em")
                                }
                                className={`button-gnr ${FontSize === "0.75em" ? "active" : ""}`}
                              >
                                Small
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

              {selectedItem &&
                selectedItem.controltype === "DropDown" &&
                controlsVisible && (
                  <>
                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="label-algnmt">
                            <div className="inpt-fild-test">Field Label</div>
                            <input
                              className="tab-txtbox unit-option"
                              value={editedDataDropDownLabel}
                              onChange={(event) =>
                                handleFieldChange(
                                  event,
                                  "DropDownLabel",
                                  setEditedDataDropDownLabel,
                                  setTreeData
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

              {selectedItem &&
                selectedItem.controltype === "FullName" &&
                controlsVisible && (
                  <>
                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="dspl-otr-otion">
                            <div className="inpt-fild-test">Required</div>
                            <div className="tggle-switch unit-option">
                              <div
                                className={`toggle-box ${isRequiredFullName ? "active" : "inactive"}`}
                                onClick={handleSwitchChangeFullName}
                              >
                                <span
                                  className={
                                    isRequiredFullName
                                      ? "on-text"
                                      : "inactive-on"
                                  }
                                >
                                  ON
                                </span>
                                <span
                                  className={
                                    !isRequiredFullName
                                      ? "on-text"
                                      : "inactive-off"
                                  }
                                >
                                  OFF
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

              {selectedItem &&
                selectedItem.controltype === "SingleChoice" &&
                controlsVisible && (
                  <>
                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="label-algnmt-gnr optns-predefnd">
                            <div className="inpt-fild-test label-algnmt">
                              Field Label
                            </div>
                            <input
                              className="tab-txtbox unit-option"
                              value={editedDataSingleChoiceLabel}
                              onChange={(event) =>
                                handleFieldChange(
                                  event,
                                  "SingleChoiceLabel",
                                  setEditedDataSingleChoiceLabel,
                                  setTreeData
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

              {selectedItem &&
                selectedItem.controltype === "MultipleChoice" &&
                controlsVisible && (
                  <>
                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="label-algnmt-gnr inpt-fild-test">
                            <div className="inpt-fild-test label-algnmt">
                              Field Postal
                            </div>
                            <input
                              className="tab-txtbox"
                              value={editedDataCheckboxHeading}
                              onChange={(event) =>
                                handleFieldChange(
                                  event,
                                  "MultipleChoiceLabel",
                                  setEditedDataCheckboxHeading,
                                  setTreeData
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              {selectedItem &&
                selectedItem.controltype === "DateBlock" &&
                controlsVisible && (
                  <>
                    <div className="clr-schm-option">
                      <div className="drowerseparater">
                        <div className="inputfildtet inpt-fild-test">
                          <div>Field Label</div>
                          <input
                            className="tab-txtbox theme-input-drpdown"
                            value={editedDataDateLabel}
                            onChange={(event) =>
                              handleFieldChange(
                                event,
                                "DateLabel",
                                setEditedDataDateLabel,
                                setTreeData
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}

              {selectedItem &&
                selectedItem.controltype === "RatingBlock" &&
                controlsVisible && (
                  <>
                    <div>
                      <div className="clr-schm-option">
                        <div className="drowerseparater">
                          <div className="label-algnmt">
                            <div className="inpt-fild-test">Field Label</div>
                            <input
                              className="tab-txtbox unit-option"
                              value={editedDataRatingLabel}
                              onChange={(event) =>
                                handleFieldChange(
                                  event,
                                  "RatingLabel",
                                  setEditedDataRatingLabel,
                                  setTreeData
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
            </div>
          </div>

          {controlsVisible && <div key="2"> </div>}
        </TabsComponent>
      </Box>
      {error && <ErrorBanner />}
    </>
  );
};

export default GeneralSection;
