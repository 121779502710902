import React, { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CreateOrOpenDiag from "../../../PopUpForm/CreateOrOpenDiag";
import themeProvider from "../../../ThemeProvider";
import { ThemeProvider, Button } from "@mui/material";
import NavBar from "../../navbarComponents/NavBar";
import { Theme } from "../../IncludeFile/Theme";
import { useMenu } from "../../../reusableComponents/menuUtils";
import {
  createRequestBody,
  postResource,
  getAccessKey,
  updateDataWithUserAndDates,
  loadLoginData,
} from "../../../apiCalls/DiagramsAPI";
import Overview from "../Overview";
import { ToastContainer, toast } from "react-toastify";
import DiagHeader from "../DiagHeader";
import { useAppContext } from "../RegionContext";
import { getFormattedLocalDateTime } from "../../../functions/ModifiedDate";
import { GiSightDisabled } from "react-icons/gi";
import { IoEyeSharp } from "react-icons/io5";
import { FavDictionary } from "../../../data/FavDictionary";
import ErrorBanner from "../../../Error/ErrorBanner";

function DiagramsRenderer() {
  const navigate = useNavigate();
  // const { getRegionInfo } = useAppContext();
  const [regionInfo, setRegionInfo] = useState(null);

  const [regions, setRegions] = React.useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const [isInactiveInvisible, setIsInactiveInvisible] = useState(true);
  const [error, setError] = useState(null);

  const authData = loadLoginData();
  const accessKey = authData?.accessKey || "";

  const fetchRegions1 = React.useCallback(async () => {
    if (!accessKey) {
      console.error("No access key available");
    }

    // setIsLoading(true);
    try {
      const response = await postResource(
        "GetQuickInfo",
        createRequestBody({
          accessKey: accessKey,
          guid: "Get Regions",
          param1: "",
          param2: "",
          param3: "",
          param4: "",
          recordSet: "",
          body: "",
        })
      );

      setRegions(response);
      // setError(null);
      return response;
    } catch (error) {
      console.error("Error fetching regions:", error);
      setError("An error occurred in fetching regions", error);
      localStorage.setItem("error", "An error occurred in fetching regions");
      localStorage.setItem("navigate", "/Landing");
    } finally {
      // setIsLoading(false);
    }
  }, [accessKey]);

  const getRegionInfo = useCallback(
    async (regionName) => {
      if (!regionName) return "HI";

      // Fetch regions if not already loaded
      if (!regions.length) {
        const ret = await fetchRegions1();

        if (ret) {
          return (
            ret.find(
              (region) =>
                region.Region.toLowerCase() === regionName.toLowerCase()
            ) || "1"
          );
        }
      } else {
        return (
          regions.find(
            (region) => region.Region.toLowerCase() === regionName.toLowerCase()
          ) || "2"
        );
      }
    },
    [regions, fetchRegions1]
  );

  // const storedRegion = localStorage.getItem("region");
  // var regionInfo;
  // if (storedRegion) {
  //   regionInfo = getRegionInfo(storedRegion);
  //   console.log("regionInfo ", regionInfo);
  // } else {
  //   console.log("No region stored in localStorage");
  // }
  // React.useEffect(() => {
  // const fetchRegions = async () => {
  //   try {
  //     const storedRegion = localStorage.getItem("region");
  //     const info = getRegionInfo(storedRegion);
  //     console.log("Region Names:", info);
  //     setRegionInfo(info);
  //   } catch (error) {
  //     console.error("Error fetching regions:", error);
  //   }
  // };

  //   fetchRegions();
  // }, []);
  //API Variables
  //selected templates in the left sidebar
  const [selected, setSelected] = useState([]);
  //all the templates and projects
  const [data, setData] = useState([]);
  const [fetched, setFetched] = useState(false);

  const fetchRegions = async () => {
    try {
      const storedRegion = localStorage.getItem("region");
      const info = await getRegionInfo(storedRegion);
      setRegionInfo(info);

      // Call fetchData only if info is valid and fetched is false
      if (!fetched && info) {
        fetchData(info);
      }
    } catch (error) {
      console.error("Error fetching regions:", error);
      setError("An error occurred in fetching regions", error);
      localStorage.setItem("error", "An error occurred in fetching regions");
      localStorage.setItem("navigate", "/Landing");
    }
  };

  useEffect(() => {
    fetchRegions(); // Call fetchRegions once on component load
  }, []);

  //get templates and projects
  async function fetchData(info) {
    try {
      //Diagram List Project Page or Diagram List Project Page - Region AI UI
      // const accessKey = getAccessKey();

      const templates = await postResource(
        "GetQuickInfo",
        createRequestBody({
          accessKey: accessKey,
          guid: `${info.API1}`,
          param1: authData?.userName?.toString() || "Unknown User",
        })
      );
      const templatesWithoutInactive = templates;
      const selectedRegion = localStorage.getItem("region");
      const regionTemplates = templatesWithoutInactive?.filter(
        (item) => item?.region?.toLowerCase() === selectedRegion?.toLowerCase()
      );
      setData(regionTemplates);
      setSelected(regionTemplates);
      setFetched(true);
    } catch (error) {
      console.error("Fetch Error ", error);
      toast.error("Error getting templates: " + error.message);
      setError("An error occurred in getting templates", error);
      localStorage.setItem("error", "An error occurred in getting templates");
      localStorage.setItem("navigate", "/Landing");
    }
  }
  //for CreateOrOpenDiag
  //selected template/project (for overview)

  //set selected template/project and open overview
  const handleDetails = (item) => {
    setSelectedItem(item);
    handlePopup();
  };

  //select project and go to /Diagrams
  const handleEditDiagram = async (item) => {
    let selectedProject = item;
    if (item.Bid !== 0) {
      selectedProject = data.find((obj) => obj.Bid === item.Bid);
    }
    navigate("/Diagrams", {
      state: { project: selectedProject },
    });
  };

  //Popup Variables
  const [open, setOpen] = useState(false);
  const [openDiagHeader, setOpenDiagHeader] = useState(false);

  const handlePopup = () => {
    setOpen(!open);
  };
  const handleDiagHeaderPopup = () => {
    setOpenDiagHeader(!openDiagHeader);
  };

  const editTemplate = (tempID, tempTitle) => {
    navigate("/ToolSelector", {
      state: { DiagTempID: tempID, DiagTempTitle: tempTitle },
    });
  };

  //popup bottom buttons
  const handleBack = () => {
    setSelectedItem(null);
    setOpenDiagHeader(false);
    handlePopup();
  };
  const handleDiagHeaderBack = () => {
    handleDiagHeaderPopup();
  };

  const handleNext = () => {
    if (selectedItem.Bid === 0) {
      setOpen(false);
      setOpenDiagHeader(true);
    } else {
      handleEditDiagram(selectedItem);
    }
  };
  const handleDiagHeaderNext = async (data) => {
    try {
      const updatedData = updateDataWithUserAndDates(data);
      const accessKey = getAccessKey();
      const projectPostResponse = await postResource(
        "PostQuickInfo",
        createRequestBody({
          accessKey: accessKey,
          guid: `${window.APIProvider.recordPath.UpsertQinfoDiagHeader}`,
          body: JSON.stringify(data),
        })
      );
      const Bid = projectPostResponse.find(
        (item) => item.outFields && item.outFields.BID
      )?.outFields.BID;
      if (projectPostResponse?.Status === "ERROR") {
        toast.error("Error getting recourse: ");
      } else {
        toast.success("Saved!");
        selectedItem.Bid = parseInt(Bid);
        handleEditDiagram(selectedItem);
      }
    } catch (error) {
      console.error("Fetch Error ", error);
      toast.error("Error saving data: " + error.message);
    }
  };

  //navbar button
  //search
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const leftButton = {
    icon: isInactiveInvisible ? <IoEyeSharp /> : <GiSightDisabled />,
    text: isInactiveInvisible ? "Show Inactive" : "Hide Inactive",
    tooltip: "Click to toggle showing inactive projects and templates",
    handleClick: () => {
      setIsInactiveInvisible(!isInactiveInvisible);
    },
  };

  //back
  const rightButtons = [
    {
      icon: Theme.GoBack,
      text: "Back",
      tooltip: "Go back to previous page",
      color: "secondary",
      handleClick: () => {
        navigate(-1);
      },
    },
  ];

  //close left sidebar from the navbar
  const { isMenuOpenleft, toggleMenu } = useMenu();

  const handleSave = async (data) => {
    try {
      // const loginData = JSON.parse(sessionStorage.getItem("loginData"));
      const userName = authData?.userName || "Unknown User";
      const todayDate = getFormattedLocalDateTime();
      if (!data.createdBy) {
        data.createdBy = userName;
      }
      if (!data.createdDate) {
        data.createdDate = todayDate;
      }
      // Update 'modifiedBy' and 'modifiedDate'
      data.modifiedBy = userName;
      data.modifiedDate = todayDate;
      data.region = regionInfo.Region;
      // const accessKey = getAccessKey();
      let templatePostResponse;
      let projectPostResponse;
      if (data.Bid === 0) {
        templatePostResponse = await postResource(
          "PostQuickInfo",
          createRequestBody({
            accessKey: accessKey,
            guid: `${window.APIProvider.recordPath.UpsertQinfoDiagTemplateHeader}`,
            body: JSON.stringify(data),
          })
        );
        setSelected((prevSelected) => {
          return prevSelected.map((s) =>
            s.diagTempID === data.diagTempID && s.Bid === 0
              ? { ...s, ...data }
              : s
          );
        });
      } else {
        data.description = data.explanation;
        projectPostResponse = await postResource(
          "PostQuickInfo",
          createRequestBody({
            accessKey: accessKey,
            guid: `${window.APIProvider.recordPath.UpsertQinfoDiagHeader}`,
            body: JSON.stringify(data),
          })
        );
        setSelected((prevSelected) => {
          return prevSelected.map((s) =>
            s.Bid === data.Bid ? { ...s, ...data } : s
          );
        });
      }
      if (
        templatePostResponse?.Status === "ERROR" &&
        projectPostResponse?.Status === "ERROR"
      ) {
        toast.error("Error getting recourse: ");
      }
      // else {
      //   toast.success("Saved!");
      // }
    } catch (error) {
      console.error("Fetch Error ", error);
      toast.error("Error saving data: " + error.message);
      setError("An error occurred in saving data", error);
      localStorage.setItem("error", "An error occurred in saving data");
      localStorage.setItem("navigate", "/Landing");
    }
  };

  const handleToggleFavorite = async (Bid, action) => {
    try {
      const response = await postResource(
        "PostQuickInfo",
        createRequestBody({
          accessKey: accessKey,
          guid: `${window.APIProvider.recordPath.ToggleFavoritesByUser}`,
          body: JSON.stringify([
            {
              username: authData?.userName?.toString() || "Unknown User",
              isFav: FavDictionary.flowProject?.toString(),
              Action: action?.toString(),
              RefPkey: Bid?.toString(),
            },
          ]),
        })
      );
      const updatedData = data.map((item) =>
        item.Bid === Bid ? { ...item, isFav: item.isFav === 1 ? 0 : 1 } : item
      );
      setData(updatedData);
      const updatedSelected = selected.map((item) =>
        item.Bid === Bid ? { ...item, isFav: item.isFav === 1 ? 0 : 1 } : item
      );
      setSelected(updatedSelected);
      if (response?.Status === "SUCCESS") {
        toast.success("Success!");
      }
    } catch (error) {
      console.error("Error ", error);
      toast.error("Error Toggling Project Activity: " + error.message);
    }
  };

  const handleToggleProjectActivity = async (Bid) => {
    try {
      const response = await postResource(
        "GetQuickInfo",
        createRequestBody({
          accessKey: accessKey,
          guid: `${window.APIProvider.recordPath.ToggleProjectActiveness}`,
          param1: Bid.toString(),
        })
      );
      const updatedData = data.map((item) =>
        item.Bid === Bid
          ? { ...item, isInactive: item.isInactive === 1 ? 0 : 1 }
          : item
      );
      setData(updatedData);
      const updatedSelected = selected.map((item) =>
        item.Bid === Bid
          ? { ...item, isInactive: item.isInactive === 1 ? 0 : 1 }
          : item
      );
      setSelected(updatedSelected);
      if (response.Status === "SUCCESS") {
        toast.success("Success!");
      }
    } catch (error) {
      console.error("Error ", error);
      toast.error("Error Toggling Project Activity: " + error.message);
    }
  };
  const handleToggleTemplateActivity = async (diagTempID) => {
    try {
      const response = await postResource(
        "GetQuickInfo",
        createRequestBody({
          accessKey: accessKey,
          guid: `${window.APIProvider.recordPath.ToggleTemplateActiveness}`,
          param1: diagTempID.toString(),
        })
      );
      const updatedData = data.map((item) =>
        item.diagTempID === diagTempID
          ? { ...item, isInactive: item.isInactive === 1 ? 0 : 1 }
          : item
      );
      setData(updatedData);
      const updatedSelected = selected.map((item) =>
        item.diagTempID === diagTempID
          ? { ...item, isInactive: item.isInactive === 1 ? 0 : 1 }
          : item
      );
      setSelected(updatedSelected);
      if (response.Status === "SUCCESS") {
        toast.success("Success!");
      }
    } catch (error) {
      console.error("Error ", error);
      toast.error("Error Toggling Project Activity: " + error.message);
    }
  };
  const [overviewDirtyFlag, setOverviewDirtyFlag] = useState(false);
  return (
    <ThemeProvider theme={themeProvider}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <NavBar
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        isSearchBar={true}
        rightButtons={rightButtons}
        leftButton={leftButton}
        isMenuOpenleft={true}
        toggleMenu={toggleMenu}
        title={`Choose [${localStorage.getItem("region")}] Template Or Project`}
      />
      <CreateOrOpenDiag
        handleDetails={handleDetails}
        handleEditDiagram={handleEditDiagram}
        searchQuery={searchQuery}
        caller={1}
        isMenuOpenleft={isMenuOpenleft}
        selected={
          isInactiveInvisible
            ? selected.filter((item) => item.isInactive !== 1)
            : selected
        }
        setSelected={setSelected}
        data={
          isInactiveInvisible
            ? data.filter((item) => item.isInactive !== 1)
            : data
        }
        fetched={fetchData}
        editTemplate={editTemplate}
        handleToggleFavorite={handleToggleFavorite}
        handleToggleProjectActivity={handleToggleProjectActivity}
        handleToggleTemplateActivity={handleToggleTemplateActivity}
        setSelectedItem={setSelectedItem}
        setOpenDiagHeader={setOpenDiagHeader}
      />
      {open && (
        <Overview
          open={open}
          overviewTitle={
            selectedItem.Bid === 0 ? selectedItem?.name : selectedItem?.title
          }
          handlePopup={handlePopup}
          item={selectedItem}
          handleBack={handleBack}
          handleNext={handleNext}
          handleSave={handleSave}
          data={data}
          overviewDirtyFlag={overviewDirtyFlag}
          setOverviewDirtyFlag={setOverviewDirtyFlag}
          nextButtonTitle={"Edit Flow"}
        />
      )}

      {openDiagHeader && (
        <DiagHeader
          open={openDiagHeader}
          handlePopup={handleDiagHeaderPopup}
          item={selectedItem}
          handleBack={handleDiagHeaderBack}
          handleNext={handleDiagHeaderNext}
          data={data}
        />
      )}
      {error && <ErrorBanner />}
    </ThemeProvider>
  );
}

export default DiagramsRenderer;
