import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import SidebarCollapse from "../Components/SideBarCollapseComponents/SidebarCollapse";
import AlignItems from "../StyleProperties/AlignItems";
import BodyColour from "../StyleProperties/BodyColour";
import Layout from "../StyleProperties/Layout";
import Font from "../StyleProperties/Font";
import Border from "../StyleProperties/Border";
import Margin from "../StyleProperties/Margin";
import Padding from "../StyleProperties/Padding";
import Size from "../StyleProperties/Size";
import TabsComponent from "../Components/diagramsComponents/TabsComponent";
import { Box, Button } from "@mui/material";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import List from "@mui/material/List";
import { ReadArray } from "../Components/Api/ApiCalls";
import { loadLoginData } from "../apiCalls/DiagramsAPI";
import Controls from "../Components/IncludeFile/Controls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const drawerWidth = 250;
const secondDrawerWidth = 300;

const MainContent = styled("main")({
  flexGrow: 1,
  padding: "16px",
  transition: "margin-right 0.3s ease",
});

const Sidebar = styled("aside")(({ theme, collapse }) => ({
  width: collapse ? 0 : drawerWidth,
  flexShrink: 0,
  height: "calc(100vh - 54px)",
  overflow: "auto",
  borderRight: `1px solid ${theme.palette.divider}`,
  transition: "width 0.3s ease",
  [`& .MuiDrawer-paper`]: {
    width: drawerWidth,
    boxSizing: "border-box",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const RightSidebar = styled("aside")(({ theme, collapse }) => ({
  width: collapse ? 0 : secondDrawerWidth,
  flexShrink: 0,
  height: "calc(100vh - 54px)",
  overflow: "auto",
  borderRight: collapse ? null : `1px solid ${theme.palette.divider}`,
  transition: "width 0.3s ease",
  [`& .MuiDrawer-paper`]: {
    width: secondDrawerWidth,
    boxSizing: "border-box",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const ExpandableStyleView = ({
  leftSidebarTree,
  matchedStyles,
  setSelectedItem,
  selectedNodesName,
  updateDefaultStyle,
  alignSelfValue,
  setAlignSelfValue,
  setDefaultStyleState,
  backgroundColorValue,
  setBackgroundColorValue,
  colorValue,
  setColorValue,
  marginTop,
  setMarginTop,
  marginBottom,
  setmarginBottom,
  marginLeft,
  marginRight,
  setMarginLeft,
  setMarginRight,
  widthValue,
  setWidthValue,
  heightValue,
  setHeightValue,
  minWidthValue,
  minHeightValue,
  setMinHeightValue,
  setMinWidthValue,
  fontSizeValue,
  setFontSizeValue,
  fontFamilyValue,
  setFontFamilyValue,
  fontWeightValue,
  setFontWeightValue,
  fontStyleValue,
  setFontStyleValue,
  textDecorationValue,
  settextDecorationValue,
  borderWidth,
  setborderWidth,
  borderRadius,
  setborderRadius,
  borderColorValue,
  setborderColorValue,
  paddingTop,
  paddingBottom,
  paddingLeft,
  paddingRight,
  setPaddingTop,
  setPaddingBottom,
  setPaddingRight,
  setPaddingLeft,
  maxWidthValue,
  maxHeightValue,
  setMaxHeightValue,
  setMaxWidthValue,
  textAlignValue,
  setTextAlignValue,
  flexDirection,
  setFlexDirection,
  alignItems,
  setAlignItems,
  alignContent,
  setAlignContent,
  justifyItems,
  setJustifyItems,
  justifyContent,
  setJustifyContent,
  defaultStyleFromLoca
}) => {

  const [leftCollapse, setLeftCollapse] = useState(false);
  const [rightCollapse, setRightCollapse] = useState(true);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const handleMainContentClick = () => {
    setSelectedItem(null);
  };

  const [isPropertyNameVisible, setPropertyNameVisible] = useState(true);
  const [isNameClicked, setIsNameClicked] = useState(false);
  const [isRightPanelVisible, setIsRightPanelVisible] = useState(true);
  const togglePropertyName = () => {
    setPropertyNameVisible(!isPropertyNameVisible);
    setIsNameClicked(!isNameClicked);
    setIsRightPanelVisible(!isRightPanelVisible);
  };

  const [stylesPropName, setStylesPropName] = useState("");
  const [defaultStyle, setDefaultStyle] = useState([]);

  useEffect(() => {
    const StylePropertyName = leftSidebarTree.map(
      (item, index) => item.propertyName
    );
    setStylesPropName(StylePropertyName);
  }, []);

  const authData = loadLoginData();
  const accessKey = authData.accessKey;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setDefaultStyle(defaultStyleFromLoca);
      } catch (error) {
        console.error("Error fetching source data:", error);
      }
    };
    fetchData();
  }, []);

  const handlePropertyNameClick = (propertyName, index) => {
    setSelectedProperty(propertyName);
    setSelectedItemIndex(index);
    if (window.innerWidth < 600) {
      setLeftCollapse(true);
      setShowLeftIcon(false);
    }
  };

  const [tabValue, setTabValue] = useState("1");
  const tabs = [{ label: "Style", value: "1" }];

  const [showLeftIcon, setShowLeftIcon] = useState(true);

  const handleClick = () => {
    setShowLeftIcon(!showLeftIcon);
    setLeftCollapse(!leftCollapse);
  };
  const handleMenushwDskButtonClick = () => {
    setLeftCollapse(!leftCollapse);
  };



  const styleType = matchedStyles[0]?.styleType;

  // const [htmlTag, setHtmlTag] = useState(null);
  // const [dynamicStyle, setDynamicStyle] = useState({});

  // useEffect(() => {
  //   if (selectedNodesName?.length > 0 && Controls?.length > 0) {
  //     let matchedControl = null;

  //     for (const group of Controls) {
  //       matchedControl = group.items.find(
  //         (item) => item.styleType === selectedNodesName[0]
  //       );
  //       if (matchedControl) break;
  //     }

  //     if (matchedControl) {
  //       console.log("HTML Tag:", matchedControl.htmlTag);
  //       setHtmlTag(matchedControl.htmlTag);

  //       const matchedStyle = matchedStyles.find(
  //         (style) => style.styleType === selectedNodesName[0]
  //       );
  //       if (matchedStyle) {
  //         setDynamicStyle(matchedStyle.style);
  //       } else {
  //         setDynamicStyle({});
  //       }

  //     } else {
  //       console.log("No matching control found for:", selectedNodesName[0]);
  //       setHtmlTag(null);
  //       setDynamicStyle({});
  //     }
  //   }
  // }, [selectedNodesName, Controls, matchedStyles]);

  const [htmlTag, setHtmlTag] = useState(null);
  const [dynamicStyle, setDynamicStyle] = useState({});
  const [inputType, setInputType] = useState("");
  const [description, setDescription] = useState(""); // State for the description text

  useEffect(() => {
    if (selectedNodesName?.length > 0 && Controls?.length > 0) {
      let matchedControl = null;

      for (const group of Controls) {
        matchedControl = group.items.find(
          (item) => item.styleType === selectedNodesName[0]
        );
        if (matchedControl) break;
      }

      if (matchedControl) {
    
        setHtmlTag(matchedControl.htmlTag);

        const matchedStyle = matchedStyles.find(
          (style) => style.styleType === selectedNodesName[0]
        );
        if (matchedStyle) {
          setDynamicStyle(matchedStyle.style);
        } else {
          setDynamicStyle({});
        }

        if (selectedNodesName[0] === "Checkbox") {
          setInputType("checkbox");
        } else if (selectedNodesName[0] === "Radio") {
          setInputType("radio");
        } else if (selectedNodesName[0] === "Icon") {
          setHtmlTag("div");
        } else if (selectedNodesName[0] === "Switch") {
          setHtmlTag("div");
        } else {
          setInputType("");
        }

        if (
          selectedNodesName[0] === "Switch" ||
          selectedNodesName[0] === "Checkbox" ||
          selectedNodesName[0] === "Divider" ||
          selectedNodesName[0] === "Image" ||
          selectedNodesName[0] === "Radio"
        ) {
          setDescription("");
        } else {
          setDescription(matchedControl.description || null);
        }
      } else {
        console.log("No matching control found for:", selectedNodesName[0]);
        setHtmlTag(null);
        setDynamicStyle({});
        setInputType("");
        setDescription(""); // Reset description
      }
    }
  }, [selectedNodesName, Controls, matchedStyles]);

  

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <div
        style={{ display: "flex", flexDirection: "row" }}
        className="expandable-flex"
      >
        <Sidebar collapse={leftCollapse}>
          <>
            <List className="prty-item-top">
              <li>
                {" "}
                <Button
                  className="prty-name item-propety"
                  onClick={togglePropertyName}
                  style={{
                    backgroundColor: isNameClicked
                      ? "rgba(0, 0, 0, 0.1)"
                      : "transparent",
                  }}
                >
                  {isPropertyNameVisible ? (
                    <ExpandMore onClick={togglePropertyName} />
                  ) : (
                    <ExpandLess onClick={() => setPropertyNameVisible(true)} />
                  )}
                  <span>{selectedNodesName}</span>
                </Button>
                {isPropertyNameVisible && (
                  <List className="prty-item">
                    {leftSidebarTree.map((item, index) => (
                      <li
                        style={{
                          backgroundColor:
                            selectedItemIndex === index
                              ? "rgba(0, 0, 0, 0.1)"
                              : "white",
                        }}
                      >
                        {" "}
                        <Button
                          key={index}
                          onClick={() =>
                            handlePropertyNameClick(item.propertyName, index)
                          }
                          className="item-propety MuiListItem-button"
                        >
                          {item.propertyName}
                        </Button>
                      </li>
                    ))}
                  </List>
                )}
              </li>{" "}
            </List>
          </>
        </Sidebar>
        <SidebarCollapse
          showmenudsk={leftCollapse}
          setShowdskmenu={setLeftCollapse}
          direction={0}
          handleMenushwDskButtonClick={handleMenushwDskButtonClick}
        />
      </div>

      {selectedProperty && (
        <Box
          padding="0.5rem"
          className="brder-right-edit"
          style={{ display: isRightPanelVisible ? "block" : "none" }}
        >
          <div
            className="inputfildtet icon-edit-align-control"
            style={{ marginBottom: "0.5rem" }}
          >
            StyleType - {/* <span className="sltitmnem"> */}
            {styleType}
            {/* </span> */}
            <div className="top-left-icon-panel" onClick={handleClick}>
              {showLeftIcon ? <FaAngleRight /> : <FaAngleLeft />}
            </div>
          </div>
          <TabsComponent
            tabValue={tabValue}
            setTabValue={setTabValue}
            tabs={tabs}
          >
            <div key="1">
              {selectedProperty && (
                <div>
                  {selectedProperty === "Align items" &&
                    stylesPropName.includes("Align items") && (
                      <AlignItems
                        matchedStyles={matchedStyles}
                        updateDefaultStyle={updateDefaultStyle}
                        alignSelfValue={alignSelfValue}
                        setAlignSelfValue={setAlignSelfValue}
                        setDefaultStyleState={setDefaultStyleState}
                        showmenudsk={leftCollapse}
                        setShowdskmenu={setLeftCollapse}
                        defaultStyle={defaultStyle}
                        setDefaultStyle={setDefaultStyle}
                        styleType={styleType}
                      />
                    )}
                  {selectedProperty === "Body Colour" &&
                    stylesPropName.includes("Body Colour") && (
                      <BodyColour
                        matchedStyles={matchedStyles}
                        updateDefaultStyle={updateDefaultStyle}
                        backgroundColorValue={backgroundColorValue}
                        setBackgroundColorValue={setBackgroundColorValue}
                        setDefaultStyleState={setDefaultStyleState}
                        showmenudsk={leftCollapse}
                        setShowdskmenu={setLeftCollapse}
                        defaultStyle={defaultStyle}
                        setDefaultStyle={setDefaultStyle}
                        styleType={styleType}
                      />
                    )}
                  {selectedProperty === "Layout" &&
                    stylesPropName.includes("Layout") && (
                      <Layout
                        matchedStyles={matchedStyles}
                        setDefaultStyleState={setDefaultStyleState}
                        updateDefaultStyle={updateDefaultStyle}
                        flexDirection={flexDirection}
                        setFlexDirection={setFlexDirection}
                        alignItems={alignItems}
                        setAlignItems={setAlignItems}
                        alignContent={alignContent}
                        setAlignContent={setAlignContent}
                        justifyItems={justifyItems}
                        setJustifyItems={setJustifyItems}
                        justifyContent={justifyContent}
                        setJustifyContent={setJustifyContent}
                        showmenudsk={leftCollapse}
                        setShowdskmenu={setLeftCollapse}
                        defaultStyle={defaultStyle}
                        setDefaultStyle={setDefaultStyle}
                        styleType={styleType}
                      >
                        '
                      </Layout>
                    )}
                  {selectedProperty === "Font" &&
                    stylesPropName.includes("Font") && (
                      <Font
                        matchedStyles={matchedStyles}
                        setDefaultStyleState={setDefaultStyleState}
                        updateDefaultStyle={updateDefaultStyle}
                        fontSizeValue={fontSizeValue}
                        setFontSizeValue={setFontSizeValue}
                        fontFamilyValue={fontFamilyValue}
                        setFontFamilyValue={setFontFamilyValue}
                        fontWeightValue={fontWeightValue}
                        setFontWeightValue={setFontWeightValue}
                        fontStyleValue={fontStyleValue}
                        setFontStyleValue={setFontStyleValue}
                        textDecorationValue={textDecorationValue}
                        settextDecorationValue={settextDecorationValue}
                        colorValue={colorValue}
                        setColorValue={setColorValue}
                        textAlignValue={textAlignValue}
                        setTextAlignValue={setTextAlignValue}
                        showmenudsk={leftCollapse}
                        setShowdskmenu={setLeftCollapse}
                        defaultStyle={defaultStyle}
                        setDefaultStyle={setDefaultStyle}
                        styleType={styleType}
                      ></Font>
                    )}
                  {selectedProperty === "Border" &&
                    stylesPropName.includes("Border") && (
                      <Border
                        matchedStyles={matchedStyles}
                        setDefaultStyleState={setDefaultStyleState}
                        updateDefaultStyle={updateDefaultStyle}
                        borderWidth={borderWidth}
                        setborderWidth={setborderWidth}
                        borderRadius={borderRadius}
                        setborderRadius={setborderRadius}
                        borderColorValue={borderColorValue}
                        setborderColorValue={setborderColorValue}
                        showmenudsk={leftCollapse}
                        setShowdskmenu={setLeftCollapse}
                        defaultStyle={defaultStyle}
                        setDefaultStyle={setDefaultStyle}
                        styleType={styleType}
                      ></Border>
                    )}
                  {selectedProperty === "Margin" &&
                    stylesPropName.includes("Margin") && (
                      <Margin
                        matchedStyles={matchedStyles}
                        updateDefaultStyle={updateDefaultStyle}
                        marginTop={marginTop}
                        setMarginTop={setMarginTop}
                        setDefaultStyleState={setDefaultStyleState}
                        alignSelfValue={alignSelfValue}
                        marginBottom={marginBottom}
                        setmarginBottom={setmarginBottom}
                        marginLeft={marginLeft}
                        marginRight={marginRight}
                        setMarginLeft={setMarginLeft}
                        setMarginRight={setMarginRight}
                        showmenudsk={leftCollapse}
                        setShowdskmenu={setLeftCollapse}
                        defaultStyle={defaultStyle}
                        setDefaultStyle={setDefaultStyle}
                        styleType={styleType}
                      >
                        '
                      </Margin>
                    )}
                  {selectedProperty === "Padding" &&
                    stylesPropName.includes("Padding") && (
                      <Padding
                        matchedStyles={matchedStyles}
                        updateDefaultStyle={updateDefaultStyle}
                        setDefaultStyleState={setDefaultStyleState}
                        paddingTop={paddingTop}
                        paddingBottom={paddingBottom}
                        paddingLeft={paddingLeft}
                        paddingRight={paddingRight}
                        setPaddingTop={setPaddingTop}
                        setPaddingBottom={setPaddingBottom}
                        setPaddingRight={setPaddingRight}
                        setPaddingLeft={setPaddingLeft}
                        showmenudsk={leftCollapse}
                        setShowdskmenu={setLeftCollapse}
                        defaultStyle={defaultStyle}
                        setDefaultStyle={setDefaultStyle}
                        styleType={styleType}
                      >
                        '
                      </Padding>
                    )}
                  {selectedProperty === "Size" &&
                    stylesPropName.includes("Size") && (
                      <Size
                        matchedStyles={matchedStyles}
                        widthValue={widthValue}
                        setWidthValue={setWidthValue}
                        updateDefaultStyle={updateDefaultStyle}
                        setDefaultStyleState={setDefaultStyleState}
                        heightValue={heightValue}
                        setHeightValue={setHeightValue}
                        minWidthValue={minWidthValue}
                        minHeightValue={minHeightValue}
                        setMinHeightValue={setMinHeightValue}
                        setMinWidthValue={setMinWidthValue}
                        maxWidthValue={maxWidthValue}
                        maxHeightValue={maxHeightValue}
                        setMaxHeightValue={setMaxHeightValue}
                        setMaxWidthValue={setMaxWidthValue}
                        showmenudsk={leftCollapse}
                        setShowdskmenu={setLeftCollapse}
                        defaultStyle={defaultStyle}
                        setDefaultStyle={setDefaultStyle}
                        styleType={styleType}
                      >
                        '
                      </Size>
                    )}
                </div>
              )}
            </div>
          </TabsComponent>
        </Box>
      )}
      <div
        style={{
          width: "100%",
          width: "100%",
        }}
      >
        {htmlTag &&
          (htmlTag === "input" && inputType ? (
            React.createElement(
              htmlTag,
              {
                type: inputType,
                style: dynamicStyle,
              },
              description || null
            )
          ) : htmlTag === "div" && selectedNodesName[0] === "Icon" ? (
            <div style={dynamicStyle}>
              <FontAwesomeIcon icon={`fa-solid fa-${description}`} />
            </div>
          ) : htmlTag === "input" && selectedNodesName[0] === "TextArea" ? (
            <textarea style={dynamicStyle} value={description} />
          ) : htmlTag === "input" && selectedNodesName[0] === "TextBox" ? (
            <input style={dynamicStyle} value={description} />
          ) : htmlTag === "input" && selectedNodesName[0] === "Date" ? (
            <input type="date" style={dynamicStyle} value={description} />
          ) : htmlTag === "div" && selectedNodesName[0] === "Switch" ? (
            <div className="controlCls" style={dynamicStyle}>
              <label className="switch">
                <input type="checkbox" checked={true} onChange={() => {}} />
                <input type="checkbox" checked={true} onChange={() => {}} />
                <span className="slider round"></span>
              </label>
              <label>Switch</label>
            </div>
          ) : (
            React.createElement(
              htmlTag,
              {
                style: dynamicStyle,
              },
              description || null
            )
          ))}
      </div>
    </div>
  );
};
export default ExpandableStyleView;
